const StringUtils = (function () {
    const stringIncludes = (containerObj, userInput) => {
        return containerObj.toUpperCase().includes(userInput.toUpperCase());
    };

    return {
        stringIncludes: stringIncludes
    }
}());

export default StringUtils;