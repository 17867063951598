import {useCallback, useEffect, useState, useMemo} from "react";
import ViewportWarning from "../shared/ViewportWarning";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import CircuitsAffected from "./CircuitsAffected";
import ImpactDuration from "./ImpactDuration";
import getMaintenanceData from "../../services/MaintenanceService";
import Loader from "../shared/Loader";
import {useSelector} from 'react-redux';
import {ConsoleLogger} from "../../logger/ConsoleLogger";
import HTTPErrorHandler from "../errorPages/HTTPErrorHandler";
import CompanyDropdown from "../shared/CompanyDropdown";


const options = {
    paginationSize: 4,
    pageStartIndex: 1,
    sizePerPage: 15
};

const maintenance_options = [{
    name : 'Upcoming',
    value: 'upcoming',
},{
    name : 'Postponed',
    value: 'postponed',
},{
    name : 'Completed',
    value: 'completed',
},{
    name : 'Cancelled',
    value: 'cancelled',
}];

const columns = [{
    dataField: 'eventNumber',
    text: 'Event Number',
    sort: true,
    style: {
    },
    classes: 'w12'
}, {
    dataField: 'fromTimeFrame',
    text: 'TimeFrame',
    classes: 'w12'
},
{
    dataField: 'maintenanceDetails',
    text: 'Maintenance Details'
}, {
    dataField: 'maintenanceType',
    text: 'Maintenance Type'
}];

const MaintenanceSchedule = () => {
    const logger = useMemo(() => new ConsoleLogger({level: process.env.REACT_APP_LOGGER_LEVEL,}),[]);
    const [hasError, setHasError] = useState(null);
    const [maintenanceType,setMaintenanceType] = useState("upcoming");
    const [maintenanceData,setMaintenanceData] = useState([]);
    const [loader,setLoader] = useState(false);
    const isEmployee = localStorage.getItem("isEmployeeLoggedIn");
    const selectedInternalMpid = useSelector(state => state.internal.company?.value);
    
    const getMaintenance = useCallback((type) =>{
        setLoader(true);
        getMaintenanceData(type).then(resp => {
            setMaintenanceData(resp.data);
            setLoader(false);
            logger.info(resp.data);
        }).catch(error => {
            setHasError(error);
            logger.error("getMaintenanceData Error: " + error);
            setLoader(false);
        })
    },[logger]) 

    useEffect(() => {
        if(isEmployee){
            if(selectedInternalMpid){
                getMaintenance(maintenanceType); 
            }
        } else {
            getMaintenance(maintenanceType);  
        }
        
        
    }, [getMaintenance, isEmployee, maintenanceType, selectedInternalMpid]);

    const renderRow = (maintenanceData) => {
        return (
            <>
            <CircuitsAffected circuitsAffected={maintenanceData.circuits_affected} />
            <ImpactDuration impacts={maintenanceData.impact_duration} />
            </>
        )
    }

    const expandRow = {
        renderer: renderRow,
        showExpandColumn: true,
        expandByColumnOnly: true,
        onlyOneExpanding: true,
        expandColumnPosition: 'right',
        expandHeaderColumnRenderer: ({ isAnyExpands }) => {
            return <b onClick={(e) => { e.stopPropagation() }}>Additional Info</b>;
        },
        expandColumnRenderer: () => {
            return (
                <svg className="ico-plus" style={{transform:"scale(0.8)"}}>
                    <path
                        d="M13.1,24H10.89V13.1H0V10.89H10.89V0H13.1V10.89H24V13.1H13.1Z"></path>
                </svg>
            );
        }
    };

    const maintenanceHandler = async (ev) => {
        let type = ev.target.value;
        logger.info(encodeURIComponent(type).replace(/%20/g, " "));
        setMaintenanceType(ev.target.value);
        setLoader(true)
        getMaintenance(type);
    }
    

        return (
            <>
            { loader ? <Loader /> : null }
            {hasError && <HTTPErrorHandler error={hasError}></HTTPErrorHandler>}
            <div>
                <div className="midsection qm-summary abs z1">
                    <div className="rel">
                        <ViewportWarning />
                        <div className="wrap">"
                            <h1 className="page-title">{isEmployee ? "Employee Maintenance" : "Maintenance"}</h1>
                            {isEmployee ? <CompanyDropdown/> : null}
                            {!hasError && 
                            (<div className="section">
                                {/* <TabsQuoteManager/> */}
                                {/* <TabsQuote /> */}
                                <div className="content">
                                    <div className="fr w20 state-select" >
                                    <select className="nice-select dark-select wide mb-5" name="validator-state"  onChange={maintenanceHandler} value={maintenanceType}>
                                        {maintenance_options.map(item => (
                                            <option key={item.value} value={item.value}>
                                                {item.name}
                                            </option>
                                        ))}
                                    </select>
                                    </div>
                                    {maintenanceData.length === 0 ?
                                            
                                            <p><br></br>There are no {maintenanceType} maintenance tickets</p>
                                        : null
                                    }
                                    <div className="table-style-wrap pagination-table">
                                        {maintenanceData.length > 0 ?
                                            <BootstrapTable condensed bordered={false} striped hover keyField='id' bootstrap4 data={maintenanceData} 
                                                columns={columns} pagination={paginationFactory(options)} 
                                                expandRow={expandRow}/> : null}          
                                    </div>
                                    
                                </div>
                            </div>)
                            }
                        </div>
                    </div>
                </div>
            </div>
            </>
        );
}


export default MaintenanceSchedule;
