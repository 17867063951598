import {useEffect, useState} from 'react';
import style from "./TicketCreate.module.css";
import {connect, useSelector} from 'react-redux';
import LabelComponent from './LabelComponent';
import CheckboxComponent from './CheckboxComponent';

import {
	tcErrorMsg,
	tcLContact,
	tcLContactEmail,
	tcLContactId,
	tcLContactPhone,
	tcLContactPhoneExt,
	tcLPhoneType,
	tcLUpdatedEmail
} from '../../redux';
import {ConsoleLogger} from "../../logger/ConsoleLogger";
import Select from 'react-select';

const TicketCreateLocalContact = (props) => {
	const logger = new ConsoleLogger({ level: process.env.REACT_APP_LOGGER_LEVEL, });
	const contactList = props.contactListData;
	const troubleContactLocalControls = props.localDetailsData;
	const localDetailsFromStore = useSelector(state => state?.ticketCreate?.localDetails);
	const contactListFromStore = useSelector(state => state?.ticketCreate?.contactList);
	const [createNewContactFlag, setCreateNewContactFlag] = useState(false);

	useEffect(() => {
		if (localDetailsFromStore.contactId?.value === "new") {
			setCreateNewContactFlag(true);
		}
	}, [localDetailsFromStore])

	const inputChange = (obj, event) => {
		const value = obj.type === 'checkbox' ? event.target.checked : event.target.value;
		props[obj.name](value);
		props.updateErrorMsg('');
	}

	const selectInputChange = (event) => {
		let contactVal = "";
		if (!event) {
			contactVal = ""
		} else {
			contactVal = event;
		}

		logger.info(`value: ${contactVal}`);
		if (createNewContactFlag === false && contactVal?.value !== "new") {
			autoFillContact(contactVal);
		} else {
			setCreateNewContactFlag(true);
			props.contact("");
			props.contactPhone("");
			props.contactPhoneExt("");
			props.contactEmail("");
			props.contactId(contactVal);
		}
		props.updateErrorMsg('');
	}

	//Autofills contact info and adds it to the redux store
	const autoFillContact = (contactId) => {
		const index = contactListFromStore.findIndex((obj) => obj.id === contactId?.value);
		logger.info(`index of contact in store: ${index}`);
		props.contactId(contactId);
		props.contact(contactListFromStore[index].name ? contactListFromStore[index].name : "");
		props.contactPhone(contactListFromStore[index].phone ? contactListFromStore[index].phone?.replace(/\D/g, '').slice(0, 10) : '');
		props.contactPhoneExt(contactListFromStore[index].phone_Extension__c ? contactListFromStore[index].phone_Extension__c : '');
		props.contactEmail(contactListFromStore[index].email ? contactListFromStore[index].email : '');
	}

	//Updates the contact name
	const updateContactName = (value, type) => {
		let firstName = localDetailsFromStore['contact'].indexOf(" ") !== -1 ? localDetailsFromStore['contact']?.substring(0, localDetailsFromStore['contact']?.indexOf(" ")) : localDetailsFromStore['contact'];
		let lastName  = localDetailsFromStore['contact'].indexOf(" ") !== -1 ? localDetailsFromStore['contact']?.substring(localDetailsFromStore['contact']?.indexOf(" ") + 1) : "";

		if(type === 'firstName'){
			props.contact(value.trim() + " " + lastName.trim());
		} else {
			props.contact(firstName.trim() + " " + value.trim());
		}
	}

	return (
		<>
			{troubleContactLocalControls && troubleContactLocalControls.length > 0 ?
				<>
					<div className="row align-items-center">
						<div className="col-12 col-sm-6 col-md-4">
							<LabelComponent obj={troubleContactLocalControls[0]} />
						</div>
						<div className="col-12 col-sm-6 col-md-8">
							{createNewContactFlag ?
								<div className="row align-items-center">
									<div className="col-12">
										<input type="text" className={`${style.inputFldText} `} onChange={(event) => { inputChange(troubleContactLocalControls[0], event) }} value={localDetailsFromStore['contact']} />
									</div>
								</div> :
								<Select
									onChange={(event) => selectInputChange(event)}
									value={localDetailsFromStore['contactId'] ? localDetailsFromStore['contactId'] : null}
									options={contactList.map((item) => {
										return { value: item.id, label: item.name }
									})}
									placeholder={'Search for a Contact...'}
									styles={{
										placeholder: (base) => ({
											...base,
											fontSize: '14px',
										})
									}}
									isSearchable
									noOptionsMessage={() => 'Loading Contacts...'}
								/>
								}
						</div>
					</div>
					{localDetailsFromStore['contactId'] !== "" ?
						<>
							<div className="row align-items-center mt-3">
								<div className="col-12 col-sm-6 col-md-4">
									<label htmlFor="firstName" className={`d-block text-uppercase mb-0 ${style.labelText}`}>First Name<span className={style.mandatory}> *</span></label>
								</div>
								<div className="col-12 col-sm-6 col-md-8">
									<div className="row align-items-center">
										<div className="col-12">
											<input type="text" className={`${style.inputFldText} `} onChange={(event) => updateContactName(event.target.value, 'firstName')} value={localDetailsFromStore['contact'].indexOf(" ") !== -1 ? localDetailsFromStore['contact']?.substring(0, localDetailsFromStore['contact']?.indexOf(" ")) : localDetailsFromStore['contact']}/>
										</div>
									</div>
								</div>
							</div>

							<div className="row align-items-center mt-3">
								<div className="col-12 col-sm-6 col-md-4">
									<label htmlFor="lastName" className={`d-block text-uppercase mb-0 ${style.labelText}`}>Last Name<span className={style.mandatory}> *</span></label>
								</div>
								<div className="col-12 col-sm-6 col-md-8">
									<div className="row align-items-center">
										<div className="col-12">
											<input type="text" className={`${style.inputFldText} `} onChange={(event) => updateContactName(event.target.value, 'lastName')} value={localDetailsFromStore['contact'].indexOf(" ") !== -1 ? localDetailsFromStore['contact']?.substring(localDetailsFromStore['contact']?.indexOf(" ") + 1) : ""}/>
										</div>
									</div>
								</div>
							</div>
						</> : null
					}

					<div className="row align-items-center mt-3">
						<div className="col-12 col-sm-6 col-md-4">
							<LabelComponent obj={troubleContactLocalControls[1]} />
						</div>
						<div className="col-12 col-sm-6 col-md-8">
							<div className="row align-items-center">
								<div className="col-8">
									<input type="text" className={`${style.inputFldText} `} maxLength="10" onChange={(event) => { inputChange(troubleContactLocalControls[1], event) }} value={localDetailsFromStore['contactPhone']} />
								</div>
								<div className="col-4 pl-0">
									<span className={` ${style.labelText} ${style.extField} textuppercase`}>ext</span>
									<input type="text" className={` pl-1 ${style.inputFldText} ${style.widthLess25px} `} onChange={(event) => { inputChange(troubleContactLocalControls[2], event) }} value={localDetailsFromStore['contactPhoneExt']} />
								</div>
							</div>

						</div>
					</div>

					<div className="row align-items-center mt-3">
						<div className="col-12 col-sm-6 col-md-4">
							<LabelComponent obj={troubleContactLocalControls[3]} />
						</div>
						<div className="col-12 col-sm-6 col-md-8">
								<select className={`${style.selectFld}`} onChange={(event) => { inputChange(troubleContactLocalControls[3], event) }} value={localDetailsFromStore['phoneType']} >
									<option>Select</option>
									<option value="cell">Cell Phone</option>
									<option value="business">Business Phone</option>
								</select>
						</div>
					</div>

					<CheckboxComponent obj={troubleContactLocalControls[4]} val={localDetailsFromStore['receiveUpdateEmail']} checkboxEmit={(event) => { inputChange(troubleContactLocalControls[4], event) }} />


					<div className="row align-items-center">
						<div className="col-12 col-sm-6 col-md-4">
							<LabelComponent obj={troubleContactLocalControls[5]} />
						</div>
						<div className="col-12 col-sm-6 col-md-8">
							<input type="text" className={` ${style.inputFldText}`} onChange={(event) => { inputChange(troubleContactLocalControls[5], event) }} value={localDetailsFromStore['contactEmail']} />
						</div>
					</div>

				</> : null
			}
		</>
	)
}

const mapDispatchToProps = {
	contact: tcLContact,
	contactPhone: tcLContactPhone,
	contactPhoneExt: tcLContactPhoneExt,
	phoneType: tcLPhoneType,
	receiveUpdateEmail: tcLUpdatedEmail,
	contactEmail: tcLContactEmail,
	updateErrorMsg: tcErrorMsg,
	contactId: tcLContactId
}

export default connect(null, mapDispatchToProps)(TicketCreateLocalContact);
