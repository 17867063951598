import axios from "./axios/customAxios";

const getMPLSInternetIPs = async () => {
	const mplsInternetIPUrl = `${process.env.REACT_APP_API_URL}/getBandwidths.php?product=13&menu=ip`;
    return await axios.get(mplsInternetIPUrl);
}
const getMPLSInternetBW = async () =>{
	const mplsInternetBWUrl = `${process.env.REACT_APP_API_URL}/getBandwidths.php?product=13&menu=bw`;
    return await axios.get(mplsInternetBWUrl);
}


const MPLSInternetHelper = {
    getMPLSInternetIPs,
    getMPLSInternetBW
}

export default MPLSInternetHelper;
