import style from './Summary.module.css';
import React from 'react';

const SummaryContent = ({circuitDetails, locADetails, locZDetails}) => {


    return (
        <>
            <div className="sub-hdr">
                <div className="nib">
                    <div className="the-notch">
                        <div className="notch-fg"><svg><path d="M35.81,26.28l-13,10v-5C-10.47,28.68-1,9.16,11.79,0c0,7.28,0,9.13,0,13.28s3.18,7.92,11,9v-5Z"></path></svg></div>
                    </div>
                </div>
                <h2>Circuit Details</h2>
            </div>


            <div className="sub-section">
                <div className="rs-colFLs w100 cf">
                    <div className={style.rowGrp}>
                        <div className={style.rowGrpContent}>
                            <table className={` table ${style.recordSetMini} `}>
                                <tr>
                                    <td className={style.rsIco}>
                                        <svg className="ico size-s"><path d="M23,35V34H21V30h2V27h8V26H27V25h6v2h2v8ZM18,20c-3,1-8,2-8,6,0,3,5.07,5,7,5h2v2H17c-2.59,0-9-2-9-7,0-6,7-7,10-8s10-2.09,10-5.25C28,10.08,23.93,9,22,9H17V7h5c2.59,0,8,1.82,8,6C30,18.41,21,19,18,20Zm-5-9H1V3H3V1H9V2H5V3h8V6h2v4H13Z"></path></svg>
                                    </td>
                                    <td className={style.rsField}>
                                        <h4>Circuit ID</h4>
                                    </td>
                                    <td className={style.rsValue}>
                                        <span>{circuitDetails?.cid}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td className={style.rsIco}>
                                        <svg className="ico size-s"><path d="M1.5,36V3h3V0h14V3h3v7h13V36Zm19-32h-3V1H5.5V4h-3V34h7V29h4v5h7Zm13,7h-12V34h12Zm-8,6h-2V15h2Zm0,5h-2V20h2Zm0,5h-2V25h2Zm0,5h-2V30h2Zm3-15h-2V15h2Zm0,5h-2V20h2Zm0,5h-2V25h2Zm0,5h-2V30h2Zm3-15h-2V15h2Zm0,5h-2V20h2Zm0,5h-2V25h2Zm0,5h-2V30h2Zm-18-9h1v3h-1Zm4-11h-2V9h2Zm0,7h-2V16h2Zm0,7h-2V23h2Zm0,6h-2V29h2Zm-4-16h1v3h-1Zm0-7h1v3h-1Zm-5,14h1v3h-1Zm0-7h1v3h-1Zm0-7h1v3h-1Zm-3,20h2v3h-2Zm0-6h2v3h-2Zm0-7h2v3h-2Zm0-7h2v3h-2Z"></path></svg>
                                    </td>
                                    <td className={style.rsField}>
                                        <h4>Account Name</h4>
                                    </td>
                                    <td className={style.rsValue}>
                                        <span>{circuitDetails?.name}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td className={style.rsIco}>
                                        <svg className="ico size-s" viewBox="0 0 25 36"><path d="M26,20V31l-1,1-1-1V20H2V31L1,32,0,31V20.34L2,18H24ZM7,13V3A7.5,7.5,0,0,1,19,3V13a8.88,8.88,0,0,1-6,2A9,9,0,0,1,7,13Zm2-1a10.28,10.28,0,0,0,4,1,9.9,9.9,0,0,0,4-1V4a4.67,4.67,0,0,0-4-2A4.37,4.37,0,0,0,9,4Z"></path></svg>
                                    </td>
                                    <td className={style.rsField}>
                                        <h4>Account Number</h4>
                                    </td>
                                    <td className={style.rsValue}>
                                        <span>{circuitDetails?.acctNum}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td className={style.rsIco}>
                                        <svg className="ico size-s"><path d="M36,2.5v31H0V2.5Zm-2,2H2v27H34ZM9,30.5H4v-2H9Zm-1-9H4V6.5H32v15H28v4H24v4H12v-4H8Zm19,7h5v2H27Z"></path></svg>
                                    </td>
                                    <td className={style.rsField}>
                                        <h4>Bandwidth</h4>
                                    </td>
                                    <td className={style.rsValue}>
                                        <span>{circuitDetails?.bw}</span>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>

                    <div className={style.rowGrp}>
                        <h2 className="alt-hdr">Location A</h2>
                        <div className="circuit-summary-addr f18">{circuitDetails.aloc}</div>
                        <div className={style.rowGrpContent}>
                            <table className={` table ${style.recordSetMini} `}>
                                {locADetails?.clli !== " " &&  locADetails?.clli?.length > 0 ?
                                <tr>
                                    <td className={style.rsIco}>
                                        <svg className="ico size-s"><path d="M30.5,24.5v-13h5v13Zm3-11h-1v9h1Zm-9-2h5v13h-5Zm2,11h1v-9h-1Zm-8-11h5v13h-5Zm2,11h1v-9h-1Zm-8-11h5v13h-5Zm2,11h1v-9h-1Zm-8-11h5v13h-5Zm2,11h1v-9h-1Zm-8-11h5v13H.5Zm2,11h1v-9h-1Z"></path></svg>
                                    </td>
                                    <td className={style.rsField}>
                                        <h4>CLLI</h4>
                                    </td>
                                    <td className={style.rsValue}>
                                        <span>{locADetails?.clli}</span>
                                    </td>
                                </tr> : null}
                                {locADetails?.cfa1 !== " " && locADetails?.cfa1?.length > 0 ?
                                <tr>
                                    <td className={style.rsIco}>
                                        <svg className="ico size-s"><path d="M30,35V14H28V33H8V3H21l7,7H21V6H19v6H30V10L21,1H6V35Z"></path></svg>
                                    </td>
                                    <td className={style.rsField}>
                                        <h4>CFA</h4>
                                    </td> 
                                    <td className={style.rsValue}>
                                        <span>{locADetails?.cfa1}</span>
                                    </td>
                                </tr> : null}
                                {locADetails?.cfa2 !== " " && locADetails?.cfa2?.length > 0 ?
                                <tr>
                                    <td className={style.rsIco}>
                                        <svg className="ico size-s"><path d="M30,35V14H28V33H8V3H21l7,7H21V6H19v6H30V10L21,1H6V35Z"></path></svg>
                                    </td>
                                    <td className={style.rsField}>
                                        <h4>CFA</h4>
                                    </td>
                                    <td className={style.rsValue}>
                                        <span>{locADetails?.cfa2}</span>
                                    </td>
                                </tr> : null}
                                {locADetails?.vendor !== " " && locADetails?.vendor?.length > 0 ?
                                <tr>
                                    <td className={style.rsIco}>
                                        <svg className="ico size-s"><path d="M1.5,36V3h3V0h14V3h3v7h13V36Zm19-32h-3V1H5.5V4h-3V34h7V29h4v5h7Zm13,7h-12V34h12Zm-8,6h-2V15h2Zm0,5h-2V20h2Zm0,5h-2V25h2Zm0,5h-2V30h2Zm3-15h-2V15h2Zm0,5h-2V20h2Zm0,5h-2V25h2Zm0,5h-2V30h2Zm3-15h-2V15h2Zm0,5h-2V20h2Zm0,5h-2V25h2Zm0,5h-2V30h2Zm-18-9h1v3h-1Zm4-11h-2V9h2Zm0,7h-2V16h2Zm0,7h-2V23h2Zm0,6h-2V29h2Zm-4-16h1v3h-1Zm0-7h1v3h-1Zm-5,14h1v3h-1Zm0-7h1v3h-1Zm0-7h1v3h-1Zm-3,20h2v3h-2Zm0-6h2v3h-2Zm0-7h2v3h-2Zm0-7h2v3h-2Z"></path></svg>
                                    </td>
                                    <td className={style.rsField}>
                                        <h4>Vendor</h4>
                                    </td>
                                    <td className={style.rsValue}>
                                        <span>{locADetails?.vendor}</span>
                                    </td>
                                </tr> : null}
                            </table>
                        </div>
                    </div>

                    <div className={style.rowGrp}>
                        <h2 className="alt-hdr">Location Z</h2>
                        <div className="circuit-summary-addr f18">{circuitDetails.zloc}</div>

                        <div className={style.rowGrpContent}>
                            <table className={` table ${style.recordSetMini} `}>
                                {locZDetails?.clli !== " " && locZDetails?.clli?.length > 0 ?
                                <tr>
                                    <td className={style.rsIco}>
                                        <svg className="ico size-s"><path d="M30.5,24.5v-13h5v13Zm3-11h-1v9h1Zm-9-2h5v13h-5Zm2,11h1v-9h-1Zm-8-11h5v13h-5Zm2,11h1v-9h-1Zm-8-11h5v13h-5Zm2,11h1v-9h-1Zm-8-11h5v13h-5Zm2,11h1v-9h-1Zm-8-11h5v13H.5Zm2,11h1v-9h-1Z"></path></svg>
                                    </td>
                                    <td className={style.rsField}>
                                        <h4>CLLI</h4>
                                    </td>
                                    <td className={style.rsValue}>
                                        <span>{locZDetails?.clli}</span>
                                    </td>
                                </tr>: null}
                                {locZDetails?.cfa1 !== " " && locZDetails?.cfa1?.length > 0 ?
                                <tr>
                                    <td className={style.rsIco}>
                                        <svg className="ico size-s"><path d="M30,35V14H28V33H8V3H21l7,7H21V6H19v6H30V10L21,1H6V35Z"></path></svg>
                                    </td>
                                    <td className={style.rsField}>
                                        <h4>CFA</h4>
                                    </td>
                                    <td className={style.rsValue}>
                                        <span>{locZDetails?.cfa1}</span>
                                    </td>
                                </tr> : null }
                               {locZDetails?.cfa2 !== " " && locZDetails?.cfa2?.length > 0 ?
                                <tr>
                                    <td className={style.rsIco}>
                                        <svg className="ico size-s"><path d="M30,35V14H28V33H8V3H21l7,7H21V6H19v6H30V10L21,1H6V35Z"></path></svg>
                                    </td>
                                    <td className={style.rsField}>
                                        <h4>CFA</h4>
                                    </td>
                                    <td className={style.rsValue}>
                                        <span>{locZDetails?.cfa2}</span>
                                    </td>
                                </tr> : null}
                                 
                                {locZDetails?.vendor !== " " && locZDetails?.vendor?.length > 0 ?
                                <tr>
                                    <td className={style.rsIco}>
                                        <svg className="ico size-s"><path d="M1.5,36V3h3V0h14V3h3v7h13V36Zm19-32h-3V1H5.5V4h-3V34h7V29h4v5h7Zm13,7h-12V34h12Zm-8,6h-2V15h2Zm0,5h-2V20h2Zm0,5h-2V25h2Zm0,5h-2V30h2Zm3-15h-2V15h2Zm0,5h-2V20h2Zm0,5h-2V25h2Zm0,5h-2V30h2Zm3-15h-2V15h2Zm0,5h-2V20h2Zm0,5h-2V25h2Zm0,5h-2V30h2Zm-18-9h1v3h-1Zm4-11h-2V9h2Zm0,7h-2V16h2Zm0,7h-2V23h2Zm0,6h-2V29h2Zm-4-16h1v3h-1Zm0-7h1v3h-1Zm-5,14h1v3h-1Zm0-7h1v3h-1Zm0-7h1v3h-1Zm-3,20h2v3h-2Zm0-6h2v3h-2Zm0-7h2v3h-2Zm0-7h2v3h-2Z"></path></svg>
                                    </td>
                                    <td className={style.rsField}>
                                        <h4>Vendor</h4>
                                    </td>
                                    <td className={style.rsValue}>
                                        <span>{locZDetails?.vendor}</span>
                                    </td>
                                </tr> : null}
                            </table>
                        </div>
                    </div>
                </div>
            </div>      
        </>
    )
}
export default SummaryContent;
