import style from "./Component.module.css";

export const InputComponent = ({ callback, label, disabled, value, name, className, error }) => {
    const inputHandle = (e) => {
        callback(e)
    }
    return (
        <div className={`my-3 ${className}`}>
            <label className='labelText'> {label} <span className={` ${style.mandatory} `}>*</span></label>
            <input type="text" name={name} className={`${style.inputFldText} w-100`} value={value} disabled={disabled} onChange={(e) => { inputHandle(e) }} />
            <p className={style.errorMsg}>{error}</p>
        </div>
    )
}
