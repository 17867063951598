import {useEffect, useState, useMemo} from 'react';
import {Link} from 'react-router-dom';
import Loader from "../shared/Loader";
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, {textFilter} from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import XLSXExport from "../../utils/XLSXExport";
import SummaryData from '../../services/CircuitSummaryService';
import {ConsoleLogger} from "../../logger/ConsoleLogger";
import HTTPErrorHandler from "../errorPages/HTTPErrorHandler";

const ticket_options = [{
    name: '30 days',
    value: '30',
}, {
    name: '60 days',
    value: '60',
}, {
    name: '90 days',
    value: '90',
}, {
    name: '180 days',
    value: '180',
}, {
    name: '365 days',
    value: '365'
}];

const columns = [{
    dataField: 'incidentNumber',
    text: 'Incident Number',
    sort: true,
    headerAttrs: { id: 'incidentNumber' },
    formatter: (cell, row) => incidentNumberFormatter(cell, row),
    filter: textFilter({
        placeholder: 'Incident Number'
    }),
},
{
    dataField: 'customerTicket',
    text: 'Customer Ticket #',
    sort: true,
    filter: textFilter({
        placeholder: 'Customer Ticket #'
    }),
    headerAttrs: { id: 'customerTicket' }
},
{
    dataField: 'status',
    text: 'Status',
    sort: true,
    filter: textFilter({
        placeholder: 'Status'
    }),
    headerAttrs: { id: 'status' }
},
{
    dataField: 'tier3',
    text: 'Trouble Type',
    sort: true,
    filter: textFilter({
        placeholder: 'Trouble Type'
    }),
    headerAttrs: { id: 'incidentType' }
},
{
    dataField: 'openDate',
    text: 'Submit Date',
    sort: true,
    filter: textFilter({
        placeholder: 'Submit Date'
    }),
    headerAttrs: { id: 'openDate' }
},
{
    dataField: 'lastMod',
    text: 'Close Date',
    sort: true,
    filter: textFilter({
        placeholder: 'Close Date'
    }),
    headerAttrs: { id: 'lastMod' }
}
];

const incidentNumberFormatter = (props, row) => {
    const URL = `/Network/TicketDetails/${row.incidentNumber}`;
    return (
        <Link to={URL}> {row.incidentNumber}</Link>
    );
}


const SummaryTickets = (props) => {
    const logger = useMemo(() => new ConsoleLogger({ level: process.env.REACT_APP_LOGGER_LEVEL, }),[]);
    const [hasError, setHasError] = useState(null);
    const [troubleTicketsData, setTroubleTicketsData] = useState([]);
    const [ticketDate, setTicketDate] = useState("30");
    const [loader, setLoader] = useState(false);
    const [loadingFlag, setLoadingFlag] = useState(true);
    const [exporCSV, setExportCSV] = useState();
    let dateObj = new Date();
    let month = dateObj.getUTCMonth() + 1; //months from 1-12
    let day = dateObj.getUTCDate();
    let year = dateObj.getUTCFullYear();
    const fullDateNow = year + "" + month + "" + day;

    useEffect(() => {
        setLoader(true)
        setLoadingFlag(true);
        SummaryData.getTicketHistory(props.circuit_id, ticketDate).then(response => {
            logger.info(JSON.stringify(response.data));
            //Sorting ticket data to display any ticket not in Closed status first
			const sortedTicketData = response.data.sort((a, b) => {
				if (a.status === "Closed" && b.status !== "Closed") {
					return 1;
				} else {
					return -1;
				}
			});
            setTroubleTicketsData(sortedTicketData);
            setLoader(false);
            setLoadingFlag(false);
            exportForCSV(sortedTicketData);
        }).catch((error) => {
            logger.error("getTroubleTickets ERROR: " + error)
            setHasError(error);
        });
    }, [logger, props.circuit_id, ticketDate])

    //Formatting data for export
    const exportForCSV = (csvData) => {
        const csvArray = csvData.map(ticket => ({
            'Incident Number': ticket.incidentNumber,
            'Customer Ticket #': ticket.customerTicket,
            'Status': ticket.status,
            'Trouble Type': ticket.tier3,
            'Submit Date': ticket.openDate,
            'Close Date': ticket.lastMod
        }));
        setExportCSV(csvArray);
    }

    return (
        <>
            <div className="sub-hdr">
                <div className="nib">
                    <div className="the-notch">
                        <div className="notch-fg">
                            <svg>
                                <path
                                    d="M35.81,26.28l-13,10v-5C-10.47,28.68-1,9.16,11.79,0c0,7.28,0,9.13,0,13.28s3.18,7.92,11,9v-5Z"></path>
                            </svg>
                        </div>
                    </div>
                </div>
                <h2>Ticket History</h2>

            </div>

            {loader ? <Loader /> : null}

            <div className="text-right mb-1">
                <button className="saveButton ico-button light-button" onClick={() => XLSXExport.exportToExcel("",exporCSV,`ticketDetails_${props.circuit_id}_${fullDateNow}`)}>
                    <span><strong>Export</strong></span>
                    <svg viewBox="0 0 34 34" className={`ico light-ico ico-arrow downloadArrow`}>
                        <path className="cls-1" d="M1,18V16L17,31V1h2V31L35,16v2L20,33H35v2H1V33H16Z"
                            transform="translate(-1 -1)">
                        </path>
                    </svg>
                </button>
            </div>
            <select className="nice-select right mb-4" onChange={(event) =>  setTicketDate(event.target.value)} value={ticketDate}>
                {ticket_options.map(item => (
                    <option key={item.value} value={item.value}>
                        {item.name}
                    </option>
                ))}
            </select>

            {hasError && (
                <HTTPErrorHandler error={hasError}></HTTPErrorHandler>
            )}
            {!hasError &&
                <div id="tickets-history-wrap" className="wrap">
                    <div className="section TroubleTicket-page">
                        <div className="content">
                            {loadingFlag ?
                                <p>Loading Data...</p> :
                                <div
                                    className={`rel table-style-wrap pagination-table page-list mb-3 troubleTicketTable troubleTicketHistoryTable`}>
                                    {troubleTicketsData?.length > 0 ?
                                        <>
                                            <BootstrapTable id="troubleTicket"
                                                keyField='id'
                                                data={troubleTicketsData}
                                                columns={columns}
                                                hover
                                                condensed
                                                bordered={false}
                                                pagination={paginationFactory()}
                                                filter={filterFactory()}
                                                defaultSortDirection="desc"
                                                className="mb-0"
                                            />
                                        </>
                                        :
                                        <p>There is no trouble ticket data available in the last {ticketDate} days</p>
                                    }
                                </div>
                            }
                        </div>
                    </div>
                </div>
            }

        </>

    );
}

export default SummaryTickets;
