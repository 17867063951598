import axios from "./axios/customAxios";

const getNNILocation = async () => {
	const nniLocationURL = `${process.env.REACT_APP_API_URL}/getAddress.php?type=mpls_nni_list`;
    return await axios.get(nniLocationURL);
}

const getNNIBW = async () =>{
	const nniBWUrl = `${process.env.REACT_APP_API_URL}/getBandwidths.php?product=7&menu=bw`;
    return await axios.get(nniBWUrl);
}


const MplsNNIHelper = {
    getNNILocation,
    getNNIBW
}

export default MplsNNIHelper;
