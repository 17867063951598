/**
 * Created by e0173131 on 1/20/2021.
 */


/**
 * JS Imports
 *
 */

// React Core Libraries
import React from 'react';

// Local JS Files Imports
import ViewportWarning from "../shared/ViewportWarning";
import UserInfoSection from './UserInfoSection';

const UserInfo = () => {
    return (
        <div className="midsection qm-upload abs z1">
            <div className="rel">
                <ViewportWarning />
                <div className="wrap">
                    <h1 className="page-title">User Information</h1>
                    <UserInfoSection></UserInfoSection>
                </div>
            </div>
        </div>
    );
}

export default UserInfo;
