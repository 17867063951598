import {
	LOC_A_ADDRESS,
	LOC_A_CITY,
	LOC_A_EACCESS_VALUES,
	LOC_A_FLOOR_NUM,
	LOC_A_STATE,
	LOC_A_STRUCT,
	LOC_A_STRUCT_NUM,
	LOC_A_UNIT,
	LOC_A_UNIT_NUM,
	LOC_A_VALID_ADDRESS,
	LOC_A_ZIP,
	LOC_A_OVERRIDE,
	LOC_A_LATITUDE,
	LOC_A_LONGITUDE,
	QUOTE_A_BW_DISPLAY,
	QUOTE_A_BW_DISPLAY_FLAG,
	LOC_A_LEC_TYPE,
	RESET_ACTION
} from "../actions/types/actionTypes"

const initialState = {
	locA: {
		a_add: "",
		a_struct: "",
		a_structNum: "",
		a_unit: "",
		a_unitNum: "",
		a_floorNum: "",
		a_city: "",
		a_state: "",
		a_zip: "",
		a_override: false,
		a_lat: "",
		a_long: "",
		a_validationFlag: false,
		a_lec_type: "",
		eaccessValidation: {}
	},

	locAWaveBw: {},

	locAWaveBwFlag: false

};

const quoteLocAReducer = (state = initialState, action) => {
	switch (action.type) {
		case LOC_A_ADDRESS:
			return {
				...state,
				locA: {
					...state.locA,
					a_add: action.payload
				}
			}
		case LOC_A_STRUCT:
			return {
				...state,
				locA: {
					...state.locA,
					a_struct: action.payload
				}
			}
		case LOC_A_STRUCT_NUM:
			return {
				...state,
				locA: {
					...state.locA,
					a_structNum: action.payload
				}
			}
		case LOC_A_FLOOR_NUM:
			return {
				...state,
				locA: {
					...state.locA,
					a_floorNum: action.payload
				}
			}
		case LOC_A_UNIT:
			return {
				...state,
				locA: {
					...state.locA,
					a_unit: action.payload
				}
			}
		case LOC_A_UNIT_NUM:
			return {
				...state,
				locA: {
					...state.locA,
					a_unitNum: action.payload
				}
			}
		case LOC_A_CITY:
			return {
				...state,
				locA: {
					...state.locA,
					a_city: action.payload
				}
			}
		case LOC_A_STATE:
			return {
				...state,
				locA: {
					...state.locA,
					a_state: action.payload
				}
			}
		case LOC_A_ZIP:
			return {
				...state,
				locA: {
					...state.locA,
					a_zip: action.payload
				}
			}
		case LOC_A_OVERRIDE:
			return {
				...state,
				locA: {
					...state.locA,
					a_override: action.payload
				}
			}
		case LOC_A_LATITUDE:
			return {
				...state,
				locA: {
					...state.locA,
					a_lat: action.payload
				}
			}
		case LOC_A_LONGITUDE:
			return {
				...state,
				locA: {
					...state.locA,
					a_long: action.payload
				}
			}
		case LOC_A_VALID_ADDRESS:
			return {
				...state,
				locA: {
					...state.locA,
					a_validationFlag: action.payload
				}
			}
		case LOC_A_LEC_TYPE:
			return {
				...state,
				locA: {
					...state.locA,
					a_lec_type: action.payload
				}
			}
		case LOC_A_EACCESS_VALUES:
			return {
				...state,
				locA: {
					...state.locA,
					eaccessValidation: action.payload
				}
			}
		case QUOTE_A_BW_DISPLAY:
			return {
				...state,
				locAWaveBw: action.payload
			}
		case QUOTE_A_BW_DISPLAY_FLAG:
			return {
				...state,
				locAWaveBwFlag: action.payload
			}
		case RESET_ACTION:
			return initialState
		default:
			return state

	}
}

export default quoteLocAReducer;
