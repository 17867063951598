import {useEffect, useState} from 'react';
import style from "./TicketCreate.module.css";
import {connect, useSelector} from 'react-redux';
import LabelComponent from './LabelComponent';
import CheckboxComponent from './CheckboxComponent';

import {
	tcErrorMsg,
	tcPContact,
	tcPContactEmail,
	tcPContactId,
	tcPContactPhone,
	tcPContactPhoneExt,
	tcPPhoneType,
	tcPUpdatedEmail
} from '../../redux';
import {ConsoleLogger} from "../../logger/ConsoleLogger";
import Select from 'react-select';

const TicketCreatePrimaryContact = (props) => {
	const logger = new ConsoleLogger({ level: process.env.REACT_APP_LOGGER_LEVEL, });
	const [createNewContactFlag, setCreateNewContactFlag] = useState(false);
	const contactList = props.contactListData;
	const troubleContactPrimaryControls = props.primaryDetailsData;
	const primaryDetailsFromStore = useSelector(state => state?.ticketCreate?.primaryDetails);
	const contactListFromStore = useSelector(state => state?.ticketCreate?.contactList);

	useEffect(() => {
		if (primaryDetailsFromStore.contactId?.value === "new") {
			setCreateNewContactFlag(true);
		}
	}, [primaryDetailsFromStore.contactId])

	const inputChange = (obj, event) => {
		const value = obj.type === 'checkbox' ? event.target.checked : event.target.value;
		props[obj.name](value);
		props.updateErrorMsg('');
	}

	const selectInputChange = (event) => {
		let contactVal = "";
		if (!event) {
			contactVal = ""
		} else {
			contactVal = event;
		}

		logger.info(`value: ${contactVal}`);
		if (createNewContactFlag === false && contactVal?.value !== "new") {
			autoFillContact(contactVal);
		} else {
			setCreateNewContactFlag(true);
			props.contact("");
			props.contactPhone("");
			props.contactPhoneExt("");
			props.contactEmail("");
			props.contactId(contactVal);
		}
		props.updateErrorMsg('');
	}

	//Autofills contact info and adds it to the redux store
	const autoFillContact = (contactId) => {
		const index = contactListFromStore.findIndex((obj) => obj.id === contactId?.value);
		logger.info(`index of contact in store: ${index}`);
		props.contactId(contactId);
		props.contact(contactListFromStore[index].name ? contactListFromStore[index].name : "");
		props.contactPhone(contactListFromStore[index].phone ? contactListFromStore[index].phone?.replace(/\D/g, '').slice(0, 10) : '');
		props.contactPhoneExt(contactListFromStore[index].phone_Extension__c ? contactListFromStore[index].phone_Extension__c : '');
		props.contactEmail(contactListFromStore[index].email ? contactListFromStore[index].email : '');
	}

	//Updates the contact name
	const updateContactName = (value, type) => {
		let firstName = primaryDetailsFromStore['contact'].indexOf(" ") !== -1 ? primaryDetailsFromStore['contact']?.substring(0, primaryDetailsFromStore['contact']?.indexOf(" ")) : primaryDetailsFromStore['contact'];
		let lastName  = primaryDetailsFromStore['contact'].indexOf(" ") !== -1 ? primaryDetailsFromStore['contact']?.substring(primaryDetailsFromStore['contact']?.indexOf(" ") + 1) : "";

		if(type === 'firstName'){
			props.contact(value.trim() + " " + lastName.trim());
		} else {
			props.contact(firstName.trim() + " " + value.trim());
		}
	}


	return (
		<>
			{troubleContactPrimaryControls && troubleContactPrimaryControls.length > 0 ?
				<>
					<div className="row align-items-center">
						<div className="col-12 col-sm-6 col-md-4">
							<LabelComponent obj={troubleContactPrimaryControls[0]} />
						</div>
						<div className="col-12 col-sm-6 col-md-8">
							{createNewContactFlag ?
								<div className="row align-items-center">
									<div className="col-12">
										<input type="text" className={`${style.inputFldText} `} onChange={(event) => { inputChange(troubleContactPrimaryControls[0], event) }} value={primaryDetailsFromStore['contact']} />
									</div>
								</div> :
								<Select
									onChange={(event) => selectInputChange(event)}
									value={primaryDetailsFromStore['contactId'] ? primaryDetailsFromStore['contactId'] : null}
									options={contactList.map((item) => {
										return { value: item.id, label: item.name }
									})}
									placeholder={'Search for a Contact...'}
									styles={{
										placeholder: (base) => ({
											...base,
											fontSize: '14px',
										})
									}}
									isSearchable
									noOptionsMessage={() => 'Loading Contacts...'}
								/>

							}
						</div>
					</div>

					{primaryDetailsFromStore['contactId'] !== "" ?
						<>
							<div className="row align-items-center mt-3">
								<div className="col-12 col-sm-6 col-md-4">
									<label htmlFor="firstName" className={`d-block text-uppercase mb-0 ${style.labelText}`}>First Name<span className={style.mandatory}> *</span></label>
								</div>
								<div className="col-12 col-sm-6 col-md-8">
									<div className="row align-items-center">
										<div className="col-12">
											<input type="text" className={`${style.inputFldText} `} onChange={(event) => updateContactName(event.target.value, 'firstName')} value={primaryDetailsFromStore['contact'].indexOf(" ") !== -1 ? primaryDetailsFromStore['contact']?.substring(0, primaryDetailsFromStore['contact']?.indexOf(" ")) : primaryDetailsFromStore['contact']}/>
										</div>
									</div>
								</div>
							</div>

							<div className="row align-items-center mt-3">
								<div className="col-12 col-sm-6 col-md-4">
									<label htmlFor="lastName" className={`d-block text-uppercase mb-0 ${style.labelText}`}>Last Name<span className={style.mandatory}> *</span></label>
								</div>
								<div className="col-12 col-sm-6 col-md-8">
									<div className="row align-items-center">
										<div className="col-12">
											<input type="text" className={`${style.inputFldText} `} onChange={(event) => updateContactName(event.target.value, 'lastName')} value={primaryDetailsFromStore['contact'].indexOf(" ") !== -1 ? primaryDetailsFromStore['contact']?.substring(primaryDetailsFromStore['contact']?.indexOf(" ") + 1) : ""}/>
										</div>
									</div>
								</div>
							</div>
						</> : null
					}

					<div className="row align-items-center mt-3">
						<div className="col-12 col-sm-6 col-md-4">
							<LabelComponent obj={troubleContactPrimaryControls[1]} />
						</div>
						<div className="col-12 col-sm-6 col-md-8">
							<div className="row align-items-center">
								<div className="col-8">
									<input type="text" className={`${style.inputFldText}`} maxLength="10" onChange={(event) => { inputChange(troubleContactPrimaryControls[1], event) }} value={primaryDetailsFromStore['contactPhone']} />
								</div>
								<div className="col-4 pl-0">
									<span className={` ${style.labelText} ${style.extField} textuppercase`}>ext</span>
									<input type="text" className={` pl-1  ${style.inputFldText} ${style.widthLess25px}`} onChange={(event) => { inputChange(troubleContactPrimaryControls[2], event) }} value={primaryDetailsFromStore['contactPhoneExt']} />
								</div>
							</div>

						</div>
					</div>

					<div className="row align-items-center mt-3">
						<div className="col-12 col-sm-6 col-md-4">
							<LabelComponent obj={troubleContactPrimaryControls[3]} />
						</div>
						<div className="col-12 col-sm-6 col-md-8">
								<select className={`${style.selectFld} `} onChange={(event) => { inputChange(troubleContactPrimaryControls[3], event) }} value={primaryDetailsFromStore['phoneType']}>
									<option>Select</option>
									<option value="cell">Cell Phone</option>
									<option value="business">Business Phone</option>
								</select>
						</div>
					</div>

					<CheckboxComponent obj={troubleContactPrimaryControls[4]} val={primaryDetailsFromStore['receiveUpdateEmail']} checkboxEmit={(event) => { inputChange(troubleContactPrimaryControls[4], event) }} />

					<div className="row  align-items-center">
						<div className="col-12 col-sm-6 col-md-4">
							<LabelComponent obj={troubleContactPrimaryControls[5]} />
						</div>
						<div className="col-12 col-sm-6 col-md-8">
							<input type="text" className={` ${style.inputFldText} `} onChange={(event) => { inputChange(troubleContactPrimaryControls[5], event) }} value={primaryDetailsFromStore['contactEmail']} />
						</div>
					</div>
				</> : null
			}

		</>
	)
}

const mapDispatchToProps = {
	contact: tcPContact,
	contactPhone: tcPContactPhone,
	contactPhoneExt: tcPContactPhoneExt,
	phoneType: tcPPhoneType,
	receiveUpdateEmail: tcPUpdatedEmail,
	contactEmail: tcPContactEmail,
	contactId: tcPContactId,
	updateErrorMsg: tcErrorMsg
}

export default connect(null, mapDispatchToProps)(TicketCreatePrimaryContact);
