import {useState} from "react"
import Select from "react-select";
import style from "../Employee.module.css";
import CancelModal from "./CancelModal";
import {SelectComponent} from "../../components/SelectComponent";
import {InputComponent} from "../../components/InputComponent";

const EditUser = () => {
    const initialForm = {
        employeeId: '', firstName: '', lastName: '', email: '', phoneNumber: '', status: '',
        webUserAdministration: '', troubleTickets: '', transportQuote: '', transportOrder: '', invoiceSystem: '', roleType: ''
    };

    const [formValues, setFormValues] = useState(initialForm);
    const [formErrors, setFormErrors] = useState({});
    const [cancelWindow, setCancelWindow] = useState(false);
    const [employeeID, setEmployeeID] = useState({ value: '', label: '' });

    const userDetails = [
        {
            "id": 1,
            "employeeId": "n9894860",
            "firstName": "Raj",
            "lastName": "A",
            "email": "n9894860@windstream.com",
            "phoneNumber": "+918608672249",
            "webUserAdministration": "1",
            "troubleTickets": "2",
            "transportQuote": "3",
            "transportOrder": "4",
            "invoiceSystem": "2",
            "roleType": "1",
            "status": "InActive"
        },
        {
            "id": 2,
            "employeeId": "e0155921",
            "firstName": "Steven",
            "lastName": "Kurek",
            "email": "steven.kurek@windstream.com",
            "phoneNumber": "+918608672249",
            "webUserAdministration": "3",
            "troubleTickets": "1",
            "transportQuote": "2",
            "transportOrder": "3",
            "invoiceSystem": "1",
            "roleType": "3",
            "status": "Active"
        }
    ]

    const editCallback = (event) => {
        const { name, value } = event.target;
        setFormValues({ ...formValues, [name]: value });
    }

    const radioBtnAction = (event) => {
        const { name, value } = event.target;
        setFormValues({ ...formValues, [name]: value });
    }

    const employeIDHandle = (event) => {
        const findUser = userDetails.find(user => user.employeeId === event.value);
        setFormValues(findUser);
        setEmployeeID({value: findUser.employeeId, label: findUser.employeeId});
    }

    const submitAction = () => {
        const validation = ValidationRule();
        if (Object.keys(validation).length === 0) {
            console.log(formValues);
        }
    }

    const ValidationRule = () => {
        const error = {};
        if (!formValues.employeeId) {
            error.employeeId = "Employee ID is Required";
        }
        if (!formValues.firstName) {
            error.firstName = "First Name is Required";
        }
        if (!formValues.lastName) {
            error.lastName = "Last Name is Required";
        }
        if (!formValues.email) {
            error.email = "Email is Required";
        }
        if (!formValues.phoneNumber) {
            error.phoneNumber = "Phone Number is Required";
        }
        if (!formValues.webUserAdministration) {
            error.webUserAdministration = "Web User Administration is Required";
        }
        if (!formValues.troubleTickets) {
            error.troubleTickets = "Trouble Tickets are is Required";
        }
        if (!formValues.transportQuote) {
            error.transportQuote = "Transport Quote is Required";
        }
        if (!formValues.transportOrder) {
            error.transportOrder = "Transport Order is Required";
        }
        if (!formValues.invoiceSystem) {
            error.invoiceSystem = "Invoice System is Required";
        }
        if (!formValues.roleType) {
            error.roleType = "Role Type is Required";
        }
        if (!formValues.status) {
            error.status = "Status is Required";
        }
        setFormErrors(error);
        return error;

    }

    const cancelAction = () => {
        setCancelWindow(true);
    }

    const cancelEmit = (action) => {
        if (action === 'Ok') {
            setCancelWindow(false);
            setFormValues(initialForm);
            setFormErrors({});
            setEmployeeID({value: '', label: ''});
        } else {
            setCancelWindow(false);
        }
    }

    return (
        <div className="EditUserPage">
            <p className="text-secondary text-right mb-5"><span className={style.mandatory}>*</span> Denotes a required field</p>
            <div className="row">
                <div className="col-md-5 col-sm-12">
                    <div className={`${style.selectWrap} w-100`}>
                        <label className='labelText'> Employee Id <span className={` ${style.mandatory} `}>*</span></label>
                        <div className="rel">
                            <Select
                                value={employeeID}
                                defaultValue={employeeID}
                                placeholder={''}
                                onChange={(event) => { employeIDHandle(event) }}
                                options={userDetails.map((item) => (
                                    { value: item.employeeId, label: item.employeeId }
                                ))}
                                isSearchable
                                isClearable
                                className={`autoCompleteStyle`}
                                classNamePrefix='profile-autoComplete'
                                noOptionsMessage={'No Profile Found'} />
                            <p className={style.errorMsg}>{formErrors.employeeId}</p>
                        </div>
                    </div>
                    <div className="d-flex justify-content-between">
                        <InputComponent label='First Name' name="firstName" className={style.w45} value={formValues.firstName} error={formErrors.firstName} callback={editCallback} />
                        <InputComponent label='Last Name' name="lastName" className={style.w45} value={formValues.lastName} error={formErrors.lastName} callback={editCallback} />
                    </div>
                    <InputComponent label='Email' name="email" className="w-100" value={formValues.email} error={formErrors.email} callback={editCallback} />
                    <InputComponent label='Phone Number' name="phoneNumber" className="w-100" value={formValues.phoneNumber} error={formErrors.phoneNumber} callback={editCallback} />

                    <div className="my-5">
                        <label className="mr-5">Status <span className={` ${style.mandatory} `}>*</span></label>
                        <input type="radio" name="status" id="active" value='Active' checked={formValues.status === 'Active' ? 'checked' : ''} onChange={(event) => { radioBtnAction(event) }}></input>
                        <label className="mr-4 text-secondary" htmlFor="active">Active</label>

                        <input type="radio" name="status" id="inActive" value='InActive' checked={formValues.status === 'InActive' ? 'checked' : ''} onChange={(event) => { radioBtnAction(event) }}></input>
                        <label className="text-secondary" htmlFor="inActive">InActive</label>
                        <p className={style.errorMsg}>{formErrors.status}</p>
                    </div>
                </div>

                <div className="col-md-7 col-sm-12">
                    <label className="d-block w-100 text-center mb-5">Web Access</label>
                    <div className="d-flex justify-content-between ml-md-5 ml-sm-0">
                        <SelectComponent label="Web User Administration" name="webUserAdministration" className={style.w45} value={formValues.webUserAdministration} options={['1', '2', '3', '4']} error={formErrors.webUserAdministration} callback={editCallback} />
                        <SelectComponent label="Trouble Tickets" name="troubleTickets" className={style.w45} value={formValues.troubleTickets} options={['1', '2', '3', '4']} error={formErrors.troubleTickets} callback={editCallback} />
                    </div>
                    <div className="d-flex justify-content-between ml-md-5 ml-sm-0">
                        <SelectComponent label="Transport Quote" name="transportQuote" className={style.w45} value={formValues.transportQuote} options={['1', '2', '3', '4']} error={formErrors.transportQuote} callback={editCallback} />
                        <SelectComponent label="Transport Order" name="transportOrder" className={style.w45} value={formValues.transportOrder} options={['1', '2', '3', '4']} error={formErrors.transportOrder} callback={editCallback} />
                    </div>
                    <div className="d-flex justify-content-between ml-md-5 ml-sm-0">
                        <SelectComponent label="Invoice System" name="invoiceSystem" className={style.w45} value={formValues.invoiceSystem} options={['1', '2', '3', '4']} error={formErrors.invoiceSystem} callback={editCallback} />
                        <SelectComponent label="Role Type" name="roleType" className={style.w45} value={formValues.roleType} options={['1', '2', '3', '4']} error={formErrors.roleType} callback={editCallback} />
                    </div>
                </div>
            </div>

            <div className="text-center my-5">
                <button type="button" className={`ico-button lhs light-button ml-3 ${style.cancelBtn} `} onClick={() => { cancelAction() }} >
                    Cancel
                    <svg className="ico-x">
                        <path
                            d="M18,17l-1,1L9,10,1,18,0,17,8,9,0,1,1,0,9,8l8-8,1,1L10,9Z"></path>
                    </svg>
                </button>

                <button type="button" className={`ico-button lhs light-button ml-3 ${style.nextBtn} `} onClick={() => { submitAction() }} >
                    Submit
                    <svg className="ico light-ico ico-arrow ml-2">
                        <path d="M0,17H29L15,31l1,1L32,16,16,0,15,1,29,15H0Z"></path>
                    </svg>
                </button>
            </div>
            {cancelWindow ? <CancelModal cancelEmit={cancelEmit} /> : null}
        </div>
    )
}

export default EditUser;