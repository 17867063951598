import { useState, useEffect, useMemo} from "react";
import style from "../QuoteCreate.module.css";
import { ConsoleLogger } from "../../../logger/ConsoleLogger";
import { ReactGrid } from "@silevis/reactgrid";
import "@silevis/reactgrid/styles.css";
import XLSXExport from "../../../utils/XLSXExport";
import { Tooltip } from "react-tippy";
import BulkHelper from "../../../services/BulkServices";
import { Redirect } from "react-router-dom";
import Loader from "../../shared/Loader";

//Custom Cell Template for Header row to add tooltip
const TooltipHeaderCellTemplate = {
    getCompatibleCell(uncertainCell) {
        const text = uncertainCell.text || '';
        return { ...uncertainCell, text };
    },

    render(cell) {
        return (
            <>
                {(cell?.tooltip?.length > 0) ?
                    <th style={{ cursor: 'default' }}>
                        {cell.text}
                        <Tooltip html={(<span style={{ whiteSpace: 'pre-line' }}>{cell.tooltip}</span>)} position="bottom">
                            <img src="../../images/svg/ico-info-filled-dark-off.svg" alt="info tip" style={{ marginLeft: "5px", width: "12px", height: "12px" }} />
                        </Tooltip>
                    </th>
                    :
                    <th style={{ cursor: 'default' }}>
                        {cell.text}
                    </th>
                }
            </>
        );

    }
}

//Custom Cell Template to add a tooltip to the qualify column which show the user the max qualification bandwidth and add a spinner when address is qualifying
const QualifyDescriptionCellTemplate = {
    getCompatibleCell(uncertainCell) {
        const text = uncertainCell.text || '';
        return { ...uncertainCell, text };
    },

    render(cell) {
        return (
            <>
                {(cell?.qualDesc?.length > 0 && cell?.qualDesc !== "") ?
                    <td style={{ cursor: 'default', color: `${cell?.text === "Qualified" ? "#28A745" : "#FF0000"}` }}>
                        {cell.text}
                        <Tooltip html={(<span style={{ whiteSpace: 'pre-line' }}>{cell.qualDesc}</span>)} position="bottom">
                            <img src="../../images/svg/ico-info-filled-dark-off.svg" alt="info tip" style={{ marginLeft: "5px", width: "12px", height: "12px" }} />
                        </Tooltip>
                    </td>
                    :
                    <>
                        {cell?.text === "Qualifying..." ?
                            <td style={{ cursor: 'default' }}>
                                {cell.text} <div className='spinner-border spinner-border-sm ml-1' role='status' style={{ borderColor: '#753bbd', borderRightColor: 'transparent' }} />
                            </td> :

                            <td style={{ cursor: 'default' }}>
                                {cell.text}
                            </td>
                        }
                    </>
                }
            </>
        );

    }
}

const BroadbandBulk = (props) => {
    const logger = useMemo(() => new ConsoleLogger({ level: process.env.REACT_APP_LOGGER_LEVEL, }), []);
    const XLSXHeaders = ["Business Name", "Address", "Address 2 Designation", "Address 2 Value", "City", "State", "Zip", "Modem Rental", "Static IPs","Term", "Requested Bandwidth", "Qualify", "Max Bandwidth"];
    const [addressTryCount, setAddressTryCount] = useState(0);
    const [cellChangesIndex, setCellChangesIndex] = useState(-1);
    const [cellChanges, setCellChanges] = useState([]);
    const [broadbandRows, setBroadbandRows] = useState([])
	const [errorMsg, setErrorMsg] = useState("");
	const [successMsg, setSuccessMsg] = useState("");
	const [bulkId, setBulkId] = useState("");
    const [loader, setLoader] = useState(false);
    //Declaring React Grid Columns
    const [columns, setColumns] = useState([
        { columnId: "businessName", width: 150, resizable: true },
        { columnId: "addressLine1", width: 250, resizable: true },
        { columnId: "unit", width: 175, resizable: true },
        { columnId: "unitNumber", width: 100, resizable: true },
        { columnId: "city", width: 100, resizable: true },
        { columnId: "state", width: 50, resizable: true },
        { columnId: "zip", width: 75, resizable: true },
        { columnId: "modemRental", width: 150, resizable: true },
        { columnId: "staticIps", width: 100, resizable: true },
        //{ columnId: "uqualId", width: 200, resizable: true }, //only internal
        { columnId: "term", width: 75, resizable: true },
        { columnId: "requestedBandwidth", width: 175, resizable: true },
        { columnId: "qualify", width: 150, resizable: true },
        { columnId: "maxBandwidth", width: 150, resizable: true },
    ]);

    const [popupShow, setPopupShow] = useState(false);
	const [popupMsg, setPopupMsg] = useState("");
    const [showSubmitButton, setShowSubmitButton] = useState(false);

    //Creating the header row and calling the custom tooltip header templated created outside fo the BroadbandBulk component
    const headerRow = {
        rowId: "header",
        cells: [
            { type: "header", text: "Business Name" },
            { type: "header", text: "Street Address" },
            { type: "header", text: "Address 2 Designation", tooltip: "If required for this street address \n must be one of the following abbreviations: \n APT - Apartment, RM - Room, LOT - Lot \n SLIP - Slip, SUIT - Suite, or UNIT - Unit \n " },
            { type: "header", text: "Address 2 Value" },
            { type: "header", text: "City" },
            { type: "header", text: "State" },
            { type: "header", text: "ZIP" },
            { type: "header", text: "Modem Rental", tooltip: "Required Either Y or N where: \n Y = Windstream Provided \n N = Customer Provided" },
            { type: "header", text: "Static IPs", tooltip: "Must be one of the following: \n 0 - Dynamic\n 2 - Static IP (1 Usable) \n 6 - Static IPs (5 Usable)" },
            //{ type: "header", text: "UQUAL ID" },
            { type: "header", text: "Term", tooltip: "Optional Field: \n MTM, 1, 2, or 3 \n 3 is default if not set" },
            { type: "header", text: "Requested Bandwidth", tooltip: "Requested Bandwidth must be lower or equal to the Max Bandwidth Qualify" },
            { type: "header", text: "Qualify" },
            { type: "header", text: "Max Bandwidth", tooltip: "Max bandwidth value is determined by the UQUAL qualification" }
        ]
    };

    useEffect(() => {
		logger.info("Inside of createBulkRows");
		const rows = [];
		for (let i = 0; i < props?.rowCount; i++) {
			rows.push({ businessName: "", addressLine1: "", unit: "", unitNumber: "", city: "", state: "", zip: "", modemRental: "", staticIps: "", term: "", uqualId: "", requestedBandwidth: "", bandwidthMenuOpened: false, bandwidthMenu: [], maxBandwidth: "", qualify: "", qualDesc:"" });
		}
		setBroadbandRows(rows);
	}, [logger, props?.rowCount])

    //Creating the rows for the reactgrid and adding the header row at the beginning by calling headerRow
    const getRows = (row) => [
        headerRow,
        ...row.map((obj, idx) => ({
            rowId: idx,
            cells: [
                { type: "text", text: obj.businessName },
                { type: "text", text: obj.addressLine1, nonEditable: obj.qualify === "Qualified" ? true : false  },
                { type: "text", text: obj.unit, nonEditable: obj.qualify === "Qualified" ? true : false },
                { type: "text", text: obj.unitNumber, nonEditable: obj.qualify === "Qualified" ? true : false },
                { type: "text", text: obj.city, nonEditable: obj.qualify === "Qualified" ? true : false },
                { type: "text", text: obj.state, nonEditable: obj.qualify === "Qualified" ? true : false },
                { type: "text", text: obj.zip, nonEditable: obj.qualify === "Qualified" ? true : false },
                { type: "text", text: obj.modemRental },
                { type: "text", text: obj.staticIps },
                //{ type: "text", text: obj.uqualId },
                { type: "text", text: obj.term },
                //{ type: "dropdown", selectedValue: obj.requestedBandwidth, isDisabled: obj.qualify !== "true" ? true : false, values: obj?.bandwidthMenu, isOpen: obj?.bandwidthMenuOpened},
                { type: "text", text: obj.requestedBandwidth },
                { type: "qualify", text: obj.qualify, qualDesc: obj.qualDesc, nonEditable: true },
                { type: "text", text: obj.maxBandwidth, nonEditable: true}
            ]
        }))
    ];

    const rows = getRows(broadbandRows);

    //Handles Column Resize
    const handleColumnResize = (column, width) => {
        logger.info("Handling the column resize: " + column + " " + width);
        setColumns((prevColumns) => {
            const columnIndex = prevColumns.findIndex(el => el.columnId === column);
            const resizedColumn = prevColumns[columnIndex];
            const updatedColumn = { ...resizedColumn, width };
            prevColumns[columnIndex] = updatedColumn;
            return [...prevColumns];
        });
    }


    //Applies changes to the address array
    const applyChangesToRows = (changes, prevRows) => {
        const updated = applyNewValue(changes, prevRows);
        setCellChanges([...cellChanges.slice(0, cellChangesIndex + 1), changes]);
        setCellChangesIndex(cellChangesIndex + 1);
        return updated;
    };

    //Handles any changes that occures in the react grid
    const handleChanges = (changes) => {
        console.log(JSON.stringify(changes));
        setBroadbandRows((prevRows) => applyChangesToRows(changes, prevRows));
    };

    //Handles the context menu which inclued copy, cut, adding rows, or deleting rows (context menu is the one that popups on right click)
    const handleContextMenu = (selectedRowIds, selectedColIds, selectionMode, menuOptions) => {
        if (selectionMode === "row") {
            menuOptions = [
                ...menuOptions, //...menuOptions are the defaulted menu options in the context menu (copy, cut, and paste) and additional options can be added here but will need additional logic to work
                {
                    id: "addRow",
                    label: "Add Row",
                    handler: () => { handleAddRow() }
                },
                {
                    id: "deleteRow",
                    label: "Delete Row",
                    handler: () => {
                        setBroadbandRows(prevRows => {
                            return [
                                ...prevRows.filter(
                                    (address, idx) => !selectedRowIds.includes(idx)
                                )
                            ]
                        })
                    }
                },
            ]
        }
        return menuOptions
    }

    //Handles the add row button that is provided at the bottom of the grid adds a row to the very end of the grid
    const handleAddRow = () => {
        logger.info("Add a row");
        setBroadbandRows(prevRows => {
            return [
                ...prevRows,
                {
                    "businessName": "",
                    "addressLine1": "",
                    "unit": "",
                    "unitNumber": "",
                    "city": "",
                    "state": "",
                    "zip": "",
                    "modemRental": "",
                    "staticIps": "",
                    "uqualId": "",
                    "term": "",
                    "requestedBandwidth": "",
                    "bandwidthMenuOpened": false,
                    "bandwidthMenu": [],
                    "maxBandwidth": "",
                    "qualify": "",
                    "qualDesc": ""
                }
            ]
        })
    }

    //Handles the delete row button that is provided at the bottom of the grid and the deletes the last row of the react grid
    const handleDeleteRow = () => {
        let updatedbroadbandRows = [...broadbandRows];
        updatedbroadbandRows.pop();
        setBroadbandRows(updatedbroadbandRows);
    }

    //Function that loops through all the rows and qualifies each address separately and determines if the bandwidth chosen is valid
    const qualifyingAddressHandler = async () => {
        logger.info("Inside of Qualifying Address Handler");
        //Loop through each address and if it is not qualified, then sets the qualify field to qualify...
        for (let i = 0; i < broadbandRows?.length; i++) {
            if (broadbandRows[i]?.addressLine1 && broadbandRows[i]?.city && broadbandRows[i]?.state && broadbandRows[i]?.zip && broadbandRows[i]?.qualify !== "Qualified") {
                let updatedAddress = [...broadbandRows];
                updatedAddress[i].qualify = "Qualifying...";
                updatedAddress[i].qualDesc = "";
                setBroadbandRows(updatedAddress);
                //If the user is qualifying more than 20 address we want to dispatch the service call in groups of 5
                //For every 5th service call we will wait to dispatch the next five until that call is done
                if(broadbandRows?.length > 20 && i % 5 === 0){
                    await fetchQualificationData(broadbandRows, i);
                } else {
                    fetchQualificationData(broadbandRows, i);
                }

            }
        }
    }

    //Recursive function that will fetch the qualification data for each address and if the address fails the first time it will recall the function
    const fetchQualificationData =  async (address, idx) => {
        //increment the try count to keep track of how many times the address has been tried
        setAddressTryCount(addressTryCount + 1);
        await BulkHelper.bulkBroadbandAddressQualifier(address[idx]).then(response => {
            let updatedAddress = [...broadbandRows];
            logger.info("Bulk Address Response: " + JSON.stringify(response.data));
            if (response.data.result === "SUCCESS") {
                updatedAddress[idx].qualify = "Qualified";
                updatedAddress[idx].qualDesc = response.data?.qualMessage;
                updatedAddress[idx].uqualId = response.data?.uqualId;
                updatedAddress[idx].maxBandwidth = response.data?.bandwidthDesc;
                updatedAddress[idx].bandwidthMenu = response.data?.bandwidthMenu;
                if(updatedAddress[idx].requestedBandwidth) {
                    if(!response.data?.bandwidthMenu?.some(bw => bw.label.toUpperCase() === updatedAddress[idx].requestedBandwidth.toUpperCase())) {
                        logger.info(`Clearing requested bandwidth: ${updatedAddress[idx].requestedBandwidth} not a valid value`);
                        updatedAddress[idx].requestedBandwidth = ""; // if the requested bandwidth isn't in the menu, clear it
                    }
                }
                let validBandwidths = "\nThe following bandwidths are valid: ";
                for(let i = 0; i < response.data?.bandwidthMenu.length; i++) {
                    logger.info("valid bandwidth value: " + response.data?.bandwidthMenu.label);
                    //Ignore any empty values
                    if(response.data?.bandwidthMenu[i].label !== "") {
                        //Add the valid bandwidth to the error message with a comma if it is not the last value
                        if(i !== response.data?.bandwidthMenu?.length - 1){
                            validBandwidths += response.data?.bandwidthMenu[i].label + ", ";
                        } else {
                            //Add the last valid bandwidth to bandwidthErrorText
                            validBandwidths += response.data?.bandwidthMenu[i].label + ". ";
                        }
                    }
                }
                updatedAddress[idx].qualDesc = response.data?.qualMessage + validBandwidths;
            } else {
                updatedAddress[idx].qualify   = "Failed";
                updatedAddress[idx].qualDesc  = response.data?.error;
            }
            //highlightQualifyColumn(response.data, idx, highlights);
            logger.info("updatedAddress: " + JSON.stringify(updatedAddress));
            setAddressTryCount(0);//reset the try count to 0
            setBroadbandRows(updatedAddress);
        }).catch(error => {
            logger.info("Number of tries to qualify address: " + addressTryCount);
            if (error.request && addressTryCount < 3) {
                //The request was made but no response was recieved
                //error.request is an instance of XLMLHTTPRequest in the browser
                logger.info(`error.request: ${JSON.stringify(error.request)}`);
                logger.info("GATEWAY 504 TIMEOUT");
                logger.info("Trying address qualifier again");
                fetchQualificationData(address, idx);
            }
        })
    }

    /*Highlight cells would be a nice feature that we may want to add in the future
    const highlightQualifyColumn = (data, index) => {
        logger.info("Inside of highlightQualifyColumn: " + index);
        console.log("inital highlights: " + JSON.stringify(highlights));
        let updatedHighlights = highlights;
        if(data?.result === "SUCCESS") {
            updatedHighlights.push({ columnId: "qualify", rowId: index, borderColor: "#00FF00"});
        } else {
            updatedHighlights.push({ columnId: "qualify", rowId: index, borderColor: "#FF0000"});
        }

        if(data?.bandwidthQual) {
            updatedHighlights.push({ columnId: "bandwidth", rowId: index, borderColor: "#00FF00"});
        } else {
            updatedHighlights.push({ columnId: "bandwidth", rowId: index, borderColor: "#FF0000"});
        }
        console.log("updatedHighlights: " + JSON.stringify(updatedHighlights));
        setHighlights(updatedHighlights);
    }
    */

    //Handler for the toggle full screen button
    const toggleFullScreen = () => {
        const element = document.getElementById("bulkForm");
        const isFullScreen = document.fullscreenElement;
        console.log(isFullScreen);
        if (isFullScreen) {
            document.exitFullscreen();
        } else {
            element.requestFullscreen();
        }
    };

    //Handles the undo button
    const handleUndoChanges = () => {
        if (cellChangesIndex >= 0) {
            setBroadbandRows((prevRows) =>
                undoChanges(cellChanges[cellChangesIndex], prevRows)
            );
        }
    };

    //Function that does the logic to undo the last action returns the updated grid with the changes and the cell change index
    const undoChanges = (changes, prevRows) => {
        const updated = applyNewValue(changes, prevRows, true);
        setCellChangesIndex(cellChangesIndex - 1);
        return updated;
    };

    //Handles the redo button
    const handleRedoChanges = () => {
        if (cellChangesIndex + 1 <= cellChanges.length - 1) {
            setBroadbandRows((prevRows) => redoChanges(cellChanges[cellChangesIndex + 1], prevRows));
        }
    };

    //Function that does the logic to redo the last action returns the updated grid with the changes and the cell change index
    const redoChanges = (changes, prevRows) => {
        const updated = applyNewValue(changes, prevRows);
        setCellChangesIndex(cellChangesIndex + 1);
        return updated;
    };

    //Applies the changes to the grid and returns the updated grid
    const applyNewValue = (changes, prevRows, usePrevValue) => {
        let error = "";
        let termError = false;
        let modemRentalError = false;
        let staticIpsError = false;
        let bandwidthError = false;
        let bandwidthErrorText = "";
        changes.forEach((change) => {
            //logger.info("change: " + JSON.stringify(change));
            const index = change.rowId;
            const fieldName = change.columnId;
            const cell = usePrevValue ? change.previousCell : change.newCell;
            let sanitizeValue = cell?.text.replace(/[\r\n]+/g, "");
            //Validation checks to prevent user from entering invalid values into certain fields
            switch (fieldName) {
                case "modemRental":
                    logger.info(`modemRental: ${sanitizeValue}`);
                    if (["y", "Y", "n", "N", ""].includes(sanitizeValue)) {
                        prevRows[index][fieldName] = sanitizeValue;
                    } else {
                        modemRentalError = true;
                    }
                    break;
                case "staticIps":
                    logger.info(`staticIps: ${sanitizeValue}`);
                    if (["0", "2", "6", "", 0, 2, 6].includes(sanitizeValue)) {
                        prevRows[index][fieldName] = sanitizeValue;
                    } else {
                        staticIpsError = true;
                    }
                    break;
                case "term":
                    logger.info(`term: ${sanitizeValue}`);
                    if (["1", "2", "3", "M", "T", "MT", "MTM","", 1, 2, 3].includes(sanitizeValue)) {
                        prevRows[index][fieldName] = sanitizeValue;
                    } else {
                        termError = true;
                    }
                    break;
                case "requestedBandwidth":
                    logger.info(`requestedBandwidth: ${sanitizeValue}`);
                    if (prevRows[index]["qualify"] === "Qualified") {
                        if(!prevRows[index]["bandwidthMenu"].some(bandwidth => bandwidth.label.toUpperCase() === sanitizeValue.toUpperCase())) {
                            logger.info("Invalid Bandwidth");
                            bandwidthError = true;
                            bandwidthErrorText = "The following are valid bandwidths: ";
                            for(let i = 0; i < prevRows[index]["bandwidthMenu"].length; i++) {
                                logger.info(`Valid bandwidth value: ${prevRows[index]["bandwidthMenu"][i].label}`);
                                //Ignore any empty values
                                if(prevRows[index]["bandwidthMenu"][i].label !== "") {
                                    //Add the valid bandwidth to the error message with a comma if it is not the last value
                                    if(i !== prevRows[index]["bandwidthMenu"]?.length - 1){
                                        bandwidthErrorText += prevRows[index]["bandwidthMenu"][i].label + ", ";
                                    } else {
                                        //Add the last valid bandwidth to bandwidthErrorText
                                        bandwidthErrorText += prevRows[index]["bandwidthMenu"][i].label + ". ";
                                    }
                                }

                            }
                        } else {
                            //Valid bandwidth so set the value
                            prevRows[index][fieldName] = sanitizeValue;
                        }
                    } else {
                        //Not Qualified so set the value which will be clear if invalid once the valid bandwidth options come back from the qualification of the address
                        prevRows[index][fieldName] = sanitizeValue;
                    }
                    break;
                    /*This code is if we want the requestedBandwidth column to be a dropdown menu
                case "requestedBandwidth":
                    if(cell?.type === "dropdown" && broadbandRows[index].qualify === "true" && cell?.isOpen) {
                        logger.info(`Opening bandwidth menu Column: ${fieldName} Value: ${cell.value} Text: ${cell.text} selectedValue: ${cell.selectedValue}`);
                        prevRows[index].bandwidthMenuOpened = true;
                    }

                    if(cell?.value){
                        logger.info(`Closing bandwidth menu Column: ${fieldName} Value: ${cell.value} Text: ${cell.text} selectedValue: ${cell.selectedValue}`)
                        prevRows[index][fieldName] = cell.value;
                        prevRows[index].bandwidthMenuOpened = false;
                    }
                    break;
                    */
                default:
                    logger.info(`Column: ${fieldName} Value: ${sanitizeValue}`);
                    prevRows[index][fieldName] = sanitizeValue;
                    break;
            }


        });
        if(termError || modemRentalError || staticIpsError || bandwidthError) {
            if(termError) error += "Please enter 1, 2, 3, or MTM for the term. ";
            if(modemRentalError) error += "Please enter 'Y' or 'N' for modem rental. ";
            if(staticIpsError) error += "Please enter 0, 2, or 6 for static IPs. ";
            if(bandwidthError) error += bandwidthErrorText;
            error += "See tooltip for additional details. ";
            setPopupShow(true);
            setPopupMsg(error);
        }
        return [...prevRows];
    };

    //Function to export data to excel
    const exportData = () => {
		const exportArr = broadbandRows.map((item) => {
			return {
                "Business Name": item.businessName,
                "Address": item.addressLine1,
                "Address Designation Value": item.unit,
                "Adress Line 2": item.unitNumber,
                "City": item.city,
                "State":item.state,
                "Zip": item.zip,
                "Modem Rental": item.modemRental,
                "Static IPs": item.staticIps,
                "Term": item.term,
                "Requested Bandwidth": item.requestedBandwidth,
                "Qualify": item.qualify,
                "Max Bandwidth": item.maxBandwidth
			};
		});

        XLSXExport.exportToExcel(XLSXHeaders, exportArr, `WHSL_BB_BULK_Export_${Date.now()}`);
	};

    const generateExcelBlob = () => {
        logger.info("Generating Excel Blob");
        const excelHeaders = ["Business Name", "Address", "Address 2 Designation", "Address 2 Value", "City", "State", "Zip", "Modem Rental", "Static IPs","Term", "Requested Bandwidth", "Max Bandwidth", "UQUAL ID", "Qualify"];
        const exportArr = broadbandRows.map((item) => {
			return {
                "Business Name": item.businessName,
                "Address": item.addressLine1,
                "Address Designation Value": item.unit,
                "Adress Line 2": item.unitNumber,
                "City": item.city,
                "State":item.state,
                "Zip": item.zip,
                "Modem Rental": item.modemRental,
                "Static IPs": item.staticIps,
                "Term": item.term,
                "Requested Bandwidth": item.requestedBandwidth,
                "Max Bandwidth": item.maxBandwidth,
                "UQUAL ID": item.uqualId,
                "Qualify": item.qualify,
			};
		});
		logger.info("exportArr: " + JSON.stringify(exportArr));
        const excelBlob = XLSXExport.createExcelBlob(excelHeaders, exportArr, `WHSL_BB_BULK_Export_${Date.now()}`);
        const formData = new FormData();
        formData.append("userfile", excelBlob, `WHSL_BB_BULK_Export_${Date.now()}.xlsx`);

        return formData;
    }

    //Handling the submission of the bulk form
    const submitHandler = () => {
        setErrorMsg("");
        setLoader(true);
        logger.info("Inside of submitHandler");
        let emptyForm = true;
        for(let i = 0; i < broadbandRows.length; i++) {
            if(broadbandRows[i]?.addressLine1 === "" & broadbandRows[i]?.city === "" || broadbandRows[i]?.state === "" || broadbandRows[i]?.zip === "") {
                logger.info("empty row");
            } else {
                emptyForm = false;
                if(broadbandRows[i]?.qualify !== "Qualified" || !broadbandRows[i]?.requestedBandwidth) {
                    setErrorMsg("Please make sure all rows with addresses have been qualifed and have a requested bandwidth.");
                    setLoader(false);
                    return;
                }
            }

        }

        if(emptyForm){
            setLoader(false);
            setErrorMsg("Please make sure at least one row is filled out before submitting.");
            return;
        }

        logger.info(`submitHandler: ${JSON.stringify(broadbandRows)}`);
        const excelFileData = generateExcelBlob(); //generate the excel file to be uploaded to the server
        BulkHelper.submitBroadbandBulk(props.projectDetails, excelFileData).then(response => {
			logger.info(`response: ${JSON.stringify(response)}`);
			if (response?.data?.bulkId) {
				logger.info(`Bulk ID: ${response?.data?.bulkId}`);
				setSuccessMsg("Bulk quote has been successfully submitted.");
				setBulkId(response?.data?.bulkId);
			} else {
				setErrorMsg("Error submitting bulk quote...");
			}
            setLoader(false);
        }).catch(error => {
            logger.error(`Submit broadband bulk error: ${error}`);
            setLoader(false);
        })
    }

    useEffect(() => {
        let displaySubmitButton = true;
        let emptyForm = true;
        for(let i = 0; i < broadbandRows.length; i++) {
            if(broadbandRows[i]?.addressLine1 === "" & broadbandRows[i]?.city === "" || broadbandRows[i]?.state === "" || broadbandRows[i]?.zip === "") {
                logger.info("empty row");
            } else {
                emptyForm = false;
                if(broadbandRows[i]?.qualify !== "Qualified" || !broadbandRows[i]?.requestedBandwidth) {
                    displaySubmitButton = false;
                }
            }
        }

        if(emptyForm) displaySubmitButton = false;

        setShowSubmitButton(displaySubmitButton);
    },[broadbandRows, logger])


    return (
        <>
            {loader ? <Loader/> : null}
            <div className={` sub-hdr alt2 ${style.subHeader} `}>
                <div className="nib">
                    <div className="the-notch">
                        <div className="notch-fg">
                            <svg>
                                <path
                                    d="M35.81,26.28l-13,10v-5C-10.47,28.68-1,9.16,11.79,0c0,7.28,0,9.13,0,13.28s3.18,7.92,11,9v-5Z"></path>
                            </svg>
                        </div>
                    </div>
                </div>
                <h2 className={style.sectionTitle}>Bulk Form</h2>
            </div>

            {Number(props.rowCount) > 0 && Object.entries(broadbandRows)?.length > 0 ?
                <>
                    <div className="text-right mr-4">
                        <button className="saveButton ico-button light-button mr-1" onClick={() => toggleFullScreen()}>
                            <span><strong>Toggle fullscreen</strong></span>
                        </button>
                        <button className="saveButton ico-button light-button" onClick={() => exportData()}>
                            <span><strong>Export</strong></span>
                            <svg viewBox="0 0 34 34" className={`ico light-ico ico-arrow downloadArrow`}>
                                <path className="cls-1" d="M1,18V16L17,31V1h2V31L35,16v2L20,33H35v2H1V33H16Z" transform="translate(-1 -1)"></path>
                            </svg>
                        </button>
                    </div>
                    <div className={`row f11 react-grid-container ${style.rowGrp}`} id="bulkForm">
                        <ReactGrid
                            rows={rows}
                            columns={columns}
                            onCellsChanged={handleChanges}
                            onContextMenu={handleContextMenu}
                            onColumnResized={handleColumnResize}
                            enableRangeSelection
                            enableRowSelection
                            //highlights={highlights}
                            //stickyTopRows={1} making header sticky
                            stickyRightColumns={2}
                            enableFillHandle
                            customCellTemplates={{ header: TooltipHeaderCellTemplate, qualify: QualifyDescriptionCellTemplate}}
                        />
                    </div>
                    <div className={`text-left ${style.rowGrp}`}>
                        <button type="button" className="btn btn-outline-secondary mr-1" onClick={() => handleAddRow()}>
                            Add Row
                        </button>
                        <button type="button" className="btn btn-outline-secondary mr-1" onClick={() => handleDeleteRow()}>
                            Delete Row
                        </button>
                        <button type="button" className="btn btn-outline-secondary mr-1" onClick={() => handleUndoChanges()}>
                            Undo
                        </button>
                        <button type="button" className="btn btn-outline-secondary mr-1" onClick={() => handleRedoChanges()}>
                            Redo
                        </button>
                    </div>
                </> : null
            }
            {errorMsg ? <div className="alert alert-danger text-center mt-2" role="alert">{errorMsg}</div> : null}
            <div className="row my-5 rel">
                <div className="col-12 text-center">
                    <button className={`ico-button lhs light-button ${style.nextBtn} mr-2`} type="button" onClick={() => qualifyingAddressHandler()}>
                        Qualify
                        <svg className="ico light-ico ico-arrow">
                            <path d="M0,17H29L15,31l1,1L32,16,16,0,15,1,29,15H0Z"></path>
                        </svg>
                    </button>
                    {showSubmitButton ?
                        <button className={`ico-button lhs light-button ${style.nextBtn}`} type="button" onClick={() => submitHandler()}>
                            Submit
                            <svg className="ico light-ico ico-arrow">
                                <path d="M0,17H29L15,31l1,1L32,16,16,0,15,1,29,15H0Z"></path>
                            </svg>
                        </button> : null
                    }
                </div>
            </div>
            {popupShow ?
				<div>
					<div className={style.overly}></div>
					<div className="modal fade show d-block">
						<div className="modal-dialog modal-dialog-centered modal-md">
							<div className="modal-content">
								<div className="modal-body text-center text-danger">
									{popupMsg}
								</div>
								<div className="modal-footer d-block text-center">
									<button type="button" className="btn-style btn-theme" data-dismiss="modal" onClick={() => setPopupShow(false)}>Ok</button>
								</div>
							</div>
						</div>
					</div>
				</div>
				: null
			}
			{bulkId ?
				<>
					<div className="mt-5">
						<p className="text-success text-center">{successMsg}</p>
					</div>
					<Redirect to={`../Quote/Bulk/View/${bulkId}`} />
				</> : null
			}
        </>
	);
}
export default BroadbandBulk;
