import axios from "./axios/customAxios";

const getBWOptions = async (circuit,accessType) => {
	const eAccessBWURL = `${process.env.REACT_APP_API_URL}/getBandwidths.php?product=20&menu=bw&circuit=${circuit}&accessType=${accessType}`;
    return await axios.get(eAccessBWURL);
}

const EAccessHelper = {
    getBWOptions
}

export default EAccessHelper;
