/**
 * Created by Rochaus on 1/20/2021.
 */
import React from 'react';
//  import { NavLink } from 'react-router-dom';

const TabsQuote = () => {
    return (
        <div className="tab-nav cf">
            {/* <NavLink className="tab-opt qm-summary" to="/support/Report" activeClassName="active">
                     <span>My Network</span>
                 </NavLink>
                 <NavLink className="tab-opt qm-create" to="/support/Document" activeClassName="active">
                     <span>Trouble Tickets</span>
                 </NavLink> */}

        </div>
    );
}

export default TabsQuote;
