import './serverError.css';
import {Link} from "react-router-dom";

const Error404 = () => {
    return (
        <>
            <div className={'errorNumber'}>{"404"}</div>
            <div className={'errorText'}>{"Page Not Found"}</div>
            <Link to="/" target="_blank" className="errLabelText">iConnect Home</Link>
        </>)
}
export default Error404;
