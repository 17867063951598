import axios from "./axios/customAxios";

const getDetailsData = async (incident_number) => {
    const ticketDetailURL = `${process.env.REACT_APP_API_URL}/getTicketDetails.php?action=details&incidentNumber=${incident_number}`;
    return await axios.get(ticketDetailURL)
}

const getContactData = async (incident_number) => {
	const ticketContactURL = `${process.env.REACT_APP_API_URL}/getTicketDetails.php?action=contacts&incidentNumber=${incident_number}`;
    return await axios.get(ticketContactURL)
}

const getNotes = async (incident_number, kineticID) => {
	const ticketNotesURL = `${process.env.REACT_APP_API_URL}/getTicketDetails.php?action=notes&incidentNumber=${incident_number}&kineticID=${kineticID}`;
    return await axios.get(ticketNotesURL)
}

const escalationValidation = async (incident_number) =>  {
	const escalationCheckURL = `${process.env.REACT_APP_API_URL}/getTicketDetails.php?action=escalationCheck&incidentNumber=${incident_number}`;
    return await axios.get(escalationCheckURL);
}

const escalateTroubleTicket = async (escalationData, kineticID) => {
	const escalationURL = `${process.env.REACT_APP_API_URL}/getTicketDetails.php?action=escalate&data=${encodeURIComponent(JSON.stringify(escalationData))}&kineticID=${kineticID}`;
    return await axios.get(escalationURL);
}

const addNote = async (noteData, kineticID) =>{
	const addNoteURL = `${process.env.REACT_APP_API_URL}/getTicketDetails.php?action=addNote&data=${encodeURIComponent(JSON.stringify(noteData))}&kineticID=${kineticID}`;
    return await axios.get(addNoteURL);
}
const statusUpdateReq = async (statusUpdateData, kineticID) =>{
	const statusURL = `${process.env.REACT_APP_API_URL}/getTicketDetails.php?action=statusUpdate&data=${JSON.stringify(statusUpdateData)}&kineticID=${kineticID}`;
    return await axios.get(statusURL);
}


const TroubleTicketDetails = {
    getDetailsData,
    getContactData,
    getNotes,
    escalationValidation,
    escalateTroubleTicket,
    addNote,
    statusUpdateReq
}
export default TroubleTicketDetails;
