import axios from "./axios/customAxios";

const broadbandProductCheck = async () => {
    const productCheckURL = `${process.env.REACT_APP_API_URL}/broadband/getBroadbandMenus.php?action=productCheck`;
    return await axios.get(productCheckURL);
}
const getUqualLocations = async (addressLine1, addressLine2) => {
	const popLocationURL = `${process.env.REACT_APP_API_URL}/broadband/getUqualAddresses.php?addressLine1=${addressLine1}&addressLine2=${addressLine2}`;
    return await axios.get(popLocationURL);
}

const uqualAddressValidation = async (address) => {
    const addressValidationURL = `${process.env.REACT_APP_API_URL}/broadband/validateUqualAddress.php?address=${encodeURIComponent(JSON.stringify(address))}`;
    return await axios.get(addressValidationURL);
}

const getKineticBroadbandBWs = async (maxBand, ranged) => {
    const broadbandBwURL = `${process.env.REACT_APP_API_URL}/broadband/getBroadbandMenus.php?maxBand=${maxBand}&ranged=${ranged}&action=bandwidthMenu`;
    return await axios.get(broadbandBwURL);
}

const getModemOptions = async () => {
    const modemOptionsURL = `${process.env.REACT_APP_API_URL}/broadband/getBroadbandMenus.php?action=modemMenu`;
    return await axios.get(modemOptionsURL);
}

const getDcrisOrderStatus = async (refId) => {
    const dcrisOrderStatusURL = `${process.env.REACT_APP_API_URL}/broadband/getDcrisOrderStatus.php?ref_id=${refId}`;
    return await axios.get(dcrisOrderStatusURL);
}

const createKineticBroadbandQuote = async (values) => {
	const createKineticBroadbandQuoteURL = `${process.env.REACT_APP_API_URL}/broadband/broadbandQuoteSubmit.php?data=${encodeURIComponent(JSON.stringify(values))}`;
	return await axios.get(createKineticBroadbandQuoteURL);
}

const createKineticBroadbandOrder = async (values) => {
	const createKineticBroadbandOrderURL = `${process.env.REACT_APP_API_URL}/broadband/broadbandOrderSubmit.php?data=${encodeURIComponent(JSON.stringify(values))}`;
	return await axios.get(createKineticBroadbandOrderURL);
}

const BroadbandHelper = {
    broadbandProductCheck,
    getUqualLocations,
    uqualAddressValidation,
    getKineticBroadbandBWs,
    getModemOptions,
    getDcrisOrderStatus,
    createKineticBroadbandQuote,
    createKineticBroadbandOrder
}

export default BroadbandHelper;
