import {HTTP_404_ERROR, HTTP_500_ERROR, HTTP_OTHER_ERROR, SESSION_ERROR_MESSAGE} from "../actions/types/actionTypes"

const initialState = {
    showErrorModal: false,
	errorMessage: "",
	error: {
		sessionErrorMessage: ""
	}
}
const execute404 = (state, action) => {
    action.history.push("/pageNotFound");
    return { ...state };
}
const execute500 = (state, action) => {
    action.history.push("/serverError");
    return { ...state };
}
const executeOtherError = (state, action) => {
    return {
        ...state,
        showErrorModal: true,
        errorMessage: action.error.response.data
    };
}
const errorHandlerReducer = (state = initialState, action) => {
    switch (action.type) {
        case HTTP_404_ERROR:
            return execute404(state, action);
        case HTTP_500_ERROR:
            return execute500(state, action);
        case HTTP_OTHER_ERROR:
			return executeOtherError(state, action);
		case SESSION_ERROR_MESSAGE:
			return {
				...state,
				error: {
					...state.error,
					sessionErrorMessage: action.payload
				}
			}
			break;
        default:
            return state;
    }
}
export default errorHandlerReducer;