import axios from "./axios/customAxios";

const getOutageKmzLink = async (designId, days) => {
    const outageKmzURL = `${process.env.REACT_APP_API_URL}/getKmzLink.php?action=outage&designId=${designId}&days=${days}`;
	return await axios.get(outageKmzURL);
}

const getOutageCsvLink = async (designId, days) => {
    const outageCsvURL = `${process.env.REACT_APP_API_URL}/getKmzLink.php?action=csv&designId=${designId}&days=${days}`;
    return await axios.get(outageCsvURL);
}

const getOutageExcelLink = async (designId, days) => {
	const outageExcelURL = `${process.env.REACT_APP_API_URL}/getKmzLink.php?action=excel&designId=${designId}&days=${days}`;
	return await axios.get(outageExcelURL);
}

const OutageOverlayHelper = {
    getOutageKmzLink,
	getOutageCsvLink,
	getOutageExcelLink
}

export default OutageOverlayHelper;
