import {
	ACTIVITY_REQUEST,
	ACTIVITY_TYPE,
	MACD_A_ADDRESS,
	MACD_A_ADDRESS2,
	MACD_A_CITY,
	MACD_A_COLO_POP,
	MACD_A_FIBER_CONNECTOR,
	MACD_A_FLOOR,
	MACD_A_FRAMING,
	MACD_A_HANDOFF,
	MACD_A_LATITUDE,
	MACD_A_LINE_CODE,
	MACD_A_LONGITUDE,
	MACD_A_ONSITE_EMAIL,
	MACD_A_ONSITE_NAME,
	MACD_A_ONSITE_PHONE,
	MACD_A_STATE,
	MACD_A_ZIP,
	MACD_ACNA,
	MACD_BW,
	MACD_CABLE_FID,
	MACD_CIRCUIT_LIST_COUNT,
	MACD_CIRCUIT_GAID,
	MACD_CIRCUIT_SEARCH,
	MACD_CIRCUIT_DETAILS,
	MACD_CIRCUIT_TYPE,
	MACD_CUSTOMER_CIRCUIT,
	MACD_CUSTOMER_PON,
	MACD_DATA_TECH_CONTACT,
	MACD_DATA_TECH_EMAIL,
	MACD_DATA_TECH_PHONE,
	MACD_DESIGN_INQUIRY_NUM,
	MACD_DIRECTION,
	MACD_DISCO_NETWORK,
	MACD_DISPATCH_OTSIDE_WIN_BUSINESS_HRS,
	MACD_EDGE_DEVICE,
	MACD_END_BILLING_DATE,
	MACD_EXISTING_CIRCUIT_ID,
	MACD_EXISTING_CIRCUIT_ID1,
	MACD_EXISTING_CIRCUIT_ID2,
	MACD_EXISTING_TN,
	MACD_FIBER_ARRAY,
	MACD_INSERT_NOTES,
	MACD_IPS,
	MACD_ACCOUNT_NUMBER,
	MACD_MANUAL_CIRCUIT_ID,
	MACD_NEW_DATA_TECH_CONTACT,
	MACD_NEW_PRIMARY_CONTACT,
	MACD_NUM_OF_FIBERS,
	MACD_NUM_OF_ROUTES,
	MACD_NUMBER_OF_UPLOADS,
	MACD_ORDER_NOTES,
	MACD_PRIMARY_CONTACT,
	MACD_PRIMARY_EMAIL,
	MACD_PRIMARY_PHONE,
	MACD_PRODUCT,
	MACD_QTY,
	MACD_ESCORT_ACCESS_REQUEST,
	MACD_REQ_SER_DATE,
	MACD_RESET,
	MACD_SPLICE_ARRAY,
	MACD_STEP_CODE,
	MACD_TEST_TYPE,
	MACD_UPLOAD_FILENAMES,
	MACD_UPLOAD_TYPE,
	MACD_Z_ADDRESS,
	MACD_Z_ADDRESS2,
	MACD_Z_CITY,
	MACD_Z_FIBER_CONNECTOR,
	MACD_Z_FLOOR,
	MACD_Z_FRAMING,
	MACD_Z_HANDOFF,
	MACD_Z_LATITUDE,
	MACD_Z_LINE_CODE,
	MACD_Z_LONGITUDE,
	MACD_Z_ONSITE_EMAIL,
	MACD_Z_ONSITE_NAME,
	MACD_Z_ONSITE_PHONE,
	MACD_Z_STATE,
	MACD_Z_ZIP,
	MACD_LEASENET_ID,
	MACD_CIRCUIT_ID,
	MACD_CONNECTOR_TYPE,
	MACD_LIVE_EQUIPMENT,
	MACD_RACK_INFO,
	MACD_PANEL_INFO,
	MACD_PORT_FIBER_ASSIGNMENT,
	MACD_SHIPPING_ADDRESS,
	MACD_SHIPPING_LD1,
	MACD_SHIPPING_LV1,
	MACD_SHIPPING_LV2,
	MACD_SHIPPING_LD3,
	MACD_SHIPPING_LV3,
	MACD_SHIPPING_CITY,
	MACD_SHIPPING_STATE,
	MACD_SHIPPING_ZIP,
	MACD_SHIPPING_CONTACT,
	MACD_SHIPPING_CONTACT_NEW,
	MACD_SHIPPING_PHONE,
	MACD_SHIPPING_EMAIL
} from "./types/actionTypes";

export const setMACDStepCode = (payload) => {
	return {
		type: MACD_STEP_CODE,
		payload: payload
	}
}

export const setMACDProduct = (payload) => {
	return {
		type: MACD_PRODUCT,
		payload: payload
	}
}

export const setMACDActivityRequest = (payload) => {
	return {
		type: ACTIVITY_REQUEST,
		payload: payload
	}
}

export const setMACDActivityType = (payload) => {
	return {
		type: ACTIVITY_TYPE,
		payload: payload
	}
}

export const setMACDCircuitListCount = (payload) => {
	return {
		type: MACD_CIRCUIT_LIST_COUNT,
		payload: payload
	}
}

export const setMACDCircuitGaid = (payload) => {
	return {
		type: MACD_CIRCUIT_GAID,
		payload: payload
	}
}

export const setMACDCircuitSearch = (payload) => {
	return {
		type: MACD_CIRCUIT_SEARCH,
		payload: payload
	}
}
export const setMACDCircuitDetails = (payload) => {
	return {
		type: MACD_CIRCUIT_DETAILS,
		payload: payload
	}
}

export const setMACDPrimaryContact = (payload) => {
	return {
		type: MACD_PRIMARY_CONTACT,
		payload: payload
	}
}

export const setMACDNewPrimaryContact = (payload) => {
	return {
		type: MACD_NEW_PRIMARY_CONTACT,
		payload: payload
	}
}

export const setMACDPrimaryPhone = (payload) => {
	return {
		type: MACD_PRIMARY_PHONE,
		payload: payload
	}
}

export const setMACDPrimaryEmail = (payload) => {
	return {
		type: MACD_PRIMARY_EMAIL,
		payload: payload
	}
}

export const setMACDTechContact = (payload) => {
	return {
		type: MACD_DATA_TECH_CONTACT,
		payload: payload
	}
}

export const setMACDNewTechContact = (payload) => {
	return {
		type: MACD_NEW_DATA_TECH_CONTACT,
		payload: payload
	}
}

export const setMACDTechPhone = (payload) => {
	return {
		type: MACD_DATA_TECH_PHONE,
		payload: payload
	}
}

export const setMACDTechEmail = (payload) => {
	return {
		type: MACD_DATA_TECH_EMAIL,
		payload: payload
	}
}

export const setMACDCustomerPon = (payload) => {
	return {
		type: MACD_CUSTOMER_PON,
		payload: payload
	}
}

export const setMACDCustomerCircuit = (payload) => {
	return {
		type: MACD_CUSTOMER_CIRCUIT,
		payload: payload
	}
}

export const setMACDACNA = (payload) => {
	return {
		type: MACD_ACNA,
		payload: payload
	}
}
export const setMACDEscortAccessRequest = (payload) => {
	return {
		type: MACD_ESCORT_ACCESS_REQUEST,
		payload: payload
	}
}
export const setMACDReqSerDate = (payload) => {
	return {
		type: MACD_REQ_SER_DATE,
		payload: payload
	}
}
export const setMACDEdgeDevice = (payload) => {
	return {
		type: MACD_EDGE_DEVICE,
		payload: payload
	}
}
export const setMACDEndBillingDate = (payload) => {
	return {
		type: MACD_END_BILLING_DATE,
		payload: payload
	}
}

export const setMACDCircuitType = (payload) => {
	return {
		type: MACD_CIRCUIT_TYPE,
		payload: payload
	}
}

export const setMACDBW = (payload) => {
	return {
		type: MACD_BW,
		payload: payload
	}
}

export const setMACDQty = (payload) => {
	return {
		type: MACD_QTY,
		payload: payload
	}
}

export const setMACDIps = (payload) => {
	return {
		type: MACD_IPS,
		payload: payload
	}
}

export const setMACDACableFid = (payload) => {
	return {
		type: MACD_CABLE_FID,
		payload: payload
	}
}
export const setMACDANumOfFibers = (payload) => {
	return {
		type: MACD_NUM_OF_FIBERS,
		payload: payload
	}
}
export const setMACDANumOfRoutes = (payload) => {
	return {
		type: MACD_NUM_OF_ROUTES,
		payload: payload
	}
}
export const setMACDDispatchOtsideWin = (payload) => {
	return {
		type: MACD_DISPATCH_OTSIDE_WIN_BUSINESS_HRS,
		payload: payload
	}
}
export const setMACDTestType = (payload) => {
	return {
		type: MACD_TEST_TYPE,
		payload: payload
	}
}

export const setMACDADirection = (payload) => {
	return {
		type: MACD_DIRECTION,
		payload: payload
	}
}

export const setMACDExistingTN = (payload, index) => {
	return {
		type: MACD_EXISTING_TN,
		index: index,
		payload: payload
	}
}

export const setMACDDesignInquiryNum = (payload) => {
	return {
		type: MACD_DESIGN_INQUIRY_NUM,
		payload: payload
	}
}

export const setMACDFiberArray = (payload, index) => {
	return {
		type: MACD_FIBER_ARRAY,
		index: index,
		payload: payload
	}
}
export const setMACDSpliceArray = (payload, index) => {
	return {
		type: MACD_SPLICE_ARRAY,
		index: index,
		payload: payload
	}
}

export const setMACDAccountNumber = (payload) => {
	return {
		type: MACD_ACCOUNT_NUMBER,
		payload: payload
	}
}

export const setMACDManualCircuit = (payload, index) => {
	return {
		type: MACD_MANUAL_CIRCUIT_ID,
		index: index,
		payload: payload
	}
}

export const setMACDDiscoNetwork = (payload) => {
	return {
		type: MACD_DISCO_NETWORK,
		payload: payload
	}
}
export const setMACDExistingCircuit = (payload, index) => {
	return {
		type: MACD_EXISTING_CIRCUIT_ID,
		index: index,
		payload: payload
	}
}

export const setMACDExistingCircuit1 = (payload) => {
	return {
		type: MACD_EXISTING_CIRCUIT_ID1,
		payload: payload
	}
}
export const setMACDExistingCircuit2 = (payload) => {
	return {
		type: MACD_EXISTING_CIRCUIT_ID2,
		payload: payload
	}
}
export const setMACDALatitude = (payload) => {
	return {
		type: MACD_A_LATITUDE,
		payload: payload
	}
}

export const setMACDALongitude = (payload) => {
	return {
		type: MACD_A_LONGITUDE,
		payload: payload
	}
}

export const setMACDAColoPop = (payload) => {
	return {
		type: MACD_A_COLO_POP,
		payload: payload
	}
}
export const setMACDAAddress = (payload) => {
	return {
		type: MACD_A_ADDRESS,
		payload: payload
	}
}

export const setMACDAAddress2 = (payload) => {
	return {
		type: MACD_A_ADDRESS2,
		payload: payload
	}
}

export const setMACDACity = (payload) => {
	return {
		type: MACD_A_CITY,
		payload: payload
	}
}

export const setMACDAState = (payload) => {
	return {
		type: MACD_A_STATE,
		payload: payload
	}
}

export const setMACDAZip = (payload) => {
	return {
		type: MACD_A_ZIP,
		payload: payload
	}
}

export const setMACDAFloor = (payload) => {
	return {
		type: MACD_A_FLOOR,
		payload: payload
	}
}

export const setMACDAOnsiteName = (payload) => {
	return {
		type: MACD_A_ONSITE_NAME,
		payload: payload
	}
}

export const setMACDAOnsitePhone = (payload) => {
	return {
		type: MACD_A_ONSITE_PHONE,
		payload: payload
	}
}

export const setMACDAOnsiteEmail = (payload) => {
	return {
		type: MACD_A_ONSITE_EMAIL,
		payload: payload
	}
}

export const setMACDAFraming = (payload) => {
	return {
		type: MACD_A_FRAMING,
		payload: payload
	}
}

export const setMACDALineCode = (payload) => {
	return {
		type: MACD_A_LINE_CODE,
		payload: payload
	}
}

export const setMACDAHandoff = (payload) => {
	return {
		type: MACD_A_HANDOFF,
		payload: payload
	}
}

export const setMACDAFiberConnector = (payload) => {
	return {
		type: MACD_A_FIBER_CONNECTOR,
		payload: payload
	}
}

export const setMACDZLatitude = (payload) => {
	return {
		type: MACD_Z_LATITUDE,
		payload: payload
	}
}

export const setMACDZLongitude = (payload) => {
	return {
		type: MACD_Z_LONGITUDE,
		payload: payload
	}
}

export const setMACDZAddress = (payload) => {
	return {
		type: MACD_Z_ADDRESS,
		payload: payload
	}
}

export const setMACDZAddress2 = (payload) => {
	return {
		type: MACD_Z_ADDRESS2,
		payload: payload
	}
}

export const setMACDZCity = (payload) => {
	return {
		type: MACD_Z_CITY,
		payload: payload
	}
}

export const setMACDZState = (payload) => {
	return {
		type: MACD_Z_STATE,
		payload: payload
	}
}

export const setMACDZZip = (payload) => {
	return {
		type: MACD_Z_ZIP,
		payload: payload
	}
}

export const setMACDZFloor = (payload) => {
	return {
		type: MACD_Z_FLOOR,
		payload: payload
	}
}

export const setMACDZOnsiteName = (payload) => {
	return {
		type: MACD_Z_ONSITE_NAME,
		payload: payload
	}
}

export const setMACDZOnsitePhone = (payload) => {
	return {
		type: MACD_Z_ONSITE_PHONE,
		payload: payload
	}
}

export const setMACDZOnsiteEmail = (payload) => {
	return {
		type: MACD_Z_ONSITE_EMAIL,
		payload: payload
	}
}

export const setMACDZFraming = (payload) => {
	return {
		type: MACD_Z_FRAMING,
		payload: payload
	}
}

export const setMACDZLineCode = (payload) => {
	return {
		type: MACD_Z_LINE_CODE,
		payload: payload
	}
}

export const setMACDZHandoff = (payload) => {
	return {
		type: MACD_Z_HANDOFF,
		payload: payload
	}
}

export const setMACDZFiberConnector = (payload) => {
	return {
		type: MACD_Z_FIBER_CONNECTOR,
		payload: payload
	}
}

export const setMACDLeaseNetID = (payload) => {
	return {
		type: MACD_LEASENET_ID,
		payload: payload
	}
}

export const setMACDCircuitID = (payload) => {
	return {
		type: MACD_CIRCUIT_ID,
		payload: payload
	}
}

export const setMACDConnectorType = (payload) => {
	return {
		type: MACD_CONNECTOR_TYPE,
		payload: payload
	}
}

export const setMACDLiveEquipment = (payload) => {
	return {
		type: MACD_LIVE_EQUIPMENT,
		payload: payload
	}
}

export const setMACDRackInfo = (payload) => {
	return {
		type: MACD_RACK_INFO,
		payload: payload
	}
}

export const setMACDPanelInfo = (payload) => {
	return {
		type: MACD_PANEL_INFO,
		payload: payload
	}
}

export const setMACDPortFiberAssignment = (payload) => {
	return {
		type: MACD_PORT_FIBER_ASSIGNMENT,
		payload: payload
	}
}

export const setMACDShippingAddress = (payload) => {
	return {
		type: MACD_SHIPPING_ADDRESS,
		payload: payload
	}
}

export const setMACDShippingLD1 = (payload) => {
	return {
		type: MACD_SHIPPING_LD1,
		payload: payload
	}
}

export const setMACDShippingLV1 = (payload) => {
	return {
		type: MACD_SHIPPING_LV1,
		payload: payload
	}
}

export const setMACDShippingLV2 = (payload) => {
	return {
		type: MACD_SHIPPING_LV2,
		payload: payload
	}
}
export const setMACDShippingLD3 = (payload) => {
	return {
		type: MACD_SHIPPING_LD3,
		payload: payload
	}
}

export const setMACDShippingLV3 = (payload) => {
	return {
		type: MACD_SHIPPING_LV3,
		payload: payload
	}
}

export const setMACDShippingCity = (payload) => {
	return {
		type: MACD_SHIPPING_CITY,
		payload: payload
	}
}

export const setMACDShippingState = (payload) => {
	return {
		type: MACD_SHIPPING_STATE,
		payload: payload
	}
}

export const setMACDShippingZip = (payload) => {
	return {
		type: MACD_SHIPPING_ZIP,
		payload: payload
	}
}

export const setMACDShippingContact = (payload) => {
	return {
		type: MACD_SHIPPING_CONTACT,
		payload: payload
	}
}
export const setMACDShippingContactNew = (payload) => {
	return {
		type: MACD_SHIPPING_CONTACT_NEW,
		payload: payload
	}
}

export const setMACDShippingPhone = (payload) => {
	return {
		type: MACD_SHIPPING_PHONE,
		payload: payload
	}
}

export const setMACDShippingEmail = (payload) => {
	return {
		type: MACD_SHIPPING_EMAIL,
		payload: payload
	}
}


export const setMACDUploadType = (payload, index) => {
	return {
		type: MACD_UPLOAD_TYPE,
		index: index,
		payload: payload
	}
}

export const setMACDUploadFilenames = (payload, index) => {
	return {
		type: MACD_UPLOAD_FILENAMES,
		index: index,
		payload: payload
	}
}

export const setMACDNumberOfUploads = (payload) => {
	return {
		type: MACD_NUMBER_OF_UPLOADS,
		payload: payload
	}
}

export const setMACDInsertNotes = (payload) => {
	return {
		type: MACD_INSERT_NOTES,
		payload: payload
	}
}

export const setMACDOrderNotes = (payload) => {
	return {
		type: MACD_ORDER_NOTES,
		payload: payload
	}
}

export const resetMACDData = (payload) => {
	return {
		type: MACD_RESET,
		payload: payload
	}
}
