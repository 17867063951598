import {QUOTE_DATA} from "../actions/types/actionTypes";

const initialState = {
    quoteData: {}
};


const quoteViewReducer = (state = initialState, action) => {
    switch (action.type) {
        case QUOTE_DATA:
            return {
                ...state,
                quoteData: action.payload
            }
        default:
            return state

    }
}
export default quoteViewReducer;
