import React, {useCallback, useEffect, useMemo, useState} from "react";
import ViewportWarning from "../shared/ViewportWarning";
import TabsQuote from "../quote/includes/TabsQuote";
import style from "./PendingOrderReviewSummary.css";
import Loader from "../shared/Loader";
import {ConsoleLogger} from "../../logger/ConsoleLogger";
import ReportHelper from "../../services/InternalReportService";
import InternalSuperHelper from "../../services/InternalQuoteService";
import {Link} from "react-router-dom";
import StripedDataGrid from "../components/tables/StripedDataGrid";
import TablePagination from "../components/tables/TablePagination";
import CustomTheme from "../components/tables/CustomTheme";
import Tooltip from '@mui/material/Tooltip';

const PendingOrderReviewSummary = () => {
    const logger = useMemo(() => new ConsoleLogger({level: process.env.REACT_APP_LOGGER_LEVEL,}), []);
    const [loader, setLoader] = useState(false);
    const [rows, setRows] = useState([]);
    const [successMsg, setSuccessMsg] = useState("");
    const [errorMsg, setErrorMsg] = useState("");
    const [pageSize, setPageSize] = useState(25);

    const [filterModel, setFilterModel] = useState({
        items: [],
    });

    const handleSearchChange = (searchValue) => {
        setFilterModel({
            items: [{columnField: 'name', operatorValue: 'contains', value: searchValue},],
        });
    };

    const url = "/Employee/Order/PendingOrderUpdates/";

    const columns = [
        {
            field: "RefID",
            headerName: "RefID",
            renderCell: (params) => formatCellLink(params),
            renderHeader: (params) => formatSearchHeader(params),
            flex: 0.5,
        }
        , {
            field: "Pon",
            headerName: "Pon",
            renderHeader: (params) => formatSearchHeader(params),
            flex: 0.5,
        }
        , {
            field: "Table",
            headerName: "Table",
            renderHeader: (params) => formatSearchHeader(params),
            flex: 0.5,
        }
        , {
            field: "Action",
            headerName: "Action",
            renderHeader: (params) => formatSearchHeader(params),
            flex: 0.5,
        }
        , {
            field: "Date",
            headerName: "Date",
            renderHeader: (params) => formatSearchHeader(params),
            flex: 0.5,
        }
        , {
            field: "Last Attempt",
            headerName: "Last Attempt",
            renderHeader: (params) => formatSearchHeader(params),
            flex: 0.5,
        }
        , {
            field: "Error",
            headerName: "Error",
            renderCell: (params) => formatHoverTooltip(params),
            renderHeader: (params) => formatSearchHeader(params),
            flex: 1,
        }
    ];

    function formatCellLink(params) {
        return (
            <Link to={url + params.value} style={{color: "#753BBD"}}>
                {params.value}
            </Link>
        );
    }

    function formatSearchHeader(params) {
        return <div>
            <span style={{marginLeft: 8, color: "#753BBD"}}>{params.colDef.headerName}</span>
        </div>;
    }

    function formatHoverTooltip(params) {
        return (
            <Tooltip title={params.value} position="top">
                <div>{params.value}</div>
            </Tooltip>
        );
    }

    const getOrders = useCallback(() => {
        setLoader(true);
        ReportHelper.pendingOrderReview().then((response) => {
            setLoader(false);
            setRows(response.data);
        }).catch(error => {
            setLoader(false);
            logger.error("getPendingOrderReview Error: " + error);
        });
    }, [logger])

    useEffect(() => {
        InternalSuperHelper.superPermissionCheck().then(response => {
            if (Number(response.data) === 1) {
                getOrders();
            } else {
                setErrorMsg("No Access");
            }
        }).catch(error => {
            logger.error(`Error getting superPermissionCheck: ${error}`);
        })
    }, [getOrders, logger]);

    const deleteRecord = async (row) => {
        setErrorMsg("");
        setSuccessMsg("");
        setLoader(true);
        ReportHelper.deletePendingOrderReview(row.RefID, row.Table, row.Action).then((response) => {
            setLoader(false);
            if (response.data.result === "SUCCESS") {
                setSuccessMsg(response.data.update);
            } else {
                setErrorMsg(response.data.error);
            }
            getOrders();
        }).catch(error => {
            setLoader(false);
            logger.error("deletePendingOrderReview Error: " + error);
        });
    }

    return (<div>
        {loader ? <Loader/> : null}
        <div className="midsection qm-summary quote-summary abs z1">
            <div className="rel">
                <ViewportWarning/>
                <div className="wrap">
                    <h1 className="page-title">{"Pending Order Review Summary"}</h1>
                    <div className="section">
                        {/* <TabsManager/> */}
                        <TabsQuote/>
                        {(<div className={`content ${style.contentQueryPOR} `}>
                            <div className={`sub-hdr alt2 ${style.subHdrAlterPOR}`}>
                                <div className="nib">
                                    <div className="the-notch">
                                        <div className="notch-fg">
                                            <svg>
                                                <path
                                                    d="M35.81,26.28l-13,10v-5C-10.47,28.68-1,9.16,11.79,0c0,7.28,0,9.13,0,13.28s3.18,7.92,11,9v-5Z"></path>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                                <h2 className={` ${style.sectionTitlePOR} m-0 `}>Pending Orders</h2>
                            </div>
                            {successMsg ? <div className="mt-5">
                                <p className="text-success text-center">{successMsg}</p>
                            </div> : null}
                            {errorMsg ? <div className="mt-5">
                                <p className="text-danger text-center">{errorMsg}</p>
                            </div> : null}
                            <div className="rel b mt-3"></div>
                            <div>
                                <div style={{height: "100vh", width: "100%"}}>
                                    <StripedDataGrid
                                        theme={CustomTheme}
                                        rows={rows}
                                        rowSelection={false}
                                        getRowId={(row) => row.RefID}
                                        columns={columns}
                                        filterModel={filterModel}
                                        onFilterModelChange={(model) => setFilterModel(model)}
                                        pageSize={pageSize}
                                        rowsPerPageOptions={[]}
                                        pageSizeOptions={[]}
                                        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                                        slots={{
                                            pagination: TablePagination,
                                        }}
                                        initialState={{
                                            pagination: {
                                                paginationModel: {
                                                    pageSize: 25
                                                },
                                            },
                                        }}
                                    />
                                </div>
                            </div>
                        </div>)}
                    </div>
                </div>
            </div>
        </div>
    </div>);
}

export default PendingOrderReviewSummary;
