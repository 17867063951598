/**
 * Created by e0173131 on 1/20/2021.
 */
import ViewportWarning from "../shared/ViewportWarning";
import TabsBilling from '../billing/includes/TabsBilling';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, {CSVExport} from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min';
import Loader from "../shared/Loader";
import FormattingUtils from '../../utils/FormattingUtils';
import React, {useCallback, useEffect, useState, useMemo} from "react";
import AccountListHelper from "../../services/AccountListService";
import HTTPErrorHandler from "../errorPages/HTTPErrorHandler";
import {ConsoleLogger} from "../../logger/ConsoleLogger";
import { useSelector } from 'react-redux';
import CompanyDropdown from '../shared/CompanyDropdown';
import getPaymentHistoryData from "../../services/PaymentHistoryService";

const {ExportCSVButton} = CSVExport;
const columns = [{
    dataField: 'confirmationNumber',
    text: 'Confirmation Number',
    sort: true,
}, {
    dataField: 'paymentDate',
    text: 'Payment Date',
    sort: true,
}, {
    dataField: 'settlementDate',
    text: 'Settlement Date',
    sort: true,
},
    {
        dataField: 'amountPaid',
        text: 'Amount Paid',
        sort: true,
    },
    {
        dataField: 'billCycleDate',
        text: 'Bill Cycle Date',
        sort: true,
    }
];


const PaymentHistory = () => {
    const logger = useMemo(() => new ConsoleLogger({level: process.env.REACT_APP_LOGGER_LEVEL,}),[]);
    const [hasError, setHasError] = React.useState(null);
    const [accountsList, setAccountList] = useState([]);
    const [selectedAccount, setSelectedAccount] = useState('');
    const [loader, setLoader] = useState(false);
    const [paymentHistoryData, setPaymentHistory] = useState([]);
    const isEmployee = localStorage.getItem("isEmployeeLoggedIn");
    const selectedInternalMpid = useSelector(state => state.internal.company?.value);

    const getAccountList = useCallback(() => {
        let accList = null;
        AccountListHelper.getAccountListData(false).then(resp => {
            logger.info(encodeURIComponent(resp.data).replace(/%20/g, " "));
            accList = resp.data;
            const MAX_CHARS_TO_DISPLAY = 55;
            let str = '';
            accList = accList.map(item => {
                str = `${item.ban} - ${item.name} - ${item.id}`;
                str = FormattingUtils.truncateWithEllipses(str, MAX_CHARS_TO_DISPLAY);
                return {value: item.ban, label: str}
            });
            setAccountList([{value: '', label: 'Select Account'}].concat(accList));
        }).catch((error) => {
            logger.error("getAccountListData ERROR: " + error)
            setHasError(error);
        });
    },[logger])

    useEffect(() => {
        if(isEmployee){
            if(selectedInternalMpid){
                getAccountList();
            }
        } else {
            getAccountList();
        }
        
    }, [getAccountList, isEmployee, selectedInternalMpid]);


    //Perfoming axios call to retrieve live data based on account that is selected
    const accountHandler = (ev) => {
        let accountNum = ev.target.value;

        setLoader(true);
        setSelectedAccount(ev.target.value);

        getPaymentHistoryData(accountNum).then(resp => {
            logger.info(encodeURIComponent(resp.data).replace(/%20/g, " "));
            setPaymentHistory(resp.data);
            setLoader(false);
        }).catch((error) => {
            logger.error("getPaymentHistory ERROR: " + error)
            setHasError(error);
        });
    }

    return (
        <>
            {loader ? <Loader/> : null}
            {hasError && (
                <HTTPErrorHandler error={hasError}></HTTPErrorHandler>
            )}
            {!hasError && 
            (<div className="midsection qm-upload abs z1">
                <div className="rel">
                    <ViewportWarning/>
                    <div className="wrap">
                        <h1 className="page-title">{isEmployee ? "Employee Payment History" :  "Payment History"}</h1>
                        {isEmployee ? <CompanyDropdown/> : null}
                        <div className="section">
                            <TabsBilling/>
                            <div className="content rel">
                                <div className="billing-page billing-payment-history">
                                    <div className="w30 state-select">
                                        <select onChange={(accountHandler)} value={selectedAccount}
                                                className="nice-select dark-select wide" name="validator-state">
                                            {accountsList.map(item => (
                                                <option key={item.value} value={item.value}>
                                                    {item.label}
                                                </option>
                                            ))}
                                        </select>
                                    </div>

                                    <div className="table-style-wrap">
                                        {paymentHistoryData.length > 0 ?

                                            <ToolkitProvider
                                                keyField="id"
                                                data={paymentHistoryData}
                                                columns={columns}
                                                exportCSV={{
                                                    fileName: 'payment-history.csv',
                                                }}
                                            >
                                                {
                                                    props => (
                                                        <>
                                                            <ExportCSVButton {...props.csvProps}>
                                                                Download Report
                                                                <svg id="Layer_1" data-name="Layer 1"
                                                                     xmlns="http://www.w3.org/2000/svg"
                                                                     viewBox="0 0 34 34">
                                                                    <path className="cls-1"
                                                                          d="M1,18V16L17,31V1h2V31L35,16v2L20,33H35v2H1V33H16Z"
                                                                          transform="translate(-1 -1)"/>
                                                                </svg>
                                                            </ExportCSVButton>
                                                            <BootstrapTable
                                                                keyField='id'
                                                                {...props.baseProps}
                                                                striped
                                                                hover
                                                                condensed
                                                                bordered={false}
                                                                wrapperClasses="table-responsive"
                                                            />
                                                        </>
                                                    )
                                                }
                                            </ToolkitProvider>

                                            : null
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            )}
        </>
    );

}
export default PaymentHistory;
