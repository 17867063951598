import style from './OrderCreate.module.css';
import React, { useCallback, useEffect, useState, useMemo } from "react";
import OrderHelper from '../../services/OrderService';
import { connect, useSelector } from "react-redux";
import { useParams } from 'react-router-dom';
import Select from 'react-select';
import {
	setOrderActivity,
	setOrderBW,
	setOrderCircuitListCount,
	setOrderCircuitSearch,
	setOrderExistingCircuit,
	setOrderManualCircuit,
	setOrderGaid,
	setOrderMountingEquipment,
	setOrderQty,
	setOrderWanSFP
} from '../../redux/actions/orderCreateActions';
import Loader from '../shared/Loader';
import { ConsoleLogger } from "../../logger/ConsoleLogger";
import HTTPErrorHandler from "../errorPages/HTTPErrorHandler";
import OrderPricingInfo from './OrderPricingInfo';
import CircuitListHelper from '../../services/CircuitListService';

const OrderServiceInfo = (props) => {
	const logger = useMemo(() => new ConsoleLogger({ level: process.env.REACT_APP_LOGGER_LEVEL, }), []);
	const isEmployee = localStorage.getItem("isEmployeeLoggedIn");
	const [hasError, setHasError] = useState(null);
	const [existingCircuitOptions, setExistingCircuitOptions] = useState([]);
	const [orderBwOptions, setOrderBwOptions] = useState([]);
	const [loader, setLoader] = useState(false);
	const [qtyOptions, setQtyOptions] = useState(25);
	const selectedQuotedData = useSelector(state => state.orderCreate.quotedData);
	const selectedOrderGeneralInfo = useSelector(state => state.orderCreate.generalInfo);
	const selectedOrderServiceInfo = useSelector(state => state.orderCreate.serviceInfo);
	const selectedOrderCircuitListCount = useSelector(state => state.orderCreate.serviceInfo.circuitListCount);
	const selectedOrderCircuitSearch = useSelector(state => state.orderCreate.serviceInfo.circuitSearch);
	const [displayManualField, setDisplayManualField] = useState(false);
	const [displayGaidField, setDisplayGaidField] = useState(false);
	const [displayValidateCircuit, setDisplayValidateCircuit] = useState(false);


	const { refId } = useParams();

	const getOrderBwOptions = useCallback(() => {
		setLoader(true);
		OrderHelper.getOrderBandwidths(refId, selectedQuotedData.product_id, selectedQuotedData.circuit_id, selectedOrderGeneralInfo.initial_term).then(resp => {
			logger.info(encodeURIComponent(resp.data).replace(/%20/g, " "));
			logger.info("Number of Bandwidth options: " + encodeURIComponent(resp.data.length).replace(/%20/g, " "));
			if (resp.data.length === 1) {
				props.updateOrderBW(resp.data[0].id);
			}
			setOrderBwOptions(resp.data)
			setLoader(false);
		}).catch(error => {
			logger.error("Getting Order Bandwidths: " + error);
			setHasError(error);
			setLoader(false);
		})
	}, [logger, props, refId, selectedOrderGeneralInfo.initial_term, selectedQuotedData.circuit_id, selectedQuotedData.product_id])

	const getExistingCircuitOptions = () => {
		setLoader(true);
		let circuitMenu = [];
		CircuitListHelper.getCircuitListCount(selectedOrderCircuitSearch).then(response => {
			props.updateOrderCircuitListCount(response.data);
			logger.info(`Circuit List Count ${response.data}`);
			setLoader(false);
			if (response.data < 5000) {
				setLoader(true);
				CircuitListHelper.getCircuitList(selectedOrderCircuitSearch).then(response => {
					if (response.data !== "No circuits found") {
						circuitMenu = response.data;
					}
					isEmployee ? setExistingCircuitOptions([{ circuit: 'manual', circuitId: 'Manual Circuit' }].concat(circuitMenu)) : setExistingCircuitOptions(circuitMenu);
					setLoader(false);
				}).catch(error => {
					logger.error(`Error getting Circuit List: ${error}`);
					setLoader(false);
				})
			} else {
				logger.info(`Too many circuits the UI will display an additional field to limit circuit picklist`);
			}
		}).catch(error => {
			logger.error(`Error counting circuit list: ${error}`);
			setHasError(error);
			setLoader(false);
		})
	}

	//Additional Circuit Search to manage accounts with extensive circuit lists that may lead to timeout problems
	const searchCircuitList = () => {
		if (selectedOrderCircuitSearch === "") {
			return;
		}
		getExistingCircuitOptions();
	}

	useEffect(() => {
		getOrderBwOptions();
		if (selectedOrderServiceInfo.cpt_activity === "B" || selectedOrderServiceInfo.cpt_activity === "R") {
			getExistingCircuitOptions();
		}
		if (selectedQuotedData.q_cpt_activity === "R") {
			props.updateOrderQty(selectedQuotedData?.exist_circuit.length);
			document.getElementById("order_qty").disabled = true;
			selectedQuotedData?.exist_circuit.forEach((circuit, idx) => {
				props.updateOrderExistingCircuit({ value: circuit, label: circuit }, idx);
			})
		}
		if (selectedOrderServiceInfo.cpt_activity === "B") {
			logger.info("Circuit0: " + selectedOrderServiceInfo?.ex_circuit[0]);
			if (selectedOrderServiceInfo?.ex_circuit[0] === 'Manual Circuit') {
				setDisplayManualField(true);
				setDisplayGaidField(true);
			}
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [getOrderBwOptions, props, selectedQuotedData.exist_circuit, selectedOrderServiceInfo.cpt_activity, selectedQuotedData.q_cpt_activity]);


	/*****************************************************************************
	ADO #543521
	Function that disables or enables the quantity selection for wave
	orders based on the term and bandwidth selected. This determines the line item
	and if it was icb in the original quote
	******************************************************************************/
	const waveQtyCheck = useCallback(() => {
		if (Number(selectedQuotedData.product_id) < 4 && selectedQuotedData.circuit_id === "7" && selectedOrderGeneralInfo?.initial_term && selectedOrderServiceInfo?.bandwidth && selectedQuotedData.q_cpt_activity !== "R") {
			setLoader(true);
			OrderHelper.icbLineItemCheck(refId, selectedOrderServiceInfo.bandwidth, selectedOrderGeneralInfo.initial_term).then(resp => {
				logger.info(encodeURIComponent(resp.data).replace(/%20/g, " "));
				if (resp.data && resp.data === 1) {
					document.getElementById("order_qty").disabled = true;
					props.updateOrderQty(selectedQuotedData.quantity);
				} else {
					document.getElementById("order_qty").disabled = false;
					let bw = selectedOrderServiceInfo.bandwidth;
					let bw_string = bw.substring(0, bw.indexOf('-'));
					props.updateOrderQty(selectedQuotedData.quantity);
					//ADO-721620 Limiting quantity for standard pricing 10 GB and 100 GB Waves
					if (bw_string === "35") {
						setQtyOptions(3);
					}
					if (bw_string === "64") {
						setQtyOptions(2);
					}
				}
				setLoader(false);
			}).catch(error => {
				logger.error("Failed to perform icb line item check: " + error);
				setLoader(false);
			})
		}
	}, [logger, props, refId, selectedOrderGeneralInfo.initial_term, selectedOrderServiceInfo.bandwidth, selectedQuotedData.circuit_id, selectedQuotedData.product_id, selectedQuotedData.q_cpt_activity, selectedQuotedData.quantity]);

	useEffect(() => {
		waveQtyCheck();
	}, [selectedOrderServiceInfo.bandwidth, waveQtyCheck])

	useEffect(() => {
		//Broadband order can only be set to new
		if (selectedQuotedData?.product_id === "23") {
			props.updateOrderActivity("N");
			props.updateOrderQty("1");
			document.getElementById("order_qty").disabled = true;
		}
	}, [props, selectedQuotedData?.product_id])

	const existingCircuitHandler = (circuit, index) => {
		if (!circuit) {
			circuit = '';
		}

		if (circuit.value === 'manual') {
			setDisplayManualField(true);
			setDisplayValidateCircuit(true);
		}
		props.updateOrderExistingCircuit(circuit.label, index);
	}

	const manualCircuitHandler = (circuit, index) => {
		props.updateOrderManualCircuit(circuit, index)
		props.updateOrderGaid('');
		setDisplayGaidField(false);
		setDisplayValidateCircuit(true);
	}

	const validateCircuit = () => {
		setDisplayValidateCircuit(false);
		setLoader(true);
		OrderHelper.validateManualCircuit(selectedOrderServiceInfo?.manual_circuit[0]).then(resp => {
			logger.info(encodeURIComponent(resp.data).replace(/%20/g, " "));
			setLoader(false);
			setDisplayGaidField(true);
			if (resp.data) {
				props.updateOrderGaid(resp.data);
			}
		}).catch(error => {
			logger.error("Failed to validate manual circuit: " + error);
			setLoader(false);
		})
	}

	const inputHandler = (ev) => {
		switch (ev.target.id) {
			case "cpt_activity":
				if (ev.target.value === "N") {
					props.updateOrderExistingCircuit("");
				}
				if (ev.target.value === "B") {
					props.updateOrderQty("1");
					document.getElementById("order_qty").disabled = true;
				} else {
					document.getElementById("order_qty").disabled = false;
				}
				props.updateOrderActivity(ev.target.value);

				break;
			case "order_qty":
				props.updateOrderQty(ev.target.value);
				if (Number(ev.target.value) > 1) {
					props.updateOrderActivity("N");
					document.getElementById("cpt_activity").disabled = true;
				} else {
					document.getElementById("cpt_activity").disabled = false;
				}
				break;
			case "bandwidth":
				props.updateOrderBW(ev.target.value);
				break;
			case "mounting_equip":
				props.updateOrderMountingEquipment(ev.target.value);
				break;
			case "wan_sfp":
				props.updateOrderWanSFP(ev.target.value);
				break;
			default:
				break;
		}
	}

	return (
		<>
			{hasError && <HTTPErrorHandler error={hasError}></HTTPErrorHandler>}
			{loader ? <Loader /> : null}
			{!hasError && (<div className={style.section1}>
				<div className={`sub-hdr alt2 ${style.subHdrAlter} `}>
					<div className="nib">
						<div className="the-notch">
							<div className="notch-fg">
								<svg>
									<path
										d="M35.81,26.28l-13,10v-5C-10.47,28.68-1,9.16,11.79,0c0,7.28,0,9.13,0,13.28s3.18,7.92,11,9v-5Z"></path>
								</svg>
							</div>
						</div>
					</div>
					<h2 className={` ${style.sectionTitle} m-0 `}>Service Information</h2>
				</div>

				<div className="sub-section">
					<div className={` row ${style.rowGrp}`}>
						<div className={` col-12 col-sm-6 col-md-4 col-lg-4 ${style.colGrp}`}>
							<label className={style.labelText}>Activity Requested<span className={style.mandatory}>*</span></label>
							<select className={style.selectFld} id='cpt_activity' name='cpt_activity' onChange={inputHandler} value={selectedOrderServiceInfo.cpt_activity} disabled={selectedQuotedData.q_cpt_activity === "R" ? "disabled" : ""}>
								{selectedQuotedData.q_cpt_activity === "R" ?
									<option>Renewal</option> :
									<>
										<option></option>
										<option value="N">New</option>
										{selectedQuotedData.product_id !== "15" && selectedQuotedData.product_id !== "23" ?
											<option value="B">Bandwidth Upgrade</option> : null
										}
									</>
								}
							</select>
						</div>
						{/*Colocation doesn't have bandwidth*/}
						{selectedQuotedData.product_id !== "15" ?
							<div className={` col-12 col-sm-6 col-md-4 col-lg-4 ${style.colGrp}`}>
								<label className={style.labelText}>Bandwidth<span className={style.mandatory}>*</span></label>
								<select className={style.selectFld} id='bandwidth' name='bandwidth' onChange={inputHandler} value={selectedOrderServiceInfo.bandwidth} disabled={selectedQuotedData.q_cpt_activity === "R" ? "disabled" : ""}>
									{orderBwOptions.map((obj) => {
										return <option value={obj.id}>{obj.name}</option>
									})}
								</select>
							</div>
							: null}
						<div className={` col-12 col-sm-6 col-md-4 col-lg-4 ${style.colGrp}`}>
							<label className={style.labelText}>Quantity<span className={style.mandatory}>*</span></label>
							<select className={style.selectFld} id='order_qty' name='order_qty' onChange={inputHandler} value={selectedOrderServiceInfo.order_qty} disabled={selectedQuotedData.q_cpt_activity === "R" ? "disabled" : ""} >
								{/*Colocation limited to 3 for order qty*/}
								{selectedQuotedData.product_id === "15" ?
									<>
										<option value="1">1</option>
										<option value="2">2</option>
										<option value="3">3</option>'
									</>
									:
									<>
										{[...Array(qtyOptions)].map((x, i) =>
											<option value={i + 1}>{i + 1}</option>
										)}
									</>
								}
							</select>
						</div>

						<div className={` col-12 col-sm-6 col-md-4 col-lg-4 ${style.colGrp}`}>
							<label className={style.labelText}>Product<span className={style.mandatory}>*</span></label>
							<input type='text' className={style.inputFld} disabled='disabled' value={selectedQuotedData.product}></input>
						</div>
						{/*Service Type is always blank for colocation*/}
						{selectedQuotedData.product_id !== "15" ?
							<div className={` col-12 col-sm-6 col-md-4 col-lg-4 ${style.colGrp}`}>
								<label className={style.labelText}>Service Type<span className={style.mandatory}>*</span></label>
								<input type='text' className={style.inputFld} disabled='disabled' value={selectedQuotedData.circuit}></input>
							</div> :
							null}
						{/*Colocation and Renewals doesn't allow for TSP CIRCUIT*/}
						{selectedQuotedData.product_id === "15" || selectedQuotedData.q_cpt_activity === "R" || selectedQuotedData.product_id === "23" ?
							null :
							<div className={` col-12 col-sm-6 col-md-4 col-lg-4 ${style.colGrp}`}>
								<label className={style.labelText}>TSP Circuit 1 </label>
								<input type='text' className={style.inputFld}></input>
							</div>
						}
						{selectedOrderServiceInfo.cpt_activity === "B" ?
							<>
								{Number(selectedOrderCircuitListCount) > 5000 ?
									<>

										<div className={` col-4 col-sm-4 col-md-2 col-lg-2 ${style.colGrp}`}>
											<label className={style.labelText}>Search By Circuit ID<span className={style.mandatory}>*</span></label>
											<input type='text' className={`${style.inputFld}`} id="searchCircuit" name="searchCircuit" onChange={(ev) => props.updateOrderCircuitSearch(ev.target.value)} value={selectedOrderCircuitSearch}></input>
											<p className='text-danger'>{`There are ${selectedOrderCircuitListCount} circuits available. Please provide part of the Circuit ID and click search.`}</p>
										</div>
										<div className={` col-1 col-sm-1 col-md-1 col-lg-1 ${style.colGrp}`}>
											<button type="button" className={`ico-button lhs light-button ${style.nextBtn} ml-3`} onClick={() => searchCircuitList()}>Search</button>
										</div>
									</> : null

								}
								{Number(selectedOrderCircuitListCount) < 5000 ?
									<div className={` col-12 col-sm-6 col-md-4 col-lg-4 ${style.colGrp}`}>
										<label className={style.labelText}>Existing Circuit ID<span className={style.mandatory}>*</span></label>
										<Select
											defaultValue={{ value: selectedOrderServiceInfo?.ex_circuit[0], label: selectedOrderServiceInfo?.ex_circuit[0] }}
											onChange={(event) => existingCircuitHandler(event, 0)}
											options={existingCircuitOptions.map((item) => {
												return { value: item.circuit, label: item.circuitId }
											})}
											isSearchable
											isClearable
											noOptionsMessage={() => 'Loading Existing Circuits'}
										/>
									</div> : null
								}
								{displayManualField ?
									<div className={` col-12 col-sm-6 col-md-4 col-lg-4 ${style.colGrp}`}>
										<label className={style.labelSelectRequiredFld}> Manual Circuit ID<span className={style.mandatory}>*</span></label>
										<input type="text" className={`${style.inputFld} mt-3`} id="manual" onChange={(ev) => manualCircuitHandler(ev.target.value, 0)} value={selectedOrderServiceInfo?.manual_circuit[0]} />
									</div>
									: null
								}
								{displayValidateCircuit ?
									<div className={` col-12 col-sm-6 col-md-4 col-lg-4 ${style.colGrp}`}>
										<button className={` ico-button lhs light-button ${style.nextBtn} `} type="button" onClick={() => validateCircuit()}>
											Validate Circuit
											<svg className="ico light-ico ico-arrow">
												<path d="M0,17H29L15,31l1,1L32,16,16,0,15,1,29,15H0Z"></path>
											</svg>
										</button>
									</div>
									: null
								}
								{displayGaidField ?
									<div className={` col-12 col-sm-6 col-md-4 col-lg-4 ${style.colGrp}`}>
										<label className={style.labelSelectRequiredFld}> GAID<span className={style.mandatory}>*</span></label>
										<input type="text" className={`${style.inputFld} mt-3`} id="gaid" onChange={(ev) => props.updateOrderGaid(ev.target.value)} value={selectedOrderServiceInfo?.gaid} />
									</div> : null
								}
							</> : null
						}
						{selectedQuotedData.q_cpt_activity === "R" && selectedOrderServiceInfo?.ex_circuit !== "" ?
							<>
								{Object.keys(selectedOrderServiceInfo?.ex_circuit).map((circuit, idx) => {
									return (
										<div key={idx} className={` col-12 col-sm-6 col-md-4 col-lg-4 ${style.colGrp}`}>
											<label className={style.labelText}>Existing Circuit ID {idx + 1}<span className={style.mandatory}>*</span></label>
											<input type='text' className={style.inputFld} disabled='disabled' value={selectedOrderServiceInfo?.ex_circuit[idx].label}></input>
										</div>
									)
								})}</> : null
						}
						{/*MPLS AGG TAIL,  MPLS INTERNET, DIA PREM: Ethernet*/}
						{selectedQuotedData.product_id === "6" || selectedQuotedData.product_id === "13" || (selectedQuotedData.product_id === "11" && selectedQuotedData.circuit_id === "6") ?
							<>
							{selectedQuotedData.q_cpt_activity !== "R" ?
							<>
								<div className={` col-12 col-sm-6 col-md-4 col-lg-4 ${style.colGrp}`}>
									<label className={style.labelText}>CPE Provider<span className={style.mandatory}>*</span></label>
									<input type='text' className={style.inputFld} disabled='disabled' value={selectedQuotedData.cpe_type}></input>
								</div>
								<div className={` col-12 col-sm-6 col-md-4 col-lg-4 ${style.colGrp}`}>
									<label className={style.labelText}>Internet Router Service<span className={style.mandatory}>*</span></label>
									<input type='text' className={style.inputFld} disabled='disabled' value={selectedQuotedData.router_service}></input>
								</div>
							</>
							: null}
								<div className={` col-12 col-sm-6 col-md-4 col-lg-4 ${style.colGrp}`}>
									<label className={style.labelText}>Optional Mounting Equipment</label>
									<select className={style.selectFld} id='mounting_equip' name='mounting_equip' onChange={inputHandler} value={selectedOrderServiceInfo.mounting_equip}>
										<option></option>
										<option value="rack">ACS-4320-RM-19 (Rack SKU - 19")</option>
									</select>
								</div>
								<div className={` col-12 col-sm-6 col-md-4 col-lg-4 ${style.colGrp}`}>
									<label className={style.labelText}>Optional WAN SFP</label>
									<select className={style.selectFld} id='wan_sfp' name='wan_sfp' onChange={inputHandler} value={selectedOrderServiceInfo.wan_sfp}>
										<option></option>
										<option value="wan_sfp">GLC-SX-MMD (Multi Mode)</option>
									</select>
								</div>
							</> : null
						}
						{/*Colocation*/}
						{selectedQuotedData.product_id === "15" ?
							<>
								<div className={` col-12 col-sm-6 col-md-4 col-lg-4 ${style.colGrp}`}>
									<label className={style.labelText}>Rack Space<span className={style.mandatory}>*</span></label>
									<input type='text' className={style.inputFld} id='rack_space' name='rack_space' value={selectedQuotedData.rack_space} disabled='disabled'></input>
								</div>
								<div className={` col-12 col-sm-6 col-md-4 col-lg-4 ${style.colGrp}`}>
									<label className={style.labelText}>Primary Power<span className={style.mandatory}>*</span></label>
									<input type='text' className={style.inputFld} id='primary_power' name='primary_power' value={selectedQuotedData.primary_power} disabled='disabled'></input>
								</div>
								<div className={` col-12 col-sm-6 col-md-4 col-lg-4 ${style.colGrp}`}>
									<label className={style.labelText}>Secondary Power<span className={style.mandatory}>*</span></label>
									<input type='text' className={style.inputFld} id='secondary_power' name='secondary_power' value={selectedQuotedData.secondary_pwr} disabled='disabled'></input>
								</div>
								<div className={` col-12 col-sm-6 col-md-4 col-lg-4 ${style.colGrp}`}>
									<label className={style.labelText}>Breaker Amperage<span className={style.mandatory}>*</span></label>
									<input type='text' className={style.inputFld} id='breaker_amp' name='breaker_amp' value={selectedQuotedData.breaker_amp} disabled='disabled'></input>
								</div>
								<div className={` col-12 col-sm-6 col-md-4 col-lg-4 ${style.colGrp}`}>
									<label className={style.labelText}>Fuse Panel<span className={style.mandatory}>*</span></label>
									<input type='text' className={style.inputFld} id='fuse_panel' name='fuse_panel' value={selectedQuotedData.fuse} disabled='disabled'></input>
								</div>
							</>
							: null}
					</div>
				</div>
			</div>)}
			<div className={style.dividers}></div>
			{Number(selectedQuotedData.product_id) < 4 && selectedQuotedData.circuit_id === "7" && selectedOrderGeneralInfo?.initial_term && selectedOrderServiceInfo?.bandwidth ?
				<OrderPricingInfo bandwidth={selectedOrderServiceInfo.bandwidth} term={selectedOrderGeneralInfo.initial_term} lineItems={selectedQuotedData.pricing?.lineitems} qty={selectedOrderServiceInfo.order_qty} sectionName={"Pricing Information"} termCnt={selectedQuotedData.pricing.termCnt} /> : null
			}
		</>
	)

}


const mapDispatchToProps = {
	updateOrderActivity: setOrderActivity,
	updateOrderQty: setOrderQty,
	updateOrderBW: setOrderBW,
	updateOrderCircuitListCount: setOrderCircuitListCount,
	updateOrderCircuitSearch: setOrderCircuitSearch,
	updateOrderExistingCircuit: setOrderExistingCircuit,
	updateOrderManualCircuit: setOrderManualCircuit,
	updateOrderGaid: setOrderGaid,
	updateOrderMountingEquipment: setOrderMountingEquipment,
	updateOrderWanSFP: setOrderWanSFP

}

export default connect(null, mapDispatchToProps)(OrderServiceInfo);
