import {
	QUOTE_BUS_NAME,
	QUOTE_CONTACT_EMAIL,
	QUOTE_CONTACT_NAME,
	QUOTE_CONTACT_PHONE,
	QUOTE_CUST_REF_ID,
	QUOTE_MANUAL_ICB,
	QUOTE_MANUAL_ICB_COMMENTS,
	QUOTE_NEW_CONTACT_NAME,
	QUOTE_PRICE_MRC,
	RESET_ACTION
} from "../actions/types/actionTypes"

const initialState = {
    contactValues: {
        name: "",
        newName: "",
        phone: "",
        email: "",
        busName: "",
        refId: "",
        mrcPrice: "",
        manual_icb: false,
        icb_comments: ""  
    }
};


const quoteContactReducer = (state = initialState, action) => {
    switch (action.type) {
        case QUOTE_CONTACT_NAME:
            return {
                ...state,
                contactValues: {
                    ...state.contactValues,
                    name: action.payload
                }
            }
        case QUOTE_NEW_CONTACT_NAME:
            return {
                ...state,
                contactValues: {
                    ...state.contactValues,
                    newName: action.payload
                }
            }
        case QUOTE_CONTACT_PHONE:
            return {
                ...state,
                contactValues: {
                    ...state.contactValues,
                    phone: action.payload
                }
            }
        case QUOTE_CONTACT_EMAIL:
            return {
                ...state,
                contactValues: {
                    ...state.contactValues,
                    email: action.payload
                }
            }
        case QUOTE_BUS_NAME:
            return {
                ...state,
                contactValues: {
                    ...state.contactValues,
                    busName: action.payload
                }
            }
        case QUOTE_CUST_REF_ID:
            return {
                ...state,
                contactValues: {
                    ...state.contactValues,
                    refId: action.payload
                }
            }
        case QUOTE_PRICE_MRC:
            return {
                ...state,
                contactValues: {
                    ...state.contactValues,
                    mrcPrice: action.payload
                }
            }
        case QUOTE_MANUAL_ICB:
            return {
                ...state,
                contactValues: {
                    ...state.contactValues,
                    manual_icb: action.payload
                }
            }
        case QUOTE_MANUAL_ICB_COMMENTS:
            return {
                ...state,
                contactValues: {
                    ...state.contactValues,
                    icb_comments: action.payload
                }
            }
        case RESET_ACTION:
            return initialState;
        default:
            return state
    }
}

export default quoteContactReducer;
