import style from "./Report.module.css";
import XLSXExport from "../../utils/XLSXExport";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import filterFactory from "react-bootstrap-table2-filter";

const InvoiceReport = ({ InvoiceData }) => {
	const columns = [{
		dataField: "lineType",
		text: "Line Type",
	},
	{
		dataField: "parentAcct",
		text: "Parent Account"
	},
	{
		dataField: "address",
		text: "Address",
		formatter: (cell, row) => addressFormatter(cell, row)
	},
	{
		dataField: "invId",
		text: "Invoice ID"
	},
	{
		dataField: "invDate",
		text: "Invoice Date"
	},
	{
		dataField: "transType",
		text: "Transaction Type"
	},
	{
		dataField: "typeDesc",
		text: "Type Description"
	},
	{
		dataField: "chgDesc",
		text: "Charge Description"
	},
	{
		dataField: "baseChgDesc",
		text: "Additional Charge Description"
	},
	{
		dataField: "description2",
		text: "Description"
	},
	{
		dataField: "connection",
		text: "Connection"
	},
	{
		dataField: "fromDt",
		text: "From Date"
	},
	{
		dataField: "toDt",
		text: "To Date"
	},
	{
		dataField: "unitPrice",
		text: "Unit Price"
	},
	{
		dataField: "quantity",
		text: "Quantity"
	},
	{
		dataField: "amount",
		text: "Amount"
	},
	{
		dataField: "bndlePiece",
		text: "Bundle Piece"
	},
	{
		dataField: "bndleFlag",
		text: "Bundle Flag"
	},
	{
		dataField: "minutes",
		text: "Minutes"
	},
	{
		dataField: "calls",
		text: "Calls"
	},
	{
		dataField: "prorate",
		text: "Prorate"
	}];

	const XLSXHeaders = ["Line Type", "Parent Account", "Account", "Account Name", "Address 1", "Address 2", "City", "State", "Zip", "Invoice ID", "Invoice Date",
		"Transaction Type", "Type Description", "Charge Description", "Additional Charge Description", "Description", "Connection", "From Date", "To Date",
		"Unit Price", "Quantity", "Amount", "Bundle Piece", "Bundle Flag", "Minutes", "Calls", "Prorate"];

	/*Function to concatenate address into a single column*/
	const addressFormatter = (props, row) => {
		let completeAddress = '';
		if (row.addr1?.length > 0) {
			completeAddress += row.addr1;
		}
		if (row.addr2?.length > 0) {
			completeAddress += " " + row.addr2;
		}
		if (row.city?.length > 0) {
			completeAddress += ", " + row.city;
		}
		if (row.state?.length > 0) {
			completeAddress += ", " + row.state;
		}
		if (row.zip?.length > 0) {
			completeAddress += " " + row.zip;
		}
		return completeAddress;
	}


	return (
		<>
			<div className='d-flex mb-5 ml-3'>
				<div className="w15 mr-5">
					<button className="saveButton ico-button light-button" onClick={() => XLSXExport.exportToExcel(XLSXHeaders, InvoiceData, `Invoice_Report${Date.now()}`, 'AA')}><span><strong>Export to Excel</strong></span>
						<svg viewBox="0 0 34 34" className={`ico light-ico ico-arrow downloadArrow`}>
							<path className="cls-1" d="M1,18V16L17,31V1h2V31L35,16v2L20,33H35v2H1V33H16Z"
								transform="translate(-1 -1)">
							</path>
						</svg>
					</button>
				</div>
				<div className="w15 ">
					<button className="saveButton ico-button light-button" onClick={() => XLSXExport.exportToExcel(XLSXHeaders, InvoiceData, `Invoice_Report${Date.now()}`, 'AA', '.csv')}><span><strong>Export to CSV</strong></span>
						<svg viewBox="0 0 34 34" className={`ico light-ico ico-arrow downloadArrow`}>
							<path className="cls-1" d="M1,18V16L17,31V1h2V31L35,16v2L20,33H35v2H1V33H16Z"
								transform="translate(-1 -1)">
							</path>
						</svg>
					</button>
				</div>
			</div>

			<BootstrapTable
				id="invoiceTable"
				classes={`${style.invoiceTable} table-fixed table table-responsive `}
				bodyClasses={`${style.invoiceTableRows}`}
				keyField='invId'
				data={InvoiceData}
				columns={columns}
				bootstrap4
				striped
				hover
				bordered={false}
				pagination={paginationFactory({
					paginationSize: 2,
					pageStartIndex: 1,
					sizePerPage: 5
				})}
				filter={filterFactory()}
				loading={true}
			/>
		</>
	)
}

export default InvoiceReport;
