import React, { useEffect, useState, useMemo } from "react";
import { ConsoleLogger } from "../../logger/ConsoleLogger";
import style from '../quote/QuoteView.module.css';
import Loader from '../shared/Loader';
import InternalQuoteHelper from '../../services/InternalQuoteService';

const VariablePricing = (props) => {
	const logger = useMemo(() => new ConsoleLogger({ level: process.env.REACT_APP_LOGGER_LEVEL, }), []);
	const isEmployee = localStorage.getItem('isEmployeeLoggedIn');
	const [loader, setLoader] = useState(false); // loader
	const [updateMsg, setUpdateMsg] = useState("");
	const [errorMsg, setErrorMsg] = useState("");
	const [pricingPermissionFlag, setPricingPermissionFlag] = useState(false);
	const [icbPricingType, setIcbPricingType] = useState("");
	const [icbVariableLineItems, setIcbVariableLineItems] = useState([]);
	const [variableLineItems, setVariableLineItems] = useState([]);
	const [popupShow, setPopupShow] = useState(false);
	const [popUpMsg, setPopupMsg] = useState("");
	const [xUidArray, setXUidArray] = useState([]);
	const [monthErrorMsg, setMonthErrorMsg] = useState("");
	const [pricingTitle, setPricingTitle] = useState("");
	var regex = /[0-9]/;

	useEffect(() => {
		logger.info("+++++++++++++++++++++++++++++++++++++++++++++++++++");
		logger.info("props.pricingType: " + JSON.stringify(props.pricingType));
		setPricingPermissionFlag(props.pricingPermissionFlag);
		setIcbPricingType(props.pricingType);
		setIcbVariableLineItems(props.icbVariableLineItems);
		//logger.info(`ORIGINAL ICB VARIABLE LINEITEM STATE: ${JSON.stringify(props.icbVariableLineItems)}`);
		if (props.pricingType === 'Variable' || props.pricingType === 'Combined') {
			setVariableLineItems(props.variableLineItems);
			logger.info(`VARIABLE LINEITEM STATE in Variable Pricing file : ${JSON.stringify(props.variableLineItems)}`);
		}

		switch (props.pricingType) {
			case 'Variable':
				setPricingTitle("VARIABLE RATE MODEL Option: no NRC, variable MRC");
				break;
			case 'BuyDown':
				setPricingTitle("Buy Down Option Option");
				break;
			case 'Combined':
				setPricingTitle("Variable MRR with Buy Down");
				break;
			default:
				break;
		}
	}, [logger, props.pricingPermissionFlag, props.pricingType, props.icbVariableLineItems, props.variableLineItems])

	const deleteRowHandler = (uidArray) => {
		console.log('UID: ' + JSON.stringify(uidArray));
		setXUidArray(uidArray);
		setPopupShow(true);
		setPopupMsg("Are you sure you want to delete Cross Connect line item?");
	}

	const popupCancel = () => {
		setPopupShow(false);
	}

	const popupConfirm = () => {
		setPopupShow(false);
		setLoader(true);
		InternalQuoteHelper.deleteLineItem(props.refId, xUidArray).then((res) => {
			if (res.data.result === "SUCCESS") {
				setUpdateMsg("Line item deleted successfully.");
				setLoader(false);
				window.location.reload();
			} else {
				setErrorMsg("Error occurred while deleting line item. ");
				setLoader(false);
			}
		})
	}

	const icbVariablePriceUpdateHandler = (value, field, term, line) => {
		logger.info(`ORIGINAL LINEITEM STATE ICB VARIABLE THAT GETS UPDATED: ${JSON.stringify(icbVariableLineItems)}`);
		logger.info(`Value updated: ${value}`);
		logger.info(`Field updated: ${field}`);
		logger.info(`Term: ${term}`);
		logger.info(`Line: ${line}`);

		//Preventing the deletion of the input field
		if (value === "") {
			value = " ";
		}
		//copying existing nested object so it can be updated with the value entered
		let updatedLineItems = { ...icbVariableLineItems, [term]: { ...icbVariableLineItems[term], [line]: { ...icbVariableLineItems[term][line] } } };
		updatedLineItems[term][line][field] = value;
		logger.info(`UPDATED ICB VARIABLE LINE ITEMS THAT GET SENT TO THE PRICING UPDATE SERVICE: ${JSON.stringify(updatedLineItems)}`);
		setIcbVariableLineItems(updatedLineItems);
		props.onChangeIcbVariable(updatedLineItems);
	}

	const variablePriceUpdateHandler = (value, field, term, line) => {
		logger.info(`ORIGINAL LINEITEM STATE VARIABLE THAT GETS UPDATED: ${JSON.stringify(variableLineItems)}`);
		logger.info(`Value updated: ${value}`);
		logger.info(`Field updated: ${field}`);
		logger.info(`Field Term: ${term}`);
		logger.info(`Field Line: ${line}`);

		if (field === "end_month_floor" && (isNaN(value) || (value < 1) || (value > (term * 12)))) {
			setMonthErrorMsg("End Month must be a number and can't be greater than the term in months.");
			value = "";
		} else {
			setMonthErrorMsg("");
		}
		//Preventing the deletion of the input field
		if (value === "") {
			value = " ";
		}
		//copying existing nested object so it can be updated with the value entered
		let updatedLineItems = { ...variableLineItems, [term]: { ...variableLineItems[term], [line]: { ...variableLineItems[term][line] } } };
		updatedLineItems[term][line][field] = value;
		logger.info(`UPDATED VARIABLE LINE ITEMS THAT GET SENT TO THE PRICING UPDATE SERVICE: ${JSON.stringify(updatedLineItems)}`);
		setVariableLineItems(updatedLineItems);
		props.onChangeVariable(updatedLineItems);
	}

	return (
		<>
			<div className='col-12 col-sm-12 col-md-12 col-lg-12'>
				{loader ? <Loader /> : null}
				<h6 className={style.headingS}>
					{pricingTitle}
				</h6>

				<>
					{Object.keys(icbVariableLineItems).map((term, col) => {
						console.log("Term: " + term + " Col: " + col);
						if (term.match(regex) || term === 'Delete') {
							return (
								<>
									<table key={props.key} className={`table table-bordered ${style.tableIcb}  `}>
										<thead>
											{col === 0 ? <th>Product</th> : ''}
											{term === 'Delete' ?
												<>
													<th>Delete</th>
												</>
												:
												<>
													<th>{term} Year</th>
												</>
											}
										</thead>
										<tbody>
											<tr>
												{col === 0 ? <td>&nbsp;</td> : null}
												{term === 'Delete' ? <td>&nbsp;</td> :
													<>
														<td><b>MRC</b></td>
														<td><b>NRC</b></td>
													</>
												}
											</tr>
											{Object.keys(icbVariableLineItems[term]).map((line, row) => {
												console.log("Line: " + line + " Row: " + row);

												return (
													<>
														{line.match(regex) ?
															<tr key={props.key}>
																{col === 0 ? <td>{icbVariableLineItems[term][line].line_desc + " - Floor"} </td> : ''}
																{icbVariableLineItems[term][line].floor_mrc ?
																	<>
																		<td>
																			{isEmployee && pricingPermissionFlag && icbVariableLineItems[term][line].floor_mrc !== "NO BID" ?
																				<input type="text" className={style.inputFld} id="floor_mrc" onChange={(event) => icbVariablePriceUpdateHandler(event.target.value, event.target.id, term, line)} value={icbVariableLineItems[term][line].floor_mrc?.length > 0 ? icbVariableLineItems[term][line].floor_mrc : " "}></input>
																				:
																				<input type="text" className={style.inputFld} id="floor_mrc" disabled></input>
																			}
																		</td>
																		<td>
																			{isEmployee && pricingPermissionFlag && icbVariableLineItems[term][line].floor_nrc !== "NO BID" ?
																				<input type="text" className={style.inputFld} id="floor_nrc" onChange={(event) => icbVariablePriceUpdateHandler(event.target.value, event.target.id, term, line)} value={icbVariableLineItems[term][line].floor_nrc?.length > 0 ? icbVariableLineItems[term][line].floor_nrc : " "}></input>
																				:
																				<input type="text" className={style.inputFld} id="floor_nrc" disabled></input>
																			}
																		</td>
																	</>
																	:
																	null
																}
																{icbVariableLineItems[term][line]?.loc === 'A' && icbVariableLineItems[term][line]?.delete === 1 ?
																	<>
																		<td style={{ padding: '15px 10px 16px 20px' }}>
																			<button className="btn ico-link light-ico tipped" onClick={() => deleteRowHandler(icbVariableLineItems[term][line]["A"])}>
																				<svg className="ico-x"><path d="M18,17l-1,1L9,10,1,18,0,17,8,9,0,1,1,0,9,8l8-8,1,1L10,9Z"></path></svg>
																			</button>
																		</td>
																	</>
																	: icbVariableLineItems[term][line]?.loc === 'Z' && icbVariableLineItems[term][line]?.delete === 1 ?
																		<>
																			<td style={{ padding: '15px 10px 16px 20px' }}>
																				<button className="btn ico-link light-ico tipped" onClick={() => deleteRowHandler(icbVariableLineItems[term][line]["Z"])}>
																					<svg className="ico-x"><path d="M18,17l-1,1L9,10,1,18,0,17,8,9,0,1,1,0,9,8l8-8,1,1L10,9Z"></path></svg>
																				</button>
																			</td>
																		</>
																		: icbVariableLineItems[term][line]?.delete === 1 || icbVariableLineItems[term][line]?.delete === 0 ?
																			<td style={{ padding: '15px 9px' }}>&nbsp;</td>
																			: null
																}
															</tr> : null}
														{line.match(regex) ?
															<tr key={props.key}>
																{col === 0 ? <td>{icbVariableLineItems[term][line].line_desc + " - Netcost"} </td> : ''}

																{icbVariableLineItems[term][line].netcost_mrc ?
																	<>
																		<td>
																			{isEmployee && pricingPermissionFlag && icbVariableLineItems[term][line].netcost_mrc !== "NO BID" ?
																				<input type="text" className={style.inputFld} id="netcost_mrc" onChange={(event) => icbVariablePriceUpdateHandler(event.target.value, event.target.id, term, line)} value={icbVariableLineItems[term][line].netcost_mrc?.length > 0 ? icbVariableLineItems[term][line].netcost_mrc : " "}></input>
																				:
																				<input type="text" className={style.inputFld} id="netcost_mrc" disabled></input>
																			}
																		</td>
																		<td>
																			{isEmployee && pricingPermissionFlag && icbVariableLineItems[term][line].netcost_nrc !== "NO BID" ?
																				<input type="text" className={style.inputFld} id="netcost_nrc" onChange={(event) => icbVariablePriceUpdateHandler(event.target.value, event.target.id, term, line)} value={icbVariableLineItems[term][line].netcost_nrc?.length > 0 ? icbVariableLineItems[term][line].netcost_nrc : " "}></input>
																				:
																				<input type="text" className={style.inputFld} id="netcost_mrc" disabled></input>
																			}
																		</td>
																	</>
																	:
																	null
																}
																{icbVariableLineItems[term][line]?.delete === 1 || icbVariableLineItems[term][line]?.delete === 0 ?
																	<td style={{ padding: '15px 10px' }}>&nbsp;</td>
																	: null
																}
															</tr>
															: null
														}
													</>
												)

											})}

										</tbody>
									</table>
								</>

							)
						}

					})}
				</>





				{icbPricingType === 'Variable' || icbPricingType === 'Combined' ?

					<>
						{monthErrorMsg ? <p className="text-danger">{monthErrorMsg}</p> : null}
						<div className={`row ${style.rowGrp} ${style.variablePricingRow}`}>
							{Object.keys(variableLineItems).map((term, col) => {
								console.log("Term: " + term + " Col: " + col);
								if (term.match(regex)) {
									return (
										<>
											<table className={`table table-bordered ${style.tableIcb}  `}>
												<thead>
													{col === 0 ? <th>Product</th> : ''}
													<th>{term} Year</th>
												</thead>
												<tbody>
													<tr>
														{col === 0 ? <td>&nbsp;</td> : null}
														{col === 0 ?
															<>
																<td><b>MRC</b></td>
																<td><b>NRC</b></td>
																{props.termCnt === 1 ? <td><b>End Month</b></td> : <td><b>End Month (all terms)</b></td>}
															</>
															:
															<>
																<td><b>MRC</b></td>
																<td><b>NRC</b></td>
															</>
														}
													</tr>
													{Object.keys(variableLineItems[term]).map((line, row) => {
														console.log("Line: " + line + " Row: " + row);

														return (
															<>
																{line.match(regex) ?
																	<tr key={row}>
																		{col === 0 ? <td>{variableLineItems[term][line].line_desc + " - Floor"} </td> : ''}
																		{variableLineItems[term][line].floor_mrc ?
																			<>
																				<td>
																					{isEmployee && pricingPermissionFlag && variableLineItems[term][line].floor_mrc !== "NO BID" ?
																						<input type="text" className={style.inputFld} id="floor_mrc" onChange={(event) => variablePriceUpdateHandler(event.target.value, event.target.id, term, line)} value={variableLineItems[term][line].floor_mrc?.length > 0 ? variableLineItems[term][line].floor_mrc : " "}></input>
																						:
																						<input type="text" className={style.inputFld} id="floor_mrc" disabled></input>
																					}
																				</td>
																				<td>
																					{isEmployee && pricingPermissionFlag && variableLineItems[term][line].floor_nrc !== "NO BID" ?
																						<input type="text" className={style.inputFld} id="floor_nrc" onChange={(event) => variablePriceUpdateHandler(event.target.value, event.target.id, term, line)} value={variableLineItems[term][line].floor_nrc?.length > 0 ? variableLineItems[term][line].floor_nrc : " "}></input>
																						:
																						<input type="text" className={style.inputFld} id="floor_nrc" disabled></input>
																					}
																				</td>
																				{col === 0 ?
																					<td>
																						{isEmployee && pricingPermissionFlag && variableLineItems[term][line].loc !== "Z" ?
																							<input type="text" className={style.inputFld} id="end_month_floor" onChange={(event) => variablePriceUpdateHandler(event.target.value, event.target.id, term, line)} value={variableLineItems[term][line].end_month_floor?.length > 0 ? variableLineItems[term][line].end_month_floor : " "}></input>
																							:
																							variableLineItems[term][line].end_month_floor
																						}
																					</td>
																					: null}
																			</>
																			:
																			null
																		}
																	</tr> : null}
																{line.match(regex) ?
																	<tr key={row}>
																		{col === 0 ? <td>{variableLineItems[term][line].line_desc + " - Netcost"} </td> : ''}

																		{variableLineItems[term][line].netcost_mrc ?
																			<>
																				<td>
																					{isEmployee && pricingPermissionFlag && variableLineItems[term][line].netcost_mrc !== "NO BID" ?
																						<input type="text" className={style.inputFld} id="netcost_mrc" onChange={(event) => variablePriceUpdateHandler(event.target.value, event.target.id, term, line)} value={variableLineItems[term][line].netcost_mrc?.length > 0 ? variableLineItems[term][line].netcost_mrc : " "}></input>
																						:
																						<input type="text" className={style.inputFld} id="netcost_mrc" disabled></input>
																					}
																				</td>
																				<td>
																					{isEmployee && pricingPermissionFlag && variableLineItems[term][line].netcost_nrc !== "NO BID" ?
																						<input type="text" className={style.inputFld} id="netcost_nrc" onChange={(event) => variablePriceUpdateHandler(event.target.value, event.target.id, term, line)} value={variableLineItems[term][line].netcost_nrc?.length > 0 ? variableLineItems[term][line].netcost_nrc : " "}></input>
																						:
																						<input type="text" className={style.inputFld} id="netcost_nrc" disabled></input>
																					}
																				</td>
																				{col === 0 ?
																					<td>
																						{/* only display on end month field
																								{isEmployee && pricingPermissionFlag && variableLineItems[option][term][line].end_month_netcost !== "NO BID" ?
																									<input type="text" className={style.inputFld} id="end_month_netcost" onChange={(event) => variablePriceUpdateHandler(event.target.value, event.target.id, option, term, line)} value={variableLineItems[option][term][line].end_month_netcost?.length > 0 ? variableLineItems[option][term][line].end_month_netcost : " "}></input>
																									:
																									variableLineItems[option][term][line].end_month_netcost
																								}
																								*/}
																					</td>
																					: null}
																			</>
																			:
																			null
																		}
																	</tr>
																	: null
																}
															</>
														)

													})}

												</tbody>
											</table>
											<div>

											</div>
										</>

									)
								}

							})}
						</div>
					</>

					: null}

				<div>
					{updateMsg ? <p className="text-success">{updateMsg}</p> : null}
					{errorMsg ? <p className="text-danger">{errorMsg}</p> : null}
				</div>
				{(popupShow ?
					<div>
						<div className={`${style.overly}`}> </div>
						<div className="modal fade show d-block">
							<div className={` modal-dialog modal-dialog-centered modal-sm`}>
								<div className="modal-content">
									<div className="modal-body">
										{popUpMsg}
									</div>

									<div className="modal-footer d-block text-center">
										<button type="button" className="btn-style btn-theme" data-dismiss="modal" onClick={() => popupConfirm()}>Ok</button>
										<button type="button" className="btn-style btn-cancel" data-dismiss="modal" onClick={() => popupCancel()}>Cancel</button>
									</div>

								</div>
							</div>
						</div>
					</div>
					: null)
				}
			</div>
		</>
	);
};
export default VariablePricing;
