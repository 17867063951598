import axios from "./axios/customAxios";


const getOfficeSuiteFeatures = async () => {
	const dataURL =
		`${process.env.REACT_APP_API_URL}/OfficeSuite.php?action=getFeatures`;
	return await axios.get(dataURL);
}

const getCustomPricing = async (item_type, term, qty) => {
	const dataURL =
		`${process.env.REACT_APP_API_URL}/OfficeSuite.php?action=specialPricing&item_type=${item_type}&term=${term}&qty=${qty}`;
	return await axios.get(dataURL);
}

const createQuote = async (values) => {
	const dataURL =
		`${process.env.REACT_APP_API_URL}/quoteSubmit.php?data=${encodeURIComponent(JSON.stringify(values))}`;
	return await axios.get(dataURL);
}

const createOrder = async (ref_id) => {
	const dataURL =
		`${process.env.REACT_APP_API_URL}/OfficeSuite.php?action=createOrder&ref_id=${ref_id}`;
	return await axios.get(dataURL);
}

const OfficeSuiteHelper = {
	getOfficeSuiteFeatures,
	getCustomPricing,
	createQuote,
	createOrder
}

export default OfficeSuiteHelper