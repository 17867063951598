// CSS Imports
import style from "./TicketDetails.module.css";


const Notes = ({ allNotes }) => {

    let allNotesDisplay = [];

    for (let i = 0; i < allNotes.length; i++) {
        allNotesDisplay.push(
            <>
                <div className={`row ${style.rowGrp}`}>
                    <div className='col-md-6'>
                        <ul className={` ${style.productList}`}>
                            <li>Date</li>
                            <li>{allNotes[i].submittedDate}</li>
                        </ul>
                    </div>

                    <div className='col-md-6'>
                        <ul className={` ${style.productList}`}>
                            <li>Entered By</li>
                            <li>{allNotes[i].submittedBy}</li>
                        </ul>
                    </div>
                </div>
                <div className={`row ${style.rowGrp}`}>
                    <div className='col-md-12'>
                        <ul className={style.noteList}>
                            <li>Note: </li>
                            <li className={style.breakLine}>{allNotes[i].notes}</li>
                        </ul>
                    </div>
                </div>
            </>
        );
    }

    return allNotesDisplay;

}



export default Notes;
