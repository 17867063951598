import axios from "./axios/customAxios";

const getPremBW = async (circuitType) => {
	const premBWUrl = `${process.env.REACT_APP_API_URL}/getBandwidths.php?product=11&menu=bw&circuit=${circuitType}`;
	return await axios.get(premBWUrl);
}

const getPremIPs = async (circuitType) => {
	const premIPUrl = `${process.env.REACT_APP_API_URL}/getBandwidths.php?product=11&menu=ip&circuit=${circuitType}`;
	return await axios.get(premIPUrl);
}

const getBroadbandBW = async (adsl, cable, ips, terms, address) => {
	const broadbandBWUrl = `${process.env.REACT_APP_API_URL}/getBamValidation.php?adsl=${adsl}&cable=${cable}&ips=${ips}&terms=${JSON.stringify(terms)}&address=${JSON.stringify(address)}`
	return await axios.get(broadbandBWUrl);
}


const DIAPremHelper = {
    getPremBW,
    getPremIPs,
    getBroadbandBW
}

export default DIAPremHelper;
