import axios from "./axios/customAxios";

const getPopLocations = async () => {
	const popLocationURL = `${process.env.REACT_APP_API_URL}/getAddress.php?type=dia_pop`;
    return await axios.get(popLocationURL);
}

const getPopBW = async () =>{
	const popBWUrl = `${process.env.REACT_APP_API_URL}/getBandwidths.php?product=10&menu=bw`;
    return await axios.get(popBWUrl);
}

const getPopIPs = async () => {
	const popIPUrl = `${process.env.REACT_APP_API_URL}/getBandwidths.php?product=10&menu=ip`;
    return await axios.get(popIPUrl);
}


const DIAPopHelper = {
    getPopLocations,
    getPopBW,
    getPopIPs
}

export default DIAPopHelper;
