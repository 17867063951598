import React from 'react';
import ViewportWarning from "../shared/ViewportWarning";

const OneTimePayment = () => {
    return (
        <div className="midsection qm-upload abs z1">
            <div className="rel">
                <ViewportWarning />
                <div className="wrap">
                    <h1 className="page-title">One Time Payment</h1>
                    <div className="section">
                        <div className="content">Please make one-time bill payments using Windstream's billing number: 855-465-0204</div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default OneTimePayment;
