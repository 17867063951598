import { useEffect, useState } from 'react';
import { Container, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import Link from '@mui/material/Link';
import PdfComponent from '../PDF/PDFComponent.js';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
	[`&.${tableCellClasses.head}`]: {
		backgroundColor: '#753BBD',
		color: theme.palette.common.white,
		fontSize: 16
	},
	[`&.${tableCellClasses.body}`]: {
		fontSize: 14,
	},
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
	'&:nth-of-type(odd)': {
		backgroundColor: theme.palette.action.hover,
	},
	// hide last border
	'&:last-child td, &:last-child th': {
		border: 0,
	},
}));

const StyledLink = styled(Link)(({ theme }) => ({
	color: '#753BBD',
	textDecoration: 'none',
	'&:hover': {
		textDecoration: 'underline',
	},
}));

const CsrReport = ({ csrData }) => {
	const [pdfUrl, setPdfUrl] = useState('');

	const handlePdfGenerated = (url) => {
		setPdfUrl(url);
	};

	useEffect(() => {
		// Trigger PDF generation when the component mounts
		const fetchPdfUrl = async () => {
			// No need to await anything here, PdfComponent will handle it
		};
		fetchPdfUrl();
	}, [csrData]);

	return (
		<>
			{csrData[0]["customerInfos"]?.length > 0 || csrData[0]["productSummaries"]?.length > 0 || csrData[0]["pics"]?.length > 0 || csrData[0]["tnSets"]?.length > 0 ?
				<Container>
					<PdfComponent
						reportData={csrData[0]}
						action="download"
						type="CSR"
						comp="csrReport"
						onPdfGenerated={handlePdfGenerated}
					/>

					<Grid container justifyContent="space-between" alignItems="center" style={{ marginBottom: 20 }}>
						<Grid item>
							<Typography variant="h5" gutterBottom>Windstream Customer Service Record</Typography>
						</Grid>
						<Grid item>
							{pdfUrl && (
								<StyledLink href={pdfUrl} underline="none" target="_blank" rel="noopener">
									<Typography variant="body1">Download PDF</Typography>
								</StyledLink>
							)}
						</Grid>
					</Grid>

					<Grid container spacing={4}>
						{csrData[0]["customerInfos"]?.length > 0 ?
							<Grid item xs={12}>
								<Paper>

									<TableContainer>
										<Table>
											<TableHead>
												<StyledTableRow>
													<StyledTableCell colSpan={8}>Account</StyledTableCell>
												</StyledTableRow>
											</TableHead>
											<TableBody>
												<StyledTableRow>
													<StyledTableCell align="center" sx={{ fontWeight: 'bold' }}>Company Name</StyledTableCell>
													<StyledTableCell align="center">{csrData[0]["customerInfos"][0]["companyName"]}</StyledTableCell>
													<StyledTableCell align="center" sx={{ fontWeight: 'bold' }}>Status</StyledTableCell>
													<StyledTableCell align="center">{csrData[0]["customerInfos"][0]["accountStatus"]}</StyledTableCell>
												</StyledTableRow>
												<StyledTableRow>
													<StyledTableCell align="center" sx={{ fontWeight: 'bold' }}>Address</StyledTableCell>
													<StyledTableCell align="center">{csrData[0]["customerInfos"][0]["addr1"]}</StyledTableCell>
													<StyledTableCell align="center" sx={{ fontWeight: 'bold' }}>Type</StyledTableCell>
													<StyledTableCell align="center">{csrData[0]["customerInfos"][0]["custAcctType"]}</StyledTableCell>
												</StyledTableRow>
												<StyledTableRow>
													<StyledTableCell align="center"></StyledTableCell>
													<StyledTableCell align="center">{csrData[0]["customerInfos"][0]["addr2"]}</StyledTableCell>
													<StyledTableCell align="center"></StyledTableCell>
													<StyledTableCell align="center"></StyledTableCell>
												</StyledTableRow>
											</TableBody>
										</Table>
									</TableContainer>
								</Paper>
							</Grid> : null
						}
						{csrData[0]["productSummaries"]?.length > 0 ?
							<Grid item xs={6}>
								<Paper>
									<TableContainer>
										<Table>
											<TableHead>
												<StyledTableRow>
													<StyledTableCell align="center" colSpan={6}>Product Summary</StyledTableCell>
												</StyledTableRow>
											</TableHead>
											<TableBody>
												<StyledTableRow>
													<StyledTableCell align="center" sx={{ fontWeight: 'bold' }}>Product</StyledTableCell>
													<StyledTableCell align="center" sx={{ fontWeight: 'bold' }}>Quantity</StyledTableCell>
												</StyledTableRow>
												{csrData[0]["productSummaries"].map((obj, index) => (
													<StyledTableRow key={index}>
														<StyledTableCell align="center">{obj.product}</StyledTableCell>
														<StyledTableCell align="center">{obj.cnt}</StyledTableCell>
													</StyledTableRow>
												))}
											</TableBody>
										</Table>
									</TableContainer>
								</Paper>
							</Grid> : null
						}
						{csrData[0]["pics"]?.length > 0 ?
							<Grid item xs={6}>
								<Paper>
									<TableContainer>
										<Table>
											<TableHead>
												<StyledTableRow>
													<StyledTableCell align="center" colSpan={6}>PIC Info</StyledTableCell>
												</StyledTableRow>
											</TableHead>
											<TableBody>
												<StyledTableRow>
													<StyledTableCell align="center" sx={{ fontWeight: 'bold' }}>PIC</StyledTableCell>
													<StyledTableCell align="center">{csrData[0]["pics"][0]["pic"]}</StyledTableCell>
												</StyledTableRow>
												<StyledTableRow>
													<StyledTableCell align="center" sx={{ fontWeight: 'bold' }}>LPIC</StyledTableCell>
													<StyledTableCell align="center">{csrData[0]["pics"][0]["lpic"]}</StyledTableCell>
												</StyledTableRow>
												<StyledTableRow>
													<StyledTableCell align="center" sx={{ fontWeight: 'bold' }}>INTNL</StyledTableCell>
													<StyledTableCell align="center">{csrData[0]["pics"][0]["intPic"]}</StyledTableCell>
												</StyledTableRow>
											</TableBody>
										</Table>
									</TableContainer>
								</Paper>
							</Grid> : null
						}
						{csrData[0]["tnSets"]?.length > 0 ?
							<Grid item xs={12}>
								<Paper>
									<TableContainer>
										<Table>
											<TableHead>
												<StyledTableRow>
													<StyledTableCell colSpan={12} align="center">Telephone Number Summary</StyledTableCell>
												</StyledTableRow>
											</TableHead>
											<TableBody>
												{csrData[0]["tnSets"].map((number, index) => (
													<StyledTableRow key={index}>
														<StyledTableCell align="center">{number.tn1}{number.type1}{number.btn1}</StyledTableCell>
														<StyledTableCell align="center">{number.tn2}{number.type2}{number.btn2}</StyledTableCell>
														<StyledTableCell align="center">{number.tn3}{number.type3}{number.btn3}</StyledTableCell>
														<StyledTableCell align="center">{number.tn4}{number.type4}{number.btn4}</StyledTableCell>
													</StyledTableRow>
												))}
											</TableBody>
										</Table>
									</TableContainer>
								</Paper>
							</Grid> : null
						}
					</Grid>
				</Container> :
				<span className="text-danger">No data found for the given report.</span>
			}
		</>
	);
};

export default CsrReport;
