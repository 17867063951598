/**
 * Created by e0173131 on 1/20/2021.
 */
import React from 'react';
import ViewportWarning from "../shared/ViewportWarning";
import GoodieColors from "./goodies/GoodieColors";
// import GoodieButtons from "./goodies/GoodieButtons";
import GoodieIcons from "./goodies/GoodieIcons";
import GoodieForm from "./goodies/GoodieForm";
// import GoodieFont from "./goodies/GoodieFont";
import GoodieSupertip from "./goodies/GoodieSupertip";
import GoodieWizard from "./goodies/GoodieWizard";
import GoodieMisc from "./goodies/GoodieMisc";
import GoodieInfo from "./goodies/GoodieInfo";
// import GoodieChangelog from "./goodies/GoodieChangelog";

const Goodies = () => {
    return (
        <div className="midsection changelog abs z1">
            <div className="rel">
                <ViewportWarning />
                <div className="wrap">
                    <h1 className="page-title">Goodies</h1>
                    <div className="section">
                        <div className="content">
                            <GoodieColors />
                            <GoodieIcons />
                            <GoodieForm />
                            <GoodieSupertip />
                            <GoodieWizard />
                            <GoodieMisc />
                            <GoodieInfo />
                            {/*
                            <GoodieButtons />
                            <GoodieFont />
                            <GoodieChangelog /> */}
                            <p className="cf">&nbsp;</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Goodies;
