import headerImage from './Images/service-order-header-650x81.jpg';
import renewalImage from './Images/renewal_order_header.jpg';
import { Page, Text, View, Document, Image, Link } from '@react-pdf/renderer';
import { styles } from './pdfStyles';
import OrderLeaseFee from './OrderLeaseFee';
import SignatureSection from './SignatureSection';
import MplsDetails from './MplsDetail';
import DDoSDetails from './DDosDetails';
import ColoDetails from './ColoDetails';
import OrderInvoice from './OrderInvoice';

const OrderPDF = (props) => {
	const orderData = props.orderData || {};
	const leaseFee = props.orderData?.leaseFee || {};
	const vrfDetails = props.orderData?.vrf || {};
	const selectedOption = props.orderData?.selected_option || '';
	const variablePricing = props.orderData?.variablePricing || '';
	const invoiceData = props.orderData?.invoice || {};
	const icbPricingType = props.orderData?.leaseFee?.icbPricingType ? Number(props.orderData?.leaseFee.icbPricingType) : 0;

	const renderRampUPDetails = (orderData) => {
		const renderRampUp = {
			'7': () => ( // MPLS_AGG_NNI
				<>
					<View style={styles.tableRow}>
						<Text style={[styles.headerColumn, styles.tableCell2Width, styles.textBold]}>MPLS Ramp Up:</Text>
						<Text style={[styles.tableColumn, styles.tableCell2Width, styles.textNormal]}>{orderData?.mpls_rampup === 1 ? "Yes" : "No"}</Text>
					</View>
				</>
			),
			'22': () => ( // E_ACCESS_NNI
				<>
					<View style={styles.tableRow}>
						<Text style={[styles.headerColumn, styles.tableCell2Width, styles.textBold]}>E-Access Ramp Up:</Text>
						<Text style={[styles.tableColumn, styles.tableCell2Width, styles.textNormal]}>{orderData?.rampup === 1 ? "Yes" : "No"}</Text>
					</View>
				</>
			),
		};

		// Default JSX if product_id is not found in the mapping
		const defaultDetails = (
			<>
				<Text style={[styles.headerColumn, styles.tableCell2Width, styles.textBold]}></Text>
				<Text style={[styles.tableColumn, styles.tableCell2Width, styles.textNormal]}></Text>
			</>
		);

		const productId = orderData?.product_id;
		return renderRampUp[productId]?.() || defaultDetails;
	};

	const renderVRFDetails = (orderData) => {
		if (!vrfDetails || Object.keys(vrfDetails).length === 0) {
			return null;
		}

		return Object.keys(vrfDetails).map((item, idx) => (
			<>
				<View style={styles.headerBarTopMargin}>
					<Text style={styles.headerBarText}>Vrf Details</Text>
				</View>
				{Object.entries(vrfDetails[idx] || {}).map(([key, value], keyIdx) => {
					// Ensure value is neither null nor undefined, and meets the condition
					if (value !== 0 && value !== '0' && value && value.length > 0) {
						return (
							<View style={styles.tableRow} key={`detail-${keyIdx}`}>
								<Text style={[styles.headerColumn, styles.tableCell2Width, styles.textBold]}>{key}:</Text>
								<Text style={[styles.tableColumn, styles.tableCell2Width, styles.textNormal]}>{value}</Text>
							</View>
						);
					}
					return null;
				})}
			</>
		));
	}

	return (
		<Document key={orderData.ref_id}>
			<Page size="A4" style={styles.page}>
				{orderData.cpt_activity === 'R' ?
					<Image style={styles.imageHeader} src={renewalImage} />
					:
					<Image style={styles.imageHeader} src={headerImage} />
				}
				<View style={{ height: 10 }}></View>
				<View style={styles.headerBar}>
					<Text style={styles.headerBarText}>General Information</Text>
				</View>
				<View style={styles.table}>
					<View style={styles.tableRow}>
						<Text style={[styles.headerColumn, styles.tableCell, styles.textBold]}>Company Name:</Text>
						<Text style={[styles.tableColumn, styles.tableCell, styles.textNormal]}>{orderData?.company}</Text>
						<Text style={[styles.headerColumn, styles.tableCell, styles.textBold]}>Reference ID:</Text>
						<Text style={[styles.tableColumn, styles.tableCell, styles.textNormal]}>{orderData?.ref_id}</Text>
					</View>
					<View style={styles.tableRow}>
						<Text style={[styles.headerColumn, styles.tableCell, styles.textBold]}>Open Date:</Text>
						<Text style={[styles.tableColumn, styles.tableCell, styles.textNormal]}>{orderData?.opened}</Text>
						<Text style={[styles.headerColumn, styles.tableCell, styles.textBold]}>{orderData?.cpt_activity === 'R' ? "Effective Date:" : "Requested Service Date:"}</Text>
						<Text style={[styles.tableColumn, styles.tableCell, styles.textNormal]}>{orderData?.serv_date}</Text>
					</View>
					<View style={styles.tableRow}>
						<Text style={[styles.headerColumn, styles.tableCell, styles.textBold]}>Expedite:</Text>
						<Text style={[styles.tableColumn, styles.tableCell, styles.textNormal]}>{orderData?.exp}</Text>
						<Text style={[styles.headerColumn, styles.tableCell, styles.textBold]}>ACNA:</Text>
						<Text style={[styles.tableColumn, styles.tableCell, styles.textNormal]}>{orderData?.acna}</Text>

					</View>
					<View style={styles.tableRow}>
						<Text style={[styles.headerColumn, styles.tableCell, styles.textBold]}>Customer PON:</Text>
						<Text style={[styles.tableColumn, styles.tableCell, styles.textNormal]}>{orderData?.cust_pon}</Text>
						<Text style={[styles.headerColumn, styles.tableCell, styles.textBold]}></Text>
						<Text style={[styles.tableColumn, styles.tableCell, styles.textNormal]}></Text>
					</View>
					{props.quoteData?.cp_quote_ref_id?.length > 1 && orderData?.icb_number?.length > 0 ?
						<View style={styles.tableRow}>
							<Text style={[styles.headerColumn, styles.tableCell, styles.textBold]}>Customer Quote ID:</Text>
							<Text style={[styles.tableColumn, styles.tableCell, styles.textNormal]}>{props.quoteData?.cp_quote_ref_id}</Text>
							<Text style={[styles.headerColumn, styles.tableCell, styles.textBold]}>ICB Number:</Text>
							<Text style={[styles.tableColumn, styles.tableCell, styles.textNormal]}>{orderData?.icb_number}</Text>
						</View>
						: null}
					{orderData?.icb_number?.length > 0 ?
						<View style={styles.tableRow}>
							<Text style={[styles.headerColumn, styles.tableCell, styles.textBold]}>ICB Number:</Text>
							<Text style={[styles.tableColumn, styles.tableCell, styles.textNormal]}>{orderData?.icb_number}</Text>
							<Text style={[styles.headerColumn, styles.tableCell, styles.textBold]}></Text>
							<Text style={[styles.tableColumn, styles.tableCell, styles.textNormal]}></Text>
						</View>
						: null}
					{props.quoteData?.cp_quote_ref_id?.length > 1 ?
						<View style={styles.tableRow}>
							<Text style={[styles.headerColumn, styles.tableCell, styles.textBold]}>Customer Quote ID:</Text>
							<Text style={[styles.tableColumn, styles.tableCell, styles.textNormal]}>{props.quoteData?.cp_quote_ref_id}</Text>
							<Text style={[styles.headerColumn, styles.tableCell, styles.textBold]}></Text>
							<Text style={[styles.tableColumn, styles.tableCell, styles.textNormal]}></Text>
						</View>
						: null}
				</View>

				<View style={styles.headerBarTopMargin}>
					<Text style={styles.headerBarText}>Contact Information</Text>
				</View>
				<View style={styles.table}>
					<View style={styles.tableRow}>
						<Text style={[styles.headerColumn, styles.tableCell2Width, styles.textBold]}>Primary Authorizing Contact:</Text>
						<Text style={[styles.tableColumn, styles.tableCell2Width, styles.textNormal]}>{orderData?.orig_contact}</Text>
					</View>
					<View style={styles.tableRow}>
						<Text style={[styles.headerColumn, styles.tableCell2Width, styles.textBold]}>Primary Authorizing Phone:</Text>
						<Text style={[styles.tableColumn, styles.tableCell2Width, styles.textNormal]}>{orderData?.orig_phone}</Text>
					</View>
					<View style={styles.tableRow}>
						<Text style={[styles.headerColumn, styles.tableCell2Width, styles.textBold]}>Primary Authorizing Email:</Text>
						<Text style={[styles.tableColumn, styles.tableCell2Width, styles.textNormal]}>{orderData?.orig_email}</Text>
					</View>
					<View style={styles.tableRow}>
						<Text style={[styles.headerColumn, styles.tableCell2Width, styles.textBold]}>Data Technical Contact:</Text>
						<Text style={[styles.tableColumn, styles.tableCell2Width, styles.textNormal]}>{orderData?.tech_contact}</Text>
					</View>
					<View style={styles.tableRow}>
						<Text style={[styles.headerColumn, styles.tableCell2Width, styles.textBold]}>Data Technical Phone:</Text>
						<Text style={[styles.tableColumn, styles.tableCell2Width, styles.textNormal]}>{orderData?.tech_phone}</Text>
					</View>
					<View style={styles.tableRow}>
						<Text style={[styles.headerColumn, styles.tableCell2Width, styles.textBold]}>Data Technical Email:</Text>
						<Text style={[styles.tableColumn, styles.tableCell2Width, styles.textNormal]}>{orderData?.tech_email}</Text>
					</View>
					{orderData?.product === '23' ? // WSBB
						<>
							<View style={styles.tableRow}>
								<Text style={[styles.headerColumn, styles.tableCell2Width, styles.textBold]}>IP Addressing:</Text>
								<Text style={[styles.tableColumn, styles.tableCell2Width, styles.textNormal]}>{props.quoteData?.ip_add}</Text>
							</View>
							<View style={styles.tableRow}>
								<Text style={[styles.headerColumn, styles.tableCell2Width, styles.textBold]}>IPs:</Text>
								<Text style={[styles.tableColumn, styles.tableCell2Width, styles.textNormal]}>{orderData?.ips}</Text>
							</View>
						</>
						: null}
					{renderRampUPDetails(orderData)}
				</View>
				<View style={styles.headerBarTopMargin}>
					<Text style={styles.headerBarText}>Service Information</Text>
				</View>
				<View style={styles.table}>
					<View style={styles.tableRow}>
						<Text style={[styles.headerColumn, styles.tableCell, styles.textBold]}>Activity Requested:</Text>
						<Text style={[styles.tableColumn, styles.tableCell, styles.textNormal]}>{orderData?.act}</Text>
						<Text style={[styles.headerColumn, styles.tableCell, styles.textBold]}>Product:</Text>
						<Text style={[styles.tableColumn, styles.tableCell, styles.textNormal]}>{orderData?.product}</Text>
					</View>
					{orderData?.product_id !== '15' ? // COLO
						<View style={styles.tableRow}>
							<Text style={[styles.headerColumn, styles.tableCell, styles.textBold]}>Service Type:</Text>
							<Text style={[styles.tableColumn, styles.tableCell, styles.textNormal]}>{orderData?.circuit}</Text>
							{orderData?.o_circuit === '7' ? // Wave
								<>
									<Text style={[styles.headerColumn, styles.tableCell, styles.textBold]}>Protection:</Text>
									<Text style={[styles.tableColumn, styles.tableCell, styles.textNormal]}>{orderData?.protection}</Text>
								</>
								:
								<>
									{orderData?.product_id === '23' ? // WS BB
										<>
											<Text style={[styles.headerColumn, styles.tableCell, styles.textBold]}>Access Method:</Text>
											<Text style={[styles.tableColumn, styles.tableCell, styles.textNormal]}>{orderData?.chipset}</Text>
										</>
										:
										<>
											<Text style={[styles.headerColumn, styles.tableCell, styles.textBold]}></Text>
											<Text style={[styles.tableColumn, styles.tableCell, styles.textNormal]}></Text>
										</>
									}
								</>
							}
						</View>
						: null}
					{orderData?.product_id === '23' ? // WS BB
						<View style={styles.tableRow}>
							<Text style={[styles.headerColumn, styles.tableCell, styles.textBold]}>Access Method:</Text>
							<Text style={[styles.tableColumn, styles.tableCell, styles.textNormal]}>{orderData?.chipset}</Text>
							<Text style={[styles.headerColumn, styles.tableCell, styles.textBold]}></Text>
							<Text style={[styles.tableColumn, styles.tableCell, styles.textNormal]}></Text>
						</View>
						: null}
					{orderData?.product_id !== '15' ? // COLO
						<View style={styles.tableRow}>
							<Text style={[styles.headerColumn, styles.tableCell, styles.textBold]}>Bandwidth:</Text>
							<Text style={[styles.tableColumn, styles.tableCell, styles.textNormal]}>{orderData?.bandwidth}</Text>
							{orderData?.product !== '23' && orderData?.cust_circuit?.length > 0 ?
								<>
									<Text style={[styles.headerColumn, styles.tableCell, styles.textBold]}>Customer Circuit:</Text>
									<Text style={[styles.tableColumn, styles.tableCell, styles.textNormal]}>{orderData?.cust_circuit}</Text>
								</>
								:
								<>
									<Text style={[styles.headerColumn, styles.tableCell, styles.textBold]}></Text>
									<Text style={[styles.tableColumn, styles.tableCell, styles.textNormal]}></Text>
								</>
							}
						</View>
						:
						<>
							{orderData?.product !== '23' && orderData?.cust_circuit?.length > 0 ?
								<View style={styles.tableRow}>
									<Text style={[styles.headerColumn, styles.tableCell, styles.textBold]}>Customer Circuit:</Text>
									<Text style={[styles.tableColumn, styles.tableCell, styles.textNormal]}>{orderData?.cust_circuit}</Text>
									<Text style={[styles.headerColumn, styles.tableCell, styles.textBold]}></Text>
									<Text style={[styles.tableColumn, styles.tableCell, styles.textNormal]}></Text>
								</View>
								: null}
						</>
					}
					<View style={styles.tableRow}>
						<Text style={[styles.headerColumn, styles.tableCell, styles.textBold]}>Quantity:</Text>
						<Text style={[styles.tableColumn, styles.tableCell, styles.textNormal]}>{orderData?.quantity}</Text>
						{orderData?.exist_circuit === 'No Circuit Found.' || orderData?.exist_circuit?.length === 0 ?
							<>
								<Text style={[styles.headerColumn, styles.tableCell, styles.textBold]}></Text>
								<Text style={[styles.tableColumn, styles.tableCell, styles.textNormal]}></Text>
							</>
							:
							<>
								<Text style={[styles.headerColumn, styles.tableCell, styles.textBold]}>Existing Circuit ID:</Text>
								<Text style={[styles.tableColumn, styles.tableCell, styles.textNormal]}>{orderData?.exist_circuit}</Text>
							</>
						}
					</View>
					{orderData?.jurisdiction?.length > 0 ?
						<View style={styles.tableRow}>
							<Text style={[styles.headerColumn, styles.tableCell, styles.textBold]}>Jurisdiction Traffic:</Text>
							<Text style={[styles.tableColumn, styles.tableCell, styles.textNormal]}>{orderData?.jurisdiction}</Text>
							<Text style={[styles.headerColumn, styles.tableCell, styles.textBold]}></Text>
							<Text style={[styles.tableColumn, styles.tableCell, styles.textNormal]}></Text>
						</View>
						: null}
					{orderData?.o_circuit === '7' ? // Wave
						<View style={styles.tableRow}>
							<Text style={[styles.headerColumn, styles.tableCell, styles.textBold]}>Number Of Routes:</Text>
							<Text style={[styles.tableColumn, styles.tableCell, styles.textNormal]}>{orderData?.num_routes_desc}</Text>
							<Text style={[styles.headerColumn, styles.tableCell, styles.textBold]}>Diversity:</Text>
							<Text style={[styles.tableColumn, styles.tableCell, styles.textNormal]}>{orderData?.diversity_desc}</Text>
						</View>
						: null}
					{orderData?.product_id === '10' || orderData?.product_id === '11' ? // DIA POP || DIA Prem
						<>
							{orderData?.product_id === '11' && orderData?.o_circuit === '6' ? // DIA Prem
								<>
									{orderData.cpt_activity !== 'R' ?
										<View style={styles.tableRow}>
											{orderData?.cpe_type?.length ?
												<>
													<Text style={[styles.headerColumn, styles.tableCell, styles.textBold]}>CPE Provider:</Text>
													<Text style={[styles.tableColumn, styles.tableCell, styles.textNormal]}>{orderData?.cpe_type}</Text>
												</>
												:
												<>
													<Text style={[styles.headerColumn, styles.tableCell, styles.textBold]}></Text>
													<Text style={[styles.tableColumn, styles.tableCell, styles.textNormal]}></Text>
												</>
											}
											{orderData?.router_service?.length ?
												<>
													<Text style={[styles.headerColumn, styles.tableCell, styles.textBold]}>Internet Router Service:</Text>
													<Text style={[styles.tableColumn, styles.tableCell, styles.textNormal]}>{orderData?.router_service}</Text>
												</>
												:
												<>
													<Text style={[styles.headerColumn, styles.tableCell, styles.textBold]}></Text>
													<Text style={[styles.tableColumn, styles.tableCell, styles.textNormal]}></Text>
												</>
											}
										</View>
										: null}
									{orderData?.mounting_equip?.length > 0 ?
										<>
											<View style={styles.tableRow}>
												<Text style={[styles.headerColumn, styles.tableCell, styles.textBold]}>Mounting Equipment:</Text>
												<Text style={[styles.tableColumn, styles.tableCell, styles.textNormal]}>{orderData?.mounting_equip}</Text>
												<Text style={[styles.headerColumn, styles.tableCell, styles.textBold]}>Optional WAN SFP:</Text>
												<Text style={[styles.tableColumn, styles.tableCell, styles.textNormal]}>{orderData?.wan_sfp}</Text>

											</View>
										</>
										: null}
								</>
								: null}
							{orderData.cpt_activity !== 'R' ?
								<>
									{orderData?.wan?.length > 0 || orderData?.lan?.length > 0 ?
										<View style={styles.tableRow}>
											<Text style={[styles.headerColumn, styles.tableCell, styles.textBold]}>WAN Requirements:</Text>
											<Text style={[styles.tableColumn, styles.tableCell, styles.textNormal]}>{orderData?.wan}</Text>
											<Text style={[styles.headerColumn, styles.tableCell, styles.textBold]}>LAN Requirements:</Text>
											<Text style={[styles.tableColumn, styles.tableCell, styles.textNormal]}>{orderData?.lan}</Text>
										</View>
										: null}
									{orderData?.ips?.length > 0 ?
										<View style={styles.tableRow}>
											<Text style={[styles.headerColumn, styles.tableCell, styles.textBold]}>Static IP's:</Text>
											<Text style={[styles.tableColumn, styles.tableCell, styles.textNormal]}>{orderData?.ips}</Text>
											<Text style={[styles.headerColumn, styles.tableCell, styles.textBold]}></Text>
											<Text style={[styles.tableColumn, styles.tableCell, styles.textNormal]}></Text>
										</View>
										: null}
								</>
								: null}
						</>
						: null}
					{/* Additional Information, shown above for WAVE */}
					{orderData?.diversity_desc?.length > 0 && orderData?.o_circuit !== '7' ?
						<View style={styles.tableRow}>
							<Text style={[styles.headerColumn, styles.tableCell, styles.textBold]}>Diversity:</Text>
							<Text style={[styles.tableColumn, styles.tableCell, styles.textNormal]}>{orderData?.diversity_desc}</Text>
							{orderData?.diversity_note?.length > 0 ?
								<>
									<Text style={[styles.headerColumn, styles.tableCell, styles.textBold]}>Diversity Note:</Text>
									<Text style={[styles.tableColumn, styles.tableCell, styles.textNormal]}>{orderData?.diversity_note}</Text>
								</>
								:
								<>
									<Text style={[styles.headerColumn, styles.tableCell, styles.textBold]}></Text>
									<Text style={[styles.tableColumn, styles.tableCell, styles.textNormal]}></Text>
								</>
							}
						</View>
						: null}
					<View style={styles.tableRow}>
						<Text style={[styles.headerColumn, styles.tableCell, styles.textBold]}>Initial term:</Text>
						<Text style={[styles.tableColumn, styles.tableCell, styles.textNormal]}>{orderData?.term} months</Text>
						<Text style={[styles.headerColumn, styles.tableCell, styles.textBold]}></Text>
						<Text style={[styles.tableColumn, styles.tableCell, styles.textNormal]}></Text>
					</View>
				</View>
				<OrderLeaseFee leaseFee={leaseFee} term={orderData?.term} variablePricing={variablePricing} selectedOption={selectedOption} orderData={orderData} />
				<View wrap={false}>
					<View style={styles.headerBarTopMargin}>
						<Text style={styles.headerBarText}>Site Information</Text>
					</View>
					<View style={styles.table}>
						{/* Table row with mixed styles */}
						<View style={styles.tableRow}>
							<Text style={[styles.tableColumn2Width, styles.tableCell, styles.textBold]}>Location A{orderData?.la_loc_type?.length > 0 ? ` - ${orderData.la_loc_type}` : ''}</Text>
							<Text style={[styles.tableColumn2Width, styles.tableCell, styles.textNormal]}></Text>
							{orderData?.lz_add?.length > 0 ?
								<>
									<Text style={[styles.tableColumn2Width, styles.tableCell, styles.textBold]}>Location Z{orderData?.lz_loc_type?.length > 0 ? ` - ${orderData.lz_loc_type}` : ''}</Text>
									<Text style={[styles.tableColumn2Width, styles.tableCell, styles.textNormal]}></Text>
								</>
								: null
							}
						</View>
						{orderData?.lz_add?.length > 0 ?
							<>
								<View style={styles.tableRow}>
									<Text style={[styles.headerColumn, styles.tableCell, styles.textBold]}>Order Name:</Text>
									<Text style={[styles.tableColumn, styles.tableCell, styles.textNormal]}>{orderData?.a_cp_name}</Text>
									{orderData?.z_cp_name?.length > 0 ?
										<>
											<Text style={[styles.headerColumn, styles.tableCell, styles.textBold]}>Loc Z Name:</Text>
											<Text style={[styles.tableColumn, styles.tableCell, styles.textNormal]}>{orderData?.z_cp_name}</Text>
										</>
										:
										<>
											<Text style={[styles.headerColumn, styles.tableCell, styles.textBold]}></Text>
											<Text style={[styles.tableColumn, styles.tableCell, styles.textNormal]}></Text>
										</>
									}
								</View>
								<View style={styles.tableRow}>
									<Text style={[styles.headerColumn, styles.tableCell, styles.textBold]}>Customer Address:</Text>
									<Text style={[styles.tableColumn, styles.tableCell, styles.textNormal]}>{orderData?.la_add}</Text>
									<Text style={[styles.headerColumn, styles.tableCell, styles.textBold]}>Customer Address:</Text>
									<Text style={[styles.tableColumn, styles.tableCell, styles.textNormal]}>{orderData?.lz_add}</Text>
								</View>
								<View style={styles.tableRow}>
									<Text style={[styles.headerColumn, styles.tableCell, styles.textBold]}>City, State, ZIP:</Text>
									<Text style={[styles.tableColumn, styles.tableCell, styles.textNormal]}>{orderData?.la_cityStateZip}</Text>
									<Text style={[styles.headerColumn, styles.tableCell, styles.textBold]}>City, State, ZIP:</Text>
									<Text style={[styles.tableColumn, styles.tableCell, styles.textNormal]}>{orderData?.lz_cityStateZip}</Text>
								</View>
								<View style={styles.tableRow}>
									<Text style={[styles.headerColumn, styles.tableCell, styles.textBold]}>Native NPA/NXX:</Text>
									<Text style={[styles.tableColumn, styles.tableCell, styles.textNormal]}>{orderData?.a_npanxx}</Text>
									<Text style={[styles.headerColumn, styles.tableCell, styles.textBold]}>Native NPA/NXX:</Text>
									<Text style={[styles.tableColumn, styles.tableCell, styles.textNormal]}>{orderData?.z_npanxx}</Text>
								</View>
								{props.quoteData?.product_id === '20' ? // EACCESS
									<>
										<View style={styles.tableRow}>
											<Text style={[styles.headerColumn, styles.tableCell, styles.textBold]}>POP/SWC:</Text>
											<Text style={[styles.tableColumn, styles.tableCell, styles.textNormal]}>{props.quoteData?.la_clli}</Text>
											<Text style={[styles.headerColumn, styles.tableCell, styles.textBold]}>POP/SWC:</Text>
											<Text style={[styles.tableColumn, styles.tableCell, styles.textNormal]}>{props.quoteData?.lz_clli}</Text>
										</View>
										<View style={styles.tableRow}>
											<Text style={[styles.headerColumn, styles.tableCell, styles.textBold]}>NNI Circuit ID:</Text>
											<Text style={[styles.tableColumn, styles.tableCell, styles.textNormal]}>{orderData?.nni_circuit_id}</Text>
											<Text style={[styles.headerColumn, styles.tableCell, styles.textBold]}></Text>
											<Text style={[styles.tableColumn, styles.tableCell, styles.textNormal]}></Text>
										</View>
									</>
									: null}
								<View style={styles.tableRow}>
									<Text style={[styles.headerColumn, styles.tableCell, styles.textBold]}>Onsite Name:</Text>
									<Text style={[styles.tableColumn, styles.tableCell, styles.textNormal]}>{orderData?.a_lcon_name}</Text>
									{orderData?.z_lcon_name?.length > 0 ?
										<>
											<Text style={[styles.headerColumn, styles.tableCell, styles.textBold]}>Onsite Name:</Text>
											<Text style={[styles.tableColumn, styles.tableCell, styles.textNormal]}>{orderData?.z_lcon_name}</Text>
										</>
										:
										<>
											<Text style={[styles.headerColumn, styles.tableCell, styles.textBold]}></Text>
											<Text style={[styles.tableColumn, styles.tableCell, styles.textNormal]}></Text>
										</>
									}
								</View>
								<View style={styles.tableRow}>
									<Text style={[styles.headerColumn, styles.tableCell, styles.textBold]}>Onsite Phone:</Text>
									<Text style={[styles.tableColumn, styles.tableCell, styles.textNormal]}>{orderData?.a_lcon_phone}</Text>
									{orderData?.z_lcon_phone?.length > 1 ?
										<>
											<Text style={[styles.headerColumn, styles.tableCell, styles.textBold]}>Onsite Phone:</Text>
											<Text style={[styles.tableColumn, styles.tableCell, styles.textNormal]}>{orderData?.z_lcon_phone}</Text>
										</>
										:
										<>
											<Text style={[styles.headerColumn, styles.tableCell, styles.textBold]}></Text>
											<Text style={[styles.tableColumn, styles.tableCell, styles.textNormal]}></Text>
										</>
									}
								</View>
								<View style={styles.tableRow}>
									<Text style={[styles.headerColumn, styles.tableCell, styles.textBold]}>Onsite Email:</Text>
									<Text style={[styles.tableColumn, styles.tableCell, styles.textNormal]}>{orderData?.a_lcon_email}</Text>
									{orderData?.z_lcon_email?.length > 0 ?
										<>
											<Text style={[styles.headerColumn, styles.tableCell, styles.textBold]}>Onsite Email:</Text>
											<Text style={[styles.tableColumn, styles.tableCell, styles.textNormal]}>{orderData?.z_lcon_email}</Text>
										</>
										:
										<>
											<Text style={[styles.headerColumn, styles.tableCell, styles.textBold]}></Text>
											<Text style={[styles.tableColumn, styles.tableCell, styles.textNormal]}></Text>
										</>
									}
								</View>
								<View style={styles.tableRow}>
									{orderData?.a_handoff?.length > 0 ?
										<>
											<Text style={[styles.headerColumn, styles.tableCell, styles.textBold]}>Handoff:</Text>
											<Text style={[styles.tableColumn, styles.tableCell, styles.textNormal]}>{orderData?.a_handoff}</Text>
										</>
										:
										<>
											<Text style={[styles.headerColumn, styles.tableCell, styles.textBold]}></Text>
											<Text style={[styles.tableColumn, styles.tableCell, styles.textNormal]}></Text>
										</>
									}
									{orderData?.z_handoff?.length > 0 ?
										<>
											<Text style={[styles.headerColumn, styles.tableCell, styles.textBold]}>Handoff:</Text>
											<Text style={[styles.tableColumn, styles.tableCell, styles.textNormal]}>{orderData?.z_handoff}</Text>
										</>
										:
										<>
											<Text style={[styles.headerColumn, styles.tableCell, styles.textBold]}></Text>
											<Text style={[styles.tableColumn, styles.tableCell, styles.textNormal]}></Text>
										</>
									}
								</View>
								{orderData?.a_fiber_conn?.length > 0 || orderData?.z_fiber_conn?.length > 0 ?
									<>
										<View style={styles.tableRow}>
											<Text style={[styles.headerColumn, styles.tableCell, styles.textBold]}>Fiber Connector Types:</Text>
											<Text style={[styles.tableColumn, styles.tableCell, styles.textNormal]}>{orderData?.a_fiber_conn}</Text>
											<Text style={[styles.headerColumn, styles.tableCell, styles.textBold]}>Fiber Connector Types:</Text>
											<Text style={[styles.tableColumn, styles.tableCell, styles.textNormal]}>{orderData?.z_fiber_conn}</Text>
										</View>
									</>
									: null}
								{orderData?.a_dem_pt?.length > 0 || orderData?.z_dem_pt?.length > 0 ?
									<>
										<View style={styles.tableRow}>
											<Text style={[styles.headerColumn, styles.tableCell, styles.textBold]}>Demarcation Point:</Text>
											<Text style={[styles.tableColumn, styles.tableCell, styles.textNormal]}>{orderData?.a_dem_pt}</Text>
											{orderData?.z_dem_pt?.length > 0 ?
												<>
													<Text style={[styles.headerColumn, styles.tableCell, styles.textBold]}>Demarcation Point:</Text>
													<Text style={[styles.tableColumn, styles.tableCell, styles.textNormal]}>{orderData?.z_dem_pt}</Text>
												</>
												:
												<>
													<Text style={[styles.headerColumn, styles.tableCell, styles.textBold]}></Text>
													<Text style={[styles.tableColumn, styles.tableCell, styles.textNormal]}></Text>
												</>
											}
										</View>
									</>
									: null}
								{orderData?.a_conx?.length > 0 || orderData?.z_conx?.length > 0 ?
									<>
										<View style={styles.tableRow}>
											<Text style={[styles.headerColumn, styles.tableCell, styles.textBold]}>Local Access Options:</Text>
											<Text style={[styles.tableColumn, styles.tableCell, styles.textNormal]}>{orderData?.a_conx}</Text>
											<Text style={[styles.headerColumn, styles.tableCell, styles.textBold]}>Local Access Options:</Text>
											<Text style={[styles.tableColumn, styles.tableCell, styles.textNormal]}>{orderData?.z_conx}</Text>
										</View>
									</>
									: null}
								{orderData?.o_circuit === '7' ?
									<>
										<View style={styles.tableRow}>
											<Text style={[styles.headerColumn, styles.tableCell, styles.textBold]}>Location A Provider:</Text>
											<Text style={[styles.tableColumn, styles.tableCell, styles.textNormal]}>{props.quoteData?.locA_provider}</Text>
											<Text style={[styles.headerColumn, styles.tableCell, styles.textBold]}>Location Z Provider:</Text>
											<Text style={[styles.tableColumn, styles.tableCell, styles.textNormal]}>{props.quoteData?.locZ_provider}</Text>
										</View>
									</>
									: null}
								{orderData?.a_remarks?.length > 0 || orderData?.z_remarks?.length > 0 ?
									<>
										<View style={styles.topAlignedRow}>
											<Text style={[styles.headerColumn, styles.tableCell, styles.textBold]}>Location A Notes:</Text>
											<Text style={[styles.tableColumn, styles.tableCell, styles.textNormal]}>{orderData?.a_remarks}</Text>
											{orderData?.z_remarks?.length > 0 ?
												<>
													<Text style={[styles.headerColumn, styles.tableCell, styles.textBold]}>Location Z Notes:</Text>
													<Text style={[styles.tableColumn, styles.tableCell, styles.textNormal]}>{orderData?.z_remarks}</Text>
												</>
												:
												<>
													<Text style={[styles.headerColumn, styles.tableCell, styles.textBold]}></Text>
													<Text style={[styles.tableColumn, styles.tableCell, styles.textNormal]}></Text>
												</>
											}
										</View>
									</>
									: null}

							</>
							: // This will be loc A only
							<>
								<View style={styles.tableRow}>
									<Text style={[styles.headerColumn, styles.tableCell2Width, styles.textBold]}>Order Name:</Text>
									<Text style={[styles.tableColumn, styles.tableCell2Width, styles.textNormal]}>{orderData?.a_cp_name}</Text>
								</View>
								<View style={styles.tableRow}>
									<Text style={[styles.headerColumn, styles.tableCell2Width, styles.textBold]}>Customer Address:</Text>
									<Text style={[styles.tableColumn, styles.tableCell2Width, styles.textNormal]}>{orderData?.la_add}</Text>
								</View>
								<View style={styles.tableRow}>
									<Text style={[styles.headerColumn, styles.tableCell2Width, styles.textBold]}>City, State, ZIP:</Text>
									<Text style={[styles.tableColumn2Width, styles.tableCell2Width, styles.textNormal]}>{orderData?.la_cityStateZip}</Text>
								</View>
								<View style={styles.tableRow}>
									<Text style={[styles.headerColumn, styles.tableCell2Width, styles.textBold]}>Native NPA/NXX:</Text>
									<Text style={[styles.tableColumn2Width, styles.tableCell2Width, styles.textNormal]}>{orderData?.a_npanxx}</Text>
								</View>
								{props.quoteData?.product_id === '20' ? // EACCESS
									<View style={styles.tableRow}>
										<Text style={[styles.headerColumn, styles.tableCell2Width, styles.textBold]}>NNI Circuit ID:</Text>
										<Text style={[styles.tableColumn2Width, styles.tableCell2Width, styles.textNormal]}>{orderData?.nni_circuit_id}</Text>
									</View>
									: null}
								<View style={styles.tableRow}>
									<Text style={[styles.headerColumn, styles.tableCell2Width, styles.textBold]}>Onsite Name:</Text>
									<Text style={[styles.tableColumn2Width, styles.tableCell2Width, styles.textNormal]}>{orderData?.a_lcon_name}</Text>
								</View>
								<View style={styles.tableRow}>
									<Text style={[styles.headerColumn, styles.tableCell2Width, styles.textBold]}>Onsite Phone:</Text>
									<Text style={[styles.tableColumn2Width, styles.tableCell2Width, styles.textNormal]}>{orderData?.a_lcon_phone}</Text>
								</View>
								<View style={styles.tableRow}>
									<Text style={[styles.headerColumn, styles.tableCell2Width, styles.textBold]}>Onsite Email:</Text>
									<Text style={[styles.tableColumn2Width, styles.tableCell2Width, styles.textNormal]}>{orderData?.a_lcon_email}</Text>
								</View>
								{orderData?.product_id !== '15' && orderData?.a_handoff?.length > 0 ? // COLO
									<View style={styles.tableRow}>
										<Text style={[styles.headerColumn, styles.tableCell2Width, styles.textBold]}>Handoff:</Text>
										<Text style={[styles.tableColumn2Width, styles.tableCell2Width, styles.textNormal]}>{orderData?.a_handoff}</Text>
									</View>
									: null}
								{orderData?.a_fiber_conn?.length > 0 ?
									<>
										<View style={styles.tableRow}>
											<Text style={[styles.headerColumn, styles.tableCell2Width, styles.textBold]}>Fiber Connector Types:</Text>
											<Text style={[styles.tableColumn2Width, styles.tableCell2Width, styles.textNormal]}>{orderData?.a_fiber_conn}</Text>
										</View>
									</>
									: null}
								{orderData?.a_dem_pt?.length > 0 ?
									<>
										<View style={styles.tableRow}>
											<Text style={[styles.headerColumn, styles.tableCell2Width, styles.textBold]}>Demarcation Point:</Text>
											<Text style={[styles.tableColumn2Width, styles.tableCell2Width, styles.textNormal]}>{orderData?.a_dem_pt}</Text>
										</View>
									</>
									: null}
								{orderData?.a_conx?.length > 0 ?
									<>
										<View style={styles.tableRow}>
											<Text style={[styles.headerColumn, styles.tableCell2Width, styles.textBold]}>Local Access Options:</Text>
											<Text style={[styles.tableColumn2Width, styles.tableCell2Width, styles.textNormal]}>{orderData?.a_conx}</Text>
										</View>
									</>
									: null}
								{orderData?.a_remarks?.length > 0 ?
									<>
										<View style={styles.tableRow}>
											<Text style={[styles.headerColumn, styles.tableCell2Width, styles.textBold]}>Location A Notes:</Text>
											<Text style={[styles.tableColumn2Width, styles.tableCell2Width, styles.textNormal]}>{orderData?.a_remarks}</Text>
										</View>
									</>
									: null}
							</>
						}
					</View>
				</View>


				{/* E-Access Details */}
				{orderData.product_id === '19' || orderData.product_id === '20' ?
					<View wrap={false}>
						<View style={styles.headerBarTopMargin}>
							{orderData.product_id === '19' ?
								<Text style={styles.headerBarText}>E-Line Details</Text>
								:
								<Text style={styles.headerBarText}>E-Access Details</Text>
							}
						</View>
						<View style={styles.table}>
							<View style={styles.tableRow}>
								<Text style={[styles.headerColumn, styles.tableCell, styles.textBold]}>MTU Size:</Text>
								<Text style={[styles.tableColumn2Width, styles.tableCell, styles.textNormal]}>{orderData?.ce_mtu}</Text>
								<Text style={[styles.headerColumn, styles.tableCell, styles.textBold]}>VLAN:</Text>
								<Text style={[styles.tableColumn2Width, styles.tableCell, styles.textNormal]}>{orderData?.ce_vlan}</Text>
							</View>
						</View>
						{orderData?.product_id === '19' && orderData?.cos_needed?.length > 0 ? // ELINE
							<View style={styles.tableRow}>
								<Text style={[styles.headerColumn, styles.tableCell, styles.textBold]}>COS Needed:</Text>
								<Text style={[styles.tableColumn, styles.tableCell, styles.textNormal]}>{orderData?.cos_needed}</Text>
								<Text style={[styles.headerColumn, styles.tableCell, styles.textBold]}>COS:</Text>
								<Text style={[styles.tableColumn, styles.tableCell, styles.textNormal]}>{props.quoteData?.[`cos${selectedOption}`]}</Text>
							</View>
							: null}
					</View>
					: null}


				{/*  MPLS Agg Tail (6) or MPLS + Internet (13) */}
				{(orderData.product_id === '6' || orderData.product_id === '13') && orderData?.cpt_activity !== 'R' ?
					renderVRFDetails(orderData)
					: null}

				{/*  MPLS Agg Tail (6) or MPLS + Internet (13) */}
				{(orderData.product_id === '6' || orderData?.product_id === '13') ? <MplsDetails {...orderData} /> : null}

				{/* DDoS Mitigation Detail  DIA Prem (11) and MPLS + Internet (13) */}
				{(orderData?.product_id === '11' || orderData?.product_id === '13') && orderData?.ddos_data === 1 ? <DDoSDetails {...orderData} /> : null}

				{/* Colo Details */}
				{orderData?.product_id === '15' ? <ColoDetails {...orderData} /> : null}

				{orderData?.general_notes?.length > 0 ?
					<View wrap={false}>
						<View style={styles.headerBarTopMargin}>
							<Text style={styles.headerBarText}>General Notes</Text>
						</View>
						<View style={styles.table}>
							<View style={styles.tableRow}>
								<Text style={[styles.tableCell, styles.textNormal]}>
									{orderData?.general_notes}
								</Text>
							</View>
						</View>
					</View>
					: null}

				{variablePricing && (icbPricingType === 2 || icbPricingType === 3 || icbPricingType === 4) ? // Wave
					<OrderInvoice invoiceData={invoiceData} term={orderData?.term} orderData={orderData} />
					: null}

				<View wrap={false}>
					<View style={styles.headerBarTopMargin}>
						<Text style={styles.headerBarText}>Terms & Conditions</Text>
					</View>
					<View style={styles.table}>
						{orderData.cpt_activity === 'R' ? // add 1 & 2
							<>
								<View style={styles.tableRow}>
									<Text style={[styles.tableCell, styles.textNormal]}>
										{orderData?.profile_agent === '2' ? // Customer Agent ADO #928420
											<>
												1. Pursuant to all the terms and conditions of the Data Service Terms and Conditions located at
												<Link src="https://www.windstreamwholesale.com/data-services-terms-and-conditions/" target="_blank" style={styles.link}>
													https://www.windstreamwholesale.com/data-services-terms-and-conditions/
												</Link>,
												as may be updated from time to time, between Windstream Enterprise Holdings, LLC and Customer, Customer hereby orders the following Service(s).
											</>
											:
											<>
												1. Pursuant to all the terms and conditions of the Master Service Agreement, and any Service Schedule(s) attached thereto, between Windstream and Customer, Customer hereby orders the following Service(s).
											</>
										}
									</Text>
								</View>
								<View style={styles.tableRowBorderBottom}>
									<Text style={[styles.tableCell, styles.textNormal]}>
										2. Re - Rate of Existing Circuit.If this Service Order is to change pricing for an Existing Circuit as indicated above. The Renewal Effective Date for this Service Order shall be on the first day of the second month following the date that this Service Order is fully executed by both parties;  unless otherwise specified in the Service order. Billing for Services shall commence as of the  Renewal Effective Date. On and after the Renewal Effective Date, the Existing Circuit shall be deemed to be a Service governed by this Service Order and the Existing Service Order shall only remain in force and effect with respect to Services other than the Existing Circuit.
									</Text>
								</View>
							</>
							:
							<>
								<View style={styles.tableRow}>
									<Text style={[styles.tableCell, styles.textNormal]}>
										{orderData?.profile_agent === '2' ? // Customer Agent ADO #928420
											<>
												Pursuant to all the terms and conditions of the Data Service Terms and Conditions located at
												<Link src="https://www.windstreamwholesale.com/data-services-terms-and-conditions/" target="_blank" style={styles.link}>
													https://www.windstreamwholesale.com/data-services-terms-and-conditions/
												</Link>,
												as may be updated from time to time, between Windstream Enterprise Holdings, LLC and Customer, Customer hereby orders the following Service(s).
											</>
											:
											<>
												Pursuant to all the terms and conditions of the Master Service Agreement, and any Service Schedule(s) attached thereto, between Windstream and Customer, Customer hereby orders the following Service(s).
											</>
										}
									</Text>
								</View>
							</>
						}
					</View>
				</View>
				<SignatureSection />
			</Page >
		</Document >

	);
}

export default OrderPDF;