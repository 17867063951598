import { Redirect, Route } from "react-router-dom";
import { ConsoleLogger } from "../src/logger/ConsoleLogger";

const ProtectedRoute = ({ component: Component, ...rest }) => {
    const logger = new ConsoleLogger({ level: process.env.REACT_APP_LOGGER_LEVEL, });
    logger.info("Inside Protected Route");
    var employeeLoginURL = `${process.env.REACT_APP_LOGIN_URL}/employee.php`;
    var myReferrer = document.referrer;
    logger.info("Referred from: " + myReferrer);
    var regex = /^https\:\/\/login(-dev|-test){0,1}\.windstream\.com\/$/

    return (
        <Route
            {...rest}
            render={({ props }) => {
                if (localStorage.getItem("isLoggedIn") || localStorage.getItem("isEmployeeLoggedIn")) {
                    if (localStorage.getItem("isLoggedIn")) {
                        logger.info("User is logged in as a Customer");
                    }
                    if (localStorage.getItem("isEmployeeLoggedIn")) {
                        logger.info("User is logged in as an Employee");
                    }
                    return <Component {...props} />
                } else if (myReferrer.match(regex)) {
                    // If this is an employee login, check whether we got sent here from windstream or not.
                    // If we did, it's probably an initial login for employee, so redirect back to the PHP
                    // to finish logging in and pulling the LDAP authorization.
                    logger.info("Second visit to employee.php");
                    window.location.href = employeeLoginURL;
                } else {
                    logger.info("User is not logged in");
                    return <Redirect to={{ pathname: "/Login" }} />
                }
            }
            }
        />
    )
}

export default ProtectedRoute;
