import {useState} from 'react';
import style from "./Employee.module.css";
import {connect, useSelector} from 'react-redux';
import {ConsoleLogger} from '../../logger/ConsoleLogger';
import ProfileService from "../../services/ProfileServices";
import Loader from "../shared/Loader";
import {api_email, api_enabled} from '../../redux';

const APIProfile = (props) => {
	const logger = new ConsoleLogger({ level: process.env.REACT_APP_LOGGER_LEVEL, });
	const profileFromStore = useSelector(state => state.employeeProfile.profile);
	const apiFromStore = useSelector(state => state.employeeProfile.credentials);
	const [toggleState, setToggleState] = useState(apiFromStore?.api_email ? 'on' : '');
	const [loader, setLoader] = useState(false);
	const [ErrorMSG, setErrorMSG] = useState('');
	const [SuccessMSG, setSuccessMSG] = useState('');

	const toggleAction = () => {
		setErrorMSG('');
		setSuccessMSG('');
		const toggle = toggleState === 'on' ? '' : 'on';
		logger.info("toggle API : " + toggle);
		setToggleState(toggle);
	}

	const inputChange = (e, field) => {
		props[field](e.target.value);
	}

	const submitAPI = (action) => {
		setLoader(true);
		const submitJSON = {
			"selectedProfile": profileFromStore.selectedProfile.value,
			"action": action,
			"email": apiFromStore.api_email
		}

		//Calling axios service to call submitApi.php
		logger.info("API Data: " + JSON.stringify(submitJSON));
		ProfileService.submitAPIData(submitJSON).then(resp => {
			logger.info('API Submit Response: ' + JSON.stringify(resp));
			if (resp.result === "SUCCESS") {
				ProfileService.getAPI(profileFromStore.selectedProfile.value).then(res => {
					logger.info("API : " + encodeURIComponent(JSON.stringify(res.data)).replace(/%20/g, " "));
					props.api_enabled(res.data.api_enabled);
					props.api_email(res.data.api_email);
				}).catch(err => {
					logger.error("Error Getting API: " + err);
					setLoader(false);
				})
			} else {
				setErrorMSG(resp.error);
			}
			setSuccessMSG('Success');
			setLoader(false);
		}).catch(error => {
			logger.error("Create Profile ERROR: " + error)
			setErrorMSG('An error has occurred.');
			setLoader(false);
		})

	}

	return (

		<div className="apiPage mt-5">
			{loader ? <Loader /> : null}
			{ErrorMSG?.length ?
				<div>
					<p className={`text-center my-4 text-danger`}>{ErrorMSG}</p>
				</div> : null}
			{SuccessMSG?.length ?
				<div>
					<p className={`text-center my-4 text-success`}>{SuccessMSG}</p>
				</div> : null}
			<div className={`sub-hdr alt0 mt-0`}>
				<div className="nib">
					<div className="the-notch">
						<div className="notch-fg">
							<svg>
								<path
									d="M35.81,26.28l-13,10v-5C-10.47,28.68-1,9.16,11.79,0c0,7.28,0,9.13,0,13.28s3.18,7.92,11,9v-5Z"></path>
							</svg>
						</div>
					</div>
				</div>
				<h2 className={`m-0 `}>API Credentials</h2>
			</div>

			<div className='my-3 px-3'>
				<div className={`toggle-comp`} onClick={(e) => toggleAction()}>
					<div className={`toggle-switch ${toggleState}`}>
						<div className="ts-bar">
							<div className="ts-left-edge"></div>
							<div className="ts-right-edge"></div>
							<div className="ts-switch"></div>
						</div>
					</div>
				</div>

				{toggleState === 'on' ?
					<div className="d-block my-5">
						<label className={`${style.labelText} m-0`}>Email Credentials To <span className={style.mandatory}>*</span></label>
						<input type="text" className={`${style.inputFldText} pl-3`} value={apiFromStore?.api_email} onChange={(e) => { inputChange(e, 'api_email') }} />
					</div>
					: null}

			</div>

			{toggleState === 'on' && apiFromStore?.api_enabled && (apiFromStore?.api_email !== '') ?
				<div className='text-center mt-4'>
					<button type="button" className={`ico-button lhs light-button ml-3 ${style.nextBtn} `} onClick={() => { submitAPI('reset') }}>
						Reset Password
						<svg className="ico light-ico ico-arrow ml-2">
							<path d="M0,17H29L15,31l1,1L32,16,16,0,15,1,29,15H0Z"></path>
						</svg>
					</button>
					<button type="button" className={`ico-button lhs light-button ml-3 ${style.nextBtn} `} onClick={() => { submitAPI('resend') }}>
						Resend Setup Email
						<svg className="ico light-ico ico-arrow ml-2">
							<path d="M0,17H29L15,31l1,1L32,16,16,0,15,1,29,15H0Z"></path>
						</svg>

					</button>
				</div>
				: null}
			{toggleState === 'on' && (!apiFromStore?.api_enabled || (apiFromStore?.api_enabled === '')) ?
				<div className='text-center mt-4'>
					<button type="button" className={`ico-button lhs light-button ml-3 ${style.nextBtn} `} onClick={() => { submitAPI('create') }}>
						Create
						<svg className="ico light-ico ico-arrow ml-2">
							<path d="M0,17H29L15,31l1,1L32,16,16,0,15,1,29,15H0Z"></path>
						</svg>
					</button>
				</div>
				: null}
			{toggleState === '' && apiFromStore?.api_email !== '' ?
				<div className='text-center mt-4'>
					<button type="button" className={`ico-button lhs light-button ml-3 ${style.nextBtn} `} onClick={() => { submitAPI('disable') }}>
						Disable
						<svg className="ico light-ico ico-arrow ml-2">
							<path d="M0,17H29L15,31l1,1L32,16,16,0,15,1,29,15H0Z"></path>
						</svg>
					</button>
				</div>
				: null}
		</div>
	)
}

const mapDispatchToProps = {
	api_enabled: api_enabled,
	api_email: api_email
}

export default connect(null, mapDispatchToProps)(APIProfile);