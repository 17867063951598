import axios from "./axios/customAxios";

const getOrderData = async (dateFrom, dateTo, report, limit, product, activityType) => {
	const orderURL =
		`${process.env.REACT_APP_API_URL}/getExistingOrders.php?report=${report}&dateFrom=${dateFrom}&dateTo=${dateTo}&limit=${limit}&product=${product}&activityType=${activityType}`;
	return await axios.get(orderURL);
}

const getOrderACNA = async () => {
	const acnaURL = `${process.env.REACT_APP_API_URL}/getOrderMenus.php?type=acna`;
	return await axios.get(acnaURL)
}

const getOrderInitalTerm = async (ref_id, product) => {
	const initalTermURL = `${process.env.REACT_APP_API_URL}/getOrderMenus.php?type=term&ref_id=${ref_id}&product=${product}`;
	return await axios.get(initalTermURL);
}

const getOrderBandwidths = async (ref_id, product, circuit, term) => {
	const bwURL = `${process.env.REACT_APP_API_URL}/getBandwidths.php?menu=quotedBW&ref_id=${ref_id}&product=${product}&circuit=${circuit}&term=${term}`;
	return await axios.get(bwURL);
}

const getOrderHandoffs = async (product, circuit, bandwidth, num_routes, ref_id, modemId) => {
	const handoffsURL = `${process.env.REACT_APP_API_URL}/getOrderMenus.php?type=handoff&product=${product}&circuit=${circuit}&band=${bandwidth}&num_routes=${num_routes}&ref_id=${ref_id}&modemId=${modemId}`;
	return await axios.get(handoffsURL);
}

const getOrderIPs = async (product, circuit) => {
	const orderIPsURL = `${process.env.REACT_APP_API_URL}/getBandwidths.php?menu=ip&product=${product}&circuit=${circuit}`;
	return await axios.get(orderIPsURL);
}

const getOrderUploadTypes = async () => {
	const uploadTypeURL = `${process.env.REACT_APP_API_URL}/getOrderMenus.php?type=uploadTypes`;
	return await axios.get(uploadTypeURL);
}

const getOrderNNICircuitIds = async () => {
	const nniCircuitURL = `${process.env.REACT_APP_API_URL}/getOrderMenus.php?type=nniCircuitID`;
	return await axios.get(nniCircuitURL);
}

const getOrderInterconnectionPoint = async () => {
	const nniLocationURL = `${process.env.REACT_APP_API_URL}/getOrderMenus.php?type=interConnectNNI&product=20`;
	return await axios.get(nniLocationURL);
}

const getOrderNNI = async () => {
	const nniURL = `${process.env.REACT_APP_API_URL}/getOrderMenus.php?type=nniMenu`;
	return await axios.get(nniURL);
}

const getOrderServiceDate = async (quote_id, product, circuit, ref_id, activity) => {
	const servURL = `${process.env.REACT_APP_API_URL}/getOrderMenus.php?type=serviceDate&quote_id=${quote_id}&product=${product}&circuit=${circuit}&ref_id=${ref_id}&activity=${activity}`;
	return await axios.get(servURL);
}

const getOrderCircuitVRF = async (nniLocation) => {
	const circuitVrfURL = `${process.env.REACT_APP_API_URL}/getOrderMenus.php?type=circuitVrf&nniLocation=${nniLocation}`;
	return await axios.get(circuitVrfURL);
}

const getJurisdictionType = async () => {
	const jurisdictionURL = `${process.env.REACT_APP_API_URL}/getOrderMenus.php?type=jurisdiction`;
	return await axios.get(jurisdictionURL);
}

const createOrder = async (values) => {
	const createOrderURL = `${process.env.REACT_APP_API_URL}/orderSubmit.php?data=${encodeURIComponent(JSON.stringify(values))}`;
	return await axios.get(createOrderURL);
}

const addOrderNotes = async (ref_id, note) => {
	const notesURL = `${process.env.REACT_APP_API_URL}/QuoteFunctions.php?type=addNote&site=order&ref_id=${ref_id}&note=${note}`;
	return await axios.get(notesURL);
}

const getOrderNotes = async (ref_id) => {
	const notesURL = `${process.env.REACT_APP_API_URL}/QuoteFunctions.php?type=notes&ref_id=${ref_id}`;
	return await axios.get(notesURL);
}

const getOrderView = async (refId) => {
	const getQuoteViewURL = `${process.env.REACT_APP_API_URL}/getOrderView.php?ref_id=${refId}`;
	return await axios.get(getQuoteViewURL);
}

const createSup = async (supData) => {
	const supURL = `${process.env.REACT_APP_API_URL}/orderSupp.php?data=${JSON.stringify(supData)}`;
	return await axios.get(supURL);
}
const orderStatusCheck = async (refId) => {
	const statusURL = `${process.env.REACT_APP_API_URL}/getOrderMenus.php?type=statusCheck&ref_id=${refId}`;
	return await axios.get(statusURL);
}

const getSavedOrderData = async (refId) => {
	const orderSavedURL = `${process.env.REACT_APP_API_URL}/getOrderView.php?ref_id=${refId}`;
	return await axios.get(orderSavedURL);
}

const icbLineItemCheck = async (refId, bandwidth, term) => {
	const icbLineItemCheckURL = `${process.env.REACT_APP_API_URL}/getOrderMenus.php?type=icbLineItem&ref_id=${refId}&band=${bandwidth}&term=${term}`;
	return await axios.get(icbLineItemCheckURL);
}

const getFileTypes = async () => {
	const getFileTypesURL = `${process.env.REACT_APP_API_URL}/getOrderMenus.php?type=uploadTypes`;
	return await axios.get(getFileTypesURL);
}

const getUploadedFiles = async (value) => {
	const productURL = `${process.env.REACT_APP_API_URL}/QuoteFunctions.php?type=uploadedFiles&ref_id=${value}`;
	return await axios.get(productURL);
}

const uploadFile = async (formData, fileType, ref_id, mpid) => {
	return axios({
		method: "post",
		url: `${process.env.REACT_APP_API_URL}/uploadFile.php?ref_id=${ref_id}&upload_type=${fileType}&mpid=${mpid}`,
		data: formData,
		headers: { "Content-Type": "multipart/form-data" },
	});
}

const archiveQuote = async (refId) => {
	const archiveURL = `${process.env.REACT_APP_API_URL}/QuoteFunctions.php?type=archive&ref_id=${refId}`;
	return await axios.get(archiveURL);
}

const updateOrder = async (values, ref_id) => {
	const updateOrderURL = `${process.env.REACT_APP_API_URL}/internal/orderUpdate.php?data=${encodeURIComponent(JSON.stringify(values))}&ref_id=${ref_id}`;
	return await axios.get(updateOrderURL);
}

const getQuickbaseId = async (refId) => {
	const getTimelineDataURL = `${process.env.REACT_APP_API_URL}/getQuickbaseTimeline.php?ref_id=${refId}&type=qbId`;
	return await axios.get(getTimelineDataURL);
}

const pullOrderTasks = async (refId) => {
	const pullOrderTasksURL = `${process.env.REACT_APP_API_URL}/getQuickbaseTimeline.php?ref_id=${refId}&type=pull`;
	return await axios.get(pullOrderTasksURL);
}

const getTimelineData = async (refId) => {
	const getTimelineDataURL = `${process.env.REACT_APP_API_URL}/getQuickbaseTimeline.php?ref_id=${refId}&type=data`;
	return await axios.get(getTimelineDataURL);
}

const validateManualCircuit = async (circuit) => {
	const validateCircuitURL = `${process.env.REACT_APP_API_URL}/internal/validateManualCircuit.php?circuit=${encodeURIComponent(JSON.stringify(circuit))}`;
	return await axios.get(validateCircuitURL);
}

const OrderHelper = {
	getOrderData,
	getOrderACNA,
	getOrderInitalTerm,
	getOrderBandwidths,
	getOrderHandoffs,
	getOrderIPs,
	getOrderUploadTypes,
	getOrderNNICircuitIds,
	getOrderInterconnectionPoint,
	getOrderNNI,
	getOrderServiceDate,
	getOrderCircuitVRF,
	getJurisdictionType,
	createOrder,
	addOrderNotes,
	getOrderNotes,
	getOrderView,
	createSup,
	orderStatusCheck,
	getSavedOrderData,
	icbLineItemCheck,
	getFileTypes,
	uploadFile,
	getUploadedFiles,
	archiveQuote,
	updateOrder,
	getQuickbaseId,
	pullOrderTasks,
	getTimelineData,
	validateManualCircuit
}

export default OrderHelper
