// CSS Imports
import style from "../quote/QuoteCreate.module.css";

// React Core Libraries
import {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import Loader from "../shared/Loader";
import ContactServices from "../../services/ContactService";
import {ConsoleLogger} from "../../logger/ConsoleLogger";
import HTTPErrorHandler from "../errorPages/HTTPErrorHandler";

const ContactsSection = (props) => {
    const logger = new ConsoleLogger({level: process.env.REACT_APP_LOGGER_LEVEL,});
    const [hasError, setHasError] = useState(null);
    const [orignalContactData, setOriginalContactData] = useState([]);
	const [contactData, setContactData] = useState([]);
	const [rolesData, setRolesData] = useState("");
    const [originalRolesData, setOriginalRolesData] = useState("");
    const [loader, setLoader] = useState(false);
    const [updatedFlag, setUpdatedFlag] = useState(false);
    const [updateFailedFlag, setUpdateFailedFlag] = useState(false);
    const [updateLoadingFlag, setUpdateLoadingFlag] = useState(false);
    const [dupContact, setDupContact] = useState(false);


	useEffect(() => {
		if (props.action === "Update") {
            let rolesArray = props.roles?.split(";");
            setContactData(props.contactDetails);
            setOriginalContactData(props.contactDetails);
            setRolesData(rolesArray);
            setOriginalRolesData(rolesArray);
		} else {
			setContactData((prevState) => ({
				...prevState,
				"gaid": props.gaid,
				"gapid": ""
			}));
		}
    }, [props.action, props.contactDetails, props.gaid, props.roles]);

	const contactHandler = (event) => {
		if (event.target.type === 'text') {
			setContactData((prevState) => ({
				...prevState,
				[event.target.id]: event.target.value
			}));
		} else if (event.target.type === 'radio') {
			setContactData((prevState) => ({
				...prevState,
				[event.target.name]: event.target.value
			}));
		} else { // checkboxes
            let roles = [...rolesData];
            if(event.target.checked){
                roles.push(event.target.value);
            } else {
                roles.splice(roles.indexOf(event.target.value), 1);
            }
			setRolesData(roles);
		}
    }


    const submitHandler = () => {
        setDupContact(false);
        let contactAction = "";
        let selectedRoles = "";

		let checkboxes = document.querySelectorAll('input[type="checkbox"]:checked');
        let roleCount =  document.querySelectorAll('input[type="checkbox"]:checked').length;

        for (let i = 0; i < roleCount; i++){
            if(i !== roleCount - 1){
                selectedRoles += `${checkboxes[i].value};`;
            } else {
                selectedRoles += checkboxes[i].value;
            }
        }

        if(props.action === "Update"){
            contactAction = "editContact";
        } else {
            contactAction = "createContact";
        }

        if((originalRolesData !== rolesData) || (orignalContactData !== contactData)){
            setLoader(true);
            setUpdateLoadingFlag(true);
            ContactServices.updateContactData(contactData, selectedRoles, contactAction, props.gaid).then((response) => {
                logger.info(`Response: ${JSON.stringify(response.data)}`);
                if(response.data?.result === "SUCCESS"){
                    if(response.data?.dupCheck){
                        setDupContact(true);
                    }
                    setUpdateLoadingFlag(false);
                    setLoader(false);
                    setUpdatedFlag(true);
                    //Don't redirect if user has tried to create a duplicate contact
                    if(!response.data?.dupCheck){
                        // Redirect to utility contacts page on a sucessful update or create
                        setTimeout(function() {
                            return window.location = '/utilities/UtilityContacts';
                        }, 5000);
                    }
                } else {
                    setLoader(false);
                    setUpdateFailedFlag(true)
                }
            }).catch(error => {
                logger.error(`Error in updateContactData: ${error}`);
                setHasError(error);
            })
        }

    }

    return (
        <>
        {hasError && <HTTPErrorHandler error={hasError}></HTTPErrorHandler>}
            {!hasError && (updateLoadingFlag ?
                <>
                    <div className="my-4 text-center">
                        <h2 className="text-success">{props.action === "Update" ? "Updating Contact...." : "Adding Contact...."}</h2>
                    </div>
                </>
            : null)}
            {!hasError && (updatedFlag && !dupContact ?
                <>
                    <div className="my-4 text-center">
						<h2 className="text-success">{props.action === "Update" ? "Successfully Updated Contact...." : "Successfully Added Contact...."}</h2>
                    </div>
                </>
            : null)}
            {!hasError && (dupContact ?
                <>
                    <div className="my-4 text-center">
						<h2 className="text-success">Contact Already Exists</h2>
                    </div>
                </>
            : null)}
            {!hasError && (updateFailedFlag ?
                <>
                    <div className="my-4 text-center">
                        <h2 className="text-danger">Updating Contact Failed....</h2>
                    </div>
                </>
            : null)}
            {loader ? <Loader /> : null}
            {!hasError && (<div className="section">
                {/* <TabsQuote /> */}
                <div className="content">
                    <Link to="/utilities/UtilityContacts" className="back_btn_link">
                        Back to Utility Contacts
                    </Link>
                    <div className="editContacts">
                        <form /*onSubmit={contactSubmit}*/>
                            <div className={` row ${style.rowGrp}`}>
                                <div className="col-5">
                                    <div className={` row ${style.rowGrp}`}>
                                        <div className="col-6">
                                            <label className={style.lableTextS}>First Name</label>
                                            <input type="text" id='firstName' className={style.inputFld} value={contactData.firstName} onChange={contactHandler} />
                                        </div>
                                        <div className="col-6">
                                            <label className={style.lableTextS}>Last Name</label>
                                            <input type="text" id='lastName' className={style.inputFld} value={contactData.lastName} onChange={contactHandler} />
                                        </div>
                                    </div>
                                    <div className={` row ${style.rowGrp}`}>
                                        <div className="col-12">
                                            <label className={style.lableTextS}>Phone</label>
                                            <input type="text" id='phone' className={style.inputFld} value={contactData.phone} onChange={contactHandler} />
                                        </div>
									</div>
                                    <div className={` row ${style.rowGrp}`}>
                                        <div className="col-12">
                                            <label className={style.lableTextS}>Email</label>
                                            <input type="text" id='email' className={style.inputFld} value={contactData.email} onChange={contactHandler} />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-7">
                                    <div className={` row ${style.rowGrp} contactTypeSubHeadingRow`}>
                                        <div className="col-6">
                                            <label className={style.lableTextS}>Contact Type</label>
                                        </div>
                                    </div>
                                    <div className={` row ${style.rowGrp}`}>
                                        <div className="col-5">
                                            <input type="checkbox" id="addtnlTicket" name="addtnlTicket" checked={rolesData.includes("Additional Ticketing") ? "checked" : ""} value= "Additional Ticketing" onChange={contactHandler}/>
                                            <label htmlFor="addtnlTicket" className={` ${style.checkboxText} `}>Additional Ticketing</label>
                                        </div>
                                        <div className="col-4">
											<input type="checkbox" id="afterHrs" name="afterHrs" checked={rolesData.includes("After Hours") ? "checked" : ""}  value="After Hours" onChange={contactHandler} />
                                            <label htmlFor="afterHrs" className={` ${style.checkboxText} `}>After Hours</label>
                                        </div>
                                    </div>
                                    <div className={` row ${style.rowGrp}`}>
										<div className="col-5">
											<input type="checkbox" id="primaryContact" name="primaryContact" checked={rolesData.includes("Primary Contact") ? "checked" : ""} value="Primary Contact" onChange={contactHandler} />
                                            <label htmlFor="primaryContact" className={` ${style.checkboxText} `}>Primary Contact</label>
                                        </div>
                                        <div className="col-6">
                                            <input type="checkbox" id="primaryMngdRouter" name="primaryMngdRouter" checked={rolesData.includes("Primary Managed Router") ? "checked" : ""} value="Primary Managed Router" onChange={contactHandler} />
                                            <label htmlFor="primaryMngdRouter" className={` ${style.checkboxText} `}>Primary Managed Router</label>
                                        </div>
                                    </div>
                                    <div className={` row ${style.rowGrp}`}>
                                        <div className="col-5">
											<input type="checkbox" id="localTicketing" name="localTicketing" checked={rolesData.includes("Local Ticketing") ? "checked" : ""} value="Local Ticketing" onChange={contactHandler} />
                                            <label htmlFor="localTicketing" className={` ${style.checkboxText} `}>Local Ticketing</label>
                                        </div>
                                        <div className="col-6">
                                            <input type="checkbox" id="mngdRouterContact" name="mngdRouterContact" checked={rolesData.includes("Managed Router Contact") ? "checked" : ""} value="Managed Router Contact" onChange={contactHandler} />
                                            <label htmlFor="mngdRouterContact" className={` ${style.checkboxText} `}>Managed Router Contact</label>
                                        </div>
                                    </div>
                                    <div className={` row ${style.rowGrp}`}>
                                        <div className="col-5">
                                        <input type="checkbox" id="cpeVendor" name="cpeVendor" checked={rolesData.includes("CPE - Vendor") ? "checked" : ""} value="CPE - Vendor" onChange={contactHandler}/>
                                            <label htmlFor="cpeVendor" className={` ${style.checkboxText} `}>CPE - Vendor</label>
                                        </div>
                                        <div className="col-4">
											<input type="checkbox" id="dataTechnical" name="dataTechnical" checked={rolesData.includes("Data - Technical") ? "checked" : ""} value="Data - Technical" onChange={contactHandler} />
                                            <label htmlFor="dataTechnical" className={` ${style.checkboxText} `}>Data - Technical</label>
                                        </div>
                                    </div>
                                    <div className={` row ${style.rowGrp}`}>
                                        <div className="col-5">
                                            <input type="checkbox" id="primaryTicketing" name="primaryTicketing" checked={rolesData.includes("Primary Ticketing") ? "checked" : ""} value="Primary Ticketing" onChange={contactHandler}/>
                                            <label htmlFor="primaryTicketing" className={` ${style.checkboxText} `}>Primary Ticketing</label>
                                        </div>
                                        <div className="col-4">
											<input type="checkbox" id="secondaryContact" name="secondaryContact" checked={rolesData.includes("Secondary Contact") ? "checked" : ""} value="Secondary Contact" onChange={contactHandler} />
                                            <label htmlFor="secondaryContact" className={` ${style.checkboxText} `}>Secondary Contact</label>
                                        </div>
                                    </div>
                                    <div className={` row ${style.rowGrp}`}>
                                        <div className="col-5">
											<input type="checkbox" id="onSiteCoordinator" name="onSiteCoordinator" checked={rolesData.includes("On-Site Coordinator") ? "checked" : ""} value="On-Site Coordinator" onChange={contactHandler} />
                                            <label htmlFor="onSiteCoordinator" className={` ${style.checkboxText} `}>On-Site Coordinator</label>
                                        </div>
                                        <div className="col-4">
											<input type="checkbox" id="partner" name="partner" checked={rolesData.includes("Partner") ? "checked" : ""} value="Partner" onChange={contactHandler} />
                                            <label htmlFor="partner" className={` ${style.checkboxText} `}>Partner</label>
                                        </div>
                                    </div>
                                    <div className={` row ${style.rowGrp}`}>
                                        <div className="col-5">
											<input type="checkbox" id="dataVendor" name="dataVendor" checked={rolesData.includes("Data - Vendor") ? "checked" : ""} value="Data - Vendor" onChange={contactHandler} />
                                            <label htmlFor="dataVendor" className={` ${style.checkboxText} `}>Data - Vendor</label>
                                        </div>
                                        <div className="col-4">
											<input type="checkbox" id="dataCenterAccess" name="dataCenterAccess" checked={rolesData.includes("Data Center Access") ? "checked" : ""} value="Data Center Access" onChange={contactHandler} />
                                            <label htmlFor="dataCenterAccess" className={` ${style.checkboxText} `}>Data Center Access</label>
                                        </div>
                                    </div>
                                    <div className={` row ${style.rowGrp}`}>
                                        <div className="col-5">
											<input type="checkbox" id="voiceTechnical" name="voiceTechnical" checked={rolesData.includes("Voice Technical") ? "checked" : ""} value="Voice Technical"onChange={contactHandler} />
                                            <label htmlFor="voiceTechnical" className={` ${style.checkboxText} `}>Voice - Technical</label>
                                        </div>
                                        <div className="col-4">
											<input type="checkbox" id="voiceVendor" name="voiceVendor" checked={rolesData.includes("Voice - Vendor") ? "checked" : ""} value="Voice - Vendor" onChange={contactHandler} />
                                            <label htmlFor="voiceVendor" className={` ${style.checkboxText} `}>Voice - Vendor</label>
                                        </div>
                                    </div>
                                    <div className={` row ${style.rowGrp}`}>
                                        <div className="col-5">
											<input type="checkbox" id="primaryBilling" name="primaryBilling" checked={rolesData.includes("Primary Billing") ? "checked" : ""} value="Primary Billing" onChange={contactHandler} />
                                            <label htmlFor="primaryBilling" className={` ${style.checkboxText} `}>Primary Billing</label>
                                        </div>
                                        <div className="col-4">
											<input type="checkbox" id="decisionMaker" name="decisionMaker" checked={rolesData.includes("Decision Maker") ? "checked" : ""} value="Decision Maker" onChange={contactHandler}/>
                                            <label htmlFor="decisionMaker" className={` ${style.checkboxText} `}>Decision Maker</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="text-center">
                                <div className={`row ${style.rowGrp} updateContactBtnRow `}>
                                        <input className="updateContactBtn" type="button" value={props.action === "Update" ? "Update Contact" : "Add Contact"} onClick={submitHandler} />
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>)}
        </>
    );
}

export default ContactsSection;
