import { useSelector } from "react-redux";
import style from "../BulkQuoteView.module.css";
import { NavLink } from "react-router-dom";
import filterFactory from "react-bootstrap-table2-filter";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { ConsoleLogger } from "../../../logger/ConsoleLogger";

const BulkQuoteView = (props) => {
    const logger = new ConsoleLogger({ level: process.env.REACT_APP_LOGGER_LEVEL, });
    const isEmployee = localStorage.getItem('isEmployeeLoggedIn');
    const isCustomer = localStorage.getItem("isLoggedIn");
    const selectedAccessValues = useSelector(state => state.userReducer.user.webaccess);
    let checkedList = [];

    const columns = [{
        dataField: "ref_id",
        text: "Ref ID",
        formatter: (cell, row) => quoteLinkFormatter(cell, row)
    },{
        dataField: "bq_desc",
        text: "Status",
        formatter: (cell, row) => statusFormater(cell, row)
    },{
        dataField: "address",
        text: "Address",
    },{
        dataField: "bus_name",
        text: "Business Name"
    },{
        dataField: "loop_band",
        text: "Bandwidth"
    },{
        dataField: "1yr_mrc",
        text: "1yr MRC"
    },{
        dataField: "1yr_nrc",
        text: "1yr NRC"
    },{
        dataField: "2yr_mrc",
        text: "2yr MRC"
    },{
        dataField: "2yr_nrc",
        text: "2yr NRC"
    },{
        dataField: "3yr_mrc",
        text: "3yr MRC"
    },{
        dataField: "3yr_nrc",
        text: "3yr NRC"
    },{
        dataField: "4yr_mrc",
        text: "4yr MRC"
    },{
        dataField: "4yr_nrc",
        text: "4yr NRC"
    },{
        dataField: "5yr_mrc",
        text: "5yr MRC"
    },{
        dataField: "5yr_nrc",
        text: "5yr NRC"
    }];

    const statusFormater = (cell, row) => {
        logger.info(`Cell: ${cell} Row: ${JSON.stringify(row)}`);
        if (Number(row.status) === 1 && Number(row.made_orderable) === 1) {
            return (
                <div className={`${style.bulkQuoteLabel}`}>
                    Success
                </div>
            );
        } else if (Number(row.status) === 1 && Number(row.made_orderable) === 0 && (isEmployee || (isCustomer && Number(selectedAccessValues?.TRANS_ORDER) > 1))) {
            return (
                <div>
                    <input className={style.bulkOrderCheckbox} type={"checkbox"} id={row.ref_id} onChange={handleOrderableCheckboxes} />
                    <label htmlFor={row.ref_id} className={`${style.bulkQuoteLabel}`}>Make Orderable</label>
                </div>
            );
        } else {
            return (
                <div className={`${style.bulkQuoteLabel}`}>
                    <abbr title={row.note}>{row.bq_desc}</abbr>
                </div>
            );
        }
    }

    // This is how the link is set up.
    const quoteLinkFormatter = (cell, row) => {
        let URL = `/Quote/View/${row.ref_id}/0`;
        return (<NavLink className={`${style.bulkQuoteLabel} ${style.bulkQuoteLink}`} to={URL}
            activeClassName="active">{row.ref_id}</NavLink>)
    }

    const handleOrderableCheckboxes = (event) => {
        logger.info(`Event: ${event.target.id}`);
        if (event.target.checked) {
            checkedList.push(event.target.id);
        } else {
            checkedList = checkedList.filter((checkboxId) => checkboxId !== event.target.id);
        }

        logger.info(`Checked List: ${JSON.stringify(checkedList)}`);
        // Passing the checked list to the parent component BulkQuoteView.js
        props.handleCheckboxes(checkedList);
    }

    return (
        <>
            <div className={`row ${style.rowGrp} mt-3`}>
                <div className={`${style.contentQueryPage} `}>
                    <div className={`tableWrap bulkQuoteSummaryTableWrap`}>
                        <div className={`rel pagination-table page-list`}>
                            <BootstrapTable id="bulkTable"
                                classes={`table-fixed table table-hover table-striped text-center`}
                                keyField='ref_id'
                                data={props.bulkQuoteList}
                                columns={columns}
                                bootstrap4
                                striped
                                hover
                                bordered={false}
                                pagination={paginationFactory({
                                    sizePerPage: 10
                                })}
                                filter={filterFactory()}
                                loading={true}
                                className="mb-0"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default BulkQuoteView;
