/**
 * Created by e0173131 on 1/20/2021.
 */
import React, {useState, Fragment} from 'react';

const GoodieMisc = () => {
    const [isCollapsed, setIsCollapsed] = useState(true);

    const handleClick = () => {
        setIsCollapsed(!isCollapsed);
    }
    return (
        <Fragment>
            <span className="cf"></span>
            <div className="sub-hdr alt1">
                <div className="expander" collapsed-id="other-content" onClick={handleClick}>
                    <svg className="ico-link light-ico ico-add">
                        <path
                            d="M24,48A24,24,0,1,1,48,24,24,24,0,0,1,24,48ZM24,1.89A22.1,22.1,0,1,0,46.08,24,22.09,22.09,0,0,0,24,1.89Zm1,32.69H23.05V25h-9.6V23.05h9.6v-9.6H25v9.6h9.61V25H25Z"></path>
                    </svg>
                </div>
                <div className="trim">&nbsp;</div>
                <div className="nib">
                    <div className="the-notch">
                        <div className="notch-fg">
                            <svg>
                                <path
                                    d="M35.81,26.28l-13,10v-5C-10.47,28.68-1,9.16,11.79,0c0,7.28,0,9.13,0,13.28s3.18,7.92,11,9v-5Z"></path>
                            </svg>
                        </div>
                    </div>
                </div>
                <h2>Misc</h2>
            </div>
            {!isCollapsed ?
                <div className="sub-section">
                    <h3>The "Record Set"</h3>
                    <p>Field/value data presented in a more exciting and - more importantly - identifiable way!
                        Use <em className="code">with-ico</em> and/or <em className="code">na</em> classes when you
                        can use an icon or the value is not available. <a href="~/Order">More examples</a> of what
                        icons can go with what record types.</p>
                    <div className="record-set">
                        <div className="record-ico">
                            <svg className="ico ico-m">
                                <path
                                    d="M1.5,36V3h3V0h14V3h3v7h13V36Zm19-32h-3V1H5.5V4h-3V34h7V29h4v5h7Zm13,7h-12V34h12Zm-8,6h-2V15h2Zm0,5h-2V20h2Zm0,5h-2V25h2Zm0,5h-2V30h2Zm3-15h-2V15h2Zm0,5h-2V20h2Zm0,5h-2V25h2Zm0,5h-2V30h2Zm3-15h-2V15h2Zm0,5h-2V20h2Zm0,5h-2V25h2Zm0,5h-2V30h2Zm-18-9h1v3h-1Zm4-11h-2V9h2Zm0,7h-2V16h2Zm0,7h-2V23h2Zm0,6h-2V29h2Zm-4-16h1v3h-1Zm0-7h1v3h-1Zm-5,14h1v3h-1Zm0-7h1v3h-1Zm0-7h1v3h-1Zm-3,20h2v3h-2Zm0-6h2v3h-2Zm0-7h2v3h-2Zm0-7h2v3h-2Z"></path>
                            </svg>
                        </div>
                        <div className="record-set-top">
                            <h4>Field</h4>
                        </div>
                        <div className="record-set-bottom">
                            <span>Value</span>
                        </div>
                    </div>
                    <div className="record-set with-ico">
                        <div className="record-ico">
                            <svg className="ico ico-m">
                                <path
                                    d="M1.5,36V3h3V0h14V3h3v7h13V36Zm19-32h-3V1H5.5V4h-3V34h7V29h4v5h7Zm13,7h-12V34h12Zm-8,6h-2V15h2Zm0,5h-2V20h2Zm0,5h-2V25h2Zm0,5h-2V30h2Zm3-15h-2V15h2Zm0,5h-2V20h2Zm0,5h-2V25h2Zm0,5h-2V30h2Zm3-15h-2V15h2Zm0,5h-2V20h2Zm0,5h-2V25h2Zm0,5h-2V30h2Zm-18-9h1v3h-1Zm4-11h-2V9h2Zm0,7h-2V16h2Zm0,7h-2V23h2Zm0,6h-2V29h2Zm-4-16h1v3h-1Zm0-7h1v3h-1Zm-5,14h1v3h-1Zm0-7h1v3h-1Zm0-7h1v3h-1Zm-3,20h2v3h-2Zm0-6h2v3h-2Zm0-7h2v3h-2Zm0-7h2v3h-2Z"></path>
                            </svg>
                        </div>
                        <div className="record-set-top">
                            <h4>Company</h4>
                        </div>
                        <div className="record-set-bottom">
                            <span>With an icon that makes sense</span>
                        </div>
                    </div>
                    <div className="record-set na">
                        <div className="record-ico">
                            <svg className="ico ico-m">
                                <path
                                    d="M1.5,36V3h3V0h14V3h3v7h13V36Zm19-32h-3V1H5.5V4h-3V34h7V29h4v5h7Zm13,7h-12V34h12Zm-8,6h-2V15h2Zm0,5h-2V20h2Zm0,5h-2V25h2Zm0,5h-2V30h2Zm3-15h-2V15h2Zm0,5h-2V20h2Zm0,5h-2V25h2Zm0,5h-2V30h2Zm3-15h-2V15h2Zm0,5h-2V20h2Zm0,5h-2V25h2Zm0,5h-2V30h2Zm-18-9h1v3h-1Zm4-11h-2V9h2Zm0,7h-2V16h2Zm0,7h-2V23h2Zm0,6h-2V29h2Zm-4-16h1v3h-1Zm0-7h1v3h-1Zm-5,14h1v3h-1Zm0-7h1v3h-1Zm0-7h1v3h-1Zm-3,20h2v3h-2Zm0-6h2v3h-2Zm0-7h2v3h-2Zm0-7h2v3h-2Z"></path>
                            </svg>
                        </div>
                        <div className="record-set-top">
                            <h4>Field with no value</h4>
                        </div>
                        <div className="record-set-bottom">
                            <span>n/a</span>
                        </div>
                    </div>
                    <h3>WE Connect Loader</h3>
                    <p><em>Stolen/borrowed from WE Connect</em></p>
                    <div className="rel goodies-ldr-example" style={{ width: "100px", height: "100px" }}>
                        {/*<style>
                            .goodies-ldr-example .ww-loader {display: block;}
                        </style>*/}
                        <div className="ww-loader">
                            <div className="lds-double-ring">
                                <div>&nbsp;</div>
                                <div>&nbsp;</div>
                            </div>
                        </div>
                    </div>
                </div>
            : null}
        </Fragment>
    );
}

export default GoodieMisc;
