import React, { useEffect, useState } from "react";
import { connect, useSelector } from 'react-redux';
import QuoteHelper from "../../services/QuoteService";
import Loader from "../shared/Loader";
import { Redirect } from "react-router-dom";
import { ConsoleLogger } from "../../logger/ConsoleLogger";
import { resetData } from "../../redux/actions/quoteActions";
import { resetInternalQuote } from "../../redux/actions/internalActions";
import HTTPErrorHandler from "../errorPages/HTTPErrorHandler";
import BroadbandHelper from "../../services/BroadbandServices";
import OfficeSuiteHelper from "../../services/OfficeSuiteService";

const QuoteSubmit = (props) => {
	const logger = new ConsoleLogger({ level: process.env.REACT_APP_LOGGER_LEVEL, });
	const [hasError, setHasError] = useState(null);
	const [quoteFlag, setQuoteFlag] = useState(false);
	const [quoteError, setQuoteError] = useState(false);
	const [quoteErrorMessage, setQuoteErrorMessage] = useState("");
	const [loader, setLoader] = useState(false);
	const [refId, setRefId] = useState("");
	const [generatePDF, setGeneratePDF] = useState(false);
	//Getting Entered Quote Values from the redux store
	const quoteData = useSelector(state => state.quote.quoteValues);
	//Getting Entered Contact Details from the redux store
	const contactDetails = useSelector(state => state.quoteContact.contactValues);
	//Getting Location Values from the redux store
	const locationADetails = useSelector(state => state.quoteLocA.locA);
	const locationZDetails = useSelector(state => state.quoteLocZ.locZ);
	const selectedInternalValues = useSelector(state => state.internal.quote);
	const isEmployee = localStorage.getItem("isEmployeeLoggedIn")


	//Final json that will be sent to quoteSubmit.php
	const submitJSON = {
		"product": quoteData.product,  //DB PRODUCT ID
		"circuit": quoteData.circuit, //DB Circuit Type ID
		"busName": contactDetails.busName, // Quote Name
		"mrc_price": contactDetails.mrcPrice,
		"cust_ref": contactDetails.refId,
		"manual": contactDetails.manual_icb,
		"manual_comments": contactDetails.icb_comments,
		"one": quoteData.term1, //Term 1 checkbox
		"two": quoteData.term2, //Term 2 checkbox
		"three": quoteData.term3, //Term 3 checkbox
		"four": quoteData.term4,
		"five": quoteData.term5,
		"ten": quoteData.term10,
		"loop_band": { //Bandwidth
			"1": quoteData.bw,
			"2": quoteData.bw1,
			"3": quoteData.bw2
		},
		"qty": quoteData.qty,
		"a_nni": quoteData.nniLoc.value, // NNI Location Select Box
		"contact": contactDetails.name, //Contact Id
		"new_contact": contactDetails.newName, //Add new contact name
		"cp_phone": contactDetails.phone, //Phone
		"email": contactDetails.email, //Email
		"a_lec_type": locationADetails.a_lec_type,
		"a_add": locationADetails.a_add,
		"a_ld1": locationADetails.a_struct,
		"a_lv1": locationADetails.a_structNum,
		"a_ld2": 'FL',
		"a_lv2": locationADetails.a_floorNum,
		"a_ld3": locationADetails.a_unit,
		"a_lv3": locationADetails.a_unitNum,
		"a_city": locationADetails.a_city,
		"a_state": locationADetails.a_state,
		"a_zip": locationADetails.a_zip,
		"z_add": locationZDetails.z_add,
		"z_ld1": locationZDetails.z_struct,
		"z_lv1": locationZDetails.z_structNum,
		"z_ld2": 'FL',
		"z_lv2": locationZDetails.z_floorNum,
		"z_ld3": locationZDetails.z_unit,
		"z_lv3": locationZDetails.z_unitNum,
		"z_city": locationZDetails.z_city,
		"z_state": locationZDetails.z_state,
		"z_zip": locationZDetails.z_zip,
		"enni_ext": quoteData.enniExtension,
		"clliA": quoteData.product === 10 || quoteData.product === 11 ? quoteData.popLoc.value : "",
		"ips": quoteData.ips,
		"ddos": quoteData.ddos,
		"cam_qty": quoteData.cam_qty,
		"ddos_type": quoteData.ddos_type,
		"la_conx": quoteData.cross_connect,
		"uid": quoteData.product === 15 ? quoteData.popLoc.value : "",
		"rack_space": quoteData.rack_space,
		"breaker_amp": quoteData.breaker_amp,
		"primary_power": quoteData.primary_power,
		"secondary_power": quoteData.secondary_power,
		"fuse_panel": quoteData.fuse_panel,
		"d_add": locationADetails.a_add,
		"d_ld1": locationADetails.a_struct,
		"d_lv1": locationADetails.a_structNum,
		"d_ld2": 'FL',
		"d_lv2": locationADetails.a_floorNum,
		"d_ld3": locationADetails.a_unit,
		"d_lv3": locationADetails.a_unitNum,
		"d_city": locationADetails.a_city,
		"d_state": locationADetails.a_state,
		"d_zip": locationADetails.a_zip,
		"cpe_type": quoteData.cpe_type,
		"router_service": quoteData.router_service,
		"vendorexcl": quoteData.vendor_exclusion,
		"vendorexcl_att": quoteData.vendorexcl_att,
		"vendorexcl_verizon": quoteData.vendorexcl_verizon,
		"vendorexcl_lumen": quoteData.vendorexcl_lumen,
		"vendorexcl_comcast": quoteData.vendorexcl_comcast,
		"vendorexcl_spectrum": quoteData.vendorexcl_spectrum,
		"coAddA": quoteData.coAddA.value,
		"coAddZ": quoteData.coAddZ.value,
		"service_protocol": {
			"1": quoteData.protocol1,
			"2": quoteData.protocol2,
			"3": quoteData.protocol3,
		},
		"route": quoteData.route_type,
		"diversity": quoteData.diversity,
		"diversity_note": quoteData.div_note,
		"route_latency": quoteData.route_latency,
		"kmz_file": quoteData.kmz_file,
		"la_existing_uni": quoteData.la_existing_uni,
		"lz_existing_uni": quoteData.lz_existing_uni,
		"a_vpn_port": {
			"1": quoteData.a_vpn_port_1,
			"2": quoteData.a_vpn_port_2,
			"3": quoteData.a_vpn_port_3
		},
		"z_vpn_port": {
			"1": quoteData.z_vpn_port_1,
			"2": quoteData.z_vpn_port_2,
			"3": quoteData.z_vpn_port_3
		},
		"cos": {
			"1": quoteData.cos1,
			"2": quoteData.cos2,
			"3": quoteData.cos3
		},
		"accessType": quoteData.accessType,
		"burstbilling": {
			"1": quoteData.burstbilling1,
			"2": quoteData.burstbilling2,
			"3": quoteData.burstbilling3,
		},
		"commit_band": {
			"1": quoteData.commitbw1,
			"2": quoteData.commitbw2,
			"3": quoteData.commitbw3,
		},
		"cosneeded": quoteData.cosneeded,
		"bamKey": quoteData.bamKey,
		"cloned": quoteData.cloned?.length > 0 ? quoteData.cloned : "",
		"agency": (selectedInternalValues?.agency?.value && isEmployee) ? selectedInternalValues.agency.value : "",
		"disableEmail": (selectedInternalValues?.disableCustEmail && isEmployee) ? selectedInternalValues.disableCustEmail : "",
		"renewal": (selectedInternalValues?.renewalQuote && isEmployee) ? selectedInternalValues.renewalQuote : "",
		"ex_circuit": (selectedInternalValues?.existingCircuitID && isEmployee) ? selectedInternalValues.existingCircuitID : "",
		"bill_system": (selectedInternalValues?.billingSystem && isEmployee) ? selectedInternalValues.bill_system : "",
		"prev_id": (selectedInternalValues?.prevQuoteNum && isEmployee) ? selectedInternalValues.prevQuoteNum : "",
		"network": (selectedInternalValues?.legacyNetwork && isEmployee) ? selectedInternalValues.legacyNetwork : "",
		"offnet": (selectedInternalValues?.offnet && isEmployee) ? selectedInternalValues.offnet : "",
		"noncom": (selectedInternalValues?.nonCommissionable && isEmployee) ? selectedInternalValues.nonCommissionable : "",
		"markup": (selectedInternalValues?.markup && isEmployee) ? selectedInternalValues.markup : "",
		"wholesale_opp_num": (selectedInternalValues?.wholesaleOpp && isEmployee) ? selectedInternalValues.wholesaleOpp : "",
		"agent_info": (selectedInternalValues?.agent && isEmployee) ? selectedInternalValues.agent : "",
		"agent_name": (selectedInternalValues.agentName && isEmployee) ? selectedInternalValues.agentName : "",
		"agent_phone": (selectedInternalValues.agentPhone && isEmployee) ? selectedInternalValues.agentPhone : "",
		"agent_fee": (selectedInternalValues.agentFee && isEmployee) ? selectedInternalValues.agentFee : "",
		"agent_email": (selectedInternalValues.agentEmail && isEmployee) ? selectedInternalValues.agentEmail : "",
		"agent_add": (selectedInternalValues.agentAddress && isEmployee) ? selectedInternalValues.agentAddress : "",
		"agent_city": (selectedInternalValues.agentCity && isEmployee) ? selectedInternalValues.agentCity : "",
		"agent_state": (selectedInternalValues.agentState && isEmployee) ? selectedInternalValues.agentState : "",
		"agent_zip": (selectedInternalValues.agentZip && isEmployee) ? selectedInternalValues.agentZip : "",
		"enterprise_rep": (selectedInternalValues.enterpriseRep && isEmployee) ? selectedInternalValues.enterpriseRep : "",
		"enterprise_name": (selectedInternalValues.enterpriseRep && selectedInternalValues.enterpriseRepObj?.enterprise_name) ? selectedInternalValues.enterpriseRepObj.enterprise_name : "",
		"enterprise_email": (selectedInternalValues.enterpriseRep && selectedInternalValues.enterpriseRepObj?.enterprise_email) ? selectedInternalValues.enterpriseRepObj.enterprise_email : "",
		"overrideA": locationADetails.a_override,
		"a_lat": locationADetails.a_lat,
		"a_lon": locationADetails.a_long,
		"overrideD": locationADetails.a_override, //DIA PREM Lat & Long override
		"d_lat": locationADetails.a_lat,
		"d_lon": locationADetails.a_long,
		"overrideE": locationADetails.a_override, //EACCESS Lat & Long override
		"e_lat": locationADetails.a_lat,
		"e_lon": locationADetails.a_long,
		"overrideA_WAVE": locationADetails.a_override, //WAVE Lat & Long override
		"overrideZ_WAVE": locationZDetails.z_override, //WAVE Lat & Long override
		"z_lat": locationZDetails.z_lat,
		"z_lon": locationZDetails.z_long,
		"overrideZ": locationZDetails.z_override,
		//Route Creator data
		"availability": quoteData?.availability,
		"routeDetails": quoteData?.routeDetails,
		"kmzUrl": quoteData?.kmzUrl,
		"edges": quoteData?.pathEdges,
		"hops": quoteData?.routeHops,
		"route_capacity": quoteData?.routeCapacity,
		"fiber_miles": quoteData?.routeFiberMiles,
		"latency": quoteData?.pathLatency,
		"buildings": quoteData?.routeBuildings,
		"otuc_path": quoteData?.otucPath,
		"network_edges": quoteData?.networkEdges,
		"network_path": quoteData?.networkPath,
		"regens": quoteData?.routeRegens,
		// DARKFIBER DATA
		"df_options": quoteData?.df_options,
		"df_term": quoteData?.df_term,
		"df_num_fibers": quoteData?.df_num_fibers,
		"df_comments": quoteData?.df_comments,
	};


	useEffect(() => {
		logger.info(submitJSON);
		logger.info("PRODUCT CODE: " + quoteData?.product_code);
		if (quoteData?.product_code === "BROADBAND") {
			let broadbandSubmitJSON = {
				"product": quoteData.product,
				"a_add": locationADetails.a_add,
				"a_ld1": locationADetails.a_struct,
				"a_lv1": locationADetails.a_structNum,
				"a_ld2": 'FL',
				"a_lv2": locationADetails.a_floorNum,
				"a_ld3": locationADetails.a_unit,
				"a_lv3": locationADetails.a_unitNum,
				"a_city": locationADetails.a_city,
				"a_state": locationADetails.a_state,
				"a_zip": locationADetails.a_zip,
				"one": quoteData.term1,
				"two": quoteData.term2,
				"three": quoteData.term3,
				"monthTomonth": quoteData.termMonthToMonth,
				"loop_band": quoteData.bw,
				"qty": quoteData.qty,
				"modemId": quoteData.modem,
				"ip_add": quoteData.typeOfIps,
				"ips": quoteData.ips,
				"cp_contact_id": contactDetails.name,
				"new_contact": contactDetails.newName,
				"cp_phone": contactDetails.phone,
				"cp_email": contactDetails.email,
				"busName": contactDetails.busName,
				"mrc_price": contactDetails.mrcPrice,
				"cust_ref": contactDetails.refId
			}
			broadbandQuoteSubmit(broadbandSubmitJSON);
		} else if (quoteData?.product_code === "OFFICESUITE") {
			logger.info("Bulding officeSuiteSubmitJSON");
			let officeSuiteSubmitJSON = {
				"product": quoteData.product,
				"a_add": locationADetails.a_add,
				"a_ld1": locationADetails.a_struct,
				"a_lv1": locationADetails.a_structNum,
				"a_ld2": 'FL',
				"a_lv2": locationADetails.a_floorNum,
				"a_ld3": locationADetails.a_unit,
				"a_lv3": locationADetails.a_unitNum,
				"a_city": locationADetails.a_city,
				"a_state": locationADetails.a_state,
				"a_zip": locationADetails.a_zip,
				"two": quoteData.term2,
				"three": quoteData.term3,
				"five": quoteData.term5,
				"contact": contactDetails.name,
				"new_contact": contactDetails.newName,
				"cp_phone": contactDetails.phone,
				"email": contactDetails.email,
				"busName": contactDetails.busName,
				"mrc_price": contactDetails.mrcPrice,
				"cust_ref": contactDetails.refId,
				// Officesuite data
				"assoc_ref_id": quoteData?.assocRefId,
				"tlc_this_loc": quoteData?.tlcThisLoc,
				"ent_group": quoteData?.entGroup,
				"ext_to_ext": quoteData?.extToExt,
				"ext_length": quoteData?.extLength,
				"porting": quoteData?.porting,
				"btn": quoteData?.btn,
				"quantities": quoteData?.officeSuiteQuantities
			}
			logger.info("calling officeSuiteQuoteSubmit");
			officeSuiteQuoteSubmit(officeSuiteSubmitJSON);
		} else {
			logger.info("calling quoteSubmit");
			quoteSubmit(submitJSON);
		}
	}, []);


	const quoteSubmit = (data) => {
		setLoader(true);
		setQuoteFlag(false);
		QuoteHelper.createQuote(data).then(resp => {
			logger.info(resp.data);
			setLoader(false);
			if (resp.data.result === "SUCCESS") {
				if (resp.data.generatePDF === 1) {
					setGeneratePDF(true);
				}
				props.clearQuoteData();
				if (isEmployee) {
					props.clearInternalQuoteData();
				}
				setRefId(resp.data.ref_id);
				setQuoteFlag(true);
			} else {
				setQuoteErrorMessage(resp.data.error);
				setQuoteError(true);
			}
		}).catch((error) => {
			logger.error("quoteCreate ERROR: " + error);
			setHasError(error);
			setLoader(false);
		})
	}

	const broadbandQuoteSubmit = (data) => {
		setLoader(true);
		BroadbandHelper.createKineticBroadbandQuote(data).then(response => {
			setLoader(false);
			logger.info(`Broadband quote submit response: ${response.data}`);
			if (response.data.result === "SUCCESS") {
				if (response.data.generatePDF === 1) {
					setGeneratePDF(true);
				}
				props.clearQuoteData();
				if (isEmployee) {
					props.clearInternalQuoteData();
				}
				setRefId(response.data.ref_id);
				setQuoteFlag(true);
			} else {
				setQuoteErrorMessage(response.data.error);
				setQuoteError(true);
			}
		}).catch(error => {
			logger.error(`Error submitting kinetic broadband quote: ${error}`);
		})
	}

	const officeSuiteQuoteSubmit = (data) => {
		setLoader(true);
		OfficeSuiteHelper.createQuote(data).then(response => {
			setLoader(false);
			logger.info(`Office quote submit response: ${response.data}`);
			if (response.data.result === "SUCCESS") {
				if (response.data.generatePDF === 1) {
					setGeneratePDF(true);
				}
				props.clearQuoteData();
				if (isEmployee) {
					props.clearInternalQuoteData();
				}
				setRefId(response.data.ref_id);
				setQuoteFlag(true);
			} else {
				setQuoteErrorMessage(response.data.error);
				setQuoteError(true);
			}
		}).catch(error => {
			logger.error(`Error submitting officesuite quote: ${error}`);
		})
	}

	return (
		<>
			{hasError && <HTTPErrorHandler error={hasError}></HTTPErrorHandler>}
			{loader ? <>
				<Loader />
				<div className="mt-5">
					<p className="text-success text-center">Submitting Quote...</p>
				</div></> : null
			}
			{!hasError && (<>{quoteFlag ?
				<>
					<div className="mt-5">
						<p className="text-success text-center">Quote Created</p>
					</div>
					<Redirect to={{ pathname: `/Quote/View/${refId}`, state: { generatePDF: generatePDF } }} />
				</> : null
			}
				{quoteError ?
					<div className="mt-5">
						<p className="text-error text-center">Quote Failed to Create</p>
						<p className="text-error text-center">{quoteErrorMessage}</p>
					</div> : null
				}</>)}
		</>

	);
}
const mapDispatchToProps = {
	clearQuoteData: resetData,
	clearInternalQuoteData: resetInternalQuote
}
export default connect(null, mapDispatchToProps)(QuoteSubmit);
