
import { useState, useEffect, useMemo } from "react";
import style from "./QuoteCreate.module.css";
import { ConsoleLogger } from "../../logger/ConsoleLogger";
import HomeData from "../../services/HomeServices";
import BroadbandHelper from "../../services/BroadbandServices";
import AsyncSelect from 'react-select/async';
import { setBroadbandObj, setUqualSearchByFlag, setUqualId } from "../../redux/actions/quoteActions";
import { connect, useSelector } from "react-redux";
import Loader from "../shared/Loader";
import {
	setLocAAddress,
	setLocACity,
	setLocAState,
	setLocAZip,
    setLocAValidationFlag
} from '../../redux/actions/quoteLocAActions';
import ReactTooltip from "react-tooltip";

const UqualAddress = (props) => {
    const logger = useMemo(() => new ConsoleLogger({ level: process.env.REACT_APP_LOGGER_LEVEL, }), []);
    const [loader, setLoader] = useState(false);
    const [stateList, setStateList] = useState([]);
    const [uqualError, setUqualError] = useState("");
    const [ilecAddress, setIlecAddress] = useState("");

    const selectedQuoteValues = useSelector(state => state.quote.quoteValues);
    const selectedKineticBroadbandValues = useSelector(state => state.quote.quoteValues.kineticBroadbandObj);
    const selectedKineticBroadbandSearchBy = useSelector(state => state.quote.quoteValues.uqualSearchByFlag);
    const selectedKineticBroadbandUqualId = useSelector(state => state.quote.quoteValues.uqualId);
    const selectedKineticBroadbandLocationValues = useSelector(state => state.quoteLocA.locA);

    const isEmployee = localStorage.getItem("isEmployeeLoggedIn");

    useEffect(() => {
        // Get the list of all the states for AV form
        HomeData.getStateList().then(resp => {
            setStateList(resp.data);
        }).catch((error) => {
            logger.error("getStates ERROR: " + error)
        })
    }, [logger]);

    useEffect(() => {
        if(!selectedKineticBroadbandSearchBy){
            props.updateKineticBroadbandUqualSearchBy("address");
        }
    },[props, selectedKineticBroadbandSearchBy])

    useEffect(() => {
        if(selectedQuoteValues?.cloned){
            logger.info("Cloning a Broadband Quote we need to validate the address by the uqual id");
            props.updateKineticBroadbandUqualId("uqualId");
        }
    },[logger, props, selectedQuoteValues?.cloned])

    useEffect(() => {
        if(props.ilecBroadbandRedirect && !selectedKineticBroadbandUqualId){
            setIlecAddress(selectedKineticBroadbandLocationValues.a_add);
            props.updateKineticBroadbandAddress("");
            props.updateKineticBroadbandCity("");
            props.updateKineticBroadbandState("");
            props.updateKineticBroadbandZip("");
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    // Loads the address options from UQUAL
    const loadUqualOptions = (inputValue, callback) => {
        if(inputValue?.length > 2) {
            BroadbandHelper.getUqualLocations(inputValue, "").then((response) => {
                const options = []
                response?.data.forEach((address) => {
                    options.push({
                        label: address.name,
                        value: address.id,
                        state: address.state,
                        city: address.city,
                        zip: address.zip
                    })
                })
                logger.info("UQUAL Address options: " + JSON.stringify(options));
                callback(options);
            }).catch((error) => {
                logger.error("error: " + error);
            })
        }
    };

    // Populate the address fields based the on the UQUAL address selected and stores the values in the redux store
    const addressChangeHandler = (address) => {
        logger.info("addressChangeHandler: " + JSON.stringify(address));
        props.updateKineticBroadbandAddress(address?.label);
        props.updateKineticBroadbandCity(address?.city);
        props.updateKineticBroadbandState(address?.state);
        props.updateKineticBroadbandZip(address?.zip);
        props.updateKineticBroadbandUqualId(address?.value);
    }

    // Validates the UQUAL address
    const validateUqualAddress = () => {
        setUqualError("");
        logger.info("Validating selected uqual address: " + selectedKineticBroadbandUqualId);
        let addressObj = {
            add: selectedKineticBroadbandLocationValues.a_add,
            state: selectedKineticBroadbandLocationValues.a_state,
            city: selectedKineticBroadbandLocationValues.a_city,
            zip: selectedKineticBroadbandLocationValues.a_zip,
            address_id: selectedKineticBroadbandUqualId,
            validateBy: selectedKineticBroadbandSearchBy
        }
        logger.info("addressObj: " + JSON.stringify(addressObj));
        setLoader(true);
        BroadbandHelper.uqualAddressValidation(addressObj).then((response) => {
            logger.info("response: " + JSON.stringify(response.data));
            if(response.data?.result === "SUCCESS"){
                props.updateKineticBroadbandObj(response.data);
                props.updateKineticBroadbandAddressValidation(true);
                if(selectedKineticBroadbandSearchBy === "uqualId"){
                    props.updateKineticBroadbandAddress(response.data?.addressLine1);
                    props.updateKineticBroadbandCity(response.data?.city);
                    props.updateKineticBroadbandState(response.data?.state);
                    props.updateKineticBroadbandZip(response.data?.zip);
                }
            } else {
                props.updateKineticBroadbandAddressValidation(false);
                setUqualError(response.data?.error);
            }
            setLoader(false);
        }).catch(error => {
            setLoader(false);
            logger.error(`Error validating uqual address: ${error}`);
        })
    }

    const uqualSearchByHandler = (event) => {
        let id = event.target.id;

        props.updateKineticBroadbandAddress("");
        props.updateKineticBroadbandCity("");
        props.updateKineticBroadbandState("");
        props.updateKineticBroadbandZip("");
        props.updateKineticBroadbandAddressValidation(false);
        props.updateKineticBroadbandObj({});

        if(id === "searchById"){
            props.updateKineticBroadbandUqualSearchBy("uqualId");
        } else {
            props.updateKineticBroadbandUqualSearchBy("address");
        }
    }

    return (
        <>
            {loader ? <Loader /> : null}
            {selectedKineticBroadbandValues?.qualMessage ?
                <div class="alert alert-success" role="alert">
                    {selectedKineticBroadbandValues?.qualMessage}
                </div> : null
            }
            {uqualError ?
                <div class="alert alert-danger" role="alert">
                    {uqualError}
                </div> : null
            }
            {isEmployee && !selectedQuoteValues?.cloned  ?
                <div className={`row ${style.rowGrp}`}>
                    <div className="col-6">
                        <input type="radio" name="searchById" id="searchById" checked={selectedKineticBroadbandSearchBy === "uqualId" ? "checked" : ""} onClick={(event) => uqualSearchByHandler(event)} />
                        <label htmlFor="searchById" className={style.checkboxText}>Search by UQUAL ID</label>
                        <input type="radio" name="searchByAddress" id="searchByAddress"  checked={selectedKineticBroadbandSearchBy === "address" ? "checked" : ""} onClick={(event) => uqualSearchByHandler(event)} />
                        <label htmlFor="searchByAddress" className={style.checkboxText}>Search by Address</label>
                    </div>
                </div> : null
            }
            {ilecAddress && props.ilecBroadbandRedirect ?
                <div className={`row ${style.rowGrp}`}>
                    <div className="col-6">
                        <span>{ilecAddress}</span>
                        <img data-tip data-for="addressInfo" className="tipped cornertip-right ml-2 mb-1" src="../../../images/svg/ico-info-filled-dark-off.svg" width="16" height="16" alt="" />
						<ReactTooltip id="addressInfo" delayHide={100} effect="solid">
								{`Please copy and paste the address into the address search field for additional validation.`}
						</ReactTooltip>
                    </div>

                </div> : null
            }
            {isEmployee && selectedKineticBroadbandSearchBy === "uqualId" && !selectedQuoteValues?.cloned  ?
                <div className={`row ${style.rowGrp}`}>
                    <div className="col-6">
                        <label className={style.labelSelectRequiredFld}>UQUAL ID<span className={style.mandatory}>*</span></label>
                        <input type="text" className={style.inputFld} id='dfId' value={selectedKineticBroadbandUqualId} onChange={(event) => props.updateKineticBroadbandUqualId(event.target.value)} />
                    </div>
                    <div className="col-6 col-sm-4 col-md-1">
                        <button type="button" className="btn-style btn-theme" onClick={validateUqualAddress}>Validate</button>
                    </div>
                </div> : null
            }
            {selectedKineticBroadbandSearchBy === "address" && !selectedQuoteValues?.cloned  ?
                <div className={`row ${style.rowGrp}`}>
                    <div className="col-6">
                        <label className={style.labelSelectRequiredFld}>Address Search <span className={style.mandatory}>*</span></label>
                        <AsyncSelect
                            cacheOptions
                            loadOptions={loadUqualOptions}
                            onChange={addressChangeHandler}
                        />
                    </div>
                </div> : null
            }
            {selectedKineticBroadbandLocationValues?.a_add || selectedKineticBroadbandLocationValues.a_validationFlag ?
                <div className={`row ${style.rowGrp}`}>
                    <div className="col-6">
                        <label className={style.labelSelectRequiredFld}>Address<span className={style.mandatory}>*</span></label>
                        <input type="text" className={style.inputFld} id='addressLine1' value={selectedKineticBroadbandLocationValues.a_add} disabled/>
                    </div>
                </div> :  null
            }
            {selectedKineticBroadbandSearchBy === "address" || selectedKineticBroadbandLocationValues.a_validationFlag ?
                <div className={`row ${style.rowGrp}`}>
                    <div className="col-12 col-sm-4 col-md-2">
                        <label className={style.lableTextS}>City <span className={style.mandatory}>*</span></label>
                        <input type="text" className={style.inputFld} id='city' value={selectedKineticBroadbandLocationValues.a_city} disabled/>
                    </div>
                    <div className="col-12 col-sm-4 col-md-2">
                        <label className={style.labelSelectRequiredFld}>State <span className={style.mandatory}>*</span></label>
                        <select className={` form-control  ${style.selectFld} ${style.requiredFld} `} id='state' value={selectedKineticBroadbandLocationValues.a_state} disabled>
                            {stateList.map((obj) => {
                                return <option value={obj.id}>{obj.name}</option>
                            })}
                        </select>
                    </div>
                    <div className="col-12 col-sm-4 col-md-2">
                        <label className={style.lableTextS}>ZIP <span className={style.mandatory}>*</span></label>
                        <input type="text" className={style.inputFld} id='zip' value={selectedKineticBroadbandLocationValues.a_zip} disabled/>
                    </div>
                    {selectedKineticBroadbandSearchBy === "address" ?
                        <div className="col-6 col-sm-4 col-md-1">
                            <button type="button" className="btn-style btn-theme" onClick={validateUqualAddress}>Validate</button>
                        </div> : null
                    }
                </div> : null
            }
        </>
    )
}

const mapDispatchToProps = {
    updateKineticBroadbandObj: setBroadbandObj,
    updateKineticBroadbandAddress: setLocAAddress,
    updateKineticBroadbandState: setLocAState,
    updateKineticBroadbandCity: setLocACity,
    updateKineticBroadbandZip: setLocAZip,
    updateKineticBroadbandUqualSearchBy: setUqualSearchByFlag,
    updateKineticBroadbandUqualId: setUqualId,
    updateKineticBroadbandAddressValidation: setLocAValidationFlag
}

export default connect(null, mapDispatchToProps)(UqualAddress);
