import style from "./QuoteCreate.module.css";
import React from "react";
import {connect, useSelector} from "react-redux";
import {
	setQuoteATTExclusion,
	setQuoteComcastExclusion,
	setQuoteLumenExclusion,
	setQuoteSpectrumExclusion,
	setQuoteVendorExclusion,
	setQuoteVerizonExclusion
} from '../../redux/actions/quoteActions';


const VendorExclusion = (props) => {
    const selectedVendorValues = useSelector(state => state.quote.quoteValues);

    const vendorExclusionHandler = (ev) => {
        let value = "";
        if (ev.target.checked === true) {
            value = 'on'
        } else {
            value = 'off'
        }
        switch (ev.target.id) {
            case "vendorexcl":
                props.updateQuoteVendorExcl(value);
                if(value === "off"){
                    props.updateQuoteATTExcl("");
                    props.updateQuoteVerizonExcl("");
                    props.updateQuoteLumenExcl("");
                    props.updateQuoteComcastExcl("");
                    props.updateQuoteSpectrumExcl("");
                }
                break;
            case "vendorexcl_att":
                if (ev.target.checked === true) {
                    props.updateQuoteATTExcl("ATT");
                } else {
                    props.updateQuoteATTExcl("");
                }
                break;
            case "vendorexcl_verizon":
                if(ev.target.checked === true){
                    props.updateQuoteVerizonExcl("VERIZON");
                } else {
                    props.updateQuoteVerizonExcl("");
                }
                break;
            case "vendorexcl_lumen":
                if(ev.target.checked === true){
					props.updateQuoteLumenExcl("LUMEN");
                }else{
                    props.updateQuoteLumenExcl("");
                }
                break;
            case "vendorexcl_comcast":
                if(ev.target.checked === true){
                    props.updateQuoteComcastExcl("COMCAST");
                }else{
                    props.updateQuoteComcastExcl("");
                }
                break;
            case "vendorexcl_spectrum":
                if(ev.target.checked === true){
                    props.updateQuoteSpectrumExcl("SPECTRUM");
                }else{
                    props.updateQuoteSpectrumExcl("");
                }
				break;
			default:
				break;
        }
    }
return(
    <div className={`row ${style.rowGrp} `}>
                    <div className="col-12 mb-2">
                        <input type="checkbox" id="vendorexcl" name="vendorexcl" onChange={vendorExclusionHandler} checked={selectedVendorValues.vendor_exclusion === "on" ? "checked" : ""} />
                        <label htmlFor="vendorExc" className={` ${style.checkboxText} ${style.textTransformNone} ml-2 `}>Vendor Exclusion</label>
                        <div className={` ${style.infoTip} ${style.infoTipVendor} `}>
                            <img src="../../images/svg/ico-info-filled-dark-off.svg" alt="info tip" className={style.infoImg} />
                            <p className={style.contentHover}>
                                Choosing this will allow you to exclude these vendors from the quoting process.
                            </p>
                        </div>
                    </div>

                    {selectedVendorValues.vendor_exclusion === "on" ?
                        <div className="col-12 mt-3">
                            <input type="checkbox" id="vendorexcl_att" onChange={vendorExclusionHandler} checked={selectedVendorValues.vendorexcl_att === "ATT" ? "checked" : ""}/>
                            <label htmlFor="AT_T" className={` ${style.checkboxText} `}>AT&amp;T</label>

                            <input type="checkbox" id="vendorexcl_verizon" onChange={vendorExclusionHandler} checked={selectedVendorValues.vendorexcl_verizon === "VERIZON" ? "checked" : ""}/>
                            <label htmlFor="verizon" className={` ${style.checkboxText} `}>Verizon</label>

							<input type="checkbox" id="vendorexcl_lumen" onChange={vendorExclusionHandler} checked={selectedVendorValues.vendorexcl_lumen === "LUMEN" ? "checked" : ""}/>
							<label htmlFor="Lumen" className={` ${style.checkboxText} `}>Lumen</label>

                            <input type="checkbox" id="vendorexcl_comcast" onChange={vendorExclusionHandler} checked={selectedVendorValues.vendorexcl_comcast === "COMCAST" ? "checked" : ""}/>
                            <label htmlFor="Comcast" className={` ${style.checkboxText} `}>Comcast</label>

                            <input type="checkbox" id="vendorexcl_spectrum" onChange={vendorExclusionHandler} checked={selectedVendorValues.vendorexcl_spectrum === "SPECTRUM" ? "checked" : ""}/>
                            <label htmlFor="Spectrum" className={` ${style.checkboxText} `}>Spectrum</label>
                        </div>
                        : null}
                </div>
)

}

const mapDispatchToProps = {
    updateQuoteVendorExcl: setQuoteVendorExclusion,
    updateQuoteATTExcl: setQuoteATTExclusion,
    updateQuoteVerizonExcl: setQuoteVerizonExclusion,
    updateQuoteLumenExcl: setQuoteLumenExclusion,
    updateQuoteComcastExcl: setQuoteComcastExclusion,
    updateQuoteSpectrumExcl: setQuoteSpectrumExclusion,
}

export default connect(null, mapDispatchToProps)(VendorExclusion);
