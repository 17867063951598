import { Text, View, } from '@react-pdf/renderer';
import { styles } from './pdfStyles';

const MplsDetails = (props) => {
    return (
        <>
            <View wrap={false}>
                <View style={styles.headerBarTopMargin}>
                    <Text style={styles.headerBarText}>MPLS & Routing Details</Text>
                </View>
                {props?.route?.length > 0 ?
                    <View style={styles.tableRow}>
                        <Text style={[styles.headerColumn, styles.tableCell2Width, styles.textBold]}>MPLS Routing:</Text>
                        <Text style={[styles.tableColumn, styles.tableCell2Width, styles.textNormal]}>{props?.route}</Text>
                    </View>
                    : null}
                {props?.own_ip?.length > 0 ?
                    <View style={styles.tableRow}>
                        <Text style={[styles.headerColumn, styles.tableCell2Width, styles.textBold]}>Assign Own WAN IP:</Text>
                        <Text style={[styles.tableColumn, styles.tableCell2Width, styles.textNormal]}>{props?.own_ip}</Text>
                    </View>
                    : null}
                {props?.route === 'BGP' ?
                    <>
                        {props?.bgp_prefixes?.length > 0 ?
                            <View style={styles.tableRow}>
                                <Text style={[styles.headerColumn, styles.tableCell2Width, styles.textBold]}>BGP Prefixes Advertised:</Text>
                                <Text style={[styles.tableColumn, styles.tableCell2Width, styles.textNormal]}>{props?.bgp_prefixes}</Text>
                            </View>
                            : null
                        }
                        {props?.override?.length > 0 ?
                            <View style={styles.tableRow}>
                                <Text style={[styles.headerColumn, styles.tableCell2Width, styles.textBold]}>Remove AS Override:</Text>
                                <Text style={[styles.tableColumn, styles.tableCell2Width, styles.textNormal]}>{props?.override}</Text>
                            </View>
                            : null}
                        {props?.bgp_comm?.length > 0 ?
                            <View style={styles.tableRow}>
                                <Text style={[styles.headerColumn, styles.tableCell2Width, styles.textBold]}>Add BGP Community Strings:</Text>
                                <Text style={[styles.tableColumn, styles.tableCell2Width, styles.textNormal]}>{props?.bgp_comm}</Text>
                            </View>
                            : null}
                        {props?.remote_asn?.length > 0 ?
                            <View style={styles.tableRow}>
                                <Text style={[styles.headerColumn, styles.tableCell2Width, styles.textBold]}>Remote ASN to Configure:</Text>
                                <Text style={[styles.tableColumn, styles.tableCell2Width, styles.textNormal]}>{props?.remote_asn}</Text>
                            </View>
                            : null}
                    </>
                    : null}
                {props?.own_ip === 'Yes' ?
                    <>
                        {props?.subnets?.length > 0 ?
                            <View style={styles.tableRow}>
                                <Text style={[styles.headerColumn, styles.tableCell2Width, styles.textBold]}>Customer WAN IP Subnet:</Text>
                                <Text style={[styles.tableColumn, styles.tableCell2Width, styles.textNormal]}>{props?.subnets}</Text>
                            </View>
                            : null}
                        {props?.win_edge?.length > 0 ?
                            <View style={styles.tableRow}>
                                <Text style={[styles.headerColumn, styles.tableCell2Width, styles.textBold]}>Configure in WIN Edge Router:</Text>
                                <Text style={[styles.tableColumn, styles.tableCell2Width, styles.textNormal]}>{props?.win_edge}</Text>
                            </View>
                            : null}
                    </>
                    : null}
                {props?.qos?.length > 0 && props?.qos !== 0 ?
                    <View style={styles.tableRow}>
                        <Text style={[styles.headerColumn, styles.tableCell2Width, styles.textBold]}>QOS Policy:</Text>
                        <Text style={[styles.tableColumn, styles.tableCell2Width, styles.textNormal]}>{props?.qos}</Text>
                    </View>
                    : null}
                {props?.cpe_type?.length > 0 ?
                    <View style={styles.tableRow}>
                        <Text style={[styles.headerColumn, styles.tableCell2Width, styles.textBold]}>CPE Provider:</Text>
                        <Text style={[styles.tableColumn, styles.tableCell2Width, styles.textNormal]}>{props?.cpe_type}</Text>
                    </View>
                    : null}
                {props?.router_service?.length > 0 ?
                    <View style={styles.tableRow}>
                        <Text style={[styles.headerColumn, styles.tableCell2Width, styles.textBold]}>Internet Router Service:</Text>
                        <Text style={[styles.tableColumn, styles.tableCell2Width, styles.textNormal]}>{props?.router_service}</Text>
                    </View>
                    : null}
                {props?.mounting_equip?.length > 0 ?
                    <View style={styles.tableRow}>
                        <Text style={[styles.headerColumn, styles.tableCell2Width, styles.textBold]}>Mounting Equipment:</Text>
                        <Text style={[styles.tableColumn, styles.tableCell2Width, styles.textNormal]}>{props?.mounting_equip}</Text>
                    </View>
                    : null}
                {props?.wan_sfp?.length > 0 ?
                    <View style={styles.tableRow}>
                        <Text style={[styles.headerColumn, styles.tableCell2Width, styles.textBold]}>Optional WAN SFP:</Text>
                        <Text style={[styles.tableColumn, styles.tableCell2Width, styles.textNormal]}>{props?.wan_sfp}</Text>
                    </View>
                    : null}
            </View>
        </>
    )
}

export default MplsDetails;