import style from "./OrderSearch.module.css";
import React, { useEffect, useState, useMemo } from 'react';
import { Tooltip } from "react-tippy";
import { ConsoleLogger } from "../../logger/ConsoleLogger";
import OrderHelper from "../../services/OrderService";
import DisplayCircuitList from "../shared/ExistingCircuitModal";
import Loader from '../shared/Loader';

const QuickbaseTimelineComponent = (props) => {
	const logger = useMemo(() => new ConsoleLogger({ level: process.env.REACT_APP_LOGGER_LEVEL, }), []);
	const [pullTasksComplete, setPullTasksComplete] = useState(false);
	const [pullTimelineComplete, setPullTimelineComplete] = useState(false);
	const [displayOrderTimeline, setDisplayOrderTimeline] = useState(false);
	const [supplemental, setSupplemental] = useState([]);
	const [quickbaseTask, setQuickbaseTask] = useState("");
	const [stepCode, setStepCode] = useState(0);
	const [offnetFOCDate, setOffnetFOCDate] = useState("N/A");
	const [offnetWorkComplete, setOffnetWorkComplete] = useState("N/A");
	const [offnetDate, setOffnetDate] = useState("N/A");
	const [serviceLocationComplete, setServiceLocationComplete] = useState("N/A");
	const [welcomeSent, setWelcomeSent] = useState("N/A");
	const [clrIssued, setClrIssued] = useState("N/A");
	const [focIssued, setFocIssued] = useState("N/A");
	const [ptdComplete, setPtdComplete] = useState("N/A");
	const [constructionComplete, setConstructionComplete] = useState("N/A");
	const [orderComplete, setOrderComplete] = useState("N/A");
	const [startBillingDate, setStartBillingDate] = useState("N/A");
	const [employee, setEmployee] = useState("");
	const [orderAcceptedDate, setOrderAcceptedDate] = useState("N/A");
	const [oSPDesignDate, setOSPDesignDate] = useState("N/A");
	const [loopAssignmentDate, setLoopAssignmentDate] = useState("N/A");
	const [transportPlanningDate, setTransportPlanningDate] = useState("N/A");
	const [selectedTaskValues, setSelectedTaskValues] = useState([]);
	const [circuitListDisplay, setCircuitListDisplay] = useState(false);
	const [isLoader, setIsLoader] = useState(false);

	const orderView = props.selectedOrderValues;
	const parentBAN = props.selectedOrderValues?.parent_ban;
	const childBAN = props.selectedQuoteValues?.la_sf_gaid;

	const updateCircuitListDisplay = (action) => {
		if (action === "open") {
			setCircuitListDisplay(true);
		} else {
			setCircuitListDisplay(false);
		}
	}

	/**
	 * This code snippet defines a function called getOrderTimeline that generates an order timeline component based on the provided activity and cptActivity values. The timeline is rendered as a series of steps with tooltips displaying relevant information for each step. The number and content of the steps depend on the values of activity and cptActivity. The generated component is returned by the function.
	 *
	 * @param {string} activity - The activity type ("N", "C", or "D").
	 * @param {string} cptActivity - The cptActivity type ("R" or any other value).
	 * @returns {JSX.Element} - The generated order timeline component.
	 */
	const getOrderTimeline = (activity, cptActivity) => {
		logger.info("Getting order timeline to display");
		logger.info("Step Code: " + stepCode);
		if (props.quickbaseId > 0) {
			switch (activity) {
				case "N":
					return (
						<div className={"o_milestones six-steps"}>
							<Tooltip html={<span
								style={{ whiteSpace: 'pre-line' }}>{"Order Creation Date: " + orderView.opened + "\nOrder Accepted Date: " + orderAcceptedDate}</span>}>
								<div className={` step ${stepCode >= 1 ? 'active' : ''}`}>
									<div>1</div>
									<span>Order</span>
								</div>
							</Tooltip>
							<div className={` gap ${stepCode >= 2 ? 'active' : ''}`}>&nbsp;</div>
							<Tooltip html={<span
								style={{ whiteSpace: 'pre-line' }}>{"Construction Planning: " + oSPDesignDate + "\nConstruction In Progress: " + loopAssignmentDate + "\nService Location Planning: " + transportPlanningDate}</span>}>
								<div className={` step ${stepCode >= 2 ? 'active' : ''}`}>
									<div>2</div>
									<span>Planning</span>
								</div>
							</Tooltip>
							<div className={` gap ${stepCode >= 3 ? 'active' : ''}`}>&nbsp;</div>
							<Tooltip html={<span
								style={{ whiteSpace: 'pre-line' }}>{"Offnet Order Sent: " + offnetDate + "\nOffnet Firm Order Commitment: " + offnetFOCDate + "\nOffnet Work: " + offnetWorkComplete}</span>}>
								<div className={` step ${stepCode >= 3 ? 'active' : ''}`}>
									<div>3</div>
									<span>Offnet Order</span>
								</div>
							</Tooltip>
							<div className={` gap ${stepCode >= 4 ? 'active' : ''}`}>&nbsp;</div>
							<Tooltip html={"Circuit Layout Record: " + clrIssued}>
								<div className={` step ${stepCode >= 4 ? 'active' : ''}`}>
									<div>4</div>
									<span>Design</span>
								</div>
							</Tooltip>
							<div className={` gap ${stepCode >= 5 ? 'active' : ''}`}>&nbsp;</div>
							<Tooltip html={<span
								style={{ whiteSpace: 'pre-line' }}>{"Service Location Work Complete: " + serviceLocationComplete + "\nConstruction: " + constructionComplete}</span>}>
								<div className={` step ${stepCode >= 5 ? 'active' : ''}`}>
									<div>5</div>
									<span>Construction</span>
								</div>
							</Tooltip>
							<div className={` gap ${stepCode >= 6 ? 'active' : ''}`}>&nbsp;</div>
							<Tooltip html={"Firm Order Commitment: " + focIssued}>
								<div className={` step ${stepCode >= 6 ? 'active' : ''}`}>
									<div>6</div>
									<span>Firm Order Committment</span>
								</div>
							</Tooltip>
							<div className={` gap ${stepCode >= 7 ? 'active' : ''}`}>&nbsp;</div>
							<Tooltip html={<span
								style={{ whiteSpace: 'pre-line' }}>{"Circuit Test Date: " + ptdComplete + "\nOrder Complete: " + orderComplete + "\nBilling Start Date: " + startBillingDate}</span>}>
								<div className={` step ${stepCode >= 7 ? 'active' : ''}`}>
									<div>7</div>
									<span>Testing / Completed</span>
								</div>
							</Tooltip>
						</div>
					);
				case "C":
					if (cptActivity === "R") {
						return (
							<div className={"o_milestones"}>
								<Tooltip html={"Order Creation Date: " + orderView?.opened}>
									<div className={` step ${stepCode >= 1 ? 'active' : ''}`}>
										<div>1</div>
										<span>Order Created</span>
									</div>
								</Tooltip>
								<div className={` gap ${stepCode >= 2 ? 'active' : ''}`}>&nbsp;</div>
								<Tooltip html={"Order Accepted Date: " + orderAcceptedDate}>
									<div className={` step ${stepCode >= 2 ? 'active' : ''}`}>
										<div>2</div>
										<span>Order Processing</span>
									</div>
								</Tooltip>
								<div className={` gap ${stepCode >= 3 ? 'active' : ''}`}>&nbsp;</div>
								<Tooltip html={"Order Complete: " + orderComplete}>
									<div className={` step ${stepCode >= 3 ? 'active' : ''}`}>
										<div>3</div>
										<span>Order Completed</span>
									</div>
								</Tooltip>
							</div>
						);
					} else {
						return (
							<div className={"o_milestones five-steps"}>
								<Tooltip html={<span
									style={{ whiteSpace: 'pre-line' }}>{"Order Creation Date: " + orderView.opened + "\nOrder Accepted Date: " + orderAcceptedDate}</span>}>
									<div className={` step ${stepCode >= 1 ? 'active' : ''}`}>
										<div>1</div>
										<span>Order</span>
									</div>
								</Tooltip>
								<div className={` gap ${stepCode >= 2 ? 'active' : ''}`}>&nbsp;</div>
								<Tooltip html={<span
									style={{ whiteSpace: 'pre-line' }}>{"Service Location Work Complete: " + ptdComplete + "\nCircuit Layout Record: " + clrIssued}</span>}>
									<div className={` step ${stepCode >= 2 ? 'active' : ''}`}>
										<div>2</div>
										<span>Design</span>
									</div>
								</Tooltip>
								<div className={` gap ${stepCode >= 3 ? 'active' : ''}`}>&nbsp;</div>
								<Tooltip html={"Firm Order Commitment: " + focIssued}>
									<div className={` step ${stepCode >= 3 ? 'active' : ''}`}>
										<div>3</div>
										<span>Firm Order Committment</span>
									</div>
								</Tooltip>
								<div className={` gap ${stepCode >= 4 ? 'active' : ''}`}>&nbsp;</div>
								<Tooltip html={<span
									style={{ whiteSpace: 'pre-line' }}>{"Circuit Test Date: " + ptdComplete + "\nOrder Complete: " + orderComplete}</span>}>
									<div className={` step ${stepCode >= 4 ? 'active' : ''}`}>
										<div>4</div>
										<span>Testing / Completed</span>
									</div>
								</Tooltip>
							</div>
						);
					}
				case "D":
					return (
						<div className={"o_milestones"}>
							<Tooltip html={"Order Creation Date: " + orderView?.opened}>
								<div className={` step ${stepCode >= 1 ? 'active' : ''}`}>
									<div>1</div>
									<span>Order Created</span>
								</div>
							</Tooltip>
							<div className={` gap ${stepCode >= 2 ? 'active' : ''}`}>&nbsp;</div>
							<Tooltip html={"Order Accepted Date: " + orderAcceptedDate}>
								<div className={` step ${stepCode >= 2 ? 'active' : ''}`}>
									<div>2</div>
									<span>Order Processing</span>
								</div>
							</Tooltip>
							<div className={` gap ${stepCode >= 3 ? 'active' : ''}`}>&nbsp;</div>
							<Tooltip html={"Order Complete: " + orderComplete}>
								<div className={` step ${stepCode >= 3 ? 'active' : ''}`}>
									<div>3</div>
									<span>Order Completed</span>
								</div>
							</Tooltip>
						</div>
					);
				default:
					break;
			}
		}
		setIsLoader(false);
	}

	/**
	 * Configures the tasks based on the selected task values.
	 */
	const configureTasks = () => {
		logger.info("In cofigure tasks");
		logger.info("Selected Task Values: " + JSON.stringify(selectedTaskValues));
		if (Array.isArray(selectedTaskValues)) {
			selectedTaskValues?.map((task) => {
				if (task?.task_type === "APP") {
					// If task type is "APP", set the order accepted date
					if (task.actual_complete === '0000-00-00') {
						setOrderAcceptedDate("PENDING");
					} else {
						setOrderAcceptedDate(task.actual_complete);
					}
				}

				if (task?.task_type === "ILAM2") {
					// If task type is "ILAM2", set the OSP design date
					if (task.actual_complete === '0000-00-00') {
						setOSPDesignDate("PENDING");
					} else {
						setOSPDesignDate(task.actual_complete);
					}
				}

				if (task?.task_type === "LAM") {
					// If task type is "LAM", set the loop assignment date
					if (task.actual_complete === '0000-00-00') {
						setLoopAssignmentDate("PENDING");
					} else {
						setLoopAssignmentDate(task.actual_complete);
					}
				}

				if (task?.task_type === "TFP") {
					// If task type is "TFP", set the transport planning date
					if (task.actual_complete === '0000-00-00') {
						setTransportPlanningDate("PENDING");
					} else {
						setTransportPlanningDate(task.actual_complete);
					}
				}

				if (task?.task_type === "ASR SEND") {
					// If task type is "ASR SEND", set the offnet ASR sent date
					if (task.actual_complete === '0000-00-00') {
						setOffnetDate("PENDING");
					} else {
						setOffnetDate(task.actual_complete);
					}
				}

				if (task?.task_type === "RCV CONF") {
					// If task type is "RCV CONF", set the offnet FOC received date
					if (task.actual_complete === '0000-00-00') {
						setOffnetFOCDate("PENDING");
					} else {
						setOffnetFOCDate(task.actual_complete);
					}
				}

				if (task?.task_type === "VEND DD") {
					// If task type is "VEND DD", set the offnet carrier due date
					if (task.actual_complete === '0000-00-00') {
						setOffnetWorkComplete("PENDING");
					} else {
						setOffnetWorkComplete(task.actual_complete);
					}
				}

				if (task?.task_type === "DLRD") {
					// If task type is "DLRD", set the CLR issued date
					if (task.actual_complete === '0000-00-00') {
						setClrIssued("PENDING");
					} else {
						setClrIssued(task.actual_complete);
					}
				}

				if (task?.task_type === "LAM_FP") {
					// If task type is "LAM_FP", set the OSPE completed date
					if (task.actual_complete === '0000-00-00') {
						setConstructionComplete("PENDING");
					} else {
						setConstructionComplete(task.actual_complete);
					}
				}

				if (task?.task_type === "PTFP") {
					// If task type is "PTFP", set the service location complete date
					if (task.actual_complete === '0000-00-00') {
						setServiceLocationComplete("PENDING");
					} else {
						setServiceLocationComplete(task.actual_complete);
					}
				}

				if (task?.task_type === "CONF") {
					// If task type is "CONF", set the FOC issued date
					if (task.actual_complete === '0000-00-00') {
						setFocIssued("PENDING");
					} else {
						setFocIssued(task.actual_complete);
					}
				}

				if (task?.task_type === "PTD") {
					// If task type is "PTD", set the PTD complete date
					if (task.actual_complete === '0000-00-00') {
						setPtdComplete("PENDING");
					} else {
						setPtdComplete(task.actual_complete);
					}
				}

				if (task?.task_type === "DD") {
					// If task type is "DD", set the order complete date
					if (task.actual_complete === '0000-00-00') {
						setOrderComplete("PENDING");
					} else {
						setOrderComplete(task.actual_complete);
					}
				}
			});
		}
	}

	const getAssignee = (first, last, email) => {
		let _first = "";
		let _last = "";
		let _email = "";

		if (!first || _first.includes("null")) {
			_first = "";
		} else {
			_first = first;
		}

		if (!last || _last.includes("null")) {
			_last = "";
		} else {
			_last = last;
		}

		if (!email || _email.includes("null")) {
			_email = "";
		} else {
			_email = email;
		}

		if (_email !== "") {
			return `${_first} ${_last}\n${_email}`
		} else {
			return `${_first} ${_last}`
		}
	}

	/**
	 * Sets supplemental values for assignees and logs information.
	 */
	const setSupplementalValues = () => {
		// Create assignee strings
		const pm_assignee = getAssignee(supplemental.pm_first, supplemental.pm_last, supplemental.pm_email);
		const pc_assignee = getAssignee(supplemental.pc_first, supplemental.pc_last, supplemental.pc_email);

		// Set employee and start billing date
		logger.info("PM: " + pm_assignee);
		if (pm_assignee.trim() === "") {
			if (pc_assignee.trim() === "") {
				setEmployee("No Assignee");
			} else {
				setEmployee(pc_assignee);
			}
		} else {
			setEmployee(pm_assignee);
		}
		setStartBillingDate(supplemental.bill_start);

		// Set welcome sent if welcome letter is marked as "Y"
		if (supplemental?.welcomeletter === "Y") {
			setWelcomeSent(supplemental.welcomeletter_timestamp);
		}
	}

	/**
	 * Sets the step code based on certain conditions.
	 */
	const defineSteps = () => {
		// Log whether the quickbaseId has a qb_uid value
		logger.info("props.quickbaseId: " + props.quickbaseId);

		// Check if the quickbaseId's qb_uid is greater than 0
		if (props.quickbaseId > 0) {
			logger.info("quickbaseTask Type: " + quickbaseTask);

			// Determine the step code based on the orderView activity and qbTask
			switch (orderView?.activity) {
				case "N":
					switch (quickbaseTask) {
						case "APP":
							setStepCode(1);
							break;
						case "ILAM2":
						case "LAM":
						case "TFP":
							setStepCode(2);
							break;
						case "ASR SEND":
						case "RCV CONF":
						case "VEND DD":
							setStepCode(3);
							break;
						case "DLRD":
							setStepCode(4);
							break;
						case "PTFP":
						case "LAM_FP":
							setStepCode(5);
							break;
						case "CONF":
						case "PTD":
							setStepCode(6);
							break;
						case "DD":
							setStepCode(7);
							break;
						default:
							setStepCode(1);
							break;
					}
					break;
				case "C":
					if (orderView?.cpt_activity !== "R") {
						switch (quickbaseTask) {
							case "APP":
								setStepCode(1);
								break;
							case "PTFP":
							case "DLRD":
								setStepCode(2);
								break;
							case "CONF":
								setStepCode(3);
								break;
							case "PTD":
							case "DD":
								setStepCode(4);
								break;
							default:
								setStepCode(1);
								break;
						}
					} else {
						switch (quickbaseTask) {
							case "APP":
								setStepCode(2);
								break;
							case "DD":
								setStepCode(3);
								break;
							default:
								setStepCode(1);
								break;
						}
					}
					break;
				case "D":
					switch (quickbaseTask) {
						case "APP":
							setStepCode(2);
							break;
						case "DD":
							setStepCode(3);
							break;
						default:
							setStepCode(1);
							break;
					}
					break;
				default:
					setStepCode(1);
					break;
			}
		}
	}

	useEffect(() => {
		setIsLoader(true);
		if (props.refId && (props.refId !== undefined)) {
			OrderHelper.pullOrderTasks(props.refId).then(resp => {
				setPullTasksComplete(true);
				setIsLoader(false);
			}).catch(error => {
				logger.error("Error pulling order tasks " + error);
				setIsLoader(false);
			})
		} else {
			// Log error if refId is not defined
			logger.error(`Error: No valid Ref ID`);
			setIsLoader(false);
		}
	}, [logger, props.refId]);

	useEffect(() => {
		if (pullTasksComplete) {
			setIsLoader(true);
			OrderHelper.getTimelineData(props.refId).then(resp => {
				logger.info("Timeline Data: " + JSON.stringify(resp.data));
				setQuickbaseTask(resp.data.taskInfo);
				setSelectedTaskValues(resp.data.tasks);
				setSupplemental(resp.data.supplemental);
				//setQuickbaseId(resp.data.qbId.qb_uid);
				setPullTimelineComplete(true);
				setIsLoader(false);
			}).catch(error => {
				logger.error("Error getting timeline data " + error);
				setIsLoader(false);
			})
		}
	}, [logger, pullTasksComplete, props.refId]);


	useEffect(() => {
		if (pullTimelineComplete) {
			defineSteps();
			configureTasks();
			setSupplementalValues();
			setDisplayOrderTimeline(true)
		}
	}, [pullTimelineComplete]);


	return (
		<>
			{isLoader ? <Loader /> : ''}
			<div>
				<div className={`sub-hdr alt1 ${style.subHdrAlter} `}>
					<div className="nib">
						<div className="the-notch">
							<div className="notch-fg">
								<svg>
									<path
										d="M35.81,26.28l-13,10v-5C-10.47,28.68-1,9.16,11.79,0c0,7.28,0,9.13,0,13.28s3.18,7.92,11,9v-5Z"></path>
								</svg>
							</div>
						</div>
					</div>
					<h2 className={` ${style.sectionTitle} m-0 `}>Order TimeLine</h2>
				</div>

				<div className="sub-section">
					{displayOrderTimeline ? getOrderTimeline(orderView?.activity, orderView?.cpt_activity) : null}
					{orderView?.activity === "N" ? <div>
						<div className={style.stepsOuter}>
							<div className={style.orderViewHeader}>New Order</div>
							<div className={`row ${style.rowGrp}`}>
								<div className={`col-sm-6 col-md-4  ${style.colGrp}`}>
									<ul className={` ${style.productList}`}>
										<li>Order Creation Date</li>
										<li>{orderView?.opened}</li>
									</ul>
								</div>
								<div className={`col-sm-6 col-md-4  ${style.colGrp}`}>
									<ul className={` ${style.productList}`}>
										<li>Order Accepted Date</li>
										<li>{orderAcceptedDate}</li>
									</ul>
								</div>
								<div className={`col-sm-6 col-md-4  ${style.colGrp}`}>
									<ul className={` ${style.productList}`}>
										<li>Construction Planning</li>
										<li>{oSPDesignDate}</li>
									</ul>
								</div>
								<div className={`col-sm-6 col-md-4  ${style.colGrp}`}>
									<ul className={` ${style.productList}`}>
										<li>Construction in Progress</li>
										<li>{loopAssignmentDate}</li>
									</ul>
								</div>
								<div className={`col-sm-6 col-md-4  ${style.colGrp}`}>
									<ul className={` ${style.productList}`}>
										<li>Service Location Planning</li>
										<li>{transportPlanningDate}</li>
									</ul>
								</div>
								<div className={`col-sm-6 col-md-4  ${style.colGrp}`}>
									<ul className={` ${style.productList}`}>
										<li>Offnet Order Sent</li>
										<li>{offnetDate}</li>
									</ul>
								</div>
								<div className={`col-sm-6 col-md-4  ${style.colGrp}`}>
									<ul className={` ${style.productList}`}>
										<li>Offnet Firm Order Committment</li>
										<li>{offnetFOCDate}</li>
									</ul>
								</div>
								<div className={`col-sm-6 col-md-4  ${style.colGrp}`}>
									<ul className={` ${style.productList}`}>
										<li>Offnet Work</li>
										<li>{offnetWorkComplete}</li>
									</ul>
								</div>
								<div className={`col-sm-6 col-md-4  ${style.colGrp}`}>
									<ul className={` ${style.productList}`}>
										<li>Circuit Layout Record</li>
										<li>{clrIssued}</li>
									</ul>
								</div>
								<div className={`col-sm-6 col-md-4  ${style.colGrp}`}>
									<ul className={` ${style.productList}`}>
										<li>Service LocationWork Completed</li>
										<li>{serviceLocationComplete}</li>
									</ul>
								</div>
								<div className={`col-sm-6 col-md-4  ${style.colGrp}`}>
									<ul className={` ${style.productList}`}>
										<li>Construction</li>
										<li>{constructionComplete}</li>
									</ul>
								</div>
								<div className={`col-sm-6 col-md-4  ${style.colGrp}`}>
									<ul className={` ${style.productList}`}>
										<li>Firm Order Committment</li>
										<li>{focIssued}</li>
									</ul>
								</div>
								<div className={`col-sm-6 col-md-4  ${style.colGrp}`}>
									<ul className={` ${style.productList}`}>
										<li>Circuit Test Date</li>
										<li>{ptdComplete}</li>
									</ul>
								</div>
								<div className={`col-sm-6 col-md-4  ${style.colGrp}`}>
									<ul className={` ${style.productList}`}>
										<li>Order Complete</li>
										<li>{orderComplete}</li>
									</ul>
								</div>
								<div className={`col-sm-6 col-md-4  ${style.colGrp}`}>
									<ul className={` ${style.productList}`}>
										<li>Billing Start Date</li>
										<li>{startBillingDate}</li>
									</ul>
								</div>
							</div>
						</div>
						<div className={style.stepsOuter}>
							<div className={`sub-hdr alt1 ${style.subHdrAlter} `}>
								<div className="nib-supplement">
									<div className="the-notch">
										<div className="notch-fg">
											<svg>
												<path
													d="M35.81,26.28l-13,10v-5C-10.47,28.68-1,9.16,11.79,0c0,7.28,0,9.13,0,13.28s3.18,7.92,11,9v-5Z"></path>
											</svg>
										</div>
									</div>
								</div>
								<h2 className={`${style.sectionTitleSupplement}`}>Supplemental
									Order
									Data</h2>
							</div>
							<div className={`row ${style.rowGrp}`}>
								<div className={`col-sm-6 col-md-4  ${style.colGrp}`}>
									<ul className={` ${style.productList}`}>
										<li>Parent BAN</li>
										<li>{parentBAN}</li>
									</ul>
								</div>
								<div className={`col-sm-6 col-md-4  ${style.colGrp}`}>
									<ul className={` ${style.productList}`}>
										<li>Child BAN</li>
										<li>{childBAN}</li>
									</ul>
								</div>
								<div className={`col-sm-6 col-md-4  ${style.colGrp}`}>
									<ul className={` ${style.productList}`}>
										<li>Order Assignee</li>
										<li>{employee}</li>
									</ul>
								</div>
								<div className={`col-sm-6 col-md-4  ${style.colGrp}`}>
									<ul className={` ${style.productList}`}>
										<li>Customer Welcome Letter Sent Timestamp</li>
										<li>{welcomeSent}</li>
									</ul>
								</div>
								<div className={`col-sm-6 col-md-4  ${style.colGrp}`}>
									<ul className={` ${style.productList}`}>
										<li>Customer Desired Due Date</li>
										<li>{orderView?.serv_date}</li>
									</ul>
								</div>
								{Array.isArray(orderView?.exist_circuit) && (orderView?.exist_circuit).length > 1 ?
									<>
										{(orderView?.exist_circuit).map((circuitId, idx) => {
											if (idx < 7) {
												return (
													<div className={`col-sm-6 col-md-4  ${style.colGrp}`}>
														<ul className={` ${style.productList}`}>
															<li>{`Circuit ID ${idx + 1}`} </li>
															<li>{circuitId}</li>
														</ul>
													</div>
												)
											}
										})}
										{(orderView?.exist_circuit).length > 6 ?
											<div className={`col-sm-6 col-md-4  ${style.colGrp}`}>
												<button className={style.circuitBtn} onClick={() => updateCircuitListDisplay('open')} >Display All Circuit</button>
												{circuitListDisplay ?
													<DisplayCircuitList exist_circuit={orderView?.exist_circuit} updateCircuitListDisplay={() => updateCircuitListDisplay('close')} />
													: null}
											</div>
											: null}
									</>
									:
									<>
										<div className={`col-sm-6 col-md-4  ${style.colGrp}`}>
											<ul className={` ${style.productList}`}>
												<li>Circuit ID</li>
												<li>{orderView.exist_circuit}</li>
											</ul>
										</div>
									</>
								}
							</div>
						</div>
					</div> : null}

					{orderView?.activity === "C" && orderView?.cpt_activity !== "R" ? <div>
						<div className={style.stepsOuter}>
							<div className={style.orderViewHeader}>Change Order</div>
							<div className={`row ${style.rowGrp}`}>
								<div className={`col-sm-6 col-md-4  ${style.colGrp}`}>
									<ul className={` ${style.productList}`}>
										<li>Order Creation Date</li>
										<li>{orderView?.opened}</li>
									</ul>
								</div>
								<div className={`col-sm-6 col-md-4  ${style.colGrp}`}>
									<ul className={` ${style.productList}`}>
										<li>Order Accepted Date</li>
										<li>{orderAcceptedDate}</li>
									</ul>
								</div>
								<div className={`col-sm-6 col-md-4  ${style.colGrp}`}>
									<ul className={` ${style.productList}`}>
										<li>Service Location Work Completed</li>
										<li>{serviceLocationComplete}</li>
									</ul>
								</div>
								<div className={`col-sm-6 col-md-4  ${style.colGrp}`}>
									<ul className={` ${style.productList}`}>
										<li>Circuit Layout Record</li>
										<li>{clrIssued}</li>
									</ul>
								</div>
								<div className={`col-sm-6 col-md-4  ${style.colGrp}`}>
									<ul className={` ${style.productList}`}>
										<li>Firm Order Confirmation</li>
										<li>{focIssued}</li>
									</ul>
								</div>
								<div className={`col-sm-6 col-md-4  ${style.colGrp}`}>
									<ul className={` ${style.productList}`}>
										<li>Circuit Test Date</li>
										<li>{ptdComplete}</li>
									</ul>
								</div>
								<div className={`col-sm-6 col-md-4  ${style.colGrp}`}>
									<ul className={` ${style.productList}`}>
										<li>Order Complete</li>
										<li>{orderComplete}</li>
									</ul>
								</div>
							</div>
						</div>
						<div className={style.stepsOuter}>
							<div className={`sub-hdr alt1 ${style.subHdrAlter} `}>
								<div className="nib-supplement">
									<div className="the-notch">
										<div className="notch-fg">
											<svg>
												<path
													d="M35.81,26.28l-13,10v-5C-10.47,28.68-1,9.16,11.79,0c0,7.28,0,9.13,0,13.28s3.18,7.92,11,9v-5Z"></path>
											</svg>
										</div>
									</div>
								</div>
								<h2 className={`${style.sectionTitleSupplement}`}>Supplemental Order Data</h2>
							</div>
							<div className={`row ${style.rowGrp}`}>
								<div className={`col-sm-6 col-md-4  ${style.colGrp}`}>
									<ul className={` ${style.productList}`}>
										<li>Parent BAN</li>
										<li>{parentBAN}</li>
									</ul>
								</div>
								<div className={`col-sm-6 col-md-4  ${style.colGrp}`}>
									<ul className={` ${style.productList}`}>
										<li>Child BAN</li>
										<li>{childBAN}</li>
									</ul>
								</div>
								<div className={`col-sm-6 col-md-4  ${style.colGrp}`}>
									<ul className={` ${style.productList}`}>
										<li>Order Assignee</li>
										<li>{employee}</li>
									</ul>
								</div>
								<div className={`col-sm-6 col-md-4  ${style.colGrp}`}>
									<ul className={` ${style.productList}`}>
										<li>Customer Desired Due Date</li>
										<li>{orderView?.serv_date}</li>
									</ul>
								</div>
								{Array.isArray(orderView?.exist_circuit) && (orderView?.exist_circuit).length > 1 ?
									<>
										{(orderView?.exist_circuit).map((circuitId, idx) => {
											if (idx < 7) {
												return (
													<div className={`col-sm-6 col-md-4  ${style.colGrp}`}>
														<ul className={` ${style.productList}`}>
															<li>{`Circuit ID ${idx + 1}`} </li>
															<li>{circuitId}</li>
														</ul>
													</div>
												)
											}
										})}
										{(orderView?.exist_circuit).length > 6 ?
											<div className={`col-sm-6 col-md-4  ${style.colGrp}`}>
												<button className={style.circuitBtn} onClick={() => updateCircuitListDisplay('open')} >Display All Circuit</button>
												{circuitListDisplay ?
													<DisplayCircuitList exist_circuit={orderView?.exist_circuit} updateCircuitListDisplay={() => updateCircuitListDisplay('close')} />
													: null}
											</div>
											: null}
									</>
									:
									<>
										<div className={`col-sm-6 col-md-4  ${style.colGrp}`}>
											<ul className={` ${style.productList}`}>
												<li>Circuit ID</li>
												<li>{orderView.exist_circuit}</li>
											</ul>
										</div>
									</>
								}
							</div>
						</div>
					</div> : null}

					{orderView?.activity === "C" && orderView?.cpt_activity === "R" ? <div>
						<div className={style.stepsOuter}>
							<div className={style.orderViewHeader}>Renewal Order</div>
							<div className={`row ${style.rowGrp}`}>
								<div className={`col-sm-6 col-md-4  ${style.colGrp}`}>
									<ul className={` ${style.productList}`}>
										<li>Order Creation Date</li>
										<li>{orderView?.opened}</li>
									</ul>
								</div>
								<div className={`col-sm-6 col-md-4  ${style.colGrp}`}>
									<ul className={` ${style.productList}`}>
										<li>Order Accepted Date</li>
										<li>{orderAcceptedDate}</li>
									</ul>
								</div>
								<div className={`col-sm-6 col-md-4  ${style.colGrp}`}>
									<ul className={` ${style.productList}`}>
										<li>Order Complete</li>
										<li>{orderComplete}</li>
									</ul>
								</div>
							</div>
						</div>
						<div className={style.stepsOuter}>
							<div className={`sub-hdr alt1 ${style.subHdrAlter} `}>
								<div className="nib-supplement">
									<div className="the-notch">
										<div className="notch-fg">
											<svg>
												<path
													d="M35.81,26.28l-13,10v-5C-10.47,28.68-1,9.16,11.79,0c0,7.28,0,9.13,0,13.28s3.18,7.92,11,9v-5Z"></path>
											</svg>
										</div>
									</div>
								</div>
								<h2 className={`${style.sectionTitleSupplement}`}>Supplemental Order Data</h2>
							</div>
							<div className={`row ${style.rowGrp}`}>
								<div className={`col-sm-6 col-md-4  ${style.colGrp}`}>
									<ul className={` ${style.productList}`}>
										<li>Parent BAN</li>
										<li>{parentBAN}</li>
									</ul>
								</div>
								<div className={`col-sm-6 col-md-4  ${style.colGrp}`}>
									<ul className={` ${style.productList}`}>
										<li>Child BAN</li>
										<li>{childBAN}</li>
									</ul>
								</div>
								<div className={`col-sm-6 col-md-4  ${style.colGrp}`}>
									<ul className={` ${style.productList}`}>
										<li>Customer Desired Due Date</li>
										<li>{orderView?.serv_date}</li>
									</ul>
								</div>
								{Array.isArray(orderView?.exist_circuit) && (orderView?.exist_circuit).length > 1 ?
									<>
										{(orderView?.exist_circuit).map((circuitId, idx) => {
											if (idx < 7) {
												return (
													<div className={`col-sm-6 col-md-4  ${style.colGrp}`}>
														<ul className={` ${style.productList}`}>
															<li>{`Circuit ID ${idx + 1}`} </li>
															<li>{circuitId}</li>
														</ul>
													</div>
												)
											}
										})}
										{(orderView?.exist_circuit).length > 6 ?
											<div className={`col-sm-6 col-md-4  ${style.colGrp}`}>
												<button className={style.circuitBtn} onClick={() => updateCircuitListDisplay('open')} >Display All Circuit</button>
												{circuitListDisplay ?
													<DisplayCircuitList exist_circuit={orderView?.exist_circuit} updateCircuitListDisplay={() => updateCircuitListDisplay('close')} />
													: null}
											</div>
											: null}
									</>
									:
									<>
										<div className={`col-sm-6 col-md-4  ${style.colGrp}`}>
											<ul className={` ${style.productList}`}>
												<li>Circuit ID</li>
												<li>{orderView.exist_circuit}</li>
											</ul>
										</div>
									</>
								}
							</div>
						</div>
					</div> : null}

					{orderView?.activity === "D" ? <div>
						<div className={style.stepsOuter}>
							<div className={style.orderViewHeader}>Disconnect Order
							</div>
							<div className={`row ${style.rowGrp}`}>
								<div className={`col-sm-6 col-md-4  ${style.colGrp}`}>
									<ul className={` ${style.productList}`}>
										<li>Order Creation Date</li>
										<li>{orderView?.opened}</li>
									</ul>
								</div>
								<div className={`col-sm-6 col-md-4  ${style.colGrp}`}>
									<ul className={` ${style.productList}`}>
										<li>Order Accepted Date</li>
										<li>{orderAcceptedDate}</li>
									</ul>
								</div>
								<div className={`col-sm-6 col-md-4  ${style.colGrp}`}>
									<ul className={` ${style.productList}`}>
										<li>Order Complete</li>
										<li>{orderComplete}</li>
									</ul>
								</div>
							</div>
						</div>
						<div className={style.stepsOuter}>
							<div className={`sub-hdr alt1 ${style.subHdrAlter} `}>
								<div className="nib-supplement">
									<div className="the-notch">
										<div className="notch-fg">
											<svg>
												<path
													d="M35.81,26.28l-13,10v-5C-10.47,28.68-1,9.16,11.79,0c0,7.28,0,9.13,0,13.28s3.18,7.92,11,9v-5Z"></path>
											</svg>
										</div>
									</div>
								</div>
								<h2 className={`${style.sectionTitleSupplement}`}>Supplemental Order Data</h2>
							</div>
							<div className={`row ${style.rowGrp}`}>
								<div className={`col-sm-6 col-md-4  ${style.colGrp}`}>
									<ul className={` ${style.productList}`}>
										<li>Parent BAN</li>
										<li>{parentBAN}</li>
									</ul>
								</div>
								<div className={`col-sm-6 col-md-4  ${style.colGrp}`}>
									<ul className={` ${style.productList}`}>
										<li>Child BAN</li>
										<li>{childBAN}</li>
									</ul>
								</div>
								<div className={`col-sm-6 col-md-4  ${style.colGrp}`}>
									<ul className={` ${style.productList}`}>
										<li>Order Assignee</li>
										<li>{employee}</li>
									</ul>
								</div>
								<div className={`col-sm-6 col-md-4  ${style.colGrp}`}>
									<ul className={` ${style.productList}`}>
										<li>Requested Bill End Date</li>
										<li>{orderView?.serv_date}</li>
									</ul>
								</div>
								{Array.isArray(orderView?.exist_circuit) && (orderView?.exist_circuit).length > 1 ?
									<>
										{(orderView?.exist_circuit).map((circuitId, idx) => {
											if (idx < 7) {
												return (
													<div className={`col-sm-6 col-md-4  ${style.colGrp}`}>
														<ul className={` ${style.productList}`}>
															<li>{`Circuit ID ${idx + 1}`} </li>
															<li>{circuitId}</li>
														</ul>
													</div>
												)
											}
										})}
										{(orderView?.exist_circuit).length > 6 ?
											<div className={`col-sm-6 col-md-4  ${style.colGrp}`}>
												<button className={style.circuitBtn} onClick={() => updateCircuitListDisplay('open')} >Display All Circuit</button>
												{circuitListDisplay ?
													<DisplayCircuitList exist_circuit={orderView?.exist_circuit} updateCircuitListDisplay={() => updateCircuitListDisplay('close')} />
													: null}
											</div>
											: null}
									</>
									:
									<>
										<div className={`col-sm-6 col-md-4  ${style.colGrp}`}>
											<ul className={` ${style.productList}`}>
												<li>Circuit ID</li>
												<li>{orderView.exist_circuit}</li>
											</ul>
										</div>
									</>
								}
							</div>
						</div>
					</div> : null}
				</div>
			</div>
		</>
	);
}
export default QuickbaseTimelineComponent;