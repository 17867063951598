import React, {useEffect, useState} from 'react';
import SummaryData from '../../services/CircuitSummaryService';
import Loader from "../shared/Loader";
import HTTPErrorHandler from "../errorPages/HTTPErrorHandler";
import {ConsoleLogger} from "../../logger/ConsoleLogger";

const FullCircuitpath = ({ designId }) => {
    const [mapFlag, setMapFlag] = useState(false);
    const [mapLoadedFlag,setMapLoadedFlag] = useState(false);
    const [loader,setLoader] = useState(false);
    const google = window.google = window.google ? window.google : {};
    const logger = new ConsoleLogger({level: process.env.REACT_APP_LOGGER_LEVEL,});
    const [hasError, setHasError] = React.useState(null);

    const getFullCircuitMap = (designId) => {
        setLoader(true);
        SummaryData.getCircuitPathDetails(designId).then(resp => {
            var pathDetails = resp.data
            if (pathDetails === "No designId" || pathDetails === "No Data") {
                setLoader(false);
                setMapFlag(true);
            } else {
                const map = new google.maps.Map(document.getElementById("map"), {
                    zoom: 6,
                    center: { lat: 41.85, lng: -87.65 },
                    mapTypeId: "terrain"
                });
                const circuitCoordinates = [];
                var locations = [];
                var loc_id = '';
                var loc = '';
                var loc_name = '';
                var address = '';
                var lat = '';
                var lng = '';
                
                pathDetails.forEach((key, val) => {
                    loc_id = key.location_id;
                    loc = key.location;
                    address = key.address;
                    lat = key.lat_degrees;
                    lng = key.lon_degrees
                    circuitCoordinates.push({ 'lat': lat, 'lng': lng })
                    var loc_data = 'ID:' + loc_id + "<br>" + loc + "<br>" + loc_name + "<br>" + address;
                    locations.push({ 0: loc_data, 1: lat, 2: lng, 3: val });
                });

                const circuitPath = new google.maps.Polyline({
                    path: circuitCoordinates,
                    geodesic: true,
                    strokeColor: "#0000ff",
                    strokeOpacity: 1.0,
                    strokeWeight: 2
                });

                var bounds = new google.maps.LatLngBounds();
                var infowindow = new google.maps.InfoWindow();

                var marker, i;

                for (i = 0; i < locations.length; i++) {
                    marker = new google.maps.Marker({
                        position: new google.maps.LatLng(locations[i][1], locations[i][2]),
                        map: map,
                    });

                    bounds.extend(marker.position);

                    google.maps.event.addListener(marker, 'click', (function (marker, i) {
                        return function () {
                            infowindow.setContent(locations[i][0]);
                            infowindow.open(map, marker);
                        }
                    })(marker, i));
                }

                //now fit the map to the newly inclusive bounds
                map.fitBounds(bounds);
                map.panToBounds(bounds);
                circuitPath.setMap(map);
                setLoader(false);
                setMapLoadedFlag(true);
                
                
    
        }
    }).catch((error) => {
        logger.error("getFullCircuitMap ERROR: " + error)
        setHasError(error);
    });;
}


useEffect(() => {
    getFullCircuitMap(designId);
}, [])




return (
    <>
        {loader ? <Loader /> : null}
        {hasError && (
                <HTTPErrorHandler error={hasError}></HTTPErrorHandler>
        )}
        
		{!hasError && mapFlag ?
			<div>
				<h6> Circuit path not available </h6>
			</div> :
			<div className='GoogleMap' id='map' style={{ height: '85vh', width: "100%" }}>
				Loading...
			</div>
		}
		{mapLoadedFlag ?
			<div class="map">* Route displayed as designed. Map may not reflect redesigned paths or changes made post-order turn up.</div> : null
        }
        
     
    </>
)
}

export default FullCircuitpath;
