import React from 'react'
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx'

export const ExportXLSX = ({ className, label, headers, csvData, fileName }) => {

    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    const exportToXLSX = (headers, csvData, fileName) => {
        const ws = XLSX.utils.json_to_sheet(csvData, {header: headers});
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, fileName + fileExtension);
    }

    return (
        <button href="#" onClick={(e) => exportToXLSX(headers, csvData, fileName)}><span><strong>{label}</strong></span>
            <svg viewBox="0 0 34 34" className={`ico light-ico ico-arrow downloadArrow ${className}`}>
                <path className="cls-1" d="M1,18V16L17,31V1h2V31L35,16v2L20,33H35v2H1V33H16Z"
                    transform="translate(-1 -1)">
                </path>
            </svg>
        </button>
    )
}