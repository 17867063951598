import "./serverError.css";
import {Link} from "react-router-dom";

const Error500 = () => {
    return (
        <>
            <div className={"errorNumber"}>{"500"}</div>
            <div className={"errorText"}>{"Server Error"}</div>
            <Link to="/" target="_blank" className="errLabelText">iConnect Home</Link>
        </>)
}
export default Error500;
