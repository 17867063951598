import axios from "./axios/customAxios";

const getCircuitDetails = async (circuitIdLike) => {
	const circuitDetailURL = `${process.env.REACT_APP_API_URL}/CircuitInformation.php?action=circuitDetails&circuitLike=`+circuitIdLike;
    return await axios.get(circuitDetailURL);
}

const getLightLevelDetails = async(circuitID) => {
	const lightLevelURL = `${process.env.REACT_APP_API_URL}/CircuitInformation.php?action=lightlevels&circuitId=`+circuitID;
    return await axios.get(lightLevelURL);
}

const getTicketHistory = async(circuitID,days) => {
	const ticketHistoryURL = `${process.env.REACT_APP_API_URL}/CircuitInformation.php?action=ticket&circuitId=`+circuitID+"&days="+days;
    return await axios.get(ticketHistoryURL);
}

const getMaintenanceHistory = async(circuitID,days) => {
	const maintenanceHistoryURL = `${process.env.REACT_APP_API_URL}/CircuitInformation.php?action=maintenance&circuitId=`+circuitID+"&days="+days;
    return await axios.get(maintenanceHistoryURL);
}

const getCircuitPathDetails = async(designId) => {
	const fullPathURL = `${process.env.REACT_APP_API_URL}/CircuitInformation.php?action=path&designId=`+designId;
    return await axios.get(fullPathURL);
}

const getKmzLink = async(designId) => {
    const kmzPathURL = `${process.env.REACT_APP_API_URL}/getKmzLink.php?designId=${designId}&action=kmz`;
    return await axios.get(kmzPathURL);
}

const getKmzToken = async(url, designId) => {
    const kmzTokenURL= `${process.env.REACT_APP_API_URL}/getKmzToken.php?url=${url}&designId=${designId}`;
    return await axios.get(kmzTokenURL);
}


const SummaryData = {
    getCircuitDetails,
    getLightLevelDetails,
    getTicketHistory,
    getMaintenanceHistory,
    getCircuitPathDetails,
    getKmzLink,
    getKmzToken

}

export default SummaryData;
