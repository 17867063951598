/**
 * Created by e0173131 on 1/20/2021.
 */
import React, {Fragment} from 'react';

const ViewportWarning = () => {
    return (
        <Fragment>
            <div className="warning">
                Adjust your device or browser height!
            </div>
        </Fragment>
    );
}

export default ViewportWarning;
