import { useCallback, useEffect, useState, useMemo } from 'react';
import { ConsoleLogger } from '../../logger/ConsoleLogger';
import {
	resetData,
	setQuoteAccessType,
	setQuoteAddBW1,
	setQuoteAddBW2,
	setQuoteAddressA,
	setQuoteAddressZ,
	setQuoteATTExclusion,
	setQuoteBreakerAmp,
	setQuoteBurstBilling1,
	setQuoteBurstBilling2,
	setQuoteBurstBilling3,
	setQuoteBW,
	setQuoteCAMQty,
	setQuoteCircuitType,
	setQuoteClone,
	setQuoteComcastExclusion,
	setQuoteCommitBw1,
	setQuoteCommitBw2,
	setQuoteCommitBw3,
	setQuoteCOS,
	setQuoteCosType1,
	setQuoteCosType2,
	setQuoteCosType3,
	setQuoteCPEType,
	setQuoteCrossConnect,
	setQuoteDDOS,
	setQuoteDDOSType,
	setQuoteDiversity,
	setQuoteDiversityNote,
	setQuoteENNIExtension,
	setQuoteFusePanel,
	setQuoteInternetRouter,
	setQuoteIps,
	setQuoteKmzFile,
	setQuoteLocAUni,
	setQuoteLocZUni,
	setQuoteLumenExclusion,
	setQuoteNNILoc,
	setQuotePopLocation,
	setQuotePrimaryPower,
	setQuoteProduct,
	setQuoteProductCode,
	setQuoteProtocol1,
	setQuoteProtocol2,
	setQuoteProtocol3,
	setQuoteQty,
	setQuoteRackSpace,
	setQuoteRouteLatency,
	setQuoteRouteType,
	setQuoteSecondaryPower,
	setQuoteSpectrumExclusion,
	setQuoteStepCode,
	setQuoteTerm1,
	setQuoteTerm2,
	setQuoteTerm3,
	setQuoteTerm4,
	setQuoteTerm5,
	setQuoteTerm10,
	setQuoteTermMonthToMonth,
	setQuoteVendorExclusion,
	setQuoteVerizonExclusion,
	setQuoteVpnAPort1,
	setQuoteVpnAPort2,
	setQuoteVpnAPort3,
	setQuoteVpnZPort1,
	setQuoteVpnZPort2,
	setQuoteVpnZPort3,
	setQuoteBroadbandAdsl,
	setQuoteBroadbandCable,
	setUqualId,
	setQuoteTypeOfIPs,
	setQuoteModem,
	setQuoteDfOptions,
	setQuoteDfTerm,
	setQuoteDfNumFibers,
	setQuoteDfComments
} from '../../redux/actions/quoteActions';
import {
	setNewQuoteContactName,
	setQuoteBusinessName,
	setQuoteContactName,
	setQuoteCusRefID,
	setQuoteEmail,
	setQuoteICBComments,
	setQuoteManualICB,
	setQuoteMRCPrice,
	setQuotePhone
} from '../../redux/actions/quoteContactActions';
import {
	setLocAAddress,
	setLocACity,
	setLocAFloorNum,
	setLocAState,
	setLocAStruct,
	setLocAStructNum,
	setLocAUnit,
	setLocAUnitNum,
	setLocAZip
} from '../../redux/actions/quoteLocAActions';
import {
	setLocZAddress,
	setLocZCity,
	setLocZFloorNum,
	setLocZState,
	setLocZStruct,
	setLocZStructNum,
	setLocZUnit,
	setLocZUnitNum,
	setLocZZip
} from '../../redux/actions/quoteLocZActions';
import {
	setAgency,
	setAgent,
	setAgentAddress,
	setAgentCity,
	setAgentEmail,
	setAgentFee,
	setAgentName,
	setAgentPhone,
	setAgentState,
	setAgentZip,
	setDisableCustEmail,
	setMarkup,
	setNonCommissionable,
	setPrevQuoteNum,
	setPricingOverride,
	setWholesaleOpportunity
} from "../../redux/actions/internalActions";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import Loader from '../shared/Loader';

const QuoteClone = (props) => {
	const logger = useMemo(() => new ConsoleLogger({ level: process.env.REACT_APP_LOGGER_LEVEL, }), []);
	const [loader, setLoader] = useState(false);
	const isEmployee = localStorage.getItem("isEmployeeLoggedIn");


	/*********************************************************************
	Helper function to set the correct image on Step 1 of Quote Create
	Takes: product id
	Result: Maps the product id to the product icon image inside of the
	public/images/product_ioncs directory and saves that value in the store
	**********************************************************************/
	const getProductCode = useCallback((product, circuit) => {
		switch (product) {
			case "1":
			case "2":
			case "3":
				props.updateQuoteProductCode("WAVE");
				break;
			case "6":
				props.updateQuoteProductCode("MPLS_A_TAIL");
				break;
			case "7":
				props.updateQuoteProductCode("MPLS_NNI");
				break;
			case "10":
				props.updateQuoteProductCode("INTERNET");
				break;
			case "11":
				if (circuit === "14") {
					props.updateQuoteProductCode("DIA_PREM_BROADBAND");
					break;
				} else {
					props.updateQuoteProductCode("INTERNET");
					break;
				}
			case "13":
				props.updateQuoteProductCode("MPLS_INTERNET");
				break;
			case "15":
				props.updateQuoteProductCode("COLOCATION");
				break;
			case "19":
				props.updateQuoteProductCode("E_LINE");
				break;
			case "20":
				props.updateQuoteProductCode("E_ACCESS");
				break;
			case "22":
				props.updateQuoteProductCode("E_ACCESS_NNI");
				break;
			case "23":
				props.updateQuoteProductCode("BROADBAND");
				break;
			case "32":
				props.updateQuoteProductCode("DARKFIBER");
				break;
			case "35":
				props.updateQuoteProductCode("MANAGED_SPECTRUM");
				break;
			default:
				break;

		}
	}, [props])

	/***********************************************************************************************
	Helper function to format the cloned address correctly so the dropdown display properly
	so the react-select dropdown is populated correctly
	Takes: Product, pop id, clli, location, provider, A or Z for location, type as Other or "", premAdress
	Result: Populates the correct pop location object or prem location object in redux store
	*************************************************************************************************/
	const cloningQuoteAddress = useCallback((product, circuit, popId, clli, location, provider, locType, type, premAddress) => {
		let premFlag = false;

		//MPLS AGG TAIL, MPLS INTERNET, DIA PREM, EACCESS AND DARKFIBER ALWAYS HAVE PREM ADDRESSES
		if (product === "6" || product === "11" || product === "13" || product === "20" || product === "32") {
			premFlag = true;
		} else {
			if (type.toUpperCase() === "OTHER" || premAddress.la_co_uid === "0") {
				premFlag = true;
			}
			//some products don't set the la_co_uid so we need to check the clli
			if (type.toUpperCase() !== "OTHER" && premAddress.la_co_uid === "0" && clli) {
				premFlag = false;
			}
		}



		if (!premFlag) {
			logger.info("Address cloned was picked from the location dropdowns");
			let addressLabel = '';
			location = location.replace(/\s+/g, ' ').trim();
			if (provider?.length > 0 && Number(product) < 4) {
				addressLabel = `${clli} - ${location} - ${provider}`;
			} else {
				addressLabel = `${clli} - ${location}`;
			}

			//WAVE(1,2,3) & E-Line & Managed Spectrum have a POP LOC A and LOC Z
			if (Number(product) < 4 || product === "19" || product === "35" || product === "32") {
				if (locType === "A") {
					props.updateQuoteAddressA({ "value": Number(popId), "label": addressLabel });
				} else {
					props.updateQuoteAddressZ({ "value": Number(popId), "label": addressLabel });
				}
			} else if (product === "10") {
				//DIA POP
				props.updateQuotePopLocation({ "value": clli, "label": addressLabel });
			} else if (product === "15") {
				props.updateQuotePopLocation({ "value": Number(popId), "label": addressLabel })
			} else {
				//MPLS NNI AND EACCESS NNI LOC A
				props.updateQuoteNNILoc({ "value": clli, "label": addressLabel })
			}
		} else {
			logger.info("Address cloned is a prem address")
			//The dropdowns need to be set to OTHER or 99 so the correct prem location components gets called
			if (Number(product) < 4 || product === "19" || product === "32") {
				if (locType === "A") {
					props.updateQuoteAddressA({ "value": "Other", "label": "Other Location" });
				} else {
					props.updateQuoteAddressZ({ "value": "Other", "label": "Other Location" });
				}

			} else if ((circuit === "6" || product === "15") && product !== "22") {
				//ETHERNET PREM/POP and COLOCATION LOC A
				logger.info("POP LOCATION");
				props.updateQuotePopLocation({ "value": 99, "label": "Other Location" });
			} else {
				logger.info("NNI LOCATION");
				//IGNORE THIS UPDATE FOR THE Z LOCATION OF EACCESS NNI because that doesn't have a dropdown
				if ((product === "22" && locType === "A") || (product !== "22" && locType !== "Z")) {
					props.updateQuoteNNILoc({ "value": 99, "label": "Other Location" });
				}
			}

			//Store prem address in the quoteLocA and quoteLocZ object in the redux store
			if (locType === "A") {
				props.updateLocAAddress(premAddress.locA.replace(/\s+/g, ' ').trim());
				props.updateLocAStruct(premAddress.la_ld1);
				props.updateLocAStructName(premAddress.la_lv1);
				props.updateLocAFloorNum(premAddress.la_lv2);
				props.updateLocAUnit(premAddress.la_ld3);
				props.updateLocAUnitNum(premAddress.la_lv3);
				props.updateLocACity(premAddress.la_city);
				props.updateLocAState(premAddress.la_state);
				props.updateLocAZip(premAddress.la_zip)
			} else {
				props.updateLocZAddress(premAddress.locZ.replace(/\s+/g, ' ').trim());
				props.updateLocZStruct(premAddress.lz_ld1);
				props.updateLocZStructName(premAddress.lz_lv1);
				props.updateLocZFloorNum(premAddress.lz_lv2);
				props.updateLocZUnit(premAddress.lz_ld3);
				props.updateLocZUnitNum(premAddress.lz_lv3);
				props.updateLocZCity(premAddress.lz_city);
				props.updateLocZState(premAddress.lz_state);
				props.updateLocZZip(premAddress.lz_zip)
			}

		}
	}, [logger, props])

	/****************************************************************************
	Helper function to map data passed in from quote view to the different redux
	quote objects to clone a quote
	****************************************************************************/
	const cloningQuoteData = useCallback((quoteData) => {
		setLoader(true);
		props.updateQuoteClone(quoteData.ref_id);
		//STEP 1 PRODUCT SELECTIONS
		//IN ICONNECT WAVE IS MAPPED TO 1 IN CPT PRIVATE LINE WAS EITHER 1, 2, OR 3
		if (Number(quoteData.product_id) < 4) {
			props.updateQuoteProduct(1)
		} else {
			props.updateQuoteProduct(Number(quoteData.product_id));
		}

		getProductCode(quoteData.product_id, quoteData.circuit_id);
		props.updateQuoteCircuitType(Number(quoteData.circuit_id));
		props.updateQuoteStepCode(2);

		//STEP 2 LOCATION INFORMATION
		//Wholesale Broadband requires a different process for cloning an address
		if (quoteData.product_id === "23") {
			logger.info("Cloning a wholesale broadband quote");
			props.updateQuoteUqualId(quoteData?.uqualId);
			props.updateLocAAddress(quoteData?.locA.replace(/\s+/g, ' ').trim());
			props.updateLocAStruct(quoteData?.la_ld1);
			props.updateLocAStructName(quoteData?.la_lv1);
			props.updateLocAFloorNum(quoteData?.la_lv2);
			props.updateLocAUnit(quoteData?.la_ld3);
			props.updateLocAUnitNum(quoteData?.la_lv3);
			props.updateLocACity(quoteData?.la_city);
			props.updateLocAState(quoteData?.la_state);
			props.updateLocAZip(quoteData?.la_zip)
		} else {
			//WAVE, ELINE, DARKFIBER and EACCESS NNI with enni extension have both LOC A AND Z
			if (Number(quoteData.product_id) < 4 || quoteData.product_id === "19" || quoteData.product_id === "32" || (quoteData.product_id === "22" && quoteData.enni_ext === "1")) {
				cloningQuoteAddress(quoteData.product_id, quoteData.circuit_id, quoteData.la_co_uid, quoteData.la_clli, quoteData.locA, quoteData.locA_provider, "A", quoteData.la_co_name, quoteData);
				cloningQuoteAddress(quoteData.product_id, quoteData.circuit_id, quoteData.lz_co_uid, quoteData.lz_clli, quoteData.locZ, quoteData.locZ_provider, "Z", quoteData.lz_co_name, quoteData);
			} else {
				cloningQuoteAddress(quoteData.product_id, quoteData.circuit_id, quoteData.la_co_uid, quoteData.la_clli, quoteData.locA, quoteData.locA_provider, "A", quoteData.la_co_name, quoteData);
			}

			//Step 2 ENNI EXTENSION
			if (quoteData.product_id === "22") {
				props.updateQuoteENNIExtension(quoteData?.enni_ext === "1" ? true : false);
			}
		}


		//Step 3 TERM
		props.updateQuoteTerm1(quoteData["1yr_term"] === "1" ? "on" : "off");
		props.updateQuoteTerm2(quoteData["2yr_term"] === "1" ? "on" : "off");
		props.updateQuoteTerm3(quoteData["3yr_term"] === "1" ? "on" : "off");
		props.updateQuoteTerm4(quoteData["4yr_term"] === "1" ? "on" : "off");
		props.updateQuoteTerm5(quoteData["5yr_term"] === "1" ? "on" : "off");
		props.updateQuoteTerm10(quoteData["10yr_term"] === "1" ? "on" : "off"); //Only Managed Spectrum
		props.updateQuoteTermMTM(quoteData["mtm_term"] === "1" ? "on" : "off"); //Only Wholesale Broadband
		props.updateQuoteDfTerm(quoteData["df_term"]) // Only DarkFiber

		//Step 3 BANDWIDTHS
		props.updateQuoteBW(quoteData?.bandwidth1?.length > 0 ? quoteData.bandwidth1 : "");
		props.updateQuoteAddBW1(quoteData?.bandwidth2?.length > 0 ? quoteData.bandwidth2 : "");
		props.updateQuoteAddBW2(quoteData?.bandwidth3?.length > 0 ? quoteData.bandwidth3 : "");

		//Wholesale Broadband bandwidth, type of IPs, and modem
		if (quoteData?.product_id === "23") {
			props.updateQuoteBW(quoteData?.loop_band);
			props.updateQuoteTypeOfIPs(quoteData?.ip_add);
			props.updateQuoteModem(quoteData?.modemId);
		}

		//Step 3 DIA POP BURSTABLE BILLING & COMMITTED BANDWIDTH
		if (quoteData.product_id === "10") {
			props.updateQuoteBurstBilling1(quoteData?.brustBilling1?.length > 0 ? quoteData.brustBilling1 : "0");
			props.updateQuoteBurstBilling2(quoteData?.brustBilling2?.length > 0 ? quoteData.brustBilling2 : "0");
			props.updateQuoteBurstBilling3(quoteData?.brustBilling3?.length > 0 ? quoteData.brustBilling3 : "0");
			props.updateQuoteCommitBw1(quoteData?.commitBand1?.length > 0 ? quoteData.commitBand1 : "");
			props.updateQuoteCommitBw2(quoteData?.commitBand2?.length > 0 ? quoteData.commitBand2 : "");
			props.updateQuoteCommitBw3(quoteData?.commitBand3?.length > 0 ? quoteData.commitBand3 : "");
		}

		//WAVE SPECIFIC STEP 3 OPTIONS
		if (Number(quoteData.product_id) < 4) {
			props.updateQuoteProtocol1(quoteData?.protocol1?.length > 0 ? quoteData.protocol1 : "");
			props.updateQuoteProtocol2(quoteData?.protocol2?.length > 0 ? quoteData.protocol2 : "");
			props.updateQuoteProtocol3(quoteData?.protocol3?.length > 0 ? quoteData.protocol3 : "");
			props.updateQuoteKmzFile(quoteData?.kmz_file === "Yes" ? true : "");
			props.updateQuoteRouteLatency(quoteData?.route_latency === "Yes" ? true : "");
			props.updateQuoteRouteType(quoteData?.num_routes);
			props.updateQuoteDiverasity(quoteData?.diversity);
			props.updateQuoteDiversityNote(quoteData?.diversity_note);

		}

		//DIA POP, DIA PREM, MPLS INTERNET IPS | DDOS MITIGATION | CRITICIAL ASSET MONITORING | DDOS PRICING OPTION | WHOLESALE BROADBAND
		if (quoteData.product_id === "10" || quoteData.product_id === "11" || quoteData.product_id === "13" || quoteData.product_id === "23") {
			props.updateQuoteIps(quoteData?.ip_key?.length > 0 ? quoteData.ip_key : "");

			//ADO-833379 Remove DDOS Mitigation
			//props.updateQuoteDDOS(quoteData?.ddos_id === "1" ? "1" : "0");
			//props.updateQuoteCAMQty(quoteData?.cam_qty?.length > 0 ? quoteData.cam_qty : "");
			//props.updateQuoteDDOSType(quoteData?.ddos_type_id?.length > 0 ? quoteData.ddos_type_id : "");
		}

		//DIA PREM, MPLS TAIL, MPLS INTERNET CPE TYPE | ROUTER SERVICE
		if (quoteData.product_id === "11" || quoteData.product_id === "6" || quoteData.product_id === "13") {
			props.updateQuoteCPEType(quoteData?.cpe_type_id ? quoteData.cpe_type_id : "");
			props.updateQuoteInternetRouter(quoteData?.router_service_id?.length > 0 ? quoteData.router_service_id : "");
		}

		//STEP 3 COLOCATION CONFIGURATIONS
		if (quoteData.product_id === "15") {
			props.updateQuoteCrossConnect(quoteData?.ila ? quoteData.ila : "");
			props.updateQuoteRackSpace(quoteData?.rack_space_id ? quoteData.rack_space_id : "");
			props.updateQuoteBreakerAmp(quoteData?.breaker_amp ? quoteData.breaker_amp : "");
			props.updateQuotePrimaryPower(quoteData?.primary_power_id ? quoteData.primary_power_id : "");
			props.updateQuoteSecondaryPower(quoteData?.secondary_pwr === "Yes" ? true : false);
			props.updateQuoteFusePanel(quoteData?.fuse === "Yes" ? "1" : "");
		}

		//BROADBAND ACCESS TYPE | CABLE | ADSL
		if (quoteData.product_id === "11") {
			props.updateQuoteBroadbandAdsl(true);
			props.updateQuoteBroadbandCable(true);
		}

		//ELINE OR EACCESS COS | LOCA UNI | LOCZ UNI | ACCESS TYPE
		if (quoteData.product_id === "19" || quoteData.product_id === "20") {
			props.updateQuoteCOS(quoteData?.cos_needed === "Yes" ? 1 : 0);
			if (quoteData?.a_vpn_port1 || quoteData?.a_vpn_port2 || quoteData?.a_vpn_port3) {
				props.updateQuoteLocAUni("no");
				props.updateQuoteVpnAPort1(quoteData?.a_vpn_port1?.length > 0 ? quoteData.a_vpn_port1 : "");
				props.updateQuoteVpnAPort2(quoteData?.a_vpn_port2?.length > 0 ? quoteData.a_vpn_port2 : "");
				props.updateQuoteVpnAPort3(quoteData?.a_vpn_port3?.length > 0 ? quoteData.a_vpn_port3 : "");
			} else {
				props.updateQuoteLocAUni(quoteData.a_uni);
			}

			if (quoteData?.z_vpn_port1 || quoteData?.z_vpn_port2 || quoteData?.z_vpn_port3) {
				props.updateQuoteLocZUni("no");
				props.updateQuoteVpnZPort1(quoteData?.z_vpn_port1?.length > 0 ? quoteData.z_vpn_port1 : "");
				props.updateQuoteVpnZPort2(quoteData?.z_vpn_port2?.length > 0 ? quoteData.z_vpn_port2 : "");
				props.updateQuoteVpnZPort3(quoteData?.z_vpn_port3?.length > 0 ? quoteData.z_vpn_port3 : "");
			} else {
				props.updateQuoteLocZUni(quoteData.z_uni);
			}

			props.updateQuoteCosType1(quoteData?.cos1?.length > 0 ? quoteData.cos1 : "");
			props.updateQuoteCosType2(quoteData?.cos2?.length > 0 ? quoteData.cos2 : "");
			props.updateQuoteCosType3(quoteData?.cos3?.length > 0 ? quoteData.cos3 : "");

			props.updateQuoteAccessType(quoteData?.access_type_id ? quoteData?.access_type_id : "");
		}




		//MPLS TAIL OR DIA PREM OR MPLS INTERNET VENDOR EXCLUSION
		if (quoteData.product_id === "6" || quoteData.product_id === "11" || quoteData.product_id === "13") {
			props.updateQuoteVendorExclusion(quoteData?.vendor === "1" ? "on" : "off");
			props.updateQuoteATTExclusion(quoteData?.vendor_Att === "1" ? "ATT" : "");
			props.updateQuoteVerizonExclusion(quoteData?.vendor_Verizon === "1" ? "VERIZON" : "");
			props.updateQuoteLumenExclusion(quoteData?.vendor_Lumen === "1" ? "LUMEN" : "");
			props.updateQuoteComcastExclusion(quoteData?.vendor_Comcast === "1" ? "COMCAST" : "");
			props.updateQuoteSpectrumExclusion(quoteData?.vendor_Spectrum === "1" ? "SPECTRUM" : "");
		}


		//DARKFIBER
		if (quoteData.product_id === "32") {
			props.updateQuoteDfOptions(quoteData?.df_options);
			props.updateQuoteDfNumFibers(quoteData?.df_num_fibers);
			props.updateQuoteDfComments(quoteData?.manual_note);
		}

		props.updateQuoteQty(quoteData?.quantity);


		//STEP 4 CONTACT SECTIONS
		props.updateQuoteContact(quoteData?.cp_contact_id);
		props.updateQuotePhone(quoteData?.cp_phone);
		props.updateQuoteEmail(quoteData?.cp_email);
		props.updateQuoteBusinessName(quoteData?.bus_name);
		props.updateQuoteCusRefID(quoteData?.cp_quote_ref_id);
		props.updateQuoteMRCPrice(quoteData?.target_mrc_price === "N/A" ? "" : quoteData.target_mrc_price);
		props.updateQuoteManualICB(quoteData?.special_req === "Yes" ? true : false);
		props.updateQuoteICBComments(quoteData?.manual_note?.length > 0 ? quoteData.manual_note : "");

		//Cloning Adding Internal Only Fields
		if (isEmployee) {
			if (quoteData.agentData) {
				props.updateAgent(true);
				props.updateAgentName(quoteData.agentData?.agent_name);
				props.updateAgentPhone(quoteData.agentData?.phone);
				props.updateAgentEmail(quoteData.agentData?.email);
				props.updateAgentFee(quoteData.agentData?.agent_fee);
				props.updateAgentAddress(quoteData.agentData?.address);
				props.updateAgentCity(quoteData.agentData?.city);
				props.updateAgentState(quoteData.agentData?.state);
				props.updateAgentZip(quoteData.agentData?.zip);
			}
			props.updateWholesaleOpportunity(quoteData?.wholesale_opp_num);
			props.updateMarkup(quoteData?.markup === "1" ? true : false);
		}

		setLoader(false);
	}, [cloningQuoteAddress, getProductCode, isEmployee, props])

	useEffect(() => {
		//make sure quote object is cleared before cloning
		props.resetQuoteObjects();
		cloningQuoteData(props.quoteData);
	}, [cloningQuoteData, props])

	return (
		<>
			{loader ? <Loader /> : <Redirect to={`/Quote/Create`} />}
		</>

	)
}

const mapDispatchToProps = {
	updateQuoteClone: setQuoteClone,
	updateQuoteProduct: setQuoteProduct,
	updateQuoteProductCode: setQuoteProductCode,
	updateQuoteCircuitType: setQuoteCircuitType,
	updateQuoteStepCode: setQuoteStepCode,
	updateQuoteTerm1: setQuoteTerm1,
	updateQuoteTerm2: setQuoteTerm2,
	updateQuoteTerm3: setQuoteTerm3,
	updateQuoteTerm4: setQuoteTerm4,
	updateQuoteTerm5: setQuoteTerm5,
	updateQuoteTerm10: setQuoteTerm10,
	updateQuoteBW: setQuoteBW,
	updateQuoteAddBW1: setQuoteAddBW1,
	updateQuoteAddBW2: setQuoteAddBW2,
	updateQuoteNNILoc: setQuoteNNILoc,
	updateQuoteENNIExtension: setQuoteENNIExtension,
	updateQuotePopLocation: setQuotePopLocation,
	updateQuoteQty: setQuoteQty,
	updateQuoteIps: setQuoteIps,
	updateQuoteDDOS: setQuoteDDOS,
	updateQuoteCAMQty: setQuoteCAMQty,
	updateQuoteDDOSType: setQuoteDDOSType,
	updateQuoteCPEType: setQuoteCPEType,
	updateQuoteCrossConnect: setQuoteCrossConnect,
	updateQuoteRackSpace: setQuoteRackSpace,
	updateQuoteBreakerAmp: setQuoteBreakerAmp,
	updateQuotePrimaryPower: setQuotePrimaryPower,
	updateQuoteSecondaryPower: setQuoteSecondaryPower,
	updateQuoteFusePanel: setQuoteFusePanel,
	updateQuoteInternetRouter: setQuoteInternetRouter,
	updateQuoteVendorExclusion: setQuoteVendorExclusion,
	updateQuoteATTExclusion: setQuoteATTExclusion,
	updateQuoteVerizonExclusion: setQuoteVerizonExclusion,
	updateQuoteLumenExclusion: setQuoteLumenExclusion,
	updateQuoteComcastExclusion: setQuoteComcastExclusion,
	updateQuoteSpectrumExclusion: setQuoteSpectrumExclusion,
	updateQuoteAddressA: setQuoteAddressA,
	updateQuoteAddressZ: setQuoteAddressZ,
	updateQuoteProtocol1: setQuoteProtocol1,
	updateQuoteProtocol2: setQuoteProtocol2,
	updateQuoteProtocol3: setQuoteProtocol3,
	updateQuoteRouteType: setQuoteRouteType,
	updateQuoteDiverasity: setQuoteDiversity,
	updateQuoteDiversityNote: setQuoteDiversityNote,
	updateQuoteRouteLatency: setQuoteRouteLatency,
	updateQuoteKmzFile: setQuoteKmzFile,
	updateQuoteCOS: setQuoteCOS,
	updateQuoteLocAUni: setQuoteLocAUni,
	updateQuoteLocZUni: setQuoteLocZUni,
	updateQuoteVpnAPort1: setQuoteVpnAPort1,
	updateQuoteVpnAPort2: setQuoteVpnAPort2,
	updateQuoteVpnAPort3: setQuoteVpnAPort3,
	updateQuoteVpnZPort1: setQuoteVpnZPort1,
	updateQuoteVpnZPort2: setQuoteVpnZPort2,
	updateQuoteVpnZPort3: setQuoteVpnZPort3,
	updateQuoteCosType1: setQuoteCosType1,
	updateQuoteCosType2: setQuoteCosType2,
	updateQuoteCosType3: setQuoteCosType3,
	updateQuoteAccessType: setQuoteAccessType,
	updateQuoteBurstBilling1: setQuoteBurstBilling1,
	updateQuoteBurstBilling2: setQuoteBurstBilling2,
	updateQuoteBurstBilling3: setQuoteBurstBilling3,
	updateQuoteCommitBw1: setQuoteCommitBw1,
	updateQuoteCommitBw2: setQuoteCommitBw2,
	updateQuoteCommitBw3: setQuoteCommitBw3,
	updateQuoteBroadbandAdsl: setQuoteBroadbandAdsl,
	updateQuoteBroadbandCable: setQuoteBroadbandCable,
	resetQuoteObjects: resetData,
	//STEP 4 CONTACT SECTION
	updateQuoteContact: setQuoteContactName,
	updateNewQuoteContactName: setNewQuoteContactName,
	updateQuotePhone: setQuotePhone,
	updateQuoteEmail: setQuoteEmail,
	updateQuoteBusinessName: setQuoteBusinessName,
	updateQuoteCusRefID: setQuoteCusRefID,
	updateQuoteMRCPrice: setQuoteMRCPrice,
	updateQuoteManualICB: setQuoteManualICB,
	updateQuoteICBComments: setQuoteICBComments,
	//LOC A
	updateLocAAddress: setLocAAddress,
	updateLocAStruct: setLocAStruct,
	updateLocAStructName: setLocAStructNum,
	updateLocAFloorNum: setLocAFloorNum,
	updateLocAUnit: setLocAUnit,
	updateLocAUnitNum: setLocAUnitNum,
	updateLocACity: setLocACity,
	updateLocAState: setLocAState,
	updateLocAZip: setLocAZip,

	//LOCZ
	updateLocZAddress: setLocZAddress,
	updateLocZStruct: setLocZStruct,
	updateLocZStructName: setLocZStructNum,
	updateLocZFloorNum: setLocZFloorNum,
	updateLocZUnit: setLocZUnit,
	updateLocZUnitNum: setLocZUnitNum,
	updateLocZCity: setLocZCity,
	updateLocZState: setLocZState,
	updateLocZZip: setLocZZip,

	//Wholesale Broadband
	updateQuoteUqualId: setUqualId,
	updateQuoteTypeOfIPs: setQuoteTypeOfIPs,
	updateQuoteModem: setQuoteModem,
	updateQuoteTermMTM: setQuoteTermMonthToMonth,

	//DARKFIBER 32
	updateQuoteDfOptions: setQuoteDfOptions,
	updateQuoteDfTerm: setQuoteDfTerm,
	updateQuoteDfNumFibers: setQuoteDfNumFibers,
	updateQuoteDfComments: setQuoteDfComments,

	//Internal
	updateAgency: setAgency,
	updateDisableCustEmail: setDisableCustEmail,
	updatePrevQuoteNum: setPrevQuoteNum,
	updateNonCommissionable: setNonCommissionable,
	updateMarkup: setMarkup,
	updateWholesaleOpportunity: setWholesaleOpportunity,
	updatePricingOverride: setPricingOverride,
	updateAgent: setAgent,
	updateAgentName: setAgentName,
	updateAgentPhone: setAgentPhone,
	updateAgentFee: setAgentFee,
	updateAgentEmail: setAgentEmail,
	updateAgentAddress: setAgentAddress,
	updateAgentCity: setAgentCity,
	updateAgentState: setAgentState,
	updateAgentZip: setAgentZip
}
export default connect(null, mapDispatchToProps)(QuoteClone);
