import headerImage from './Images/service-quote-header-650x81.jpg';
import renewalHeaderImage from './Images/renewal_quote_header.jpg';
import { Page, Text, View, Document, Image } from '@react-pdf/renderer';
import { styles } from './pdfStyles';

const QuotePDF = (props) => {
	const lineItems = props.quoteData?.pricing?.lineitems || {};
	const regex = /[0-9]/;

	const HeaderImage = () => (
		<Image style={styles.imageHeader} src={props.quoteData?.q_cpt_activity === 'R' ? renewalHeaderImage : headerImage} />
	);

	return (
		<Document>
			<Page size="A4" style={styles.page}>
				<HeaderImage />
				<View style={{ height: 10 }}></View>
				<View style={styles.headerBar}>
					<Text style={styles.headerBarText}>Quote</Text>
				</View>

				<View style={styles.table}>
					<View style={styles.tableRow}>
						<Text style={[styles.headerColumn, styles.tableCell, styles.textBold]}>Company Name</Text>
						<Text style={[styles.tableColumn, styles.tableCell, styles.textNormal]}>{props.quoteData?.company}</Text>
						<Text style={[styles.headerColumn, styles.tableCell, styles.textBold]}>Reference ID</Text>
						<Text style={[styles.tableColumn, styles.tableCell, styles.textNormal]}>{props.quoteData?.ref_id}</Text>
					</View>
					<View style={styles.tableRow}>
						<Text style={[styles.headerColumn, styles.tableCell, styles.textBold]}>Quote Name</Text>
						<Text style={[styles.tableColumn, styles.tableCell, styles.textNormal]}>{props.quoteData?.bus_name}</Text>
						<Text style={[styles.headerColumn, styles.tableCell, styles.textBold]}>Customer Quote ID</Text>
						<Text style={[styles.tableColumn, styles.tableCell, styles.textNormal]}>{props.quoteData?.cp_quote_ref_id}</Text>
					</View>
					<View style={styles.tableRow}>
						<Text style={[styles.headerColumn, styles.tableCell, styles.textBold]}>Product</Text>
						<Text style={[styles.tableColumn, styles.tableCell, styles.textNormal]}>{props.quoteData?.product}</Text>
						<Text style={[styles.headerColumn, styles.tableCell, styles.textBold]}>ICB Number</Text>
						<Text style={[styles.tableColumn, styles.tableCell, styles.textNormal]}>{props.quoteData?.icb_number}</Text>
					</View>
					{props.quoteData?.product_id !== '15' ?
						<View style={styles.tableRow}>
							<Text style={[styles.headerColumn, styles.tableCell, styles.textBold]}>Circuit</Text>
							<Text style={[styles.tableColumn, styles.tableCell, styles.textNormal]}>{props.quoteData?.circuit}</Text>
							<Text style={[styles.headerColumn, styles.tableCell, styles.textBold]}>Quote Submitted</Text>
							<Text style={[styles.tableColumn, styles.tableCell, styles.textNormal]}>{props.quoteData?.open_date}</Text>
						</View>
						:
						<View style={styles.tableRow}>
							<Text style={[styles.headerColumn, styles.tableCell, styles.textBold]}>Quote Submitted</Text>
							<Text style={[styles.tableColumn, styles.tableCell, styles.textNormal]}>{props.quoteData?.open_date}</Text>
							<Text style={[styles.headerColumn, styles.tableCell, styles.textBold]}></Text>
							<Text style={[styles.tableColumn, styles.tableCell, styles.textNormal]}></Text>
						</View>
					}
					<View style={styles.tableRow}>
						<Text style={[styles.headerColumn, styles.tableCell, styles.textBold]}>Valid Until</Text>
						<Text style={[styles.tableColumn, styles.tableCell, styles.textNormal]}>{props.quoteData?.valid_until}</Text>
						<Text style={[styles.headerColumn, styles.tableCell, styles.textBold]}></Text>
						<Text style={[styles.tableColumn, styles.tableCell, styles.textNormal]}></Text>
					</View>
				</View>
				<View style={styles.headerBar}>
					<Text style={styles.headerBarText}>Location</Text>
				</View>
				<View style={styles.table}>
					{/* Table row with mixed styles */}
					<View style={styles.tableRow}>
						<Text style={[styles.tableColumn2Width, styles.tableCell, styles.textBold]}>Location A{props.quoteData?.la_loc_type?.length > 0 ? ` - ${props.quoteData.la_loc_type}` : ''}</Text>
						<Text style={[styles.tableColumn2Width, styles.tableCell, styles.textNormal]}></Text>
						{props.quoteData?.lz_add?.length > 0 ?
							<>
								<Text style={[styles.tableColumn2Width, styles.tableCell, styles.textBold]}>Location Z{props.quoteData?.lz_loc_type?.length > 0 ? ` - ${props.quoteData.lz_loc_type}` : ''}</Text>
								<Text style={[styles.tableColumn2Width, styles.tableCell, styles.textNormal]}></Text>
							</>
							:
							<>
								<Text style={[styles.tableColumn2Width, styles.tableCell, styles.textBold]}></Text>
								<Text style={[styles.tableColumn2Width, styles.tableCell, styles.textNormal]}></Text>
							</>
						}
					</View>
					<View style={styles.tableRow}>
						<Text style={[styles.tableColumn, styles.tableCell, styles.textNormal]}>{props.quoteData?.la_add}</Text>
						<Text style={[styles.tableColumn, styles.tableCell, styles.textNormal]}>{props.quoteData?.lz_add}</Text>
					</View>
					<View style={styles.tableRow}>
						<Text style={[styles.tableColumn2Width, styles.tableCell, styles.textNormal]}>{props.quoteData?.la_cityStateZip}</Text>
						<Text style={[styles.tableColumn2Width, styles.tableCell, styles.textNormal]}>{props.quoteData?.lz_cityStateZip}</Text>
					</View>
					{props.quoteData?.locA_provider ?
						<View style={styles.tableRow}>
							<Text style={[styles.headerColumn, styles.tableCell, styles.textBold]}>Demarc Provider</Text>
							<Text style={[styles.tableColumn, styles.tableCell, styles.textNormal]}>{props.quoteData?.locA_provider}</Text>
							{props.quoteData?.locZ_provider?.length > 0 ?
								<>
									<Text style={[styles.headerColumn, styles.tableCell, styles.textBold]}>Demarc Provider</Text>
									<Text style={[styles.tableColumn, styles.tableCell, styles.textNormal]}>{props.quoteData?.locZ_provider}</Text>
								</>
								: null}
						</View>
						: null}
				</View>

				<View style={styles.headerBar}>
					<Text style={styles.headerBarText}>Services</Text>
				</View>
				{Object.keys(lineItems).length > 0 && Object.keys(lineItems).map((option, optionIndex) => {
					const optionData = lineItems[option];
					return Object.keys(optionData).map((term, termIndex) => {
						const termData = optionData[term];
						const styleForIteration = optionIndex === 0 && termIndex === 0 ? styles.table : styles.tableTop;
						if (term.match(regex)) {
							const isFirstTermOfOption = termIndex === 0;
							return (
								<View key={`${option}-${term}`} wrap={false} style={styleForIteration}>
									{isFirstTermOfOption && (
										<>
											{props.quoteData?.product_id !== '15' && (
												<>
													<View key={optionIndex} style={styles.tableRow}>
														<Text style={[styles.tableColumn2Width, styles.tableCellCenter, styles.textBold]}>
															{props.quoteData?.circuit} {optionData.bw}
															{optionData.fiber ? ` - ${optionData.fiber}` : null}
															{optionData.upBand?.length > 0 ? ` / ${optionData.upBand}` : null}
															{optionData.protocol?.length > 0 ? ` Protocol ${optionData.protocol}` : null}
															{optionData.cos ? ` COS: ${optionData.cos}` : null}
														</Text>
													</View>
													{(props.quoteData?.product_id === '19' || props.quoteData?.product_id === '20') && (
														<View style={styles.tableRow}>
															<Text style={[styles.tableColumn2Width, styles.tableCellCenter, styles.textBold]}>
																{props.quoteData[option]?.a_uni ? ` Loc A UNI: ${props.quoteData[option].a_uni}` : null}
																{props.quoteData[option]?.z_uni ? ` Loc Z UNI: ${props.quoteData[option].z_uni}` : null}
															</Text>
														</View>
													)}
												</>
											)}
											<View style={styles.tableRow}>
												<Text style={[styles.tableColumn2Width, styles.tableCellCenter, styles.textBold]}></Text>
												<Text style={[styles.quantityColumn, styles.tableCellCenter, styles.textNormal]}>Quantity</Text>
												<Text style={[styles.tableColumn, styles.tableCellCenter, styles.textNormal]}>MRC</Text>
												<Text style={[styles.tableColumn, styles.tableCellCenter, styles.textNormal]}>NRC</Text>
											</View>
										</>
									)}
									<View style={styles.pricingTable}>
										<View style={styles.pricingHeaderRow}>
											<Text style={[styles.tableColumn, styles.tableCell, styles.textBold]}>
												{term} Year Term
											</Text>
										</View>
										{Object.keys(termData).map((line, lineIndex) => {
											if (line.match(regex)) {
												const lineItem = termData[line];
												return (
													<View style={styles.tableRow} key={`${line}-${lineIndex}`}>
														<Text style={[styles.tableColumn2Width, styles.tableCell, styles.textNormal]}>
															{lineItem.line_desc}
														</Text>
														<Text style={[styles.quantityColumn, styles.tableCellCenter, styles.textNormal]}>
															{optionData.qty}
														</Text>
														<Text style={[styles.tableColumn, styles.tableCellCenter, styles.textNormal]}>
															{lineItem.price_mrc}
														</Text>
														<Text style={[styles.tableColumn, styles.tableCellCenter, styles.textNormal]}>
															{lineItem.price_nrc}
														</Text>
													</View>
												);
											}
											return null;
										})}
										<View style={styles.tableRowBorderTop}>
											<Text style={[styles.tableColumn2Width, styles.tableCell, styles.textBold]}>
												{optionData.icb_price_desc} Totals
											</Text>
											<Text style={[styles.quantityColumn, styles.tableCellCenter, styles.textBold]}>
												{optionData.qty}
											</Text>
											<Text style={[styles.tableColumn, styles.tableCellCenter, styles.textBold]}>
												{termData.total_mrc}
											</Text>
											<Text style={[styles.tableColumn, styles.tableCellCenter, styles.textBold]}>
												{termData.total_nrc}
											</Text>
										</View>
										{props.quoteData?.offnet_vendor_visible === '1' && props.quoteData?.product_id !== '29' && termData.a_vendor?.length > 0 && (
											<>
												<View style={styles.tableRowBorderTop}>
													<Text style={[styles.tableColumn2Width, styles.tableCell, styles.textNormal]}>
														Vendor (Loc A)
													</Text>
													<Text style={[styles.quantityColumn, styles.tableCellCenter, styles.textNormal]}></Text>
													<Text style={[styles.tableColumn, styles.tableCellCenter, styles.textNormal]}>
														{termData.a_vendor}
													</Text>
													<Text style={[styles.tableColumn, styles.tableCellCenter, styles.textNormal]}></Text>
												</View>
												<View style={styles.tableRow}>
													<Text style={[styles.tableColumn2Width, styles.tableCell, styles.textNormal]}>
														Vendor (Loc Z)
													</Text>
													<Text style={[styles.quantityColumn, styles.tableCellCenter, styles.textNormal]}></Text>
													<Text style={[styles.tableColumn, styles.tableCellCenter, styles.textNormal]}>
														{termData.z_vendor}
													</Text>
													<Text style={[styles.tableColumn, styles.tableCellCenter, styles.textNormal]}></Text>
												</View>
											</>
										)}
									</View>
								</View>
							);
						}
						return null;
					});
				})}


				{props.quoteData?.lz_add?.length > 0 ?
					<>
						<View wrap={false}>
							<View style={styles.headerBarTopMargin}>
								<Text style={styles.headerBarText}>Details</Text>
							</View>
							<View style={styles.table}>
								{props.quoteData?.exist_circuit?.length > 0 ?
									<View style={styles.tableRow}>
										<Text style={[styles.tableColumn, styles.tableCell, styles.textBold]}>Circuit ID</Text>
										<Text style={[styles.tableColumn, styles.tableColumn2Width, styles.textNormal]}>{props.quoteData?.exist_circuit}</Text>
										<Text style={[styles.tableColumn, styles.tableCell, styles.textBold]}></Text>
									</View>
									: null}
								{props.quoteData?.la_existing_uni?.length > 0 ?
									<View style={styles.tableRow}>
										<Text style={[styles.tableColumn, styles.tableCell, styles.textBold]}>Loc A Existing UNI</Text>
										<Text style={[styles.tableColumn, styles.tableCell, styles.textNormal]}>{props.quoteData?.la_existing_uni}</Text>
										<Text style={[styles.tableColumn, styles.tableCell, styles.textBold]}>Loc Z Existing UNI</Text>
										<Text style={[styles.tableColumn, styles.tableCell, styles.textNormal]}>{props.quoteData?.lz_existing_uni}</Text>
									</View>
									: null}

								<View style={styles.tableRow}>
									<Text style={[styles.tableColumn, styles.tableCell, styles.textBold]}>Rate Center</Text>
									<Text style={[styles.tableColumn, styles.tableCell, styles.textNormal]}>{props.quoteData?.la_ratecenter}</Text>
									<Text style={[styles.tableColumn, styles.tableCell, styles.textBold]}>Rate Center</Text>
									<Text style={[styles.tableColumn, styles.tableCell, styles.textNormal]}>{props.quoteData?.lz_ratecenter}</Text>
								</View>
								<View style={styles.tableRow}>
									<Text style={[styles.tableColumn, styles.tableCell, styles.textBold]}>CSWC</Text>
									<Text style={[styles.tableColumn, styles.tableCell, styles.textNormal]}>{props.quoteData?.la_clli}</Text>
									<Text style={[styles.tableColumn, styles.tableCell, styles.textBold]}>CSWC</Text>
									<Text style={[styles.tableColumn, styles.tableCell, styles.textNormal]}>{props.quoteData?.lz_clli}</Text>
								</View>
								{props.quoteData?.la_lata?.length > 0 ?
									<View style={styles.tableRow}>
										<Text style={[styles.tableColumn, styles.tableCell, styles.textBold]}>LATA</Text>
										<Text style={[styles.tableColumn, styles.tableCell, styles.textNormal]}>{props.quoteData?.la_lata}</Text>
										<Text style={[styles.tableColumn, styles.tableCell, styles.textBold]}>LATA</Text>
										<Text style={[styles.tableColumn, styles.tableCell, styles.textNormal]}>{props.quoteData?.lz_lata}</Text>
									</View>
									: null}
								<View style={styles.tableRow}>
									<Text style={[styles.tableColumn, styles.tableCell, styles.textBold]}>NPA/NXX</Text>
									<Text style={[styles.tableColumn, styles.tableCell, styles.textNormal]}>{props.quoteData?.la_npanxx}</Text>
									<Text style={[styles.tableColumn, styles.tableCell, styles.textBold]}>NPA/NXX</Text>
									<Text style={[styles.tableColumn, styles.tableCell, styles.textNormal]}>{props.quoteData?.lz_npanxx}</Text>
								</View>
								{props.quoteData?.product_id === '22' ? // E_ACCESS_NNI
									<View style={styles.tableRow}>
										<Text style={[styles.tableColumn, styles.tableCell, styles.textBold]}>ENNI Extension</Text>
										<Text style={[styles.tableColumn, styles.tableCell, styles.textNormal]}>{props.quoteData?.enni_ext}</Text>
										<Text style={[styles.tableColumn, styles.tableCell, styles.textBold]}></Text>
										<Text style={[styles.tableColumn, styles.tableCell, styles.textNormal]}></Text>
									</View>
									: null}
							</View>
						</View>
					</>
					:
					<>
						<View wrap={false}>
							<View style={styles.headerBarTopMargin}>
								<Text style={styles.headerBarText}>Details</Text>
							</View>
							<View style={styles.table}>
								{props.quoteData?.exist_circuit?.length > 0 ?
									<View style={styles.tableRow}>
										<Text style={[styles.tableColumn, styles.tableCell, styles.textBold]}>Circuit ID</Text>
										<Text style={[styles.tableColumn, styles.tableCell, styles.textNormal]}>{props.quoteData?.exist_circuit}</Text>
									</View>
									: null}
								{props.quoteData?.local_access_option?.length > 0 ?
									<View style={styles.tableRow}>
										<Text style={[styles.tableColumn, styles.tableCell, styles.textBold]}>Local Access Options</Text>
										<Text style={[styles.tableColumn, styles.tableCell, styles.textNormal]}>{props.quoteData?.local_access_option}</Text>
									</View>
									: null}
								{props.quoteData?.product_id === '10' || props.quoteData?.product_id === '11' ||
									props.quoteData?.product_id === '13' || props.quoteData?.product_id === '23' ||
									props.quoteData?.product_id === '24' ? // DIA POP, DIA Prem, MPLS + Internet, SDWAN, Wholesale BB
									<View style={styles.tableRow}>
										<Text style={[styles.tableColumn, styles.tableCell, styles.textBold]}>IPs</Text>
										<Text style={[styles.tableColumn, styles.tableCell, styles.textNormal]}>{props.quoteData?.ips}</Text>
									</View>
									: null}
								{props.quoteData?.vendor_exclusions?.length > 0 ?
									<View style={styles.tableRow}>
										<Text style={[styles.tableColumn, styles.tableCell, styles.textBold]}>Vendor Exclusions</Text>
										<Text style={[styles.tableColumn, styles.tableCell, styles.textNormal]}>{props.quoteData?.vendor_exclusions}</Text>
									</View>
									: null}
								{props.quoteData?.product_id === '20' || props.quoteData?.la_existing_uni?.length > 0 ? // E-Access
									<View style={styles.tableRow}>
										<Text style={[styles.tableColumn, styles.tableCell, styles.textBold]}>Loc A Existing UNI</Text>
										<Text style={[styles.tableColumn, styles.tableCell, styles.textNormal]}>{props.quoteData?.la_existing_uni}</Text>
									</View>
									: null}
								{props.quoteData?.product_id === '20' ? // E-Access
									<View style={styles.tableRow}>
										<Text style={[styles.tableColumn, styles.tableCell, styles.textBold]}>COS Needed</Text>
										<Text style={[styles.tableColumn, styles.tableCell, styles.textNormal]}>{props.quoteData?.cos_needed}</Text>
									</View>
									: null}
								{props.quoteData?.access_type?.length > 0 && (props.quoteData?.product_id === '11' || props.quoteData?.product_id === '13' ||
									props.quoteData?.product_id === '20' || props.quoteData?.product_id === '24' ||
									props.quoteData?.product_id === '25') ? // DIA Prem || MPLS_INTERNET || E-Access || SDWAN || DYIP
									<View style={styles.tableRow}>
										<Text style={[styles.tableColumn, styles.tableCell, styles.textBold]}>Access Type</Text>
										<Text style={[styles.tableColumn, styles.tableCell, styles.textNormal]}>{props.quoteData?.access_type}</Text>
									</View>
									: null}
								{props.quoteData?.product_id !== '10' ? // DIA POP
									<>
										{props.quoteData?.la_ratecenter?.length > 0 ?
											<View style={styles.tableRow}>
												<Text style={[styles.tableColumn, styles.tableCell, styles.textBold]}>Rate Center</Text>
												<Text style={[styles.tableColumn, styles.tableCell, styles.textNormal]}>{props.quoteData?.la_ratecenter}</Text>
											</View>
											: null}
										{props.quoteData?.la_clli?.length > 0 ?
											<View style={styles.tableRow}>
												<Text style={[styles.tableColumn, styles.tableCell, styles.textBold]}>CSWC</Text>
												<Text style={[styles.tableColumn, styles.tableCell, styles.textNormal]}>{props.quoteData?.la_clli}</Text>
											</View>
											: null}
										{props.quoteData?.la_lata?.length > 0 ?
											<View style={styles.tableRow}>
												<Text style={[styles.tableColumn, styles.tableCell, styles.textBold]}>LATA</Text>
												<Text style={[styles.tableColumn, styles.tableCell, styles.textNormal]}>{props.quoteData?.la_lata}</Text>
											</View>
											: null}
										{props.quoteData?.la_npanxx?.length > 0 ?
											<View style={styles.tableRow}>
												<Text style={[styles.tableColumn, styles.tableCell, styles.textBold]}>NPA/NXX</Text>
												<Text style={[styles.tableColumn, styles.tableCell, styles.textNormal]}>{props.quoteData?.la_npanxx}</Text>
											</View>
											: null}
									</>
									: null}
								{props.quoteData?.cpe_type?.length > 0 ?
									<View style={styles.tableRow}>
										<Text style={[styles.tableColumn, styles.tableCell, styles.textBold]}>CPE Provider</Text>
										<Text style={[styles.tableColumn, styles.tableCell, styles.textNormal]}>{props.quoteData?.cpe_type}</Text>
									</View>
									: null}
								{props.quoteData?.router_service?.length > 0 ?
									<View style={styles.tableRow}>
										<Text style={[styles.tableColumn, styles.tableCell, styles.textBold]}>Router Service</Text>
										<Text style={[styles.tableColumn, styles.tableCell, styles.textNormal]}>{props.quoteData?.router_service}</Text>
									</View>
									: null}
								{props.quoteData?.product_id === '23' ? // Broadband
									<View style={styles.tableRow}>
										<Text style={[styles.tableColumn, styles.tableCell, styles.textBold]}>Tier</Text>
										<Text style={[styles.tableColumn, styles.tableCell, styles.textNormal]}>{props.quoteData?.tier}</Text>
									</View>
									: null}
								{props.quoteData?.product_id === '22' ? // E_ACCESS_NNI
									<View style={styles.tableRow}>
										<Text style={[styles.tableColumn, styles.tableCell, styles.textBold]}>ENNI Extension</Text>
										<Text style={[styles.tableColumn, styles.tableCell, styles.textNormal]}>{props.quoteData?.enni_ext}</Text>
									</View>
									: null}
								{props.quoteData?.product_id === '15' ? // COLOCATION
									<>
										<View style={styles.tableRow}>
											<Text style={[styles.tableColumn, styles.tableCell, styles.textBold]}>Rack Space</Text>
											<Text style={[styles.tableColumn, styles.tableCell, styles.textNormal]}>{props.quoteData?.rack_space}</Text>
										</View>
										<View style={styles.tableRow}>
											<Text style={[styles.tableColumn, styles.tableCell, styles.textBold]}>Primary Power</Text>
											<Text style={[styles.tableColumn, styles.tableCell, styles.textNormal]}>{props.quoteData?.primary_power}</Text>
										</View>
										<View style={styles.tableRow}>
											<Text style={[styles.tableColumn, styles.tableCell, styles.textBold]}>Breaker Amperage</Text>
											<Text style={[styles.tableColumn, styles.tableCell, styles.textNormal]}>{props.quoteData?.breaker_amp}</Text>
										</View>
										<View style={styles.tableRow}>
											<Text style={[styles.tableColumn, styles.tableCell, styles.textBold]}>Secondary Power</Text>
											<Text style={[styles.tableColumn, styles.tableCell, styles.textNormal]}>{props.quoteData?.secondary_pwr}</Text>
										</View>
									</>
									: null}
							</View>
						</View>
					</>
				}
				<View wrap={false}>
					<View style={styles.headerBarTopMargin}>
						<Text style={styles.headerBarText}>Customer Contact</Text>
					</View>
					<View style={styles.table}>
						<View style={styles.tableRow}>
							<Text style={[styles.tableColumn, styles.tableCell, styles.textBold]}>Customer Contact</Text>
							<Text style={[styles.tableColumn, styles.tableCell, styles.textNormal]}>{props.quoteData?.cp_contact}</Text>
						</View>
						<View style={styles.tableRow}>
							<Text style={[styles.tableColumn, styles.tableCell, styles.textBold]}>Customer Phone</Text>
							<Text style={[styles.tableColumn, styles.tableCell, styles.textNormal]}>{props.quoteData?.cp_phone}</Text>
						</View>
						<View style={styles.tableRow}>
							<Text style={[styles.tableColumn, styles.tableCell, styles.textBold]}>Customer Email</Text>
							<Text style={[styles.tableColumn, styles.tableCell, styles.textNormal]}>{props.quoteData?.cp_email}</Text>
						</View>
					</View>
				</View>
				<View wrap={false}>
					<View style={styles.headerBarTopMargin}>
						<Text style={styles.headerBarText}>Notices</Text>
					</View>
					<View style={styles.table}>
						<View style={styles.tableRow}>
							<Text style={[styles.tableCell, styles.textNormal]}>
								This Service Quote expires 90 days from the date hereof. This document is for discussion purposes only; it is not a legal offer.
							</Text>
						</View>
						<View style={styles.tableRowBorderBottom}>
							<Text style={[styles.tableCell, styles.textNormal]}>
								Pricing is for BUDGETARY purposes only. Service Inquiry/site survey will be required to provide firm pricing and availability. If firm pricing and availability are required please reply, and request a formal Service Inquiry. Please allow up to 10 business days for Service Inquiries to complete.
							</Text>
						</View>
					</View>
				</View>

			</Page >
		</Document >
	);
}

export default QuotePDF;