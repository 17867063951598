import {useCallback, useEffect, useState, useMemo} from 'react';
import {connect, useSelector} from 'react-redux';
import style from './MACD.module.css';
import QuoteHelper from '../../services/QuoteService';
import OrderHelper from '../../services/OrderService';
import {
	setMACDACNA,
	setMACDCustomerCircuit,
	setMACDCustomerPon,
    setMACDLeaseNetID,
    setMACDCircuitID,
	setMACDNewPrimaryContact,
	setMACDNewTechContact,
	setMACDPrimaryContact,
	setMACDPrimaryEmail,
	setMACDPrimaryPhone,
	setMACDTechContact,
	setMACDTechEmail,
	setMACDTechPhone
} from '../../redux';
import Loader from '../shared/Loader';
import {ConsoleLogger} from "../../logger/ConsoleLogger";
import HTTPErrorHandler from "../errorPages/HTTPErrorHandler";

const MACDCustomerInfo = (props) => {
    const [hasError, setHasError] = useState(null);
    const logger = useMemo(() => new ConsoleLogger({ level: process.env.REACT_APP_LOGGER_LEVEL, }), []);

    //State Variables
    const [contactOptions, setContactOptions] = useState([]);
    const [newPrimaryContactFlag, setNewPrimaryContactFlag] = useState(false);
    const [newTechContactFlag, setNewTechContactFlag] = useState(false);
    const [acnaOptions, setAcnaOptions] = useState([]);
    const [loader, setLoader] = useState(false);

    //Redux Store Variables
    const selectedMACDContacts = useSelector(state => state?.MACDOrder.CONTACTS);
    const selectedMACDGeneralInfo = useSelector(state => state?.MACDOrder.GeneralOrderInformation);
    const selectedMACDProduct = useSelector(state => state?.MACDOrder.selectedProduct.productId);
	const selectedActivityRequest = useSelector(state => state?.MACDOrder.selectedActivityRequest);
    const selectedActivityType = useSelector(state => state?.MACDOrder.selectedActivityType.name);

    const getContactOptions = useCallback(() => {
        setLoader(true);
        QuoteHelper.getQuoteContact().then(response => {
            logger.info(`Contact Data: ${JSON.stringify(response.data)}`);
            setContactOptions(response.data);
            setLoader(false);
        }).catch(error => {
            logger.error("Error Getting Contacts: " + error);
            setHasError(error);
            setLoader(false)
        })
    },[logger])

    const autoFillContact = (contactValue, type) => {
        setLoader(true);
        QuoteHelper.getQuoteContactInfo(contactValue).then(resp => {
            if (type === "orig_contact") {
                props.PRIMARY_AUTHORIZING_PHONE(resp.data.phone);
                props.PRIMARY_AUTHORIZING_EMAIL(resp.data.email);
            } else {
                props.DATA_TECHNICAL_PHONE(resp.data.phone);
                props.DATA_TECHNICAL_EMAIL(resp.data.email);
            }
            setLoader(false);
        }).catch(error => {
            logger.error("Could Not AutoFill Contact: " + error);
            setHasError(error);
            setLoader(false);
        })

    }

    const getOrderACNAOptions = useCallback(() => {
        setLoader(true);
        OrderHelper.getOrderACNA().then(resp => {
            logger.info(encodeURIComponent(resp.data).replace(/%20/g, " "));
            setAcnaOptions(resp.data);
            //autoselect option if only 1 acna
            if (resp.data?.length === 1) {
                props.ACNA(resp.data[0].id);
            }
            setLoader(false);
        }).catch(error => {
            logger.error("Getting ACNA Options " + error);
            setHasError(error);
            setLoader(false);
        })
    },[logger, props])

    useEffect(() => {
        getContactOptions();
        getOrderACNAOptions();
    }, [getContactOptions, getOrderACNAOptions])

	const contactHandler = (ev) => {
		let contact = ev.target.value;

		if (contact === 'new') {
			if (ev.target.id === "orig_contact") {
				props.PRIMARY_AUTHORIZING_CONTACT(ev.target.value);
				setNewPrimaryContactFlag(true);
			} else {
				props.DATA_TECHNICAL_CONTACT(ev.target.value);
				setNewTechContactFlag(true);
			}
		} else {
			if (ev.target.id === "orig_contact") {
				props.PRIMARY_AUTHORIZING_CONTACT(ev.target.value);
				autoFillContact(contact, "orig_contact");
			} else {
				props.DATA_TECHNICAL_CONTACT(ev.target.value);
				autoFillContact(contact, "tech_contact");

			}
		}
	}

	//Handler to create a new contact and add it to the redux store
	const newContactHandler = (ev) => {
		switch (ev.target.id) {
			case "new_orig_contact":
				props.NEW_PRIMARY_AUTHORIZING_CONTACT(ev.target.value);
				break;
			case "new_tech_contact":
				props.NEW_DATA_TECHNICAL_CONTACT(ev.target.value);
				break;
			default:
				break;
		}
	}
    const inputHandler = (ev) => {
		switch (ev.target.id) {
			case "cust_pon":
				props.CUSTOMER_PON(ev.target.value);
				break;
			case "cust_circuit":
				props.CUSTOMER_CIRCUIT(ev.target.value);
				break;
			case "acna":
				props.ACNA(ev.target.value);
				break;
            case "leasenet_id":
                props.LEASENET_ID(ev.target.value);
                break;
            case "circuit_id":
                props.CIRCUIT_ID(ev.target.value);
                break;
			case "orig_phone":
				props.PRIMARY_AUTHORIZING_PHONE(ev.target.value);
				break;
			case "orig_email":
				props.PRIMARY_AUTHORIZING_EMAIL(ev.target.value);
				break;
			case "tech_phone":
				props.DATA_TECHNICAL_PHONE(ev.target.value);
				break;
			case "tech_email":
				props.DATA_TECHNICAL_EMAIL(ev.target.value);
				break;
			default:
				break;
		}
	}



    return (
            <>
            {hasError && <HTTPErrorHandler error={hasError}></HTTPErrorHandler>}
            {loader ? <Loader/> : null}
            {!hasError && (<><div className={style.section1}>
            <div className={`sub-hdr alt0 ${style.subHdrAlter} `}>
                <div className="nib">
                    <div className="the-notch">
                        <div className="notch-fg">
                            <svg>
                                <path
                                    d="M35.81,26.28l-13,10v-5C-10.47,28.68-1,9.16,11.79,0c0,7.28,0,9.13,0,13.28s3.18,7.92,11,9v-5Z"></path>
                            </svg>
                        </div>
                    </div>
                </div>
                <h2 className={` ${style.sectionTitle} m-0 `}>Contacts</h2>
            </div>

            <div className="sub-section">
                <div className={` row ${style.rowGrp}`}>
                    <div className={` col-12 col-sm-6 col-md-4 col-lg-4 ${style.colGrp}`}>
                        <label className={style.labelText}>Primary Authorizing Contact<span className={style.mandatory}>*</span></label>
                        {newPrimaryContactFlag || selectedMACDContacts.PRIMARY_AUTHORIZING_CONTACT === "new"  ?
                            <input type='text' className={style.inputFld} maxLength='108' id='new_orig_contact' name='new_orig_contact' onChange={newContactHandler} value={selectedMACDContacts.NEW_PRIMARY_AUTHORIZING_CONTACT}></input>
                            :
                            <select className={style.selectFld} onChange={contactHandler} id='orig_contact' name='orig_contact' value={selectedMACDContacts.PRIMARY_AUTHORIZING_CONTACT}>
                                {contactOptions.map((obj) => {
                                    return <option value={obj.id}>{obj.name}</option>
                                })}
                            </select>}
                    </div>
                    <div className={` col-12 col-sm-6 col-md-4 col-lg-4 ${style.colGrp}`}>
                        <label className={style.labelText}>Primary Authorizing Phone<span className={style.mandatory}>*</span></label>
                        <input type='text' className={style.inputFld} maxLength='10' id='orig_phone' name='orig_phone' onChange={inputHandler} value={selectedMACDContacts.PRIMARY_AUTHORIZING_PHONE}></input>
                    </div>
                    <div className={` col-12 col-sm-6 col-md-4 col-lg-4 ${style.colGrp}`}>
                        <label className={style.labelText}>Primary Authorizing Email<span className={style.mandatory}>*</span></label>
                        <input type='text' className={style.inputFld} maxLength='118' id='orig_email' name='orig_email' onChange={inputHandler} value={selectedMACDContacts.PRIMARY_AUTHORIZING_EMAIL} ></input>
                    </div>
                    <div className={` col-12 col-sm-6 col-md-4 col-lg-4 ${style.colGrp}`}>
                        <label className={style.labelText}>Data Technical Contact<span className={style.mandatory}>*</span></label>
                        {newTechContactFlag || selectedMACDContacts.DATA_TECHNICAL_CONTACT === "new" ?
                            <input type='text' className={style.inputFld} maxLength='108' id='new_tech_contact' name='new_tech_contact' onChange={newContactHandler} value={selectedMACDContacts.NEW_DATA_TECHNICAL_CONTACT}></input>
                            :
                            <select className={style.selectFld} id='tech_contact' name='tech_contact' onChange={contactHandler} value={selectedMACDContacts.DATA_TECHNICAL_CONTACT}>
                                {contactOptions.map((obj) => {
                                    return <option value={obj.id}>{obj.name}</option>
                                })}
                            </select>}
                    </div>
                    <div className={` col-12 col-sm-6 col-md-4 col-lg-4 ${style.colGrp}`}>
                        <label className={style.labelText}>Data Technical Phone<span className={style.mandatory}>*</span></label>
                        <input type='text' className={style.inputFld} maxLength='10' id='tech_phone' name='tech_phone' onChange={inputHandler} value={selectedMACDContacts.DATA_TECHNICAL_PHONE}></input>
                    </div>
                    <div className={` col-12 col-sm-6 col-md-4 col-lg-4 ${style.colGrp}`}>
                        <label className={style.labelText}>Data Technical Email<span className={style.mandatory}>*</span></label>
                        <input type='text' className={style.inputFld} maxLength='118' id='tech_email' name='tech_email' onChange={inputHandler} value={selectedMACDContacts.DATA_TECHNICAL_EMAIL}></input>
                    </div>
                </div>
            </div></div>
            <div className={style.dividers}></div>
            <div className={style.section1}>
                <div className={`sub-hdr alt1 ${style.subHdrAlter} `}>
                    <div className="nib">
                        <div className="the-notch">
                            <div className="notch-fg">
                                <svg>
                                    <path
                                        d="M35.81,26.28l-13,10v-5C-10.47,28.68-1,9.16,11.79,0c0,7.28,0,9.13,0,13.28s3.18,7.92,11,9v-5Z"></path>
                                </svg>
                            </div>
                        </div>
                    </div>
                    <h2 className={` ${style.sectionTitle} m-0 `}>General Order Information</h2>
                </div>

                <div className="sub-section">
                    <div className={` row ${style.rowGrp}`}>
                        <div className={` col-12 col-sm-6 col-md-4 col-lg-4 ${style.colGrp}`}>
                            <label className={style.labelText}>Customer Pon<span className={style.mandatory}>*</span></label>
                            <input type='text' className={style.inputFld} maxLength='16' id='cust_pon' name='cust_pon' onChange={inputHandler} value={selectedMACDGeneralInfo.CUSTOMER_PON}></input>
                        </div>
                        <div className={` col-12 col-sm-6 col-md-4 col-lg-4 ${style.colGrp}`}>
                            <label className={style.labelText}>Customer Circuit</label>
                            <input type='text' className={style.inputFld} maxLength='64' id='cust_circuit' name='cust_circuit' onChange={inputHandler} value={selectedMACDGeneralInfo.CUSTOMER_CIRCUIT}></input>
                        </div>
                        <div className={` col-12 col-sm-6 col-md-4 col-lg-4 ${style.colGrp}`}>
                            <label className={style.labelText}>ACNA<span className={style.mandatory}>*</span></label>
                            <select className={style.selectFld} id='acna' name='acna' onChange={inputHandler} value={selectedMACDGeneralInfo.ACNA}>
                                {acnaOptions.map((obj) => {
                                    return <option value={obj.id}>{obj.name}</option>
                                })}
                            </select>
                        </div>
                        {(selectedActivityRequest === "Disconnect" && selectedMACDProduct === 15) || selectedActivityType === "Cross-Connect" ?
                            <>
                                <div className={` col-12 col-sm-6 col-md-4 col-lg-4 ${style.colGrp}`}>
                                    <label className={style.labelText}>Circuit ID</label>
                                    <input type='text' className={style.inputFld} id='circuit_id' name='circuit_id' onChange={inputHandler} value={selectedMACDGeneralInfo.CIRCUIT_ID}></input>
                                </div>
                                <div className={` col-12 col-sm-6 col-md-4 col-lg-4 ${style.colGrp}`}>
                                    <label className={style.labelText}>LeaseNet Id</label>
                                    <input type='text' className={style.inputFld} maxLength='15' id='leasenet_id' name='leasenet_id' onChange={inputHandler} value={selectedMACDGeneralInfo.LEASENET_ID}></input>
                                </div>
                            </> : null
                        }
                    </div>
                </div>
            </div>
            <div className={style.dividers}></div>
            </>)}
        </>
    )

}

const mapDispatchToProps = {
    PRIMARY_AUTHORIZING_CONTACT: setMACDPrimaryContact,
    NEW_PRIMARY_AUTHORIZING_CONTACT: setMACDNewPrimaryContact,
    PRIMARY_AUTHORIZING_PHONE: setMACDPrimaryPhone,
    PRIMARY_AUTHORIZING_EMAIL: setMACDPrimaryEmail,
    DATA_TECHNICAL_CONTACT: setMACDTechContact,
    NEW_DATA_TECHNICAL_CONTACT: setMACDNewTechContact,
    DATA_TECHNICAL_PHONE: setMACDTechPhone,
    DATA_TECHNICAL_EMAIL: setMACDTechEmail,
    CUSTOMER_PON: setMACDCustomerPon,
    CUSTOMER_CIRCUIT: setMACDCustomerCircuit,
    ACNA: setMACDACNA,
    LEASENET_ID: setMACDLeaseNetID,
    CIRCUIT_ID: setMACDCircuitID
}

export default connect(null, mapDispatchToProps)(MACDCustomerInfo);
