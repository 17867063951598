import { useState } from 'react';
import { NavLink, Redirect } from 'react-router-dom';
import HomeData from '../../../services/HomeServices';
import Loader from '../../shared/Loader';

const TabsQuote = () => {
	const [refIdVal, setRefIdVal] = useState("");
	const [searchByIdError, setSearchByIdError] = useState("");
	const [quoteRedirect, setQuoteRedirect] = useState(false);
	const [orderRedirect, setOrderRedirect] = useState(false);
	const [orderCreateRedirect, setOrderCreateRedirect] = useState(false);
	const [loader, setLoader] = useState(false);
	const isEmployee = localStorage.getItem("isEmployeeLoggedIn");

	const searchHandler = async (ev) => {
		setRefIdVal(ev.target.value);
		setSearchByIdError("");
	}

	/**************************************************************************
	Function to validate the ref id entered before redirecting to Quote / Order View
	***************************************************************************/
	const searchId = (ev) => {
		ev.preventDefault();
		setSearchByIdError(null);
		setLoader(true);
		if (refIdVal?.length === 0) {
			setSearchByIdError('Windstream Reference ID required.');
			setLoader(false);
		} else {
			HomeData.validateId('quote', refIdVal).then((resp) => {
				console.log('RESPONSE from validateId search:' + JSON.stringify(resp.data));
				if (resp.data.result === 'SUCCESS') {
					setRefIdVal(resp.data.refId);
					if (resp.data.type === 'quote') {
						setRefIdVal(resp.data.refId);
						setQuoteRedirect(true);
					} else if (resp.data.type === 'order_create') {
						setOrderCreateRedirect(true);
					} else {
						setOrderRedirect(true);
					}
				} else if (resp.data.result === 'FAIL') {
					setSearchByIdError(resp.data.error);
				} else {
					setSearchByIdError("No Quote or Order found.");
				}
				setLoader(false);
			});
		}
	}

	return (
		<>
			{loader ? <Loader /> : null}
			<div className="tab-nav cf d-flex align-items-bottom">
				<NavLink className="tab-opt" to="/Quote/Summary" activeClassName="active">
					<span>Summary</span>
				</NavLink>
				<NavLink className="tab-opt" to="/Quote/Create" activeClassName="active">
					<span>Create</span>
				</NavLink>
				<NavLink className="tab-opt" to="/Quote/RouteCreator" activeClassName="active">
					<span>Route Creator</span>
				</NavLink>
				<NavLink className="tab-opt" to="/Quote/Bulk/Create" activeClassName="active">
					<span>Bulk</span>
				</NavLink>
				<NavLink className="tab-opt" to="/Quote/Bulk/Summary" activeClassName="active">
					<span>Bulk Summary</span>
				</NavLink>
				{isEmployee ?
					<NavLink className="tab-opt" to="/Quote/Renewal/Create" activeClassName="active">
						<span>Renewals</span>
					</NavLink> : null
				}
				<div className="input-field dark">
					<input type='text' name="quoteRefId" id='quoteRefId' onChange={searchHandler} placeholder='Quick Search by ID' />
					{searchByIdError?.length > 0 ?
						<p className='text-danger small'>{searchByIdError}</p> : null
					}
				</div>
				<button className="quote-summary-search border-0" onClick={searchId}></button>
			</div>
			{quoteRedirect ?
				<Redirect to={`/Quote/View/${refIdVal}`} /> : null
			}
			{orderRedirect ?
				<Redirect to={`/Order/View/${refIdVal}`} /> : null
			}
			{orderCreateRedirect ?
				<Redirect to={`/Order/Create/${refIdVal}`} /> : null
			}
		</>
	);
}

export default TabsQuote;
