import axios from "./axios/customAxios";

const fetchMACDProducts = async () => {
    const macdMatrixUrl = `${process.env.REACT_APP_API_URL}/getMacdMatrix.php`;
	return await axios.get(macdMatrixUrl);
}

const fetchMACDActivityTypes = async () => {
	const macdActivityTypesUrl = `${process.env.REACT_APP_API_URL}/getMacdActivityTypes.php`;
	return await axios.get(macdActivityTypesUrl);
}

const getAccountProfile = async () => {
    const macdMPIDURL = `${process.env.REACT_APP_API_URL}/getAccountProfile.php`;
    return await axios.get(macdMPIDURL);
}


const getMACDCircuitValues = async(circuitId) => {
	const circuitValuesURL = `${process.env.REACT_APP_API_URL}/TroubleTickets.php?action=circuitAddress&circuitId=${circuitId}`;
    return await axios.get(circuitValuesURL);
}

const getMACDCircuitTypes = async (product) => {
    const macdCircuitTypeURL = `${process.env.REACT_APP_API_URL}/getOrderMenus.php?type=macdCircuitType&product=${product}`;
    return await axios.get(macdCircuitTypeURL)
}

const getMACDBandwidths = async (product, circuit) => {
    const macdBandwidthsURL = `${process.env.REACT_APP_API_URL}/getBandwidths.php?menu=bw&product=${product}&circuit=${circuit}&macd=${true}`;
    return await axios.get(macdBandwidthsURL);
}

const getMACDHandoffs = async (circuit,bandwidth, num_routes) => {
    const macdHandoffURL =  `${process.env.REACT_APP_API_URL}/getOrderMenus.php?type=handoff&circuit=${circuit}&band=${bandwidth}&num_routes=${num_routes}&macd=${1}`;
    return await axios.get(macdHandoffURL)
}

const getDarkFiberPricing = async (macd, qty, testType, direction, numRoutes, spliceArray, fiberArray) => {
    const macdDarkFiberURL = `${process.env.REACT_APP_API_URL}/getLeaseFee.php?macd=${macd}&qty=${qty}&testType=${testType}&direction=${direction}&numRoutes=${numRoutes}&spliceArray=${JSON.stringify(Object.values(spliceArray))}&fiberArray=${JSON.stringify(Object.values(fiberArray))}`;
    return await axios.get(macdDarkFiberURL);
}

const getDefaultServiceDate = async (product) => {
    const macdDefaultDateURL =  `${process.env.REACT_APP_API_URL}/getOrderMenus.php?type=macdDefaultDate&product=${product}`;
    return await axios.get(macdDefaultDateURL);
}

const createMACD = async (formData, macdData) => {
	return await axios({
		method: "post",
		url: `${process.env.REACT_APP_API_URL}/orderACDsubmit.php?data=${encodeURIComponent(JSON.stringify(macdData))}`,
		data: formData,
		headers: { "Content-Type": "multipart/form-data" },
	});
}

const MACDHelper = {
    fetchMACDProducts,
    fetchMACDActivityTypes,
    getAccountProfile,
    getMACDCircuitValues,
    getMACDCircuitTypes,
    getMACDBandwidths,
    getMACDHandoffs,
    getDarkFiberPricing,
    getDefaultServiceDate,
    createMACD
}

export default MACDHelper;
