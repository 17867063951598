

import style from "./QuoteCreate.module.css";
import { useCallback, useEffect, useState, useMemo } from "react";
import { connect, useSelector } from "react-redux";
import PremAddress from "./PremAddress";
import QuoteLocZ from "./QuoteLocZ";
import WaveHelper from "../../services/WaveService";
import Select from 'react-select';
import Loader from "../shared/Loader";
import Term from './Term';
import {
	setQuoteAddBW1,
	setQuoteAddressA,
	setQuoteAddressZ,
	setQuoteBW,
	setQuoteKmzFile,
	setQuoteQty
} from "../../redux/actions/quoteActions";
import { ConsoleLogger } from "../../logger/ConsoleLogger";
import HTTPErrorHandler from "../errorPages/HTTPErrorHandler";

const ManagedSpectrum = (props) => {
	const logger = useMemo(() => new ConsoleLogger({ level: process.env.REACT_APP_LOGGER_LEVEL, }),[]);
	const [hasError, setHasError] = useState(null);
	const [popLocationAOptions, setPopLocationAOptions] = useState([]);
	const [popLocationZOptions, setPopLocationZOptions] = useState([]);
	const [bandwidthAdded, setBandwidthAdded] = useState(false);
	const [bandwidthCount, setBandwidthCount] = useState(0);
	const [loader, setLoader] = useState(false);
	const [popUpMessage, setPopUpMessage] = useState("");
	const [popUpShow, setPopUpShow] = useState(false);
	const selectedManagedSpectrumValues = useSelector(state => state.quote.quoteValues);

	//Managed Spectrum uses the same pop list as wave
	const getWavePOPLocation = useCallback(() => {
		if (props.stepCode === 2) {
			let popListA = null;
			let popListZ = null;
			let str = '';
			setLoader(true);
			WaveHelper.getPOPLocations().then(response => {
				logger.info(response.data);
				popListA = response.data.locA;
				popListZ = response.data.locZ;
				popListA = popListA.map(item => {
					str = `${item.name}`;
					return { value: item.id, label: str }
				});
				popListZ = popListZ.map(item => {
					str = `${item.name}`;
					return { value: item.id, label: str }
				});
				setPopLocationAOptions(popListA);
				setPopLocationZOptions(popListZ);
				setLoader(false);
			}).catch(error => {
				logger.error("Getting Managed Spectrum Pop Locations: " + error);
				setHasError(error);
			})
		}
	},[logger, props.stepCode])

	useEffect(() => {
		getWavePOPLocation();
	}, [getWavePOPLocation]);

	useEffect(() => {
		if (selectedManagedSpectrumValues.bw1 !== "") {
			setBandwidthCount(1);
		}
	}, [selectedManagedSpectrumValues.bw1])

	const findPopLoc = (e, type) => {
		logger.info(e);
		let popVal = '';
		if (!e) {
			popVal = ''
		} else {
			popVal = e;
		}

		if (type === 'locA') {
			props.updateManagedSpectrumPopAddressA(popVal);
		} else {
			props.updateManagedSpectrumPopAddressZ(popVal);
		}
	}

	const addBandwidth = () => {
		setBandwidthAdded(!bandwidthAdded);
		setBandwidthCount(bandwidthCount < 3 ? bandwidthCount + 1 : bandwidthCount);
	}

	const removeBandwidth = () => {
		let counter = bandwidthCount - 1;
		setBandwidthCount(bandwidthCount - 1);
		if (counter === 0) {
			props.updateManagedSpectrumAddBw1("");
		}
	}

	const bandwidthHandler = (ev) => {
		switch (ev.target.id) {
			case "bw":
				props.updateManagedSpectrumBw(ev.target.value);
				break;
			case "bw1":
				props.updateManagedSpectrumAddBw1(ev.target.value);
				break;
			default:
				break;
		}
	}

	const optionHandler = (ev) => {
		if (ev.target.id === "kmz_file") {
			props.updateManagedSpectrumKmzFile(ev.target.checked);
		}
		if (ev.target.checked === true) {
			if (ev.target.id === "kmz_file") {
				setPopUpMessage("ICB is required when 'Request Route KMZ File' is checked");
				setPopUpShow(true);
			}
		}
	}

	// ADO-649371 Keep "Other Location" as option in search list
	const customFilter = (option, searchText) => {
		if (option.value.toString().toLowerCase().includes("other") ||
			option.label.toString().toLowerCase().includes(searchText.toString().toLowerCase()) ||
			option.value.toString().toLowerCase().includes(searchText.toString().toLowerCase())
		) {
			return true;
		} else {
			return false;
		}
	}

	return (
		<>
			{hasError && <HTTPErrorHandler error={hasError}></HTTPErrorHandler>}
			{loader ? <Loader /> : null}
			{!hasError && (<>{props.stepCode === 2 ?
				<>
					<div className={`row ${style.rowGrp}`}>
						<div className="col-sm-6">
							<label className={style.labelSelectRequiredFld}>POP Location<span className={style.mandatory}>*</span> </label>
							<Select
								value={{ value: selectedManagedSpectrumValues.coAddA.value, label: selectedManagedSpectrumValues.coAddA.label }}
								onChange={(e) => { findPopLoc(e, 'locA') }}
								options={popLocationAOptions.map((item) => {
									return { value: item.value, label: item.label }
								})}
								filterOption={customFilter}
								isSearchable
								isClearable
								noOptionsMessage={() => 'Loading POP Locations'}
							/>
						</div>
					</div>

					{/*if other is selected than take the user input*/}
					{selectedManagedSpectrumValues.coAddA.value === "Other" ?
						<PremAddress /> : null
					}


					<div className={style.dividers}></div>

					<div className={` sub-hdr alt1 ${style.subHeader} `}>
						<div className="nib">
							<div className="the-notch">
								<div className="notch-fg"><svg><path d="M35.81,26.28l-13,10v-5C-10.47,28.68-1,9.16,11.79,0c0,7.28,0,9.13,0,13.28s3.18,7.92,11,9v-5Z"></path></svg></div>
							</div>
						</div>
						<h2 className={style.sectionTitle}>Location Z</h2>
					</div>

					<div className={`row ${style.rowGrp}`}>
						<div className="col-sm-6">
							<label className={style.labelSelectRequiredFld}>POP Location<span className={style.mandatory}>*</span> </label>
							<Select
								value={{ value: selectedManagedSpectrumValues.coAddZ.value, label: selectedManagedSpectrumValues.coAddZ.label }}
								onChange={(e) => { findPopLoc(e, 'locZ') }}
								options={popLocationZOptions.map((item) => {
									return { value: item.value, label: item.label }
								})}
								filterOption={customFilter}
								maxMenuHeight={150}
								isSearchable
								isClearable
								noOptionsMessage={() => 'Loading POP Locations'}
							/>
						</div>
					</div>
					{/*if other is selected than take the user input*/}
					{selectedManagedSpectrumValues.coAddZ.value === "Other" ?
						<QuoteLocZ /> : null
					}
				</>
				: null}

				{(props.stepCode === 3) && (props.section === "term") ?
					<Term product={"MANAGED_SPECTRUM"} /> : null
				}

				{(props.stepCode === 3) && (props.section === "bandwidth") ?
					<>
						<div className={style.bandwidthWrap}>
							<div className={`row ${style.rowGrp} `}>
								<div className="col-6 col-sm-4 col-md-3">
									<label className={style.labelSelectRequiredFld}>Bandwidth <span
										className={style.mandatory}>*</span></label>
									<select className={` form-control  ${style.selectFld} ${style.requiredFld} `} id="bw" onChange={bandwidthHandler} value={selectedManagedSpectrumValues.bw}>
										<option>-</option>
										<option value="3">100 Ghz</option>
										<option value="4">112.5 Ghz</option>
									</select>
								</div>
								<div className="col-2 col-sm-2 col-md-2 align-self-center clearfix">
									<div className={`add-toggle ${style.actionBtn} ${bandwidthAdded ? '' : ''} `} onClick={addBandwidth}>
										<svg className="ico-plus"><path d="M13.1,24H10.89V13.1H0V10.89H10.89V0H13.1V10.89H24V13.1H13.1Z"></path></svg>
									</div>
								</div>
							</div>
						</div>
						{bandwidthCount >= 1 ?
							<div className={style.bandwidthWrap}>
								<div className={`row ${style.rowGrp} `}>
									<div className="col-6 col-sm-4 col-md-3">
										<label className={style.labelSelectRequiredFld}>Additional Bandwidth</label>
										<select className={` form-control  ${style.selectFld} ${style.requiredFld} `} id="bw1" onChange={bandwidthHandler} value={selectedManagedSpectrumValues.bw1}>
											<option>-</option>
											<option value="3">100 Ghz</option>
											<option value="4">112.5 Ghz</option>
										</select>
									</div>
									<div className={`col-2 col-sm-4 col-md-4 align-self-center clearfix ${style.svgWarp} `}>
										<div className={`add-toggle ${style.actionBtn} ${bandwidthAdded ? '' : ''} `}>
											<svg version="1.1" id="Layer_1" className="ico-plus" viewBox="0 0 511.99 511.99" onClick={removeBandwidth}>
												<path d="M8.27,222.43c165.13,0,330.25,0,495.73,0c0,21.3,0,42.35,0,64.01c-165.25,0-330.49,0-495.73,0 C8.27,265.1,8.27,243.76,8.27,222.43z"/>
											</svg>
										</div>
									</div>
								</div>
							</div> : null
						}
					</> : null
				}
				{(props.stepCode === 3) && (props.section === "productConfig") ?
					<>
						<div className="col-12 col-sm-4 col-md-3 mb-5">
							<label className={style.labelSelectRequiredFld}>Quantity <span className={style.mandatory}>*</span></label>
							<select className={` form-control  ${style.selectFld} ${style.requiredFld} `} id='qty' value={selectedManagedSpectrumValues.qty}>
								<option disabled>-</option>
								<option disabled value="1">1</option>
							</select>
						</div>
					</> : null
				}
				{(props.stepCode === 3) && (props.section === "Options") ?
					<>
						<div className={`row ${style.rowGrp} `}>
							<div className="col-3">
								<input type="checkbox" id="kmz_file" name="kmz_file" onChange={optionHandler} checked={selectedManagedSpectrumValues.kmz_file ? "checked" : ""} />
								<label className={` ${style.checkboxText} ${style.textTransformNone} ml-2`}>Request Route KMZ File</label>
								<div className={` ${style.infoTip} ${style.infoTipKMZ} `}>
									<img src="../../images/svg/ico-info-filled-dark-off.svg" alt="info tip" className={style.infoImg} />
									<p className={style.contentHover}>
										Provides details via Google Earth for requested wave locations.  Response times may vary.
									</p>
								</div>
							</div>
						</div>
					</>: null
				}
			</>)}
			{popUpShow ?
				<div>
					<div className={`${style.overly}`}> </div>
					<div className="modal fade show d-block">
						<div className={` modal-dialog modal-dialog-centered modal-md`}>
							<div className="modal-content">
								<div className="modal-body">
									{popUpMessage}
								</div>
								<div className="modal-footer d-block text-center">
									<button type="button" className="btn-style btn-theme" data-dismiss="modal" onClick={() => setPopUpShow(false)}>Ok</button>
									<button type="button" className="btn-style btn-cancel" data-dismiss="modal" onClick={() => setPopUpShow(false)}>Cancel</button>
								</div>
							</div>
						</div>
					</div>
				</div> : null
			}
		</>
	)

}

const mapDispatchToProps = {
	updateManagedSpectrumPopAddressA: setQuoteAddressA,
	updateManagedSpectrumPopAddressZ: setQuoteAddressZ,
	updateManagedSpectrumBw: setQuoteBW,
	updateManagedSpectrumAddBw1: setQuoteAddBW1,
	updateManagedSpectrumKmzFile: setQuoteKmzFile,
	updateManagedSpectrumQty: setQuoteQty
}

export default connect(null, mapDispatchToProps)(ManagedSpectrum);
