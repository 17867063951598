/**
 * Initializes the Pendo integration with the provided user and account information.
 * @param pendoUser
 * @param {string} type - The type of the VISITOR.
 */
export const initializePendo = (pendoUser, type) => {
    window.pendo.initialize({
        visitor: {
            id: pendoUser.userLogin, // Required if user is logged in, default creates anonymous ID
            type: type, name: pendoUser.name,
            userfullname: pendoUser.userFullName,
            role: getRole("Web User Admin", pendoUser.role.web) + ", " +
                getRole("Trouble Tickets", pendoUser.role.tickets) + ", " +
                getRole("Transport Quote", pendoUser.role.quote) + ", " +
                getRole("Transport Order", pendoUser.role.order) + ", " +
                getRole("Invoice System", pendoUser.role.invoice),// Multiple roles can be separated by commas?
            email: pendoUser.email,
        },

        account: {
            id: pendoUser.userLogin, // Required if using Pendo Feedback, default uses the value 'ACCOUNT-UNIQUE-ID'
            // id: pendoUser.commsGlobalAccountId,
            name: pendoUser.name,
            partnertype: pendoUser.partnerType.vertical + ", " +
                pendoUser.partnerType.ae + ", " +
                pendoUser.partnerType.cam + ", " +
                pendoUser.partnerType.type, // Required if using Pendo Feedback ", // Multiple partner types can be separated by commas?
            commsglobalaccountid: pendoUser.commsGlobalAccountId,
            globalaccountid: pendoUser.globalAccountId,
        },
    });
    // pendoUser.displayUserInfo();
};

const getRole = (role, type) => {
    switch (type) {
        case 0:
            return role + "|No Access";
        case 1:
            return role + "|Read Only";
        case 2:
            return role + "|Write Access";
        case 3:
            return role + "|Full Access";
        default:
            return role + "|No Access";
    }
}
