import axios from "./axios/customAxios";

const getAccountListData = async (isInvoice) => {
    const accountsURL = `${process.env.REACT_APP_API_URL}/getAccounts.php?isInvoice=${isInvoice}`;
    return await axios.get(accountsURL);
}

const AccountListHelper = {
    getAccountListData
}

export default AccountListHelper;
