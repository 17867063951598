import { useCallback, useEffect, useState, useMemo } from 'react';
import ViewportWarning from "../shared/ViewportWarning";
import style from "./TicketCreate.module.css";
import TroubleTicketData from "../../services/TicketCreateService";
import Loader from "../shared/Loader";
import { Redirect } from "react-router-dom";
import { connect, useSelector } from 'react-redux';
import {
	tcAuthorization,
	tcCircuit,
	tcCircuitListCount,
	tcCircuitAddress,
	tcErrorMsg,
	tcContactList,
	tcLContact,
	tcLContactEmail,
	tcLContactId,
	tcLContactPhone,
	tcLContactPhoneExt,
	tcLocalDetails,
	tcLPhoneType,
	tcLUpdatedEmail,
	tcPrimaryDetails,
	tcReset,
	tcStepCode,
	tcTroubleDetails
} from '../../redux';
import TicketCreateProducts from './TicketCreateProducts';
import TicketCreateTroubleDetail from './TicketCreateTroubleDetail';
import TicketCreatePrimaryContact from './TicketCreatePrimaryContact';
import TicketCreateLocalContact from './TicketCreateLocalContact';
import TicketCreateAuthorization from './TicketCreateAuthorization';
import { ConsoleLogger } from "../../logger/ConsoleLogger";
import HTTPErrorHandler from "../errorPages/HTTPErrorHandler";
import CompanyDropdown from '../shared/CompanyDropdown';
import CircuitListHelper from '../../services/CircuitListService';
import ContactData from '../../services/ContactService';

const TicketCreate = (props) => {
	const logger = useMemo(() => new ConsoleLogger({ level: process.env.REACT_APP_LOGGER_LEVEL, }), []);
	const [hasError, setHasError] = useState(null);
	const [troubleTypeOption, setTroubleTypeOption] = useState([]);
	const [productType, setProductType] = useState([]);
	const [showFooter, setShowFooter] = useState(false);
	const [creatingTicketFlag, setCreatingTicketFlag] = useState(false);
	const [contactList, setContactList] = useState([]);
	const [incidentNumber, setIncidentNumber] = useState('');
	const [ticketCreatedFlag, setTicketCreatedFlag] = useState(false);
	const [loader, setLoader] = useState(false);
	const [popupShow, setPopupShow] = useState(false);

	const [troubleDetailsControls, setTroubleDetailsControls] = useState([]);
	const [troubleContactLocalControls, setTroubleContactLocalControls] = useState([]);
	const [troubleContactPrimaryControls, setTroubleContactPrimaryControls] = useState([]);
	const [troubleAuthorization, setTroubleAuthorization] = useState([]);
	const [circuitList, setCircuitList] = useState(null);

	const selectedProductType = useSelector(state => state.ticketCreate.selectedProduct?.dbId);
	const selectedCircuitListCount = useSelector(state => state.ticketCreate?.selectedCircuitListCount);
	const selectedCircuitSearch = useSelector(state => state.ticketCreate?.selectedCircuitSearch);
	const findCircuitValue = useSelector(state => state.ticketCreate?.selectedCircuitValue?.label);
	const circuitAddress = useSelector(state => state.ticketCreate?.selectedCircuitAddress);
	const selectedProductSubType = useSelector(state => state.ticketCreate?.selectedProductType);
	const productSubOptionSelected = useSelector(state => state.ticketCreate?.selectedProductType?.id ? true : false);
	const activeStepCode = useSelector(state => state.ticketCreate?.activeStepCode);
	const troubleDetailsFromStore = useSelector(state => state?.ticketCreate?.troubleDetails);
	const localDetailsFromStore = useSelector(state => state?.ticketCreate?.localDetails);
	const primaryDetailsFromStore = useSelector(state => state?.ticketCreate?.primaryDetails);
	const authorizationFromStore = useSelector(state => state?.ticketCreate?.authorization);
	const errorMsg = useSelector(state => state?.ticketCreate?.errorMsg);

	const isEmployee = localStorage.getItem('isEmployeeLoggedIn');
    const selectedInternalMpid = useSelector(state => state.internal.company?.value);

	useEffect(() => {
		resetData();
		getProducts();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedInternalMpid])

	//Step 1 - Trouble Experience options
	useEffect(() => {
		if (activeStepCode === 1) {
			//Getting Trouble Type Options
			TroubleTicketData.getTroubleTypeOptions().then(res => {
				setProductType(res.data);
			}).catch(error => {
				setHasError(error);
				logger.error("Error getTroubleTypeOptions " + error);
			});
		}
	}, [activeStepCode, logger, selectedProductType])

	//Step 1 - Circuit Picklist
	const getCircuitListMenu = () => {
		setLoader(true);
		let circuitMenu = [];
		logger.info("selectedCircuitSearch " + selectedCircuitSearch);
		CircuitListHelper.getCircuitListCount(selectedCircuitSearch).then(response => {
			logger.info(`Circuit List Count:  ${response.data}`);
			props.updatedCircuitListCount(response.data);
			setLoader(false);
			if(response.data < 5000){
				setLoader(true);
				CircuitListHelper.getCircuitList(selectedCircuitSearch).then(response => {
					if(response.data !== "No circuits found"){
						circuitMenu = response.data;
					}
					setCircuitList(circuitMenu);
					setLoader(false);
				}).catch(error => {
					setLoader(false);
					setHasError(error);
					logger.error(`Error getCircuitList ${error}`);
				})
			} else {
				logger.info(`Too many circuits the UI will display an additional field to limit circuit picklist`);
			}
		}).catch(error => {
			setLoader(false);
			logger.error(`Error getCircuitListCount ${error}`);
		})
	}

	//Additional Circuit Search to manage accounts with extensive circuit lists that may lead to timeout problems
	const searchCircuitList = () => {
		if(selectedCircuitSearch === ""){
			return;
		}
		getCircuitListMenu();
	}

	useEffect(() => {
		//Once we have a product selected fetch the circuitList if the product selected isn't Dark Fiber, the circuitList is empty and the activeStepCode is 1
		if (selectedProductType && selectedProductType !== 1 && !circuitList && activeStepCode === 1) {
			getCircuitListMenu();
		}

	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [activeStepCode, circuitList, selectedProductType, isEmployee, selectedInternalMpid])

	//Step 2 - Trouble Ticket Details
	const addressMandatory = useCallback((troubleDetails) => {
		// Dark Fiber Only
		if (selectedProductType === 1) {
			troubleDetails.forEach(td => {
				if (['LocA', 'cityA', 'stateA', 'zipA', 'LocZ', 'cityZ', 'stateZ', 'zipZ'].includes(td.name)) {
					td.mandatory = true;
				}
			})
		}
		setTroubleDetailsControls(troubleDetails);
	}, [selectedProductType])

	const getTroubleDetailsControls = useCallback(() => {
		TroubleTicketData.getTroubleDetails().then(res => {
			setTroubleDetailsControls(res.data);
			addressMandatory(res.data);
		}).catch(error => {
			logger.error("getTroubleDetails ERROR: " + error)
			setHasError(error);
			setLoader(false);
		});
	}, [addressMandatory, logger])

	useEffect(() => {
		getTroubleDetailsControls()
	}, [getTroubleDetailsControls]);

	//Step 3 - Ticket Contact List Primary and Local Contact
	const getTroubleContactPrimaryControls = useCallback(() => {
		TroubleTicketData.getTroubleContactPrimary().then(res => {
			setTroubleContactPrimaryControls(res.data);
		}).catch(error => {
			logger.error("getTroubleContactPrimary ERROR: " + error)
			setHasError(error);
			setLoader(false);
		});
	}, [logger])

	const getTroubleContactLocalControls = useCallback(() => {
		TroubleTicketData.getTroubleContactLocal().then(res => {
			setTroubleContactLocalControls(res.data);
		}).catch(error => {
			logger.error("getTroubleContactLocal ERROR: " + error)
			setHasError(error);
			setLoader(false);
		});
	}, [logger])

	//Handles Copy to Local Contact box
	const copyToLocalContact = (event) => {
		if (event.target.checked) {
			for (const key in primaryDetailsFromStore) {
				props[key](primaryDetailsFromStore[key]);
			}
		} else {
			for (const key in primaryDetailsFromStore) {
				if (key !== 'receiveUpdateEmail') {
					props[key]('');
				}
			}
		}
	}

	//Populates the Primary/Local/Additional Contact Name fields
	const getContactList = useCallback(() => {
		setLoader(true);
		ContactData.getWinforceContacts().then(response => {
			logger.info(`Contact List: ${JSON.stringify(response.data.result)}`);
			//Filter out any contact that have the inactive_Contact__c flag set to true and storing the contacts in the store
			props.updatedContactList(response.data?.result.filter(contact=>!contact.inactive_Contact__c));
			let activeContacts = response.data?.result.filter(contact => !contact.inactive_Contact__c).map(contact => {
				return { id: contact.id, name: contact.name};
			}).sort((a, b) => a.name.localeCompare(b.name));
			activeContacts.push({id: "new", name: "Create New Contact"})
			setContactList(activeContacts);
			setLoader(false);
		}).catch(error => {
			logger.error("getTicketContacts ERROR: " + error)
			setHasError(error);
			setLoader(false);
		});
	}, [logger])

	useEffect(() => {
		if (activeStepCode === 3) {
			getTroubleContactPrimaryControls();
			getTroubleContactLocalControls();
			getContactList();
		}
	}, [activeStepCode, getContactList, getTroubleContactLocalControls, getTroubleContactPrimaryControls])


	//Step 4 - Trouble Authorization
	const getTroubleAuthorization = useCallback(() => {
		TroubleTicketData.getTroubleAuthorization().then(res => {
			setTroubleAuthorization(res.data);
		}).catch(error => {
			logger.error("getTroubleAuthorization ERROR: " + error)
			setHasError(error);
			setLoader(false);
		});
	}, [logger])


	useEffect(() => {
		getTroubleAuthorization();
	}, [getTroubleAuthorization])

	const getProducts = () => {
		TroubleTicketData.getTroubleProducts().then(res => {
			setTroubleTypeOption(res.data);
		}).catch(error => {
			setHasError(error);
			logger.error("Error getTroubleProducts " + error);
		});
	}

	//Button Handlers - Next Button and Previous Button
	const nextStep = async () => {
		if (activeStepCode === 1) {
			validationStep1();
		}
		if (activeStepCode === 2) {
			validationStep2();
		}
		if (activeStepCode === 3) {
			validationStep3();
		}
		if (activeStepCode === 4) {
			validationStep4();
		}
	}

	const previousStep = () => {
		if (activeStepCode > 1) {
			let prevStep = activeStepCode - 1;
			props.updateErrorMsg('');
			props.updateStepCode(prevStep);

		}
	}

	//Validation Functions
	const validationStep1 = () => {
		//All products require a trouble experienced type and every product besides Dark Fiber (1) requires a circuit id selection
		if (!productSubOptionSelected) {
			props.updateErrorMsg('Please verify you have selected a trouble experienced type');
			return;
		} else if (selectedProductType !== 1 && (findCircuitValue === "" || findCircuitValue === undefined)) {
			props.updateErrorMsg('Please verify you have selected a circuit');
			return;
		} else {
			finalValidation();
		}
	}

	const validationStep2 = () => {
		let validationFailed = validationForm(troubleDetailsControls, troubleDetailsFromStore);
		if (validationFailed) {
			props.updateErrorMsg('Please verify all required information has been entered');
		} else {
			if (contactList.length === 0) {
				getContactList();
			}
			props.updateErrorMsg('');
			finalValidation();
		}
	}

	const validationStep3 = () => {
		let validatedPrimary = validationForm(troubleContactPrimaryControls, primaryDetailsFromStore);
		let validatedLocal = validationForm(troubleContactLocalControls, localDetailsFromStore);

		//ADO-847096 Adding Additional Validation to check contact name for first and last name
		let primaryFirstName = primaryDetailsFromStore['contact'].indexOf(" ") !== -1 ? primaryDetailsFromStore['contact']?.substring(0, primaryDetailsFromStore['contact']?.indexOf(" ")) : primaryDetailsFromStore['contact'];
		let primaryLastName  = primaryDetailsFromStore['contact'].indexOf(" ") !== -1 ? primaryDetailsFromStore['contact']?.substring(primaryDetailsFromStore['contact']?.indexOf(" ") + 1) : "";

		let localFirstName = localDetailsFromStore['contact'].indexOf(" ") !== -1 ? localDetailsFromStore['contact']?.substring(0, localDetailsFromStore['contact']?.indexOf(" ")) : localDetailsFromStore['contact'];
		let localLastName  = localDetailsFromStore['contact'].indexOf(" ") !== -1 ? localDetailsFromStore['contact']?.substring(localDetailsFromStore['contact']?.indexOf(" ") + 1) : "";

		if(primaryFirstName.length === 0 || primaryLastName.length === 0) {
			props.updateErrorMsg('Please verify the primary contact has first and last name');
			return;
		}

		if(localFirstName.length === 0 || localLastName.length === 0 ) {
			props.updateErrorMsg('Please verify the primary contact has first and last name');
			return;
		}

		if (validatedPrimary || validatedLocal) {
			props.updateErrorMsg('Please verify all required information has been entered');
		} else {
			props.updateErrorMsg('');
			finalValidation();
		}
	}

	const validationStep4 = () => {
		let validationFailed = validationForm(troubleAuthorization, authorizationFromStore);
		let accessHours = false;
		troubleAuthorization.forEach((TD) => {
			logger.info(`Checking if ${TD.name} is checked `);
			if (authorizationFromStore[TD.name] && TD.type === "checkbox" && TD.name !== "monToFri") {
				if ((!authorizationFromStore["selectTimes_FromValue"][TD.label] || !authorizationFromStore["selectTimes_ToValue"][TD.label])) {
					logger.info(`Manadatory access hours for ${TD.fullName} is empty `);
					accessHours = true;
				}
			}
		});

		if (validationFailed) {
			props.updateErrorMsg('Please verify all required information has been entered');
		} else if (accessHours) {
			props.updateErrorMsg('Please verify access hours have been entered for every day of the week selected');
		} else {
			props.updateErrorMsg('');
			submitbtn();
		}
	}

	const finalValidation = () => {
		if (activeStepCode < 4) {
			let nextStep = activeStepCode + 1;
			props.updateStepCode(nextStep);
		}
	}

	const validationForm = (formName, storeValue) => {
		let validationError = false;

		formName.forEach(val => {
			if (val.mandatory && (storeValue[val.name] === '' || storeValue[val.name] === null || storeValue[val.name] === undefined)) {
				validationError = true;
			}
		})
		return validationError;
	}

	//Ticket Submission - Mapping redux data to ticket request values to be returned to submitTicket.php
	const submitbtn = () => {

		//Maps iconnect ticket values to existing ticket values from cpt
		let ticketRequest = {
			"ticket_type": selectedProductType,
			"trouble_type": selectedProductSubType.dbId,
			"circuit_id": findCircuitValue,
			"cust_ticket": troubleDetailsFromStore['customerTicketNumber'],
			"power": troubleDetailsFromStore['powerVerified'],
			"problem_desc": troubleDetailsFromStore['descriptionOfProblem'],
			"dispatch_auth": troubleDetailsFromStore['dispatchAuthorization'],
			"intrusive_auth": troubleDetailsFromStore['intrusiveTestingAuthorized'],
			"intrusive_from_time": troubleDetailsFromStore['intrusiveFrom'] + " " + troubleDetailsFromStore['intrusiveFromHrs'],
			"intrusive_to_time": troubleDetailsFromStore['intrusiveTo'] + " " + troubleDetailsFromStore['intrusiveToHrs'],
			"intrusive_tz": troubleDetailsFromStore['intrusiveTz'],
			"turn_up": troubleDetailsFromStore['turnUp'],
			"bounce_time": troubleDetailsFromStore['timeOfBounce'] + " " + troubleDetailsFromStore['timeOfBounceHrs'],
			"bounce_tz": troubleDetailsFromStore['bounceTimeTz'],
			"error_type": troubleDetailsFromStore['typeOfErrors'],
			"error_rate": troubleDetailsFromStore['errorRate'],
			"circ_down_time": troubleDetailsFromStore['timeOfcircuitDownHrs'],
			"circ_down_date": troubleDetailsFromStore['dateOfcircuitDown'],
			"circ_down_tz": troubleDetailsFromStore['circuitDownTz'],
			"req_dur_from": troubleDetailsFromStore['dateOfdurationFrom'] + " " + troubleDetailsFromStore['timeOfdurationFromHrs'],
			"req_dur_to": troubleDetailsFromStore['dateOfdurationTo'] + " " + troubleDetailsFromStore['timeOfdurationToHrs'],
			"pull_perf_monitor": troubleDetailsFromStore['pullPerformanceMonitoring'],
			"num_sites": troubleDetailsFromStore['numberOfSites'],
			"cust_access_req": troubleDetailsFromStore['customerPersonalAccess'],
			"pl_date": troubleDetailsFromStore['dateOfpacketLoss'],
			"pl_time": troubleDetailsFromStore['timeOfpacketLossHrs'],
			"pl_percent": troubleDetailsFromStore['percentageLoss'],
			"sites_oos": troubleDetailsFromStore['sitesOut'],

			//ADO-761374 Dark Fiber Address fields
			"a_address": troubleDetailsFromStore['LocA'],
			"a_city": troubleDetailsFromStore['cityA'],
			"a_state": troubleDetailsFromStore['stateA'],
			"a_zip": troubleDetailsFromStore['zipA'],
			"z_address": troubleDetailsFromStore['LocZ'],
			"z_city": troubleDetailsFromStore['cityZ'],
			"z_state": troubleDetailsFromStore['stateZ'],
			"z_zip": troubleDetailsFromStore['zipZ'],
			//Primary Contact
			"primary_contact_id": primaryDetailsFromStore['contactId'],
			"primary_contact_name": primaryDetailsFromStore['contact'],
			"primary_phone": primaryDetailsFromStore['contactPhone'],
			"primary_phone_ext": primaryDetailsFromStore['contactPhoneExt'],
			"primary_phone_type": primaryDetailsFromStore['phoneType'],
			"primary_email": primaryDetailsFromStore['contactEmail'],
			"primary_status_emails": primaryDetailsFromStore['receiveUpdateEmail'],
			//Local Contact
			"local_contact_id": localDetailsFromStore['contactId'],
			"local_contact_name": localDetailsFromStore['contact'],
			"local_phone": localDetailsFromStore['contactPhone'],
			"local_phone_ext": localDetailsFromStore['contactPhoneExt'],
			"local_phone_type": localDetailsFromStore['phoneType'],
			"local_email": localDetailsFromStore['contactEmail'],
			"local_status_emails": localDetailsFromStore['receiveUpdateEmail'],

			"24_7": authorizationFromStore['selectDate'],
			"time_from": authorizationFromStore['selectTimes_FromValue']?.length > 0 ? authorizationFromStore['selectTimes_FromValue'] : "",
			"time_to": authorizationFromStore['selectTimes_ToValue']?.length > 0 ? authorizationFromStore['selectTimes_ToValue'] : "",
			"mon_fri": authorizationFromStore['monToFri'],
			"mon": authorizationFromStore['dayMon'],
			"tues": authorizationFromStore['dayTues'],
			"wed": authorizationFromStore['dayWed'],
			"thur": authorizationFromStore['dayThur'],
			"fri": authorizationFromStore['dayFri'],
			"sat": authorizationFromStore['daySat'],
			"sun": authorizationFromStore['daySun'],
			"ckt_gaid": circuitAddress.ckt_gaid,
			"ckt_designId": circuitAddress.ckt_designId,
			"ckt_type": circuitAddress.ckt_type,
			"ckt_status": circuitAddress.ckt_status,
			"ckt_name": circuitAddress.ckt_name
		};
		logger.info(ticketRequest);
		createTroubleTicket(ticketRequest);
	}

	//Calls the create ticket ticket api which returns an incident number if a ticket is created
	const createTroubleTicket = (ticketValues) => {
		setLoader(true);
		setCreatingTicketFlag(true);
		TroubleTicketData.createTicket(ticketValues).then(response => {
			logger.info(`createTicket response: ${JSON.stringify(response.data)}`);
			if (response.data.result === "SUCCESS") {
				setLoader(false);
				setIncidentNumber(response.data?.incident_number);
				setTicketCreatedFlag(true);
				resetData();
			} else {
				setLoader(false);
				props.updateErrorMsg(response.data?.error);
			}
			setCreatingTicketFlag(false);
		}).catch(error => {
			logger.error(`createTicket ERROR: ${error}`);
			setHasError(error);
			setLoader(false);
			setCreatingTicketFlag(false);
			props.updateErrorMsg(JSON.stringify(error['message']));
		})
	}

	//Clear data on reload or after ticket creation or if cancel button is clicked
	const resetData = () => {
		setShowFooter(false);
		setCircuitList(null);
		props.updateErrorMsg('');
		props.clearStore();
	}

	//Popup Handlers
	const cancelAction = () => {
		setPopupShow(true);
	}
	const popupOk = () => {
		// footer cancel btn
		resetData();
		popupCancel();
	}

	const popupCancel = () => {
		setPopupShow(false);
	}

	return (
		<>
			{hasError && <HTTPErrorHandler error={hasError}></HTTPErrorHandler>}
			{loader ? <Loader /> : null}
			<div className="midsection qm-upload abs z1">
				<div className="rel">
					<ViewportWarning />
					<div className="wrap">
					<h1 className="page-title">{isEmployee ? "Employee Ticket Create" : "Ticket Create"}</h1>
						{isEmployee ? <CompanyDropdown area={"Ticket Create"} stepCode={activeStepCode} /> : null}
						{!hasError && (<div className="section">
							<div className={` content has-actions ${style.ticketCreatePage} `}>
								<div className="milestones four-steps mb-4">
									<div className={`step ${activeStepCode === 1 ? 'active' : null}`}>
										<div>1</div>
										<span>Product Details</span>
									</div>
									<div className="gap">&nbsp;</div>
									<div className={`step ${activeStepCode === 2 ? 'active' : null}`}>
										<div>2</div>
										<span>Trouble Details</span>
									</div>
									<div className="gap">&nbsp;</div>
									<div className={`step ${activeStepCode === 3 ? 'active' : null}`}>
										<div>3</div>
										<span>Contact Details</span>
									</div>
									<div className="gap">&nbsp;</div>
									<div className={`step ${activeStepCode === 4 ? 'active' : null}`}>
										<div>4</div>
										<span>Authorization</span>
									</div>
								</div>
								{activeStepCode === 1 ?
									<TicketCreateProducts products={troubleTypeOption} productSubOption={productType} troubleDetailsData={troubleDetailsControls} circuitData={circuitList} circuitCount={selectedCircuitListCount} searchCircuitList={searchCircuitList} /> : null
								}

								{activeStepCode === 2 ?
									<TicketCreateTroubleDetail troubleDetailsData={troubleDetailsControls} /> : null
								}

								{activeStepCode === 3 ?
									<div className={`step3 ${style.rowGap}`}>
										<div className="row">
											<div className="col-12 col-md-5">
												<TicketCreatePrimaryContact primaryDetailsData={troubleContactPrimaryControls} contactListData={contactList} />
											</div>
											<div className="col-12 offset-md-1 col-md-5">
												<TicketCreateLocalContact localDetailsData={troubleContactLocalControls} contactListData={contactList} />
											</div>
										</div>

										<div className="row mt-3">
											<div className="col-sm">
												<div className="form-check">
													<input type="checkbox" className="form-check-input" id="copyDetails" onChange={(event) => { copyToLocalContact(event) }} />
													<div className="col-sm">
														<label className={`form-check-label ${style.labelText} textuppercase text-top`} htmlFor="copyDetails"> Copy to Local Contact</label>
													</div>
												</div>
											</div>
										</div>
									</div> : null
								}


								{activeStepCode === 4 ?
									<>
										<TicketCreateAuthorization authorizationData={troubleAuthorization} />
										{/* success msg */}
										{creatingTicketFlag ?
											<div className="my-4 text-center">
												<h5 className="text-success">Creating Trouble Ticket</h5>
											</div> : null
										}
										{ticketCreatedFlag ?
											<Redirect to={`/Network/TicketDetails/${incidentNumber}`} /> : null
										}
									</> : null
								}

								{(productSubOptionSelected || showFooter) ?
									<div className={style.pageActions}>
										<div className="text-center">
											<p className="text-danger">{errorMsg}</p>

											<button className={` ico-button light-button `} onClick={() => { cancelAction() }} >
												Cancel
												<svg className="ico-x">
													<path
														d="M18,17l-1,1L9,10,1,18,0,17,8,9,0,1,1,0,9,8l8-8,1,1L10,9Z"></path>
												</svg>
											</button>

											{(activeStepCode !== 1) ?
												<button type="button" className={`ico-button light-button ${style.prevBtn} ml-3`} onClick={() => { previousStep() }} disabled={(activeStepCode === 1) ? true : false}>
													<svg className={`ico ico-right ${style.prevArrow}`}>
														<path d="M0,17H29L15,31l1,1L32,16,16,0,15,1,29,15H0Z"></path>
													</svg>
													Previous
												</button>
												: null}

											<button type="button" className={`ico-button lhs light-button ${style.nextBtn} ml-3`} onClick={() => { nextStep() }}  >
												{activeStepCode === 4 ? 'Submit' : 'Next'}
												<svg className="ico light-ico ico-arrow">
													<path d="M0,17H29L15,31l1,1L32,16,16,0,15,1,29,15H0Z"></path>
												</svg>
											</button>
										</div>
									</div>
									: null}
							</div>
						</div>)}
					</div>
				</div>
			</div>
			{!hasError && (popupShow ?
				<div>
					<div className={`${style.overly}`}> </div>
					<div className="modal fade show d-block">
						<div className={` modal-dialog modal-dialog-centered modal-sm`}>
							<div className="modal-content">
								<div className="modal-body">
									Are you sure you want to cancel?
								</div>

								<div className="modal-footer d-block text-center">
									<button type="button" className="btn-style btn-theme" data-dismiss="modal" onClick={() => { popupOk() }}>Ok</button>
									<button type="button" className="btn-style btn-cancel" data-dismiss="modal" onClick={() => popupCancel()}>Cancel</button>
								</div>

							</div>
						</div>
					</div>
				</div>
				: null)
			}
		</>
	);
}

// export default TicketCreate;
const mapDispatchToProps = {
	updateStepCode: tcStepCode,
	updatedCircuitValue: tcCircuit,
	updatedCircuitListCount: tcCircuitListCount,
	updatedCircuitAddress: tcCircuitAddress,
	updatedTroubleDetails: tcTroubleDetails,
	updatedPrimaryDetails: tcPrimaryDetails,
	updatedLocalDetails: tcLocalDetails,
	updatedAuthorization: tcAuthorization,
	updatedContactList: tcContactList,
	contact: tcLContact,
	contactId: tcLContactId,
	contactPhone: tcLContactPhone,
	contactPhoneExt: tcLContactPhoneExt,
	phoneType: tcLPhoneType,
	receiveUpdateEmail: tcLUpdatedEmail,
	contactEmail: tcLContactEmail,
	updateErrorMsg: tcErrorMsg,
	clearStore: tcReset
}

export default connect(null, mapDispatchToProps)(TicketCreate);
