import {useState} from "react";
import CancelModal from "./CancelModal";
import style from "../Employee.module.css";


const Input = ({ inputEmit, label, disabled, value, name }) => {
    const inputHandle = (e) => {
        inputEmit(e)
    }
    return (
        <div className='my-3'>
            <label className='labelText'> {label}</label>
            <input type="text" name={name} className={`${style.inputFldText} w-100`} value={value} disabled={disabled} onChange={(e) => { inputHandle(e) }} />
        </div>
    )
}

const Account = () => {
    const initialForm = { login: 'e0155921', firstName: 'Steven', lastName: 'Kurek', email: 'steven.kurek@windstream.com', status: 'Active', vacationForward: '' };
    const [formValues, setFormValues] = useState(initialForm);
    const [cancelWindow, setCancelWindow] = useState(false);
    const inputEmit = (e) => {
        const { name, value } = e.target;
        setFormValues({ ...formValues, [name]: value })
    }

    const submitAction = () => {
        console.log(formValues);
    }

    const cancelAction = () => {
        setCancelWindow(true);
    }

    const cancelEmit = (action) => {
        if (action === 'Ok') {
            setCancelWindow(false);
            setFormValues(initialForm);
        } else {
            setCancelWindow(false);
        }
    }

    return (
        <div className="staffPage">
            <p className="text-secondary text-right m-0"><span className={style.mandatory}>*</span> Denotes a required field</p>
            <div className={`sub-hdr alt0 mt-0`}>
                <div className="nib">
                    <div className="the-notch">
                        <div className="notch-fg">
                            <svg>
                                <path
                                    d="M35.81,26.28l-13,10v-5C-10.47,28.68-1,9.16,11.79,0c0,7.28,0,9.13,0,13.28s3.18,7.92,11,9v-5Z"></path>
                            </svg>
                        </div>
                    </div>
                </div>
                <h2 className={` m-0 `}>User Information</h2>
            </div>

            <div className='row my-3 px-3'>
                <div className='col-12 col-md-4'>
                    <Input label='Login' name='login' disabled={true} value={formValues.login} inputEmit={inputEmit} />
                    <Input label='First Name' name='firstName' disabled={true} value={formValues.firstName} inputEmit={inputEmit} />
                    <Input label='Last Name' name='lastName' disabled={true} value={formValues.lastName} inputEmit={inputEmit} />
                </div>
                <div className='col-12 col-md-4'>
                    <Input label='Email' name='email' disabled={true} value={formValues.email} inputEmit={inputEmit} />
                    <Input label='Status' name='status' disabled={true} value={formValues.status} inputEmit={inputEmit} />
                    <Input label='Vacation Forward' name='vacationForward' value={formValues.vacationForward} disabled={false} inputEmit={inputEmit} />
                </div>
            </div>


            <div className="text-center my-5">
                <button type="button" className={`ico-button lhs light-button ml-3 ${style.cancelBtn} `} onClick={() => { cancelAction() }} >
                    Cancel
                    <svg className="ico-x">
                        <path
                            d="M18,17l-1,1L9,10,1,18,0,17,8,9,0,1,1,0,9,8l8-8,1,1L10,9Z"></path>
                    </svg>
                </button>

                <button type="button" className={`ico-button lhs light-button ml-3 ${style.nextBtn} `} onClick={() => { submitAction() }} >
                    Submit
                    <svg className="ico light-ico ico-arrow ml-2">
                        <path d="M0,17H29L15,31l1,1L32,16,16,0,15,1,29,15H0Z"></path>
                    </svg>
                </button>
            </div>
            {cancelWindow ? <CancelModal cancelEmit={cancelEmit} /> : null}
        </div>


    )
}

export default Account;