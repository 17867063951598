import style from "./QuoteCreate.module.css";
import { useCallback, useEffect, useState, useMemo } from "react";
import { connect, useSelector } from "react-redux";
import PremAddress from "./PremAddress";
import QuoteLocZ from "./QuoteLocZ";
import Term from "./Term"
import Loader from '../shared/Loader';
import { ConsoleLogger } from "../../logger/ConsoleLogger";
import {
    setQuoteDfNumFibers,
    setQuoteDfComments
} from "../../redux/actions/quoteActions";

const DarkFiber = (props) => {
    const logger = new ConsoleLogger({ level: process.env.REACT_APP_LOGGER_LEVEL, });
    const selectedQuoteValues = useSelector(state => state.quote.quoteValues);
    const [errorMessage, setErrorMessage] = useState('');
    const [loader, setLoader] = useState(false);

    const inputHandler = (ev) => {
        switch (ev.target.id) {
            case "df_num_fibers":
                const numFibers = parseInt(ev.target.value, 10);
                if (numFibers > 0 && numFibers % 2 === 0) {
                    setErrorMessage('');
                    props.updateDfNumFibers(numFibers);
                } else {
                    setErrorMessage('Number of Fibers must be an even number greater than zero.');
                }
                break;
            case "df_comments":
                props.updateDfComments(ev.target.value);
                break
            default:
                break;
        }
    }

    return (
        <>
            {loader ? <Loader /> : null}
            {props.stepCode === 2 ?
                <>
                    <PremAddress />
                    <QuoteLocZ />
                </>
                : null}
            {props.stepCode === 3 && props.section === "term" ?
                <Term product={"DARKFIBER"} />
                : null}

            {props.stepCode === 3 && props.section === "productConfig" ?
                <>
                    <div className={`row ${style.rowGrp}`}>
                        <div className="col-12 col-sm-4 col-md-3 mb-5">
                            <label className={style.lableTextS}>Number of Fibers <span className={style.mandatory}>*</span>
                                <input type="text" className={style.inputFld} id='df_num_fibers' onChange={inputHandler} value={selectedQuoteValues.df_num_fibers} />
                                {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
                            </label>
                        </div>
                    </div>
                    <div className={`row ${style.rowGrp}`}>
                        <div className="col-6">
                            <label className={style.lableTextS}>Description and Special Instructions/Requests</label>
                            <textarea className={style.textareaFld} id="df_comments" maxLength="255" onChange={inputHandler} value={selectedQuoteValues.df_comments}></textarea>
                        </div>
                    </div>
                </>
                : null}
        </>
    )
}

const mapDispatchToProps = {
    updateDfNumFibers: setQuoteDfNumFibers,
    updateDfComments: setQuoteDfComments,
}

export default connect(null, mapDispatchToProps)(DarkFiber);