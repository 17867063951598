import React, {useEffect, useMemo, useState} from 'react';
import {Link, useLocation, useParams} from 'react-router-dom';
import ViewportWarning from "../shared/ViewportWarning";
import ContactData from "../../services/ContactService";
import {ConsoleLogger} from "../../logger/ConsoleLogger";
import Loader from "../shared/Loader";
import HTTPErrorHandler from "../errorPages/HTTPErrorHandler";
import style from "./UtilityContacts.module.css";
import ReactTooltip from 'react-tooltip';

const InvoiceEnrollment = () => {
    const logger = useMemo(() => new ConsoleLogger({level: process.env.REACT_APP_LOGGER_LEVEL,}), []);
    const [hasError, setHasError] = useState(null);
    const [enrollmentInvoices, setEnrollmentInvoices] = useState({});
    const [enrollMessage, setEnrollmentMessage] = useState("");
    const [banArray, setBanArray] = useState([]);
    const [subscribeBans, setSubscribeBans] = useState([]);
    const [unsubscribeBans, setUnsubscribeBans] = useState([]);
    const [allSelected, setAllSelected] = useState(false);
    const [loader, setLoader] = useState(false);
    const location = useLocation();
    const enrollContactData = location.state?.contactDetails;
    const {gaid, gapid} = useParams();

    useEffect(() => {
        getEnrollmentsInvoice();
    }, [gaid, gapid])

    useEffect(() => {
        enrollmentBanArray(enrollmentInvoices);
    }, [enrollmentInvoices]);

    /**
     * Retrieves enrollments for invoices.
     */
    const getEnrollmentsInvoice = () => {
        // Set loader to true to indicate loading state
        if (loader === false) {
            setLoader(true);

            // Retrieve invoice enrollments using ContactData API
            ContactData.getInvoiceEnrollments(gaid, gapid, "gaid")
                .then(response => {
                    if (response.data) {
                        setLoader(false);
                        // Set the enrollment message with the response data
                        setEnrollmentMessage(response.data.status);
                        // Set enrollment invoices with the retrieved data
                        setEnrollmentInvoices(response.data);
                    }
                })
                .catch(error => {
                    setLoader(false);
                    // Set error state and log the error
                    setEnrollmentMessage("FAILED");
                    setHasError(error);
                    logger.error("Error getting invoiced enrollments " + error);
                });
        }
    }

    const updateEnrollmentsInvoice = (bans, action) => {
        ContactData.updateInvoiceEnrollmentByBan(bans, gapid, "ban", action)
            .then(response => {
                if (response.data) {
                    if (response.data.status === "SUCCESS") {
                        // Set the enrollment message with the response data
                        setEnrollmentMessage(response.data.status);
                        getEnrollmentsInvoice();
                    }
                }
            })
            .catch(error => {
                setLoader(false);
                // Set error state and log the error
                setEnrollmentMessage("FAILED");
                setHasError(error);
                logger.error("Error getting invoiced enrollments " + error);
            });
    }


    /**
     * Generates an array of enrollment ban objects and sets it in the state.
     */
    const enrollmentBanArray = (object) => {

        // Check if there are any enrollment invoices
        const arraySize = Object.keys(object).length;
        // Check if there are any enrollment invoices
        if (arraySize > 0) {
            // Map over the enrollment invoices and create a table row for each
            let invoicePrefs = Object.entries(object).map((enrollment) => (<tr>
                <td className={"left-align"}><p>{enrollment["1"].ban}</p></td>
                <td>
                    <input
                        type="checkbox"
                        id={enrollment["1"].ban}
                        name="enrollInvoiceEmail"
                        key={enrollment["1"].ban}
                        checked={enrollment["1"].enrollInvoiceEmail}
                        onChange={e => handleCheckboxChange(e, enrollment["1"].ban)}
                    />
                </td>
                <td></td>
            </tr>));

            checkSelectAll(object);

            // Set the array of enrollment ban objects in the state
            setBanArray(invoicePrefs);
        }
    }

    const handleCheckboxChange = (event, id) => {
        const arraySize = Object.keys(enrollmentInvoices).length;
        // Check if there are any enrollment invoices
        if (arraySize > 0) {
            let enrollments = Object.entries(enrollmentInvoices).map((enrollment) => {
                if (event.target.checked && enrollment["0"] === id) {
                    // Set the value in the object array
                    enrollment["1"].enrollInvoiceEmail = true;
                    enrollmentInvoices[id].enrollInvoiceEmail = true;
                    let _id = enrollment["1"].ban.replace("Ban: ", "");
                    setSubscribeBans(previousBans => [...previousBans, _id]);
                    setUnsubscribeBans(previousBans => previousBans.filter(ban => ban !== _id));
                } else if (!event.target.checked && enrollment["0"] === id) {
                    // Set the value in the object array
                    enrollment["1"].enrollInvoiceEmail = false;
                    enrollmentInvoices[id].enrollInvoiceEmail = false;
                    let _id = enrollment["1"].ban.replace("Ban: ", "");
                    setUnsubscribeBans(previousBans => [...previousBans, _id]);
                    setSubscribeBans(previousBans => previousBans.filter(ban => ban !== _id));
                }
                return new Object({[enrollment["0"]]: enrollment["1"]});
            });
            let listOfObjects = enrollments.reduce((acc, obj) => Object.assign(acc, obj));
            enrollmentBanArray(listOfObjects);
        }
    };

    const updateInvoiceEnrollmentByBans = (event) => {
        setEnrollmentMessage("Submitting request...")
        setLoader(true);
        logger.info(subscribeBans);
        logger.info(unsubscribeBans);
        // Save BANs
        updateEnrollmentsInvoice(subscribeBans, "subscribe");
        // Remove BANs
        updateEnrollmentsInvoice(unsubscribeBans, "unsubscribe");
        setLoader(false);

        // Clear arrays
        setSubscribeBans([]);
        setUnsubscribeBans([]);
    }

    const handleSelectAll = (event) => {
        const arraySize = Object.keys(enrollmentInvoices).length;
        // Check if there are any enrollment invoices
        if (arraySize > 0) {

            let enrollments = Object.entries(enrollmentInvoices).map((enrollment) => {
                if (event.target.checked) {
                    // Set the value in the object array
                    enrollment["1"].enrollInvoiceEmail = true;
                    let id = enrollment["1"].ban.replace("Ban: ", "");
                    setSubscribeBans(previousBans => [...previousBans, id]);
                    setUnsubscribeBans(previousBans => previousBans.filter(ban => ban !== id));
                } else {
                    // Set the value in the object array
                    enrollment["1"].enrollInvoiceEmail = false;
                    let id = enrollment["1"].ban.replace("Ban: ", "");
                    setUnsubscribeBans(previousBans => [...previousBans, id]);
                    setSubscribeBans(previousBans => previousBans.filter(ban => ban !== id));
                }
                return new Object({[enrollment["0"]]: enrollment["1"]});
            });

            if (event.target.checked) {
                setAllSelected(true);
                setUnsubscribeBans([]);
            } else {
                setAllSelected(false);
                setSubscribeBans([]);
            }
            let listOfObjects = enrollments.reduce((acc, obj) => Object.assign(acc, obj));
            enrollmentBanArray(listOfObjects);
        }
    }

    const checkSelectAll = (object) => {
        let countSelected = 0;
        const arraySize = Object.keys(object).length;
        // Check if there are any enrollment invoices
        if (arraySize > 0) {
            Object.entries(object).map((enrollment) => {
                if (enrollment["1"].enrollInvoiceEmail) {
                    countSelected++;
                }
            });
        }

        if (arraySize === countSelected) {
            setAllSelected(true);
        } else {
            setAllSelected(false);
        }
    }

    return (<>
        {hasError && <HTTPErrorHandler error={hasError}></HTTPErrorHandler>}
        {loader ? <Loader/> : null}
        <div className="midsection qm-upload abs z1">
            <div className="rel">
                <ViewportWarning/>
                <div className="wrap">
                    <h1 className="page-title">Enrollment</h1>
                    {!hasError && (<div className="section">
                        {/* <TabsQuote /> */}
                        <div className="content enrollment_page">
                            <Link to="/utilities/UtilityContacts" className="back_btn_link">
                                <p>Back to Utility Contacts</p>
                            </Link>
                            <p>This web page allows you set/update notifications on an account contact</p>
                            <div className="enrollfetchedData">
                                <div>
                                    <p>Contact
                                        Name: <span>{enrollContactData?.firstName} {enrollContactData?.lastName}</span>
                                    </p>
                                    <p>Email: <span>{enrollContactData?.email}</span></p>
                                    <p>Phone: <span>{enrollContactData?.phone}</span></p>
                                </div>
                            </div>
                            <div className="mt-5">
                                <table className={`table ${style.enrollmentTable}`}>
                                    <thead>
                                    <tr>
                                        <th className="left-align">Notifications</th>
                                        <th className="left-align">Email
                                            <img data-tip data-for="emailToolTipInfo"
                                                 className="tipped cornertip-right ml-2"
                                                 src="../../../images/svg/ico-info-filled-dark-off.svg"
                                                 width="15"
                                                 height="15" alt=""/>
                                            <ReactTooltip id="emailToolTipInfo"
                                                          className={`${style.enrollmentToolTipInfo} ${style.enrollmentInfoWindow}`}
                                                          delayHide={100}
                                                          effect='solid'>
                                                All Email notifications to be received are voluntary and not
                                                soliciting in nature. Notifications may provide information about
                                                your account and services with Windstream. Ensure your contact
                                                information is correct before subscribing to a notification To
                                                discontinue receiving email notifications, you can unselect the
                                                email preferences for any of the notification type alerts from the
                                                notification center page.
                                            </ReactTooltip>
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td className={"left-align"}>
                                            <p>Invoice Enrollments</p>
                                        </td>
                                        <td>
                                            <div>
                                                <input
                                                    type="checkbox"
                                                    id="selectAll"
                                                    name="selectAll"
                                                    key="selectAll"
                                                    onChange={handleSelectAll}
                                                    checked={allSelected}
                                                />
                                                &nbsp;&nbsp;&nbsp;Select All
                                            </div>
                                        </td>
                                    </tr>
                                    {banArray}
                                    </tbody>
                                </table>
                                <br/>
                                <div>
                                    <button type="button"
                                            className={`ico-button lhs light-button ${style.enrollUpdateBtn}`}
                                            onClick={updateInvoiceEnrollmentByBans}>
                                        Submit
                                        <svg className="ico light-ico ico-arrow">
                                            <path d="M0,17H29L15,31l1,1L32,16,16,0,15,1,29,15H0Z"></path>
                                        </svg>
                                    </button>
                                </div>
                                {/*Message From the Enroll API */}
                                {enrollMessage ? <div className="mt-5">
                                    <p className="text-center text-success">{enrollMessage}</p>
                                </div> : null}
                            </div>
                        </div>
                    </div>)}
                </div>
            </div>
        </div>
    </>);
}

export default InvoiceEnrollment;
