import {HTTPHeaders} from './HTTPHeaders';
import axios from 'axios';
import {ConsoleLogger} from "../../logger/ConsoleLogger";
import {store} from "../../utils/createStore";

const logger = new ConsoleLogger({level: process.env.REACT_APP_LOGGER_LEVEL,});

// Step-1: Create a new Axios instance with a custom config.
// The timeout is set to 10s. If the request takes longer than
// that then the request will be aborted.
const customAxios = axios.create({
    timeout: 100000,
	headers: HTTPHeaders,
	withCredentials: true
});

// Step-2: Create request, response & error handlers
// For internal iconnect the mpid will need to be added as a parameter to all requests
const requestHandler = request => {
	if (localStorage.getItem("isEmployeeLoggedIn")) {
		let reduxStore = store.getState();
		let currentInternalMpid = reduxStore?.internal?.company?.value;
		if (currentInternalMpid) {
			logger.info(`Passing in mpid since employee is logged in: ${currentInternalMpid}`);
			request.params = { mpid: currentInternalMpid }
		}
	} else {
		logger.info(`Customer request interceptor`);
	}
	return request
};

const responseHandler = response => {
	if (response.data.result) {
		logger.info(`Result: ${response.data.result}`);
		if (response.data.result !== "SUCCESS") {
			if (response.data.result === "AUTH") {
				logger.info("Handle FAIL CASE");
				var message = '';
				if (response.data.message.length !== 0) {
					message = response.data.message;
				} else if (response.data.data.message.length !== 0) {
					message = response.data.data.message;
				}
				logger.info(`MESSAGE FROM AUTH: ${message}`);
				switch (message) {
					case "NO ACCESS":
						window.location = '/NoAccess';
						break;
					case "NO ICONNECT ACCESS":
						window.location = '/NoIconnect';
						break;
					case "NO TOKEN FOUND":
					case "ACCOUNT IS INACTIVE":
					case "UNKNOWN AUTH FAILURE":
					case "NO USER DATA FOUND":
						logger.info(`Message from auth: ${message}`);
						//Employee
						if (localStorage.getItem("isEmployeeLoggedIn")) {
							logger.info(`Employee is logged in and inside of customAxios responseHandler`);
							if (localStorage.getItem("retry")) {
								logger.info(`Retry was set redirecting employee login page`);
								// Remove Employee Data
								localStorage.removeItem("isEmployeeLoggedIn");
								localStorage.removeItem("employeeLoggedInTime");
								localStorage.removeItem("eid");
								localStorage.removeItem("employeeName");
								window.location = "/Employee/Login";
							} else {
								localStorage.setItem("retry", 1);
								localStorage.setItem("sessionReset", 1);
								// Remove Employee Data on retry
								localStorage.removeItem("isEmployeeLoggedIn");
								localStorage.removeItem("employeeLoggedInTime");
								localStorage.removeItem("eid");
								localStorage.removeItem("employeeName");
								logger.info(`Setting retry and call employee.php`);
								//Redirect to php auth
								window.location.href = `${process.env.REACT_APP_LOGIN_URL}/employee.php`;
							}
							break;
						}
						if (localStorage.getItem("isLoggedIn")) {
							logger.info(`Inside of case in customAxios for Customer and message from auth: ${message}`);
							localStorage.removeItem("isLoggedIn");
							localStorage.removeItem("loggedInTime");
							localStorage.removeItem("user");
							window.location = '/Login';
						}
						break;
					default:
						logger.info(`Inside of default case in customAxios for Customer and message from auth: ${message}`);
						logger.info("Response: " + response);
						localStorage.removeItem("isLoggedIn");
						localStorage.removeItem("loggedInTime");
						localStorage.removeItem("user");
						window.location = '/Login';
						break;
				}
            }
		}
		if (response.data.data && !response.data.data?.length) {
			logger.info("Returning response data")
            return response.data;
        } else {
			logger.info("Returning response")
			return response;
		}
    } else {
		logger.info("Returning default response")
		return response;
	}
};

// Step-3: Configure/make use of request & response interceptors from Axios
// Note: You can create one method say configureInterceptors, add below in that,
// export and call it in an init function of the application/page.
customAxios.interceptors.request.use(
    (request) => requestHandler(request)
);

customAxios.interceptors.response.use(
    (response) => responseHandler(response)
);

// Step-4: Export the newly created Axios instance to be used in different locations.
export default customAxios;
