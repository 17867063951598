import React from 'react';
import { Link } from 'react-router-dom';
import style from './FieldServiceDispatchRates.module.css';

const FieldServiceDispatchRates = () => {
	return (
		<div className="midsection qm-upload abs z1">
			<div className="rel">
				<div className="wrap">
					<h1 className="page-title">Field Service Dispatch Rates</h1>
					<div className="section">
						<div className="content">
							<div className={style.serviceInfo}>
								<div className={style.contactInfo}>
									<p>
										To report trouble with your service, or for colocation customers, to request escorted site access, please contact the Wholesale Network Operations Center at <strong>844.946.2662</strong> or <Link to="/Ticket/Create" className={style.link}>open a trouble ticket</Link>.
									</p>
								</div>
								<div className={style.dispatchRates}>
									<table>
										<thead>
											<tr>
												<th>Days</th>
												<th>Hourly Rate*</th>
											</tr>
										</thead>
										<tbody>
											<tr>
												<td>Monday-Friday 08:00-17:00</td>
												<td>$200.00</td>
											</tr>
											<tr>
												<td>Weekday Evenings / Saturdays</td>
												<td>$235.00</td>
											</tr>
											<tr>
												<td>Sundays / Holidays</td>
												<td>$275.00</td>
											</tr>
										</tbody>
									</table>
									<p>* Charges are per technician and shall include travel time, with a two-hour minimum, rounded up to the nearest half hour thereafter.</p>
									<p>Customer shall be responsible for the payment of any and all charges imposed by Windstream at Windstream's current field service dispatch rate(s), as may be updated from time to time, for the following:</p>
									<ul>
										<li>Escorted Site Access</li>
										<li>For trouble reports resulting in no trouble found on Windstream's network</li>
										<li>When any trouble report results from any Customer equipment or facility or any equipment or facility provided to Customer by any entity other than Windstream</li>
										<li>When incomplete or incorrect information supplied by Customer causes unnecessary dispatches by Windstream</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default FieldServiceDispatchRates;