/**
 * Created by e0173131 on 1/20/2021.
 */
import React, {useEffect, useState, useMemo, useCallback} from "react";
import ViewportWarning from "../shared/ViewportWarning";
import TabsQuote from "./includes/TabsQuote";
import FormattingUtils from '../../utils/FormattingUtils';
import Loader from "../shared/Loader";
import AccountListHelper from "../../services/AccountListService";
import HTTPErrorHandler from "../errorPages/HTTPErrorHandler";
import {ConsoleLogger} from "../../logger/ConsoleLogger";
import {useSelector} from 'react-redux';
import CompanyDropdown from '../shared/CompanyDropdown';
import getAccountSummaryData from "../../services/AccountSummaryService";

const AccountSummary = () => {
    const logger = useMemo(() => new ConsoleLogger({level: process.env.REACT_APP_LOGGER_LEVEL,}),[]);
    const [accountsList, setAccountList] = useState([]);
    const [selectedAccount, setSelectedAccount] = useState('');
    const [loader, setLoader] = useState(false);
    const [accData, setAccountData] = useState('');
    const [hasError, setHasError] = React.useState(null);
    const isEmployee = localStorage.getItem("isEmployeeLoggedIn");
    const selectedInternalMpid = useSelector(state => state.internal.company?.value);

    const getAccountList = useCallback(() => {
        let accList = null;
        setLoader(true);
        AccountListHelper.getAccountListData(false).then(resp => {
            accList = resp.data;
            const MAX_CHARS_TO_DISPLAY = 55;
            let str = '';
            accList = accList.map(item => {
                str = `${item.ban} - ${item.name} - ${item.id}`;
                str = FormattingUtils.truncateWithEllipses(str, MAX_CHARS_TO_DISPLAY);
                return { value: item.ban, label: str }
            });
            setAccountList([{ value: '', label: 'Select Account' }].concat(accList));
            setLoader(false);
        }).catch((error) => {
            logger.error("getAccountListData ERROR: " + error)
            setLoader(false);
            setHasError(error);
        });

    },[logger])

    useEffect(() => {
        if(isEmployee){
            if(selectedInternalMpid){
               getAccountList(); 
            }
        } else {
            getAccountList();
        } 
        
    }, [getAccountList, isEmployee, selectedInternalMpid]);

    //Perfoming axios call to retrieve live data based on account that is selected
    const accountHandler = (ev) => {
        let accountNum = ev.target.value;
        setLoader(true)
        setSelectedAccount(ev.target.value);
		getAccountSummaryData(accountNum).then(resp => {
            setAccountData(resp.data[0]);
            setLoader(false);
        }).catch((error) => {
            logger.error("getAccountSummary ERROR: " + error)
            setLoader(false);
            setHasError(error);
        });
    }

    return (
        <>
        {loader ? <Loader /> : null}
        {hasError && (
                <HTTPErrorHandler error={hasError}></HTTPErrorHandler>
            )}
            {!hasError && 
                (<div className="midsection qm-upload abs z1">
                <div className="rel">
                    <ViewportWarning />
                    <div className="wrap">
                        <h1 className="page-title">{isEmployee ? "Employee Account Summary" : "Account Summary"}</h1>
                        {isEmployee ? <CompanyDropdown/> : null}
                        <div className="section">
                            <TabsQuote />
                            <div className="content">
                                <div id="topRow">
                                    <div className="fl w30 state-select">
                                        <select onChange={(accountHandler)} value={selectedAccount}
                                            className="nice-select dark-select wide" name="validator-state">
                                            {accountsList.map(item => (
                                                <option key={item.value} value={item.value}>
                                                    {item.label}
                                                </option>
                                            ))}
                                        </select>
									</div>
									{/*
                                    {accData && <PDFReportGenerator title="Account Summary"
										document={<Invoice invoice={accData} />} />}
									*/}
                                </div>
                                {accData &&
                                    <div className="row">
                                        <div className="col-6">
                                            <div className="row">
                                                <div className="col-4">
                                                    Account Number
                                                </div>
                                                <div className="col-8">
                                                    {accData.accountNbr}
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-4">
                                                    Customer Name
                                                </div>
                                                <div className="col-8">
                                                    {accData.customerName}
                                                </div>
                                            </div><div className="row">
                                                <div className="col-4">
                                                    Bill Date
                                                </div>
                                                <div className="col-8">
                                                    {accData.billDate}
                                                </div>
                                            </div><div className="row">
                                                <div className="col-4">
                                                    Due Date
                                                </div>
                                                <div className="col-8">
                                                    {accData.dueDate}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="row">
                                                <div className="col-8">
                                                    Previous Balance
                                                </div>
                                                <div className="col-4">
                                                    {FormattingUtils.formatCurrency(accData.prevBal)}
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-8">
                                                    Payments and Adjustments
                                                </div>
                                                <div className="col-4">
                                                    {FormattingUtils.formatCurrency(accData.adjnOchg)}
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-8">
                                                    Past Due - Payments Due Immediately
                                                </div>
                                                <div className="col-4">
                                                    {FormattingUtils.formatCurrency(accData.pastdue)}
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-8">
                                                    Current Charges(Invoice# {accData.currentInvoiceNbr})
                                                </div>
                                                <div className="col-4">
                                                    {FormattingUtils.formatCurrency(accData.currentBalance)}
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-8">
                                                    Total Amount Due
                                                </div>
                                                <div className="col-4">
                                                    {FormattingUtils.formatCurrency(accData.totamtdue)}
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-8">
                                                    Total Balance
                                                </div>
                                                <div className="col-4">
                                                    {FormattingUtils.formatCurrency(accData.currentBalance)}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>)}
            
        </>
    );
}

export default AccountSummary;
