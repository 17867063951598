import { Tooltip } from "react-tippy";

const IconToolTip = () => {

    return (
        <Tooltip html={(
            <span>
                ICON is Windstream Wholesale’s Intelligent Convergent Optical Network. Click to learn more about ICON.
            </span>
        )}
            arrow={true}
            hideDelay={5000}>
            &nbsp;<a href="https://www.windstreamwholesale.com/technology-leadership/#Transport-Optical-Network" target="_blank" rel="noopener noreferrer">ICON</a>&nbsp;
        </Tooltip>
    )
}

export default IconToolTip
