import React, { useEffect, useState } from 'react';
import StripedDataGrid from "../components/tables/StripedDataGrid";
import CustomTheme from "../components/tables/CustomTheme";
import TablePagination from "../components/tables/TablePagination";
import XLSXExport from "../../utils/XLSXExport";

const OrderActivity = ({ reportData, activity }) => {
	const [rows, setRows] = useState([]);
	const [pageSize, setPageSize] = useState(25);
	const [filterModel, setFilterModel] = useState({ items: [] });

	useEffect(() => {
		setRows(reportData);
	}, [reportData]);


	const columns = [
		{ field: 'ref_id', headerName: 'Ref ID', width: 100 },
		{ field: 'Customer PON', headerName: 'Customer PON', width: 150 },
		{ field: 'Order Date', headerName: 'Order Date', width: 150 },
		{ field: 'mpid', headerName: 'MPID', width: 100 },
		{ field: 'company', headerName: 'Company', width: 150 },
		{ field: 'nam', headerName: 'AE', width: 150 },
		{ field: 'acct_type', headerName: 'Account Type', width: 150 },
		{ field: 'product', headerName: 'Product', width: 200 },
		{ field: 'circuits', headerName: 'Circuits', width: 300 },
		{ field: 'Requested Service Date', headerName: activity === 'D' ? 'End Billing Date' : 'Requested Service Date', width: 200 },
		{ field: 'term', headerName: 'Term', width: 100 },
		{ field: 'Bandwidth', headerName: 'Bandwidth', width: 150 },
		{ field: 'Version Number', headerName: 'Version Number', width: 150 },
		{ field: 'Supplement', headerName: 'Supplement', width: 150 },
		{ field: 'Status', headerName: 'Status', width: 150 },
	];

	const formattedDate = new Date().toLocaleDateString('en-US', {
		year: 'numeric',
		month: '2-digit',
		day: '2-digit',
	}).replaceAll('/', '-');

	const XLSXHeaders = ["Reference ID", "Customer PON", "Order Date", "MPID", "Company", "AE", "Account Type", "Product",
		"Circuits", activity === 'D' ? 'End Billing Date' : 'Requested Service Date', "Term", "Bandwidth", "Version Number", "Supplement", "Status"];

	return (
		<>
			<div className='d-flex mb-5 ml-3'>
				<div className="w15 mr-5">
					<button className="saveButton ico-button light-button" onClick={() => XLSXExport.exportToExcel(XLSXHeaders, reportData, `Order_Activity_Report_${formattedDate}.xlsx`)}><span><strong>Export to Excel</strong></span>
						<svg viewBox="0 0 34 34" className={`ico light-ico ico-arrow downloadArrow`}>
							<path className="cls-1" d="M1,18V16L17,31V1h2V31L35,16v2L20,33H35v2H1V33H16Z"
								transform="translate(-1 -1)">
							</path>
						</svg>
					</button>
				</div>
				<div className="w15 ">
					<button className="saveButton ico-button light-button" onClick={() => XLSXExport.exportToExcel(XLSXHeaders, reportData, `Order_Activity_Report_${formattedDate}.csv`)}><span><strong>Export to CSV</strong></span>
						<svg viewBox="0 0 34 34" className={`ico light-ico ico-arrow downloadArrow`}>
							<path className="cls-1" d="M1,18V16L17,31V1h2V31L35,16v2L20,33H35v2H1V33H16Z"
								transform="translate(-1 -1)">
							</path>
						</svg>
					</button>
				</div>
			</div>
			<div style={{ width: '100%' }}>
				<StripedDataGrid
					autoHeight
					theme={CustomTheme}
					rows={rows}
					rowSelection={false}
					getRowId={(row) => row.ref_id}
					columns={columns}
					filterModel={filterModel}
					onFilterModelChange={(model) => setFilterModel(model)}
					pageSize={pageSize}
					rowsPerPageOptions={[]}
					resultsPerPageOptions={[]}
					pageSizeOptions={[]}
					onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
					slots={{ pagination: TablePagination }}
					initialState={{
						pagination: {
							paginationModel: {
								pageSize: 25,
							},
						},
					}}
				/>
			</div>
		</>
	);
};

export default OrderActivity;