import React, { Fragment, useState } from 'react';
import style from "./TicketCreate.module.css";
import { connect, useSelector } from 'react-redux';

import {
	tcDayFri,
	tcDayMon,
	tcDaySat,
	tcDaySun,
	tcDayThur,
	tcDayTues,
	tcDayWed,
	tcErrorMsg,
	tcMonToFri,
	tcSelectDate,
	tcTimeFrom,
	tcTimeTo
} from '../../redux';
import { ConsoleLogger } from "../../logger/ConsoleLogger";

const TicketCreateAuthorization = (props) => {
	const logger = new ConsoleLogger({ level: process.env.REACT_APP_LOGGER_LEVEL, });

	const troubleAuthorization = props.authorizationData;
	const authorizationFromStore = useSelector(state => state?.ticketCreate?.authorization);
	const [copyTimes, setCopyTimes] = useState(false);
	const [timeTo, setTimeTo] = useState(false);
	const [timeFrom, setTimeFrom] = useState(false);

	const inputChange = (obj, e, index) => {
		const value = e.target.value;

		if (obj.name === "selectTimes_FromValue" || obj.name === "selectTimes_ToValue") {
			props[obj.name](value, index);
		} else {
			props[obj.name](value);
		}

		props.updateErrorMsg('');
	}

	const twentyFourBySeven = (crObj, obj, e) => {
		const value = e.target.checked;
		props.selectDate(value);

		// logger.info(authorizationFromStore?.selectDate);
		if (value) {
			obj[1].mandatory = false;
			obj[2].mandatory = false;
		} else {
			obj[1].mandatory = true;
			obj[2].mandatory = true
		}

		troubleAuthorization.forEach(ta => {
			if ((ta.name !== 'selectDate' && ta.type === 'checkbox')) {
				props[ta.name](false);
			}
			if (ta.name === 'selectTimes_FromValue' || ta.name === 'selectTimes_ToValue') {
				props.selectTimes_FromValue('');
				props.selectTimes_ToValue('');

			}
		});
		logger.info(obj);
		props.updateErrorMsg('');
	}

	//Handles the changes on the trouble authorization page
	const troubleAuthorizationDay = async (crObj, e) => {
		let elementValue = e.target.checked;

		//If one of the day options is selected no longer make the 24_7 option mandatory
		troubleAuthorization[0].mandatory = false;

		if (crObj.name === 'monToFri') {
			troubleAuthorization.forEach(ta => {
				if ((ta.name !== 'daySat' && ta.type === 'checkbox') && (ta.name !== 'daySun' && ta.type === 'checkbox')) {
					props[ta.name](elementValue);
					if (!elementValue) {
						props.selectTimes_FromValue("", ta.label);
						props.selectTimes_ToValue("", ta.label);
					}

				}
			});
		}

		if (crObj.name !== 'monToFri') {
			troubleAuthorization.forEach(ta => {
				if (ta.name === 'monToFri') {
					props.monToFri(false);
				}
				if (ta.type === 'checkbox' && ta.name === crObj.name) {
					props[ta.name](elementValue);
				}
			});
		}

		if (!elementValue) {
			props.selectTimes_FromValue("", crObj.label);
			props.selectTimes_ToValue("", crObj.label);
		}
	}

	/*Helper function to allow user to copy hours when the Monday-Friday option is selected*/
	const copyTimeHandler = (event) => {
		setCopyTimes(event.target.checked);
		if (event.target.checked) {
			props.selectTimes_FromValue(timeFrom, "Mon");
			props.selectTimes_FromValue(timeFrom, "Tue");
			props.selectTimes_FromValue(timeFrom, "Wed");
			props.selectTimes_FromValue(timeFrom, "Thu");
			props.selectTimes_FromValue(timeFrom, "Fri");

			props.selectTimes_ToValue(timeTo, "Mon");
			props.selectTimes_ToValue(timeTo, "Tue");
			props.selectTimes_ToValue(timeTo, "Wed");
			props.selectTimes_ToValue(timeTo, "Thu");
			props.selectTimes_ToValue(timeTo, "Fri");
		} else {
			props.selectTimes_FromValue("", "Mon");
			props.selectTimes_FromValue("", "Tue");
			props.selectTimes_FromValue("", "Wed");
			props.selectTimes_FromValue("", "Thu");
			props.selectTimes_FromValue("", "Fri");

			props.selectTimes_ToValue("", "Mon");
			props.selectTimes_ToValue("", "Tue");
			props.selectTimes_ToValue("", "Wed");
			props.selectTimes_ToValue("", "Thu");
			props.selectTimes_ToValue("", "Fri");
		}
	}


	return (
		<div className={`step4 ${style.rowGap}`}>
			{troubleAuthorization && troubleAuthorization.length > 0 ?
				<div className="row">
					<div className={`col-12 col-sm-12 col-md-6`}>
						<div className="row">
							<div className="col-12">
								<label className={` textuppercase ${style.labelText}  ${troubleAuthorization[0]?.hasError ? (style.errorFldText) : null} `}>Select Dates <span className={style.mandatory}>*</span></label>
								<div className="">
									<input type="checkbox" className="" id="247" checked={authorizationFromStore['selectDate']} onChange={(e) => { twentyFourBySeven(troubleAuthorization[0], troubleAuthorization, e) }} />
									<label className={`form-check-label ml-2 textuppercase ${style.labelText}`} htmlFor="247">24/7 Access</label>
								</div>
							</div>

							{authorizationFromStore.selectDate ? '' :
								<div className="col-12">
									<div className={`d-flex ${style.fWrap} ${style.spaceAround}`}>

										{troubleAuthorization ?

											<>
												{troubleAuthorization.map((TD, index) =>
													<Fragment key={TD + '_' + index + 'Auth'}>
														{(TD.type === "checkbox") ?

															<div className="mx-2">
																<label className={`textuppercase ${style.labelText} mb-1`}>{TD.label}</label>
																<div className="text-center">
																	<input type="checkbox" className="" onChange={(e) => { troubleAuthorizationDay(TD, e) }} checked={authorizationFromStore[TD.name]} />
																</div>
															</div>
															: null}
													</Fragment>
												)
												}
											</>
											: null}
									</div>
								</div>

							}

						</div>
					</div>
					{authorizationFromStore.selectDate ? '' :
						<div className={`col-12 col-sm-12 col-md-6 ${style.borderLeft2px}`}>
							<div className="row mb-3">
								<div className="col-12">
									<label className={`textuppercase ${style.labelText}`}>Select Times US/Eastern Time Zone <span className={style.mandatory}> *</span></label>
								</div>

								<>
									{troubleAuthorization.map((TD, index) => {
										if (TD.type === "checkbox" && authorizationFromStore[TD.name]) {
											if (TD.name === "monToFri") {
												return (
													<>
														<div className="col-12">
															<label className={`textuppercase ${style.labelTimeText}`}>Monday - Friday<span className={style.mandatory}> *</span></label>
														</div>
														<div className="row ml-3 mb-2">
															<div className="col-4">
																<label className={`textuppercase ${style.labelTimeText} d-block mb-1 ${troubleAuthorization[1]?.hasError ? (style.errorFldText) : null}`}>From</label>
																<input type="time" placeholder="hh:mm am/pm" className={` ${style.inputFldText} `} onChange={(e) => { setTimeFrom(e.target.value) }} value={timeFrom} />
															</div>
															<div className="col-4">
																<label className={`textuppercase ${style.labelTimeText} d-block mb-1 ${troubleAuthorization[2]?.hasError ? (style.errorFldText) : null}`}>To</label>
																<input type="time" placeholder="hh:mm am/pm" className={` ${style.inputFldText} `} onChange={(e) => { setTimeTo(e.target.value) }} value={timeTo} />
															</div>
															<div className="col-4">
																<label className={`textuppercase ${style.labelText} mb-1`}>Copy Times</label>
																<div className="text-center">
																	<input type="checkbox" className="" onChange={(e) => { copyTimeHandler(e) }} checked={copyTimes} />
																</div>
															</div>
														</div>
													</>
												)
											} else {
												return (
													<>
														<div className="col-12">
															<label className={`textuppercase ${style.labelTimeText}`}>{TD.fullLabel}<span className={style.mandatory}> *</span></label>
														</div>
														<div className="row ml-3 mb-2">
															<div className="col-6">
																<label className={`textuppercase ${style.labelTimeText} d-block mb-1 ${troubleAuthorization[1]?.hasError ? (style.errorFldText) : null}`}>From</label>
																<input type="time" placeholder="hh:mm am/pm" className={` ${style.inputFldText} `} onChange={(e) => { inputChange(troubleAuthorization[1], e, TD.label) }} value={authorizationFromStore['selectTimes_FromValue'][TD.label]} />
															</div>
															<div className="col-6">
																<label className={`textuppercase ${style.labelTimeText} d-block mb-1 ${troubleAuthorization[2]?.hasError ? (style.errorFldText) : null}`}>To</label>
																<input type="time" placeholder="hh:mm am/pm" className={` ${style.inputFldText} `} onChange={(e) => { inputChange(troubleAuthorization[2], e, TD.label) }} value={authorizationFromStore['selectTimes_ToValue'][TD.label]} />
															</div>
														</div>
													</>
												)
											}
										}
										return null;
									}
									)}
								</>

							</div>
						</div>}
				</div>
				: null}


		</div>
	)

}

const mapDispatchToProps = {
	selectDate: tcSelectDate,
	selectTimes_FromValue: tcTimeFrom,
	selectTimes_ToValue: tcTimeTo,
	monToFri: tcMonToFri,
	dayMon: tcDayMon,
	dayTues: tcDayTues,
	dayWed: tcDayWed,
	dayThur: tcDayThur,
	dayFri: tcDayFri,
	daySat: tcDaySat,
	daySun: tcDaySun,
	updateErrorMsg: tcErrorMsg
}

export default connect(null, mapDispatchToProps)(TicketCreateAuthorization);
