import {
	QUOTE_BUS_NAME,
	QUOTE_CONTACT_EMAIL,
	QUOTE_CONTACT_NAME,
	QUOTE_CONTACT_PHONE,
	QUOTE_CUST_REF_ID,
	QUOTE_MANUAL_ICB,
	QUOTE_MANUAL_ICB_COMMENTS,
	QUOTE_NEW_CONTACT_NAME,
	QUOTE_PRICE_MRC
} from "./types/actionTypes"

export const setQuoteContactName = (name) => {
    return {
        type: QUOTE_CONTACT_NAME,
        payload: name
    }
}

export const setNewQuoteContactName = (newName) =>{
    return {
        type: QUOTE_NEW_CONTACT_NAME,
        payload: newName
    }
}

export const setQuotePhone = (phone) => {
    return {
        type: QUOTE_CONTACT_PHONE,
        payload: phone
    }
}

export const setQuoteEmail = (email) => {
    return {
        type: QUOTE_CONTACT_EMAIL,
        payload: email
    }
}

export const setQuoteBusinessName = (busName) => {
    return {
        type: QUOTE_BUS_NAME,
        payload: busName
    }
}

export const setQuoteCusRefID = (refID) => {
    return {
        type: QUOTE_CUST_REF_ID,
        payload: refID
    }
}

export const setQuoteMRCPrice = (price) => {
    return {
        type: QUOTE_PRICE_MRC,
        payload: price
    }
}

export const setQuoteManualICB = (manual_icb) => {
    return {
        type: QUOTE_MANUAL_ICB,
        payload: manual_icb
    }
}

export const setQuoteICBComments = (icb_comments) => {
    return {
        type: QUOTE_MANUAL_ICB_COMMENTS,
        payload: icb_comments
    }
}
