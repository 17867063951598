/**
 * Created by e0173131 on 1/20/2021.
 */

// CSS Imports
import style from "./TroubleTickets.module.css";
import quoteSummaryStyles from "../quote/QuoteSummary.module.css";

/**
 * JS Imports
 *
 */
// React Core Libraries
import {useEffect, useState} from 'react';
import axios from "../../services/axios/customAxios";
import {Link} from 'react-router-dom';

// Local JS Files Imports
import ViewportWarning from "../shared/ViewportWarning";
import {ExportXLSX} from '../../utils/ExportXLSX';
import Loader from "../shared/Loader";
import StringUtils from '../../utils/StringUtils';
import TabsTroubleTicketsSumary from "./includes/TabsTroubleTicketsSumary";

// 3rd party npm Libraries
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, {textFilter} from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ReactTooltip from 'react-tooltip';
import {Tooltip} from 'react-tippy';
import _ from 'underscore';
import {DebounceInput} from 'react-debounce-input';
import {useCurrentWidth} from 'react-breakpoints-hook';
import {ConsoleLogger} from "../../logger/ConsoleLogger";
import HTTPErrorHandler from "../errorPages/HTTPErrorHandler";

const columnsDesktop = [{
    dataField: 'incident_number',
    text: 'Incident',
    sort: true,
    headerAttrs: { id: 'Incident' },
    formatter: (cell, row) => ticketNumberFormatter(cell, row),
    filter: textFilter({
        placeholder: 'Incident'
    }),
}, {
    dataField: 'customerTicket',
    text: 'Customer Ticket Number',
    sort: true,
    filter: textFilter({
        placeholder: 'Customer ticket Number'
    }),
    headerAttrs: { id: 'customerTicket' },
    formatter: (cell, row) => customerNumberFormatter(cell, row)
},
{
    dataField: 'circuitID',
    text: 'Circuit ID',
    sort: true,
    filter: textFilter({
        placeholder: 'circuit ID'
    }),
    headerAttrs: { id: 'circuitID' },
    formatter: (cell, row) => circuidIdFormatter(cell, row)
},

{
    dataField: 'aloc',
    text: 'ALoc',
    sort: true,
    filter: textFilter({
        placeholder: 'ALoc'
    }),
    headerAttrs: { id: 'aloc' },
    formatter: (cell, row) => aLocFormatter(cell, row)

},
{
    dataField: 'zloc',
    text: 'ZLoc',
    sort: true,
    filter: textFilter({
        placeholder: 'ZLoc'
    }),
    headerAttrs: { id: 'zloc' },
    formatter: (cell, row) => zLocFormatter(cell, row)

},
{
    dataField: 'product',
    text: 'Product',
    sort: true,
    filter: textFilter({
        placeholder: 'Product'
    }),
    headerAttrs: { id: 'product' },

},
{
    dataField: 'bw',
    text: 'BW',
    sort: true,
    filter: textFilter({
        placeholder: 'BW'
    }),
    headerAttrs: { id: 'bandwidth' },

},
{
    dataField: 'status',
    text: 'Status',
    sort: true,
    filter: textFilter({
        placeholder: 'Status'
    }),
    headerAttrs: { id: 'status' },
    formatter: (cell, row) => statusFormatter(cell, row)

},
{
    dataField: 'proactive',
    text: 'Proactive',
    sort: true,
    /*filter: textFilter({
        placeholder: 'Proactive'
    }),*/
    headerAttrs: { id: 'proactive' },
    formatter: (cell, row) => proactiveFormatter(cell, row)
}
];

const columnsCompact = [{
    dataField: 'incident_number',
    text: 'Incident',
    sort: true,
    headerAttrs: { id: 'Incident' },
    imgSrc: "ico-spec-green.svg",
    headerFormatter: (cell, row) => headerFormatter(cell, row),
    formatter: (cell, row) => ticketNumberFormatter(cell, row),

}, {
    dataField: 'customerTicket',
    text: 'Customer ticket Number',
    sort: true,

    headerAttrs: { id: 'customerTicket' },
    imgSrc: "ico-spec-green.svg",
    headerFormatter: (cell, row) => headerFormatter(cell, row),
    formatter: (cell, row) => customerNumberFormatter(cell, row)
},
{
    dataField: 'circuitID',
    text: 'circuit ID',
    sort: true,
    headerAttrs: { id: 'circuitID' },
    imgSrc: "ico-transport-green.svg",
    headerFormatter: (cell, row) => headerFormatter(cell, row),
    formatter: (cell, row) => circuidIdFormatter(cell, row)

},

{
    dataField: 'aloc',
    text: 'ALoc',
    sort: true,
    headerAttrs: { id: 'aloc' },
    imgSrc: "ico-location-green.svg",
    headerFormatter: (cell, row) => headerFormatter(cell, row),
    formatter: (cell, row) => aLocFormatter(cell, row)

},
{
    dataField: 'zloc',
    text: 'ZLoc',
    sort: true,
    headerAttrs: { id: 'zloc' },
    imgSrc: "ico-location-green.svg",
    headerFormatter: (cell, row) => headerFormatter(cell, row),
    formatter: (cell, row) => zLocFormatter(cell, row)

},
{
    dataField: 'product',
    text: 'Product',
    sort: true,
    headerAttrs: { id: 'product' },
    imgSrc: "ico-product-green.svg",
    headerFormatter: (cell, row) => headerFormatter(cell, row),

},
{
    dataField: 'bw',
    text: 'BW',
    sort: true,
    headerAttrs: { id: 'bandwidth' },
    imgSrc: "ico-port-green.svg",
    headerFormatter: (cell, row) => headerFormatter(cell, row),

},
{
    dataField: 'status',
    text: 'Status',
    sort: true,
    headerAttrs: { id: 'status' },
    imgSrc: "ico-alert-32-green.svg",
    headerFormatter: (cell, row) => headerFormatter(cell, row),
    formatter: (cell, row) => statusFormatter(cell, row)

},
{
    dataField: 'proactive',
    text: 'Proactive',
    sort: true,
    headerAttrs: { id: 'proactive' },
    imgSrc: "ico-proactive-green.svg",
    headerFormatter: (cell, row) => headerFormatter(cell, row),
    formatter: (cell, row) => proactiveFormatter(cell, row)
}
];

const headerFormatter = (props, row) => {
    const logger = new ConsoleLogger({ level: process.env.REACT_APP_LOGGER_LEVEL, });

    logger.info(props)
    return (
        <>
            <ReactTooltip />
            <span data-tip={props.text}>{props.text}</span>
            <img alt="header-icon" className={style.headerIcon} src={`../images/svg/${props.imgSrc}`} data-tip={props.text}></img>
        </>
    )

}
const ticketNumberFormatter = (props, row) => {
    const URL = `/Network/TicketDetails/${row.incident_number}`;
    return (
        <Link to={URL} target="_blank"> {row.incident_number}</Link>
    );
}
const customerNumberFormatter = (props, row) => {
    return (
        <Link> {row.customerTicket}</Link>
    );
}
const circuidIdFormatter = (props, row) => {
    return (
        <Link> {row.circuitID}</Link>
    );
}
const statusFormatter = (props, row) => {
    return row.status;
}

const proactiveFormatter = (props, row) => {
    return (
        row.proactive ? (<div className="proactive-check">
            <svg className="ico ico-dashboard" />
        </div>) : ''
    );
}

const aLocFormatter = (props, row) => {
    const aLocation = row.aloc;
    return (
        <>
            <ReactTooltip />
            <span data-tip={aLocation}>{aLocation.substring(0, 10)}</span>
        </>
    )
}

const zLocFormatter = (props, row) => {
    const zLocation = row.zloc;
    return (
        <>
            <ReactTooltip />
            <span data-tip={zLocation}>{zLocation.substring(0, 10)}</span>
        </>
    )
}

const TroubleTicketsSummary = () => {
    const logger = new ConsoleLogger({ level: process.env.REACT_APP_LOGGER_LEVEL, });
    const [hasError, setHasError] = useState(null);
    const API_URL = `${process.env.REACT_APP_API_URL}/getTroubleTickets.php`;
    const width = useCurrentWidth();
    const [troubleTicketsData, setTroubleTicketsData] = useState([]);
    const [loader, setLoader] = useState(false);
    const [copyTroubleTicketsData, setCopyTroubleTicketsData] = useState([]);
    const [ticketdIdError, setTicketdIdError] = useState(false);
    const [popupErrorMsg, setPopupErrorMsg] = useState('');
    const [enableDesktop, setEnableDesktop] = useState(true);
    const [enableSearch, setEnableSearch] = useState(false);
    const [fromDate, setFromDate] = useState(undefined);
    const [toDate, setToDate] = useState(undefined);
    const [past30DaysSelected, setPast30DaysSelected] = useState(false);
    const [totalNoOfOpenTickets, setTotalNoOfOpenTickets] = useState(0);
    const [filterForm, setFilterForm] = useState({});

    // This is equivalent to ComponentDidMount
    useEffect(() => {
        getTroubleTickets();
        let formControls = filterForm;
        formControls['fromDate'] = '';
        formControls['toDate'] = '';
        setFilterForm(formControls);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        getDimensions();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [width]);

    /*componentWillUnmount() {
        window.removeEventListener('resize', this.getDimensions); 
      }
      */

    const getDimensions = () => {
        if (width <= 992) {
            setEnableDesktop(false)
        } else {
            setEnableDesktop(true)
        }
    }

    const desktopToggle = () => {
        setEnableDesktop(!enableDesktop);
        setEnableSearch(false);
    }

    const searchToggle = () => {
        setEnableSearch(!enableSearch);
        setEnableDesktop(true);
    }



    const getTroubleTickets = async () => {
        setLoader(true);
        await axios.get(API_URL).then(resp => {
            setTroubleTicketsData(resp.data);
            setCopyTroubleTicketsData(resp.data);
            setTotalNoOfOpenTickets(resp.data.length);
            setLoader(false);
        }).catch(error => {
            logger.error("getTroubleTickets ERROR in TroubleTicketsSummary Page: " + error)
            setHasError(error);
        })
    }

    const onChangeWindstreamTicket = (ev) => {
        let inputValue = ev.target.value.trim();
        let ticket;
        if (inputValue) {
            ticket = _.filter(copyTroubleTicketsData, (troubleTicketObj) => {
                return StringUtils.stringIncludes(troubleTicketObj.customerTicket, inputValue);
            });
            if (ticket && ticket.length > 0) {
                setTroubleTicketsData(ticket)
            } else {
                setTroubleTicketsData([])
            }
        } else { // for empty search box
            setTroubleTicketsData(copyTroubleTicketsData)
        }
    }

    const closeModal = () => {
        setTicketdIdError(false);
        setPopupErrorMsg('');
    }

    const dateChangeFrom = async (eve) => {
        let fromDateSelected = eve.target.value;
        let formControls = filterForm;
        formControls['fromDate'] = fromDateSelected;
        setFromDate(eve.target.value);
        setFilterForm(formControls);
        if (filterForm['fromDate'] !== "" && filterForm['toDate'] !== "") {
            dateValidationFinal();
        }
    }

    const dateChangeTo = async (eve) => {
        let toDateSelected = eve.target.value;
        let formControls = filterForm;
        formControls['toDate'] = toDateSelected;
        setToDate(toDateSelected);
        setFilterForm(formControls)
        if (filterForm['fromDate'] !== "" && filterForm['toDate'] !== "") {
            dateValidationFinal();
        }
    }

    const dateValidationFinal = async () => {
        let filterDate = copyTroubleTicketsData.filter(TT => TT.ticket.reportedDate.substring(0, 10) >= filterForm['fromDate'] && TT.ticket.reportedDate.substring(0, 10) <= filterForm['toDate']);
        setTroubleTicketsData(filterDate);
    }

    const past30Days = async (selectedValue) => {
        const selectedDays = parseInt(selectedValue);
        if (selectedDays === 0) {
            setTroubleTicketsData(copyTroubleTicketsData);
        } else {
            const today = new Date();
            const pastDate = new Date().setDate(today.getDate() - selectedDays);
            const todayDateSubstring = new Date().toISOString().substring(0, 10);
            const pastDateSubstring = new Date(pastDate).toISOString().substring(0, 10);
            const filterData = troubleTicketsData.filter(TT => (TT.ticket.reportedDate.substring(0, 10) <= todayDateSubstring) && (TT.ticket.reportedDate.substring(0, 10) >= pastDateSubstring));
            setTroubleTicketsData(filterData);
        }
    }

    const getOpenTickets = async () => {
        let formControls = filterForm;
        formControls['fromDate'] = '';
        formControls['toDate'] = '';
        setPast30DaysSelected(false);
        setFromDate(undefined);
        setFilterForm(formControls);
        getTroubleTickets();
    }

    return (
        <>
            <ReactTooltip />
            {loader ? <Loader /> : null}
            {hasError && <HTTPErrorHandler error={hasError}></HTTPErrorHandler>}
            <div className="midsection qm-summary quote-summary abs z1">
                <div className="rel">
                    <ViewportWarning />
                    <div className="wrap">
                        <h1 className="page-title">Trouble Tickets</h1>
                        <div className="section">
                            {/* Tabs */}
                            <TabsTroubleTicketsSumary />
                            {!hasError && (<div className={`content ${quoteSummaryStyles.contentQueryPage} `}>
                                <div className="align-center">
                                    {/* <GridFilter /> */}
                                    <div className={quoteSummaryStyles.filterWrap}>
                                        <div className={quoteSummaryStyles.dateFieldWrap}>
                                            <select className={` ${quoteSummaryStyles.dateSelect}`} onChange={(event) => past30Days(event.target.value)}>
                                                <option value={15}>Past 15 Days</option>
                                                <option value={30} selected>Past 30 Days</option>
                                                <option value={60}>Past 60 Days</option>
                                                <option value={0}>Beginning of Time</option>
                                            </select>
                                        </div>
                                        <div className={quoteSummaryStyles.dateRange}>
                                            <label htmlFor="date-from">From</label>
                                            <input type="date" id="date-from" name="date-start" value={filterForm['fromDate'] ? filterForm['fromDate'] : '' || ''} onChange={event => dateChangeFrom(event)} />
                                            <label htmlFor="start" className="ml-4">To</label>
                                            <input type="date" id="date-to" name="date-end" min={fromDate} value={filterForm['toDate'] ? filterForm['toDate'] : '' || ''} onChange={event => dateChangeTo(event)} />
                                        </div>
                                    </div>
                                </div>

                                <div className={` ${style.tableWrap} tableWrap quoteSummaryTableWrap`}>
                                    <div className={`rel pagination-table troubleTicketTable ${enableSearch ? 'searchableEnableTable' : null} ${enableDesktop ? null : 'desktopViewTable'} `}>
                                        {troubleTicketsData.length > 0 ?
                                            <BootstrapTable id="quoteSummaryTable"
                                                keyField='id'
                                                data={troubleTicketsData}
                                                columns={enableDesktop ? columnsDesktop : columnsCompact}
                                                striped
                                                hover
                                                condensed
                                                bordered={false}
                                                pagination={paginationFactory({
                                                    sizePerPage: 5
                                                })}
                                                filter={filterFactory()}
                                                defaultSortDirection="desc"
                                                className="mb-0"
                                            />
                                            : null
                                        }

                                        {
                                            troubleTicketsData.length === 0 ?
                                                <div className={`text-center ${style.noDataWrap} `}>
                                                    <h1 className="m-0">No Data Available</h1>
                                                </div>
                                                : null
                                        }
                                    </div>
                                </div>

                                {/* footer */}
                                <div className={`footer-info-panel ${style.fixedFooter} `}>
                                    <div className={`d-flex align-items-center ${style.spaceBetween}`}>
                                        <div className={`${style.footerGrid} ${style.pointer}`} onClick={getOpenTickets}>
                                            <span className={` ${style.labelText} text-white`}>Open Tickets</span>
                                            <span className={`rel ${style.circleWrap}`}>
                                                <span className={` ${style.labelText} ${style.posText} text-white`}>{totalNoOfOpenTickets}</span>
                                            </span>
                                        </div>
                                        <div className={`${style.footerGrid} ${style.searchableParams}`}>
                                            <div id="widget-priority" className={` toggle-switch toggle-switch-sm ${enableSearch ? 'on' : ''}`} onClick={searchToggle}>
                                                <div className="ts-bar">
                                                    <div className="ts-left-edge">&nbsp;</div>
                                                    <div className="ts-right-edge">&nbsp;</div>
                                                    <div className="ts-switch">&nbsp;</div>
                                                </div>
                                            </div>
                                            <p className={`  ts-label ts-label-new ${style.labelText} text-secondary`} >SEARCHABLE PARAMETERS</p>
                                        </div>
                                        <div className={`${style.footerGrid}`}>
                                            <Link to="/Ticket/Create" className={` ${style.labelText} ${style.textTheme}`}> Create Ticket
                                                <svg className={` ico ${style.createTicketIco} `}><path className={style.pathTheme} d="M16,8.9h2v10H16Zm2,14H16v-2h2Zm14-1H30l-1-1v-7a12.9,12.9,0,0,0-1.73-6.32A11.55,11.55,0,0,0,23,3.33a11.93,11.93,0,0,0-12,0A11.55,11.55,0,0,0,6.73,7.58,12.9,12.9,0,0,0,5,13.9v8A8.87,8.87,0,0,0,6.73,27,11.14,11.14,0,0,0,11,30.91c1.86,1,3.87,1,6,1h3v-1H16l-1-1,1-1h4c.68,0,.88.35,1.37.85a2.44,2.44,0,0,1,.73,1.77,2.38,2.38,0,0,1-.73,1.75A1.54,1.54,0,0,1,20,33.9H17a13.92,13.92,0,0,1-6.83-1.63,12.74,12.74,0,0,1-4.9-4.56A13.59,13.59,0,0,1,3,20.9v-7A14.63,14.63,0,0,1,5.27,6.77a13.24,13.24,0,0,1,4.9-4.9,13.41,13.41,0,0,1,13.66,0,13.24,13.24,0,0,1,4.9,4.9A14.63,14.63,0,0,1,31,13.9v6h1v-6l1.08-1.08L34,13.9v7Zm-32-2v-6l1-1,1,1v6l-1,1Z"></path></svg>
                                            </Link>
                                        </div>
                                    </div>
                                    <div className={`d-flex align-items-center ${style.spaceBetween}`}>
                                        <div className={`${style.footerGrid} w-50 rel searchWrap d-flex`}>
                                            <DebounceInput type="search" className={` ${style.searchFld}`}
                                                placeholder="Search by Ticket Number" debounceTimeout={300} onKeyUp={event => onChangeWindstreamTicket(event)} />
                                            <img alt={""} className={style.searchIcoInputField} src="../../images/svg/ico-search-fill.svg" />
                                            <img data-tip="Search on Incident and Customer Ticket Number" className={`tipped cornertip d-flex ${style.posRight} ${style.infoIco}`} src="../../images/svg/ico-info-filled-dark-off.svg" width="36" alt="" >
                                            </img>
                                            <Tooltip
                                                html={(
                                                    <infoIconHover />
                                                )}
                                                position={'left-start'} delay={200} hideDelay={200}
                                            >
                                                {/* <img className={`tipped cornertip d-flex ${style.posRight} ${style.infoIco}`} src="../../images/svg/ico-info-filled-dark-off.svg" width="36" alt="" /> */}
                                            </Tooltip>
                                        </div>
                                        <div className={`${style.footerGrid} ${style.desktopView}`}>
                                            <div id="widget-priority" className={` toggle-switch toggle-switch-sm ${style.toggleSwitch} ${enableDesktop ? 'green-toggle on' : ''}`} onClick={desktopToggle}>
                                                <div className="ts-bar">
                                                    <div className="ts-left-edge">&nbsp;</div>
                                                    <div className="ts-right-edge">&nbsp;</div>
                                                    <div className={` ts-switch ${style.tsGreenBG} `}>&nbsp;</div>
                                                </div>
                                            </div>
                                            <p className={`  ts-label ts-label-new ${style.labelText} text-secondary mb-0`} >DESKTOP VIEW</p>
                                        </div>
                                        <div className={`${style.footerGrid}`}>
                                            {/* <a className={` ${style.labelText} ${style.textTheme} ${style.textCenter}`}>EXPORT
                                            
                                            </a>
                                            <svg viewBox="0 0 34 34" className={` ico light-ico ico-arrow  ${style.downloadIco}`}>
                                                    <path className={` ${style.pathTheme}`} d="M1,18V16L17,31V1h2V31L35,16v2L20,33H35v2H1V33H16Z" transform="translate(-1 -1)"></path>
                                                </svg> */}
                                            <button className={` ${style.textThemeSVG} exportBtnTroubleTicket rel`}>
                                                <ExportXLSX label="Export" csvData={troubleTicketsData} fileName={`Dashboard Export_${Date.now()}`} />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                {/* footer end */}
                            </div>)
                            }
                        </div>
                    </div>
                </div>
            </div>

            {!hasError && (ticketdIdError ?
                <div>
                    <div className={`${style.overly}`}> </div>
                    <div className="modal fade show d-block">
                        <div className="modal-dialog modal-dialog-centered">
                            <div className="modal-content">
                                <div className="modal-body text-center">
                                    {popupErrorMsg}
                                </div>
                                <div className="modal-footer justify-content-center">
                                    <button type="button" className="btn-style btn-cancel" data-dismiss="modal" onClick={closeModal}>Close</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                : null)}
        </>
    );
}

export default TroubleTicketsSummary;
