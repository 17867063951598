/**
 * Created by Boby on 10/1/2021.
 */

// CSS Imports
import style from "../network/TroubleTickets.module.css";

/**
 * JS Imports
 *
 */
// React Core Libraries
import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';

// Local JS Files Imports
import ViewportWarning from "../shared/ViewportWarning";
import TabsQuote from "./includes/TabsQuote";
import StringUtils from '../../utils/StringUtils';
import Loader from "../shared/Loader";
import {getMaintenanceEventHistory} from "../../services/TicketsHistoryService";
import DateUtils from '../../utils/DateUtils';

// 3rd party npm Libraries
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, {textFilter} from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import {ExportXLSX} from '../../utils/ExportXLSX';
import ReactTooltip from 'react-tooltip';
import {Tooltip} from 'react-tippy';
import {DebounceInput} from 'react-debounce-input';
import _ from 'underscore';
import {useCurrentWidth} from 'react-breakpoints-hook';
import {ConsoleLogger} from "../../logger/ConsoleLogger";
import HTTPErrorHandler from "../errorPages/HTTPErrorHandler";

const columns = [{
    dataField: 'eventNumber',
    text: 'Event Number',
    sort: true,
    headerAttrs: { id: 'eventNumber' },
    filter: textFilter({
        placeholder: 'Event Number'
    }),
},
{
    dataField: 'impactType',
    text: 'Impact Type',
    sort: true,
    filter: textFilter({
        placeholder: 'Impact Type'
    }),
    headerAttrs: { id: 'impactType' },
    /*formatter: (cell, row) => troubleTypeFormatter(cell, row)*/
},
{
    dataField: 'impactDuration',
    text: 'Impact Duration',
    sort: true,
    filter: textFilter({
        placeholder: 'Impact Duration'
    }),
    headerAttrs: { id: 'impactDuration' },
    /*formatter: (cell, row) => troubleTypeFormatter(cell, row)*/
},
{
    dataField: 'windowStart',
    text: 'Window Start',
    sort: true,
    filter: textFilter({
        placeholder: 'Window Start'
    }),
    headerAttrs: { id: 'windowStart' },
    /*formatter: (cell, row) => troubleTypeFormatter(cell, row)*/
},
{
    dataField: 'windowEnd',
    text: 'Window End',
    sort: true,
    filter: textFilter({
        placeholder: 'Window End'
    }),
    headerAttrs: { id: 'windowEnd' },
    /*formatter: (cell, row) => troubleTypeFormatter(cell, row)*/
},
{
    dataField: 'status',
    text: 'Status',
    sort: true,
    filter: textFilter({
        placeholder: 'Status'
    }),
    headerAttrs: { id: 'status' },
    /*formatter: (cell, row) => statusFormatter(cell, row)*/

}
];

const columnsDesktop = [{
    dataField: 'eventNumber',
    text: 'Event Number',
    sort: true,
    headerAttrs: { id: 'eventNumber' },
    headerClass: '',
    imgSrc: "ico-spec-white.svg",
    headerFormatter: (cell) => headerFormatter(cell)
},
{
    dataField: 'impactType',
    text: 'Impact Type',
    sort: true,
    headerAttrs: { id: 'impactType' },
    headerClass: '',
    imgSrc: "ico-alert-32-white.svg",
    headerFormatter: (cell) => headerFormatter(cell),
    /*formatter: (cell, row) => troubleTypeFormatter(cell, row)*/
},
{
    dataField: 'impactDuration',
    text: 'Impact Duration',
    sort: true,
    headerAttrs: { id: 'impactDuration' },
    headerClass: '',
    imgSrc: "ico-clock-white.svg",
    headerFormatter: (cell) => headerFormatter(cell),
    /*formatter: (cell, row) => troubleTypeFormatter(cell, row)*/
},
{
    dataField: 'windowStart',
    text: 'Window Start',
    sort: true,
    headerAttrs: { id: 'windowStart' },
    headerClass: 'headerIconForTroubleTicketsHistory',
    imgSrc: "ico-events-white.svg",
    headerFormatter: (cell) => headerFormatter(cell),
    /*formatter: (cell, row) => troubleTypeFormatter(cell, row)*/
},
{
    dataField: 'windowEnd',
    text: 'Window End',
    sort: true,
    headerAttrs: { id: 'windowEnd' },
    headerClass: 'headerIconForTroubleTicketsHistory',
    imgSrc: "ico-events-white.svg",
    headerFormatter: (cell) => headerFormatter(cell),
    /*formatter: (cell, row) => troubleTypeFormatter(cell, row)*/
},
{
    dataField: 'status',
    text: 'Status',
    sort: true,
    headerAttrs: { id: 'status' },
    headerClass: '',
    imgSrc: "ico-alert-32-white.svg",
    headerFormatter: (cell) => headerFormatter(cell),
    /*formatter: (cell, row) => statusFormatter(cell, row)*/

}
];

const headerFormatter = (props) => {
    return (
        <>
            <ReactTooltip />
            <img alt="header-icon" className={`${style.headerIcon} ${style.headerIconForTroubleTicketsHistory}`} src={`../images/svg/${props.imgSrc}`} data-tip={props.text}></img>
        </>
    )
}

const Maintenance = () => {
    const logger = new ConsoleLogger({level: process.env.REACT_APP_LOGGER_LEVEL,});
    const [hasError, setHasError] = useState(null);

    /**
     * The below circuit id is coming from url params from 
     * network page for the first time. When we switch to 
     * another tab and come back to this tab, it will throw 
     * error. To avoid the error it is hardcoded currently.
     * As a permanent solution it has to be either stored in some 
     * kind of storage like 'localStorage' or 'Redux'
     */
    //const { circuitId } = useParams();
    const circuitId = 'TXKJUS112429WXN'
    let timerid = null;
    let width = useCurrentWidth();
    const [troubleTicketsData, setTroubleTicketsData] = useState([]);
    const [copyTroubleTicketsData, setCopyTroubleTicketsData] = useState([]);
    const [ticketdIdError, setTicketdIdError] = useState(false);
    const [popupErrorMsg, setPopupErrorMsg] = useState('');
    const [enableDesktop, setEnableDesktop] = useState(false);
    const [enableSearch, setEnableSearch] = useState(false);
    const [fromDate, setFromDate] = useState(undefined);
    // const [toDate, setToDate] = useState(undefined);
    const [past30DaysSelected, setPast30DaysSelected] = useState(false);
    const [totalNoOfOpenTickets, setTotalNoOfOpenTickets] = useState(0);
    const [filterForm, setFilterForm] = useState({});
    const [loader, setLoader] = useState(false);

    // This is equivalent to ComponentDidMount
    useEffect(() => {
        // const selectedCircuit = Array.prototype.find(obj => {
        Array.prototype.find(obj => {
            logger.info(obj.circuit)
            logger.info(obj.circuit.toUpperCase())
            return obj.circuit.toUpperCase() === circuitId.toUpperCase()
        });
        logger.info('<<<<<<<< SELECTED CIRCUIT OBJECT EQUALITY >>>>>>>')

        logger.info(circuitId)
        //dispatch(allActions.networkHealthActions.setSelectedCircuit(selectedCircuit));
        getTroubleTickets(circuitId);
        let formControls = filterForm;
        formControls['fromDate'] = '';
        formControls['toDate'] = '';
        setFilterForm(formControls);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        getDimensions();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [width]);

    //actually set the state to the window dimensions
    const getDimensions = () => {
        if (width <= 992) {
            setEnableDesktop(true)
        } else {
            setEnableDesktop(false)
        }
    }

    const desktopToggle = () => {
        setEnableDesktop(!enableDesktop);
        setEnableSearch(false);
        /*this.setState(prevState => ({
            enableDesktop: !prevState.enableDesktop,
            enableSearch: false,

        }))*/
    }

    const searchToggle = () => {
        setEnableSearch(!enableSearch);
        setEnableDesktop(false);
        /*this.setState(prevState => ({
            enableSearch: !prevState.enableSearch,
            enableDesktop: false

        }))*/
    }

    const getTroubleTickets = async (circuitId) => {
        setLoader(true)
        getMaintenanceEventHistory(circuitId).then(resp => {
            setTroubleTicketsData(resp);
            logger.info('<<<<<<<< settroubleticketsdata before past90Days')
            logger.info(resp)
            logger.info(encodeURIComponent(troubleTicketsData).replace(/%20/g, " "))
            setCopyTroubleTicketsData(resp);
            past90Days(resp);
            setTotalNoOfOpenTickets(resp.length);
            if (timerid) {
                clearTimeout(timerid);
            }
            timerid = setTimeout(() => {
                setLoader(false)
            }, 200);
        }).catch((error) => {
            logger.error("getMaintenanceEventHistory ERROR: " + error)
            setHasError(error);
        });
    }

    const onChangeWindstreamTicket = (ev) => {
        const inputValue = ev.target.value.trim();
        let findCustTicket;
        if (ev.keyCode === 13) { // enter key
        }
        if (inputValue) {
            setLoader(true)
            setTimeout(() => {
                findCustTicket = _.filter(copyTroubleTicketsData, (eachObj) => {
                    return StringUtils.stringIncludes(eachObj.eventNumber, inputValue);
                });
                if (findCustTicket && findCustTicket.length > 0) {
                    setLoader(false);
                    setTroubleTicketsData(findCustTicket)
                } else {
                    setLoader(false);
                    setTroubleTicketsData([]);
                    setTicketdIdError(true);
                    setPopupErrorMsg(`Unable to find '${inputValue}' Ticket Number. 
                    Please try again or contact our repair center at NNN-NNN-NNNN`);
                }
            }, 1000);
        } else { // for empty search box
            setLoader(false);
            setTroubleTicketsData(copyTroubleTicketsData);
        }
    };

    const closeModal = () => {
        setTicketdIdError(false);
        setPopupErrorMsg('');
    }

    const dateChangeFrom = async (eve) => {
        logger.info('<<<<<< dateChangeFrom >>>>>>')
        let fromDateSelected = eve.target.value;
        let formControls = filterForm;
        formControls['fromDate'] = fromDateSelected;
        setFromDate(eve.target.value);
        setFilterForm(formControls);
        if (filterForm['fromDate'] !== "" && filterForm['toDate'] !== "") {
            dateValidationFinal();
        }
    }

    const dateChangeTo = async (eve) => {
        logger.info('<<<<<< dateChangeTo >>>>>>')
        let toDateSelected = eve.target.value;
        let formControls = filterForm;
        formControls['toDate'] = toDateSelected;
        // setToDate(toDateSelected);
        setFilterForm(formControls);
        if (filterForm['fromDate'] !== "" && filterForm['toDate'] !== "") {
            dateValidationFinal();
        }
    }

    const dateValidationFinal = async () => {
        logger.info('<<< dateValidationFinal >>>')
        if (past30DaysSelected) {
            setPast30DaysSelected(false);
        }
        let filteredDate = copyTroubleTicketsData.filter(TT => TT.windowStart.substring(0, 10) >=
            filterForm['fromDate'] && TT.windowStart.substring(0, 10) <= filterForm['toDate']);
        setTroubleTicketsData(filteredDate)
    }

    const past30Days = () => {
        // If 2nd time past30Days button is clicked then it won't perform anything.
        if (past30DaysSelected) {
            return;
        }
        resetAllInputData();
        let today = new Date();
        let todaydateSubstring = new Date().toISOString().substring(0, 10);
        let timestamp = new Date().setDate(today.getDate() - 30);
        let past30Days = new Date(timestamp).toISOString().substring(0, 10);
        let filterData = copyTroubleTicketsData.filter(TT => (TT.windowStart.substring(0, 10) >=
            past30Days) && (todaydateSubstring >= TT.windowStart.substring(0, 10)));
        setPast30DaysSelected(!past30DaysSelected);
        setTroubleTicketsData(DateUtils.sortArrByDateProperty(filterData, 'windowStart', 'desc'));
        /*
        this.setState(prevState => ({
            past30DaysSelected: !prevState.past30DaysSelected,
            troubleTicketsData: filterData
        }))
        */
    }

    const past90Days = (data) => {
        const ticketsData = copyTroubleTicketsData ?
            (copyTroubleTicketsData.length > 0 ? copyTroubleTicketsData : data) : data;
        let today = new Date();
        let todaydateSubstring = new Date().toISOString().substring(0, 10);
        let timestamp = new Date().setDate(today.getDate() - 90);
        let past90Days = new Date(timestamp).toISOString().substring(0, 10);
        let filterData = ticketsData.filter(TT => {
            return (TT.windowStart.substring(0, 10) >= past90Days) && (todaydateSubstring >= TT.windowStart.substring(0, 10))
        });
        setTroubleTicketsData(DateUtils.sortArrByDateProperty(filterData, 'windowStart', 'desc'));
    }

    const resetDays = () => {
        past90Days();
        resetAllInputData();
    }

    const resetAllInputData = () => {
        const formControls = filterForm;
        formControls['fromDate'] = '';
        formControls['toDate'] = '';
        setFilterForm(formControls);
        setPast30DaysSelected(false);
    }

    const getOpenTickets = async () => {
        let formControls = filterForm;
        formControls['fromDate'] = '';
        formControls['toDate'] = '';
        setPast30DaysSelected(false);
        setFromDate(undefined);
        setFilterForm(formControls);
        getTroubleTickets();
    }

    return (
        <>
            <ReactTooltip />
            {loader ? <Loader /> : null}
            {hasError && (
                <HTTPErrorHandler error={hasError}></HTTPErrorHandler>
            )}
            <div id="maintenance-history-midsection" className="midsection qm-summary abs z1">
                <div className="rel">
                    <ViewportWarning />
                    <div id="maintenance-history-wrap" className="wrap">
                        <div id="maintenance-history-header-links" className="header-links">
                            <span>Circuit Health</span>
                        </div>
                        <h1 className="page-title">
                            <span>CA/KJUS/795678/ /WXN /</span>
                        </h1>
                        <div className="section TroubleTicket-page">
                            {/* <TabsQuoteManager/> */}
                            <TabsQuote />
                            {!hasError && 
                            (<div className="content">
                                <div className={`rel table-style-wrap pagination-table page-list mb-3 troubleTicketTable troubleTicketHistoryTable ${enableSearch ? 'searchableEnableTable' : null} ${enableDesktop ? 'desktopViewTable' : null} `}>
                                    {troubleTicketsData.length > 0 ?
                                        <>
                                            <BootstrapTable id="troubleTicket"
                                                keyField='id'
                                                data={troubleTicketsData}
                                                columns={enableDesktop ? columnsDesktop : columns}
                                                hover
                                                condensed
                                                bordered={false}
                                                pagination={paginationFactory()}
                                                filter={filterFactory()}
                                                defaultSortDirection="desc"
                                                className="mb-0"
                                            />
                                            <div className={style.footerdateSearchWrap}>
                                                <div className={`${style.dateWrap}`}>
                                                    <button className={` ${style.btnTextDate} `} onClick={resetDays}>
                                                        <img alt={""} className={`${style.timeIcon}`} src="../../images/svg/ico-clock.svg" />
                                                        Reset days
                                                    </button>
                                                    <button className={` ${style.btnTextDate}  ${past30DaysSelected ? style.btnActive : ''} `} onClick={past30Days}>
                                                        <img alt={""} className={`${style.timeIcon}`} src="../../images/svg/ico-clock.svg" />
                                                        Past 30 days
                                                    </button>
                                                    <div className={`rel ${style.dateFieldWrap} `}>
                                                        <span className={` ${style.dateLabel} ${style.dateFrom}`}>From</span>
                                                        <input type="date" className={`form-control ml-0 ${style.dateField} ${style.borderRight0}`} value={filterForm['fromDate'] ? filterForm['fromDate'] : '' || ''} onChange={(ev) => dateChangeFrom(ev)} />
                                                    </div>

                                                    <div className={`rel ${style.dateFieldWrap} `}>
                                                        <span className={` ${style.dateLabel} ${style.dateTo}`}>To</span>
                                                        <input type="date" className={`form-control ${style.dateField} ${style.borderLeft0}`} min={fromDate} value={filterForm['toDate'] ? filterForm['toDate'] : '' || ''} onChange={(ev) => dateChangeTo(ev)} />
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                        : null
                                    }
                                    {
                                        troubleTicketsData.length === 0 ?
                                            <div className={`text-center ${style.noDataWrap} `}>
                                                <h1 className="m-0">No Data Available</h1>
                                            </div>
                                            : null
                                    }
                                </div>
                                {/* footer */}
                                <div className={`footer-info-panel ${style.fixedFooter} `}>
                                    <div className={`d-flex align-items-center ${style.spaceBetween}`}>
                                        <div className={`${style.footerGrid} ${style.pointer}`} onClick={getOpenTickets}>
                                            <span className={` ${style.labelText} text-white`}>Open Tickets</span>
                                            <span className={`rel ${style.circleWrap}`}>
                                                <span className={` ${style.labelText} ${style.posText} text-white`}>{totalNoOfOpenTickets}</span>
                                            </span>
                                        </div>
                                        <div className={`${style.footerGrid} ${style.searchableParams}`}>
                                            <div id="widget-priority" className={` toggle-switch toggle-switch-sm ${enableSearch ? 'on' : ''}`} onClick={searchToggle}>
                                                <div className="ts-bar">
                                                    <div className="ts-left-edge">&nbsp;</div>
                                                    <div className="ts-right-edge">&nbsp;</div>
                                                    <div className="ts-switch">&nbsp;</div>
                                                </div>
                                            </div>
                                            <p className={`  ts-label ts-label-new ${style.labelText} text-secondary`} >SEARCHABLE PARAMETERS</p>
                                        </div>
                                        <div className={`${style.footerGrid}`}>
                                            <Link to="/Ticket/Create" target="_blank" className={` ${style.labelText} ${style.textTheme}`}> Create Ticket
                                                <svg className={` ico ${style.createTicketIco} `}><path className={style.pathTheme} d="M16,8.9h2v10H16Zm2,14H16v-2h2Zm14-1H30l-1-1v-7a12.9,12.9,0,0,0-1.73-6.32A11.55,11.55,0,0,0,23,3.33a11.93,11.93,0,0,0-12,0A11.55,11.55,0,0,0,6.73,7.58,12.9,12.9,0,0,0,5,13.9v8A8.87,8.87,0,0,0,6.73,27,11.14,11.14,0,0,0,11,30.91c1.86,1,3.87,1,6,1h3v-1H16l-1-1,1-1h4c.68,0,.88.35,1.37.85a2.44,2.44,0,0,1,.73,1.77,2.38,2.38,0,0,1-.73,1.75A1.54,1.54,0,0,1,20,33.9H17a13.92,13.92,0,0,1-6.83-1.63,12.74,12.74,0,0,1-4.9-4.56A13.59,13.59,0,0,1,3,20.9v-7A14.63,14.63,0,0,1,5.27,6.77a13.24,13.24,0,0,1,4.9-4.9,13.41,13.41,0,0,1,13.66,0,13.24,13.24,0,0,1,4.9,4.9A14.63,14.63,0,0,1,31,13.9v6h1v-6l1.08-1.08L34,13.9v7Zm-32-2v-6l1-1,1,1v6l-1,1Z"></path></svg>
                                            </Link>
                                        </div>
                                    </div>
                                    <div className={`d-flex align-items-center ${style.spaceBetween}`}>
                                        <div className={`${style.footerGrid} w-50 rel searchWrap d-flex`}>
                                            <DebounceInput type="search" className={` ${style.searchFld}`}
                                                placeholder="Search by Event Number" debounceTimeout={500} onKeyUp={event => onChangeWindstreamTicket(event)} />
                                            <img alt={""} className={style.searchIcoInputField} src="../../images/svg/ico-search-fill.svg" />
                                            <img data-tip="Search on Event Number" className={`tipped cornertip d-flex ${style.posRight} ${style.infoIco}`} src="../../images/svg/ico-info-filled-dark-off.svg" width="36" alt="" />
                                            <Tooltip
                                                html={(
                                                    <infoIconHover />

                                                )}
                                                position={'left-start'} delay={200} hideDelay={200}
                                            >
                                                {/* <img className={`tipped cornertip d-flex ${style.posRight} ${style.infoIco}`} src="../../images/svg/ico-info-filled-dark-off.svg" width="36" alt="" /> */}
                                            </Tooltip>
                                        </div>
                                        <div className={`${style.footerGrid} ${style.desktopView}`}>
                                            <div id="widget-priority" className={` toggle-switch toggle-switch-sm ${style.toggleSwitch} ${enableDesktop ? 'on' : ''}`} onClick={desktopToggle}>
                                                <div className="ts-bar">
                                                    <div className="ts-left-edge">&nbsp;</div>
                                                    <div className="ts-right-edge">&nbsp;</div>
                                                    <div className={` ts-switch ${style.tsGreenBG} `}>&nbsp;</div>
                                                </div>
                                            </div>
                                            <p className={`  ts-label ts-label-new ${style.labelText} text-secondary mb-0`} >DESKTOP VIEW</p>
                                        </div>
                                        <div className={`${style.footerGrid}`}>
                                            <button className={` ${style.textThemeSVG} exportBtnTroubleTicket rel`}>
                                                <ExportXLSX label="Export" csvData={troubleTicketsData} fileName={`Dashboard Export_${Date.now()}`} />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                {/* footer end */}
                            </div>)
                            }
                        </div>
                    </div>
                </div>
            </div>

            {/* Error modal popup starts */}
            {!hasError && (ticketdIdError ?
                <div>
                    <div className={`${style.overly}`}> </div>
                    <div className="modal fade show d-block">
                        <div className="modal-dialog modal-dialog-centered">
                            <div className="modal-content">
                                <div className="modal-body text-center">
                                    {popupErrorMsg}
                                </div>
                                <div className="modal-footer justify-content-center">
                                    <button type="button" className="btn-style btn-cancel" data-dismiss="modal" onClick={closeModal}>Close</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> : null)
            }
            {/* Error modal popup ends */}
        </>
    );
}

export default Maintenance;
