import axios from "./axios/customAxios";
import {ConsoleLogger} from "../logger/ConsoleLogger";

const logger = new ConsoleLogger({level: process.env.REACT_APP_LOGGER_LEVEL,});


const getContactAccounts = async () => {
    const contactAccountsURL = `${process.env.REACT_APP_API_URL}/getContacts.php?contactOp=accountList`;
    return await axios.get(contactAccountsURL);
}

const getChildAccounts = async (accountNum) => {
    const childAccountUrl = `${process.env.REACT_APP_API_URL}/getContacts.php?contactOp=childAccounts&parent=${accountNum}`;
    return await axios.get(childAccountUrl);
}

const getWinforceContacts = async (accountNum) => {
    if(!accountNum) accountNum = "";
    const parentAccountUrl = `${process.env.REACT_APP_API_URL}/contacts/winforceContact.php?action=getContactList&commsId=${accountNum}`;
    return await axios.get(parentAccountUrl);
}

const getEnrollmentIds = async (gaid, gapid) => {
    const enrollmentIdsUrl = `${process.env.REACT_APP_API_URL}/getContacts.php?contactOp=getCircuits&gaid=${gaid}&gapid=${gapid}`;
    return await axios.get(enrollmentIdsUrl);
}

const getContactData = async (custId, gaid) => {
    const custContactUrl = `${process.env.REACT_APP_API_URL}/getContacts.php?contactOp=getContactObject&gaid=${gaid}&gapid=${custId}`;
    return await axios.get(custContactUrl);
}

const getCommsAccount = async () => {
    const custContactUrl = `${process.env.REACT_APP_API_URL}/getContacts.php?contactOp=commsAccount`;
    return await axios.get(custContactUrl);
}

const getDataNetworkEnrollments = async (gaid, gapid) => {
    const currentEnrollmentUrl = `${process.env.REACT_APP_API_URL}/getEnrollments.php?gaid=${gaid}&gapid=${gapid}`;
    return await axios.get(currentEnrollmentUrl);
}

const getInvoiceEnrollments = async (data, gapid, type) => {
    const currentEnrollmentUrl = `${process.env.REACT_APP_API_URL}/getEnrollmentsInvoicePrefs.php?data=${data}&gapid=${gapid}&type=${type}`;
    logger.info(currentEnrollmentUrl);
    return await axios.get(currentEnrollmentUrl);
}

const updateContactData = async (data, selectedRoles, action, gaid) => {
    const custContactUrl = `${process.env.REACT_APP_API_URL}/contacts/winforceContact.php?data=${JSON.stringify(data)}&selectedRoles=${JSON.stringify(selectedRoles)}&action=${action}&commsId=${gaid}`;
    return await axios.get(custContactUrl);
}

const updateEnrollments = async (enrollmentData, updatedEnrollmentData, gaid, gapid) => {
    const enrollUrl = `${process.env.REACT_APP_API_URL}/processContactEnrollments.php?currentEnrollments=${JSON.stringify(enrollmentData)}&updatedEnrollments=${JSON.stringify(updatedEnrollmentData)}&gaid=${gaid}&gapid=${gapid}`;
    return await axios.get(enrollUrl);
}

const updateInvoiceEnrollmentByBan = async (ban, gapid, type, action) => {
    const enrollUrl = `${process.env.REACT_APP_API_URL}/saveEnrollmentsInvoicePrefs.php?data=${ban}&gapid=${gapid}&type=${type}&action=${action}`;
    return await axios.get(enrollUrl);
}

const updateInvoiceEnrollmentByGaid = async (gaid, gapid, type, action) => {
    const enrollUrl = `${process.env.REACT_APP_API_URL}/saveEnrollmentsInvoicePrefs.php?data=${gaid}&gapid=${gapid}&type=${type}&action=${action}`;
    return await axios.get(enrollUrl);
}

const deactivateContact = async (sfId) => {
    const deactivateContactUrl = `${process.env.REACT_APP_API_URL}/contacts/winforceContact.php?action=deactivateContact&sfId=${sfId}`;
    return await axios.get(deactivateContactUrl);
}

const ContactData = {
    getContactAccounts,
    getWinforceContacts,
    getChildAccounts,
    getEnrollmentIds,
    getContactData,
    getCommsAccount,
    getDataNetworkEnrollments,
    getInvoiceEnrollments,
    updateContactData,
    updateEnrollments,
    updateInvoiceEnrollmentByBan,
    updateInvoiceEnrollmentByGaid,
    deactivateContact,
}

export default ContactData
