import { useEffect, useCallback, useState, useRef, useMemo } from "react";
import HomeData from "../../services/HomeServices";
import style from "./AddressValidator.module.css";
import validateAddress from "../../services/AddressValidatorService";
import Loader from "../shared/Loader";
import { ConsoleLogger } from "../../logger/ConsoleLogger";

const AddressValidator = () => {
	const logger = useMemo(() => new ConsoleLogger({ level: process.env.REACT_APP_LOGGER_LEVEL, }), []);
	const [addressObj, setAddressObj] = useState({ "address": "", "city": "", "state": "", "zip": "", "floor": "", "lat": "", "lon": "", "geocode": false, "gpon": false, "override": "" });
	const [addressErrorObj, setAddressErrorObj] = useState({ "addressError": "", "cityError": "", "stateError": "", "zipError": "" });
	const [stateList, setStateList] = useState([]);
	const [isValid, setIsValid] = useState(false);
	const [loader, setLoader] = useState(false);
	const [validatorResponse, setValidatorResponse] = useState([]);
	const resultRef = useRef(null);
	const google = window.google = window.google ? window.google : {};

	useEffect(() => {
		if (isValid) {
			resultRef.current?.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
		}
	}, [isValid]);

	const fillInAddress = useCallback((ac) => {
		var place = ac.getPlace();
		setAddressObj(prevAdd => ({ ...prevAdd, "address": place.formatted_address }))
		for (let x = 0; x < place.address_components.length; x++) {
			switch (place.address_components[x].types[0]) {
				case 'locality':
					setAddressObj(prevAdd => ({ ...prevAdd, "city": place.address_components[x].short_name }));
					break;
				case 'administrative_area_level_1':
					setAddressObj(prevAdd => ({ ...prevAdd, "state": place.address_components[x].short_name }));
					break;
				case 'postal_code':
					setAddressObj(prevAdd => ({ ...prevAdd, "zip": place.address_components[x].short_name }));
					break;
				default:
					break;
			}
		}
		return place;
	}, [])

	const initAutocomplete = useCallback(() => {
		const autocomplete = new google.maps.places.Autocomplete((document.getElementById('address')), { types: ['geocode'] });
		google.maps.event.addListener(autocomplete, 'place_changed', function () {
			fillInAddress(autocomplete);
		});
	}, [fillInAddress, google.maps.event, google.maps.places.Autocomplete])

	useEffect(() => {
		// Get the list of all the states for AV form
		HomeData.getStateList().then(resp => {
			setStateList(resp.data);
		}).catch((error) => {
			logger.error(`getStateList Error: ${error}`);
		})
		initAutocomplete();
	}, [initAutocomplete, logger]);

	const clearAddress = () => {
		setAddressObj({ "address": "", "city": "", "state": "", "zip": "", "floor": "", "lat": "", "lon": "", "geocode": false, "gpon": false, "override": "" })
		setAddressErrorObj({ "addressError": "", "cityError": "", "stateError": "", "zipError": "" });
		setIsValid(false);
	}

	//Calls getAvResults.php to validate address
	const validate = () => {
		if (addressObj.lat || addressObj.lon) setAddressObj(prevAdd => ({ ...prevAdd, "override": true }));

		//Validation Check
		if (!addressObj.address) setAddressErrorObj(prevAddError => ({ ...prevAddError, "addressError": "Address is required" }));
		if (!addressObj.city) setAddressErrorObj(prevAddError => ({ ...prevAddError, "cityError": "City is required" }));
		if (!addressObj.state) setAddressErrorObj(prevAddError => ({ ...prevAddError, "stateError": "State is required" }))
		if (!addressObj.zip) setAddressErrorObj(prevAddError => ({ ...prevAddError, "zipError": "Zip is required" }));

		if (addressObj.address && addressObj.city && addressObj.state && addressObj.zip) {
			setLoader(true);
			logger.info(`Address to Validate: ${JSON.stringify(addressObj)}`);
			validateAddress(addressObj).then(response => {
				logger.info(`Address Validator Response: ${JSON.stringify(response.data)}`);
				setValidatorResponse(response.data);
				setIsValid(true)
				setLoader(false);
			}).catch(error => {
				logger.error(`validateAddress ERROR: ${error}`);
				setLoader(false);
			})
		}

	}

	//input handler for all of the required fields
	const addressInputHandler = (event) => {
		let id = event.target.id;
		let errorId = id + "Error";
		setAddressObj(prevAdd => ({ ...prevAdd, [id]: event.target.value }))
		setAddressErrorObj(prevAdd => ({ ...prevAdd, [errorId]: "" }))
	}


	return (
		<>
			{loader ? <Loader /> : null}
			<div className="midsection qm-upload abs z1">
				<div className="rel">
					<div className="wrap">
						<h1 className="page-title">Address Validator</h1>
						<div className="section">
							<div className={`content ${style.contentQueryPage}`}>
								<div className={`row `}>
									<div className="col-12">
										<p className={` ${style.lableTextS} ${style.textBlack} `}>
											<span className={style.mandatory}>*</span> Denotes a required field</p>
									</div>
								</div>
								<div className={`sub-hdr alt ${style.subHeader} `}>
									<div className="nib">
										<div className="the-notch">
											<div className="notch-fg">
												<svg>
													<path
														d="M35.81,26.28l-13,10v-5C-10.47,28.68-1,9.16,11.79,0c0,7.28,0,9.13,0,13.28s3.18,7.92,11,9v-5Z"></path>
												</svg>
											</div>
										</div>
									</div>
									<h2 className={style.sectionTitle}>Address Input</h2>
								</div>
								<div className={`row ${style.rowGrp} `}>
									<div className="col-8">
										<label className={style.lableTextS}>Address <span className={style.mandatory}>*</span></label>
										<input type="text" className={style.inputFld} id="address" onChange={(event) => addressInputHandler(event)} value={addressObj.address} />
										{addressErrorObj.addressError ?
											<div className="invalid-feedback d-block">
												{addressErrorObj.addressError}
											</div> : null
										}
									</div>
									<div className="col-4">
										<label className={style.lableTextS}>Floor</label>
										<input type="text" className={style.inputFld} id='lv2' onChange={(event) => setAddressObj(prevAdd => ({ ...prevAdd, [event.target.id]: event.target.value }))} value={addressObj.lv2} />
									</div>
								</div>
								<div className={`row ${style.rowGrp}`}>
									<div className="col-6">
										<label className={style.lableTextS}>City <span className={style.mandatory}>*</span></label>
										<input type="text" className={style.inputFld} id='city' onChange={(event) => addressInputHandler(event)} value={addressObj.city} />
										{addressErrorObj.cityError ?
											<div className="invalid-feedback d-block">
												{addressErrorObj.cityError}
											</div> : null
										}
									</div>
									<div className="col-4">
										<label className={style.lableTextS}>State <span className={style.mandatory}>*</span></label>
										<select className={style.selectFldState} id='state' onChange={(event) => addressInputHandler(event)} value={addressObj.state}>
											{stateList.map((obj) => {
												return <option value={obj.id}>{obj.name}</option>
											})}
										</select>
										{addressErrorObj.stateError ?
											<div className="invalid-feedback d-block">
												{addressErrorObj.stateError}
											</div> : null
										}
									</div>
									<div className="col-2">
										<label className={style.lableTextS}>ZIP <span className={style.mandatory}>*</span></label>
										<input type="text" className={style.inputFld} id='zip' onChange={(event) => addressInputHandler(event)} value={addressObj.zip} />
										{addressErrorObj.zipError ?
											<div className="invalid-feedback d-block">
												{addressErrorObj.zipError}
											</div> : null
										}
									</div>
								</div>
								<div className={`row ${style.rowGrp}`}>
									<div className="col-3">
										<label className={style.lableTextS}>Latitude</label>
										<input type="text" className={style.inputFld} id='lat' onChange={(event) => setAddressObj(prevAdd => ({ ...prevAdd, [event.target.id]: event.target.value }))} value={addressObj.lat} />
									</div>
									<div className="col-3">
										<label className={style.lableTextS}>Longitude</label>
										<input type="text" className={style.inputFld} id='lon' onChange={(event) => setAddressObj(prevAdd => ({ ...prevAdd, [event.target.id]: event.target.value }))} value={addressObj.lon} />
									</div>
									<div className="col-2">
										<label htmlFor='geocode' className={style.labelText}>Set Geocode True</label>
										<input type='checkbox' id='geocode' name='geocode' className={`${style.inputFld} ml-2`} onChange={(event) => setAddressObj(prevAdd => ({ ...prevAdd, [event.target.id]: event.target.value }))} checked={addressObj.geocode ? "checked" : ""}></input>
									</div>
									<div className="col-4">
										<label htmlFor='gpon' className={style.labelText}>Get GPON Indicator</label>
										<input type='checkbox' id='gpon' name='gpon' className={`${style.inputFld} ml-2`} onChange={(event) => setAddressObj(prevAdd => ({ ...prevAdd, [event.target.id]: event.target.value }))} checked={addressObj.gpon ? "checked" : ""}></input>
									</div>
								</div>
								<div className={`row ${style.rowGrp}`}>
									<div className="col-6">
										<button className={`ico-button light-button`} type="button" onClick={() => clearAddress()}>
											Clear
											<svg className="ml-2 ico-x">
												<path d="M18,17l-1,1L9,10,1,18,0,17,8,9,0,1,1,0,9,8l8-8,1,1L10,9Z"></path>
											</svg>
										</button>
									</div>
									<div className="col-6">
										<button className={`ico-button light-button`} type="button" onClick={() => validate()}>
											Validate
											<svg className="ico light-ico ico-arrow">
												<path d="M0,17H29L15,31l1,1L32,16,16,0,15,1,29,15H0Z"></path>
											</svg>
										</button>
									</div>
								</div>
								{isValid ?
									<>
										<div className={style.dividers}></div>
										<div className={`sub-hdr alt ${style.subHeader} `}>
											<div className="nib">
												<div className="the-notch">
													<div className="notch-fg">
														<svg>
															<path
																d="M35.81,26.28l-13,10v-5C-10.47,28.68-1,9.16,11.79,0c0,7.28,0,9.13,0,13.28s3.18,7.92,11,9v-5Z"></path>
														</svg>
													</div>
												</div>
											</div>

											<h2 className={style.sectionTitle}>Results</h2>
										</div>
										<div className={`row ${style.rowGrp}`}>
											<div className='col-6'>
												<ul className={style.avResultList}>
													<li>Input Data</li>
													<li>{addressObj.address}</li>
												</ul>
											</div>
										</div>
										<div className={`row ${style.rowGrp}`}>
											<div className='col-md-3'>
												<ul className={style.avResultList}>
													<li>Reliability</li>
													<li className={validatorResponse.color === "green" ? "text-success" : "text-danger"}>{validatorResponse.reliability}</li>
												</ul>
											</div>
											<div className='col-md-3'>
												<ul className={style.avResultList}>
													<li>Percent Match</li>
													<li className={validatorResponse.color === "green" ? "text-success" : "text-danger"}>{validatorResponse.percentMatch}</li>
												</ul>
											</div>
											<div className='col-md-3'>
												<ul className={style.avResultList}>
													<li>Considered Valid</li>
													<li className={validatorResponse.color === "green" ? "text-success" : "text-danger"}>{validatorResponse.valid}</li>
												</ul>
											</div>
										</div>
										{validatorResponse?.validationNotes?.length > 0 ?
											<div className={`row ${style.rowGrp}`}>
												<div className='col-6'>
													<ul className={style.avResultList}>
														<li>Validation Notes</li>
														<li>{validatorResponse.validationNotes}</li>
													</ul>
												</div>
											</div> : null
										}
										<div className={`row ${style.rowGrp}`}>
											<div className='col-md-3'>
												<ul className={style.avResultList}>
													<li>House Prefix</li>
													<li>{validatorResponse.housePrefix}</li>
												</ul>
											</div>
											<div className='col-md-3'>
												<ul className={style.avResultList}>
													<li>House Number</li>
													<li>{validatorResponse.houseNumber}</li>
												</ul>
											</div>
											<div className='col-md-3'>
												<ul className={style.avResultList}>
													<li>House Suffix</li>
													<li>{validatorResponse.houseSuffix}</li>
												</ul>
											</div>
										</div>
										<div ref={resultRef} />
										<div className={`row ${style.rowGrp}`}>
											<div className='col-md-3'>
												<ul className={style.avResultList}>
													<li>Street Pre Directional</li>
													<li>{validatorResponse.streetPreDirectional}</li>
												</ul>
											</div>
											<div className='col-md-3'>
												<ul className={style.avResultList}>
													<li>Street Name</li>
													<li>{validatorResponse.streetName}</li>
												</ul>
											</div>
											<div className='col-md-3'>
												<ul className={style.avResultList}>
													<li>Street Suffix</li>
													<li>{validatorResponse.streetSuffix}</li>
												</ul>
											</div>
											<div className='col-md-3'>
												<ul className={style.avResultList}>
													<li>Street Post Directional</li>
													<li>{validatorResponse.streetPostDirectional}</li>
												</ul>
											</div>
										</div>
										<div className={`row ${style.rowGrp}`}>
											<div className='col-md-3'>
												<ul className={style.avResultList}>
													<li>Route</li>
													<li>{validatorResponse.route}</li>
												</ul>
											</div>
											<div className='col-md-3'>
												<ul className={style.avResultList}>
													<li>Box</li>
													<li>{validatorResponse.box}</li>
												</ul>
											</div>
											<div className='col-md-3'>
												<ul className={style.avResultList}>
													<li>Un-number House</li>
													<li>{validatorResponse.unNumberHouse}</li>
												</ul>
											</div>
											<div className='col-md-3'>
												<ul className={style.avResultList}>
													<li>Building</li>
													<li>{validatorResponse.building}</li>
												</ul>
											</div>
										</div>
										<div className={`row ${style.rowGrp}`}>
											<div className='col-md-3'>
												<ul className={style.avResultList}>
													<li>Floor</li>
													<li>{validatorResponse.floor}</li>
												</ul>
											</div>
											<div className='col-md-3'>
												<ul className={style.avResultList}>
													<li>Unit</li>
													<li>{validatorResponse.unit}</li>
												</ul>
											</div>
											<div className='col-md-3'>
												<ul className={style.avResultList}>
													<li>Type</li>
													<li>{validatorResponse.type}</li>
												</ul>
											</div>
											<div className='col-md-3'>
												<ul className={style.avResultList}>
													<li>Value</li>
													<li>{validatorResponse.value}</li>
												</ul>
											</div>
										</div>
										<div className={`row ${style.rowGrp}`}>
											<div className='col-md-3'>
												<ul className={style.avResultList}>
													<li>City</li>
													<li>{validatorResponse.city}</li>
												</ul>
											</div>
											<div className='col-md-3'>
												<ul className={style.avResultList}>
													<li>State</li>
													<li>{validatorResponse.state}</li>
												</ul>
											</div>
											<div className='col-md-3'>
												<ul className={style.avResultList}>
													<li>Postal Code</li>
													<li>{validatorResponse.postalCode}</li>
												</ul>
											</div>
											<div className='col-md-3'>
												<ul className={style.avResultList}>
													<li>Postal Code Ext</li>
													<li>{validatorResponse.postalCodeExtension}</li>
												</ul>
											</div>
										</div>
										<div className={`row ${style.rowGrp}`}>
											<div className='col-md-3'>
												<ul className={style.avResultList}>
													<li>County</li>
													<li>{validatorResponse.county}</li>
												</ul>
											</div>
											<div className='col-md-3'>
												<ul className={style.avResultList}>
													<li>Country</li>
													<li>{validatorResponse.country}</li>
												</ul>
											</div>
											<div className='col-md-3'>
												<ul className={style.avResultList}>
													<li>GEO Code</li>
													<li>{validatorResponse.geoCode}</li>
												</ul>
											</div>
											<div className='col-md-3'>
												<ul className={style.avResultList}>
													<li>GPON Capable</li>
													<li>{validatorResponse.gpon}</li>
												</ul>
											</div>
											<div className='col-md-3'>
												<ul className={style.avResultList}>
													<li>Incorporated</li>
													<li>{validatorResponse.isIncorporated}</li>
												</ul>
											</div>
										</div>
										<div className={`row ${style.rowGrp}`}>
											<div className='col-md-3'>
												<ul className={style.avResultList}>
													<li>Latitude</li>
													<li>{validatorResponse.latitude}</li>
												</ul>
											</div>
											<div className='col-md-3'>
												<ul className={style.avResultList}>
													<li>Longitude</li>
													<li>{validatorResponse.longitude}</li>
												</ul>
											</div>
										</div>
										<div className={`row ${style.rowGrp}`}>
											<div className='col-md-3'>
												<ul className={style.avResultList}>
													<li>Vertical Coordinates</li>
													<li>{validatorResponse.verticalCoordinates}</li>
												</ul>
											</div>
											<div className='col-md-3'>
												<ul className={style.avResultList}>
													<li>Horizontal Coordinates</li>
													<li>{validatorResponse.horizontalCoordinates}</li>
												</ul>
											</div>
										</div>
										<div className={`row ${style.rowGrp}`}>
											<div className='col-md-3'>
												<ul className={style.avResultList}>
													<li>LATA</li>
													<li>{validatorResponse.lata}</li>
												</ul>
											</div>
											<div className='col-md-3'>
												<ul className={style.avResultList}>
													<li>Rate Center</li>
													<li>{validatorResponse.rateCenter}</li>
												</ul>
											</div>
											<div className='col-md-3'>
												<ul className={style.avResultList}>
													<li>Wire Center Clli</li>
													<li>{validatorResponse.wireCenterClli}</li>
												</ul>
											</div>
											<div className='col-md-3'>
												<ul className={style.avResultList}>
													<li>Switch Clli</li>
													<li>{validatorResponse.switchClli}</li>
												</ul>
											</div>
										</div>
										<div className={`row ${style.rowGrp}`}>
											<div className='col-md-3'>
												<ul className={style.avResultList}>
													<li>OCN</li>
													<li>{validatorResponse.ocn}</li>
												</ul>
											</div>
											<div className='col-md-3'>
												<ul className={style.avResultList}>
													<li>OCN Name</li>
													<li>{validatorResponse.ocnName}</li>
												</ul>
											</div>
											<div className='col-md-3'>
												<ul className={style.avResultList}>
													<li>OCN Category</li>
													<li>{validatorResponse.ocnCategory}</li>
												</ul>
											</div>
											<div className='col-md-3'>
												<ul className={style.avResultList}>
													<li>Distance</li>
													<li>{validatorResponse.distance}</li>
												</ul>
											</div>
										</div>
										<div className={`row ${style.rowGrp}`}>
											<div className='col-md-3'>
												<ul className={style.avResultList}>
													<li>NPA-NXX</li>
													<li>{`${validatorResponse.npa} - ${validatorResponse.nxx}`}</li>
												</ul>
											</div>
											<div className='col-md-3'>
												<ul className={style.avResultList}>
													<li>Equipment Type</li>
													<li>{validatorResponse.equipmentType}</li>
												</ul>
											</div>
											<div className='col-md-3'>
												<ul className={style.avResultList}>
													<li>BOID</li>
													<li>{validatorResponse.boid}</li>
												</ul>
											</div>
											<div className='col-md-3'>
												<ul className={style.avResultList}>
													<li>BEX</li>
													<li>{validatorResponse.bex}</li>
												</ul>
											</div>
										</div>
										<div className={`row ${style.rowGrp}`}>
											<div className='col-md-3'>
												<ul className={style.avResultList}>
													<li>Towncode</li>
													<li>{validatorResponse.towncode}</li>
												</ul>
											</div>
											<div className='col-md-3'>
												<ul className={style.avResultList}>
													<li>On-Net</li>
													<li>{validatorResponse.onNet}</li>
												</ul>
											</div>
											<div className='col-md-3'>
												<ul className={style.avResultList}>
													<li>Ethernet 2.0 Capable</li>
													<li>{validatorResponse.eofwCapable}</li>
												</ul>
											</div>
											<div className='col-md-3'>
												<ul className={style.avResultList}>
													<li>Fixed Wireless Capable</li>
													<li>{validatorResponse.eofwCapable}</li>
												</ul>
											</div>
										</div>
									</> : null}
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default AddressValidator;
