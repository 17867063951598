import style from "./Popup.module.css"
const Popup = ({message, show, onClose }) => {
    if (!show) {
      return null;
    }
    return (
        <>
            <div className={`${style.popupOverlay}`}></div>
            <div className="modal fade show d-block">
                <div className="modal-dialog modal-dialog-centered modal-md">
                    <div className="modal-content">
                        <div className="modal-body text-center">
                            {message}
                        </div>
                        <div className="modal-footer d-block text-center">
                            <button type="button" className="btn-style btn-theme" data-dismiss="modal" onClick={() => onClose("YES")}>
                                Yes
                            </button>
                            <button type="button" className="btn-style btn-cancel" data-dismiss="modal" onClick={() => onClose("NO")}>
                                No
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Popup;
