import {SelectComponent} from "../components/SelectComponent";
import style from "../employee/Employee.module.css";
import {ConsoleLogger} from "../../logger/ConsoleLogger";
import {useState} from "react";

const TicketRedirectModal = (props) => {
    const logger = new ConsoleLogger({level: process.env.REACT_APP_LOGGER_LEVEL,});
    const [incident, setIncident] = useState("");
    const [error, setError] = useState(false);

    const popupYes = (event) => {
        if (incident !== "" && incident !== undefined) {
            props.dupTicketPopupEmit("Yes", incident);
        } else {
            setError(true);
        }
    }

    const popupNo = () => {
        props.dupTicketPopupEmit("No");
    }

    const redirectCallback = (event) => {
        const {value} = event.target;
        setIncident(value);
    }

    function getArrayFromList(incidentList) {
        return incidentList.map((incident) => {
            return incident.incident_number;
        });
    }

    return (
        <div>
            <div className="overlay show"></div>
            <div className="modal fade show d-block">
                <div className={` modal-dialog modal-dialog-centered modal-`}>
                    <div className="modal-content">
                        <div className="modal-body">
                            There is an existing ticket on this circuit.
                            <div>
                                <SelectComponent label="Incident List" name="incidentList" className={style.w45}
                                                 value={props.incidentList.incident_number}
                                                 options={getArrayFromList(props.incidentList)}
                                                 error={""} callback={redirectCallback}/>
                            </div>
                            Would you like to access the selected ticket to view the latest updates, add a worklog
                            comment,
                            request a status
                            update, or submit an escalation?
                        </div>

                        <div className="modal-footer d-block text-center">
                            <button type="button" className="btn-style btn-theme" data-dismiss="modal"
                                    onClick={(event) => {
                                        popupYes(event)
                                    }}>Yes
                            </button>
                            <button type="button" className="btn-style btn-cancel" data-dismiss="modal"
                                    onClick={(event) => {
                                        popupNo(event)
                                    }}>No
                            </button>
                            {error ? <p className={"red"}>Please select an incident.</p> : null}
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}
export default TicketRedirectModal;