import {useState} from "react";
import ViewportWarning from "../shared/ViewportWarning";
import style from "./Employee.module.css";
import bulkAddressSubmit from "../../services/BulkAddressValidationService";
import Loader from "../shared/Loader";
import {ConsoleLogger} from "../../logger/ConsoleLogger";

const Bulk = () => {
    const logger = new ConsoleLogger({ level: process.env.REACT_APP_LOGGER_LEVEL, });
    const [formValues, setFormValues] = useState({project_name: "", contact: "", phone: "", email: "", fileUpload: "" });
    const [formErrors, setFormErrors] = useState({});
    const [selectedFile, setSelectedFile] = useState("");
	const [fileName, setFileName] = useState("");
    const [successMsg, setSuccessMsg] = useState("");
    const [errorMsg, setErrorMsg] = useState("");
    const [loader, setLoader] = useState(false);

    const inputHandle = (event) => {
        let name = event.target.name;
        let value = event.target.value;
        setFormValues({ ...formValues, [name]: value });
    }

    const validation = () => {
        const errors = {};
        if (!formValues.project_name) {
            errors.project_name = "Project Name is Required"
        }
        if (!formValues.contact) {
            errors.contact = "Name is Required";
        }
        if (!formValues.phone) {
            errors.phone = "Phone is Required";
        }
        if (!formValues.email) {
            errors.email = "Email is Required";
        }
        if (!formValues.fileUpload) {
            errors.fileUpload = "File Upload is Required";
        }
        setFormErrors(errors);
        return errors;
    }

    const handleFileSelect = (event) => {
		setSelectedFile(event.target.files[0]);
		setFileName(`File name: ${event.target.files[0].name}`);
        setFormValues({ ...formValues, "fileUpload": event.target.files[0].name});
	}

    const submitBulkAddress = () => {
        setSuccessMsg("");
        setErrorMsg("");
        const errorCheck = validation();
        if (Object.keys(errorCheck).length === 0) {
            const formData = new FormData();
			formData.append("userfile", selectedFile);
            setLoader(true);
            bulkAddressSubmit(formValues, formData).then(response => {
                logger.info("Submitting bulk addresses for validation");
                if(response.data.result === "SUCCESS"){
                    setSuccessMsg(response.data.update);
                } else {
                    setErrorMsg(response.data.error);
                }
                setLoader(false);
                
            }).catch(error => {
                setLoader(false);
                logger.error(`Error submitting bulk addresses for validation: ${error}`);
            })
        }
    }

    return (
        <>
            {loader ? <Loader/> : null}
            <div className="midsection employee abs z1">
                <div className="rel">
                    <ViewportWarning />
                    <div className="wrap">
                        <h1 className="page-title"> Bulk Address Validation</h1>
                        <div className="section">
                            <div className="content">

                                <div className={`row `}>
                                    <div className="col-12">
                                        <p className="text-secondary"><span className={style.mandatory}>*</span> Denotes a required field</p>
                                    </div>
                                </div>
                                <div className={`sub-hdr alt0 mt-3`}>
                                    <div className="nib">
                                        <div className="the-notch">
                                            <div className="notch-fg">
                                                <svg>
                                                    <path
                                                        d="M35.81,26.28l-13,10v-5C-10.47,28.68-1,9.16,11.79,0c0,7.28,0,9.13,0,13.28s3.18,7.92,11,9v-5Z"></path>
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                    <h2 className={`m-0 `}>Project Details</h2>
                                </div>
                                <div className='px-3 my-3'>
                                    <div className='row'>
                                        <div className='col-12 col-md-4'>
                                            <div className='my-3'>
                                                <label className='labelText'> Project Name <span className={` ${style.mandatory} `}>*</span></label>
                                                <input type="text" name="project_name" className={`${style.inputFldText} w-100`} onChange={(event) => { inputHandle(event) }} />
                                                <p className={style.errorMsg}>{formErrors.project_name}</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className='col-12 col-md-4'>
                                            <div className='my-3'>
                                                <label className='labelText'> Name <span className={` ${style.mandatory} `}>*</span></label>
                                                <input type="text" name="contact" className={`${style.inputFldText} w-100`} onChange={(event) => { inputHandle(event) }} />
                                                <p className={style.errorMsg}>{formErrors.contact}</p>
                                            </div>
                                        </div>
                                        <div className='col-12 col-md-4'>
                                            <div className='my-3'>
                                                <label className='labelText'> Phone <span className={` ${style.mandatory} `}>*</span></label>
                                                <input type="text" name="phone" className={`${style.inputFldText} w-100`} maxLength="10" onChange={(event) => { inputHandle(event) }} />
                                                <p className={style.errorMsg}>{formErrors.phone}</p>
                                            </div>
                                        </div>
                                        <div className='col-12 col-md-4'>
                                            <div className='my-3'>
                                                <label className='labelText'> Email <span className={` ${style.mandatory} `}>*</span></label>
                                                <input type="text" name="email" className={`${style.inputFldText} w-100`} maxLength="118" onChange={(event) => { inputHandle(event) }} />
                                                <p className={style.errorMsg}>{formErrors.email}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className={style.dividers}></div>

                                <div className={`sub-hdr alt0`}>
                                    <div className="nib">
                                        <div className="the-notch">
                                            <div className="notch-fg">
                                                <svg>
                                                    <path
                                                        d="M35.81,26.28l-13,10v-5C-10.47,28.68-1,9.16,11.79,0c0,7.28,0,9.13,0,13.28s3.18,7.92,11,9v-5Z"></path>
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                    <h2 className={`m-0 `}>File Attachment</h2>
                                </div>
                                <div className='px-3 mt-4'>
                                    <div className='row'>
                                        <div className="col-12 col-sm-6 col-md-4">
                                            <label className={` d-block mb-4`}>File Upload <span className={style.mandatory}> *</span></label>
                                            <p>A MAXIMUM of 1,000 addresses can be submitted at one time</p>
                                            <label htmlFor="uploadFile" className={style.uploadLabel}>
                                                <div className={style.uploadLabelText}>Browse...</div>
                                                <input type="file" name="fileUpload" id="uploadFile" className={style.uploadFile} onChange={(event) => handleFileSelect(event)} />
                                            </label>
                                            <div>{fileName}</div>	
                                        </div>
                                        <div className="col-12 col-sm-6 col-md-8">
                                            <div className={style.uplodadFileList}>
                                                <label className={` text-uppercase mb-4 d-block`}>Bulk Upload Templates</label>
                                                <a href='../../assets/files/Bulk_Address_Validation_Upload_Template.xls' download>Bulk_Address_Validation_Upload_Template</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {successMsg ? 
                                    <div className="mt-5">
                                        <p className="text-success text-center">{successMsg}</p>
                                    </div> : null
                                }
                                {errorMsg ? 
                                    <div className="mt-5">
                                        <p className="text-danger text-center">{errorMsg}</p>
                                    </div> : null
                                }
                                <div className="text-center my-5">
                                    <button type="button" className={`ico-button lhs light-button ml-3 ${style.nextBtn} `} onClick={() => { submitBulkAddress() }} >
                                        Submit
                                        <svg className="ico light-ico ico-arrow ml-2">
                                            <path d="M0,17H29L15,31l1,1L32,16,16,0,15,1,29,15H0Z"></path>
                                        </svg>
                                    </button>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Bulk;
