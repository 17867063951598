import { useState } from "react";
import { connect } from "react-redux";
import style from "./QuoteCreate.module.css";
import { setQuoteAddressA, setQuoteAddressZ } from "../../redux/actions/quoteActions";
import {
	setLocAAddress,
	setLocACity,
	setLocAFloorNum,
	setLocAState,
	setLocAStruct,
	setLocAStructNum,
	setLocAUnit,
	setLocAUnitNum,
	setLocAValidationFlag,
	setLocAZip
} from '../../redux/actions/quoteLocAActions';
import {
	setLocZAddress,
	setLocZCity,
	setLocZFloorNum,
	setLocZState,
	setLocZStruct,
	setLocZStructNum,
	setLocZUnit,
	setLocZUnitNum,
	setLocZValidationFlag,
	setLocZZip
} from '../../redux/actions/quoteLocZActions';
import {
	setQuotePopLocation
} from '../../redux/actions/quoteActions';
import Select from 'react-select';

const CustomPopRules = (props) => {
	const [popLocation, setPopLocation] = useState({ value: "", label: "" });
	const [popUpShow, setPopUpShow] = useState(true);
	/*Updating pop location in store and clearing the manually entered address*/
	const popHandler = (ev, type) => {
		let popVal = '';
		if (!ev) {
			popVal = ''
		} else {
			popVal = ev;
		}
		setPopLocation(popVal);
		if (type === "locA") {
			//Keep the validated google manually entered address
			if (popVal.value === "Other") {
				if (props.product === 15) {
					props.updateColocationLocation({ value: "Other", label: "Other Location" });
				} else {
					props.updateWavePopAddressA({ value: "Other", label: "Other Location" });
				}
			} else {
				if (props.product === 15) {
					props.updateColocationLocation(popVal);
				} else {
					props.updateWavePopAddressA(popVal);
				}
				clearALoc();
			}
		} else {
			//Keep the validated google manually entered address
			if (popVal.value === "Other") {
				props.updateWavePopAddressZ({ value: "Other", label: "Other Location" });
			} else {
				props.updateWavePopAddressZ(popVal);
				clearZLoc();
			}
		}
		setPopUpShow(false);
	}

	/*Clearing PremAddres.js or QuoteLocZ.js component*/
	const clearHandler = (type) => {
		setPopUpShow(false);
		if (type == "locA") {
			props.updateWavePopAddressA("");
			clearALoc();
		} else {
			props.updateWavePopAddressZ("");
			clearZLoc();
		}
	}

	const clearALoc = () => {
		props.updateLocAAddress("");
		props.updateLocAStruct("");
		props.updateLocAStructNum("");
		props.updateLocAFloorNum("");
		props.updateLocAUnit("");
		props.updateLocAUnitNum("");
		props.updateLocACity("");
		props.updateLocAState("");
		props.updateLocAZip("");
		props.updateLocAValidAddress(false);
	}

	const clearZLoc = () => {
		props.updateLocZAddress("");
		props.updateLocZStruct("");
		props.updateLocZStructNum("");
		props.updateLocZFloorNum("");
		props.updateLocZUnit("");
		props.updateLocZUnitNum("");
		props.updateLocZCity("");
		props.updateLocZState("");
		props.updateLocZZip("");
		props.updateLocZValidAddress(false);
	}



	return (
		<>
			{popUpShow ?
				<div>
					<div className={`${style.overly}`}> </div>
					<div className="modal fade show d-block">
						<div className={` modal-dialog modal-dialog-centered modal-md`}>
							<div className="modal-content">
								<div className="modal-body">
									<p>The address entered does not match a Windstream PoP. Failure to meet Windstream at a designated Windstream PoP may result in an increased quote price, as well as additional time for both quoting and installation. Please select one of the Windstream PoPs listed below, or continue with your original address submission.<br></br></p>
									<label className={style.labelText}>Existing Pop Location(s)<span className={style.mandatory}>*</span></label>
									<Select
										defaultValue={popLocation}
										onChange={(ev) => { popHandler(ev, props.type) }}
										options={props.pop_list.map((item) => {
											return { value: item.id, label: item.address }
										})}
										isSearchable
										isClearable
										noOptionsMessage={() => 'Loading POP Locations'}
									/>
								</div>
								<div className="modal-footer d-block text-center">
									<button type="button" className="btn-style btn-cancel" data-dismiss="modal" onClick={() => clearHandler(props.type)}>Clear Address Form</button>
								</div>
							</div>
						</div>
					</div>
				</div> : null
			}
		</>
	)
}

const mapDispatchToProps = {
	updateWavePopAddressA: setQuoteAddressA,
	updateLocAAddress: setLocAAddress,
	updateLocAStruct: setLocAStruct,
	updateLocAStructNum: setLocAStructNum,
	updateLocAFloorNum: setLocAFloorNum,
	updateLocAUnit: setLocAUnit,
	updateLocAUnitNum: setLocAUnitNum,
	updateLocACity: setLocACity,
	updateLocAState: setLocAState,
	updateLocAZip: setLocAZip,
	updateLocAValidAddress: setLocAValidationFlag,
	updateWavePopAddressZ: setQuoteAddressZ,
	updateLocZAddress: setLocZAddress,
	updateLocZStruct: setLocZStruct,
	updateLocZStructNum: setLocZStructNum,
	updateLocZFloorNum: setLocZFloorNum,
	updateLocZUnit: setLocZUnit,
	updateLocZUnitNum: setLocZUnitNum,
	updateLocZCity: setLocZCity,
	updateLocZState: setLocZState,
	updateLocZZip: setLocZZip,
	updateLocZValidAddress: setLocZValidationFlag,
	updateColocationLocation: setQuotePopLocation


}

export default connect(null, mapDispatchToProps)(CustomPopRules);
