import {
	MPLS_NNI_ADD,
	MPLS_NNI_BW,
	MPLS_NNI_CITY,
	MPLS_NNI_FLOOR_NUM,
	MPLS_NNI_LOCATION,
	MPLS_NNI_STATE,
	MPLS_NNI_STRUCT,
	MPLS_NNI_STRUCT_NUM,
	MPLS_NNI_TERM2,
	MPLS_NNI_TERM3,
	MPLS_NNI_UNIT,
	MPLS_NNI_UNIT_NUM,
	MPLS_NNI_ZIP
} from "../actions/types/actionTypes"

const initialState = {
    quoteValues: {
        nniLoc: "",
        address: "",
        struct: "",
        structNum: "",
        floorNum: "",
        unit: "",
        unitNum: "",
        city: "",
        state: "",
        zip: "",
        terms: "",
        term2: "",
        term3: "",
        bw: ""
    }
};

const mplsNNIReducer = (state = initialState, action) => {
    switch (action.type) {
        case MPLS_NNI_LOCATION:
            return {
                ...state,
               quoteValues:{
                    ...state.quoteValues,
                    nniLoc: action.payload
               }
            }
        case MPLS_NNI_TERM2:
            return {
                ...state,
                quoteValues:{
                    ...state.quoteValues,
                    term2: action.payload,
                    term3: ""
                }
            }
        case MPLS_NNI_TERM3:
            return {
                ...state,
                quoteValues:{
                    ...state.quoteValues,
                    term2: "",
                    term3: action.payload
                }
            }

        case MPLS_NNI_BW:
            return{
                ...state,
                quoteValues:{
                    ...state.quoteValues,
                    bw: action.payload
                }
            }
        case MPLS_NNI_ADD:
            return {
                ...state,
                quoteValues: {
                    ...state.quoteValues,
                    address: action.payload
                }
            }
        case MPLS_NNI_STRUCT:
            return {
                ...state,
                quoteValues: {
                    ...state.quoteValues,
                    struct: action.payload
                }
            }
        case MPLS_NNI_STRUCT_NUM:
            return {
                ...state,
                quoteValues: {
                    ...state.quoteValues,
                    structNum: action.payload
                }
            }    
        case MPLS_NNI_FLOOR_NUM:
            return {
                ...state,
                quoteValues: {
                    ...state.quoteValues,
                    floorNum: action.payload
                }
            }
        case MPLS_NNI_UNIT:
            return {
                ...state,
                quoteValues: {
                    ...state.quoteValues,
                    unit: action.payload
                }
            }
        case MPLS_NNI_UNIT_NUM:
            return {
                ...state,
                quoteValues: {
                    ...state.quoteValues,
                    unitNum: action.payload
                }
            }
        case MPLS_NNI_CITY:
            return {
                ...state,
                quoteValues: {
                    ...state.quoteValues,
                    city: action.payload
                }
            }
        case MPLS_NNI_STATE:
            return {
                ...state,
                quoteValues: {
                    ...state.quoteValues,
                    state: action.payload
                }
            }
        case MPLS_NNI_ZIP:
            return {
                ...state,
                quoteValues: {
                    ...state.quoteValues,
                    zip: action.payload
                }
            }

        default:
            return state
    }
}

export default mplsNNIReducer;
