import React from 'react';
import {styled, ThemeProvider} from "@mui/material/styles";
import {alpha, FormControl, MenuItem, Select} from "@mui/material";
import MuiPagination from "@mui/material/Pagination";
import {gridPageCountSelector, gridPageSizeSelector, useGridApiContext, useGridSelector} from "@mui/x-data-grid";
import CustomTheme from "./CustomTheme";

const HOVER_OPACITY = 0.2;

const CustomPagination = styled(MuiPagination)(({theme}) => ({
    '& .MuiPaginationItem-root': {
        color: theme.palette.primary.main, // Use custom color for text
    },
    '& .MuiPaginationItem-root.Mui-selected': {
        backgroundColor: theme.palette.primary.main, // Use primary color background
        color: theme.palette.common.white, // Use white color for text
    },
    '& .MuiPaginationItem-root.Mui-selected:hover': {
        backgroundColor: alpha(theme.palette.primary.main, HOVER_OPACITY + 0.2), // Lighter shade on hover
    },
    '& .MuiPaginationItem-root:hover': {
        backgroundColor: alpha(theme.palette.primary.main, HOVER_OPACITY), // Lighter shade on hover
    },
}));

const CustomPageSizeOptions = styled(FormControl)(({theme}) => ({
    '& .MuiSelect-root': {
        color: theme.palette.primary.main, // Custom color for text
    },
    '& MuiFormControl-root': {
        maxWidth: 100,
    },
    '& .MuiSelect-select': {
        backgroundColor: alpha(theme.palette.primary.main, 0.1), // Light background color
    },
    '& .MuiSelect-icon': {
        color: theme.palette.primary.main, // Custom color for the icon
    },
}));

const Pagination = ({page, onPageChange, className}) => {
    const apiRef = useGridApiContext();
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);
    const pageSize = useGridSelector(apiRef, gridPageSizeSelector);

    return (<ThemeProvider theme={CustomTheme}>
        <CustomPagination
            className={className}
            count={Number(pageCount)}
            page={page + 1}
            onChange={(event, newPage) => {
                onPageChange(event, newPage - 1);
            }}
        />
        <CustomPageSizeOptions variant="outlined" sx={{m: 1, minWidth: 100, minHeight: 50, maxHeight: 50}}>
            <div>
                <Select
                    autoWidth={true}
                    labelId={"select-label"}
                    id={"page-select"}
                    value={pageSize}
                    label="Page"
                    onChange={(event) => apiRef.current.setPageSize(Number(event.target.value))}
                    inputProps={{'aria-label': 'Page size'}}
                >
                    {[5, 10, 25, 50, 100].map((size) => (
                        <MenuItem key={size} value={size}>
                            {size}
                        </MenuItem>
                    ))}
                </Select>
            </div>
        </CustomPageSizeOptions>
    </ThemeProvider>);
};

export default Pagination;