import axios from "./axios/customAxios";

const getAggTailBW = async () => {
	const aggtailBWURL = `${process.env.REACT_APP_API_URL}/getBandwidths.php?product=6&menu=bw`;
    return await axios.get(aggtailBWURL);
}


const MPLSAggTailHelper = {
    getAggTailBW
}

export default MPLSAggTailHelper;
