import {
	LOC_Z_ADDRESS,
	LOC_Z_CITY,
	LOC_Z_FLOOR_NUM,
	LOC_Z_STATE,
	LOC_Z_STRUCT,
	LOC_Z_STRUCT_NUM,
	LOC_Z_UNIT,
	LOC_Z_UNIT_NUM,
	LOC_Z_VALID_ADDRESS,
	LOC_Z_ZIP,
	LOC_Z_OVERRIDE,
	LOC_Z_LATITUDE,
	LOC_Z_LONGITUDE,
	QUOTE_Z_BW_DISPLAY,
	QUOTE_Z_BW_DISPLAY_FLAG,
	RESET_ACTION
} from "../actions/types/actionTypes"


const initialState = {
	locZ: {
		z_add: "",
		z_struct: "",
		z_structNum: "",
		z_unit: "",
		z_unitNum: "",
		z_floorNum: "",
		z_city: "",
		z_state: "",
		z_zip: "",
		z_override: false,
		z_lat: "",
		z_long: "",
		z_validationFlag: false
	},

	locZWaveBw: {},

	locZWaveBwFlag: false

};

const quoteLocZReducer = (state = initialState, action) => {
	switch (action.type) {
		case LOC_Z_ADDRESS:
			return {
				...state,
				locZ: {
					...state.locZ,
					z_add: action.payload
				}
			}
		case LOC_Z_STRUCT:
			return {
				...state,
				locZ: {
					...state.locZ,
					z_struct: action.payload
				}
			}
		case LOC_Z_STRUCT_NUM:
			return {
				...state,
				locZ: {
					...state.locZ,
					z_structNum: action.payload
				}
			}
		case LOC_Z_FLOOR_NUM:
			return {
				...state,
				locZ: {
					...state.locZ,
					z_floorNum: action.payload
				}
			}
		case LOC_Z_UNIT:
			return {
				...state,
				locZ: {
					...state.locZ,
					z_unit: action.payload
				}
			}
		case LOC_Z_UNIT_NUM:
			return {
				...state,
				locZ: {
					...state.locZ,
					z_unitNum: action.payload
				}
			}
		case LOC_Z_CITY:
			return {
				...state,
				locZ: {
					...state.locZ,
					z_city: action.payload
				}
			}
		case LOC_Z_STATE:
			return {
				...state,
				locZ: {
					...state.locZ,
					z_state: action.payload
				}
			}
		case LOC_Z_ZIP:
			return {
				...state,
				locZ: {
					...state.locZ,
					z_zip: action.payload
				}
			}
		case LOC_Z_OVERRIDE:
			return {
				...state,
				locZ: {
					...state.locZ,
					z_override: action.payload
				}
			}
		case LOC_Z_LATITUDE:
			return {
				...state,
				locZ: {
					...state.locZ,
					z_lat: action.payload
				}
			}
		case LOC_Z_LONGITUDE:
			return {
				...state,
				locZ: {
					...state.locZ,
					z_long: action.payload
				}
			}
		case LOC_Z_VALID_ADDRESS:
			return {
				...state,
				locZ: {
					...state.locZ,
					z_validationFlag: action.payload
				}
			}
		case QUOTE_Z_BW_DISPLAY:
			return {
				...state,
				locZWaveBw: action.payload
			}
		case QUOTE_Z_BW_DISPLAY_FLAG:
			return {
				...state,
				locZWaveBwFlag: action.payload
			}
		case RESET_ACTION:
			return initialState;
		default:
			return state

	}
}

export default quoteLocZReducer;
