import {
	QUOTE_A_ADDRESS,
	QUOTE_AVAILABILITY_FLAG,
	QUOTE_A_VPN_PORT_1,
	QUOTE_A_VPN_PORT_2,
	QUOTE_A_VPN_PORT_3,
	QUOTE_ACCESS_TYPE,
	QUOTE_ADD_BW_1,
	QUOTE_ADD_BW_2,
	QUOTE_ATT_EXCLUSION,
	QUOTE_BAM_KEY,
	QUOTE_BREAKER_AMP,
	QUOTE_BROADBAND_ADSL,
	QUOTE_BROADBAND_BANDWIDTHS,
	QUOTE_BROADBAND_CABLE,
	QUOTE_BURSTABLE_BILLING_1,
	QUOTE_BURSTABLE_BILLING_2,
	QUOTE_BURSTABLE_BILLING_3,
	QUOTE_BW,
	QUOTE_CAM_QTY,
	QUOTE_CIRCUIT_TYPE,
	QUOTE_CLONE,
	QUOTE_COMCAST_EXCLUSION,
	QUOTE_COMMIT_BW_1,
	QUOTE_COMMIT_BW_2,
	QUOTE_COMMIT_BW_3,
	QUOTE_COS,
	QUOTE_COS_TYPE_1,
	QUOTE_COS_TYPE_2,
	QUOTE_COS_TYPE_3,
	QUOTE_DF_OPTIONS,
	QUOTE_DF_TERM,
	QUOTE_DF_NUM_FIBERS,
	QUOTE_DF_COMMENTS,
	QUOTE_CPE_TYPE,
	QUOTE_DDOS,
	QUOTE_DDOS_TYPE,
	QUOTE_DIVERSITY,
	QUOTE_DIVERSITY_NOTE,
	QUOTE_ENNI_EXTENSION,
	QUOTE_ERROR_MSG,
	QUOTE_FIBER_CROSS_CONNECT,
	QUOTE_FUSE_PANEL,
	QUOTE_INTERNET_ROUTER,
	QUOTE_IPS,
	QUOTE_KMZ_FILE,
	QUOTE_LOCA_UNI,
	QUOTE_LOCZ_UNI,
	QUOTE_LUMEN_EXCLUSION,
	QUOTE_NNI_LOCATION,
	QUOTE_POP_LOCATION,
	QUOTE_PRIMARY_POWER,
	QUOTE_PRODUCT,
	QUOTE_PRODUCT_CODE,
	QUOTE_PROTOCOL_1,
	QUOTE_PROTOCOL_2,
	QUOTE_PROTOCOL_3,
	QUOTE_QTY,
	QUOTE_RACK_SPACE,
	QUOTE_ROUTE_LATENCY,
	QUOTE_ROUTE_TYPE,
	QUOTE_SECONDARY_POWER,
	QUOTE_SPECTRUM_EXCLUSION,
	QUOTE_STEP_CODE,
	QUOTE_TERM_1,
	QUOTE_TERM_2,
	QUOTE_TERM_3,
	QUOTE_TERM_4,
	QUOTE_TERM_5,
	QUOTE_TERM_10,
	QUOTE_VENDOR_EXCLUSION,
	QUOTE_VERIZON_EXCLUSION,
	QUOTE_Z_ADDRESS,
	QUOTE_Z_VPN_PORT_1,
	QUOTE_Z_VPN_PORT_2,
	QUOTE_Z_VPN_PORT_3,
	QUOTE_ROUTE_DETAILS,
	QUOTE_PATH_DETAILS,
	QUOTE_PATH_NUMBER,
	QUOTE_KMZ_URL,
	QUOTE_ROUTE_CAPACITY,
	QUOTE_PATH_EDGES,
	QUOTE_AUTO_PRICES,
	QUOTE_ROUTE_HOPS,
	QUOTE_ROUTE_FIBER_MILES,
	QUOTE_PATH_LATENCY,
	QUOTE_ROUTE_BUILDINGS,
	QUOTE_NETWORK_EDGES,
	QUOTE_OTUC_PATH,
	QUOTE_NETWORK_PATH,
	QUOTE_ROUTE_REGENS,
	QUOTE_AVAILABILITY_ICON_FLAG,
	QUOTE_BROADBAND_OBJ,
	QUOTE_UQUAL_SEARCH_BY_FLAG,
	QUOTE_UQUAL_ID,
	RESET_ACTION,
	QUOTE_TERM_MONTH_TO_MONTH,
	QUOTE_TYPE_OF_IPS,
	QUOTE_MODEM,
	QUOTE_ASSOC_REF_ID,
	QUOTE_TLC_THIS_LOC,
	QUOTE_ENT_GROUP,
	QUOTE_EXT_TO_EXT,
	QUOTE_EXT_LENGTH,
	QUOTE_PORTING,
	QUOTE_BTN,
	QUOTE_OFFICESUITE_QUANTITIES,
	QUOTE_OFFICESUITE_FEATURES,
	QUOTE_FEATURE_TOTAL_MRC,
	QUOTE_FEATURE_TOTAL_NRC,
	QUOTE_SUB_TOTAL_MRC,
	QUOTE_SUB_TOTAL_NRC,
	QUOTE_TOTAL_MRC,
	QUOTE_TOTAL_NRC,
	UPDATE_FEATURE_PRICING,
} from "../actions/types/actionTypes"

const initialState = {
	quoteValues: {
		cloned: "",
		availability: false,
		iconFlag: "",
		product: "",
		product_code: "",
		circuit: "",
		step: 1,
		term1: "",
		term2: "",
		term3: "",
		term4: "",
		term5: "",
		term10: "",
		bw: "",
		bw1: "",
		bw2: "",
		nniLoc: "",
		enniExtension: false,
		popLoc: "",
		qty: 1,
		ips: "",
		ddos: "",
		cam_qty: "",
		ddos_type: "",
		cross_connect: "0",
		rack_space: "",
		breaker_amp: "",
		primary_power: "",
		secondary_power: "",
		fuse_panel: "",
		cpe_type: "",
		router_service: "",
		vendor_exclusion: "",
		vendorexcl_att: "",
		vendorexcl_verizon: "",
		vendorexcl_lumen: "",
		vendorexcl_comcast: "",
		vendorexcl_spectrum: "",
		cable: "",
		adsl: "",
		coAddA: "",
		coAddZ: "",
		protocol1: "",
		protocol2: "",
		protocol3: "",
		route_type: "",
		diversity: "",
		route_latency: "",
		kmz_file: "",
		cosneeded: "",
		la_existing_uni: "",
		lz_existing_uni: "",
		a_vpn_port_1: "",
		a_vpn_port_2: "",
		a_vpn_port_3: "",
		z_vpn_port_1: "",
		z_vpn_port_2: "",
		z_vpn_port_3: "",
		cos1: "",
		cos2: "",
		cos3: "",
		accessType: "",
		burstbilling1: "",
		burstbilling2: "",
		burstbilling3: "",
		commitbw1: "",
		commitbw2: "",
		commitbw3: "",
		broadbandBWOptions: [],
		bamKey: "",
		errorMsg: "",
		kmzUrl: "",
		routeDetails: "",
		pathDetails: "",
		pathNumber: "",
		routeCapacity: 0,
		pathEdges: 0,
		autoPrices: {},
		routeHops: 0,
		routeFiberMiles: 0,
		pathLatency: 0,
		routeBuildings: 0,
		networkEdges: 0,
		otucPath: "",
		networkPath: "",
		routeRegens: 0,
		kineticBroadbandObj: {},
		uqualSearchByFlag: "",
		uqualId: "",
		termMonthToMonth: "",
		typeOfIps: "",
		modem: "1",
		assocRefId: "",
		tlcThisLoc: "",
		entGroup: "",
		extToExt: "",
		extLength: "",
		porting: "",
		btn: "",
		officeSuiteQuantities: [],
		officeSuiteFeatures: [],
		featureTotalMrc: [],
		featureTotalNrc: [],
		subTotalMrc: [],
		subTotalNrc: [],
		totalMrc: "",
		totalNrc: ""

	}
};

const quoteReducer = (state = initialState, action) => {
	switch (action.type) {
		case QUOTE_CLONE:
			return {
				...state,
				quoteValues: {
					...state.quoteValues,
					cloned: action.payload
				}
			}
		case QUOTE_AVAILABILITY_FLAG:
			return {
				...state,
				quoteValues: {
					...state.quoteValues,
					availability: action.payload
				}
			}
		case QUOTE_AVAILABILITY_ICON_FLAG:
			return {
				...state,
				quoteValues: {
					...state.quoteValues,
					iconFlag: action.payload
				}
			}
		case QUOTE_ROUTE_DETAILS:
			return {
				...state,
				quoteValues: {
					...state.quoteValues,
					routeDetails: action.payload
				}
			}
		case QUOTE_PATH_DETAILS:
			return {
				...state,
				quoteValues: {
					...state.quoteValues,
					pathDetails: action.payload
				}
			}
		case QUOTE_PATH_NUMBER:
			return {
				...state,
				quoteValues: {
					...state.quoteValues,
					pathNumber: action.payload
				}
			}
		case QUOTE_KMZ_URL:
			return {
				...state,
				quoteValues: {
					...state.quoteValues,
					kmzUrl: action.payload
				}
			}
		case QUOTE_ROUTE_CAPACITY:
			return {
				...state,
				quoteValues: {
					...state.quoteValues,
					routeCapacity: action.payload
				}
			}
		case QUOTE_PATH_EDGES:
			return {
				...state,
				quoteValues: {
					...state.quoteValues,
					pathEdges: action.payload
				}
			}
		case QUOTE_AUTO_PRICES:
			return {
				...state,
				quoteValues: {
					...state.quoteValues,
					autoPrices: action.payload
				}
			}
		case QUOTE_ROUTE_HOPS:
			return {
				...state,
				quoteValues: {
					...state.quoteValues,
					routeHops: action.payload
				}
			}
		case QUOTE_ROUTE_FIBER_MILES:
			return {
				...state,
				quoteValues: {
					...state.quoteValues,
					routeFiberMiles: action.payload
				}
			}
		case QUOTE_PATH_LATENCY:
			return {
				...state,
				quoteValues: {
					...state.quoteValues,
					pathLatency: action.payload
				}
			}
		case QUOTE_ROUTE_BUILDINGS:
			return {
				...state,
				quoteValues: {
					...state.quoteValues,
					routeBuildings: action.payload
				}
			}
		case QUOTE_NETWORK_EDGES:
			return {
				...state,
				quoteValues: {
					...state.quoteValues,
					networkEdges: action.payload
				}
			}
		case QUOTE_OTUC_PATH:
			return {
				...state,
				quoteValues: {
					...state.quoteValues,
					otucPath: action.payload
				}
			}
		case QUOTE_NETWORK_PATH:
			return {
				...state,
				quoteValues: {
					...state.quoteValues,
					networkPath: action.payload
				}
			}
		case QUOTE_ROUTE_REGENS:
			return {
				...state,
				quoteValues: {
					...state.quoteValues,
					routeRegens: action.payload
				}
			}
		case QUOTE_PRODUCT:
			return {
				...state,
				quoteValues: {
					...state.quoteValues,
					product: action.payload
				}
			}
		case QUOTE_PRODUCT_CODE:
			return {
				...state,
				quoteValues: {
					...state.quoteValues,
					product_code: action.payload
				}
			}
		case QUOTE_CIRCUIT_TYPE:
			return {
				...state,
				quoteValues: {
					...state.quoteValues,
					circuit: action.payload
				}
			}
		case QUOTE_STEP_CODE:
			return {
				...state,
				quoteValues: {
					...state.quoteValues,
					step: action.payload
				}
			}
		case QUOTE_TERM_1:
			return {
				...state,
				quoteValues: {
					...state.quoteValues,
					term1: action.payload,
				}
			}
		case QUOTE_TERM_2:
			return {
				...state,
				quoteValues: {
					...state.quoteValues,
					term2: action.payload,
				}
			}
		case QUOTE_TERM_3:
			return {
				...state,
				quoteValues: {
					...state.quoteValues,
					term3: action.payload,

				}
			}
		case QUOTE_TERM_4:
			return {
				...state,
				quoteValues: {
					...state.quoteValues,
					term4: action.payload,

				}
			}
		case QUOTE_TERM_5:
			return {
				...state,
				quoteValues: {
					...state.quoteValues,
					term5: action.payload,
				}
			}
		case QUOTE_TERM_10:
			return {
				...state,
				quoteValues: {
					...state.quoteValues,
					term10: action.payload,
				}
			}
		case QUOTE_BW:
			return {
				...state,
				quoteValues: {
					...state.quoteValues,
					bw: action.payload
				}
			}
		case QUOTE_ADD_BW_1:
			return {
				...state,
				quoteValues: {
					...state.quoteValues,
					bw1: action.payload
				}
			}
		case QUOTE_ADD_BW_2:
			return {
				...state,
				quoteValues: {
					...state.quoteValues,
					bw2: action.payload
				}
			}
		case QUOTE_NNI_LOCATION:
			return {
				...state,
				quoteValues: {
					...state.quoteValues,
					nniLoc: action.payload
				}
			}
		case QUOTE_ENNI_EXTENSION:
			return {
				...state,
				quoteValues: {
					...state.quoteValues,
					enniExtension: action.payload
				}
			}
		case QUOTE_POP_LOCATION:
			return {
				...state,
				quoteValues: {
					...state.quoteValues,
					popLoc: action.payload
				}
			}
		case QUOTE_IPS:
			return {
				...state,
				quoteValues: {
					...state.quoteValues,
					ips: action.payload
				}
			}
		case QUOTE_DDOS:
			return {
				...state,
				quoteValues: {
					...state.quoteValues,
					ddos: action.payload
				}
			}
		case QUOTE_CAM_QTY:
			return {
				...state,
				quoteValues: {
					...state.quoteValues,
					cam_qty: action.payload
				}
			}
		case QUOTE_DDOS_TYPE:
			return {
				...state,
				quoteValues: {
					...state.quoteValues,
					ddos_type: action.payload
				}
			}
		case QUOTE_QTY:
			return {
				...state,
				quoteValues: {
					...state.quoteValues,
					qty: action.payload
				}
			}
		case QUOTE_FIBER_CROSS_CONNECT:
			return {
				...state,
				quoteValues: {
					...state.quoteValues,
					cross_connect: action.payload
				}
			}
		case QUOTE_RACK_SPACE:
			return {
				...state,
				quoteValues: {
					...state.quoteValues,
					rack_space: action.payload
				}
			}
		case QUOTE_BREAKER_AMP:
			return {
				...state,
				quoteValues: {
					...state.quoteValues,
					breaker_amp: action.payload
				}
			}
		case QUOTE_PRIMARY_POWER:
			return {
				...state,
				quoteValues: {
					...state.quoteValues,
					primary_power: action.payload
				}
			}
		case QUOTE_SECONDARY_POWER:
			return {
				...state,
				quoteValues: {
					...state.quoteValues,
					secondary_power: action.payload
				}
			}
		case QUOTE_FUSE_PANEL:
			return {
				...state,
				quoteValues: {
					...state.quoteValues,
					fuse_panel: action.payload
				}
			}
		case QUOTE_CPE_TYPE:
			return {
				...state,
				quoteValues: {
					...state.quoteValues,
					cpe_type: action.payload
				}
			}
		case QUOTE_INTERNET_ROUTER:
			return {
				...state,
				quoteValues: {
					...state.quoteValues,
					router_service: action.payload
				}
			}
		case QUOTE_VENDOR_EXCLUSION:
			return {
				...state,
				quoteValues: {
					...state.quoteValues,
					vendor_exclusion: action.payload
				}
			}
		case QUOTE_ATT_EXCLUSION:
			return {
				...state,
				quoteValues: {
					...state.quoteValues,
					vendorexcl_att: action.payload
				}
			}
		case QUOTE_VERIZON_EXCLUSION:
			return {
				...state,
				quoteValues: {
					...state.quoteValues,
					vendorexcl_verizon: action.payload
				}
			}
		case QUOTE_LUMEN_EXCLUSION:
			return {
				...state,
				quoteValues: {
					...state.quoteValues,
					vendorexcl_lumen: action.payload
				}
			}
		case QUOTE_COMCAST_EXCLUSION:
			return {
				...state,
				quoteValues: {
					...state.quoteValues,
					vendorexcl_comcast: action.payload
				}
			}
		case QUOTE_SPECTRUM_EXCLUSION:
			return {
				...state,
				quoteValues: {
					...state.quoteValues,
					vendorexcl_spectrum: action.payload
				}
			}
		case QUOTE_BROADBAND_CABLE:
			return {
				...state,
				quoteValues: {
					...state.quoteValues,
					cable: action.payload
				}
			}
		case QUOTE_BROADBAND_ADSL:
			return {
				...state,
				quoteValues: {
					...state.quoteValues,
					adsl: action.payload
				}
			}
		case QUOTE_A_ADDRESS:
			return {
				...state,
				quoteValues: {
					...state.quoteValues,
					coAddA: action.payload
				}
			}
		case QUOTE_Z_ADDRESS:
			return {
				...state,
				quoteValues: {
					...state.quoteValues,
					coAddZ: action.payload
				}
			}
		case QUOTE_PROTOCOL_1:
			return {
				...state,
				quoteValues: {
					...state.quoteValues,
					protocol1: action.payload
				}
			}
		case QUOTE_PROTOCOL_2:
			return {
				...state,
				quoteValues: {
					...state.quoteValues,
					protocol2: action.payload
				}
			}
		case QUOTE_PROTOCOL_3:
			return {
				...state,
				quoteValues: {
					...state.quoteValues,
					protocol3: action.payload
				}
			}
		case QUOTE_ROUTE_TYPE:
			return {
				...state,
				quoteValues: {
					...state.quoteValues,
					route_type: action.payload
				}
			}
		case QUOTE_DIVERSITY:
			return {
				...state,
				quoteValues: {
					...state.quoteValues,
					diversity: action.payload
				}
			}
		case QUOTE_DIVERSITY_NOTE:
			return {
				...state,
				quoteValues: {
					...state.quoteValues,
					div_note: action.payload
				}
			}
		case QUOTE_ROUTE_LATENCY:
			return {
				...state,
				quoteValues: {
					...state.quoteValues,
					route_latency: action.payload
				}
			}
		case QUOTE_KMZ_FILE:
			return {
				...state,
				quoteValues: {
					...state.quoteValues,
					kmz_file: action.payload
				}
			}

		case QUOTE_COS:
			return {
				...state,
				quoteValues: {
					...state.quoteValues,
					cosneeded: action.payload
				}
			}

		case QUOTE_DF_OPTIONS:
			return {
				...state,
				quoteValues: {
					...state.quoteValues,
					df_options: action.payload
				}
			}

		case QUOTE_DF_TERM:
			return {
				...state,
				quoteValues: {
					...state.quoteValues,
					df_term: action.payload
				}
			}

		case QUOTE_DF_NUM_FIBERS:
			return {
				...state,
				quoteValues: {
					...state.quoteValues,
					df_num_fibers: action.payload
				}
			}

		case QUOTE_DF_COMMENTS:
			return {
				...state,
				quoteValues: {
					...state.quoteValues,
					df_comments: action.payload
				}
			}

		case QUOTE_LOCA_UNI:
			return {
				...state,
				quoteValues: {
					...state.quoteValues,
					la_existing_uni: action.payload
				}
			}
		case QUOTE_LOCZ_UNI:
			return {
				...state,
				quoteValues: {
					...state.quoteValues,
					lz_existing_uni: action.payload
				}
			}

		case QUOTE_A_VPN_PORT_1:
			return {
				...state,
				quoteValues: {
					...state.quoteValues,
					a_vpn_port_1: action.payload
				}
			}

		case QUOTE_A_VPN_PORT_2:
			return {
				...state,
				quoteValues: {
					...state.quoteValues,
					a_vpn_port_2: action.payload
				}
			}
		case QUOTE_A_VPN_PORT_3:
			return {
				...state,
				quoteValues: {
					...state.quoteValues,
					a_vpn_port_3: action.payload
				}
			}
		case QUOTE_Z_VPN_PORT_1:
			return {
				...state,
				quoteValues: {
					...state.quoteValues,
					z_vpn_port_1: action.payload
				}
			}
		case QUOTE_Z_VPN_PORT_2:
			return {
				...state,
				quoteValues: {
					...state.quoteValues,
					z_vpn_port_2: action.payload
				}
			}
		case QUOTE_Z_VPN_PORT_3:
			return {
				...state,
				quoteValues: {
					...state.quoteValues,
					z_vpn_port_3: action.payload
				}
			}
		case QUOTE_COS_TYPE_1:
			return {
				...state,
				quoteValues: {
					...state.quoteValues,
					cos1: action.payload
				}
			}
		case QUOTE_COS_TYPE_2:
			return {
				...state,
				quoteValues: {
					...state.quoteValues,
					cos2: action.payload
				}
			}
		case QUOTE_COS_TYPE_3:
			return {
				...state,
				quoteValues: {
					...state.quoteValues,
					cos3: action.payload
				}
			}

		case QUOTE_ACCESS_TYPE:
			return {
				...state,
				quoteValues: {
					...state.quoteValues,
					accessType: action.payload
				}
			}
		case QUOTE_BURSTABLE_BILLING_1:
			return {
				...state,
				quoteValues: {
					...state.quoteValues,
					burstbilling1: action.payload
				}
			}
		case QUOTE_BURSTABLE_BILLING_2:
			return {
				...state,
				quoteValues: {
					...state.quoteValues,
					burstbilling2: action.payload
				}
			}
		case QUOTE_BURSTABLE_BILLING_3:
			return {
				...state,
				quoteValues: {
					...state.quoteValues,
					burstbilling3: action.payload
				}
			}
		case QUOTE_COMMIT_BW_1:
			return {
				...state,
				quoteValues: {
					...state.quoteValues,
					commitbw1: action.payload
				}
			}
		case QUOTE_COMMIT_BW_2:
			return {
				...state,
				quoteValues: {
					...state.quoteValues,
					commitbw2: action.payload
				}
			}
		case QUOTE_COMMIT_BW_3:
			return {
				...state,
				quoteValues: {
					...state.quoteValues,
					commitbw3: action.payload
				}
			}
		case QUOTE_BROADBAND_BANDWIDTHS:
			return {
				...state,
				quoteValues: {
					...state.quoteValues,
					broadbandBWOptions: action.payload
				}
			}
		case QUOTE_BAM_KEY:
			return {
				...state,
				quoteValues: {
					...state.quoteValues,
					bamKey: action.payload
				}
			}
		case QUOTE_BROADBAND_OBJ:
			return {
				...state,
				quoteValues: {
					...state.quoteValues,
					kineticBroadbandObj: action.payload
				}
			}
		case QUOTE_UQUAL_SEARCH_BY_FLAG:
			return {
				...state,
				quoteValues: {
					...state.quoteValues,
					uqualSearchByFlag: action.payload
				}
			}
		case QUOTE_UQUAL_ID:
			return {
				...state,
				quoteValues: {
					...state.quoteValues,
					uqualId: action.payload
				}
			}
		case QUOTE_TERM_MONTH_TO_MONTH:
			return {
				...state,
				quoteValues: {
					...state.quoteValues,
					termMonthToMonth: action.payload
				}
			}
		case QUOTE_TYPE_OF_IPS:
			return {
				...state,
				quoteValues: {
					...state.quoteValues,
					typeOfIps: action.payload
				}
			}
		case QUOTE_MODEM: {
			return {
				...state,
				quoteValues: {
					...state.quoteValues,
					modem: action.payload
				}
			}
		}
		case QUOTE_ASSOC_REF_ID: {
			return {
				...state,
				quoteValues: {
					...state.quoteValues,
					assocRefId: action.payload
				}
			}
		}
		case QUOTE_TLC_THIS_LOC: {
			return {
				...state,
				quoteValues: {
					...state.quoteValues,
					tlcThisLoc: action.payload
				}
			}
		}
		case QUOTE_ENT_GROUP: {
			return {
				...state,
				quoteValues: {
					...state.quoteValues,
					entGroup: action.payload
				}
			}
		}
		case QUOTE_EXT_TO_EXT: {
			return {
				...state,
				quoteValues: {
					...state.quoteValues,
					extToExt: action.payload
				}
			}
		}
		case QUOTE_EXT_LENGTH: {
			return {
				...state,
				quoteValues: {
					...state.quoteValues,
					extLength: action.payload
				}
			}
		}
		case QUOTE_PORTING: {
			return {
				...state,
				quoteValues: {
					...state.quoteValues,
					porting: action.payload
				}
			}
		}
		case QUOTE_BTN: {
			return {
				...state,
				quoteValues: {
					...state.quoteValues,
					btn: action.payload
				}
			}
		}
		case QUOTE_OFFICESUITE_QUANTITIES: {
			return {
				...state,
				quoteValues: {
					...state.quoteValues,
					officeSuiteQuantities: action.payload
				}
			}
		}
		case QUOTE_OFFICESUITE_FEATURES: {
			return {
				...state,
				quoteValues: {
					...state.quoteValues,
					officeSuiteFeatures: action.payload
				}
			}
		}
		case UPDATE_FEATURE_PRICING: {
			const { catId, itemId, mrc, nrc } = action.payload;

			const category = state.quoteValues.officeSuiteFeatures[catId] || { key: catId, value: {} };

			const itemFeature = category.value[itemId] ? category.value[itemId].feature : {};

			// Update the category with the new item pricing, while preserving the existing feature details
			const updatedCategory = {
				...category,
				value: {
					...category.value,
					[itemId]: {
						feature: itemFeature, // Preserve existing feature details
						mrc, // Update Monthly Recurring Charge
						nrc, // Update Non-Recurring Charge
					},
				},
			};
			// Update the state with the new category and item pricing information
			return {
				...state,
				quoteValues: {
					...state.quoteValues,
					officeSuiteFeatures: {
						...state.quoteValues.officeSuiteFeatures,
						[catId]: updatedCategory, // Update the specific category with the new or updated item
					},
				},
			};
		}
		case QUOTE_FEATURE_TOTAL_MRC: {
			return {
				...state,
				quoteValues: {
					...state.quoteValues,
					featureTotalMrc: {
						...state.quoteValues.featureTotalMrc,
						...action.payload
					}
				}
			};
		}
		case QUOTE_FEATURE_TOTAL_NRC: {
			return {
				...state,
				quoteValues: {
					...state.quoteValues,
					featureTotalNrc: {
						...state.quoteValues.featureTotalNrc,
						...action.payload
					}
				}
			};
		}
		case QUOTE_SUB_TOTAL_MRC: {
			const { newSubTotalMrc } = action.payload;
			return {
				...state,
				quoteValues: {
					...state.quoteValues,
					subTotalMrc: newSubTotalMrc
				}
			};
		}
		case QUOTE_SUB_TOTAL_NRC: {
			const { newSubTotalNrc } = action.payload;
			return {
				...state,
				quoteValues: {
					...state.quoteValues,
					subTotalNrc: newSubTotalNrc
				}
			};
		}
		case QUOTE_TOTAL_MRC: {
			const updatedTotalMrc = parseFloat(action.payload.updatedTotalMrc);

			return {
				...state,
				quoteValues: {
					...state.quoteValues,
					totalMrc: updatedTotalMrc
				}
			}
		}
		case QUOTE_TOTAL_NRC: {
			const updatedTotalNrc = parseFloat(action.payload.updatedTotalNrc);

			return {
				...state,
				quoteValues: {
					...state.quoteValues,
					totalNrc: updatedTotalNrc
				}
			}
		}
		case QUOTE_ERROR_MSG: {
			return {
				...state,
				quoteValues: {
					...state.quoteValues,
					errorMsg: action.payload
				}
			}
		}
		case RESET_ACTION:
			return initialState;
		default:
			return state
	}
}

export default quoteReducer;
