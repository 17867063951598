/**
 * Created by Rochaus on 1/20/2021.
 */
import React from 'react';
import {NavLink} from 'react-router-dom';

const TabsQuote = () => {
    return (
        <div className="tab-nav cf">
            <NavLink className="tab-opt qm-summary" to="/History/CircuitDetails" activeClassName="active">
                <span>Circuit Details</span>
            </NavLink>
            <NavLink className="tab-opt qm-create" to="/History/LightLevels" activeClassName="active">
                <span>Light Levels</span>
            </NavLink>
            <NavLink className="tab-opt qm-search" to="/History/TroubleTicketsHistory" activeClassName="active">
                <span>Tickets</span>
            </NavLink>
            <NavLink className="tab-opt qm-upload" to="/History/Maintenance" activeClassName="active">
                <span>Maintenance</span>
            </NavLink>
        </div>
    );
}

export default TabsQuote;
