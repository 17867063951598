import React, {useEffect, useMemo, useState} from "react";
import {Link, useParams} from 'react-router-dom';
import ViewportWarning from "../shared/ViewportWarning";
import TabsQuote from "../quote/includes/TabsQuote";
import style from "../quote/QuoteCreate.module.css";
import Loader from "../shared/Loader";
import InternalHelper from "../../services/InternalReportService";
import InternalSuperHelper from '../../services/InternalQuoteService';
import {ConsoleLogger} from "../../logger/ConsoleLogger";


const PendingOrderUpdates = () => {
	const { refId } = useParams();
	const logger = useMemo(() => new ConsoleLogger({ level: process.env.REACT_APP_LOGGER_LEVEL, }), []);
	const [loader, setLoader] = useState(false); // loader
	const [successMsg, setSuccessMsg] = useState("");
	const [errorMsg, setErrorMsg] = useState("");
	const [oppNum, setOppNum] = useState('');
	const [laSfGaid, setLaSfGaid] = useState('');
	const [lzSfGaid, setLzSfGaid] = useState('');
	const [kpiDocId, setKpiDocId] = useState('');
	const [ncCode, setNcCode] = useState('');
	const [nciCode, setNciCode] = useState('');
	const [secnciCode, setSecnciCode] = useState('');
	const [requeueAsrDset, setRequeueAsrDset] = useState('');
	const [requeueQbDset, setRequeueQb] = useState('');
	const [product, setProduct] = useState('');
	const [pendingOrderReview, setPendingOrderReview] = useState('');
	const [pendingAsr, setPendingAsr] = useState('');
	const [pendingDset, setPendingDset] = useState('');
	const [pendingWf, setPendingWf] = useState('');
	const [pendingProv, setPendingProv] = useState('');
	const [pendingQb, setPendingQb] = useState('');
	const [pendingDocId, setPendingDocId] = useState('');

	useEffect(() => {
		setLoader(true);

		InternalSuperHelper.superPermissionCheck(refId).then(response => {
			if (Number(response.data) === 1) {
				InternalHelper.getPendingOrderData(refId).then((res) => {
					if (res.result === "SUCCESS") {
						setOppNum(res.data.opp_num);
						setLaSfGaid(res.data.la_sf_gaid);
						setLzSfGaid(res.data.lz_sf_gaid);
						setKpiDocId(res.data.kpi_doc_id);
						setNcCode(res.data.nc_code);
						setNciCode(res.data.nci_code);
						setSecnciCode(res.data.secnci_code);
						setProduct(res.data.product);
						setLoader(false);
					}
				})
			} else {
				setErrorMsg("No Access");
			}
		}).catch(error => {
			logger.error(`Error getting superPermissionCheck: ${error}`);
		})

	}, []);

	const handleSubmit = () => {
		setSuccessMsg("");
		setErrorMsg("");
		setLoader(true);
		let submitJSON = {
			"opp_num": oppNum,
			"la_sf_gaid": laSfGaid,
			"lz_sf_gaid": lzSfGaid,
			"kpi_doc_id": kpiDocId,
			"nc_code": ncCode,
			"nci_code": nciCode,
			"secnci_code": secnciCode,
			"requeue_asr_dset": requeueAsrDset,
			"requeue_qb_dset": requeueQbDset,
			"pending_order_review": pendingOrderReview,
			"pending_asr": pendingAsr,
			"pending_dset": pendingDset,
			"pending_wf": pendingWf,
			"pending_prov": pendingProv,
			"pending_qb": pendingQb,
			"pending_doc_id": pendingDocId,
		}

		logger.info('SubmitNNI:' + JSON.stringify(submitJSON));
		InternalHelper.updatePendingOrderData(refId, submitJSON).then(res => {
			if (res.data.result === "SUCCESS") {
				console.log("Success: " + res.data.update);
				setSuccessMsg(res.data.update);
			} else {
				console.log("Error: " + res.data.error);
				setErrorMsg(res.data.error);
			}
			setLoader(false);
		}).catch(error => {
			logger.error(`Error with the pricing update: ${error}`);
			setLoader(false);
		})
	}


	const inputChange = (ev) => {
		switch (ev.target.id) {
			case "oppNum":
				setOppNum(ev.target.value);
				break;
			case "laSfGaid":
				setLaSfGaid(ev.target.value);
				break;
			case "lzSfGaid":
				setLzSfGaid(ev.target.value);
				break;
			case "kpiDocId":
				setKpiDocId(ev.target.value);
				break;
			case "ncCode":
				setNcCode(ev.target.value);
				break;
			case "nciCode":
				setNciCode(ev.target.value);
				break;
			case "secnciCode":
				setSecnciCode(ev.target.value);
				break;
			case "requeueAsrDset":
				setRequeueAsrDset(ev.target.value);
				break;
			case "requeueQb":
				setRequeueQb(ev.target.value);
				break;
			case "pendingOrderReview":
				setPendingOrderReview(ev.target.value);
				break;
			case "pendingAsr":
				setPendingAsr(ev.target.value);
				break;
			case "pendingDset":
				setPendingDset(ev.target.value);
				break;
			case "pendingWf":
				setPendingWf(ev.target.value);
				break;
			case "pendingProv":
				setPendingProv(ev.target.value);
				break;
			case "pendingQb":
				setPendingQb(ev.target.value);
				break;
			case "pendingDocId":
				setPendingDocId(ev.target.value);
				break;
			default:
				break;
		}
	}


	return (
		<div className="midsection qm-upload abs z1">
			{loader ? <Loader /> : null}
			<div className="rel">
				<ViewportWarning />
				<div className="wrap">
					<h1 className="page-title">Pending Review Order Updates</h1>
					<div className="section">
						{/* <TabsQuoteManager/> */}
						<TabsQuote />
						<div className={`content ${style.contentQueryPage}`}>
							<form>
								{errorMsg?.length ?
									<div>
										<p className={`text-center my-4 text-danger`}>{errorMsg}</p>
									</div> : null}
								{successMsg?.length ?
									<div>
										<p className={`text-center my-4 text-success`}>{successMsg}</p>
									</div> : null}
								<div className='col-md-4'>
									<div className={`qm-summary mt-2`}>
										<button className={` ico-button lhs light-button ${style.nextBtn} `} type="button">
											<Link to="../../Reports/PendingOrderReview" className="back_btn_link">
												Return To Fallout Report
											</Link>
										</button>
									</div>
								</div>
								<div className={`sub-hdr alt ${style.subHeader} `}>
									<div className="nib">
										<div className="the-notch">
											<div className="notch-fg">
												<svg>
													<path
														d="M35.81,26.28l-13,10v-5C-10.47,28.68-1,9.16,11.79,0c0,7.28,0,9.13,0,13.28s3.18,7.92,11,9v-5Z"></path>
												</svg>
											</div>
										</div>
									</div>
									<h2 className={style.sectionTitle}>Order Data</h2>
								</div>
								<div className={`row ${style.rowGrp}`}>
									<div className="col-3 col-sm-4 col-md-3">
										<label className={style.lableTextS}>Ref ID </label>
										<input type="text" className={style.inputFld} id="ncCode" value={refId} disabled={true}></input>
									</div>
									<div className="col-3 col-sm-4 col-md-3">
									</div>
									<div className="col-3 col-sm-4 col-md-3">
									</div>
								</div>
								<div className={`row ${style.rowGrp}`}>
									<div className="col-3 col-sm-4 col-md-3">
										<label className={style.lableTextS}>NC Code </label>
										<input type="text" className={style.inputFld} id="ncCode"
											value={ncCode} maxLength='63' onChange={inputChange}></input>
									</div>
									<div className="col-3 col-sm-4 col-md-3">
										<label className={style.lableTextS}>Opportunity Number </label>
										<input type="text" className={style.inputFld} id="oppNum"
											value={oppNum} maxLength='63' onChange={inputChange}></input>
									</div>
									<div className="col-3 col-sm-4 col-md-3">
										<label className={style.lableTextS}>KPI DOC ID</label>
										<input type="text" className={style.inputFld} id="kpiDocId"
											value={kpiDocId} maxLength='63' onChange={inputChange}></input>
									</div>
								</div>
								<div className={`row ${style.rowGrp}`}>
									<div className="col-3 col-sm-4 col-md-3">
										<label className={style.lableTextS}>NCI Code</label>
										<input type="text" className={style.inputFld} id="nciCode"
											value={nciCode} maxLength='63' onChange={inputChange}></input>
									</div>
									<div className="col-3 col-sm-4 col-md-3">
										<label className={style.lableTextS}>Loc A SF GAID </label>
										<input type="text" className={style.inputFld} id="laSfGaid"
											value={laSfGaid} maxLength='63' onChange={inputChange}></input>
									</div>
									<div className="col-3 col-sm-4 col-md-3">
									</div>
								</div>
								<div className={`row ${style.rowGrp}`}>
									<div className="col-3 col-sm-4 col-md-3">
										<label className={style.lableTextS}>SECNCI Code</label>
										<input type="text" className={style.inputFld} id="secnciCode"
											value={secnciCode} maxLength='63' onChange={inputChange}></input>
									</div>
									<div className="col-3 col-sm-4 col-md-3">
										{((Number(product) >= 1) && (Number(product) <= 3)) || (Number(product) === 19) || (Number(product) === 20) ?
											<><label className={style.lableTextS}>Loc Z SF GAID </label>
												<input type="text" className={style.inputFld} id="lzSfGaid"
													value={lzSfGaid} maxLength='63' onChange={inputChange}></input></>
											: null}
									</div>
									<div className="col-3 col-sm-4 col-md-3">
									</div>
								</div>
								<div className={`row ${style.rowGrp}`}>
									<div className="col-3 col-sm-4 col-md-3">
										<input type="checkbox" id="requeueAsrDset" name="requeueAsrDset" value="on"
											onChange={inputChange} />
										<label htmlFor="requeueAsrDset" className={` ${style.checkboxText} `}>Re-queue ASR/DSET</label>
									</div>
									<div className="col-3 col-sm-4 col-md-3">
										<input type="checkbox" id="requeueQb" name="requeueQb" value="on"
											onChange={inputChange} />
										<label htmlFor="requeueQb" className={` ${style.checkboxText} `}>Re-queue Quickbase</label>
									</div>
								</div>
								<div className={`sub-hdr alt ${style.subHeader} `}>
									<div className="nib">
										<div className="the-notch">
											<div className="notch-fg">
												<svg>
													<path
														d="M35.81,26.28l-13,10v-5C-10.47,28.68-1,9.16,11.79,0c0,7.28,0,9.13,0,13.28s3.18,7.92,11,9v-5Z"></path>
												</svg>
											</div>
										</div>
									</div>
									<h2 className={style.sectionTitle}>Clear Pending Tables</h2>
								</div>
								<div className={`row ${style.rowGrp}`}>
									<div className="col-3 col-sm-4 col-md-3">
										<input type="checkbox" id="pendingOrderReview" name="pendingOrderReview" value="on"
											onChange={inputChange} />
										<label htmlFor="pendingOrderReview" className={` ${style.checkboxText} `}>Order Review Fallout</label>
									</div>
									<div className="col-3 col-sm-4 col-md-3">
										<input type="checkbox" id="pendingAsr" name="pendingAsr" value="on"
											onChange={inputChange} />
										<label htmlFor="pendingAsr" className={` ${style.checkboxText} `}>Pending ASR</label>
									</div>
									<div className="col-3 col-sm-4 col-md-3">
										<input type="checkbox" id="pendingDset" name="pendingDset" value="on"
											onChange={inputChange} />
										<label htmlFor="pendingDset" className={` ${style.checkboxText} `}>Pending DSET</label>
									</div>
								</div>
								<div className={`row ${style.rowGrp}`}>
									<div className="col-3 col-sm-4 col-md-3">
										<input type="checkbox" id="pendingWf" name="pendingWf" value="on"
											onChange={inputChange} />
										<label htmlFor="pendingWf" className={` ${style.checkboxText} `}>Pending Winforce</label>
									</div>
									<div className="col-3 col-sm-4 col-md-3">
										<input type="checkbox" id="pendingProv" name="pendingProv" value="on"
											onChange={inputChange} />
										<label htmlFor="pendingProv" className={` ${style.checkboxText} `}>Pending Provisioning</label>
									</div>
									<div className="col-3 col-sm-4 col-md-3">
										<input type="checkbox" id="pendingQb" name="pendingQb" value="on"
											onChange={inputChange} />
										<label htmlFor="pendingQb" className={` ${style.checkboxText} `}>Pending Quickbase</label>
									</div>
								</div>
								<div className={`row ${style.rowGrp}`}>
									<div className="col-3 col-sm-4 col-md-3">
										<input type="checkbox" id="pendingDocId" name="pendingDocId" value="on"
											onChange={inputChange} />
										<label htmlFor="pendingDocId" className={` ${style.checkboxText} `}>Pending Document ID</label>
									</div>
								</div>
								<div className={"row my-5 rel"}>
									<div className={"col-12 text-center"}>
										<button className={` ico-button lhs light-button ${style.nextBtn} `} type="button" onClick={handleSubmit}>
											Submit
											<svg className="ico light-ico ico-arrow">
												<path d="M0,17H29L15,31l1,1L32,16,16,0,15,1,29,15H0Z"></path>
											</svg>
										</button>
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}


export default PendingOrderUpdates;
