import React, { useCallback, useEffect, useRef, useState } from 'react';
import style from "./TicketCreate.module.css";
import { connect, useSelector } from 'react-redux';
import LabelComponent from './LabelComponent';
import InputComponent from './InputComponent';
import RadioBtnComponent from './RadioBtnComponent';
import GoogleAutocomplete from '../GoogleAutomplete/GoogleAutocomplete';
import GoogleAutocompletez from '../GoogleAutomplete/GoogleAutocompleteLocZ';

import {
	tcCityA,
	tcCityZ,
	tcCompanyName,
	tcCustomerPersonalAccess,
	tcCustomerTicketNumber,
	tcDateOfcircuitDown,
	tcDateOfdurationFrom,
	tcDateOfdurationTo,
	tcDateOfpacketLoss,
	tcDescriptionOfProblem,
	tcDispatchAuthorization,
	tcErrorMsg,
	tcErrorRate,
	tcFibersAssociated,
	tcIntrusiveFrom,
	tcIntrusiveFromHrs,
	tcIntrusiveTestingAuthorized,
	tcIntrusiveTo,
	tcIntrusiveToHrs,
	tcIntrusiveTz,
	tcIruNumber,
	tcLocA,
	tcLocZ,
	tcNumberOfFibers,
	tcNumberOfSites,
	tcOtdrResults,
	tcPercentageLoss,
	tcPowerVerified,
	tcPullPerformanceMonitoring,
	tcSitesOut,
	tcStateA,
	tcStateZ,
	tcTimeOfBounce,
	tcTimeOfBounceHrs,
	tcBounceTz,
	tcTimeOfcircuitDownHrs,
	tcCircuitDownTz,
	tcTimeOfdurationFromHrs,
	tcTimeOfdurationToHrs,
	tcTimeOfpacketLossHrs,
	tcTurnUp,
	tcTypeOfErrors,
	tcZipA,
	tcZipZ
} from '../../redux';

const timeZoneOptions = [
	{ "id": "EST", "name": "EST" },
	{ "id": "CST", "name": "CST" },
	{ "id": "MST", "name": "MST" },
	{ "id": "PST", "name": "PST" },
	{ "id": "UTC", "name": "UTC" }
];
const TicketCreateTroubleDetail = (props) => {
	const troubleDetailsControls = props.troubleDetailsData;
	const troubleDetailsFromStore = useSelector(state => state?.ticketCreate?.troubleDetails);
	const selectedProductType = useSelector(state => state.ticketCreate.selectedProduct?.dbId);
	const selectedProductSubType = useSelector(state => state.ticketCreate?.selectedProductType);
	const [dispatchAuthorizationShow, setDispatchAuthorizationShow] = useState(false);

	let refs = useRef([React.createRef(), React.createRef()]);

	const checkIntrusive = useCallback((value) => {
		troubleDetailsControls.forEach(td => {
			if (value === 'yes') {
				if (['intrusiveFrom', 'intrusiveFromHrs', 'intrusiveTo', 'intrusiveToHrs'].includes(td.name)) {
					td.mandatory = true;
				}
			} else {
				if (['intrusiveFrom', 'intrusiveFromHrs', 'intrusiveTo', 'intrusiveToHrs'].includes(td.name)) {
					td.mandatory = false;
					props[td.name]('');
				}
			}
		})
	}, [props, troubleDetailsControls])

	useEffect(() => {
		if (troubleDetailsControls) {
			checkIntrusive(troubleDetailsFromStore?.intrusiveTestingAuthorized);
		}
	}, [checkIntrusive, troubleDetailsControls, troubleDetailsFromStore?.intrusiveTestingAuthorized]);

	/**
	 * Updates the input value in the redux store and performs additional actions based on the input object.
	 *
	 * @param {object} obj - The input object containing the name and value properties.
	 * @param {object} e - The event object containing the target value.
	 * @return {void} This function does not return a value.
	 */
	const inputChange = (obj, event) => {
		const value = event.target.value;

		if (obj.name) {
			props[obj.name](value);
		}

		if (obj.name === "dispatchAuthorization" && value === 'yes') {
			setDispatchAuthorizationShow(true);
		}

		if (obj.name === 'intrusiveTestingAuthorized') {
			checkIntrusive(value);
		}

		props.updateErrorMsg('');
	}


	//Handles the dispatch pop up when the dispatch is true
	const dispatchHandler = async (action) => {
		let isCancel = (action === "cancel") ? 'no' : 'yes';
		setDispatchAuthorizationShow(false);
		props.dispatchAuthorization(isCancel);
	}

	const onChangeAutocomplete = (e, field) => {
		let address = '';
		let zip = '';
		let city = ''
		let state = '';

		for (const component of e.address_components) {
			const componentType = component.types[0];
			switch (componentType) {
				case "street_number": {
					address = `${component.long_name} ${address}`;
					break;
				}

				case "route": {
					address += component.short_name;
					break;
				}

				case "postal_code": {
					zip = `${component.long_name}${zip}`;
					break;
				}

				case "postal_code_suffix": {
					zip = `${zip}-${component.long_name}`;
					break;
				}

				case "locality":
					city = component.long_name;
					break;

				case "administrative_area_level_1": {
					state = component.short_name;
					break;
				}

				default: break;
			}

			if (field === "locA") {
				props.LocA(address);
				props.cityA(city);
				props.stateA(state);
				props.zipA(zip);
			} else {
				props.LocZ(address);
				props.cityZ(city);
				props.stateZ(state);
				props.zipZ(zip);
			}
		}
	}

	return (
		<>
			{troubleDetailsControls.length > 0 ?
				<div className={`step2 ${style.rowGap}`}>
					<div className="row space-between">
						<div className="col-sm-12 col-md-5">
							<div className="form-group">
								<InputComponent type="text" cName={style.inputFldText} obj={troubleDetailsControls[0]} val={troubleDetailsFromStore['customerTicketNumber']} inputEmit={(e) => { inputChange(troubleDetailsControls[0], e) }} />
							</div>

							{/*Type of Errors Text Box*/}
							{selectedProductSubType?.dbId === 2 ?
								<div className="form-group">
									<InputComponent type="text" cName={style.inputFldText} obj={troubleDetailsControls[9]} val={troubleDetailsFromStore['typeOfErrors']} inputEmit={(e) => { inputChange(troubleDetailsControls[9], e) }} />
								</div> : null}

							{/*Pulling Performance Monitoring Button*/}
							{selectedProductSubType?.dbId === 8 ?
								<div className="form-group">
									<RadioBtnComponent obj={troubleDetailsControls[17]} val={troubleDetailsFromStore['pullPerformanceMonitoring']} radioBtnEmit={(e) => { inputChange(troubleDetailsControls[17], e) }} />
								</div>
								: null}

							{/*Power Verified Radio Button*/}
							<div className="form-group">
								<RadioBtnComponent obj={troubleDetailsControls[1]} val={troubleDetailsFromStore['powerVerified']} radioBtnEmit={(e) => { inputChange(troubleDetailsControls[1], e) }} />
							</div>

							{/*Number of Sites Text Box (Out of Service, Packet Loss, and Simplex Type*/}
							{selectedProductSubType?.dbId === 5 || selectedProductSubType?.dbId === 6 || selectedProductSubType?.dbId === 7 ?
								<div className="form-group">
									<InputComponent type="text" cName={style.inputFldText} obj={troubleDetailsControls[18]} val={troubleDetailsFromStore['numberOfSites']} inputEmit={(e) => { inputChange(troubleDetailsControls[18], e) }} />
								</div> : null}


							{/*Percentage Loss Text Box (Packet Loss Type*/}
							{selectedProductSubType?.dbId === 6 ?
								<div className="form-group">
									<InputComponent type="text" cName={style.inputFldText} obj={troubleDetailsControls[22]} val={troubleDetailsFromStore['percentageLoss']} inputEmit={(e) => { inputChange(troubleDetailsControls[22], e) }} />
								</div> : null}

							{/*Dipatch Authorization Button*/}
							<div className="form-group">
								<RadioBtnComponent obj={troubleDetailsControls[5]} val={troubleDetailsFromStore['dispatchAuthorization']} radioBtnEmit={(e) => { inputChange(troubleDetailsControls[5], e) }} />
							</div>

							{selectedProductSubType?.dbId !== 5 || selectedProductSubType?.dbId !== 7 || selectedProductSubType?.dbId !== 8 ?
								<>
									{/*Intrusive Testing Authorized Button*/}
									<div className="form-group">
										<RadioBtnComponent obj={troubleDetailsControls[6]} val={troubleDetailsFromStore['intrusiveTestingAuthorized']} radioBtnEmit={(e) => { inputChange(troubleDetailsControls[6], e) }} />
									</div>

									{
										troubleDetailsFromStore['intrusiveTestingAuthorized'] === 'yes' ?
											<>
												<div className="form-group ">
													<InputComponent type="date" placeholder="MM/DD/YYYY" cName={` ${style.inputFldDate} w-35 mb-3`} obj={troubleDetailsControls[36]} val={troubleDetailsFromStore['intrusiveFrom']} inputEmit={(e) => { inputChange(troubleDetailsControls[36], e) }} />
													<InputComponent type="time" placeholder="hh:mm am/pm" cName={` ${style.inputFldTime} ${style.hrsFld} mr-3`} obj={troubleDetailsControls[37]} val={troubleDetailsFromStore['intrusiveFromHrs']} inputEmit={(e) => { inputChange(troubleDetailsControls[37], e) }} />
													<select className={`${style.selectFld} ${style.timeZoneFld}`} value={troubleDetailsFromStore['intrusiveTz']} onChange={(e) => inputChange(troubleDetailsControls[40], e)}>
														{timeZoneOptions.map((obj) => {
															return <option value={obj.id}>{obj.name}</option>
														})}
													</select>
												</div>
												<div className="form-group mb-5">
													<InputComponent type="date" placeholder="MM/DD/YYYY" cName={` ${style.inputFldDate} w-35 mb-3`} obj={troubleDetailsControls[38]} val={troubleDetailsFromStore['intrusiveTo']} inputEmit={(e) => { inputChange(troubleDetailsControls[38], e) }} />
													<InputComponent type="time" placeholder="hh:mm am/pm" cName={` ${style.inputFldTime} ${style.hrsFld} mr-3`} obj={troubleDetailsControls[39]} val={troubleDetailsFromStore['intrusiveToHrs']} inputEmit={(e) => { inputChange(troubleDetailsControls[39], e) }} />
													<select className={`${style.selectFld} ${style.timeZoneFld} `} value={troubleDetailsFromStore['intrusiveTz']} onChange={(e) => inputChange(troubleDetailsControls[40], e)}>
														{timeZoneOptions.map((obj) => {
															return <option value={obj.id}>{obj.name}</option>
														})}
													</select>
												</div>
											</>
											: null
									}

									{/*Turn Up Button*/}
									<div className="form-group">
										<RadioBtnComponent obj={troubleDetailsControls[7]} val={troubleDetailsFromStore['turnUp']} radioBtnEmit={(e) => { inputChange(troubleDetailsControls[7], e) }} />
									</div></> : null}

							{/*Customer Personal Access Button Only for Out of Service Trouble Type*/}
							{selectedProductSubType?.dbId === 5 ?
								<div className="form-group">
									<RadioBtnComponent obj={troubleDetailsControls[19]} val={troubleDetailsFromStore['customerPersonalAccess']} radioBtnEmit={(e) => { inputChange(troubleDetailsControls[19], e) }} />
								</div>
								: null}
							{/*Site(s) Out of Service Button (Simplex Trouble Type)*/}
							{selectedProductSubType?.dbId === 7 ?
								<div className="form-group">
									<RadioBtnComponent obj={troubleDetailsControls[23]} val={troubleDetailsFromStore['sitesOut']} radioBtnEmit={(e) => { inputChange(troubleDetailsControls[23], e) }} />
								</div>
								: null}

							{/*DARK FIBER A ADDRESS FIELDS*/}
							{selectedProductType === 1 ?
								<>

									<div className="form-group">
										<LabelComponent obj={troubleDetailsControls[24]} />
										<GoogleAutocomplete name='locA' id='locA' cName={style.inputFld} placeholder="Enter your address" val={troubleDetailsFromStore['LocA']} ref={refs.current[0]} addressValues={(e, field) => { onChangeAutocomplete(e, field) }} />
									</div>

									<div className="row">
										<div className="col-sm">
											<InputComponent type="text" cName={style.inputCityFld} obj={troubleDetailsControls[25]} val={troubleDetailsFromStore['cityA']} inputEmit={(e) => { inputChange(troubleDetailsControls[25], e) }} />
										</div>
										<div className="col-sm">
											{/* <LabelComponent obj={troubleDetailsControls[26]} /> */}
											<InputComponent type="text" cName={style.inputCityFld} obj={troubleDetailsControls[26]} val={troubleDetailsFromStore['stateA']} inputEmit={(e) => { inputChange(troubleDetailsControls[26], e) }} />
											{/* <select className={` form-control  ${style.selectFld} ${style.requiredFld} `} defaultValue={troubleDetailsFromStore['stateA']} onChange={(e) => { inputChange(troubleDetailsControls[26], e) }}>
                                                <option>Select</option>
                                                <option>State 1</option>
                                                <option>State 2</option>
                                                <option>State 3</option>
                                                <option>State 4</option>
                                            </select> */}

										</div>
										<div className="col-sm">
											<InputComponent type="text" cName={style.inputFld} obj={troubleDetailsControls[27]} val={troubleDetailsFromStore['zipA']} inputEmit={(e) => { inputChange(troubleDetailsControls[27], e) }} />
										</div>
									</div>
								</>
								: null}

							{/*DARK FIBER Z ADDRESS FIELDS*/}
							{selectedProductType === 1 ?
								<>
									<div className="form-group mt-5">
										<LabelComponent obj={troubleDetailsControls[28]} />
										<GoogleAutocompletez name='locZ' id='locZ' cName={style.inputFld} placeholder="Enter your address" val={troubleDetailsFromStore['LocZ']} ref={refs.current[1]} addressValues={(e, field) => { onChangeAutocomplete(e, field) }} />
									</div>
									<div className="form-group">
										<div className="row">
											<div className="col-sm">
												<InputComponent type="text" cName={style.inputCityFld} obj={troubleDetailsControls[29]} val={troubleDetailsFromStore['cityZ']} inputEmit={(e) => { inputChange(troubleDetailsControls[29], e) }} />
											</div>
											<div className="col-sm">
												<InputComponent type="text" cName={style.inputCityFld} obj={troubleDetailsControls[30]} val={troubleDetailsFromStore['stateZ']} inputEmit={(e) => { inputChange(troubleDetailsControls[30], e) }} />
											</div>
											<div className="col-sm">
												<InputComponent type="text" cName={style.inputCityFld} obj={troubleDetailsControls[31]} val={troubleDetailsFromStore['zipZ']} inputEmit={(e) => { inputChange(troubleDetailsControls[31], e) }} />
											</div>
										</div>
									</div>
								</>
								: null}
						</div>


						<div className="col-sm-12 col-md-6">
							{/*Problem Description Text Box (Every Trouble Type*/}
							<div className="form-group">
								<LabelComponent obj={troubleDetailsControls[2]} />
								<textarea id="problemDescription" className={style.textareaFld} value={troubleDetailsFromStore['descriptionOfProblem']} onChange={(e) => { inputChange(troubleDetailsControls[2], e) }}></textarea>
							</div>

							{/*Error Rate Text Box (Data Connection Errors Trouble Type)*/}
							{selectedProductSubType?.dbId === 2 ?
								<div className="form-group">
									<InputComponent type="text" cName={style.inputFldText} obj={troubleDetailsControls[10]} val={troubleDetailsFromStore['errorRate']} inputEmit={(e) => { inputChange(troubleDetailsControls[10], e) }} />
								</div> : null}


							{/*Time of Bounce Date and Hour Box (Bouncing Circuit Trouble Type*/}
							{selectedProductSubType?.dbId === 1 ?
								<div className="form-group">
									<InputComponent type="date" placeholder="MM/DD/YYYY" cName={` ${style.inputFldDate} w-35 mb-3`} obj={troubleDetailsControls[4]} val={troubleDetailsFromStore['timeOfBounce']} inputEmit={(e) => { inputChange(troubleDetailsControls[4], e) }} />
									<InputComponent type="time" placeholder="hh:mm am/pm" cName={`${style.inputFldTime} mr-3`} obj={troubleDetailsControls[8]} val={troubleDetailsFromStore['timeOfBounceHrs']} inputEmit={(e) => { inputChange(troubleDetailsControls[8], e) }} />
									<select className={`${style.selectFld} ${style.timeZoneFld} mb-3 `} value={troubleDetailsFromStore['bounceTimeTz']} onChange={(e) => inputChange(troubleDetailsControls[42], e)}>
										{timeZoneOptions.map((obj) => {
											return <option value={obj.id}>{obj.name}</option>
										})}
									</select>
								</div> : null}


							{/*Circuit Down Date and Hour Box (Data Down Trouble Type*/}
							{selectedProductSubType?.dbId === 3 ?
								<div className="form-group">
									<InputComponent type="date" placeholder="MM/DD/YYYY" cName={` ${style.inputFldDate} w-35 mb-3`} obj={troubleDetailsControls[11]} val={troubleDetailsFromStore['dateOfcircuitDown']} inputEmit={(e) => { inputChange(troubleDetailsControls[11], e) }} />
									<InputComponent type="time" placeholder="hh:mm am/pm" cName={`${style.inputFldTime} mr-3`} obj={troubleDetailsControls[12]} val={troubleDetailsFromStore['timeOfcircuitDownHrs']} inputEmit={(e) => { inputChange(troubleDetailsControls[12], e) }} />
									<select className={`${style.selectFld} ${style.timeZoneFld}`} value={troubleDetailsFromStore['circuitDownTz']} onChange={(e) => inputChange(troubleDetailsControls[41], e)}>
										{timeZoneOptions.map((obj) => {
											return <option value={obj.id}>{obj.name}</option>
										})}
									</select>
								</div> : null}

							{/*Time of Packet Loss Date and Hour Box (Packet Loss Trouble Type*/}
							{selectedProductSubType?.dbId === 6 ?
								<div className="form-group">
									<InputComponent type="date" placeholder="MM/DD/YYYY" cName={style.inputFldDate} obj={troubleDetailsControls[20]} val={troubleDetailsFromStore['dateOfpacketLoss']} inputEmit={(e) => { inputChange(troubleDetailsControls[20], e) }} />
									<InputComponent type="time" placeholder="hh:mm am/pm" cName={style.inputFldTime} obj={troubleDetailsControls[21]} val={troubleDetailsFromStore['timeOfpacketLossHrs']} inputEmit={(e) => { inputChange(troubleDetailsControls[21], e) }} />
								</div> : null}


							{/*Duration Request Date and Hour Box (Monitoring/Request Trouble Type*/}
							{selectedProductSubType?.dbId === 8 ?
								<>
									<div className="form-group">
										<InputComponent type="date" placeholder="MM/DD/YYYY" cName={style.inputFldDate} obj={troubleDetailsControls[13]} val={troubleDetailsFromStore['dateOfdurationFrom']} inputEmit={(e) => { inputChange(troubleDetailsControls[13], e) }} />
										<InputComponent type="time" placeholder="hh:mm am/pm" cName={style.inputFldTime} obj={troubleDetailsControls[14]} val={troubleDetailsFromStore['timeOfdurationFromHrs']} inputEmit={(e) => { inputChange(troubleDetailsControls[14], e) }} />
									</div>
									<div className="form-group">
										<InputComponent type="date" placeholder="MM/DD/YYYY" cName={style.inputFldDate} obj={troubleDetailsControls[15]} val={troubleDetailsFromStore['dateOfdurationTo']} inputEmit={(e) => { inputChange(troubleDetailsControls[15], e) }} />
										<InputComponent type="time" placeholder="hh:mm am/pm" cName={style.inputFldTime} obj={troubleDetailsControls[16]} val={troubleDetailsFromStore['timeOfdurationToHrs']} inputEmit={(e) => { inputChange(troubleDetailsControls[16], e) }} />
									</div></> : null}


							{/*DARK FIBER Additional Trouble Detail Fields*/}
							{selectedProductType === 1 ?
								<>
									<div className="form-group">
										<InputComponent type="text" cName={style.inputFldText} obj={troubleDetailsControls[32]} val={troubleDetailsFromStore['iruNumber']} inputEmit={(e) => { inputChange(troubleDetailsControls[32], e) }} />
									</div>
									<div className="form-group">
										<InputComponent type="text" cName={style.inputFldText} obj={troubleDetailsControls[33]} val={troubleDetailsFromStore['otdrResults']} inputEmit={(e) => { inputChange(troubleDetailsControls[33], e) }} />
									</div>
									<div className="form-group">
										<InputComponent type="text" cName={style.inputFldText} obj={troubleDetailsControls[34]} val={troubleDetailsFromStore['numberOfFibers']} inputEmit={(e) => { inputChange(troubleDetailsControls[34], e) }} />
									</div>
									<div className="form-group">
										<InputComponent type="text" cName={style.inputFldText} obj={troubleDetailsControls[35]} val={troubleDetailsFromStore['fibersAssociated']} inputEmit={(e) => { inputChange(troubleDetailsControls[35], e) }} />
									</div></>
								: null}

						</div>
					</div>

				</div>
				: null}

			{
				dispatchAuthorizationShow ?
					<div>
						<div className={`${style.overly}`}> </div>
						<div className="modal fade show d-block">
							<div className="modal-dialog modal-dialog-centered modal-lg">
								<div className="modal-content">
									<div className="modal-body">
										When you authorize Windstream to dispatch a technician to your site and the trouble is not on our network, you may incur a service fee.
										To confirm you want to authorize dispatch, Click 'Ok'. If you want to dispatch authorization, Click 'Cancel'.
									</div>

									<div className="modal-footer">
										<button type="button" className="btn-style btn-cancel" data-dismiss="modal" onClick={() => { dispatchHandler('cancel') }}>Cancel</button>
										<button type="button" className="btn-style btn-theme" data-dismiss="modal" onClick={() => { dispatchHandler('ok') }}>Ok</button>
									</div>

								</div>
							</div>
						</div>
					</div>
					: null
			}
		</>
	)
}

const mapDispatchToProps = {
	customerTicketNumber: tcCustomerTicketNumber,
	powerVerified: tcPowerVerified,
	descriptionOfProblem: tcDescriptionOfProblem,
	companyName: tcCompanyName,
	timeOfBounce: tcTimeOfBounce,
	dispatchAuthorization: tcDispatchAuthorization,
	intrusiveTestingAuthorized: tcIntrusiveTestingAuthorized,
	turnUp: tcTurnUp,
	timeOfBounceHrs: tcTimeOfBounceHrs,
	typeOfErrors: tcTypeOfErrors,
	errorRate: tcErrorRate,
	dateOfcircuitDown: tcDateOfcircuitDown,
	timeOfcircuitDownHrs: tcTimeOfcircuitDownHrs,
	dateOfdurationFrom: tcDateOfdurationFrom,
	timeOfdurationFromHrs: tcTimeOfdurationFromHrs,
	dateOfdurationTo: tcDateOfdurationTo,
	timeOfdurationToHrs: tcTimeOfdurationToHrs,
	pullPerformanceMonitoring: tcPullPerformanceMonitoring,
	numberOfSites: tcNumberOfSites,
	customerPersonalAccess: tcCustomerPersonalAccess,
	dateOfpacketLoss: tcDateOfpacketLoss,
	timeOfpacketLossHrs: tcTimeOfpacketLossHrs,
	percentageLoss: tcPercentageLoss,
	sitesOut: tcSitesOut,
	LocA: tcLocA,
	cityA: tcCityA,
	stateA: tcStateA,
	zipA: tcZipA,
	LocZ: tcLocZ,
	cityZ: tcCityZ,
	stateZ: tcStateZ,
	zipZ: tcZipZ,
	iruNumber: tcIruNumber,
	otdrResults: tcOtdrResults,
	numberOfFibers: tcNumberOfFibers,
	fibersAssociated: tcFibersAssociated,
	intrusiveFrom: tcIntrusiveFrom,
	intrusiveFromHrs: tcIntrusiveFromHrs,
	intrusiveTo: tcIntrusiveTo,
	intrusiveToHrs: tcIntrusiveToHrs,
	intrusiveTz: tcIntrusiveTz,
	circuitDownTz: tcCircuitDownTz,
	bounceTimeTz: tcBounceTz,
	updateErrorMsg: tcErrorMsg
}

export default connect(null, mapDispatchToProps)(TicketCreateTroubleDetail);
