import React, {useState} from 'react';
import {ConsoleLogger} from '../../logger/ConsoleLogger';
import ProfileService from "../../services/ProfileServices";
import Loader from "../shared/Loader";
import style from "./Products.module.css";
import {connect, useSelector} from 'react-redux';
import {
    Broadband,
    BroadbandDiscount,
    Colocation,
    ColocationDiscount,
    DarkFiber,
    DarkFiberDiscount,
    DIAPOP,
    DIAPOPDiscount,
    DIAPrem,
    DIAPremDiscount,
    EAccess,
    EAccessDiscount,
    EAccessNNI,
    EAccessNNIDiscount,
    ELine,
    ELineDiscount,
    ManagedSpectrum,
    ManagedSpectrumDiscount,
    MPLSAggNNI,
    MPLSAggNNIDiscount,
    MPLSAggTail,
    MPLSAggTailDiscount,
    MPLSInternet,
    MPLSInternetDiscount,
    PrivateLine,
    PrivateLineDiscount
} from '../../redux';
import StripedDataGrid from "../components/tables/StripedDataGrid";
import CustomTheme from "../components/tables/CustomTheme";
import TablePagination from "../components/tables/TablePagination";

const Products = (props) => {
    const logger = new ConsoleLogger({level: process.env.REACT_APP_LOGGER_LEVEL,});
    const profileFromStore = useSelector(state => state.employeeProfile.profile); // to get selectedProfile
    const productsFromStore = useSelector(state => state.employeeProfile.products);
    const [MSG, setMSG] = useState('');
    const [loader, setLoader] = useState(false);

    const [pageSize, setPageSize] = useState(25);
    const [filterModel, setFilterModel] = useState({
        items: [],
    });

    // Made id = product id to use when updating the DB
    const profileProductData = [
        {
            id: 1,
            product: "Private Line",
            code: "PrivateLine",
            discountCode: "PrivateLineDiscount",
            orderingAvailable: productsFromStore.PrivateLine,
            discountPercent: productsFromStore.PrivateLineDiscount
        },
        {
            id: 6,
            product: "MPLS Agg Tail",
            code: "MPLSAggTail",
            discountCode: "MPLSAggTailDiscount",
            orderingAvailable: productsFromStore.MPLSAggTail,
            discountPercent: productsFromStore.MPLSAggTailDiscount
        },
        {
            id: 13,
            product: "MPLS + Internet",
            code: "MPLSInternet",
            discountCode: "MPLSInternetDiscount",
            orderingAvailable: productsFromStore.MPLSInternet,
            discountPercent: productsFromStore.MPLSInternetDiscount
        },
        {
            id: 7,
            product: "MPLS Agg NNI",
            code: "MPLSAggNNI",
            discountCode: "MPLSAggNNIDiscount",
            orderingAvailable: productsFromStore.MPLSAggNNI,
            discountPercent: productsFromStore.MPLSAggNNIDiscount
        },
        {
            id: 10,
            product: "DIA POP",
            code: "DIAPOP",
            discountCode: "DIAPOPDiscount",
            orderingAvailable: productsFromStore.DIAPOP,
            discountPercent: productsFromStore.DIAPOPDiscount
        },
        {
            id: 11,
            product: "DIA Prem",
            code: "DIAPrem",
            discountCode: "DIAPremDiscount",
            orderingAvailable: productsFromStore.DIAPrem,
            discountPercent: productsFromStore.DIAPremDiscount
        },
        {
            id: 15,
            product: "Colocation",
            code: "Colocation",
            discountCode: "ColocationDiscount",
            orderingAvailable: productsFromStore.Colocation,
            discountPercent: productsFromStore.ColocationDiscount
        },
        {
            id: 19,
            product: "E-Line",
            code: "ELine",
            discountCode: "ELineDiscount",
            orderingAvailable: productsFromStore.ELine,
            discountPercent: productsFromStore.ELineDiscount
        },
        {
            id: 20,
            product: "E-Access",
            code: "EAccess",
            discountCode: "EAccessDiscount",
            orderingAvailable: productsFromStore.EAccess,
            discountPercent: productsFromStore.EAccessDiscount
        },
        {
            id: 22,
            product: "E-Access NNI",
            code: "EAccessNNI",
            discountCode: "EAccessNNIDiscount",
            orderingAvailable: productsFromStore.EAccessNNI,
            discountPercent: productsFromStore.EAccessNNIDiscount
        },
        {
            id: 23,
            product: "Broadband",
            code: "Broadband",
            discountCode: "BroadbandDiscount",
            orderingAvailable: productsFromStore.Broadband,
            discountPercent: productsFromStore.BroadbandDiscount
        },
        {
            id: 32,
            product: "Dark Fiber",
            code: "DarkFiber",
            discountCode: "DarkFiberDiscount",
            orderingAvailable: productsFromStore.DarkFiber,
            discountPercent: productsFromStore.DarkFiberDiscount
        },
        {
            id: 35,
            product: "Managed Spectrum",
            code: "ManagedSpectrum",
            discountCode: "ManagedSpectrumDiscount",
            orderingAvailable: productsFromStore.ManagedSpectrum,
            discountPercent: productsFromStore.ManagedSpectrumDiscount
        }
    ];

    const columns = [
        {
            field: 'product',
            headerName: 'Product',
            renderHeader: (params) => formatSearchHeader(params),
            flex: 0.5,
        },
        {
            field: 'orderingAvailable',
            headerName: 'Ordering Available',
            renderCell: (params) => orderingAvailableFormatter(params),
            renderHeader: (params) => formatSearchHeader(params),
            flex: 0.5,
        },
        {
            field: 'discountPercent',
            headerName: 'Discount Percent',
            renderCell: (params) => formatDiscount(params),
            renderHeader: (params) => formatSearchHeader(params),
            flex: 0.5,
        }
    ];

    function formatSearchHeader(params) {
        return <div>
            <span style={{marginLeft: 8, color: "#753BBD"}}>{params.colDef.headerName}</span>
        </div>;
    }

    function formatDiscount(params) {
        return <div className='my-2'>
            <input type="text" autoFocus="autoFocus" className={`${style.inputFldText} `}
                   value={productsFromStore[params.row.discountCode]}
                   onChange={(e) => props[params.row.discountCode](e.target.value)}/>
        </div>;
    }

    const orderingAvailableFormatter = (params) => {
        const state = params.row.orderingAvailable ? 'on' : '';

        return (
            <div className={`toggle-comp`} onClick={() => toggleAction(params.row)}>
                <div className={`toggle-switch ${state}`}>
                    <div className="ts-bar">
                        <div className="ts-left-edge"></div>
                        <div className="ts-right-edge"></div>
                        <div className="ts-switch"></div>
                    </div>
                </div>
            </div>
        )
    }

    const toggleAction = (row) => {
        setMSG('');
        logger.info('row:' + JSON.stringify(row) + ' row.orderingAvailable: ' + row.orderingAvailable);
        let findIndex = profileProductData.findIndex(p => p.code === row.code);
        logger.info('FindIndex: ' + findIndex);
        props[row.code](!row.orderingAvailable);
    }

    const submitProducts = () => {
        let submitJSON = {
            "selectedProfile": profileFromStore.selectedProfile,
            "products": profileProductData
        }
        //Calling axios service to call profileProductSubmit.php
        logger.info("Product Data: " + JSON.stringify(submitJSON));
        setLoader(true);
        ProfileService.submitProducts(submitJSON).then(resp => {
            logger.info('Product Submit Response: ' + resp.data.result);
            if (resp.data.result === "SUCCESS") {
                setMSG('Success')
            } else {
                setMSG(resp.data.error);
            }
            setLoader(false);
        }).catch(error => {
            logger.error("Update Products ERROR:" + error)
            setMSG('An error has occurred.');
            setLoader(false);
        })
    }

    return (
        <div className="productsPage mt-5">
            {loader ? <Loader/> : null}
            <div className={`sub-hdr alt0`}>
                <div className="nib">
                    <div className="the-notch">
                        <div className="notch-fg">
                            <svg>
                                <path
                                    d="M35.81,26.28l-13,10v-5C-10.47,28.68-1,9.16,11.79,0c0,7.28,0,9.13,0,13.28s3.18,7.92,11,9v-5Z"></path>
                            </svg>
                        </div>
                    </div>
                </div>
                <h2 className={` m-0 `}>Products</h2>
            </div>

            <div>
                <div style={{width: "100%"}}>
                    <StripedDataGrid
                        autoHeight
                        theme={CustomTheme}
                        rows={profileProductData}
                        rowSelection={false}
                        getRowId={(row) => row.id}
                        columns={columns}
                        filterModel={filterModel}
                        onFilterModelChange={(model) => setFilterModel(model)}
                        pageSize={pageSize}
                        rowsPerPageOptions={[]}
                        pageSizeOptions={[]}
                        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                        slots={{
                            pagination: TablePagination,
                        }}
                        initialState={{
                            pagination: {
                                paginationModel: {
                                    pageSize: 5
                                },
                            },
                        }}
                    />
                </div>
            </div>

            {MSG.length ?
                <div>
                    <p className={`text-center my-4 ${MSG === 'Success' ? 'text-success' : 'text-danger'} `}>{MSG}</p>
                </div> : null}
            <div className='text-center mt-5'>
                <button type="button" className={`ico-button lhs light-button ml-3 ${style.nextBtn} `} onClick={() => {
                    submitProducts()
                }}>
                    Update Products
                    <svg className="ico light-ico ico-arrow ml-2">
                        <path d="M0,17H29L15,31l1,1L32,16,16,0,15,1,29,15H0Z"></path>
                    </svg>
                </button>
            </div>
        </div>
    )
}


const mapDispatchToProps = {
    PrivateLine: PrivateLine,
    MPLSAggTail: MPLSAggTail,
    MPLSInternet: MPLSInternet,
    MPLSAggNNI: MPLSAggNNI,
    DIAPOP: DIAPOP,
    DIAPrem: DIAPrem,
    Colocation: Colocation,
    ELine: ELine,
    EAccess: EAccess,
    EAccessNNI: EAccessNNI,
    DarkFiber: DarkFiber,
    ManagedSpectrum: ManagedSpectrum,
    Broadband: Broadband,
    PrivateLineDiscount: PrivateLineDiscount,
    MPLSAggTailDiscount: MPLSAggTailDiscount,
    MPLSInternetDiscount: MPLSInternetDiscount,
    MPLSAggNNIDiscount: MPLSAggNNIDiscount,
    DIAPOPDiscount: DIAPOPDiscount,
    DIAPremDiscount: DIAPremDiscount,
    ColocationDiscount: ColocationDiscount,
    ELineDiscount: ELineDiscount,
    EAccessDiscount: EAccessDiscount,
    EAccessNNIDiscount: EAccessNNIDiscount,
    DarkFiberDiscount: DarkFiberDiscount,
    ManagedSpectrumDiscount: ManagedSpectrumDiscount,
    BroadbandDiscount: BroadbandDiscount

}

export default connect(null, mapDispatchToProps)(Products);