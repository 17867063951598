import {useState} from 'react';
import {ConsoleLogger} from '../../../logger/ConsoleLogger';

const TabsStaff = (props) => {
    const logger = new ConsoleLogger({ level: process.env.REACT_APP_LOGGER_LEVEL, });
    const [linkName, setLinkName] = useState('Account');

    const navClick = (link) => {
        setLinkName(link);
        props.handleNav(link);
    }

    return (
        <div className="tab-nav cf">
            <a href className={`tab-opt ${linkName === 'Account' ? 'active' : ''} `} onClick={() => { navClick('Account') }}>
                <span>Account</span>
            </a>
            <a href className={`tab-opt ${linkName === 'AddUser' ? 'active' : ''} `} onClick={() => { navClick('AddUser') }}>
                <span>Add User</span>
            </a>
            <a href className={`tab-opt ${linkName === 'EditUser' ? 'active' : ''} `} onClick={() => { navClick('EditUser') }}>
                <span>Edit User</span>
            </a>
            
        </div>
    );
}

export default TabsStaff;
