import {
	RESET_ACTION,
	TC_AUTHORIZATION,
	TC_CIRCUIT_ADDRESS,
	TC_CIRCUIT_DETAILS,
    TC_CIRCUIT_LIST_COUNT,
    TC_CIRCUIT_SEARCH,
	TC_CITY_A,
	TC_CITY_Z,
	TC_COMPANY_NAME,
	TC_CONTACT_LOCAL,
	TC_CONTACT_PRIMARY,
	TC_CUSTOMER_PERSONAL_ACCESS,
	TC_CUSTOMER_TICKET_NUMBER,
	TC_DATE_OF_CIRCUIT_DOWN,
	TC_DATE_OF_DURATION_FROM,
	TC_DATE_OF_DURATION_TO,
	TC_DATE_OF_PACKETLOSS,
	TC_DAY_FRI,
	TC_DAY_MON,
	TC_DAY_SAT,
	TC_DAY_SUN,
	TC_DAY_THUR,
	TC_DAY_TUES,
	TC_DAY_WED,
	TC_DESCRIPTION_OF_PROBLEM,
	TC_DISPATCH_AUTHORIZATION,
	TC_ERROR_MSG,
	TC_ERROR_RATE,
	TC_FIBERS_ASSOCIATED,
	TC_INTRUSIVE_FROM,
	TC_INTRUSIVE_FROM_HRS,
	TC_INTRUSIVE_TESTING_AUTHORIZED,
	TC_INTRUSIVE_TO,
	TC_INTRUSIVE_TO_HRS,
    TC_INTRUSIVE_TZ,
	TC_IRU_NUMBER,
	TC_L_CONTACT,
	TC_L_CONTACT_EMAIL,
	TC_L_CONTACT_ID,
	TC_L_CONTACT_PHONE,
	TC_L_CONTACT_PHONE_EXT,
	TC_L_PHONE_TYPE,
	TC_L_UPDATE_EMAIL,
	TC_LOC_A,
	TC_LOC_Z,
	TC_MON_TO_FRI,
	TC_NUMBER_OF_FIBERS,
	TC_NUMBER_OF_SITES,
	TC_OPTIONS,
	TC_OTDR_RESULTS,
    TC_CONTACT_LIST,
	TC_P_CONTACT,
	TC_P_CONTACT_EMAIL,
	TC_P_CONTACT_ID,
	TC_P_CONTACT_PHONE,
	TC_P_CONTACT_PHONE_EXT,
	TC_P_PHONE_TYPE,
	TC_P_UPDATE_EMAIL,
	TC_PERCENTAGE_LOSS,
	TC_POWER_VERIFIED,
	TC_PRODUCTS,
	TC_PULL_PERFORMANCE_MONITORING,
	TC_SELECT_DATE,
	TC_SITES_OUT,
	TC_STATE_A,
	TC_STATE_Z,
	TC_STEP_CODE,
	TC_TIME_FROM,
	TC_TIME_OF_BOUNCE,
	TC_TIME_OF_BOUNCEHRS,
    TC_BOUNCE_TZ,
	TC_TIME_OF_CIRCUIT_DOWNHRS,
    TC_CIRCUIT_DOWN_TZ,
	TC_TIME_OF_DURATION_FROMHRS,
	TC_TIME_OF_DURATION_TOHRS,
	TC_TIME_OF_PACKETLOSSHRS,
	TC_TIME_TO,
	TC_TROUBLE_DETAILS,
	TC_TURN_UP,
	TC_TYPE_OF_ERRORS,
	TC_ZIP_A,
	TC_ZIP_Z
} from "./types/actionTypes";

export const tcStepCode = (payload) => {
    return {
        type: TC_STEP_CODE,
        payload: payload
    }
}

export const tcProducts = (payload) => {
    return {
        type: TC_PRODUCTS,
        payload: payload
    }
}

export const tcOptions = (payload) => {
    return {
        type: TC_OPTIONS,
        payload: payload
    }
}

export const tcCircuitListCount = (payload) => {
    return {
        type: TC_CIRCUIT_LIST_COUNT,
        payload: payload
    }
}

export const tcCircuitSearch = (payload) => {
    return {
        type: TC_CIRCUIT_SEARCH,
        payload: payload
    }
}

export const tcCircuit = (payload) => {
    return {
        type: TC_CIRCUIT_DETAILS,
        payload: payload
    }
}

export const tcCircuitAddress = (payload) => {
    return {
        type : TC_CIRCUIT_ADDRESS,
        payload: payload
    }
}

export const tcTroubleDetails = (payload) => {
    return {
        type: TC_TROUBLE_DETAILS,
        payload: payload
    }
}

export const tcLocalDetails = (payload) => {
    return {
        type: TC_CONTACT_LOCAL,
        payload: payload
    }
}

export const tcPrimaryDetails = (payload) => {
    return {
        type: TC_CONTACT_PRIMARY,
        payload: payload
    }
}

export const tcAuthorization = (payload) => {
    return {
        type: TC_AUTHORIZATION,
        payload: payload
    }
}

export const tcCustomerTicketNumber = (payload) => {
    return {
        type: TC_CUSTOMER_TICKET_NUMBER,
        payload: payload
    }
}

export const tcPowerVerified = (payload) => {
    return {
        type: TC_POWER_VERIFIED,
        payload: payload
    }
}

export const tcDescriptionOfProblem = (payload) => {
    return {
        type: TC_DESCRIPTION_OF_PROBLEM,
        payload: payload
    }
}

export const tcCompanyName = (payload) => {
    return {
        type: TC_COMPANY_NAME,
        payload: payload
    }
}

export const tcTimeOfBounce = (payload) => {
    return {
        type: TC_TIME_OF_BOUNCE,
        payload: payload
    }
}

export const tcBounceTz = (payload) => {
    return {
        type: TC_BOUNCE_TZ,
        payload: payload
    }
}

export const tcDispatchAuthorization = (payload) => {
    return {
        type: TC_DISPATCH_AUTHORIZATION,
        payload: payload
    }
}

export const tcIntrusiveTestingAuthorized = (payload) => {
    return {
        type: TC_INTRUSIVE_TESTING_AUTHORIZED,
        payload: payload
    }
}

export const tcTurnUp = (payload) => {
    return {
        type: TC_TURN_UP,
        payload: payload
    }
}

export const tcTimeOfBounceHrs = (payload) => {
    return {
        type: TC_TIME_OF_BOUNCEHRS,
        payload: payload
    }
}

export const tcTypeOfErrors = (payload) => {
    return {
        type: TC_TYPE_OF_ERRORS,
        payload: payload
    }
}

export const tcErrorRate = (payload) => {
    return {
        type: TC_ERROR_RATE,
        payload: payload
    }
}

export const tcDateOfcircuitDown = (payload) => {
    return {
        type: TC_DATE_OF_CIRCUIT_DOWN,
        payload: payload
    }
}

export const tcTimeOfcircuitDownHrs = (payload) => {
    return {
        type: TC_TIME_OF_CIRCUIT_DOWNHRS,
        payload: payload
    }
}

export const tcCircuitDownTz = (payload) => {
    return {
        type: TC_CIRCUIT_DOWN_TZ,
        payload: payload
    }
}

export const tcDateOfdurationFrom = (payload) => {
    return {
        type: TC_DATE_OF_DURATION_FROM,
        payload: payload
    }
}

export const tcIntrusiveFrom = (payload) => {
    return {
        type: TC_INTRUSIVE_FROM,
        payload: payload
    }
}

export const tcIntrusiveFromHrs = (payload) => {
    return {
        type: TC_INTRUSIVE_FROM_HRS,
        payload: payload
    }
}

export const tcIntrusiveTo = (payload) => {
    return {
        type: TC_INTRUSIVE_TO,
        payload: payload
    }
}

export const tcIntrusiveToHrs = (payload) => {
    return {
        type: TC_INTRUSIVE_TO_HRS,
        payload: payload
    }
}

export const tcIntrusiveTz = (payload) => {
    return {
        type: TC_INTRUSIVE_TZ,
        payload: payload
    }
}

export const tcTimeOfdurationFromHrs = (payload) => {
    return {
        type: TC_TIME_OF_DURATION_FROMHRS,
        payload: payload
    }
}

export const tcDateOfdurationTo = (payload) => {
    return {
        type: TC_DATE_OF_DURATION_TO,
        payload: payload
    }
}

export const tcTimeOfdurationToHrs = (payload) => {
    return {
        type: TC_TIME_OF_DURATION_TOHRS,
        payload: payload
    }
}

export const tcPullPerformanceMonitoring = (payload) => {
    return {
        type: TC_PULL_PERFORMANCE_MONITORING,
        payload: payload
    }
}

export const tcNumberOfSites = (payload) => {
    return {
        type: TC_NUMBER_OF_SITES,
        payload: payload
    }
}

export const tcCustomerPersonalAccess = (payload) => {
    return {
        type: TC_CUSTOMER_PERSONAL_ACCESS,
        payload: payload
    }
}

export const tcDateOfpacketLoss = (payload) => {
    return {
        type: TC_DATE_OF_PACKETLOSS,
        payload: payload
    }
}

export const tcTimeOfpacketLossHrs = (payload) => {
    return {
        type: TC_TIME_OF_PACKETLOSSHRS,
        payload: payload
    }
}

export const tcPercentageLoss = (payload) => {
    return {
        type: TC_PERCENTAGE_LOSS,
        payload: payload
    }
}

export const tcSitesOut = (payload) => {
    return {
        type: TC_SITES_OUT,
        payload: payload
    }
}

export const tcLocA = (payload) => {
    return {
        type: TC_LOC_A,
        payload: payload
    }
}

export const tcCityA = (payload) => {
    return {
        type: TC_CITY_A,
        payload: payload
    }
}

export const tcStateA = (payload) => {
    return {
        type: TC_STATE_A,
        payload: payload
    }
}

export const tcZipA = (payload) => {
    return {
        type: TC_ZIP_A,
        payload: payload
    }
}

export const tcLocZ = (payload) => {
    return {
        type: TC_LOC_Z,
        payload: payload
    }
}

export const tcCityZ = (payload) => {
    return {
        type: TC_CITY_Z,
        payload: payload
    }
}

export const tcStateZ = (payload) => {
    return {
        type: TC_STATE_Z,
        payload: payload
    }
}

export const tcZipZ = (payload) => {
    return {
        type: TC_ZIP_Z,
        payload: payload
    }
}

export const tcIruNumber = (payload) => {
    return {
        type: TC_IRU_NUMBER,
        payload: payload
    }
}

export const tcOtdrResults = (payload) => {
    return {
        type: TC_OTDR_RESULTS,
        payload: payload
    }
}


export const tcNumberOfFibers = (payload) => {
    return {
        type: TC_NUMBER_OF_FIBERS,
        payload: payload
    }
}

export const tcFibersAssociated = (payload) => {
    return {
        type: TC_FIBERS_ASSOCIATED,
        payload: payload
    }
}

export const tcContactList = (payload) => {
    return {
        type: TC_CONTACT_LIST,
        payload: payload
    }
}

export const tcPContact = (payload) => {
    return {
        type: TC_P_CONTACT,
        payload: payload
    }
}

export const tcPContactId = (payload) => {
    return {
        type: TC_P_CONTACT_ID,
        payload: payload
    }
}

export const tcPContactPhone = (payload) => {
    return {
        type: TC_P_CONTACT_PHONE,
        payload: payload
    }
}

export const tcPContactPhoneExt = (payload) => {
    return {
        type: TC_P_CONTACT_PHONE_EXT,
        payload: payload
    }
}

export const tcPPhoneType = (payload) => {
    return {
        type: TC_P_PHONE_TYPE,
        payload: payload
    }
}

export const tcPUpdatedEmail = (payload) => {
    return {
        type: TC_P_UPDATE_EMAIL,
        payload: payload
    }
}

export const tcPContactEmail = (payload) => {
    return {
        type: TC_P_CONTACT_EMAIL,
        payload: payload
    }
}

export const tcLContact = (payload) => {
    return {
        type: TC_L_CONTACT,
        payload: payload
    }
}

export const tcLContactId = (payload) => {
    return {
        type: TC_L_CONTACT_ID,
        payload: payload
    }
}

export const tcLContactPhone = (payload) => {
    return {
        type: TC_L_CONTACT_PHONE,
        payload: payload
    }
}

export const tcLContactPhoneExt = (payload) => {
    return {
        type: TC_L_CONTACT_PHONE_EXT,
        payload: payload
    }
}

export const tcLPhoneType = (payload) => {
    return {
        type: TC_L_PHONE_TYPE,
        payload: payload
    }
}

export const tcLUpdatedEmail = (payload) => {
    return {
        type: TC_L_UPDATE_EMAIL,
        payload: payload
    }
}

export const tcLContactEmail = (payload) => {
    return {
        type: TC_L_CONTACT_EMAIL,
        payload: payload
    }
}

export const tcSelectDate = (payload) => {
    return {
        type: TC_SELECT_DATE,
        payload: payload
    }
}

export const tcTimeFrom = (payload, day) => {
    return {
        type: TC_TIME_FROM,
        index: day,
        payload: payload
    }
}

export const tcTimeTo = (payload, day) => {
    return {
        type: TC_TIME_TO,
        index: day,
        payload: payload
    }
}

export const tcMonToFri = (payload) => {
    return {
        type: TC_MON_TO_FRI,
        payload: payload
    }
}

export const tcDayMon = (payload) => {
    return {
        type: TC_DAY_MON,
        payload: payload
    }
}

export const tcDayTues = (payload) => {
    return {
        type: TC_DAY_TUES,
        payload: payload
    }
}

export const tcDayWed = (payload) => {
    return {
        type: TC_DAY_WED,
        payload: payload
    }
}

export const tcDayThur = (payload) => {
    return {
        type: TC_DAY_THUR,
        payload: payload
    }
}

export const tcDayFri = (payload) => {
    return {
        type: TC_DAY_FRI,
        payload: payload
    }
}

export const tcDaySat = (payload) => {
    return {
        type: TC_DAY_SAT,
        payload: payload
    }
}

export const tcDaySun = (payload) => {
    return {
        type: TC_DAY_SUN,
        payload: payload
    }
}

export const tcErrorMsg = (payload) => {
    return {
        type: TC_ERROR_MSG,
        payload: payload
    }
}

export const tcReset = (payload) => {
    return {
        type: RESET_ACTION,
        payload: payload
    }
}
