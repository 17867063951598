const FileDownloadLinkGenerator = (function () {

    const onButtonClick = (fileName, path) => {
        // using Java Script method to get PDF file
        fetch(path + fileName).then(response => {
            response.blob().then(blob => {
                // Creating new object of PDF file
                const fileURL = window.URL.createObjectURL(blob);
                // Setting various property values
                let alink = document.createElement('a');
                alink.href = fileURL;
                alink.download = fileName;
                alink.click();
            })
        })
    }

    return {
        onButtonClick: onButtonClick
    }
}());

export default FileDownloadLinkGenerator;