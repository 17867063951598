// React Core Libraries
import {combineReducers} from 'redux'

// Local JS Files Imports
import userReducer from './userReducer'
import errorHandlerReducer from './errorHandlerReducer'
import {orderSummaryReducer} from './orderSummaryReducers';
import {alertWindowHomePageReducer} from './alertWindowHomePageReducer';
import mplsNNIReducer from './mplsNNIReducer';
import quoteContactReducer from './quoteContactReducer';
import quoteLocAReducer from './quoteLocAReducer';
import quoteLocZReducer from './quoteLocZReducer'
import quoteReducer from './quoteReducer';
import orderReducer from './orderReducer';
import {ticketCreateReducer} from './ticketCreateReducer';
import orderCreateReducer from './orderCreateReducer';
import MACDReducer from './MACDReducer';
import employeeProfileReducer from './employeeProfileReducer';
import internalReducer from './internalReducer';
import quoteViewReducer from "./quoteViewReducer";
import orderViewReducer from "./orderViewReducer";
import quickbaseTaskReducer from "./quickbaseTaskReducer";
import locationReducer from "./locationReducer";
import quoteProfileReducer from "./quoteProfileReducer";
import storage from "redux-persist-indexeddb-storage";
import {CLEAR_REDUX_STORE} from "../actions/types/actionTypes";
import networkIntelligenceReducer from './networkIntelligenceReducer';


const appReducer = combineReducers({
    userReducer: userReducer,
    errorReducer: errorHandlerReducer,
    networkIntelligence: networkIntelligenceReducer,
    orderSummaryReducer: orderSummaryReducer,
    isShowAlertHome: alertWindowHomePageReducer,
    mplsQuote: mplsNNIReducer,
    quoteContact: quoteContactReducer,
    quoteLocA: quoteLocAReducer,
    quoteLocZ: quoteLocZReducer,
    locationDatas: locationReducer,
    quote: quoteReducer,
    order: orderReducer,
    ticketCreate: ticketCreateReducer,
    orderCreate: orderCreateReducer,
    custQuoteProfile: quoteProfileReducer,
    quoteView: quoteViewReducer,
    orderView: orderViewReducer,
    quickbaseTasks: quickbaseTaskReducer,
    MACDOrder: MACDReducer,
    employeeProfile: employeeProfileReducer,
    internal: internalReducer
})

const rootReducer = (state, action) => {
    if(action.type === CLEAR_REDUX_STORE){
        storage("storeDB").removeItem('persist:root');
        return appReducer(undefined, action);
    }
    return appReducer(state, action)
}

export default rootReducer
