// React Core Libraries
import React from "react";
import {useParams, useLocation} from 'react-router-dom';

// Local JS Files Imports
import ViewportWarning from "../shared/ViewportWarning";
import ContactsSection from './ContactsSection';


const EditContacts = () => {
    const location = useLocation();
    const { custId, gaid } = useParams();
    const contactDetails = location.state.contactDetails;
    const contactRoles = location.state.contactRoles;
    return (
            <div className="midsection qm-upload abs z1">
                <div className="rel">
                    <ViewportWarning />
                    <div className="wrap">
                        <h1 className="page-title">Edit Contacts</h1>
					    <ContactsSection action={"Update"} custId={custId} gaid={gaid} contactDetails={contactDetails} roles={contactRoles}></ContactsSection>
                    </div>
                </div>
            </div>
    );
}

export default EditContacts;
