import style from "./QuoteCreate.module.css";
import QuoteHelper from "../../services/QuoteService";
import { useEffect, useState, useMemo } from "react";
import { connect, useSelector } from "react-redux";
import {
	setNewQuoteContactName,
	setQuoteBusinessName,
	setQuoteContactName,
	setQuoteCusRefID,
	setQuoteEmail,
	setQuoteICBComments,
	setQuoteManualICB,
	setQuoteMRCPrice,
	setQuotePhone
} from '../../redux/actions/quoteContactActions';
import Loader from "../shared/Loader";
import { ConsoleLogger } from "../../logger/ConsoleLogger";
import HTTPErrorHandler from "../errorPages/HTTPErrorHandler";
import QuoteInternal from "./QuoteInternal";

const QuoteContacts = (props) => {
	const logger = useMemo(() => new ConsoleLogger({ level: process.env.REACT_APP_LOGGER_LEVEL, }), []);
	const [hasError, setHasError] = useState(null);
	const [quoteContactOptions, setQuoteContactOptions] = useState([]);
	const [newContactFlag, setNewContactFlag] = useState(false);
	const [popupShow, setPopupShow] = useState(false);
	const [popupMsg, setPopupMsg] = useState('');
	const [loader, setLoader] = useState(false);
	const selectedQuoteValues = useSelector(state => state.quote.quoteValues);
	const selectedQuoteContactValues = useSelector(state => state.quoteContact.contactValues);
	const selectedQuoteRouteDetails = useSelector(state => state.quote.quoteValues.routeDetails);
	const iconFlag = useSelector(state => state.quote.quoteValues.iconFlag); //Flag to tell if the quote is process started from the wave availability tool and if the locations are on the icon network
	const isEmployee = localStorage.getItem('isEmployeeLoggedIn');

	useEffect(() => {
		//Populates Contact Dropdown
		setLoader(true);
		QuoteHelper.getQuoteContact().then(resp => {
			logger.info(resp.data);
			setQuoteContactOptions(resp.data);
			setLoader(false);
		}).catch(error => {
			logger.error("Error Getting Contacts: " + error);
			setHasError(error);
			setLoader(false);
		})
	}, [logger]);

	//Adds selected contact to redux store
	const contactHandler = (ev) => {
		let contact = ev.target.value;
		if (contact === 'new') {
			setNewContactFlag(true);
		} else {
			autoFillContact(contact);
		}
		logger.info(ev.target.value);
		props.updateQuoteContactName(contact);
	}

	//Autofills contact info and adds it to the redux store
	const autoFillContact = (value) => {
		setLoader(true);
		QuoteHelper.getQuoteContactInfo(value).then(resp => {
			logger.info(resp.data);
			props.updateQuotePhone(resp.data.phone);
			props.updateQuoteEmail(resp.data.email);
			setLoader(false);
		}).catch(error => {
			logger.error("Error Autofilling contact: " + error);
			setHasError(error);
		})
	}

	//Handler to create a new contact and add it to the redux store
	const newContactHandler = (ev) => {
		props.updateQuoteNewContactName(ev.target.value);
	}

	//Handler for ICB checkbox
	const splReqICB = (ev) => {
		props.updateQuoteManualICB(ev.target.checked);
		if (ev.target.checked === true) {
			setPopupMsg("Processing of special request (i.e Diversity, Protection) will require ICB. Click OK to accept and enter your special request, or Cancel to proceed without including special customer requirements");
			setPopupShow(true);
		}
	}

	const popupOk = () => {
		setPopupShow(false);
		setPopupMsg('');
	}
	const popupCancel = () => {
		props.updateQuoteManualICB(false);
		setPopupShow(false);
		setPopupMsg('');
	}

	//Handles the remainder of the inputs on the contact pages and adds them to the redux store
	const inputHandler = (ev) => {
		logger.info(ev.target.id);
		logger.info(ev.target.value);
		switch (ev.target.id) {
			case "busName":
				props.updateQuoteBusinessName(ev.target.value);
				break;
			case "refId":
				props.updateQuoteCusRefID(ev.target.value);
				break;
			case "mrcPrice":
				props.updateQuoteMRCPrice(ev.target.value);
				break;
			case "updatePhone":
				props.updateQuotePhone(ev.target.value);
				break;
			case "updateEmail":
				props.updateQuoteEmail(ev.target.value);
				break;
			case "icb_comments":
				props.updateQuoteICBComments(ev.target.value);
				break;
			default:
				break;

		}
	}

	return (
		<>
			{hasError && <HTTPErrorHandler error={hasError}></HTTPErrorHandler>}
			{loader ? <Loader /> : null}
			{!hasError && (<><div className={`row`}>
				<div className="col-12">
					<p className={` ${style.lableTextS} ${style.textBlack} `}><span className={style.mandatory}>*</span> Denotes a required field</p>
				</div>
			</div>
				<div className={`sub-hdr alt ${style.subHeader} `}>
					<div className="nib"><div className="the-notch"><div className="notch-fg"><svg><path d="M35.81,26.28l-13,10v-5C-10.47,28.68-1,9.16,11.79,0c0,7.28,0,9.13,0,13.28s3.18,7.92,11,9v-5Z"></path></svg></div></div></div><h2 className={style.sectionTitle}>Contact</h2>
				</div>

				<div className={`row ${style.rowGrp} `}>
					<div className="col-12 col-sm-4 col-md-3">
						<label className={style.labelSelectRequiredFld}> Name <span className={style.mandatory}>*</span></label>
						{newContactFlag ?
							<input type="text" id="name" className={style.inputFld} onChange={newContactHandler} value={selectedQuoteContactValues.newName}></input>
							:
							<select className={` form-control  ${style.selectFld} ${style.requiredFld} `} onChange={contactHandler} value={selectedQuoteContactValues.name}>
								{quoteContactOptions.map((obj) => {
									return <option value={obj.id}>{obj.name}</option>
								})}
							</select>}

					</div>
					<div className="col-12 col-sm-4 col-md-3">
						<label className={style.lableTextS}>Phone <span className={style.mandatory} >*</span></label>
						<input type="text" className={style.inputFld} id="updatePhone" maxLength="10" onChange={inputHandler} value={selectedQuoteContactValues.phone}></input>
					</div>
					<div className="col-12 col-sm-4 col-md-3">
						<label className={style.lableTextS}>Email <span className={style.mandatory} >*</span></label>
						<input type="text" className={style.inputFld} id="updateEmail" onChange={inputHandler} value={selectedQuoteContactValues.email}></input>
					</div>
				</div>
				<div className={style.dividers}></div>

				<div className={` sub-hdr alt1 ${style.subHeader} `}>
					<div className="nib"><div className="the-notch"><div className="notch-fg"><svg><path d="M35.81,26.28l-13,10v-5C-10.47,28.68-1,9.16,11.79,0c0,7.28,0,9.13,0,13.28s3.18,7.92,11,9v-5Z"></path></svg></div></div></div><h2 className={style.sectionTitle}>Additional Details</h2>
				</div>

				<div className={`row ${style.rowGrp} `}>
					<div className="col-12 col-sm-4 col-md-4">
						<label className={style.lableTextS}>Quote Name
							<span className={style.mandatory} > *</span>
							<span className={`ml-2 ${style.infoTip}  `}>
								<img src="../../images/svg/ico-info-filled-dark-off.svg" alt="info tip" className={style.infoImg} />
								<p className={style.contentHover}>
									Quote Name will be displayed in billing and portal

								</p>
							</span>
						</label>
						<input type="text" className={style.inputFld} id="busName" maxLength="48" onChange={inputHandler} value={selectedQuoteContactValues.busName}></input>
					</div>
				</div>

				{selectedQuoteValues.product_code === "COLOCATION" ?
					<div className={`row ${style.rowGrp} `}>
						<div className="col-12 col-sm-4 col-md-4">
							<label className={style.lableTextS}>Customer Quote Reference ID </label>
							<input type="text" className={style.inputFld} id="refId" maxLength="12" onChange={inputHandler} value={selectedQuoteContactValues.assocRefId}></input>
						</div>
					</div>
					: null}
				<div className={`row ${style.rowGrp} `}>
					<div className="col-12 col-sm-4 col-md-4">

						<label className={style.lableTextS}>Customer Quote Reference ID </label>
						<input type="text" className={style.inputFld} id="refId" maxLength="12" onChange={inputHandler} value={selectedQuoteContactValues.refId}></input>

					</div>
				</div>

				<div className={`row ${style.rowGrp} `}>
					<div className="col-12 col-sm-4 col-md-4">
						<label className={style.lableTextS}>
							Target MRC Price
							{(Number(selectedQuoteValues.bw) >= 600 || Number(selectedQuoteValues.bw1) >= 600 || Number(selectedQuoteValues.bw2) >= 600) && isEmployee && selectedQuoteValues.iconFlag === "" && selectedQuoteValues.product_code === "WAVE" ?
								<span className={style.mandatory}> *</span> : null
							}
						</label>
						<input type="text" className={style.inputFld} id="mrcPrice" onChange={inputHandler} value={selectedQuoteContactValues.mrcPrice}></input>
					</div>
				</div>

				{/*Fields only used on the internal side*/}
				{isEmployee && selectedQuoteValues.product_code !== "BROADBAND" ?
					<QuoteInternal section={"additional_details"} /> : null
				}

				{selectedQuoteValues.product_code !== "BROADBAND" ?
					<div className={`row ${style.rowGrp} `}>
						<div className="col-6">
							<input type="checkbox" id="splReq" name="splReq" checked={selectedQuoteContactValues.manual_icb ? "checked" : ""} onChange={splReqICB} />
							<label htmlFor="splReq" className={` ${style.checkboxText} ${style.textTransformNone} mb-2`}>Special Requirements or ICB Quote Request</label>
						</div>
					</div> : null
				}

				<div className={`row ${style.rowGrp} d-flex`}>
					{selectedQuoteContactValues.manual_icb ?
						<div className="col-6">
							<textarea className={style.textareaFld} id="icb_comments" maxLength="255" onChange={inputHandler} value={selectedQuoteContactValues.icb_comments}></textarea>
						</div> : null
					}
					{/*This section will only show up for quote created from the wave availability tool and the two locations choosen are on the icon network*/}
					{iconFlag === "ICON" ?
						<div className="col-6">
							<textarea className={style.textareaFld} id="routeDetailComments" disabled value={selectedQuoteRouteDetails}></textarea>
						</div> : null
					}
				</div>
				{popupShow ?
					<div>
						<div className={`${style.overly}`}> </div>
						<div className="modal fade show d-block">
							<div className={` modal-dialog modal-dialog-centered modal-md`}>
								<div className="modal-content">
									<div className="modal-body">
										{popupMsg}
									</div>
									<div className="modal-footer d-block text-center">
										<button type="button" className="btn-style btn-theme" data-dismiss="modal" onClick={popupOk}>Ok</button>
										<button type="button" className="btn-style btn-cancel" data-dismiss="modal" onClick={popupCancel}>Cancel</button>
									</div>

								</div>
							</div>
						</div>
					</div>
					: null
				}</>)}
		</>
	)
}

const mapDispatchToProps = {
	updateQuoteContactName: setQuoteContactName,
	updateQuoteNewContactName: setNewQuoteContactName,
	updateQuotePhone: setQuotePhone,
	updateQuoteEmail: setQuoteEmail,
	updateQuoteBusinessName: setQuoteBusinessName,
	updateQuoteCusRefID: setQuoteCusRefID,
	updateQuoteMRCPrice: setQuoteMRCPrice,
	updateQuoteManualICB: setQuoteManualICB,
	updateQuoteICBComments: setQuoteICBComments
}

export default connect(null, mapDispatchToProps)(QuoteContacts);
