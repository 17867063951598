import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx'

const XLSXExport = (function () {

	const exportToExcel = (headers, csvData, fileName, columnRange, fileTypeExtension) => {
		const fileType = fileTypeExtension?.length > 0 ? '.csv;charset=UTF-8' : 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
		const fileExtension = fileTypeExtension?.length > 0 ? '.csv' : '.xlsx';
		const ws = XLSX.utils.book_new();
		const bookTypeExtension = fileTypeExtension?.length > 0 ? 'csv' : 'xlsx';
		/*Adding custom headers to spreadsheet*/
		if (headers?.length > 0) {
			XLSX.utils.sheet_add_aoa(ws, [headers]);
			XLSX.utils.sheet_add_json(ws, csvData, { origin: 'A2', skipHeader: true });
		} else {
			XLSX.utils.sheet_add_json(ws, csvData);
		}
		/*Setting a specific column range if one is passed in to exclude certain data from the excel spreadsheet*/
		if (columnRange?.length > 0) {
			const range = XLSX.utils.decode_range(ws["!ref"]);
			const rows = range.e.r;
			ws["!ref"] = `A1:${columnRange}${rows}`;
		}

		const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
		const excelBuffer = XLSX.write(wb, { bookType: bookTypeExtension, type: 'array', cellStyles: true });
		const data = new Blob([excelBuffer], { type: fileType });
		FileSaver.saveAs(data, fileName + fileExtension);
	}

	const createExcelBlob = (headers, csvData) => {
		const ws = XLSX.utils.book_new();
		XLSX.utils.sheet_add_aoa(ws, [headers]);
		XLSX.utils.sheet_add_json(ws, csvData, { origin: 'A2', skipHeader: true });
		const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };

		// Determine the index of "UQUAL ID" in headers
		const uqualIdIndex = headers.findIndex(header => header === "UQUAL ID");
		if (uqualIdIndex !== -1) {
			// Convert column index to Excel column letter (e.g., 0 -> A, 1 -> B, ...)
			const uqualIdColumn = String.fromCharCode(65 + uqualIdIndex);

			// Loop through each row in csvData
			for (let i = 0; i < csvData.length; i++) {
				// Excel rows start at 1, and we added headers in the first row and data starts from the second row (A2)
				const cellRef = `${uqualIdColumn}${i + 2}`;
				const cell = ws[cellRef];
				if (cell) {
					// Explicitly set the cell type to string
					cell.t = 's';
				}
			}
		}

		const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array', cellStyles: true });
		const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
		return blob;
	}

	return {
		exportToExcel: exportToExcel,
		createExcelBlob: createExcelBlob
	}

}());

export default XLSXExport;
