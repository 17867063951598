import axios from "./axios/customAxios";

const localURL = "iconnect-local.windstreamwholesale.com";
const devURL = "iconnect-dev.windstreamwholesale.com";

const getRenewalCircuitsByMpid = async () => {
	const renewalCircuitsURL = `${process.env.REACT_APP_API_URL}/internal/getRenewalCircuitIds.php`;
	return await axios.get(renewalCircuitsURL)
}

const getRenewalCircuitsByGaid = async (gaid) => {
	const renewalCircuitsGaidURL = `${process.env.REACT_APP_API_URL}/internal/getRenewalCircuitIds.php?gaid=${gaid}`;
	return await axios.get(renewalCircuitsGaidURL)
}

const pullCircuitInfo = async (circuits) => {
	let pullCircuitInfoURL = "";
	if(window.location.hostname === localURL || window.location.hostname === devURL){
		pullCircuitInfoURL = `${process.env.REACT_APP_API_URL}/internal/validateRenewalCircuit.php?circuits=${JSON.stringify(circuits)}`;
		return await axios.get(pullCircuitInfoURL);
	} else {
		pullCircuitInfoURL = `${process.env.REACT_APP_API_URL}/internal/validateRenewalCircuit.php`;
		return await axios.post(pullCircuitInfoURL, JSON.stringify(circuits));
	}
}

const qbCircuitInfo = async (circuits) => {
	let qbCircuitInfoURL = "";
	if(window.location.hostname === localURL || window.location.hostname === devURL){
		qbCircuitInfoURL = `${process.env.REACT_APP_API_URL}/internal/getQuickbaseCircuit.php?circuits=${JSON.stringify(circuits)}`;
		return await axios.get(qbCircuitInfoURL);
	} else {
		qbCircuitInfoURL = `${process.env.REACT_APP_API_URL}/internal/getQuickbaseCircuit.php`;
		return await axios.post(qbCircuitInfoURL, JSON.stringify(circuits));
	}
}

const getRenewalCircuitMenu = async (product) => {
	const circuitMenuURL = `${process.env.REACT_APP_API_URL}/getBandwidths.php?product=${product}&menu=renewalCircuitMenu&macd=1`;
    return await axios.get(circuitMenuURL);
}

const getBandwidths = async (product, circuit) =>{
	const bandwidthURL = `${process.env.REACT_APP_API_URL}/getBandwidths.php?product=${product}&menu=bw&circuit=${circuit}&macd=1`;
    return await axios.get(bandwidthURL);
}

const submitRenewalQuote = async (values) => {
	const renewalQuoteURL = `${process.env.REACT_APP_API_URL}/internal/renewalQuoteSubmit.php?data=${encodeURIComponent(JSON.stringify(values))}`;
	return await axios.get(renewalQuoteURL);
}

const submitRenewalOrder = async (values) => {
	const renewalOrderURL = `${process.env.REACT_APP_API_URL}/internal/renewalOrderSubmit.php?data=${encodeURIComponent(JSON.stringify(values))}`;
	return await axios.get(renewalOrderURL);
}

const RenewalHelper = {
    getRenewalCircuitsByMpid,
	getRenewalCircuitsByGaid,
	pullCircuitInfo,
	qbCircuitInfo,
	getRenewalCircuitMenu,
	getBandwidths,
	submitRenewalQuote,
	submitRenewalOrder
}

export default RenewalHelper;
