import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';

const columns = [{
    dataField: 'type',
    text: 'Impact Type',
    sort: true,
    style: {
    },
    classes: 'w12'
}, {
    dataField: 'duration',
    text: 'Duration',
    classes: 'w12'
}, {
    dataField: 'start_time',
    text: 'Impact Start'
},
{
    dataField: 'end_time',
    text: 'Impact End'

}];


const ImpactDuration = ({impacts}) => {
    const data = impacts;
    return (
        <header>
            <h6><b>Impacts</b></h6>
            <div className="table-style-wrap pagination-table border border-5 border-dark">
                { data.length > 0 ?
                    <BootstrapTable 
                        keyField='id'
                        data={data}
                        columns={columns}
                        striped
                        hover
                        condensed
                        bordered
                    />
                    : null
                }
            
            </div>
        </header>
    )
}

export default ImpactDuration;
