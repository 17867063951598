// 3rd Party Libraries CSS
// Bootstrap CSS Import
// React Bootstrap Table 2 CSS Imports
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
// React Tippy CSS Import
import 'react-tippy/dist/tippy.css';
// DevExtreme CSS Imports
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.greenmist.css';

// 3rd Party Libraries JS
import 'bootstrap/dist/js/bootstrap.min';
import 'bootstrap/dist/js/bootstrap.bundle';
import { connect } from 'react-redux';
import { ErrorBoundary } from 'react-error-boundary'

// All Internal CSS Imports
/* eslint-disable jsx-a11y/anchor-is-valid */
import './css/font.css';
import './css/helpers.css';
import './css/nice-select.css';
import './css/normalize.css';
import './css/overlayscrollbars.min.css';
import './css/simplebar.css';
import './css/style.css';

// All Components Imports
import Home from "./views/home/Home";
import Navigation from "./views/shared/Navigation";
import Supertip from "./views/shared/Supertip";
import Goodies from "./views/home/Goodies";
import QuoteSummary from './views/quote/QuoteSummary';
import QuoteCreate from './views/quote/QuoteCreate';
import OrderSummary from './views/order/OrderSummary';
import OrderCreate from './views/order/OrderCreate';
import OrderView from './views/order/OrderView';
import BulkQuoteCreate from './views/quote/BulkQuoteCreate';
import QuoteView from './views/quote/QuoteView';
import React, { useEffect, useState } from 'react';
import TroubleTickets from './views/network/TroubleTickets';
import TroubleTicketsSummary from './views/network/TroubleTicketsSummary';
import CircuitDetails from './views/history/CircuitDetails';
import LightLevels from './views/history/LightLevels';
import TroubleTicketsHistory from './views/history/TroubleTicketsHistory';
import Maintenance from './views/history/Maintenance';
import CircuitHealth from './views/network/CircuitHealth';
import MaintenanceSchedule from './views/network/MaintenanceSchedule';
import AccountSummary from './views/billing/AccountSummary';
import Invoices from './views/billing/Invoices';
import PaymentHistory from './views/billing/PaymentHistory';
import OneTimePayment from './views/billing/OneTimePayment';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import NetworkSummary from './views/network/Network';
import FiberMapping from './views/network/FiberMapping';
import Login from './views/login/Login';
import ProtectedRoute from "./ProtectedRoute";
import EmployeeRoute from "./EmployeeRoute";
import SupportDocument from './views/support/SupportDocument';
import SupportReport from './views/support/Report';
import TicketCreate from './views/ticket/TicketCreate';
import AddContacts from './views/utilities/AddContacts';
import EditContacts from './views/utilities/EditContacts';
import FileStation from './views/utilities/FileStation';
import SpecCodeTool from './views/utilities/SpecCodeTool';
import StaffWebAccess from './views/utilities/StaffWebAccess';
import UserManager from './views/utilities/UserManager';
import UtilityContacts from './views/utilities/UtilityContacts';
import TicketDetails from './views/network/TicketDetails';
import NoInternetError from './views/errorPages/NoInternetError';
import NoAccessError from './views/errorPages/NoAccessError';
import NoIconnectError from './views/errorPages/NoIconnectError';
import Summary from './views/circuit/Summary';
import RouteCreator from './views/quote/routeCreator/RouteCreator';
import UserInfo from './views/login/UserInfo';
import UserManagement from './views/login/UserManagement';
import MACD from './views/order/MACD';
import { setUser, clearReduxStore } from "./redux/actions/userActions";
import { ConsoleLogger } from "./logger/ConsoleLogger";
import LoginResult from "./views/login/LoginResult";
import Error404 from './views/errorPages/server/Error404';
import Error500 from './views/errorPages/server/Error500';
import BulkQuoteSummary from "./views/quote/BulkQuoteSummary";
import BulkQuoteView from "./views/quote/BulkQuoteView";
import RuntimeErrorHandler from './views/errorPages/RuntimeErrorHandler';
import Profile from './views/employee/Employee';
import Bulk from './views/employee/Bulk';
import LayerZero from './views/network/LayerZero';
import FieldServiceDispatchRates from './views/support/FieldServiceDispatchRates';
import Staff from './views/employee/Staff/Staff';
import EmployeeHome from './views/employee/EmployeeHome';
import EmployeeLogin from './views/employee/EmployeeLogin';
import EmployeeLoginResult from './views/employee/EmployeeLoginResult';
import AddressValidator from './views/tools/AddressValidator';
import AsrMapping from './views/employee/AsrMapping';
import Renewals from './views/quote/Renewals';
import IcbPricing from './views/pricing/IcbPricing';
import PendingOrderReviewSummary from './views/employee/PendingOrderReviewSummary';
import MaintenanceEnrollment from "./views/utilities/MaintenanceEnrollment";
import InvoiceEnrollment from "./views/utilities/InvoiceEnrollment";
import PendingOrderUpdates from './views/employee/PendingOrderUpdates';
import InternalOrderUpdates from './views/employee/InternalOrderUpdates';
import NetworkViewer from './views/network/NetworkViewer';
import BulkCreate from './views/quote/bulk/BulkCreate';
import RfoRcaRequestForm from './views/support/RfoRcaRequestForm'
import RfoRcaRequestSuccess from './views/support/RfoRcaRequestSuccess'

const logger = new ConsoleLogger({ level: process.env.REACT_APP_LOGGER_LEVEL, });
const environment_url = process.env.REACT_APP_API_URL;
logger.info(`The current Environment URL is: ${environment_url}`);

// Disables login
// localStorage.setItem('isLoggedIn', "true");

const App = (props) => {
	const [widgetChange, setWidgetChange] = useState(false);
	const [loggedIn, setLoggedIn] = useState(false);
	const [employeeLoggedIn, setEmployeeLoggedIn] = useState(false);
	const [isEditContact, setIsEditContact] = useState(false);

	/*
	Everytime the current path is changed, it is stored in local storage
	The current path is not updated or changed if the user goes to the login page or the login result page for either internal or customer
	Winauth on a login will redirect to / so we also don't want to update the current path in that case
	By saving the currentPath to local storage this will give us a way to know where the user was and allow us to redirect them to the correct place
	after their token is validated or regenerated on a timeout
	*/
	const currentPath = window.location.pathname;
	logger.info(`currentPath: ${currentPath}`);
	if (!currentPath.includes("Login") && currentPath !== "/") {
		localStorage.setItem("currentPath", currentPath);
	}

	const callbackFunction = (childData) => {
		setWidgetChange(childData)
	}

	const logoutAuthenticationURL = `${process.env.REACT_APP_LOGIN_URL}/login.php?action=logout`;

	useEffect(() => {
		if (localStorage.getItem('isLoggedIn')) {
			setLoggedIn(true)
		}
		if (localStorage.getItem('isEmployeeLoggedIn')) {
			setEmployeeLoggedIn(true)
		}
	}, [])

	const logout = () => {
		//Clearing everything in localStorage
		localStorage.clear();
		//Clearing redux store on logout
		props.clearStore();
		//OAuth Logging out
		window.location.href = logoutAuthenticationURL;
	}

	const loginCheck = () => {
		if (localStorage.getItem('isLoggedIn')) {
			setLoggedIn(true)
		}
	}

	const employeeLoginCheck = () => {
		if (localStorage.getItem('isEmployeeLoggedIn')) {
			setEmployeeLoggedIn(true)
		}
	}

	const callBackEdit = () => {
		setIsEditContact(true);
		return <Redirect to={{ pathname: "/utilities/UtilityContacts" }} />
	}

	return (<div id="ui-shell" className="shell">
		<Router>
			{loggedIn || employeeLoggedIn ? <>
				<Navigation parentCallback={callbackFunction} logoutHandle={logout} />
				<Supertip />
			</> : null}
			<ErrorBoundary FallbackComponent={RuntimeErrorHandler}>
				<Switch>
					<Route path="/serverError" component={Error500} />
					<Route path="/pageNotFound" component={Error404} />
					<Route path="/noInternet" component={NoInternetError} />
					<Route path="/noAccess" component={NoAccessError} />
					<Route path="/noIconnect" component={NoIconnectError} />
					<ProtectedRoute path="/Home/Goodies" component={Goodies} />
					<ProtectedRoute path="/Quote/Summary" component={QuoteSummary} />
					<ProtectedRoute path="/Quote/Create" component={QuoteCreate} />
					<ProtectedRoute path="/Quote/View/:refId/:makeOrderable?" component={QuoteView} />
					<EmployeeRoute path="/Employee/Quote/View/:refId/:makeOrderable?" component={QuoteView} />
					<EmployeeRoute path="/Employee/Quote/IcbPricing/:refId?" component={IcbPricing} />
					<EmployeeRoute path="/Quote/IcbPricing/:refId?" component={IcbPricing} />
					<ProtectedRoute path="/Quote/Bulk/Summary" component={BulkQuoteSummary} />
					<ProtectedRoute path="/Quote/Bulk/Create" component={BulkQuoteCreate} />
					<ProtectedRoute path="/Quote/Bulk/View/:bulkId" component={BulkQuoteView} />
					<ProtectedRoute path="/Order/Summary" component={OrderSummary} />
					<ProtectedRoute path="/Order/MACD/Create" component={MACD} />
					<ProtectedRoute path="/Order/Create/:refId" component={OrderCreate} />
					<ProtectedRoute path="/Order/View/:refId" component={OrderView} />
					<EmployeeRoute path="/Employee/Order/View/:refId" component={OrderView} />
					<ProtectedRoute path="/Network/TroubleTickets" component={TroubleTickets} />
					<ProtectedRoute path="/Network/TroubleTicketsSummary" component={TroubleTicketsSummary} />
					<ProtectedRoute path="/History/CircuitDetails" component={CircuitDetails} />
					<ProtectedRoute path="/History/LightLevels" component={LightLevels} />
					<ProtectedRoute path="/History/TroubleTicketsHistory" exact component={TroubleTicketsHistory} />
					<Route path="/History/TroubleTicketsHistory/:circuitId" component={TroubleTicketsHistory} />
					<ProtectedRoute path="/History/Maintenance" component={Maintenance} />
					<ProtectedRoute path="/Network/TicketDetails" exact component={TicketDetails} />
					<Route path="/Network/TicketDetails/:incidentNumber" component={TicketDetails} />
					<ProtectedRoute path="/Network/CircuitHealth" component={CircuitHealth} />
					<ProtectedRoute path="/Network/MaintenanceSchedule" exact component={MaintenanceSchedule} />
					<Route path="/Network/MaintenanceSchedule/:eventNumber" component={MaintenanceSchedule} />
					<ProtectedRoute path="/Billing/AccountSummary" component={AccountSummary} />
					<ProtectedRoute path="/Billing/Invoices" component={Invoices} />
					<ProtectedRoute path="/Billing/PaymentHistory" component={PaymentHistory} />
					<ProtectedRoute path="/Billing/OneTimePayment" component={OneTimePayment} />
					<ProtectedRoute path="/Network/Summary" component={NetworkSummary} />
					<ProtectedRoute path="/Network/FiberMapping" component={FiberMapping} />
					<ProtectedRoute path="/Network/LayerZero" component={LayerZero} />
					<ProtectedRoute path="/support/Report" component={SupportReport} />
					<ProtectedRoute path="/support/Document" component={SupportDocument} />
					<ProtectedRoute path="/Ticket/Create" component={TicketCreate} />
					<ProtectedRoute path="/utilities/AddContacts/:gaid" component={AddContacts} />
					<ProtectedRoute path="/utilities/MaintenanceEnrollment/:gaid/:gapid" component={MaintenanceEnrollment} />
					<ProtectedRoute path="/utilities/InvoiceEnrollment/:gaid/:gapid" component={InvoiceEnrollment} />
					<ProtectedRoute path="/utilities/EditContacts/:custId/:gaid"
						component={() => (<EditContacts utilityContactsCall={callBackEdit} />)} />
					<ProtectedRoute path="/utilities/FileStation" component={FileStation} />
					<ProtectedRoute path="/utilities/SpecCodeTool" component={SpecCodeTool} />
					<ProtectedRoute path="/utilities/StaffWebAccess" component={StaffWebAccess} />
					<ProtectedRoute path="/utilities/UserManager" component={UserManager} />
					<ProtectedRoute path="/utilities/UtilityContacts"
						component={() => (<UtilityContacts showTable={isEditContact} />)} />
					<ProtectedRoute path="/Circuits/Summary" exact component={Summary} />
					<Route path="/Circuits/Summary/:circuitID/:designID" component={Summary} />
					<ProtectedRoute path="/Quote/RouteCreator" component={RouteCreator} />
					<Route path="/Login"
						component={() => (<Login loginCallBack={loginCheck} logInStatus={loggedIn} />)} />
					<Route path={"/LoginResult/:authId/:userId"} component={() => (<LoginResult />)} />
					<ProtectedRoute path="/" exact component={() => (<Home widgetStatus={widgetChange} />)} />
					<ProtectedRoute path="/Manage/UserInfo/:userId?" component={UserInfo} />
					<ProtectedRoute path="/Manage/UserManage" component={UserManagement} />
					<EmployeeRoute path="/Employee/Profile" component={Profile} />
					<EmployeeRoute path="/Employee/BulkAddress" component={Bulk} />
					<ProtectedRoute path="/Tools/AddressValidator" component={AddressValidator} />
					<EmployeeRoute path="/Employee/AsrMapping" component={AsrMapping} />
					<EmployeeRoute path="/Quote/Renewal/Create" component={Renewals} />
					<ProtectedRoute path="/support/FieldServiceDispatchRates" component={FieldServiceDispatchRates} />
					<EmployeeRoute path="/Employee/Staff" component={Staff} />
					<EmployeeRoute path="/Employee/Home" component={EmployeeHome} />
					<EmployeeRoute path="/Employee/Reports/PendingOrderReview" component={PendingOrderReviewSummary} />
					<EmployeeRoute path="/Employee/Order/PendingOrderUpdates/:refId?" component={PendingOrderUpdates} />
					<EmployeeRoute path="/Employee/Order/InternalOrderUpdates/:refId?" component={InternalOrderUpdates} />
					<ProtectedRoute path="/Network/NetworkViewer" component={NetworkViewer} />
					<ProtectedRoute path="/Bulk/Create" component={BulkCreate} />
					<ProtectedRoute path="/Support/RfoRcaRequestForm" component={RfoRcaRequestForm} />
					<ProtectedRoute path="/Support/RfoRcaRequestSuccess" component={RfoRcaRequestSuccess} />
					<Route path="/Employee/Login"
						component={() => (<EmployeeLogin EmployeeloginCallBack={employeeLoginCheck} EmployeelogInStatus={employeeLoggedIn} />)} />
					<Route path={"/Employee/LoginResult/:authId/:employeeId/:employeeName?"} component={() => (<EmployeeLoginResult />)} />
					<Route path="*" component={Error404} />
				</Switch>
			</ErrorBoundary>
		</Router>
	</div>);
}

const mapDispatchToProps = {
	updateUserApp: setUser,
	clearStore: clearReduxStore
}

export default connect(null, mapDispatchToProps)(App);
