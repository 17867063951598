import {ALERT_WINDOW_HOME} from "../actions/types/actionTypes"

const initialState = {
    isShow: true
}
export const alertWindowHomePageReducer = (state = initialState, action) => {
    switch(action.type) {
        case ALERT_WINDOW_HOME: return {
            isShow: action.payload
        }
        default: return state;
    }

}