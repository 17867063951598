import {useEffect, useMemo, useState} from 'react';
import {Link, useParams, useLocation} from 'react-router-dom';
import ViewportWarning from "../shared/ViewportWarning";
import ContactData from "../../services/ContactService";
import {ConsoleLogger} from "../../logger/ConsoleLogger";
import Loader from "../shared/Loader";
import HTTPErrorHandler from "../errorPages/HTTPErrorHandler";
import CircuitModal from './CircuitModal';
import style from "./UtilityContacts.module.css";
import ReactTooltip from 'react-tooltip';

const MaintenanceEnrollment = () => {
    const logger = useMemo(() => new ConsoleLogger({level: process.env.REACT_APP_LOGGER_LEVEL,}), []);
    const [hasError, setHasError] = useState(null);
    const [enrollMessage, setEnrollmentMessage] = useState("");
    const [enrollmentDataNetwork, setEnrollmentDataNetwork] = useState([]);
    const [updatedEnrollmentDataNetwork, setUpdatedEnrollmentDataNetwork] = useState([]);
    const [loader, setLoader] = useState(false);
    const {gaid, gapid} = useParams();
    const [openSelectCircuit, setOpenSelectCircuit] = useState(false);
    const [circuitPreference, setCircuitPreference] = useState("");
    const location = useLocation();
    const enrollContactData = location.state?.contactDetails;

    useEffect(() => {
        //Getting Enrollment for Data Network
        getEnrollmentsDataNetwork();
    }, [gaid, gapid, logger])

    /**
     * Updates the enrollments based on the updated data.
     */
    const updateEnrollmentsHandler = () => {
        // Check if the updated enrollment data is different from the original enrollment data
        if (updatedEnrollmentDataNetwork !== enrollmentDataNetwork) {
            setLoader(true);

            // Call the updateEnrollments function from the ContactData module
            ContactData.updateEnrollments(enrollmentDataNetwork, updatedEnrollmentDataNetwork, gaid, gapid)
                .then(resp => {
                    // Log the response data after encoding and replacing spaces
                    logger.info(encodeURIComponent(resp.data).replace(/%20/g, " "));

                    setLoader(false);

                    // Set the enrollment message with the response data
                    setEnrollmentMessage(resp.data);
                })
                .catch(error => {
                    setLoader(false);

                    // Set an error flag and log the error
                    setHasError(error);
                    logger.error("Error with updating Enrollments" + error);
                });
        }
    }

    /**
     * Retrieves enrollment data for a network.
     * Sets the enrollment data and circuit preference based on the response.
     */
    const getEnrollmentsDataNetwork = () => {
        // Set loader to true to indicate loading state
        setLoader(true);

        // Retrieve enrollment data for the network
        ContactData.getDataNetworkEnrollments(gaid, gapid)
            .then(resp => {
                // Set enrollment data and updated enrollment data
                setEnrollmentDataNetwork(resp.data);
                setUpdatedEnrollmentDataNetwork(resp.data);

                // Set circuit preference based on the response
                setCircuitPreference(resp.data.circuitPreference === "INDIVIDUAL" ? "INDIVIDUAL" : "ALL");

                // Set loader to false to indicate loading is complete
                setLoader(false);
            })
            .catch(error => {
                // Set loader to false to indicate loading is complete
                setLoader(false);

                // Set error flag and log the error
                setHasError(error);
                logger.error("Error getting data network enrollments " + error);
            });
    }

    /**
     * Handles the enrollment data network event.
     *
     * @param {Event} event - The event object.
     */
    const enrollmentDataNetworkHandler = (event) => {
        // Log the checked value of the target element
        logger.info(event.target.checked);

        // Log the id of the target element
        logger.info(event.target.id);

        // Update the enrollment data network state
        setUpdatedEnrollmentDataNetwork((prevState) => ({
            ...prevState, [event.target.id]: event.target.checked
        }));
    }

    /**
     * Opens the select circuit dialog.
     */
    const circuitSelection = () => {
        setOpenSelectCircuit(true);
    }

    /**
     * Closes the select circuit modal.
     */
    const modalClose = () => {
        setOpenSelectCircuit(false);
    }

    /**
     * Updates the circuit preference in the enrollment data network.
     *
     * @param {string} preference - The new circuit preference value.
     */
    const updateCircuitPreference = (preference) => {
        // Update the circuit preference in the enrollment data network
        setUpdatedEnrollmentDataNetwork((prevState) => ({
            ...prevState, "circuitPreference": preference
        }));
    }

    /**
     * Updates the enrollment data network with the provided circuit list.
     *
     * @param {Array} circuitList - The list of circuits to add to the enrollment data network.
     */
    const addSelectedCircuits = (circuitList) => {
        // Update the enrollment data network by merging the existing state with the new circuit list
        setUpdatedEnrollmentDataNetwork((prevState) => ({
            ...prevState, "items": circuitList
        }));
    }

    return (<>
        {hasError && <HTTPErrorHandler error={hasError}></HTTPErrorHandler>}
        {loader ? <Loader/> : null}
        <div className="midsection qm-upload abs z1">
            <div className="rel">
                <ViewportWarning/>
                <div className="wrap">
                    <h1 className="page-title">Enrollment</h1>
                    {!hasError && (<div className="section">
                        {/* <TabsQuote /> */}
                        <div className="content enrollment_page">
                            <Link to="/utilities/UtilityContacts" className="back_btn_link">
                                <p>Back to Utility Contacts</p>
                            </Link>
                            <p>This web page allows you set/update notifications on an account contact</p>
                            <div className="enrollfetchedData">
                                <div>
                                    <p>Contact
                                        Name: <span>{enrollContactData?.firstName} {enrollContactData?.lastName}</span>
                                    </p>
                                    <p>Email: <span>{enrollContactData?.email}</span></p>
                                    <p>Phone: <span>{enrollContactData?.phone}</span></p>
                                </div>
                            </div>
                            <div className="mt-5">
                                <table className={`table ${style.enrollmentTable}`}>
                                    <thead>
                                    <tr>
                                        <th>Notifications</th>
                                        <th>Email
                                            <img data-tip data-for="emailToolTipInfo"
                                                 className="tipped cornertip-right ml-2"
                                                 src="../../../images/svg/ico-info-filled-dark-off.svg" width="15"
                                                 height="15" alt=""/>
                                            <ReactTooltip id="emailToolTipInfo"
                                                          className={`${style.enrollmentToolTipInfo} ${style.enrollmentInfoWindow}`}
                                                          delayHide={100} effect='solid'>
                                                All Email notifications to be received are voluntary and not
                                                soliciting in nature. Notifications may provide information about
                                                your account and services with Windstream. Ensure your contact
                                                information is correct before subscribing to a notification To
                                                discontinue receiving email notifications, you can unselect the
                                                email preferences for any of the notification type alerts from the
                                                notification center page.
                                            </ReactTooltip>
                                        </th>
                                        <th>SMS
                                            <img data-tip data-for="smsToolTipInfo"
                                                 className="tipped cornertip-right ml-2"
                                                 src="../../../images/svg/ico-info-filled-dark-off.svg" width="15"
                                                 height="15" alt=""/>
                                            <ReactTooltip id="smsToolTipInfo"
                                                          className={`${style.enrollmentToolTipInfo} ${style.enrollmentInfoWindow}`}
                                                          delayHide={100} effect='solid'>
                                                All SMS notifications to be received are voluntary, and standard SMS
                                                rates may apply. Notifications may provide information about your
                                                account and services with Windstream. Ensure your contact
                                                information is correct before subscribing to a notification.To
                                                discontinue receiving notifications, you can unselect the SMS
                                                preference for any of the notification type alerts from the
                                                notification center page.
                                            </ReactTooltip>
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td>
                                            Network Maintenance
                                            <img data-tip data-for="networkMaintenanceInfo"
                                                 className="tipped cornertip-right ml-2"
                                                 src="../../../images/svg/ico-info-filled-dark-off.svg" width="15"
                                                 height="15" alt=""/>
                                            <ReactTooltip id="networkMaintenanceInfo"
                                                          className={`${style.enrollmentToolTipInfo} ${style.enrollmentInfoWindow}`}
                                                          delayHide={100} effect='solid'>
                                                By selecting Maintenance, you are subscribing to receive
                                                notifications from Windstream's
                                                Network Management team when we're scheduling Network Maintenance
                                                windows.
                                                To keep the Network and your services running efficiently, securely
                                                and with as
                                                little impact to up-time as possible, we must perform routine
                                                Maintenance. You
                                                will receive a notification describing the Maintenance and impact to
                                                your network
                                                and services. Please read through the notification carefully.
                                                <br></br>
                                                <b>NOTE: </b>SMS Notification do not contain the details of the
                                                Maintenance. If you have
                                                subscribed to this service and are still not receiving out emails,
                                                Please check
                                                your junk mail folder and add Windstream to your Safe Sender List.
                                                To discontinue
                                                receiving this notification, you can unselect the delivery
                                                preference for Maintenance
                                                from the notification center page in Wholesale Service Assurance
                                                Portal.
                                            </ReactTooltip>

                                            <button className={`${style.selectCircuitBtn} ml-2`}
                                                    onClick={() => circuitSelection()}>
                                                <span className="select_circuit">Select Circuit(s)</span>
                                            </button>
                                        </td>
                                        <td>
                                            <input type="checkbox" id="enrollEmail" name="enrollEmail"
                                                   checked={updatedEnrollmentDataNetwork.enrollEmail ? "checked" : ""}
                                                   onChange={enrollmentDataNetworkHandler}/>
                                        </td>
                                        <td>
                                            <input type="checkbox" id="enrollSMS" name="enrollSMS"
                                                   checked={updatedEnrollmentDataNetwork.enrollSMS ? "checked" : ""}
                                                   onChange={enrollmentDataNetworkHandler}/>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                                <div className="text-center">
                                    <button type="button"
                                            className={`ico-button lhs light-button ${style.enrollUpdateBtn}`}
                                            onClick={updateEnrollmentsHandler}>
                                        Submit
                                        <svg className="ico light-ico ico-arrow">
                                            <path d="M0,17H29L15,31l1,1L32,16,16,0,15,1,29,15H0Z"></path>
                                        </svg>
                                    </button>
                                </div>
                            </div>
                            {/*Message From the Enroll API */}
                            {enrollMessage ? <div className="mt-5">
                                <p className="text-center text-success">{enrollMessage}</p>
                            </div> : null}
                        </div>
                        {openSelectCircuit ? <CircuitModal gaid={gaid} gapid={gapid} modalClose={() => modalClose()}
                                                           addSelectedCircuits={(circuitList) => addSelectedCircuits(circuitList)}
                                                           circuitPreference={circuitPreference}
                                                           updateCircuitPreference={(preference) => updateCircuitPreference(preference)}/> : null}
                    </div>)}
                </div>
            </div>
        </div>
    </>);
}

export default MaintenanceEnrollment;
