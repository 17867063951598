/**
 * Created by e0173131 on 1/20/2021.
 */
import React, {useState, Fragment} from 'react';

const GoodieIcons = () => {
    const [isCollapsed, setIsCollapsed] = useState(true);

    const handleClick = () => {
        setIsCollapsed(!isCollapsed);
    }
    return (
        <Fragment>
            <span className="cf"></span>
            <div className="sub-hdr alt3">
                <div className="expander" collapsed-id="icon-content" onClick={handleClick}>
                    <svg className="ico-link light-ico ico-add">
                        <path
                            d="M24,48A24,24,0,1,1,48,24,24,24,0,0,1,24,48ZM24,1.89A22.1,22.1,0,1,0,46.08,24,22.09,22.09,0,0,0,24,1.89Zm1,32.69H23.05V25h-9.6V23.05h9.6v-9.6H25v9.6h9.61V25H25Z"></path>
                    </svg>
                </div>
                <div className="trim">&nbsp;</div>
                <div className="nib">
                    <div className="the-notch">
                        <div className="notch-fg">
                            <svg>
                                <path
                                    d="M35.81,26.28l-13,10v-5C-10.47,28.68-1,9.16,11.79,0c0,7.28,0,9.13,0,13.28s3.18,7.92,11,9v-5Z"></path>
                            </svg>
                        </div>
                    </div>
                </div>
                <h2>Icons</h2>
            </div>
            {!isCollapsed ?
                <div className="sub-section">
                    {/*<style>
                        .ico-sample {float: left; width: 100px; height: 100px; margin: 12px 12px 0 0; text-align: center; border: 1px dotted #eee;}
                        .ico-sample-alt {float: left; width: 100px; height: 100px; margin: 12px 12px 0 0; text-align: center; border: 1px dotted #fff;}

                        .ico-sample.scale-example {border: 1px solid #fff;}
                        .ico-sample .ico {margin: 12px auto 12px auto;}
                        .ico-sample span {display: block; font-size: 12px;}
                        .ico-sample svg path {fill: #333333;}
                        .ico-sample.unfinished svg path {fill: #ff6a00;}
                        em.code {color: #B31983;}
                    </style>*/}
                    <p>Note that some narrow icons have the <em className="code">viewBox</em> attribute set but most do not need it.</p>
                    <p>SVG icon data and associative name:</p>
                    <div className="cf">
                        <div className="ico-sample">
                            <svg className="ico">
                                <path
                                    d="M36,2V32l-2,2H2L0,32V2L2,0H34ZM34,32V2H2V16h8l3,4,5-10,3,6h8v2H20l-2-4L13,24,9,18H2V32Z" />
                            </svg>
                            <span>Network Health</span>
                        </div>
                        <div className="ico-sample">
                            <svg className="ico">
                                <path
                                    d="M16,8.9h2v10H16Zm2,14H16v-2h2Zm14-1H30l-1-1v-7a12.9,12.9,0,0,0-1.73-6.32A11.55,11.55,0,0,0,23,3.33a11.93,11.93,0,0,0-12,0A11.55,11.55,0,0,0,6.73,7.58,12.9,12.9,0,0,0,5,13.9v8A8.87,8.87,0,0,0,6.73,27,11.14,11.14,0,0,0,11,30.91c1.86,1,3.87,1,6,1h3v-1H16l-1-1,1-1h4c.68,0,.88.35,1.37.85a2.44,2.44,0,0,1,.73,1.77,2.38,2.38,0,0,1-.73,1.75A1.54,1.54,0,0,1,20,33.9H17a13.92,13.92,0,0,1-6.83-1.63,12.74,12.74,0,0,1-4.9-4.56A13.59,13.59,0,0,1,3,20.9v-7A14.63,14.63,0,0,1,5.27,6.77a13.24,13.24,0,0,1,4.9-4.9,13.41,13.41,0,0,1,13.66,0,13.24,13.24,0,0,1,4.9,4.9A14.63,14.63,0,0,1,31,13.9v6h1v-6l1.08-1.08L34,13.9v7Zm-32-2v-6l1-1,1,1v6l-1,1Z" />
                            </svg>
                            <span>Trouble Tickets</span>
                        </div>
                        <div className="ico-sample">
                            <svg className="ico">
                                <path
                                    d="M35,15v7l-2,1H31l-1-1V15a12.9,12.9,0,0,0-1.73-6.32A11.55,11.55,0,0,0,24,4.38a11.93,11.93,0,0,0-12,0A11.55,11.55,0,0,0,7.73,8.63,12.9,12.9,0,0,0,6,15v8a8.87,8.87,0,0,0,1.73,5.1A11.14,11.14,0,0,0,12,32c1.86,1,3.87,1,6,1h3V32H17l-1-1,1-1h4c.68,0,.88.35,1.37.85a2.44,2.44,0,0,1,.73,1.77,2.38,2.38,0,0,1-.73,1.75A1.54,1.54,0,0,1,21,35H18a13.92,13.92,0,0,1-6.83-1.63,12.74,12.74,0,0,1-4.9-4.56A13.59,13.59,0,0,1,4,22V15A14.63,14.63,0,0,1,6.27,7.82a13.24,13.24,0,0,1,4.9-4.9,13.41,13.41,0,0,1,13.66,0,13.24,13.24,0,0,1,4.9,4.9A14.63,14.63,0,0,1,32,15v6h1V15l1.08-1.08ZM3,15v6L2,22,1,21V15l1-1Z" />
                            </svg>
                            <span>Contact Us</span>
                        </div>
                        <div className="ico-sample">
                            <svg className="ico">
                                <path
                                    d="M20,1V3H32L10.69,23.89,11.76,25,33,4V15h2V1ZM1,9V33l2,2H27l2-2V14l-2,2V33H3V9H19l2-2H3Z" />
                            </svg>
                            <span>External Link</span>
                        </div>
                        <div className="ico-sample">
                            <svg className="ico">
                                <path
                                    d="M23,35V34H21V30h2V27h8V26H27V25h6v2h2v8ZM18,20c-3,1-8,2-8,6,0,3,5.07,5,7,5h2v2H17c-2.59,0-9-2-9-7,0-6,7-7,10-8s10-2.09,10-5.25C28,10.08,23.93,9,22,9H17V7h5c2.59,0,8,1.82,8,6C30,18.41,21,19,18,20Zm-5-9H1V3H3V1H9V2H5V3h8V6h2v4H13Z" />
                            </svg>
                            <span>Circuit Health</span>
                        </div>
                        <div className="ico-sample">
                            <svg className="ico">
                                <path
                                    d="M25,11V5H11V17h6V9h2v8h6l2,2V31l-2,2H19v3H17V33H11L9,31V25h2v6h6V19H11.06L9,17V5l2-2h6V0h2V3h6l2,2v6Zm-6,8V31h6V19Z" />
                            </svg>
                            <span>Billing</span>
                        </div>
                        <div className="ico-sample">
                            <svg className="ico">
                                <path
                                    d="M12,17v3h3l1,1v7l-1,1H12v2H10V29H7L6,28V25H8v2h2V22H7L6,21V14l1-1h3V11h2v2h3l1,1v3H14V15H8v5h2V17Zm0,10h2V22H12ZM30,14V35H6V1H21l9,9v2H19V6h2v4h7L21,3H8v7H6V32H8v1H28V14Z" />
                            </svg>
                            <span>Invoices</span>
                        </div>
                        <div className="ico-sample">
                            <svg className="ico" viewBox="0 0 32 36">
                                <path
                                    d="M32.54,0l-.87.11L0,4V27L32.54,31Zm-2,2V15h-14V4Zm-16,2V15h-13V6Zm-13,12h13V27l-13-2Zm15,0h14V29l-14-2Z" />
                            </svg>
                            <span>Utilities</span>
                        </div>
                        <div className="ico-sample">
                            <svg className="ico ico-web">
                                <path
                                    d="M16.51,0C25.17,0,33,7.84,33,16.51S25.17,33,16.51,33,0,25.17,0,16.51,7.84,0,16.51,0ZM8,15.82A24,24,0,0,1,9.53,8.94,20.55,20.55,0,0,1,6,7a13.86,13.86,0,0,0-3.51,8.8ZM2.46,17.19A13.84,13.84,0,0,0,6,26a19.9,19.9,0,0,1,3.56-1.91A21.36,21.36,0,0,1,8,17.19ZM12.59,3a13.21,13.21,0,0,0-5.66,3.1A15.47,15.47,0,0,0,10,7.75,22,22,0,0,1,12.59,3ZM6.93,27A12.63,12.63,0,0,0,12.59,30,19.46,19.46,0,0,1,10,25.22,15.12,15.12,0,0,0,6.93,27Zm8.89-16.28a15.83,15.83,0,0,1-5.06-1.28,20.44,20.44,0,0,0-1.41,6.43h6.47ZM9.35,17.19a21.25,21.25,0,0,0,1.41,6.43,15.8,15.8,0,0,1,5.06-1.27V17.19ZM15.82,2.41a7.92,7.92,0,0,0-1.41.14,27.88,27.88,0,0,0-3.24,5.7,17.32,17.32,0,0,0,4.65,1Zm0,21.35a17.11,17.11,0,0,0-4.6,1,24.81,24.81,0,0,0,3.19,5.74,7.09,7.09,0,0,0,1.41.14Zm1.37-14.5a17.16,17.16,0,0,0,4.65-1,27,27,0,0,0-3.24-5.7,7.83,7.83,0,0,0-1.41-.14Zm0,6.56h6.48a20.46,20.46,0,0,0-1.42-6.43,15.83,15.83,0,0,1-5.06,1.28Zm0,1.37v5.16a15.8,15.8,0,0,1,5.06,1.27,21.28,21.28,0,0,0,1.42-6.43Zm0,13.41a7,7,0,0,0,1.41-.14,24.84,24.84,0,0,0,3.2-5.74,17.18,17.18,0,0,0-4.61-1Zm8.9-24.54A13.32,13.32,0,0,0,20.43,3,21.93,21.93,0,0,1,23,7.75,15.86,15.86,0,0,0,26.09,6.06ZM20.43,30A12.74,12.74,0,0,0,26.09,27,15.21,15.21,0,0,0,23,25.22,19.1,19.1,0,0,1,20.43,30ZM30.55,15.82A13.81,13.81,0,0,0,27,7a20.43,20.43,0,0,1-3.55,1.92A24,24,0,0,1,25,15.82ZM25,17.19a21.9,21.9,0,0,1-1.55,6.89A19.79,19.79,0,0,1,27,26a13.79,13.79,0,0,0,3.51-8.8Z" />
                            </svg>
                            <span>Staff Web Access</span>
                        </div>
                        <div className="ico-sample">
                            <svg className="ico">
                                <path
                                    d="M31,21.5v14H13V8.5h9l9,9v2H20v-6h2v4h7l-7-7H15v23H29v-12Zm-20,10H9V4.5H21v2H11Zm-4-2H5V.5H18v2H7Z" />
                            </svg>
                            <span>File Station</span>
                        </div>
                        <div className="ico-sample">
                            <svg className="ico">
                                <path
                                    d="M30.5,24.5v-13h5v13Zm3-11h-1v9h1Zm-9-2h5v13h-5Zm2,11h1v-9h-1Zm-8-11h5v13h-5Zm2,11h1v-9h-1Zm-8-11h5v13h-5Zm2,11h1v-9h-1Zm-8-11h5v13h-5Zm2,11h1v-9h-1Zm-8-11h5v13H.5Zm2,11h1v-9h-1Z" />
                            </svg>
                            <span>SPEC Code Tool / Number</span>
                        </div>
                        <div className="ico-sample">
                            <svg className="ico">
                                <path
                                    d="M33.12,34.92a5.28,5.28,0,0,1-6.46-1.36V27a5,5,0,0,1,1.7-1.36,5.17,5.17,0,0,1,4.76,0A4.94,4.94,0,0,1,34.82,27v6.56A5.06,5.06,0,0,1,33.12,34.92Zm0-7.38a3.64,3.64,0,0,0-2.36-.88,3.81,3.81,0,0,0-2.4.88v5.24a3.81,3.81,0,0,0,2.4.88,3.64,3.64,0,0,0,2.36-.88Zm-2.38-4a5.22,5.22,0,0,1-2.38-.58,5.16,5.16,0,0,1-1.7-1.36V15a5,5,0,0,1,1.7-1.36,5.17,5.17,0,0,1,4.76,0A4.94,4.94,0,0,1,34.82,15v6.56a5.06,5.06,0,0,1-1.7,1.36A5.19,5.19,0,0,1,30.74,23.5Zm2.38-8a3.64,3.64,0,0,0-2.36-.88,3.81,3.81,0,0,0-2.4.88v5.24a3.81,3.81,0,0,0,2.4.88,3.64,3.64,0,0,0,2.36-.88Zm-2.54-4.6a5.22,5.22,0,0,1-2.38-.58A5.16,5.16,0,0,1,26.5,9V2.44a5,5,0,0,1,1.7-1.36,5.17,5.17,0,0,1,4.76,0,4.94,4.94,0,0,1,1.7,1.36V9A5.06,5.06,0,0,1,33,10.36,5.19,5.19,0,0,1,30.58,10.94ZM33,3.12a3.54,3.54,0,0,0-4.76,0v5.2a3.54,3.54,0,0,0,4.76,0ZM18.18,20.32l5,3v2l-5-3Zm0-2h6v1h-6Zm0-3,5-4v2l-5,4ZM10.42,23.5A5.22,5.22,0,0,1,8,22.92a8.79,8.79,0,0,1-1.7-1.36V15A5,5,0,0,1,8,13.64a5.17,5.17,0,0,1,4.76,0A4.94,4.94,0,0,1,14.5,15v6.56a5.06,5.06,0,0,1-1.7,1.36A5.19,5.19,0,0,1,10.42,23.5Zm2.38-8a3.64,3.64,0,0,0-2.36-.88,3.81,3.81,0,0,0-2.4.88v5.24a3.81,3.81,0,0,0,2.4.88,3.64,3.64,0,0,0,2.36-.88Zm7.38,11.78v8h-2v-8h-15v8h-2v-8l2-2h15Z" />
                            </svg>
                            <span>Contacts</span>
                        </div>
                        <div className="ico-sample">
                            <svg className="ico">
                                <path
                                    d="M20,34.91H18v-8H3v8H1v-8l2-2H18l2,2Zm15,0H33v-8H22v-2H33l2,2ZM7.87,22.51a8.5,8.5,0,0,1-1.7-1.36V14.59a5.06,5.06,0,0,1,1.7-1.36,5.28,5.28,0,0,1,6.46,1.36v6.56a5,5,0,0,1-1.7,1.36,5.17,5.17,0,0,1-4.76,0Zm0-2.14a3.76,3.76,0,0,0,2.39.88,3.69,3.69,0,0,0,2.37-.88V15.13a3.69,3.69,0,0,0-2.37-.88,3.76,3.76,0,0,0-2.39.88ZM22.49,9.59A5,5,0,0,1,20.79,11,5.17,5.17,0,0,1,16,11a4.94,4.94,0,0,1-1.7-1.36V3A5.06,5.06,0,0,1,16,1.67,5.28,5.28,0,0,1,22.49,3Zm-1.7-5.88a3.64,3.64,0,0,0-2.38-.92A3.6,3.6,0,0,0,16,3.71v5.2a3.6,3.6,0,0,0,2.38.92,3.64,3.64,0,0,0,2.38-.92Zm1.7,10.88a5.06,5.06,0,0,1,1.7-1.36,5.28,5.28,0,0,1,6.46,1.36v6.56A5,5,0,0,1,29,22.51a5.17,5.17,0,0,1-4.76,0,4.94,4.94,0,0,1-1.7-1.36Zm1.7,5.78a3.76,3.76,0,0,0,2.39.88A3.69,3.69,0,0,0,29,20.37V15.13a3.69,3.69,0,0,0-2.37-.88,3.76,3.76,0,0,0-2.39.88Z" />
                            </svg>
                            <span>User Manager</span>
                        </div>
                        <div className="ico-sample">
                            <svg className="ico">
                                <path
                                    d="M24.09,2.17A16,16,0,1,1,16,0,15.76,15.76,0,0,1,24.09,2.17Zm-.8,26.28a13.89,13.89,0,0,0,5.16-5.16,14.6,14.6,0,0,0,0-14.58,13.89,13.89,0,0,0-5.16-5.16,14.6,14.6,0,0,0-14.58,0A13.89,13.89,0,0,0,3.55,8.71a14.6,14.6,0,0,0,0,14.58,13.89,13.89,0,0,0,5.16,5.16,14.6,14.6,0,0,0,14.58,0ZM10.88,10.88a1,1,0,0,0,.24.69.8.8,0,0,0,.63.27,1,1,0,0,0,.54-.16.69.69,0,0,0,.28-.48A3.91,3.91,0,0,1,13.67,9a2.92,2.92,0,0,1,2.24-.87,4.59,4.59,0,0,1,1,.16h.32a11.22,11.22,0,0,1,1,.63,2.13,2.13,0,0,1,.73,1,3.24,3.24,0,0,1,.23,1.34,2.79,2.79,0,0,1-.41,1.69,6.2,6.2,0,0,1-1.1,1.19l-.73.64-.73.73a3.91,3.91,0,0,0-.64.87l-.16.34c-.14.31-.23.54-.3.71A4.59,4.59,0,0,0,15,18.69v1.6a.84.84,0,0,0,.26.62,1,1,0,0,0,.64.25.86.86,0,0,0,.63-.24.81.81,0,0,0,.25-.63V19.15A3.41,3.41,0,0,1,16.87,18a1.27,1.27,0,0,1,.18-.57l.14-.3.28-.34.37-.4a4.76,4.76,0,0,1,.47-.42l.48-.44a5.24,5.24,0,0,1,.48-.41l.48-.41a3,3,0,0,0,.64-.74l.18-.29a4.33,4.33,0,0,0,.46-1,7.2,7.2,0,0,0,.18-1.46,3.55,3.55,0,0,0-.41-1.92,3.77,3.77,0,0,0-.45-.84,7.73,7.73,0,0,0-.6-.67,6.54,6.54,0,0,0-1.6-.87,7.57,7.57,0,0,0-2.06-.32,5.38,5.38,0,0,0-3.37,1.18A4.79,4.79,0,0,0,10.88,10.88ZM16,22.67a1.11,1.11,0,0,0-1.05.41,1.35,1.35,0,0,0-.46,1,1,1,0,0,0,.14.48L15,25a1.39,1.39,0,0,0,1.05.45A1.07,1.07,0,0,0,17.05,25a.74.74,0,0,0,.34-.4,1.52,1.52,0,0,0,.12-.52,1.07,1.07,0,0,0-.14-.52l-.32-.48A1.37,1.37,0,0,0,16,22.67Z" />
                            </svg>
                            <span>Support / Help</span>
                        </div>
                        <div className="ico-sample">
                            <svg className="ico">
                                <path
                                    d="M32,33H16v1.5L14,36H2L0,34V19l2-2H6V2L8,0H32l2,2V31.5ZM3,19,2,20V33l1,1H13l1-1V20l-1-1ZM32,2H8V17h6l2,2V31H32ZM12,11H25v1H12Zm0-3H25V9H12Zm0-3h5V6H12ZM4,27h7v1H4Zm0-3h7v1H4Zm0-3H9v1H4Zm6,10H4V30h6Z" />
                            </svg>
                            <span>Reports</span>
                        </div>
                        <div className="ico-sample">
                            <svg className="ico" viewBox="0 0 25 36">
                                <path
                                    d="M26,20V31l-1,1-1-1V20H2V31L1,32,0,31V20.34L2,18H24ZM7,13V3A7.5,7.5,0,0,1,19,3V13a8.88,8.88,0,0,1-6,2A9,9,0,0,1,7,13Zm2-1a10.28,10.28,0,0,0,4,1,9.9,9.9,0,0,0,4-1V4a4.67,4.67,0,0,0-4-2A4.37,4.37,0,0,0,9,4Z" />
                            </svg>
                            <span>User</span>
                        </div>
                        <div className="ico-sample">
                            <svg className="ico">
                                <path
                                    d="M27.5,30l-1,1H9.5l-1-1V19l2-1V29h15V19h-10l-1-1,1-1h11l1,1Zm-16-12V15c0-1.79,0-10,7-10s7,7,7,10v1h-2V15c0-2,0-8-5-8s-5,7.71-5,8v2Z" />
                            </svg>
                            <span>Change Password</span>
                        </div>
                        <div className="ico-sample">
                            <svg className="ico">
                                <path
                                    d="M35,23l-3.19-2.81v1.16A13.25,13.25,0,0,1,30,28.17,13.19,13.19,0,0,1,25.2,33,12.35,12.35,0,0,1,19,35H18V33h1a9.26,9.26,0,0,0,5.26-1.61,11.75,11.75,0,0,0,5.71-10L30,20l-3,3-1-1,5-5,5,5ZM23,25l-1,1H12l-1-1V18l2-1v7h8V18H18l-1-1,1-1h4l1,1Zm-9-8V14c0-1.79.91-5,4-5s4,4,4,5v1H20V14c0-.48,0-3-2-3s-2,2.71-2,3v2ZM18,3H17a9.05,9.05,0,0,0-5.28,1.31A11.39,11.39,0,0,0,7.58,8.47a11.47,11.47,0,0,0-1.67,5.88v1L9,13l1,1L5,19,0,14l1-1,3,2.4V14.35A13.25,13.25,0,0,1,5.87,7.53a13.27,13.27,0,0,1,4.8-4.87A11.43,11.43,0,0,1,17,1h1Z" />
                            </svg>
                            <span>Reset Password</span>
                        </div>
                        <div className="ico-sample">
                            <svg className="ico ico-logout">
                                <path
                                    d="M14,5h2V2.09L14,0H2L0,2V23l2,2H14l2-2V20H14v3H2V2H14Zm-2,7v1h9l-4,4,1,1,5-5V12L18,7,17,8l4,4Z" />
                            </svg>
                            <span>Logout</span>
                        </div>
                        <div className="ico-sample">
                            <svg className="ico">
                                <path
                                    d="M21,24V22H35v2Zm0-7H32v2H21ZM11,33v3H9V33H3L1,31V25H3v6H9V19H3L1,17V5L3,3H9V0h2V3h6l2,2v6H17V5H3V17H9V9h2v8h8v2H11V31h6V27h2v4l-2,2Zm6-11h2v2H17Zm17,7H21V27H34Z" />
                            </svg>
                            <span>Quote Manager</span>
                        </div>
                        <div className="ico-sample">
                            <svg className="ico">
                                <path
                                    d="M35,24H29v6H27V24H21V22h6V16h2v6h6ZM11,33v3H9V33H3L1,31V25H3v6H9V19H3L1,17V5L3,3H9V0h2V3h6l2,2v6H17V5H3V17H9V9h2v8h8v2H11V31h6V27h2v4l-2,2Zm6-11h2v2H17Z" />
                            </svg>
                            <span>Create Quote</span>
                        </div>
                        <div className="ico-sample">
                            <svg className="ico">
                                <path
                                    d="M29,18V31H27V18l-5,5-1-1,7-7,7,7-1,1ZM11,33v3H9V33H3L1,31V25H3v6H9V19H3L1,17V5L3,3H9V0h2V3h6l2,2v6H17V5H3V17H9V9h2v8h8v2H11V31h6V27h2v4l-2,2Zm6-11h2v2H17Z" />
                            </svg>
                            <span>Upload Quote</span>
                        </div>
                        <div className="ico-sample">
                            <svg className="ico">
                                <path
                                    d="M32.89,18.71v-2h2v2Zm-4-2h2v2h-2Zm-4,12h2v2h-2Zm0-12h2v2h-2Zm-4,12h2v2h-2Zm0-12h2v2h-2Zm0-12h2v2h-2Zm-4,24h2v2h-2Zm0-12h2v2h-2Zm0-12h2v2h-2Zm-4,24h2v2h-2Zm0-12h2v2h-2Zm0-12h2v2h-2Zm-12,20h10v10H.89Zm2,8h6v-6h-6Zm-2-20h10v10H.89Zm2,8h6v-6h-6Zm-2-20h10v10H.89Zm2,8h6v-6h-6Z" />
                            </svg>
                            <span>Summary</span>
                        </div>
                        <div className="ico-sample">
                            <svg className="ico">
                                <path d="M34.89,18.71h-16v16h-2v-16H.89v-2h16V.71h2v16h16Z" />
                            </svg>
                            <span>Plus / Add</span>
                        </div>
                        <div className="ico-sample">
                            <svg className="ico">
                                <path
                                    d="M28.73,2.65A12.61,12.61,0,0,1,33.11,7.1a12.31,12.31,0,0,1,0,12.38,12.14,12.14,0,0,1-4.38,4.45,11.7,11.7,0,0,1-6.19,1.74A12.21,12.21,0,0,1,12,19.51a12.08,12.08,0,0,1-1.66-6.2A12.3,12.3,0,0,1,12,7.07,12.28,12.28,0,0,1,22.54,1a11.94,11.94,0,0,1,6.19,1.7Zm-.92,19.62a10.2,10.2,0,0,0,3.74-3.78,10.54,10.54,0,0,0,0-10.6,10.2,10.2,0,0,0-3.74-3.78,10.24,10.24,0,0,0-10.54,0,10.05,10.05,0,0,0-3.74,3.78,10.54,10.54,0,0,0,0,10.6,10.48,10.48,0,0,0,9,5.24,10.14,10.14,0,0,0,5.27-1.46ZM29.88,9a8.46,8.46,0,0,0-3.11-3.09c-1.3-.75-4.55-.83-4.55-.83l1,2a5.64,5.64,0,0,1,2.7.46,6.49,6.49,0,0,1,2.43,2.42,11.11,11.11,0,0,1,.87,3.12l2,1v-1A18,18,0,0,0,29.88,9ZM11.22,23.05l-10,10v1l1,1,10-10v-2Z" />
                            </svg>
                            <span>Search</span>
                        </div>
                        <div className="ico-sample">
                            <svg className="ico">
                                <path
                                    d="M32,25v5a1,1,0,0,1-1,1l-7.55,0a6,6,0,0,1-2,3.51,5.46,5.46,0,0,1-3.73,1.45,1,1,0,0,1-.66-.26,1.07,1.07,0,0,1,0-1.44.92.92,0,0,1,.69-.3,3.6,3.6,0,0,0,2.36-.83A3.65,3.65,0,0,0,21.43,31H5a1,1,0,0,1-1-1V25l3-4V14A10.77,10.77,0,0,1,8.18,9.22C10.3,4.83,12.68,5,12,5c2,0,1.34,1.69,1,2a9.24,9.24,0,0,0-4,7v8L6,25v4H30V25l-3-3V14A9.29,9.29,0,0,0,25.75,9.4,9.11,9.11,0,0,0,18,5a1.07,1.07,0,0,1-1-1V1a1.13,1.13,0,0,1,1.14-.9A.85.85,0,0,1,19,1V3c.19,0,10,1.23,10,11v7Z" />
                            </svg>
                            <span>Notifications</span>
                        </div>
                        <div className="ico-sample">
                            <svg className="ico">
                                <path
                                    d="M15,36l2-4h4l2,4,6-4-5.3-8.4a8.09,8.09,0,0,0,1.9-5.2,7.89,7.89,0,0,0-3.8-6.9,7.91,7.91,0,0,0-10.9,2.9,7.67,7.67,0,0,0,0,8,8.07,8.07,0,0,0,6.9,4,1.1,1.1,0,0,0,0-2.2,5.1,5.1,0,0,1-2.9-.8,5.3,5.3,0,0,1-2.1-2.1,6,6,0,0,1,0-6,6.34,6.34,0,0,1,2.1-2.2,5.9,5.9,0,0,1,8.9,5.1,5.22,5.22,0,0,1-1.8,4,.92.92,0,0,0-.2,1L27,31l-3,3-2-4H16l-2,4-3-1,2-4L8,25,5,27,3,25l3-2V18L2,16l1-2,3,1,3-5L6,7,8,5l3,3,5-2V2h4V6l5,2,3-3,2,2-3,3,2,5,4-1,1,2-5,2v5l4,2-2,2-1-1-1,1,2,2,4-4-4-3V19l5-2-2-5-4,1-1-3,3-3L28,3,25,6,21,4V0H15V4L11,6,8,3,4,7l3,3L5,13,2,12,0,17l4,2v3L1,25l4,4,3-2,3,2L9,34Z" />
                            </svg>
                            <span>Settings</span>
                        </div>
                        <div className="ico-sample">
                            <svg className="ico">
                                <path d="M0,32.5V3.5H12l4,4H32l4,3v22Zm34-20-4-3H15l-4-4H2v25H34Z"></path>
                            </svg>
                            <span>Folder / Archive</span>
                        </div>
                        <div className="ico-sample">
                            <svg className="ico">
                                <path
                                    d="M33,21V35H15V8h9l9,9v2H22V13h2v4h7l-7-7H17V33H31V21ZM10,12V6h2v4h2v2H10Zm2-9H5V26h8v2H3V1h9l6,6H16Z"></path>
                            </svg>
                            <span>Copy</span>
                        </div>
                        <div className="ico-sample">
                            <svg className="ico">
                                <path
                                    d="M22,27V17L35,5V2s-1-1-1-1H9A1,1,0,0,0,8,2,1,1,0,0,0,9,3H33V5L20,17V27l-5,5V16L2.62,3.36A.84.84,0,0,0,2,3.09a.93.93,0,0,0-.68.26A.87.87,0,0,0,1,4a1.09,1.09,0,0,0,.26.72L13,17V34l2,1Z"></path>
                            </svg>
                            <span>Filter</span>
                        </div>
                        <div className="ico-sample">
                            <svg className="ico">
                                <path
                                    d="M18,35A17,17,0,1,1,35,18,17,17,0,0,1,18,35ZM18,3a15,15,0,0,0,0,30c10,0,15-7.48,15-15A15.08,15.08,0,0,0,18,3ZM17,20V10h2v9l5,3-1,2Z"></path>
                            </svg>
                            <span>Time</span>
                        </div>
                        <div className="ico-sample">
                            <svg className="ico">
                                <path d="M34.5,7V8l-22,22h-2l-9-9V20l1-1,9,9,22-22Z"></path>
                            </svg>
                            <span>Check / OK</span>
                        </div>
                        <div className="ico-sample">
                            <svg className="ico">
                                <path
                                    d="M31,19.5l-2,1H13a3.7,3.7,0,0,0,3,2H29v2H15l-3-2.16L10.5,20.1,6,4.5l-1-1H1v-2H5.25L7,2.5l1,1,4,15H29l5-11,1,2v1ZM14,7.5h6v-6h2v6h6v2H22v6H20v-6H14Zm0,19a4.21,4.21,0,0,1,4,4,4.2,4.2,0,0,1-4,4,4.2,4.2,0,0,1-4-4A4.21,4.21,0,0,1,14,26.5Zm0,6a2,2,0,0,0,0-4,2,2,0,0,0,0,4Zm14-6a4.21,4.21,0,0,1,4,4,4.2,4.2,0,0,1-4,4,4.2,4.2,0,0,1-4-4A4.21,4.21,0,0,1,28,26.5Zm0,6a2,2,0,0,0,0-4,2,2,0,0,0,0,4Z"></path>
                            </svg>
                            <span>Order Manager Create</span>
                        </div>
                        <div className="ico-sample">
                            <svg className="ico">
                                <path d="M1,18V16L17,31V1h2V31L35,16v2L20,33H35v2H1V33H16Z"></path>
                            </svg>
                            <span>Download</span>
                        </div>
                        <div className="ico-sample">
                            <svg className="ico">
                                <path
                                    d="M31,35H4a2.65,2.65,0,0,1-3-3V7.93C1,6.78,3,6.77,3,7.93L3,12H33V8c0-1,0-2-2-2H29V8c0,1-2,1-2,0V2c0-1,2-1,2,0V4h4c-.82,0,2,0,2,3V32C35,35,33,35,31,35Zm2-4V13H3V31c0,1,0,2,2,2H31C33,33,33,32.22,33,31Zm-5-6a2,2,0,1,1,2-2A2,2,0,0,1,28,25Zm0-6a2,2,0,1,1,2-2A2,2,0,0,1,28,19Zm-5,6a2,2,0,1,1,2-2A2,2,0,0,1,23,25Zm0-6a2,2,0,1,1,2-2A2,2,0,0,1,23,19ZM18,31a2,2,0,1,1,2-2A2,2,0,0,1,18,31Zm0-6a2,2,0,1,1,2-2A2,2,0,0,1,18,25Zm0-6a2,2,0,1,1,2-2A2,2,0,0,1,18,19ZM13,31a2,2,0,1,1,2-2A2,2,0,0,1,13,31Zm0-6a2,2,0,1,1,2-2A2,2,0,0,1,13,25Zm0-6a2,2,0,1,1,2-2A2,2,0,0,1,13,19ZM8,31a2,2,0,1,1,2-2A2,2,0,0,1,8,31Zm0-6a2,2,0,1,1,2-2A2,2,0,0,1,8,25ZM9,8C9,9,7,9,7,8V2C7,1,9,1,9,2V4H24V6H9Z"></path>
                            </svg>
                            <span>Calendar</span>
                        </div>
                        <div className="ico-sample">
                            <svg className="ico">
                                <path
                                    d="M19,34l9-15c.6-1-1.52-1.95-2-1L18,32,9.48,17.91A10.86,10.86,0,0,1,8,12.67a9.44,9.44,0,0,1,1.36-5A9.89,9.89,0,0,1,18,3a9.42,9.42,0,0,1,4.71,1A10.41,10.41,0,0,1,28,12H23a5.82,5.82,0,0,0-2-3,4.73,4.73,0,0,0-3.1-1.09c-4.76,0-4.86,4.76-4.86,4.76,0,4.62,4.91,4.76,4.86,4.76a.9.9,0,0,0,0-1.8,2.69,2.69,0,0,1-2-.82,2.89,2.89,0,0,1,0-4,2.83,2.83,0,0,1,4,0C21.37,12.3,21.24,14,23,14h5c1,0,2,0,2-2,0-4.25-2.61-7.33-6.1-9.36A11.29,11.29,0,0,0,18,1,12.12,12.12,0,0,0,7.61,6.89,12,12,0,0,0,6,13a10.9,10.9,0,0,0,1.68,5.93L17,34C17,34.94,19,35,19,34Z"></path>
                            </svg>
                            <span>Location</span>
                        </div>
                        <div className="ico-sample">
                            <svg className="ico">
                                <path
                                    d="M18,35,35,19V3a2,2,0,0,0-2-2L9.8,24.71,3,18V3H28c1,0,1-2,0-2H3A2.15,2.15,0,0,0,1,3V19l8,8a.64.64,0,0,0,1,0L33,4V18L18,32l-5-4c-1-1-2,0-2,1Z"></path>
                            </svg>
                            <span>Firewall / Security</span>
                        </div>
                        <div className="ico-sample">
                            <svg className="ico">
                                <path
                                    d="M36,2.5v31H0V2.5Zm-2,2H2v27H34ZM9,30.5H4v-2H9Zm-1-9H4V6.5H32v15H28v4H24v4H12v-4H8Zm19,7h5v2H27Z"></path>
                            </svg>
                            <span>Port</span>
                        </div>
                        <div className="ico-sample">
                            <svg className="ico">
                                <path
                                    d="M33,13.63V26.5c0,2,0,2-2,2H5c-2,0-2,0-2-2V9.5c0-2,1.09-2,2-2H32L19.55,20.09a2.76,2.76,0,0,1-3.2,0L6.83,10c-.68-.78-2.14.5-1.36,1.36l9.76,10.2a5,5,0,0,0,5.54,0L35,7.5v-1c0-1-1-1-2-1H3a2.15,2.15,0,0,0-2,2v21a2,2,0,0,0,2,2H33a2.15,2.15,0,0,0,2-2l0-14.87C35,12.48,33,12.48,33,13.63Zm-6.56,6.46,4,4.32c.77.68-.59,2.22-1.36,1.36l-4-4.32C24.38,20.64,25.74,19.28,26.45,20.09Zm-15.64,1.5L7.17,25.33c-.9.9-1.87-.75-1.36-1.26l3.64-3.74C10.17,19.61,11.46,20.69,10.81,21.59Z"></path>
                            </svg>
                            <span>Email</span>
                        </div>
                        <div className="ico-sample">
                            <svg className="ico">
                                <path
                                    d="M7,1,3,7V33a2.15,2.15,0,0,0,2,2H31a2.15,2.15,0,0,0,2-2V7L29,1ZM8,2h9V7H5ZM19,2h9l3,5H19ZM5,8H31V33H5Zm10,3c-1,0-1,1-1,2s0,2,1,2h6c1,0,1-1,1-2s0-2-1-2Zm1,1h4v2H16Z"></path>
                            </svg>
                            <span>Product</span>
                        </div>
                        <div className="ico-sample">
                            <svg className="ico">
                                <path
                                    d="M1.5,36V3h3V0h14V3h3v7h13V36Zm19-32h-3V1H5.5V4h-3V34h7V29h4v5h7Zm13,7h-12V34h12Zm-8,6h-2V15h2Zm0,5h-2V20h2Zm0,5h-2V25h2Zm0,5h-2V30h2Zm3-15h-2V15h2Zm0,5h-2V20h2Zm0,5h-2V25h2Zm0,5h-2V30h2Zm3-15h-2V15h2Zm0,5h-2V20h2Zm0,5h-2V25h2Zm0,5h-2V30h2Zm-18-9h1v3h-1Zm4-11h-2V9h2Zm0,7h-2V16h2Zm0,7h-2V23h2Zm0,6h-2V29h2Zm-4-16h1v3h-1Zm0-7h1v3h-1Zm-5,14h1v3h-1Zm0-7h1v3h-1Zm0-7h1v3h-1Zm-3,20h2v3h-2Zm0-6h2v3h-2Zm0-7h2v3h-2Zm0-7h2v3h-2Z"></path>
                            </svg>
                            <span>Company / Office</span>
                        </div>
                    </div>
                    <br />
                    <h3>Arrows + Sizes</h3>
                    <div className="cf">
                        <div className="ico-sample">
                            <svg className="ico ico-arrow up">
                                <path d="M17.89.71h-2l15,16H.89v2h30l-15,16h2l17-17Z" />
                            </svg>
                            <span>Normal</span>
                        </div>
                        <div className="ico-sample">
                            <svg className="ico ico-arrow down">
                                <path d="M17.89.71h-2l15,16H.89v2h30l-15,16h2l17-17Z" />
                            </svg>
                            <span>Normal</span>
                        </div>
                        <div className="ico-sample">
                            <svg className="ico ico-arrow left">
                                <path d="M17.89.71h-2l15,16H.89v2h30l-15,16h2l17-17Z" />
                            </svg>
                            <span>Normal</span>
                        </div>
                        <div className="ico-sample">
                            <svg className="ico ico-arrow">
                                <path d="M17.89.71h-2l15,16H.89v2h30l-15,16h2l17-17Z" />
                            </svg>
                            <span>Normal</span>
                        </div>
                        <div className="ico-sample">
                            <svg className="ico ico-arrow up size-s">
                                <path d="M17.89.71h-2l15,16H.89v2h30l-15,16h2l17-17Z" />
                            </svg>
                            <span>Small</span>
                        </div>
                        <div className="ico-sample">
                            <svg className="ico ico-arrow down size-s">
                                <path d="M17.89.71h-2l15,16H.89v2h30l-15,16h2l17-17Z" />
                            </svg>
                            <span>Small</span>
                        </div>
                        <div className="ico-sample">
                            <svg className="ico ico-arrow left size-s">
                                <path d="M17.89.71h-2l15,16H.89v2h30l-15,16h2l17-17Z" />
                            </svg>
                            <span>Small</span>
                        </div>
                        <div className="ico-sample">
                            <svg className="ico ico-arrow size-s">
                                <path d="M17.89.71h-2l15,16H.89v2h30l-15,16h2l17-17Z" />
                            </svg>
                            <span>Small</span>
                        </div>
                        <div className="ico-sample">
                            <svg className="ico ico-arrow up size-m">
                                <path d="M17.89.71h-2l15,16H.89v2h30l-15,16h2l17-17Z" />
                            </svg>
                            <span>Medium</span>
                        </div>
                        <div className="ico-sample">
                            <svg className="ico ico-arrow down size-m">
                                <path d="M17.89.71h-2l15,16H.89v2h30l-15,16h2l17-17Z" />
                            </svg>
                            <span>Medium</span>
                        </div>
                        <div className="ico-sample">
                            <svg className="ico ico-arrow left size-m">
                                <path d="M17.89.71h-2l15,16H.89v2h30l-15,16h2l17-17Z" />
                            </svg>
                            <span>Medium</span>
                        </div>
                        <div className="ico-sample">
                            <svg className="ico ico-arrow size-m">
                                <path d="M17.89.71h-2l15,16H.89v2h30l-15,16h2l17-17Z" />
                            </svg>
                            <span>Medium</span>
                        </div>

                        <div className="ico-sample">
                            <svg className="ico ico-arrow up">
                                <path d="M10.5,1h-2l16,17L8.5,35h2l17-17Z" />
                            </svg>
                            <span>Normal</span>
                        </div>
                        <div className="ico-sample">
                            <svg className="ico ico-arrow down">
                                <path d="M10.5,1h-2l16,17L8.5,35h2l17-17Z" />
                            </svg>
                            <span>Normal</span>
                        </div>
                        <div className="ico-sample">
                            <svg className="ico ico-arrow left">
                                <path d="M10.5,1h-2l16,17L8.5,35h2l17-17Z" />
                            </svg>
                            <span>Normal</span>
                        </div>
                        <div className="ico-sample">
                            <svg className="ico ico-arrow">
                                <path d="M10.5,1h-2l16,17L8.5,35h2l17-17Z" />
                            </svg>
                            <span>Normal</span>
                        </div>
                        <div className="ico-sample">
                            <svg className="ico ico-arrow up size-xs">
                                <path d="M10.5,1h-2l16,17L8.5,35h2l17-17Z" />
                            </svg>
                            <span>XSmall, Sort Up</span>
                        </div>
                        <div className="ico-sample">
                            <svg className="ico ico-arrow down size-xs">
                                <path d="M10.5,1h-2l16,17L8.5,35h2l17-17Z" />
                            </svg>
                            <span>XSmall, Sort Down</span>
                        </div>
                        <div className="ico-sample">
                            <svg className="ico ico-arrow left size-xs">
                                <path d="M10.5,1h-2l16,17L8.5,35h2l17-17Z" />
                            </svg>
                            <span>XSmall</span>
                        </div>
                        <div className="ico-sample">
                            <svg className="ico ico-arrow  size-xs">
                                <path d="M10.5,1h-2l16,17L8.5,35h2l17-17Z" />
                            </svg>
                            <span>XSmall</span>
                        </div>
                    </div>
                    <br />
                    <p><em>Anything you see elsewhere in the portal but not listed here means the icon is not
                        finished/final.</em></p>
                    <h3>Small, x2, x3 Scale Examples</h3>
                    <p>Using <em className="code">size-s</em>, <em className="code">size-m</em>, <em
                        className="code">size-l</em> CSS class:</p>
                    <div className="cf">
                        <div className="ico-sample scale-example">
                            <svg className="ico size-s">
                                <path
                                    d="M32.89,18.71v-2h2v2Zm-4-2h2v2h-2Zm-4,12h2v2h-2Zm0-12h2v2h-2Zm-4,12h2v2h-2Zm0-12h2v2h-2Zm0-12h2v2h-2Zm-4,24h2v2h-2Zm0-12h2v2h-2Zm0-12h2v2h-2Zm-4,24h2v2h-2Zm0-12h2v2h-2Zm0-12h2v2h-2Zm-12,20h10v10H.89Zm2,8h6v-6h-6Zm-2-20h10v10H.89Zm2,8h6v-6h-6Zm-2-20h10v10H.89Zm2,8h6v-6h-6Z" />
                            </svg>
                        </div>
                        <div className="ico-sample scale-example">
                            <svg className="ico size-m">
                                <path
                                    d="M32.89,18.71v-2h2v2Zm-4-2h2v2h-2Zm-4,12h2v2h-2Zm0-12h2v2h-2Zm-4,12h2v2h-2Zm0-12h2v2h-2Zm0-12h2v2h-2Zm-4,24h2v2h-2Zm0-12h2v2h-2Zm0-12h2v2h-2Zm-4,24h2v2h-2Zm0-12h2v2h-2Zm0-12h2v2h-2Zm-12,20h10v10H.89Zm2,8h6v-6h-6Zm-2-20h10v10H.89Zm2,8h6v-6h-6Zm-2-20h10v10H.89Zm2,8h6v-6h-6Z" />
                            </svg>
                        </div>
                        <div className="ico-sample scale-example">
                            <svg className="ico size-l">
                                <path d="M32.89,18.71v-2h2v2Zm-4-2h2v2h-2Zm-4,12h2v2h-2Zm0-12h2v2h-2Zm-4,12h2v2h-2Zm0-12h2v2h-2Zm0-12h2v2h-2Zm-4,24h2v2h-2Zm0-12h2v2h-2Zm0-12h2v2h-2Zm-4,24h2v2h-2Zm0-12h2v2h-2Zm0-12h2v2h-2Zm-12,20h10v10H.89Zm2,8h6v-6h-6Zm-2-20h10v10H.89Zm2,8h6v-6h-6Zm-2-20h10v10H.89Zm2,8h6v-6h-6Z" />
                            </svg>
                        </div>
                    </div>
                    <p>Extra small <em className="code">size-xs</em> is also available but most do not render well at that tiny size so try to avoid.</p>
                    <h3>Icon Link</h3>
                    <p>An icon that acts as a link has the <em className="code">ico-link</em> + <em
                        className="code">light-ico</em> class:</p>
                    <div className="cf">
                        <div className="ico-sample-alt">
                            <a className="ico-link light-ico" href="#">
                                <svg className="ico">
                                    <path
                                        d="M32.89,18.71v-2h2v2Zm-4-2h2v2h-2Zm-4,12h2v2h-2Zm0-12h2v2h-2Zm-4,12h2v2h-2Zm0-12h2v2h-2Zm0-12h2v2h-2Zm-4,24h2v2h-2Zm0-12h2v2h-2Zm0-12h2v2h-2Zm-4,24h2v2h-2Zm0-12h2v2h-2Zm0-12h2v2h-2Zm-12,20h10v10H.89Zm2,8h6v-6h-6Zm-2-20h10v10H.89Zm2,8h6v-6h-6Zm-2-20h10v10H.89Zm2,8h6v-6h-6Z" />
                                </svg>
                            </a>
                        </div>
                    </div>
                    <p><em>Font Glyph version will be made available at some point.</em></p>
                </div>
            : null}
        </Fragment>
    );
}

export default GoodieIcons;
