/**
 * Created by Rochaus on 1/20/2021.
 */
import React from 'react';
import {NavLink} from 'react-router-dom';

const TabsTroubleTicketsSumary = () => {
    return (
        <div className="tab-nav cf">
            <NavLink className="tab-opt qm-summary" to="/Ticket/Create" activeClassName="active">
                <span>Create Ticket</span>
            </NavLink>
            <NavLink className="tab-opt qm-create" to="/Network/TroubleTicketsSummary" activeClassName="active">
                <span>Trouble Ticket Summary</span>
            </NavLink>
        </div>
    );
}

export default TabsTroubleTicketsSumary;
