import {
	RESET_ACTION,
	TC_AUTHORIZATION,
	TC_CIRCUIT_ADDRESS,
    TC_CIRCUIT_LIST_COUNT,
    TC_CIRCUIT_SEARCH,
	TC_CIRCUIT_DETAILS,
	TC_CITY_A,
	TC_CITY_Z,
	TC_COMPANY_NAME,
	TC_CONTACT_LOCAL,
	TC_CONTACT_PRIMARY,
	TC_CUSTOMER_PERSONAL_ACCESS,
	TC_CUSTOMER_TICKET_NUMBER,
	TC_DATE_OF_CIRCUIT_DOWN,
	TC_DATE_OF_DURATION_FROM,
	TC_DATE_OF_DURATION_TO,
	TC_DATE_OF_PACKETLOSS,
	TC_DAY_FRI,
	TC_DAY_MON,
	TC_DAY_SAT,
	TC_DAY_SUN,
	TC_DAY_THUR,
	TC_DAY_TUES,
	TC_DAY_WED,
	TC_DESCRIPTION_OF_PROBLEM,
	TC_DISPATCH_AUTHORIZATION,
	TC_ERROR_MSG,
	TC_ERROR_RATE,
	TC_FIBERS_ASSOCIATED,
	TC_INTRUSIVE_FROM,
	TC_INTRUSIVE_FROM_HRS,
	TC_INTRUSIVE_TESTING_AUTHORIZED,
	TC_INTRUSIVE_TO,
	TC_INTRUSIVE_TO_HRS,
    TC_INTRUSIVE_TZ,
	TC_IRU_NUMBER,
	TC_L_CONTACT,
	TC_L_CONTACT_EMAIL,
	TC_L_CONTACT_ID,
	TC_L_CONTACT_PHONE,
	TC_L_CONTACT_PHONE_EXT,
	TC_L_PHONE_TYPE,
	TC_L_UPDATE_EMAIL,
	TC_LOC_A,
	TC_LOC_Z,
	TC_MON_TO_FRI,
	TC_NUMBER_OF_FIBERS,
	TC_NUMBER_OF_SITES,
	TC_OPTIONS,
	TC_OTDR_RESULTS,
    TC_CONTACT_LIST,
	TC_P_CONTACT,
	TC_P_CONTACT_EMAIL,
	TC_P_CONTACT_ID,
	TC_P_CONTACT_PHONE,
	TC_P_CONTACT_PHONE_EXT,
	TC_P_PHONE_TYPE,
	TC_P_UPDATE_EMAIL,
	TC_PERCENTAGE_LOSS,
	TC_POWER_VERIFIED,
	TC_PRODUCTS,
	TC_PULL_PERFORMANCE_MONITORING,
	TC_SELECT_DATE,
	TC_SITES_OUT,
	TC_STATE_A,
	TC_STATE_Z,
	TC_STEP_CODE,
	TC_TIME_FROM,
	TC_TIME_OF_BOUNCE,
	TC_TIME_OF_BOUNCEHRS,
    TC_BOUNCE_TZ,
	TC_TIME_OF_CIRCUIT_DOWNHRS,
    TC_CIRCUIT_DOWN_TZ,
	TC_TIME_OF_DURATION_FROMHRS,
	TC_TIME_OF_DURATION_TOHRS,
	TC_TIME_OF_PACKETLOSSHRS,
	TC_TIME_TO,
	TC_TROUBLE_DETAILS,
	TC_TURN_UP,
	TC_TYPE_OF_ERRORS,
	TC_ZIP_A,
	TC_ZIP_Z
} from "../actions/types/actionTypes";

const initialState = {
    activeStepCode: 1,
    selectedProduct: {},
    selectedProductType: {},
    selectedCircuitListCount: "",
    selectedCircuitSearch: "",
    selectedCircuitValue: '',
    selectedCircuitAddress: {
        ckt_gaid: '',
        ckt_designId: '',
        ckt_type: '',
        ckt_status: '',
        ckt_name: ''
    },
    troubleDetails: {
        customerTicketNumber: '',
        powerVerified: null,
        descriptionOfProblem: '',
        companyName: 'Transport(JG)',
        timeOfBounce: '',
        dispatchAuthorization: null,
        intrusiveTestingAuthorized: null,
        turnUp: null,
        timeOfBounceHrs: '',
        typeOfErrors: '',
        errorRate: '',
        dateOfcircuitDown: '',
        timeOfcircuitDownHrs: '',
        dateOfdurationFrom: '',
        timeOfdurationFromHrs: '',
        dateOfdurationTo: '',
        timeOfdurationToHrs: '',
        pullPerformanceMonitoring: null,
        numberOfSites: '',
        customerPersonalAccess: null,
        dateOfpacketLoss: '',
        timeOfpacketLossHrs: '',
        percentageLoss: '',
        sitesOut: null,
        LocA: '',
        cityA: '',
        stateA: '',
        zipA: '',
        LocZ: '',
        cityZ: '',
        stateZ: '',
        zipZ: '',
        iruNumber: '',
        otdrResults: '',
        numberOfFibers: '',
        fibersAssociated: '',
        intrusiveFrom: '',
        intrusiveFromHrs: '',
        intrusiveTo: '',
        intrusiveToHrs: '',
        intrusiveTz: 'EST',
        circuitDownTz: 'EST',
        bounceTimeTz: 'EST'
    },
    contactList: [],
    localDetails: {
        contact: '',
        contactId: '',
        contactPhone: '',
        contactPhoneExt: '',
        phoneType: '',
        receiveUpdateEmail: true,
        contactEmail: ''
    },
    primaryDetails: {
        contact: '',
        contactId: '',
        contactPhone: '',
        contactPhoneExt: '',
        phoneType: '',
        receiveUpdateEmail: true,
        contactEmail: ''
    },
    authorization: {
        selectDate: false,
        selectTimes_FromValue: {},
        selectTimes_ToValue: {},
        monToFri: false,
        dayMon: false,
        dayTues: false,
        dayWed: false,
        dayThur: false,
        dayFri: false,
        daySat: false,
        daySun: false
    },
    errorMsg: ''
}

export const ticketCreateReducer = (state = initialState, action) => {
    switch (action.type) {
        case TC_STEP_CODE: return {
            ...state,
            activeStepCode: action.payload
        }
        case TC_PRODUCTS: return {
            ...state,
            selectedProduct: action.payload
        }

        case TC_OPTIONS: return {
            ...state,
            selectedProductType: action.payload
        }

        case TC_CIRCUIT_LIST_COUNT: return {
            ...state,
            selectedCircuitListCount: action.payload
        }

        case TC_CIRCUIT_SEARCH: return {
            ...state,
            selectedCircuitSearch: action.payload
        }

        case TC_CIRCUIT_DETAILS: return {
            ...state,
            selectedCircuitValue: action.payload
        }

        case TC_CIRCUIT_ADDRESS: return {
            ...state,
            selectedCircuitAddress: action.payload
        }

        case TC_TROUBLE_DETAILS: return {
            ...state,
            troubleDetails: action.payload
        }

        case TC_CONTACT_LOCAL: return {
            ...state,
            localDetails: action.payload
        }

        case TC_CONTACT_PRIMARY: return {
            ...state,
            primaryDetails: action.payload
        }

        case TC_AUTHORIZATION: return {
            ...state,
            authorization: action.payload
        }

        case TC_CUSTOMER_TICKET_NUMBER: return {
            ...state,
            troubleDetails: {
                ...state.troubleDetails,
                customerTicketNumber: action.payload
            }
        }

        case TC_POWER_VERIFIED: return {
            ...state,
            troubleDetails: {
                ...state.troubleDetails,
                powerVerified: action.payload
            }
        }

        case TC_DESCRIPTION_OF_PROBLEM: return {
            ...state,
            troubleDetails: {
                ...state.troubleDetails,
                descriptionOfProblem: action.payload
            }
        }

        case TC_COMPANY_NAME: return {
            ...state,
            troubleDetails: {
                ...state.troubleDetails,
                companyName: action.payload
            }
        }

        case TC_TIME_OF_BOUNCE: return {
            ...state,
            troubleDetails: {
                ...state.troubleDetails,
                timeOfBounce: action.payload
            }
        }

        case TC_BOUNCE_TZ: return {
            ...state,
            troubleDetails: {
                ...state.troubleDetails,
                bounceTimeTz: action.payload
            }
        }

        case TC_DISPATCH_AUTHORIZATION: return {
            ...state,
            troubleDetails: {
                ...state.troubleDetails,
                dispatchAuthorization: action.payload
            }
        }

        case TC_INTRUSIVE_TESTING_AUTHORIZED: return {
            ...state,
            troubleDetails: {
                ...state.troubleDetails,
                intrusiveTestingAuthorized: action.payload
            }
        }

        case TC_INTRUSIVE_TZ: return {
            ...state,
            troubleDetails: {
                ...state.troubleDetails,
                intrusiveTz: action.payload
            }
        }

        case TC_TURN_UP: return {
            ...state,
            troubleDetails: {
                ...state.troubleDetails,
                turnUp: action.payload
            }
        }

        case TC_TIME_OF_BOUNCEHRS: return {
            ...state,
            troubleDetails: {
                ...state.troubleDetails,
                timeOfBounceHrs: action.payload
            }
        }

        case TC_TYPE_OF_ERRORS: return {
            ...state,
            troubleDetails: {
                ...state.troubleDetails,
                typeOfErrors: action.payload
            }
        }

        case TC_ERROR_RATE: return {
            ...state,
            troubleDetails: {
                ...state.troubleDetails,
                errorRate: action.payload
            }
        }

        case TC_DATE_OF_CIRCUIT_DOWN: return {
            ...state,
            troubleDetails: {
                ...state.troubleDetails,
                dateOfcircuitDown: action.payload
            }
        }

        case TC_TIME_OF_CIRCUIT_DOWNHRS: return {
            ...state,
            troubleDetails: {
                ...state.troubleDetails,
                timeOfcircuitDownHrs: action.payload
            }
        }

        case TC_CIRCUIT_DOWN_TZ: return {
            ...state,
            troubleDetails: {
                ...state.troubleDetails,
                circuitDownTz: action.payload
            }
        }

        case TC_DATE_OF_DURATION_FROM: return {
            ...state,
            troubleDetails: {
                ...state.troubleDetails,
                dateOfdurationFrom: action.payload
            }
        }

        case TC_TIME_OF_DURATION_FROMHRS: return {
            ...state,
            troubleDetails: {
                ...state.troubleDetails,
                timeOfdurationFromHrs: action.payload
            }
        }

        case TC_DATE_OF_DURATION_TO: return {
            ...state,
            troubleDetails: {
                ...state.troubleDetails,
                dateOfdurationTo: action.payload
            }
        }

        case TC_TIME_OF_DURATION_TOHRS: return {
            ...state,
            troubleDetails: {
                ...state.troubleDetails,
                timeOfdurationToHrs: action.payload
            }
        }

        case TC_PULL_PERFORMANCE_MONITORING: return {
            ...state,
            troubleDetails: {
                ...state.troubleDetails,
                pullPerformanceMonitoring: action.payload
            }
        }

        case TC_NUMBER_OF_SITES: return {
            ...state,
            troubleDetails: {
                ...state.troubleDetails,
                numberOfSites: action.payload
            }
        }

        case TC_CUSTOMER_PERSONAL_ACCESS: return {
            ...state,
            troubleDetails: {
                ...state.troubleDetails,
                customerPersonalAccess: action.payload
            }
        }

        case TC_DATE_OF_PACKETLOSS: return {
            ...state,
            troubleDetails: {
                ...state.troubleDetails,
                dateOfpacketLoss: action.payload
            }
        }

        case TC_TIME_OF_PACKETLOSSHRS: return {
            ...state,
            troubleDetails: {
                ...state.troubleDetails,
                timeOfpacketLossHrs: action.payload
            }
        }

        case TC_PERCENTAGE_LOSS: return {
            ...state,
            troubleDetails: {
                ...state.troubleDetails,
                percentageLoss: action.payload
            }
        }

        case TC_SITES_OUT: return {
            ...state,
            troubleDetails: {
                ...state.troubleDetails,
                sitesOut: action.payload
            }
        }

        case TC_LOC_A: return {
            ...state,
            troubleDetails: {
                ...state.troubleDetails,
                LocA: action.payload
            }
        }

        case TC_CITY_A: return {
            ...state,
            troubleDetails: {
                ...state.troubleDetails,
                cityA: action.payload
            }
        }

        case TC_STATE_A: return {
            ...state,
            troubleDetails: {
                ...state.troubleDetails,
                stateA: action.payload
            }
        }

        case TC_ZIP_A: return {
            ...state,
            troubleDetails: {
                ...state.troubleDetails,
                zipA: action.payload
            }
        }

        case TC_LOC_Z: return {
            ...state,
            troubleDetails: {
                ...state.troubleDetails,
                LocZ: action.payload
            }
        }

        case TC_CITY_Z: return {
            ...state,
            troubleDetails: {
                ...state.troubleDetails,
                cityZ: action.payload
            }
        }

        case TC_STATE_Z: return {
            ...state,
            troubleDetails: {
                ...state.troubleDetails,
                stateZ: action.payload
            }
        }

        case TC_ZIP_Z: return {
            ...state,
            troubleDetails: {
                ...state.troubleDetails,
                zipZ: action.payload
            }
        }

        case TC_IRU_NUMBER: return {
            ...state,
            troubleDetails: {
                ...state.troubleDetails,
                iruNumber: action.payload
            }
        }

        case TC_OTDR_RESULTS: return {
            ...state,
            troubleDetails: {
                ...state.troubleDetails,
                otdrResults: action.payload
            }
        }

        case TC_NUMBER_OF_FIBERS: return {
            ...state,
            troubleDetails: {
                ...state.troubleDetails,
                numberOfFibers: action.payload
            }
        }

        case TC_FIBERS_ASSOCIATED: return {
            ...state,
            troubleDetails: {
                ...state.troubleDetails,
                fibersAssociated: action.payload
            }
        }

        case TC_INTRUSIVE_FROM: return {
            ...state,
            troubleDetails: {
                ...state.troubleDetails,
                intrusiveFrom: action.payload
            }
        }

        case TC_INTRUSIVE_FROM_HRS: return {
            ...state,
            troubleDetails: {
                ...state.troubleDetails,
                intrusiveFromHrs: action.payload
            }
        }

        case TC_INTRUSIVE_TO: return {
            ...state,
            troubleDetails: {
                ...state.troubleDetails,
                intrusiveTo: action.payload
            }
        }

        case TC_INTRUSIVE_TO_HRS: return {
            ...state,
            troubleDetails: {
                ...state.troubleDetails,
                intrusiveToHrs: action.payload
            }
        }

        case TC_CONTACT_LIST: return {
            ...state,
            contactList: action.payload
        }

        case TC_P_CONTACT: return {
            ...state,
            primaryDetails: {
                ...state.primaryDetails,
                contact: action.payload
            }
        }

        case TC_P_CONTACT_ID: return {
            ...state,
            primaryDetails: {
                ...state.primaryDetails,
                contactId: action.payload
            }
        }

        case TC_P_CONTACT_PHONE: return {
            ...state,
            primaryDetails: {
                ...state.primaryDetails,
                contactPhone: action.payload
            }
        }

        case TC_P_CONTACT_PHONE_EXT: return {
            ...state,
            primaryDetails: {
                ...state.primaryDetails,
                contactPhoneExt: action.payload
            }
        }

        case TC_P_PHONE_TYPE: return {
            ...state,
            primaryDetails: {
                ...state.primaryDetails,
                phoneType: action.payload
            }
        }

        case TC_P_UPDATE_EMAIL: return {
            ...state,
            primaryDetails: {
                ...state.primaryDetails,
                receiveUpdateEmail: action.payload
            }
        }

        case TC_P_CONTACT_EMAIL: return {
            ...state,
            primaryDetails: {
                ...state.primaryDetails,
                contactEmail: action.payload
            }
        }

        case TC_L_CONTACT: return {
            ...state,
            localDetails: {
                ...state.localDetails,
                contact: action.payload
            }
        }

        case TC_L_CONTACT_ID: return {
            ...state,
            localDetails: {
                ...state.localDetails,
                contactId: action.payload
            }
        }

        case TC_L_CONTACT_PHONE: return {
            ...state,
            localDetails: {
                ...state.localDetails,
                contactPhone: action.payload
            }
        }

        case TC_L_CONTACT_PHONE_EXT: return {
            ...state,
            localDetails: {
                ...state.localDetails,
                contactPhoneExt: action.payload
            }
        }

        case TC_L_PHONE_TYPE: return {
            ...state,
            localDetails: {
                ...state.localDetails,
                phoneType: action.payload
            }
        }

        case TC_L_UPDATE_EMAIL: return {
            ...state,
            localDetails: {
                ...state.localDetails,
                receiveUpdateEmail: action.payload
            }
        }

        case TC_L_CONTACT_EMAIL: return {
            ...state,
            localDetails: {
                ...state.localDetails,
                contactEmail: action.payload
            }
        }

        case TC_SELECT_DATE: return {
            ...state,
            authorization: {
                ...state.authorization,
                selectDate: action.payload
            }
        }

        case TC_TIME_FROM: return {
            ...state,
            authorization: {
                ...state.authorization,
                selectTimes_FromValue: {
                    ...state.authorization.selectTimes_FromValue,
                    [action.index]: action.payload
                }
            }
        }

        case TC_TIME_TO: return {
            ...state,
            authorization: {
                ...state.authorization,
                selectTimes_ToValue: {
                    ...state.authorization.selectTimes_ToValue,
                    [action.index]: action.payload
                }
            }
        }

        case TC_MON_TO_FRI: return {
            ...state,
            authorization: {
                ...state.authorization,
                monToFri: action.payload
            }
        }

        case TC_DAY_MON: return {
            ...state,
            authorization: {
                ...state.authorization,
                dayMon: action.payload
            }
        }

        case TC_DAY_TUES: return {
            ...state,
            authorization: {
                ...state.authorization,
                dayTues: action.payload
            }
        }

        case TC_DAY_WED: return {
            ...state,
            authorization: {
                ...state.authorization,
                dayWed: action.payload
            }
        }

        case TC_DAY_THUR: return {
            ...state,
            authorization: {
                ...state.authorization,
                dayThur: action.payload
            }
        }

        case TC_DAY_FRI: return {
            ...state,
            authorization: {
                ...state.authorization,
                dayFri: action.payload
            }
        }

        case TC_DAY_SAT: return {
            ...state,
            authorization: {
                ...state.authorization,
                daySat: action.payload
            }
        }

        case TC_DAY_SUN: return {
            ...state,
            authorization: {
                ...state.authorization,
                daySun: action.payload
            }
        }

        case TC_ERROR_MSG: return {
            ...state,
            errorMsg: action.payload
        }

        case RESET_ACTION:
            return initialState;

        default: return state;
    }
}
