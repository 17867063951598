import React, { useEffect, useState } from 'react';
import Loader from "../shared/Loader";
import style from './AsrMapping.module.css';
import InternalHelper from "../../services/InternalReportService";

const AsrMapping = () => {
	const [originalValues, setOriginalValues] = useState({ ebonded: '', asr_term: '', cpt_term: '' });
	const [mapPullDownArray, setMapPullDownArray] = useState([]);
	const [cptPullDownArray, setCptPullDownArray] = useState([]);
	const [formValues, setFormValues] = useState({ ebonded: '', asr_term: '', cpt_term: '' });
	const [loader, setLoader] = useState(false);
	const [successMsg, setSuccessMsg] = useState("");
	const [errorMsg, setErrorMsg] = useState("");

	useEffect(() => {
		loadMapping();
	}, []);

	const loadMapping = () => {
		setLoader(true);
		InternalHelper.loadAsrMapping().then(response => {
			if (response.data.result === 'SUCCESS') {
				setOriginalValues(response.data.initialValues);
				setFormValues(response.data.initialValues);
				setMapPullDownArray(response.data.mapPullDownArray);
				setCptPullDownArray(Object.values(response.data.cptPullDownArray));
			} else {
				setErrorMsg(response.data.error);
			}
			setLoader(false);
		}).catch(error => {
			setErrorMsg("An error occurred while fetching data.");
			setLoader(false);
		});
	};

	const handleInputChange = (e) => {
		const { name, value } = e.target;
		setFormValues({ ...formValues, [name]: value });

		if (name === 'ebonded') {
			const [asr_term, cpt_term] = value.split(' => ');
			setFormValues({ ebonded: value, asr_term, cpt_term });
			setOriginalValues({ ebonded: value, asr_term, cpt_term });
		}
	};

	const handleSubmit = (action) => {
		setLoader(true);
		setSuccessMsg("");
		setErrorMsg("");

		if (action === 'delete' && (originalValues.ebonded !== formValues.ebonded || originalValues.asr_term !== formValues.asr_term || originalValues.cpt_term !== formValues.cpt_term)) {
			setErrorMsg("Original and new values do not match. Please verify what you are deleting.");
			setLoader(false);
			return;
		}

		if ((formValues.asr_term === '' && formValues.cpt_term !== '') || (formValues.asr_term !== '' && formValues.cpt_term === '')) {
			setErrorMsg("ASR Term and CPT Term required.");
			setLoader(false);
			return;
		}

		InternalHelper.processAsrMapping(action, originalValues, formValues).then(response => {
			if (response.data.result === 'SUCCESS') {
				setSuccessMsg(response.data.message);
				loadMapping();
			} else {
				setErrorMsg(response.data.message);
			}
			setLoader(false);
		}).catch(error => {
			setErrorMsg("An error occurred while processing the request.");
			setLoader(false);
		});
	};

	return (
		<div className="midsection asrMapping abs z1">
			{loader && <Loader />}
			<div className="rel">
				<div className="wrap">
					<h1 className="page-title">ASR Mapping</h1>
					<div className="section">
						<div className="content">
							<div className={`sub-hdr alt0 mt-3`}>
								<h2 className={`m-0 `}>Mapping Details</h2>
							</div>
							{successMsg && <p className="text-success text-center">{successMsg}</p>}
							{errorMsg && <p className="text-danger text-center">{errorMsg}</p>}
							<div className='px-3 my-3'>
								<div className='row'>
									<div className='col-4'>
										<label className='labelText'>Mapping Key</label>
										<select name="ebonded" className="nice-select dark-select wide" value={formValues.ebonded} onChange={handleInputChange}>
											{Array.isArray(mapPullDownArray) && mapPullDownArray.map((item, index) => (
												<option key={index} value={item}>{item}</option>
											))}
										</select>
									</div>
									<div className='col-4'>
										<label className='labelText'>ASR Term</label>
										<input type="text" name="asr_term" className={`${style.inputFldText} w-100`} value={formValues.asr_term} onChange={handleInputChange} />
									</div>
									<div className='col-4'>
										<label className='labelText'>CPT Term</label>
										<select name="cpt_term" className="nice-select dark-select wide" value={formValues.cpt_term} onChange={handleInputChange}>
											{Array.isArray(cptPullDownArray) && cptPullDownArray.map((item, index) => (
												<option key={index} value={item}>{item}</option>
											))}
										</select>
									</div>
								</div>
							</div>
							<div className="text-center my-5">
								<button type="button" className={`ico-button lhs light-button ml-3 ${style.nextBtn}`} onClick={() => handleSubmit('add')}>
									Add
									<svg className="ico light-ico ico-arrow ml-2">
										<path d="M0,17H29L15,31l1,1L32,16,16,0,15,1,29,15H0Z"></path>
									</svg>
								</button>
								<button type="button" className={`ico-button lhs light-button ml-3 ${style.nextBtn}`} onClick={() => handleSubmit('update')}>
									Update
									<svg className="ico light-ico ico-arrow ml-2">
										<path d="M0,17H29L15,31l1,1L32,16,16,0,15,1,29,15H0Z"></path>
									</svg>
								</button>
								<button type="button" className={`ico-button lhs light-button ml-3 ${style.nextBtn}`} onClick={() => handleSubmit('delete')}>
									Delete
									<svg className="ico light-ico ico-arrow ml-2">
										<path d="M0,17H29L15,31l1,1L32,16,16,0,15,1,29,15H0Z"></path>
									</svg>
								</button>
							</div>
							<div className="info-text">
								<p>Select an existing ebonded ASR to CPT mapping from the left side pull down menu. This will place the corresponding ASR and CPT values in the editable fields to the right. Then select the desired action.</p>
								<p>Delete Selected - will remove the selected mapping from the table.</p>
								<p>Update Selected - will update the selected entry to the edited values.</p>
								<p>Add New Mapping - will add a new entry based on the edited values.</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default AsrMapping;