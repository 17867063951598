import { useCallback, useEffect, useState } from 'react';
import { ConsoleLogger } from "../../logger/ConsoleLogger";
import style from "./TicketDetails.module.css";
import FiberMappingHelper from '../../services/FiberMappingService';
import Loader from '../shared/Loader';
import { useSelector } from 'react-redux';
import CompanyDropdown from '../shared/CompanyDropdown';

const FiberMapping = (kmzToken = "") => {
	const logger = new ConsoleLogger({ level: process.env.REACT_APP_LOGGER_LEVEL, });
	const defaultLatlng = { 'lat': 39, 'lng': -98 };
	const google = window.google = window.google ? window.google : {};
	const user = localStorage.getItem('user');
	const [circuitList, setCircuitList] = useState([]);
	const [commsId, setCommsId] = useState("");
	const [searchText, setSearchText] = useState('');
	const [circuitListForSearch, setCircuitListForSearch] = useState([]);
	const [noCircuitsFound, setNoCircuitsFound] = useState(false);
	const [loader, setLoader] = useState(false);
	const isEmployee = localStorage.getItem("isEmployeeLoggedIn");
	const selectedInternalMpid = useSelector(state => state.internal.company?.value);
	const eid = localStorage.getItem('eid');

	var map;

	var styles = [
		{
			"stylers": [
				{ "hue": "#EEEEEE" },
				{ "saturation": -300 },
				{ "lightness": 20 }
			]
		}
	]

	const initMap = () => {
		map = new google.maps.Map(document.getElementById('map'), {
			center: defaultLatlng,
			zoom: 5,
			mapTypeId: "terrain"
		});

		map.setOptions({ styles: styles });
	}

	const getCircuitRouteIds = useCallback(() => {
		setLoader(true);
		FiberMappingHelper.getCircuitIds().then(response => {
			console.log(response.data);
			setCircuitList(response.data);
			setCircuitListForSearch(response.data);
			setCommsId(response.gaid); //commsId is the same for all circuits
			if (response.data?.length === 0) {
				setNoCircuitsFound(true);
			} else {
				setNoCircuitsFound(false);
			}
			setLoader(false);
		}).catch(error => {
			console.log(`getCircuitOptions Error: ${error}`);
			setLoader(false);
		})
	}, [])

	useEffect(() => {
		initMap();
		if (isEmployee) {
			if (selectedInternalMpid) {
				getCircuitRouteIds();
			}
		} else {
			getCircuitRouteIds();
		}
	}, [getCircuitRouteIds, isEmployee, selectedInternalMpid])

	const kmlInit = async (kmlLayerMultiple) => {
		await initMap();
		kmlLayerMultiple.forEach(function (url) {
			var kmlLayer = new google.maps.KmlLayer(url, {
				suppressInfoWindows: true,
				preserveViewport: true,
				map: map
			});

			kmlLayer.addListener(kmlLayer, 'defaultviewport_changed', function () {
				var bounds = kmlLayer.getDefaultViewport();
				map.setCenter(bounds.getCenter());
			});
		})


	}

	const circuitHandle = (event, circuit) => {
		let isChecked = event.target.checked;
		let updatedCircuitListData = [];
		updatedCircuitListData = { ...circuitList, [event.target.id]: { ...circuitList[event.target.id] } }
		updatedCircuitListData[event.target.id].checked = isChecked
		setCircuitListForSearch(updatedCircuitListData);
		setCircuitList(updatedCircuitListData);
		if (updatedCircuitListData[event.target.id].url === "" && isChecked) {
			setLoader(true);
			FiberMappingHelper.getSingleCircuitKmz(circuit.gaid, circuit.designId).then(response => {
				console.log(`getSingleKmz response: ${response.data}`);
				if (response.data.result === "SUCCESS") {
					updatedCircuitListData[circuit.designId].url = response.data.url
					setLoader(false);
				} else {
					updatedCircuitListData[circuit.designId].error = response.data.error
					setLoader(false);
				}
				setCircuitListForSearch(updatedCircuitListData);
				setCircuitList(updatedCircuitListData);
				displayKmz(updatedCircuitListData);
			}).catch(error => {
				console.log(`generateKmzURL error: ${error}`);
				setLoader(false);
			})

		} else {
			setCircuitListForSearch(updatedCircuitListData);
			setCircuitList(updatedCircuitListData);
			displayKmz(updatedCircuitListData);
		}
	}

	const displayKmz = (circuits) => {
		let kmzUrlList = [];
		Object.entries(circuits).forEach(([, val]) => {
			if (val.checked && val.url) {
				kmzUrlList.push(val.url)
			}
		})

		console.log(JSON.stringify(kmzUrlList));

		kmlInit(kmzUrlList);
	}

	//Function to search circuit list for a specific circuit
	const searchHandle = (event) => {
		let searchValue = event.target.value;
		setSearchText(searchValue);
		let filterCircuitList = Object.entries(circuitListForSearch).filter(([, val]) => {
			return val.label.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase());
		});
		filterCircuitList = Object.fromEntries(filterCircuitList);
		setCircuitList(filterCircuitList);
	}

	const downloadMultiKMZ = (circuits) => {
		let selectedCircuits = [];
		Object.entries(circuits).forEach(([, val]) => {
			if (val.checked) {
				selectedCircuits.push(val.designId);
			}
		})

		setLoader(true);
		FiberMappingHelper.getMultiKmz(commsId, selectedCircuits).then(response => {
			console.log(`getMultiKmz response: ${response.data}`);
			if (response.data.result === "SUCCESS") {
				logger.info("Successfully got a token for the kmz that contains all selected circuits");
				//downloading excel file
				let selectedCircuitsKmzlink = document.createElement('a');
				selectedCircuitsKmzlink.href = response.data.url;
				selectedCircuitsKmzlink.download = response.data.filename;
				selectedCircuitsKmzlink.click();
				setLoader(false);
			} else {
				logger.info("Error getting token for selected circuit kmz download file");
				setLoader(false);
			}
		}).catch(error => {
			console.log(`getMultiKmz error: ${error}`);
			setLoader(false);
		})
	}

	const downloadAllKMZ = () => {
		console.log("Download All KMZ Action");
		setLoader(true);
		FiberMappingHelper.getAllCircuitsKmz(commsId).then(response => {
			console.log(`getAllCircuitKmz response: ${response.data}`);
			if (response.data.result === "SUCCESS") {
				logger.info("Successfully got a token for the kmz that contains all circuits");
				let allCircuitKmzlink = document.createElement('a');
				allCircuitKmzlink.href = response.data.url;
				allCircuitKmzlink.download = response.data.filename;
				allCircuitKmzlink.click();
				setLoader(false);
			} else {
				logger.info("Error getting token for all circuit kmz download file");
				setLoader(false);
			}
		}).catch(error => {
			console.log(`getAllCircuitKmz error: ${error}`);
			setLoader(false);
		})
	}

	return (
		<>
			{loader ? <Loader /> : null}
			<div className="midsection employee abs z1">
				<div className="rel">
					<div className="wrap">
						<h1 className="page-title mb-0">Circuit Route Maps</h1>
						{isEmployee ? <CompanyDropdown /> : null}
						<h6 className="page-title mt-0 mb-5">Select existing Wave, Dark Fiber, or Managed Spectrum circuits below to show their fiber routes and download the KMZ file.</h6>
						<div className="section">
							<div className="content">
								<div className="d-flex">
									{user === "Wholesale_Demo" || eid === "e0187647" || eid === "e0176276" ?
										<div className="w-25">
											<div class={`dropdown-menu show px-2 ${style.dropdownMenu}`}>
												<div class="form-group">
													<input type="text" class="form-control" id="" placeholder="Search" value={searchText} onChange={(e) => { searchHandle(e) }} disabled={noCircuitsFound ? "disabled" : ""} />
												</div>

												{Object.entries(circuitList).map(([key, val]) => (
													<>
														<div key={key} class="form-check">
															<input type="checkbox" class={`form-check-input ${style.checkbox}`} id={val.designId} checked={val.checked} onChange={(e) => { circuitHandle(e, val) }} />
															<label class={`form-check-label ${style.checkboxLabel}`} for={val.designId}>
																{val.label}<span className="text-danger">{val.error}</span>
															</label>
														</div>						
													</>
												))}
												{noCircuitsFound ? <p className="text-danger">No Available Circuits</p> : null}
											</div>
										</div> : null
									}

									{user === "Wholesale_Demo" || eid === "e0187647" || eid === "e0176276" || eid === "e0155951" ?
										<div className="w-75">
											<div className="d-flex">
												<p>
													Select up to ten (10) circuits to display on map.<br></br>
													Click <b>Download KMZ</b> to download KMZ of circuits selected.<br></br>
													Click <b>Download All Circuits KMZ</b> to download all circuits listed below without the need to select them.
												</p>
												<div className="w-50">
													<div className='ml-3'>
														<button className='light-button py-1 px-3' onClick={() => downloadMultiKMZ(circuitList)}>Download KMZ</button>
														<button className='light-button py-1 px-3 ml-2' onClick={() => downloadAllKMZ()}>Download All Circuits KMZ</button>
													</div>
												</div>
											</div>
											<div className="map" id="map" style={{ height: '85vh', width: "100%" }} />
										</div> :
										<div className="w-100">
											<p>Feature still in development</p>
											<div className="map" id="map" style={{ height: '85vh', width: "100%" }} />
										</div>
									}
								</div>

							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default FiberMapping;
