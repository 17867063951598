import { useEffect, useState, useMemo } from "react";
import ViewportWarning from "../../shared/ViewportWarning";
import TabsQuote from "../includes/TabsQuote";
import style from "../QuoteCreate.module.css";
import QuoteHelper from "../../../services/QuoteService";
import Loader from "../../shared/Loader";
import { ConsoleLogger } from "../../../logger/ConsoleLogger";
import CompanyDropdown from "../../shared/CompanyDropdown";
import { useSelector } from 'react-redux';
import BroadbandBulk from "./BroadbandBulk";
import BroadbandHelper from "../../../services/BroadbandServices";

const BulkCreate = (props) => {
	const logger = useMemo(() => new ConsoleLogger({ level: process.env.REACT_APP_LOGGER_LEVEL, }), []);
	const [loader, setLoader] = useState(false); // loader
	const [contactArray, setContactArray] = useState([]); // build contact Array
	const [newContactFlag, setNewContactFlag] = useState(false);
	const [productArray, setProductArray] = useState([]); // build product Array
	const [rowCount, setRowCount] = useState("");
	const [displayBulkForm, setDisplayBulkForm] = useState(false);
	const [errorMsg, setErrorMsg] = useState("");
	const [projectDetails, setProjectDetails] = useState({"projectName": "", "contact": "", "product": "", "phone": "", "email": ""});
	const [broadbandErrorMsg, setBroadbandErrorMsg] = useState("");
	const isCustomer = localStorage.getItem('isLoggedIn');
	const isEmployee = localStorage.getItem('isEmployeeLoggedIn');
	const selectedInternalMpid = useSelector(state => state.internal.company?.value);

	useEffect(() => {
		//Check if ILEC Broadband product is available
		if(isCustomer || (isEmployee && selectedInternalMpid)) {
			setLoader(true);
			BroadbandHelper.broadbandProductCheck().then(response => {
				logger.info(`Broadband Product Check: ${(response.data)}`);
				if (response.data) {
					logger.info("Broadband is available");
					setBroadbandErrorMsg("");
				} else {
					logger.info(`Broadband is not available`);
					if(isEmployee){
						setBroadbandErrorMsg("Broadband Bulk Quoting is not available");
					} else {
						let user = localStorage.getItem("user");
						setBroadbandErrorMsg(`Your account '${user}' does not have access to the Broadband Bulk Quoting. If you believe this to be in error, please contact your site administrator or your Windstream Account Team for more information.`);
					}
				}
				setLoader(false);
			}).catch(error => {
				logger.error(`broadbandProductCheck error: ${error}`);
				setLoader(false);
			})
		}

	},[isCustomer, isEmployee, logger, selectedInternalMpid])

	useEffect(() => {
		setLoader(true);
		QuoteHelper.getProductMenu("bulk").then(res => {
			setProductArray(res.data);
			setLoader(false);
		}).catch((error) => {
			logger.error("getProductMenu ERROR: " + error)
			setLoader(false);
		});
		if (isEmployee) {
			if (selectedInternalMpid) {
				//Populates Contact Dropdown if user is Employee we need to wait for mpid
				setLoader(true);
				QuoteHelper.getQuoteContact().then(resp => {
					setContactArray(resp.data);
					setLoader(false);
				}).catch((error) => {
					logger.error("Get Contact Error: " + error);
					setLoader(false);
				});
			}
		} else {
			//Populates Contact Dropdown
			setLoader(true);
			QuoteHelper.getQuoteContact().then(resp => {
				setContactArray(resp.data);
				setLoader(false);
			}).catch((error) => {
				logger.error("Get Contact Error: " + error);
				setLoader(false);
			});
		}
	}, [isEmployee, logger, selectedInternalMpid]);

	const generateBulkRows = () => {
		if(Number(rowCount) > 0 && projectDetails?.product && projectDetails?.contact && projectDetails?.projectName && projectDetails?.phone && projectDetails?.email) {
			setDisplayBulkForm(true);
			//Disable the ability to change the number of rows once form is generated
			document.getElementById("rowCount").disabled = true;
		} else if(!projectDetails?.projectName){
			setErrorMsg("Please enter project name");
			return;
		} else if(!projectDetails?.contact){
			setErrorMsg("Please select contact");
			return;
		} else if(!projectDetails?.product){
			setErrorMsg("Please select product");
			return
		} else {
			setErrorMsg("Please enter number of rows to initialize bulk form");
			return
		}
	}

	//Handler for input changes to the project detail fields
	const inputChange = (event) => {
		setErrorMsg("");
		switch (event.target.id) {
			case "projectName":
				setProjectDetails({ ...projectDetails, projectName: event.target.value });
				break;
			case "name":
				setProjectDetails({ ...projectDetails, name: event.target.value });
				break;
			case "phone":
				setProjectDetails({ ...projectDetails, phone: event.target.value });
				break;
			case "email":
				setProjectDetails({ ...projectDetails, email: event.target.value });
				break;
			case "product":
				setProjectDetails({ ...projectDetails, product: event.target.value });
				setDisplayBulkForm(false);
				setRowCount("");
				//if the product changes we need to enable the ability to pick the number of rows
				document.getElementById("rowCount").disabled = false;
				break;
			case "rowCount":
				setRowCount(event.target.value);
				break;
			default:
				break;
		}
	}

	//Handles Contact Dropdown and calls helper to auto fill contact
	const contactHandler = (ev) => {
		let cp_id = ev.target.value;
		if (cp_id === "new") {
			setProjectDetails({ ...projectDetails, contact: cp_id });
			setNewContactFlag(true);
		} else {
			autoFillContact(cp_id);
		}

	}

	//Helper function that calls DB to pull the contact info and auto fill the phone and email fields
	const autoFillContact = (value) => {
		setLoader(true);
		QuoteHelper.getQuoteContactInfo(value).then(resp => {
			setProjectDetails({...projectDetails, contact: value, phone: resp.data.phone, email: resp.data.email });
			setLoader(false);
		}).catch((error) => {
			logger.error(`Get Contact info Error: ${error}`);
			setLoader(false);
		});
	}

	return (
		<div className="midsection qm-upload abs z1">
			{loader ? <Loader /> : null}
			<div className="rel">
				<ViewportWarning />
				<div className="wrap">
					<h1 className="page-title">{isEmployee ? "Employee Bulk Tool" : "Bulk Tool"}</h1>
					{isEmployee ? <CompanyDropdown /> : null}
					<div className="section">
						{/* <TabsQuoteManager/> */}
						<TabsQuote />
						<div className={`content ${style.contentQueryPage}`}>
							{!broadbandErrorMsg ?
								<>
									<div className="row">
										<div className="col-12">
											<p className={` ${style.lableTextS} ${style.textBlack} `}><span className={style.mandatory}>*</span> Denotes a required field</p>
										</div>
									</div>
									<div className={`sub-hdr alt ${style.subHeader} `}>
										<div className="nib">
											<div className="the-notch">
												<div className="notch-fg">
													<svg>
														<path d="M35.81,26.28l-13,10v-5C-10.47,28.68-1,9.16,11.79,0c0,7.28,0,9.13,0,13.28s3.18,7.92,11,9v-5Z"></path>
													</svg>
												</div>
											</div>
										</div>
										<h2 className={style.sectionTitle}>Project Details</h2>
									</div>
									<div className={`row ${style.rowGrp}`}>
										<div className="col-12 col-sm-4 col-md-3">
											<label className={style.lableTextS}>Project Name <span className={style.mandatory}>*</span> </label>
											<input type="text" className={style.inputFld} id="projectName" value={projectDetails?.projectName} maxLength='63' onChange={inputChange}></input>
										</div>
									</div>
									<div className={`row ${style.rowGrp} `}>
										<div className="col-12 col-sm-4 col-md-3">
											<label className={style.labelSelectRequiredFld}>Name <span className={style.mandatory}>*</span></label>
											{newContactFlag ?
												<input type="text" id="name" className={style.inputFld} onChange={inputChange}></input>
												:
												<select className={`form-control ${style.selectFld} ${style.requiredFld} `} id="contact" value={projectDetails?.contact} onChange={contactHandler}>
													{contactArray.map((obj) => {
														return <option value={obj.id}>{obj.name}</option>
													})}
												</select>
											}
										</div>
										<div className="col-12 col-sm-4 col-md-3">
											<label className={style.lableTextS}>Phone<span className={style.mandatory}>*</span></label>
											<input type="text" className={style.inputFld} maxLength="10" id="phone" onChange={inputChange} value={projectDetails?.phone}></input>
										</div>
										<div className="col-12 col-sm-4 col-md-3">
											<label className={style.lableTextS}>Email <span className={style.mandatory}>*</span></label>
											<input type="text" className={style.inputFld} id="email" onChange={inputChange} value={projectDetails?.email}></input>
										</div>
									</div>
									<div className={`row ${style.rowGrp}`}>
										<div className="col-6 col-sm-4 col-md-3">
											<label className={style.labelSelectRequiredFld}>Product <span className={style.mandatory}>*</span></label>
											<select className={` form-control  ${style.selectFld} `} id="product" value={projectDetails?.product} onChange={inputChange}>
												{/*Commenting this out until we go back an add the rest of the products into the new bulk experience
												{productArray?.map((obj) => {
														return <option value={obj.id}>{obj.name}</option>
													})
												}*/}
												<option values=""></option>
												<option value="23">Wholesale Broadband</option>
											</select>
											{projectDetails?.product === "23" ? <a href="../../assets/files/bulk/Wholesale_Broadband_TemplateV1.xls" className={style.lableTextS}>Wholesale_Broadband_TemplateV1</a> : null}
										</div>
										<div className="col-12 col-sm-4 col-md-3">
											<label className={style.lableTextS}>Number of Rows<span className={style.mandatory}>*</span></label>
											<select className={` form-control  ${style.selectFld} `} id="rowCount" value={rowCount} onChange={inputChange}>
												<option values=""></option>
												{[...Array(100)].map((x, i) =>
													<option value={i + 1}>{i + 1}</option>
												)}
											</select>
										</div>
										{!displayBulkForm ?
											<div className="col-12 col-sm-4 col-md-3">
												<button className={` ico-button lhs light-button ${style.nextBtn} `} type="button" onClick={() => generateBulkRows()}>
													Generate Bulk Form
													<svg className="ico light-ico ico-arrow">
														<path d="M0,17H29L15,31l1,1L32,16,16,0,15,1,29,15H0Z"></path>
													</svg>
												</button>
											</div> : null
										}
									</div>
									{errorMsg ? <div className={`row ${style.rowGrp} text-center `}><span className="text-danger">{errorMsg}</span></div> : null}
									<div className={style.dividers}></div>
									{projectDetails?.product === "23" && displayBulkForm ? <BroadbandBulk rowCount={rowCount} projectDetails={projectDetails} /> : null}
								</> :
								<div className="row">
									<span className="text-danger ml-2">{broadbandErrorMsg}</span>
								</div>
							}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
export default BulkCreate;
