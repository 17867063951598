import {createTheme} from "@mui/material/styles";

const CustomTheme = createTheme({
    palette: {
        primary: {
            main: '#753BBD',
        },
        common: {
            grey: '#999999', black: '#000000', white: '#FFFFFF',
        },
        background: {
            paper: '#ffffff', // Adding background paper color
            default: '#fafafa', // Default background color
        },
        text: {
            primary: '#333333', // Primary text color
            secondary: '#555555', // Secondary text color
        },
        action: {
            selectedOpacity: 0.08, // Numeric value for selected opacity
            hoverOpacity: 0.04, // Numeric value for hover opacity
        },
    },
    shape: {
        borderRadius: 4, // Default border radius
    },
    spacing: 8, // Default spacing unit
});

export default CustomTheme;