import {applyMiddleware, compose, createStore} from "redux";
import rootReducer from "../redux/reducers/rootReducer";
import thunk from "redux-thunk";
import {persistReducer, persistStore} from "redux-persist";
import storage from "redux-persist-indexeddb-storage";
import autoMergeLevel2 from "redux-persist/es/stateReconciler/autoMergeLevel2";

const environment = process.env.REACT_APP_NODE_ENV;

let composeEnhancers;

if (environment === "production") {
    composeEnhancers = compose;
} else {
    composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ||
        compose
}

const persistConfig = {
    key: "root",
    storage: storage("storeDB"),
    stateReconciler: autoMergeLevel2
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const configureStore = (initialState) => {
    return createStore(
        persistedReducer,
        initialState,
        composeEnhancers(applyMiddleware(thunk))
    )
}

export const store = configureStore();
export const persistor = persistStore(store);
