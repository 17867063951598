import { StyleSheet } from '@react-pdf/renderer';
import { Font } from '@react-pdf/renderer';
import fontSrc from '../../../font/Montserrat-Regular.ttf';
import boldSrc from '../../../font/Montserrat-Bold.ttf';

Font.register({ src: boldSrc, family: 'Montserrat-Bold' });
Font.register({ src: fontSrc, family: 'Montserrat-Regular' });

export const styles = StyleSheet.create({
    page: {
        flexDirection: 'column',
        backgroundColor: '#FFFFFF',
        padding: 30,
    },
    section: {
        marginBottom: 10,
    },
    imageHeader: {
        width: '100%',
        height: 81,
    },
    headerBar: {
        backgroundColor: '#753BBD',
        padding: 5,
        marginBottom: 5,
    },
    headerBarText: {
        fontFamily: 'Montserrat-Bold',
        fontSize: 12,
        color: 'white',
    },
    textBold: {
        fontFamily: 'Montserrat-Bold',
        fontSize: 11,
        color: 'black',
    },
    textNormal: {
        fontFamily: 'Montserrat-Regular',
        fontSize: 11,
        color: 'black',
    },
    table: {
        display: 'flex',
        width: '100%',
        marginBottom: 5,
    },
    tableRow: {
        flexDirection: 'row',
        borderBottom: '1px solid #E4E4E4',
    },
    tableCell: {
        flex: 1,
        padding: 5,
    },
    headerCell: {
        fontFamily: 'Montserrat-Bold',
        backgroundColor: '#753BBD',
        color: 'white',
    },
    columnHalf: {
        width: '50%',
        paddingRight: 5,
    },
});