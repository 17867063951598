// styles.js
import { StyleSheet } from '@react-pdf/renderer';
import { Font } from '@react-pdf/renderer';
import fontSrc from '../../font/Montserrat-Regular.ttf';
import boldSrc from '../../font/Montserrat-Bold.ttf';

Font.register({ src: boldSrc, family: 'Montserrat-Bold' });
Font.register({ src: fontSrc, family: 'Montserrat-Regular' });

export const styles = StyleSheet.create({
	page: {
		flexDirection: 'column',
		backgroundColor: '#E4E4E4',
		margin: 30, // Adds margin around the whole page
	},
	section: {
		margin: 10,
		padding: 10,
		flexGrow: 1,
	},
	imageHeader: {
		width: 535,
		height: 81,
		marginBottom: 1,
	},
	headerBar: {
		flexDirection: 'row',
		backgroundColor: '#753BBD',
		padding: 5,
		width: 535,
	},
	headerBarTopMargin: {
		flexDirection: 'row',
		backgroundColor: '#753BBD',
		padding: 5,
		width: 535,
		marginTop: 20,
	},
	headerBarText: {
		fontFamily: 'Montserrat-Bold',
		fontSize: 11,
		color: 'white',
	},
	textBold: {
		fontFamily: 'Montserrat-Bold',
		fontSize: 11,
		color: 'black',
	},
	textNormal: {
		fontFamily: 'Montserrat-Regular',
		fontSize: 11,
		color: 'black',
	},
	textSmall: {
		fontSize: 9,
		color: 'black',
	},
	tableTop: {
		display: 'flex',
		flexDirection: 'column',
		marginTop: 15,
	},
	table: {
		display: 'flex',
		flexDirection: 'column',
	},
	tableRowTop: {
		flexDirection: 'row',
		alignItems: 'left',
		width: 535,
		marginTop: 20,
	},
	tableRow: {
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'flex-start',
		width: 535,
	},
	topAlignedRow: {
		flexDirection: 'row',
		alignItems: 'flex-start',
		justifyContent: 'flex-start',
		width: 535,
		paddingTop: 3,
	},
	tableRowPadding: {
		flexDirection: 'row',
		alignItems: 'left',
		width: 535,
		padding: 5,
	},
	tableRowBorderTop: {
		flexDirection: 'row',
		alignItems: 'left',
		borderTop: 1,
		width: 535,
	},
	tableRowBorderBottom: {
		flexDirection: 'row',
		alignItems: 'left',
		width: 535,
		borderBottom: 1,
		marginTop: 8,
	},
	tableRowCenter: {
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'center',
		width: 535,
	},
	tableRowVerticalAlign: {
		flexDirection: 'row',
		alignItems: 'center',
		borderTop: 1,
	},
	headerColumn: {
		flex: 1,
	},
	tableColumn: {
		flex: 1,
	},
	quantityColumn: {
		flex: 0.5,
	},
	tableColumn2Width: {
		flex: 2,
	},
	tableColumn3Width: {
		flex: 3,
	},
	tableCell2Width: {
		flex: 2,
	},
	tableCell3Width: {
		flex: 3,
	},
	tableCell: {
		padding: 1,
		flexDirection: 'row',
		alignItems: 'stretch',
		flexWrap: 'wrap',
		justifyContent: 'center',
	},
	tableCellCenter: {
		padding: 3,
		textAlign: 'center',
		alignItems: 'stretch',
		flexWrap: 'wrap',
		justifyContent: 'center',
	},
	signatureCell: {
		padding: 1,
		borderBottom: '1 solid black',
		marginTop: 2,
	},

	// pricing part of Quote PDF
	pricingTable: {
		display: 'flex',
		flexDirection: 'column',
		border: 1,
		width: 535,
	},
	pricingHeader: {  // This would be part of pricing with gray BG
		alignItems: 'center',
		padding: 5,
	},
	pricingHeaderRow: {  // Pricing with gray BG on quote
		backgroundColor: '#CCCCCC',
		alignItems: 'center',
		padding: 5,
		width: 535,
	},
	pricingHeaderRowBg: {  // Gray BG
		backgroundColor: '#CCCCCC',
	},
	// Signature Section in OrderPDF
	dualColumn: {
		flexDirection: 'row',
		justifyContent: 'space-between',
	},
	halfWidth: {
		width: '50%',
		padding: 10,
	},
	signatureBelowText: {
		fontFamily: 'Montserrat-Regular',
		fontSize: 11,
		color: 'black',
	},
	signatureText: {
		fontFamily: 'Montserrat-Regular',
		fontSize: 11,
		color: 'black',
		marginTop: 10,
	},
	signatureAboveLine: {
		borderBottom: '1 solid black',
		height: 1,
		width: '80%',
		marginTop: 15,
	},
	signatureLine: {
		borderBottom: '1 solid black',
		height: 1,
		width: '80%',
		//marginBottom: 5, // Space between line and text
	},
	//leaseFee
	tableBorder: {
		border: '1px solid #eaeaea', // Adjust color as needed
	},
	headerRow: {
		backgroundColor: '#f2f2f2', // Light grey background for header row
		borderBottom: '2px solid #dee2e6', // Slightly darker line to separate header
	},
	// Order PDF footnote
	footnoteContainer: {
		width: 535,
		flexDirection: 'column',
	},
	smallText: {
		fontSize: 8,
		color: '#666666',
		fontFamily: 'Montserrat-Regular',
	},
	notationText: {
		fontSize: 6, // Smaller font size for the notation
		fontFamily: 'Montserrat-Regular',
		marginTop: -2, // Adjust as needed to position the text slightly above the baseline
	},

});