import { Text, View, } from '@react-pdf/renderer';
import { styles } from './pdfStyles';

const OrderLeaseFee = ({ leaseFee, term, variablePricing, orderData }) => {
	if (!leaseFee || leaseFee.length === 0 || !leaseFee[0]) return <Text style={[styles.textBold]}>No Lease Fee data</Text>;

	const icbPricingType = leaseFee?.icbPricingType ? Number(leaseFee.icbPricingType) : 0;
	const icbPricingName = leaseFee?.icbPricingName || '';
	const termDisplay = orderData?.o_circuit === '7' ? `${Math.floor(term / 12)} Year Term` : `${term} Month Term`;
	const leaseItems = Object.entries(leaseFee)
		.filter(([key, value]) => !isNaN(Number(key)))
		.map(([key, value]) => value);

	const formatItemText = (itemText) => {
		return itemText.replace(' - (', '\n');
	};

	return (
		<>
			<View wrap={false}>
				<View style={styles.headerBarTopMargin}>
					<Text style={styles.headerBarText}>Lease Fee</Text>
				</View>
				<View style={styles.tableRowCenter}>
					<Text style={[styles.tableColumn, styles.tableCell, styles.textBold]}>
						{icbPricingType > 1 ? `${icbPricingName} - ${termDisplay}` : termDisplay}
					</Text>
				</View>
				<View style={styles.pricingTable}>
					<>
						<View style={[styles.tableRow, styles.headerRow, styles.tableBorder, styles.pricingHeaderRowBg]}>
							<Text style={[styles.tableColumn2Width, styles.tableCell, styles.textBold]}>Item(s)</Text>
							<Text style={[styles.quantityColumn, styles.tableCellCenter, styles.textBold]}>Qty</Text>
							<Text style={[styles.tableColumn, styles.tableCellCenter, styles.textBold]}>MRC</Text>
							<Text style={[styles.tableColumn, styles.tableCellCenter, styles.textBold]}>NRC</Text>
							<Text style={[styles.tableColumn, styles.tableCellCenter, styles.textBold]}>Total MRC<Text style={styles.notationText}>1</Text></Text>
							<Text style={[styles.tableColumn, styles.tableCellCenter, styles.textBold]}>Total NRC<Text style={styles.notationText}>2</Text></Text>
						</View>
						{leaseItems.map((item, idx) => (
							<View key={`leaseFee-item-${idx}`} style={[styles.tableRow]}>
								<Text style={[styles.tableColumn2Width, styles.tableCell, styles.textNormal]}>
									{formatItemText(item.item)}
								</Text>
								<Text style={[styles.quantityColumn, styles.tableCellCenter, styles.textNormal]}>
									{item.qty}
								</Text>
								<Text style={[styles.tableColumn, styles.tableCellCenter, styles.textNormal]}>
									{item.mrc}
								</Text>
								<Text style={[styles.tableColumn, styles.tableCellCenter, styles.textNormal]}>
									{item.nrc}
								</Text>
								<Text style={[styles.tableColumn, styles.tableCellCenter, styles.textNormal]}>
									{item.total_mrc}
								</Text>
								<Text style={[styles.tableColumn, styles.tableCellCenter, styles.textNormal]}>
									{item.total_nrc}
								</Text>
							</View>
						))}
						<View style={styles.tableRowBorderTop}>
							<Text style={[styles.tableColumn2Width, styles.tableCell, styles.textBold]}>
								{`Overall Totals\n${variablePricing && (icbPricingType === 2 || icbPricingType === 3) ? leaseFee?.varMonths : ''}`}
							</Text>
							<Text style={[styles.tableColumn, styles.tableCell, styles.textNormal]}>
							</Text>
							<Text style={[styles.quantityColumn, styles.tableCell, styles.textNormal]}>
							</Text>
							<Text style={[styles.tableColumn, styles.tableCell, styles.textNormal]}>
							</Text>
							<Text style={[styles.tableColumn, styles.tableCellCenter, styles.textBold]}>
								{leaseFee?.overall_total_mrc}
							</Text>
							<Text style={[styles.tableColumn, styles.tableCellCenter, styles.textBold]}>
								{leaseFee?.overall_total_nrc}
							</Text>
						</View>
						{variablePricing && (icbPricingType === 2 || icbPricingType === 4) ?
							<View style={styles.tableRowBorderTop}>
								<Text style={[styles.tableColumn2Width, styles.tableCell, styles.textBold]}>
									{`Overall Totals\n${leaseFee?.postMonths}`}
								</Text>
								<Text style={[styles.tableColumn, styles.tableCell, styles.textNormal]}>
								</Text>
								<Text style={[styles.quantityColumn, styles.tableCell, styles.textNormal]}>
								</Text>
								<Text style={[styles.tableColumn, styles.tableCell, styles.textNormal]}>
								</Text>
								<Text style={[styles.tableColumn, styles.tableCellCenter, styles.textBold]}>
									{leaseFee?.post_mrc}
								</Text>
								<Text style={[styles.tableColumn, styles.tableCellCenter, styles.textBold]}>
									{leaseFee?.post_nrc}
								</Text>
							</View>
							: null}
						{variablePricing && (icbPricingType > 1) ?
							<View style={styles.tableRowBorderTop}>
								<Text style={[styles.tableColumn2Width, styles.tableCell, styles.textBold]}>
									Average MRC<Text style={styles.notationText}>3</Text>
								</Text>
								<Text style={[styles.tableColumn, styles.tableCell, styles.textNormal]}>
								</Text>
								<Text style={[styles.quantityColumn, styles.tableCell, styles.textNormal]}>
								</Text>
								<Text style={[styles.tableColumn, styles.tableCell, styles.textNormal]}>
								</Text>
								<Text style={[styles.tableColumn, styles.tableCellCenter, styles.textBold]}>
									{leaseFee?.average_mrc}
								</Text>
								<Text style={[styles.tableColumn, styles.tableCellCenter, styles.textBold]}>
									{leaseFee?.average_nrc}
								</Text>
							</View>
							: null}
					</>
				</View>
				{/* Footnote Section */}
				<View style={styles.footnoteContainer}>
					<Text style={styles.textSmall}>1) Or any other term used in the Customer's Agreement to convey the monthly fees applicable to Services including, but not limited to, the use of "Monthly Lease Fee"</Text>
					<Text style={styles.textSmall}>2) Or any other term used in the Customer's Agreement to convey the one-time fees applicable to Services</Text>
					{variablePricing ?
						<Text style={styles.textSmall}>3) Average MRC is the cumulative MRC over the length of the service contract. Average is calculated using monthly recurring charges
							and any one time supplemental charges for the length of the term. Upon expiration of the term, the billing will revert to Average
							Equivalent MRC as defined, unless otherwise noted.</Text>
						: null}
				</View>
			</View>
		</>
	);
};

export default OrderLeaseFee;