import {useState} from "react";
import ViewportWarning from "../../shared/ViewportWarning";
import Account from "./Account";
import AddUser from "./AddUser";
import EditUser from "./EditUser";
import TabsStaff from "./TabsStaff";

const Staff = () => {
    const [navLink, setNavLink] = useState('Account');

    const handleNavClick = (e) => {
        setNavLink(e);
    }

    const LoadComponent = () => {
        switch (navLink) {
            case 'Account':
                return <Account />
            case 'AddUser':
                return <AddUser />
            case 'EditUser':
                return <EditUser />
            default: return;
        }
    }

    return (
        <div className="midsection employee abs z1">
            <div className="rel">
                <ViewportWarning />
                <div className="wrap">
                    <h1 className="page-title"> Staff Management</h1>
                    <div className="section">
                        <TabsStaff handleNav={(e) => handleNavClick(e)} />
                        <div className="content">
                            <LoadComponent />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Staff;