// CSS Imports
import style from "./UserManagement.module.css";

/**
 * JS Imports
 *
 */
// React Core Libraries
import { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { useImmer } from "use-immer";

// Local JS Files Imports
import ViewportWarning from "../shared/ViewportWarning";
import UserManagementService from "../../services/UserManagementService";
import Loader from "../shared/Loader";
import { userManage } from "../../redux/actions/userActions";

// 3rd party npm Libraries
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import filterFactory from "react-bootstrap-table2-filter";
import { ConsoleLogger } from "../../logger/ConsoleLogger";
import HTTPErrorHandler from "../errorPages/HTTPErrorHandler";
import XLSXExport from "../../utils/XLSXExport";

const pagination = paginationFactory({
	sizePerPage: 10
});

const UserManagement = () => {
	const logger = new ConsoleLogger({ level: process.env.REACT_APP_LOGGER_LEVEL, });
	const [hasError, setHasError] = useState(null);
	const [statusSuccess, setStatusSuccess] = useState(false);
	const [displaySuccess, setDisplaySuccess] = useState(null);
	const [statusError, setStatusError] = useState(false);
	const [displayError, setDisplayError] = useState(null);
	const [userStatus, setUserStatus] = useState('');
	const [userId, setUserId] = useState('');
	const [popupShow, setPopupShow] = useState(false);
	const [popupMsg, setPopupMsg] = useState('');
	const [loader, setLoader] = useState(false);
	const selectedUser = useSelector(state => state.userReducer.user.webaccess);
	const dispatch = useDispatch();
	const history = useHistory();
	let timerid = null;
	const XLSXHeaders = ["User ID", "First Name", "Last Name", "Email", "Status", "Transport Quote", "Transport Order", "Invoice System", "Trouble Tickets", "Web User Admin", "First Login", "Last Login", "Last Modified"];
	const [userState, setUserState] = useImmer({
		userSummaryData: [],
		loader: false
	});

	const columnsDesktop = [{
		dataField: 'userId',
		text: 'User ID',
		sort: true,
		classes: "w10",
		headerAttrs: { id: 'userId' },
		formatter: (cell, row) => userIdFormatter(cell, row)
	}, {
		dataField: 'firstName',
		text: 'First Name',
		sort: true,
		headerAttrs: { id: 'firstName' }
	},
	{
		dataField: 'lastName',
		text: 'Last name',
		sort: true,
		headerAttrs: { id: 'lastName' },
	},
	{
		dataField: 'email',
		text: 'Email',
		headerAttrs: { id: 'email' },
	},
	{
		dataField: 'webAccess',
		text: 'Web Access',
		headerAttrs: { id: 'webAccess' },
		formatter: (cell, row) => webAccessFormatter(cell, row)
	},
	{
		dataField: 'loginInfo',
		text: 'Login Info',
		headerAttrs: { id: 'loginInfo' },
		formatter: (cell, row) => loginInfoFormatter(cell, row),
	}];

	const webAccessFormatter = (props, row) => {
		//const webOptions = Object.values(row.webAccess);
		let listItems = null;

		if (row?.webAccess) {
			listItems = row.webAccess.map((val) => <li>{val}</li>);
		} else {
			listItems = "No Web Access Level Set";
		}

		return (
			<div>
				<ul>
					{listItems}
				</ul>
			</div>
		);

	}

	const loginInfoFormatter = (props, row) => {
		const listItems = row.loginInfo.map((val) => <li>{val}</li>);

		return (
			<div>
				<ul>
					{listItems}
				</ul>
			</div>
		);

	}

	const userIdFormatter = (props, row) => {
		// <div><Link onClick={() => routeChange(row, URL)} target="_self">{uid}</Link></div>
		let uid = row.userId
		const URL = `/Manage/UserInfo/${uid}`;
		return (
			<>
				{row.iconnectID?.length > 0 ?
					<>
						{Number(selectedUser.UserAdmin) > 1 ?
							<>
								<div><Link to={URL} onClick={() => routeChange(row, URL)} target="_self">{uid}</Link></div>
								<div id={uid}><Link onClick={() => statusLink(row.status, uid)} target="_self">{row.status}</Link></div>
							</>
							:
							<>
								<div>{uid}</div>
								<div>{row.status}</div>
							</>
						}
					</>
					:
					<>
						<div>{uid}</div>
						<div className='text-danger'>Not set up for iconnect access</div>

					</>
				}
			</>
		);

	}

	useEffect(() => {
		document.getElementById('errorMessage')?.scrollIntoView({ behavior: 'smooth' });
	}, [statusError]);

	useEffect(() => {
		document.getElementById('successMessage')?.scrollIntoView({ behavior: 'smooth' });
	}, [statusSuccess]);

	const statusLink = (status, uid) => {
		setStatusError(false);
		setStatusSuccess(false);
		setPopupShow(true);
		setUserId(uid);
		if (status === 'Active') {
			setUserStatus('Active');
			setPopupMsg("This will deactivate the user's account. Click Ok to continue or Cancel. ");
		} else {
			setUserStatus('Not Active');
			setPopupMsg("This will activate the user's account. Click Ok to continue or Cancel.");
		}
	}

	const updateStatus = async () => {
		setPopupShow(false);
		setUserState(draft => {
			draft.loader = true;
		});

		if (userStatus === 'Active') {
			logger.info('Active....to make not active');
			const updateJSON = {
				"action": "disable",
				"uid": userId
			};

			UserManagementService.updateUserStatus(updateJSON).then(resp => {
				logger.info(encodeURIComponent(resp.data).replace(/%20/g, " "));
				if (resp.data.result === 'SUCCESS') {
					setUserState(draft => {
						draft.userSummaryData = resp.data.data;
					});
					setUserState(draft => { draft.loader = false; });
					setDisplaySuccess(userId + " was made inactive");
					setStatusSuccess(true);

				} else {
					setUserState(draft => { draft.loader = false; });
					setDisplayError('ERROR: User status failed to be made not active. ' + resp.data.error);
					setStatusError(true);
				}
			}).catch(error => {
				setUserState(draft => { draft.loader = false; });
				logger.error("UserManagementService.updateUserStatus ERROR: user status failed to make not active. " + error);
				setHasError(error);
			});
		} else {
			logger.info('Not Active....to make active');
			const updateJSON = {
				"action": "enable",
				"uid": userId
			};
			UserManagementService.updateUserStatus(updateJSON).then(resp => {
				logger.info(encodeURIComponent(resp.data).replace(/%20/g, " "));
				if (resp.data.result === 'SUCCESS') {
					setUserState(draft => {
						draft.userSummaryData = resp.data.data;
					});
					setUserState(draft => { draft.loader = false; });
					setDisplaySuccess(userId + " was made active");
					setStatusSuccess(true);
				} else {
					setUserState(draft => { draft.loader = false; });
					setDisplayError('ERROR: User status failed to be made active. ' + resp.data.error);
					setStatusError(true);
				}
			}).catch(error => {
				setUserState(draft => { draft.loader = false; });
				logger.error("UserManagementService.updateUserStatus ERROR: user status failed to make active. " + error);
				setHasError(error);
			});
		}
	}

	const popupCancel = () => {
		setPopupShow(false);
		setPopupMsg('');
	}

	const routeChange = (userObj, path) => {
		dispatch(userManage(userObj));
		history.push(path);
	}

	const submitJSON = {
		"action": "list"
	};

	// This is equivalent to ComponentDidMount
	useEffect(() => {
		getUserData(submitJSON);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const getUserData = (submitJSON) => {
		setUserState(draft => {
			draft.loader = true;
		});
		UserManagementService.getUserData(submitJSON).then(resp => {
			setUserState(draft => {
				draft.userSummaryData = resp.data.data;
			});
			if (timerid) {
				clearTimeout(timerid);
			}
			timerid = setTimeout(() => {
				setUserState(draft => {
					draft.loader = false;
				});
			}, 200);
		}).catch(error => {
			logger.error("UserManagementService.getUserData ERROR: " + error)
			setHasError(error);
		});
	}

	const updateUserListJSON = {
		"action": "list",
		"status": "all"
	};

	const updateUserDisplay = async (event) => {
		logger.info(encodeURIComponent(event.target.id).replace(/%20/g, " ") + ' : ' + encodeURIComponent(event.target.value).replace(/%20/g, " "));
		if (event.target.checked) {
			UserManagementService.getUserData(updateUserListJSON).then(resp => {
				logger.info("Checked: display ALL users.");
				setUserState(draft => {
					draft.userSummaryData = resp.data.data;
				});
				if (timerid) {
					clearTimeout(timerid);
				}
				timerid = setTimeout(() => {
					setUserState(draft => {
						draft.loader = false;
					});
				}, 200);
			}).catch(error => {
				logger.error("UserManagementService.getUserData all users ERROR: " + error)
				setHasError(error);
			});
		} else {
			getUserData(submitJSON);
			logger.info("Not Checked: display only active users.");
		}

	}

	const downloadUserData = () => {
		setLoader(true);
		const downloadUserListJSON = {
			"action": "download",
			"status": "all",
			"site": "internal"
		};
		/*Calls manage.php to get the data to populate the User Table at the botton of this page*/
		UserManagementService.getUserData(downloadUserListJSON).then(resp => {
			logger.info("DOWNLOAD DATA: " + JSON.stringify(resp.data.data));
			const exportArr = resp.data.data.map((item) => {
				return {
					'User ID': item.userId,
					'First Name': item.firstName,
					'Last Name': item.lastName,
					'Email': item.email,
					'Status': item.status,
					'Transport Quote': item.transportQuote,
					'Transport Order': item.transportOrder,
					'Invoice System': item.invoiceSystem,
					'Trouble Tickets': item.troubleTicket,
					'Web User Admin': item.webUserAdmin,
					'First Login': item.firstLogin,
					'Last Login': item.lastLogin,
					'Last Modified': item.lastModified
				};
			});
			XLSXExport.exportToExcel(XLSXHeaders, exportArr, `UserData_${Date.now()}`)
			setLoader(false);
		}).catch(error => {
			setLoader(false);
			setHasError(error);
		})
	}

	return (
		<>
			{popupShow ?
				<div>
					<div className={`${style.overly}`}> </div>
					<div className="modal fade show d-block">
						<div className={` modal-dialog modal-dialog-centered modal-md`}>
							<div className="modal-content">
								<div className="modal-body">
									{popupMsg}
								</div>
								<div className="modal-footer d-block text-center">
									<button type="button" className="btn-style btn-theme" data-dismiss="modal" onClick={updateStatus}>Ok</button>
									<button type="button" className="btn-style btn-cancel" data-dismiss="modal" onClick={popupCancel}>Cancel</button>
								</div>

							</div>
						</div>
					</div>
				</div>
				: null
			}

			{/*<ReactTooltip />*/}
			{userState.loader ? <Loader /> : null}
			{loader ? <Loader /> : null}
			{hasError && (<HTTPErrorHandler error={hasError}></HTTPErrorHandler>)}
			<div className="midsection qm-upload abs z1">
				<div className="rel">
					<ViewportWarning />
					<div className="wrap">
						<h1 className="page-title mb-0">User Management</h1>
						<div className="section w-100 UserManagement-page">
							{/* <TabsQuote /> */}
							{statusError ?
								<div id='errorMessage' className="mt-5">
									<p className="text-danger text-center">{displayError}</p>
								</div> : null
							}
							{statusSuccess ?
								<div id='successMessage' className="mt-5">
									<p className="text-success text-center">{displaySuccess}</p>
								</div> : null
							}
							{!hasError && (
								<div className="content bg-transparent px-0">
									<div className="row mt-5 justify-content-end">
											<div>
												<input type="checkbox" id="allUsers" name="allUsers" onChange={updateUserDisplay} />
												<label for="allUsers" className={`${style.textColor} ml-2`}>Show Inactive Users</label>
											</div>
											<div>
												<button type="button" className={`${style.addUserButton} ml-3 px-3`} onClick={() => routeChange({}, '/Manage/UserInfo')}>
													{'Add User'}
												</button>
											</div>
											<div class="mr-4">
												<button type="button" className={`${style.addUserButton} ml-3 px-3`} onClick={() => downloadUserData()}>
													{'Export'}
												</button>
											</div>
									</div>
									<div className={` ${style.tableWrap} tableWrap`}>
										<div className={`rel table-style-wrap userManagement-table pagination-table page-list userManagementTable ${style.userManageTable}`}>
											{true ?
												<BootstrapTable id="userManage"
													bootstrap4
													keyField='id'
													data={userState.userSummaryData}
													columns={columnsDesktop}
													striped
													hover
													condensed
													bordered={false}
													pagination={pagination}
													filter={filterFactory()}
													defaultSortDirection="desc"
													className="mb-0"
												/>
												: null
											}
										</div>
									</div>
								</div>)}
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default UserManagement;
