import style from "./Report.module.css";
import XLSXExport from "../../utils/XLSXExport";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import filterFactory from "react-bootstrap-table2-filter";

const TroubleTicketReport = ({ TroubleTicketData }) => {
	const columns = [{
		dataField: "billPrntSubId",
		text: "Parent Account",
	},
	{
		dataField: "custAcctNbr",
		text: "Child Account Number"
	},
	{
		dataField: "companyName",
		text: "Company Name",
	},
	{
		dataField: "ticketId",
		text: "Ticket"
	},
	{
		dataField: "openDt",
		text: "Open Date"
	},
	{
		dataField: "closeDt",
		text: "Close Date"
	},
	{
		dataField: "resDate",
		text: "Cleared Date"
	},
	{
		dataField: "troubleTypeCd",
		text: "Trouble Type Code"
	},
	{
		dataField: "ttTypeCd",
		text: "Trouble Type"
	},
	{
		dataField: "troubleDesc",
		text: "Trouble Description"
	},
	{
		dataField: "troubleFoundCd",
		text: "Trouble Found Code"
	},
	{
		dataField: "causeCd",
		text: "Cause Code"
	},
	{
		dataField: "clearedCd",
		text: "Cleared Code"
	},
	{
		dataField: "clearedComment",
		text: "Cleared Comment"
	}];

	const XLSXHeaders = ["Parent Account Number", "Child Account Number", "Company Name", "Ticket", "Open Date", "Close Date", "Cleared Date",
		"Trouble Type Code", "Trouble Type", "Trouble Description", "Trouble Found Code", "Cause Code", "Cleared Code", "Cleared Comment"];

	return (
		<>
			<div className='d-flex mb-5 ml-3'>
				<div className="w15 mr-5">
					<button className="saveButton ico-button light-button" onClick={() => XLSXExport.exportToExcel(XLSXHeaders, TroubleTicketData, `TroubleTicket_Report${Date.now()}`, 'AA')}><span><strong>Export to Excel</strong></span>
						<svg viewBox="0 0 34 34" className={`ico light-ico ico-arrow downloadArrow`}>
							<path className="cls-1" d="M1,18V16L17,31V1h2V31L35,16v2L20,33H35v2H1V33H16Z"
								transform="translate(-1 -1)">
							</path>
						</svg>
					</button>
				</div>
				<div className="w15 ">
					<button className="saveButton ico-button light-button" onClick={() => XLSXExport.exportToExcel(XLSXHeaders, TroubleTicketData, `TroubleTicket_Report${Date.now()}`, 'AA', '.csv')}><span><strong>Export to CSV</strong></span>
						<svg viewBox="0 0 34 34" className={`ico light-ico ico-arrow downloadArrow`}>
							<path className="cls-1" d="M1,18V16L17,31V1h2V31L35,16v2L20,33H35v2H1V33H16Z"
								transform="translate(-1 -1)">
							</path>
						</svg>
					</button>
				</div>
			</div>

			<BootstrapTable
				id="troubleTicketTable"
				classes={`${style.troubleTicketTable} table-fixed table table-responsive `}
				keyField='ticketId'
				data={TroubleTicketData}
				columns={columns}
				bootstrap4
				striped
				hover
				bordered={false}
				pagination={paginationFactory({
					paginationSize: 2,
					pageStartIndex: 1,
					sizePerPage: 5
				})}
				filter={filterFactory()}
				loading={true}
			/>
		</>
	)
}

export default TroubleTicketReport;
