import style from './OrderCreate.module.css';
import React, {useEffect, useState} from "react";
import OrderHelper from '../../services/OrderService';
import {connect, useSelector} from "react-redux";
import {
	setOrderCustomerAssigned,
	setOrderEVCS,
	setOrderJurisdictionalTraffic,
	setOrderLANRequirement,
	setOrderLayer2Protocol,
	setOrderMTUSize,
	setOrderNNICircuitId,
	setOrderNNIInterconnection,
	setOrderOtherField,
	setOrderRampUp,
	setOrderStaticIp,
	setOrderVLAN,
	setOrderWANRequirement
} from '../../redux/actions/orderCreateActions';
import OrderMpls from './OrderMpls';
import Loader from '../shared/Loader';
import {ConsoleLogger} from "../../logger/ConsoleLogger";
import HTTPErrorHandler from "../errorPages/HTTPErrorHandler";

const OrderProductDetails = (props) => {
	const logger = new ConsoleLogger({ level: process.env.REACT_APP_LOGGER_LEVEL, });
	const [hasError, setHasError] = useState(null);
	const [orderIpOptions, setOrderIpOptions] = useState([]);
	const [nniCircuitIdOptions, setNNICircuitOptions] = useState([]);
	const [nniLocationOptions, setNNILocationOptions] = useState([]);
	const [popupShow, setPopupShow] = useState(false);
	const [popupMsg, setPopupMsg] = useState('');
	const [loader, setLoader] = useState(false);
	const selectedQuotedData = useSelector(state => state.orderCreate.quotedData);
	const selectedOrderProductDetails = useSelector(state => state.orderCreate.productDetails);

	useEffect(() => {
		getOrderIpOptions();
		getOrderNNICircuitOptions();
		getOrderInterconnectionPointOptions();
		getJurisdictionOrderType();
	}, [])

	const getOrderIpOptions = () => {
		if (selectedQuotedData.product_id === "10" || selectedQuotedData.product_id === "11") {
			setLoader(true);
			OrderHelper.getOrderIPs(selectedQuotedData.product_id, selectedQuotedData.circuit_id).then(resp => {
				logger.info(resp.data);
				setOrderIpOptions(resp.data);
				setLoader(false);
			}).catch(error => {
				logger.error("Getting Static IPs: " + error);
				setHasError(error);
				setLoader(false);
			})
		}
	}

	const getOrderNNICircuitOptions = () => {
		if (selectedQuotedData.product_id === "20") {
			setLoader(true);
			OrderHelper.getOrderNNICircuitIds().then(resp => {
				logger.info(resp.data);
				setNNICircuitOptions(resp.data);
				if (resp.data.length === 1) {
					props.updateOrderNNICircuitId(resp.data[0].id);
				}
				setLoader(false);
			}).catch(error => {
				logger.error("Getting NNI Circuit IDS for EACCESS " + error);
				setHasError(error);
				setLoader(false);
			})
		}
	}

	const getOrderInterconnectionPointOptions = () => {
		if (selectedQuotedData.product_id === "20") {
			setLoader(true);
			OrderHelper.getOrderInterconnectionPoint().then(resp => {
				logger.info(resp.data);
				setNNILocationOptions(resp.data);
				setLoader(false);
			}).catch(error => {
				logger.error("Getting Interconnection Points " + error);
				setHasError(error);
				setLoader(false);
			})
		}
	}

	const getJurisdictionOrderType = () => {
		if (Number(selectedQuotedData.product_id) < 4 || selectedQuotedData.product_id === "19" || selectedQuotedData.product_id === "20" || selectedQuotedData.product_id === "22" || selectedQuotedData.product_id === "7" || selectedQuotedData.product_id === "6" ) {
			setLoader(true);
			OrderHelper.getJurisdictionType().then(resp => {
				logger.info(resp.data);
				switch (resp.data.jurisdiction) {
					case "1":
						//Set to Intrastate
						props.updateOrderJurisdictionalTraffic("1");
						break;
					case "2":
						//Set to Interstate
						props.updateOrderJurisdictionalTraffic("2");
						break;
					case "3":
						//Set to Interstate
						props.updateOrderJurisdictionalTraffic("2");
						break;
					default:
						//Set to Interstate
						props.updateOrderJurisdictionalTraffic("2");
						break;
				}
				setLoader(false);
			}).catch(error => {
				logger.error("Getting Jurisdiction Type: " + error);
				setHasError(error);
				setLoader(false);

			})
		}
	}

	const inputHandler = (ev) => {
		switch (ev.target.id) {
			case "wan_id":
				props.updateOrderWANRequirement(ev.target.value);
				break;
			case "lan_id":
				props.updateOrderLANRequirement(ev.target.value);
				break;
			case "ips":
				props.updatdOrderStaticIp(ev.target.value);
				break;
			case "jurisdiction":
				props.updateOrderJurisdictionalTraffic(ev.target.value);
				break;
			case "ceMtu":
				props.updateOrderMTUSize(ev.target.value);
				break;
			case "mtu_other":
				props.updateOrderMTUOther(ev.target.value);
				break;
			case "ceVlan":
				props.updateOrderVLAN(ev.target.value, 0);
				break;
			case "nni_circuit_id":
				props.updateOrderNNICircuitId(ev.target.value);
				break;
			case "eline_evc":
				props.updateOrderEVCS(ev.target.value);
				break;
			case "eline_l2cp":
				props.updateOrderLayer2Protocol(ev.target.value);
				break;
			case "interNNi":
				props.updateOrderNNIInterconnection(ev.target.value);
				break;
			case "vlan_customer":
				props.updateOrderCustomerAssigned(ev.target.value);
				break;
			default:
				break;
		}
	}

	const rampUpHandler = (ev) => {
		if (ev.target.checked === true) {
			setPopupShow(true);
			//MPLS NNI 1000 MB
			if (selectedQuotedData.product_id === "7" && selectedQuotedData.loop_band === "33") {
				setPopupMsg("By choosing the Ramp Period, the customer commits to achieve and maintain $2500 MRC of MPLS Agg Tail services (connected to the NNI) within 12 months from the date the NNI is placed into service for it to remain eligible for a $0 MRC. If the MPLS Agg Tail commitment is not met in the first 12 months and maintained for the remaining term, standard rates for the respective NNI will be applied by Windstream going forward.");
			}
			//MPLS NNI 10GB
			if (selectedQuotedData.product_id === "7" && selectedQuotedData.loop_band === "35") {
				setPopupMsg("By choosing the Ramp Period, the customer commits to achieve and maintain $5000 MRC of MPLS Agg Tail services (connected to the NNI) within 12 months from the date the NNI is placed into service for it to remain eligible for a $0 MRC. If the MPLS Agg Tail commitment is not met in the first 12 months and maintained for the remaining term, standard rates for the respective NNI will be applied by Windstream going forward.");
			}
			//EACCESS NNI 1000 MB
			if (selectedQuotedData.product_id === "22" && selectedQuotedData.loop_band === "33") {
				setPopupMsg("Customer, at its own cost, shall provide collocation space and power in the applicable location for the placement of the Windstream NID. By choosing the Ramp Period, the customer commits to achieve and maintain $2500 MRC of Carrier Ethernet Tail services (connected to the NNI) within 12 months from the date the NNI is placed into service for it to remain eligible for a $0 MRC. If the Carrier Ethernet Tail commitment is not met in the first 12 months and maintained for the remaining term, standard rates for the respective NNI will be applied by Windstream going forward.");
			}
			//EACCESS NNI 10GB
			if (selectedQuotedData.product_id === "22" && selectedQuotedData.loop_band === "35") {
				setPopupMsg("Customer, at its own cost, shall provide collocation space and power in the applicable location for the placement of the Windstream NID. By choosing the Ramp Period, the customer commits to achieve and maintain $5000 MRC of Carrier Ethernet Tail services (connected to the NNI) within 12 months from the date the NNI is placed into service for it to remain eligible for a $0 MRC. If the Carrier Ethernet Tail commitment is not met in the first 12 months and maintained for the remaining term, standard rates for the respective NNI will be applied by Windstream going forward.");
			}
			props.updateOrderRampUp(true);
		} else {
			setPopupShow(false);
			setPopupMsg('');
			props.updateOrderRampUp(false);
		}
	}

	const popupOk = () => {
		setPopupShow(false);
		setPopupMsg('');
		props.updateOrderRampUp(true);
	}
	const popupCancel = () => {
		setPopupShow(false);
		setPopupMsg('');
		props.updateOrderRampUp(false);
	}

	return (
		<>
		{hasError && <HTTPErrorHandler error={hasError}></HTTPErrorHandler>}
			{loader ? <Loader /> : null}
			{/*Product Details for MPLS TAIL & MPLS INTERNET*/}
			{!hasError && (selectedQuotedData.product_id === "6" || selectedQuotedData.product_id === "13") ?
				<OrderMpls /> :
				<>
					<div className={style.section1}>
						<div className={`sub-hdr alt2 ${style.subHdrAlter} `}>
							<div className="nib">
								<div className="the-notch">
									<div className="notch-fg">
										<svg>
											<path
												d="M35.81,26.28l-13,10v-5C-10.47,28.68-1,9.16,11.79,0c0,7.28,0,9.13,0,13.28s3.18,7.92,11,9v-5Z"></path>
										</svg>
									</div>
								</div>
							</div>
							<h2 className={` ${style.sectionTitle} m-0 `}>Product Details</h2>
						</div>

						<div className="sub-section">
							<div className={` row ${style.rowGrp}`}>
								{/*DIA-POP AND DIA PREM: ETHERNET*/}
								{selectedQuotedData?.q_cpt_activity !== "R" && (selectedQuotedData.product_id === "10" || selectedQuotedData.product_id === "11") && selectedQuotedData.circuit_id === "6" ?
									<>
										<div className={` col-12 col-sm-6 col-md-4 col-lg-4 ${style.colGrp}`}>
											<label className={style.labelText}>WAN Requirements<span className={style.mandatory}>*</span></label>
											<select className={style.selectFld} id='wan_id' name='wan_id' onChange={inputHandler} value={selectedOrderProductDetails.wan_id}>
												<option></option>
												<option value="1">Standard /31 (Please Verify customer equipment requirement)</option>
												<option value="2">/30 WAN - (Customer Equipment does not support /31 subnet mask)</option>
												<option value="3">Use selected static IP block as WAN, where first usable is gateway</option>
											</select>
										</div>
										<div className={` col-12 col-sm-6 col-md-4 col-lg-4 ${style.colGrp}`}>
											<label className={style.labelText}>LAN Requirements<span className={style.mandatory}>*</span></label>
											<select className={style.selectFld} id='lan_id' name='lan_id' onChange={inputHandler} value={selectedOrderProductDetails.lan_id}>
												<option></option>
												<option value="1">BGP</option>
												<option value="2">Static</option>
											</select>
											{selectedOrderProductDetails.lan_id === "1" ?
												<a href='../../assets/files/BGP_Form.doc' className={style.lableTextS} download>BGP_Form</a>
												: null}
										</div>
									</>
									: null}
								{/*DIA-POP OR DIA PREM*/}
								{selectedQuotedData.product_id === "10" || selectedQuotedData.product_id === "11" ?
									<div className={` col-12 col-sm-6 col-md-4 col-lg-4 ${style.colGrp}`}>
										<label className={style.labelText}>Static IP's<span className={style.mandatory}>*</span></label>
										<select className={style.selectFld} id='ips' name='ips' onChange={inputHandler} value={selectedQuotedData.ip_key}>
											{orderIpOptions.map((obj) => {
												return <option value={obj.id} disabled>{obj.name}</option>
											})}
										</select>
									</div>
									: null}
								{/*Wave (POP TO POP, POP TO PREM, PREM TO PREM), E-Line, E-Access, E Access NNI, MPLS NNI, MPLS AGG TAIL, MANAGED SPECTRUM*/}
								{selectedQuotedData?.q_cpt_activity !== "R" && (Number(selectedQuotedData.product_id) < 4 || selectedQuotedData.product_id === "19" || selectedQuotedData.product_id === "20" || selectedQuotedData.product_id === "22" || selectedQuotedData.product_id === "7" || selectedQuotedData.product_id === "6" || selectedQuotedData.product_id === "35") ?
									<>
										<div className={` col-12 col-sm-6 col-md-4 col-lg-4 ${style.colGrp}`}>
											<label className={style.labelText}>Jurisdictional Traffic<span className={style.mandatory}>*</span></label>
											<div className="d-flex flex-row">
												<select className={style.selectFld} id='jurisdiction' name='jurisdiction' onChange={inputHandler} value={selectedOrderProductDetails.jurisdiction}>
													<option value="1">Intrastate</option>
													<option value="2">Interstate</option>
												</select>
												<div className={` ${style.w_10}`}>
													<div className={` ${style.infoTip}`}>
														<img src="../../images/svg/ico-info-filled-dark-off.svg" alt="info tip" className={style.infoImg} />
														<p className={style.contentHover}>
															FUSF exempt customers will default to an Interstate rate plan. Exemption certificates on file with Windstream will dictate monthly taxes and surchanges. For non-exempt customers please see your Windstream Account Executive to discuss executing a global Jurisdiction Traffic form.
														</p>
													</div>
												</div>
											</div>
											<a href={`${process.env.REACT_APP_API_URL}/loadJurisdictionalForm.php?id=${selectedQuotedData.ref_id}`} className={style.lableTextS}>Download Jurisdictional Traffic Certification form</a>
											{/*<a href='../../assets/files/Jurisdictional_Traffic_Certification.pdf' className={style.lableTextS} download>Download Jurisdictional Traffic Certification form</a>*/}
										</div>
									</>
									: null}
								{/*ONLY EACCESS_NNI OR MPLS NNI HAS RAMP UP OPTION */}
								{selectedQuotedData.product_id === "22" || selectedQuotedData.product_id === "7" ?
									<div className={` col-12 col-sm-6 col-md-4 col-lg-4 ${style.colGrp}`}>
										<label htmlFor='rampup' className={style.labelText}>Ramp Up</label>
										<input type='checkbox' id='rampup' name='rampup' className={style.inputFld} onChange={rampUpHandler} checked={selectedOrderProductDetails.rampup ? "checked" : ""}></input>
									</div>
									: null}
								{/*ONLY E-LINE PRODUCT DETAILS*/}
								{selectedQuotedData.product_id === "19" ?
									<>
										{/*Only for E-LINE EVPL*/}
										{selectedQuotedData.circuit_id === "12" ?
											<div className={`col-12 col-sm-6 col-md-4 col-lg-4 ${style.colGrp}`}>
												<label className={style.labelText}>Number of EVC's<span className={style.mandatory}>*</span></label>
												<select
													className={style.inputFld}
													id='eline_evc'
													name='eline_evc'
													onChange={inputHandler}
													value={selectedOrderProductDetails.eline_evc || '1'}>
													{[...Array(9).keys()].map(num => (
														<option key={num + 1} value={num + 1}>
															{num + 1}
														</option>
													))}
												</select>
											</div>
											: null}
										<div className={` col-12 col-sm-6 col-md-4 col-lg-4 ${style.colGrp}`}>
											<label className={style.labelText}>Layer 2 Control Protocol<span className={style.mandatory}>*</span></label>
											<select className={style.selectFld} id='eline_l2cp' name='eline_l2cp' onChange={inputHandler} value={selectedOrderProductDetails.eline_l2cp}>
												<option></option>
												<option value="CTA">CTA</option>
												<option value="CTB">CTB</option>
												<option value="CTB-2">CTB-2</option>
											</select>
										</div>
										<div className={` col-12 col-sm-6 col-md-4 col-lg-4 ${style.colGrp}`}>
											<label className={style.labelText}>MTU SIZE<span className={style.mandatory}>*</span></label>
											<select className={style.selectFld} id='ceMtu' name='ceMtu' onChange={inputHandler} value={selectedOrderProductDetails.ceMtu}>
												<option></option>
												<option value="2000">2000 bytes</option>
												<option value="1534">1534 bytes</option>
											</select>
										</div>
									</> : null
								}
								{/*ONLY FOR E-ACCESS PRODUCT DETAILS*/}
								{selectedQuotedData.product_id === "20" ?
									<>
										<div className={` col-12 col-sm-6 col-md-4 col-lg-4 ${style.colGrp}`}>
											<label className={style.labelText}>Interconnection Point NNI Location<span className={style.mandatory}>*</span></label>
											<select className={style.selectFld} id='interNNi' name='interNNi' onChange={inputHandler} value={selectedOrderProductDetails.interNNi}>
												{nniLocationOptions.map((obj) => {
													return <option value={obj.id}>{obj.name}</option>
												})}
											</select>
										</div>
										<div className={` col-12 col-sm-6 col-md-4 col-lg-4 ${style.colGrp}`}>
											<label className={style.labelText}>NNI Circuit ID<span className={style.mandatory}>*</span></label>
											<select className={style.selectFld} id='nni_circuit_id' name='nni_circuit_id' onChange={inputHandler} value={selectedOrderProductDetails.nni_circuit_id}>
												{nniCircuitIdOptions.map((obj) => {
													return <option value={obj.id}>{obj.name}</option>
												})}
											</select>
										</div>
										<div className={` col-12 col-sm-6 col-md-4 col-lg-4 ${style.colGrp}`}>
											<label className={style.labelText}>MTU SIZE<span className={style.mandatory}>*</span></label>
											<select className={style.selectFld} id='ceMtu' name='ceMtu' onChange={inputHandler} value={selectedOrderProductDetails.ceMtu}>
												<option></option>
												<option value="2000">2000 bytes</option>
												<option value="1534">1534 bytes</option>
												<option value="9000">9000 bytes</option>
												<option value="other">Other</option>
											</select>
										</div>
										{selectedOrderProductDetails.ceMtu === "other" ?
											<div className={` col-12 col-sm-6 col-md-4 col-lg-4 ${style.colGrp}`}>
												<label className={style.labelText}>Other<span className={style.mandatory}>*</span></label>
												<input type='text' className={style.inputFld} maxLength='4' id='mtu_other' name='mtu_other' onChange={inputHandler} value={selectedOrderProductDetails.mtu_other}></input>
											</div> : null
										}
									</> : null
								}
								{/*E-LINE AND E-ACCESS*/}
								{selectedQuotedData.product_id === "19" || selectedQuotedData.product_id === "20" ?
									<>
										<div className={` col-12 col-sm-6 col-md-4 col-lg-4 ${style.colGrp}`}>
											<label className={style.labelText}>VLAN<span className={style.mandatory}>*</span></label>
											<select className={style.selectFld} id='ceVlan' name='ceVlan' onChange={inputHandler} value={selectedOrderProductDetails.ceVlan[0]}>
												<option></option>
												<option value="w">Windstream Assigned</option>
												<option value="c">Customer Assigned</option>
											</select>
										</div>
										{selectedOrderProductDetails.ceVlan[0] === "c" ?
											<div className={` col-12 col-sm-6 col-md-4 col-lg-4 ${style.colGrp}`}>
												<label className={style.labelText}>Customer Assigned<span className={style.mandatory}>*</span></label>
												<input type='text' className={style.inputFld} maxLength='4' id='vlan_customer' name='vlan_customer' onChange={inputHandler} value={selectedOrderProductDetails.vlan_customer}></input>
											</div>
											: null}
									</> : null
								}
							</div>
						</div>
					</div>
					<div className={style.dividers}></div></>
			}
			{popupShow ?
				<div>
					<div className={`${style.overly}`}> </div>
					<div className="modal fade show d-block">
						<div className={` modal-dialog modal-dialog-centered modal-md`}>
							<div className="modal-content">
								<div className="modal-body">
									{popupMsg}
								</div>
								<div className="modal-footer d-block text-center">
									<button type="button" className="btn-style btn-theme" data-dismiss="modal" onClick={popupOk}>Ok</button>
									<button type="button" className="btn-style btn-cancel" data-dismiss="modal" onClick={popupCancel}>Cancel</button>
								</div>

							</div>
						</div>
					</div>
				</div>
				: null
			}
		</>
	)
}


const mapDispatchToProps = {
	updateOrderWANRequirement: setOrderWANRequirement,
	updateOrderLANRequirement: setOrderLANRequirement,
	updatdOrderStaticIp: setOrderStaticIp,
	updateOrderRampUp: setOrderRampUp,
	updateOrderJurisdictionalTraffic: setOrderJurisdictionalTraffic,
	updateOrderMTUSize: setOrderMTUSize,
	updateOrderMTUOther: setOrderOtherField,
	updateOrderVLAN: setOrderVLAN,
	updateOrderCustomerAssigned: setOrderCustomerAssigned,
	updateOrderNNICircuitId: setOrderNNICircuitId,
	updateOrderEVCS: setOrderEVCS,
	updateOrderLayer2Protocol: setOrderLayer2Protocol,
	updateOrderNNIInterconnection: setOrderNNIInterconnection
}

export default connect(null, mapDispatchToProps)(OrderProductDetails);
