import {Logger} from "./Logger";
import {LoggerFunction} from "./LoggerFunction";
import {LogLevel} from "./LogLevel";

const DISABLED: LoggerFunction = (message?: any, ...optionalParams: any[]) => {};

export class ConsoleLogger implements Logger {
    readonly info: LoggerFunction;
    readonly warn: LoggerFunction;
    readonly error: LoggerFunction;

    constructor(options?: {level?: LogLevel}) {

        const level = options?.level;
        this.error = console.error.bind(console);
        this.warn = console.warn.bind(console);
        this.info = console.log.bind(console);
        switch (level) {
            case "OFF":
                this.error = DISABLED;
                this.warn = DISABLED;
                this.info = DISABLED;
                break;
            case "WARN":
                this.info = DISABLED;
                break;
            case "ERROR":
                this.warn = DISABLED;
                this.info = DISABLED;
                break;
        }
    }
}