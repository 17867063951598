import React, { useCallback, useEffect, useState, useMemo } from 'react';
import ViewportWarning from "../shared/ViewportWarning";
import BootstrapTable from 'react-bootstrap-table-next';
import { Link } from 'react-router-dom';
import JSONUtils from '../../utils/JSONUtils';
import ArrayUtils from '../../utils/ArrayUtils';
import XLSXExport from '../../utils/XLSXExport';
import filterFactory from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ReactTooltip from 'react-tooltip';
import { Tooltip } from 'react-tippy';
import style from "./TroubleTickets.module.css";
import Loader from "../shared/Loader";
import CountDownTimer from '../components/CountDownTimer';
import getCircuitHealthData from '../../services/CircuitHealthService';
import { ConsoleLogger } from "../../logger/ConsoleLogger";
import HTTPErrorHandler from "../errorPages/HTTPErrorHandler";
import { connect, useSelector } from 'react-redux';
import CompanyDropdown from '../shared/CompanyDropdown';
import { setCircuitHealthMpid, setCircuitHealth } from '../../redux/actions/networkIntelligenceActions';

const CircuitHealth = (props) => {
	const logger = useMemo(() => new ConsoleLogger({ level: process.env.REACT_APP_LOGGER_LEVEL, }), []);
	const [hasError, setHasError] = useState(null);
	const columnsDesktopView = [{
		dataField: 'circuitID',
		text: 'Circuit ID',
		formatter: (cell, row) => circuitColumnFormatter(cell, row),
		headerFormatter: (column) => headersFormatter(column, 'circuit-id-header')
	}, {
		dataField: 'account',
		text: 'Account',
		headerFormatter: (column) => headersFormatter(column, 'account-header')
	}, {
		dataField: 'product',
		text: 'Product',
		headerFormatter: (column) => headersFormatter(column, 'product-header')
	},
	{
		dataField: 'aloc',
		text: 'ALoc',
		headerFormatter: (column) => headersFormatter(column, 'location-header')
	},
	{
		dataField: 'zloc',
		text: 'ZLoc',
		headerFormatter: (column) => headersFormatter(column, 'location-header')
	},
	{
		dataField: 'events',
		text: 'Scheduled Events',
		formatter: (cell, row) => eventCellFormatter(cell, row),
		headerFormatter: (column) => headersFormatter(column, 'events-header')
	},
	{
		dataField: 'health',
		text: 'Health',
		formatter: (cell, row) => healthColumnFormatter(cell, row),
		headerFormatter: (column) => headersFormatter(column, 'health-header')
	},
	{
		dataField: 'ticket',
		text: 'Open Tickets',
		formatter: (cell, row) => ticketCellFormatter(cell, row),
		headerFormatter: (column) => headersFormatter(column, 'open-tickets-header')
	},
	{
		dataField: 'proactive',
		text: 'Proactive',
		formatter: (cell, row) => proactiveCellFormatter(cell, row),
		headerFormatter: (column) => headersFormatter(column, 'proactive-header')
	}
	];

	const [loader, setLoader] = useState(false);
	const [circuitList, setCircuitList] = useState([]);
	const [CSVKeyData, setCSVKeyData] = useState([]);
	const [upCircuits, setUpCircuits] = useState(0);
	const [downCircuits, setDownCircuits] = useState(0);
	const [errorCircuits, setErrorCircuits] = useState(0);
	const [totalEvents, setTotalEvents] = useState(0);
	const [totalTickets, setTotalTickets] = useState(0);
	const [columns, setColumns] = useState(columnsDesktopView);
	const [toggleSwitchBtn, setToggleSwitchBtn] = useState(false);
	const [dataFlag, setDataFlag] = useState(true);

	const isEmployee = localStorage.getItem("isEmployeeLoggedIn");
	const selectedInternalMpid = useSelector(state => state.internal.company?.value);
	const selectedCircuitHealth = useSelector(state => state.networkIntelligence.circuitHealth);
	const columnsCompactView = columnsDesktopView.slice();
	columnsCompactView.splice(2, 1);

	const circuitColumnFormatter = (props, row) => {
		let cid = row.circuitID ? row.circuitID : '';
		let designId = row.designID ? row.designID : '';
		let id = cid.replace(/\s+/g, '')
		id = id.replace(/\//g, '');
		let URL = `/Circuits/Summary/${id}/${designId}`;
		return (
			<Link to={URL} target="_blank">{cid}</Link>
		)
	}

	const ticketCellFormatter = (props, row) => {
		let URL = '/Network/TicketDetails/';
		if (props !== null) {
			const ticketNo = props.ticketNo ? props.ticketNo : '';
			URL = URL + ticketNo;
			return (
				props.ticketNo ?
					(
						<>
							<Tooltip arrow="true"
								html={
									<table style={{ textAlign: 'left' }}>
										<tr>
											<td>Reported Date: {props.reportedDate}</td>
										</tr>
									</table>
								}
								position={'left-start'} delay={200} hideDelay={200}
							>
								<Link to={URL} target="_blank">{props.ticketNo}</Link>
							</Tooltip>
							<div className="bandwidth-wrap">
								{props.custFlag === true ?
									<>
										<ul>
											<li>{row.customerTicket}</li>
										</ul>
										<ul>
											<li>{props.status}</li>
										</ul>
									</> :
									<ul>
										<li>{props.status}</li>
									</ul>
								}
							</div>

						</>
					) : <></>
			);
		}
	}

	const proactiveCellFormatter = (props, row) => {
		if (row.ticket !== null) {
			const ticket = row.ticket;
			return (
				ticket.proactive ? (<div className="proactive-check">
					<svg className="ico ico-dashboard" />
				</div>) : ''
			);
		}
	}

	const healthColumnFormatter = (props, row) => {
		let classes = 'healthicon';
		let health = false;
		if (props.toLowerCase() === 'up') {
			// COLOR GREEN
			health = true;
			classes += ' healthicon-green';
		} else if (props.toLowerCase() === 'up with alerts') {
			// COLOR ORANGE
			health = true;
			classes += ' healthicon-orange';
		} else if (props.toLowerCase() === 'down') {
			// COLOR RED
			health = true;
			classes += ' healthicon-red';
		} else {
			health = false; //No ticket was found so don't show any health
		}
		return (
			<>
				{health ?
					<>
						<div className={classes} data-tip={props}>
							<ReactTooltip />
							<svg className="ico ico-dashboard" />
						</div>
					</> : null
				}
			</>
		);
	}

	const EventInfoRows1 = ({ event }) => {
		return (
			<table style={{ textAlign: 'left' }}>
				<tr>
					<td>Impact Type: {event.maintenanceType}</td>
				</tr>
				<tr>
					<td>Impact Duration: {event.impactDuration}</td>
				</tr>
				<tr>
					<td>Window Start: {event.fromTimeFrame}</td>
				</tr>
				<tr>
					<td>Window End: {event.toTimeFrame}</td>
				</tr>
			</table>
		);
	}


	const EventsList = (props) => {
		let URL = '';
		let SCHEDULE_INDICATOR = '';
		let CURRENT_TIME;
		let startTime;
		let endTime;
		if (localStorage.getItem("loggedInTime")) {
			CURRENT_TIME = new Date(localStorage.getItem("loggedInTime"));
		}
		const listItems = props.events.map((event) => {
			startTime = new Date(event.fromTimeFrame);
			endTime = new Date(event.toTimeFrame);
			SCHEDULE_INDICATOR = (CURRENT_TIME < startTime && CURRENT_TIME < endTime) ? ' (S)' :
				(CURRENT_TIME > startTime && CURRENT_TIME < endTime) ? ' (A)' : '';
			URL = `/Network/MaintenanceSchedule/${event.eventNumber}`;
			return (
				<li key={event.eventNumber.toString()}>
					<Tooltip arrow="true"
						html={<EventInfoRows1 event={event} />}
						position={'right-start'} delay={200} hideDelay={200} distance={175}
					>
						<Link /*data-tip data-for='event-info'*/ to={URL}
							target="_blank">{event.eventNumber + SCHEDULE_INDICATOR}</Link>
					</Tooltip>
				</li>
			);
		}
		);
		return (
			<>
				<ul>{listItems}</ul>
			</>
		);
	}

	const eventCellFormatter = (props, row) => {
		let CURRENT_TIME;
		if (localStorage.getItem("loggedInTime")) {
			CURRENT_TIME = new Date(localStorage.getItem("loggedInTime"));
		}
		let SCHEDULE_INDICATOR = '';
		let URL = `/Network/MaintenanceSchedule/`;
		if (props.length === 1) {
			const startTime = new Date(props[0].fromTimeFrame);
			const endTime = new Date(props[0].toTimeFrame);
			SCHEDULE_INDICATOR = (CURRENT_TIME < startTime && CURRENT_TIME < endTime) ? ' (S)' :
				(CURRENT_TIME > startTime && CURRENT_TIME < endTime) ? ' (A)' : '';
			URL = URL + `${props[0].eventNumber}`;
		}
		return (
			props.length === 0 ? <></> : props.length === 1 ?
				<Tooltip arrow="true"
					html={<EventInfoRows1 event={props[0]} />}
					position={'left-start'} delay={200} hideDelay={200}
				>
					<Link to={URL} target="_blank">{props[0].eventNumber + SCHEDULE_INDICATOR}</Link>

				</Tooltip>
				:
				(<div className="dropdown2">
					<svg className="ico ico-dashboard ico-svg" />
					<EventsList events={props} />
				</div>)
		);
	}

	const totalEventsCount = (acc, nextObj) => {
		if (acc instanceof Object) {
			acc = acc['events'].length;
		}
		return (acc + nextObj['events'].length);
	}

	const totalTicketsCount = (acc, nextObj) => {
		if (nextObj instanceof Object) {
			const ticket = nextObj['ticket'];
			if (ticket && ticket.ticketNo) {
				acc += 1;
			}
		}
		return acc;
	}

	const headersFormatter = (column, svgID) => {
		let desktopView;
		let compactView;

		if (window.innerWidth > 992 && !toggleSwitchBtn) {
			desktopView = 'block';
			compactView = 'none';
			setColumns(columnsDesktopView);
			setToggleSwitchBtn(true);
		} else {
			desktopView = 'none';
			compactView = 'block';
			setColumns(columnsCompactView);
			setToggleSwitchBtn(false);
		}

		if (column.text === 'Account') {
			return (
				<React.Fragment>
					<div className='desktop-view' style={{ display: desktopView }}>
						{column.text}
					</div>
					<div id={svgID} className='compact-view'>
						<svg className='ico tipped m-auto' style={{ display: compactView }} data-tip={column.text}>
							<ReactTooltip />
						</svg>
					</div>
				</React.Fragment>
			);
		}
		if (column.text === 'ALoc' || column.text === 'ZLoc') {
			return (
				<React.Fragment>
					<div className='desktop-view' style={{ display: desktopView }}>{column.text}</div>
					<div id={svgID} className='tally-ico m-auto compact-view' style={{ display: compactView }}
						data-tip={column.text}>
						<div><span>{column.text.charAt(0)}</span></div>
						<svg className='ico tipped'>
							<ReactTooltip />
						</svg>
					</div>
				</React.Fragment>
			);
		}
		return (
			<React.Fragment>
				<div className='desktop-view' style={{ display: desktopView }}>{column.text}</div>
				<div id={svgID} className='compact-view'>
					<svg className='ico tipped m-auto' style={{ display: compactView }} data-tip={column.text}>
						<ReactTooltip />
						<path />
					</svg>
				</div>
			</React.Fragment>
		);
	}


	const toggleAction = async () => {
		await setToggleSwitchBtn(!toggleSwitchBtn);
		toggleSwitchBtn ? setColumns(columnsCompactView) : setColumns(columnsDesktopView);
	}

	//Function to determine the different circuit health statistics, total events and tickets
	const getCircuitHealthCount = useCallback((data) => {
		let healthCount = 0;
		let totalEvntsCount = 0;
		let totalTcktsCount = 0;

		healthCount = ArrayUtils.groupByKey(data, 'health');
		totalEvntsCount = data.reduce(totalEventsCount, 0);
		totalTcktsCount = data.reduce(totalTicketsCount, 0);

		logger.info(healthCount);
		logger.info(healthCount['DOWN']?.length);
		logger.info(totalEvntsCount);

		setUpCircuits(healthCount['UP'] ? healthCount['UP'].length : 0);
		setDownCircuits(healthCount['DOWN'] ? healthCount['DOWN'].length : 0);
		setErrorCircuits(healthCount['UP WITH ALERTS'] ? healthCount['UP WITH ALERTS'].length : 0);
		setTotalEvents(totalEvntsCount);
		setTotalTickets(totalTcktsCount);
	},[logger])

	//Function to format the circuit health data so it can be exported
	const filterExportData = useCallback((data) => {
		let scheduleIndicator = '';
		let currentTime;
		let startTime;
		let endTime;
		if (localStorage.getItem("loggedInTime")) {
			currentTime = new Date(localStorage.getItem("loggedInTime"));
		}
		JSONUtils.jsonToFlatObject(data).then(CSVData => {
			CSVData.forEach(row => {
				startTime = new Date(row['Window Start']);
				endTime = new Date(row['Window End']);
				scheduleIndicator = (currentTime < startTime && currentTime < endTime) ? ' (S)' :
					(currentTime > startTime && currentTime < endTime) ? ' (A)' : '';
				row['Maintenance'] += scheduleIndicator;
			})
			setCSVKeyData(CSVData);
		}).catch(error => {
			logger.error("JSONUtils.jsonToFlatObject ERROR: " + error)
			setHasError(error);
		})
	},[logger])


	//Pulling circuit health data from our uportal tables
	const getCircuitHealth = useCallback(() => {
		setLoader(true);
		getCircuitHealthData().then(tableRows => {
			logger.info(encodeURIComponent(tableRows.data).replace(/%20/g, " "));
			if (tableRows.data === "No data found") {
				setDataFlag(false);
			} else {
				setDataFlag(true);
				setCircuitList(tableRows.data);
				getCircuitHealthCount(tableRows.data);
				filterExportData(tableRows.data);
				props.updateCircuitHealth(tableRows.data);
			}
			setLoader(false);
		}).catch(error => {
			setLoader(false);
			logger.error("Getting Circuit Health Dashboard Data: " + error);
			setHasError(error);
		});
	}, [filterExportData, getCircuitHealthCount, logger, props])

	//Pulling circuit health data from redux store if it exists and the selectecd mpid matches
	const getCircuitHealthFromStore = useCallback(() => {
		if (selectedCircuitHealth?.circuitHealthData === "No data found") {
			setDataFlag(false);
		} else {
			setDataFlag(true);
			setCircuitList(selectedCircuitHealth?.circuitHealthData);
			getCircuitHealthCount(selectedCircuitHealth?.circuitHealthData);
			filterExportData(selectedCircuitHealth?.circuitHealthData);
		}
	},[filterExportData, getCircuitHealthCount, selectedCircuitHealth?.circuitHealthData])

	useEffect(() => {
		if (isEmployee) {
			if (selectedInternalMpid && selectedInternalMpid !== selectedCircuitHealth?.circuitHealthMpid) {
				props.updateCicuitHealthMpid(selectedInternalMpid);
				props.updateCircuitHealth([]);
				setCircuitList([]);
				getCircuitHealth();
			} else {
				getCircuitHealthFromStore();
			}
		} else {
			if(Object.entries(selectedCircuitHealth?.circuitHealthData)?.length > 0) {
				getCircuitHealthFromStore();
			} else {
				getCircuitHealth();
			}
		}
	}, [getCircuitHealth, getCircuitHealthFromStore, isEmployee, props, selectedCircuitHealth?.circuitHealthData, selectedCircuitHealth?.circuitHealthMpid, selectedInternalMpid]);



	function handleRefresh(countDown) {
		if (countDown < 2) {
			props.updateCircuitHealth([]);
			setCircuitList([]);
			getCircuitHealth();
		}
	}

	return (
		<>
			<ReactTooltip />
			{loader ? <Loader /> : null}
			{hasError && <HTTPErrorHandler error={hasError}></HTTPErrorHandler>}
			<div className="midsection qm-upload abs z1">
				<div className="rel">
					<ViewportWarning />
					<div className="wrap">
						<h1 className="page-title mb-0">{isEmployee ? "Employee Circuit Health Dashboard" : "Circuit Health Dashboard"} </h1>
						{isEmployee ? <CompanyDropdown /> : null}
						<h6 className="page-title mt-0 mb-0">Dashboard displays active trouble tickets as well as active
							and scheduled maintenance events.</h6>
						<h6 className="page-title mt-0 mb-0">Please visit <u><a className="white"
							href="/Network/Summary">My
							Network</a></u> to locate circuit health history for all active circuits</h6>
						{!hasError &&
							(<div className="section w-100 TroubleTicket-page circuit-health-page">
								<div className="content bg-transparent px-0">
									<div className={` ${style.tableWrap} tableWrap`}>
										<div
											className={`rel table-style-wrap pagination-table page-list my-3 troubleTicketTable`}>
											{circuitList.length > 0 && dataFlag ?
												<BootstrapTable id="troubleTicket"
													keyField='id'
													data={circuitList}
													columns={columns}
													hover
													condensed
													bordered={false}
													pagination={paginationFactory()}
													filter={filterFactory()}
													defaultSortDirection="desc"
													className="mb-0"
												/>
												: null
											}
											{!dataFlag ?
												<div className={`text-center ${style.noDataWrap} `}>
													<h1 className="m-0 text-danger">No Data Available</h1>
												</div>
												: null
											}
										</div>
									</div>
									{/* footer */}
									<div className={`footer-info-panel ${style.fixedFooter} `}>
										<div className={`d-flex align-items-center ${style.spaceBetween}`}>
											<div className={`${style.footerGrid}`}>
												<span className={` ${style.menuLabelText} text-white`}>Health</span>
												<span className={`rel ${style.circleWrap} orangeCircle`}>
													<span
														className={` ${style.labelText} ${style.posText} text-white`}>{errorCircuits}</span>
												</span>
												<span className={`rel ${style.circleWrap} greenCircle`}>
													<span
														className={` ${style.labelText} ${style.posText} text-white`}>{upCircuits}</span>
												</span>
												<span className={`rel ${style.circleWrap} redCircle`}>
													<span
														className={` ${style.labelText} ${style.posText} text-white`}>{downCircuits}</span>
												</span>
											</div>
											<div className={`${style.footerGrid}`}>
												<span className={` ${style.menuLabelText} text-white`}>Events</span>
												<span className={`rel ${style.circleWrap}`}>
													<span
														className={` ${style.labelText} ${style.posText} text-white`}>{totalEvents}</span>
												</span>
											</div>
											<div className={`${style.footerGrid}`}>
												<span className={` ${style.menuLabelText} text-white`}>Tickets</span>
												<span className={`rel ${style.circleWrap}`}>
													<span
														className={` ${style.labelText} ${style.posText} text-white`}>{totalTickets}</span>
												</span>
											</div>
											<div className={`${style.footerGrid} ico-link`}>
												<Link to="/Network/Summary"
													className={` ${style.menuLabelText} text-white`}> Inventory
													<svg className={` ico ${style.createTicketIco} `}>
														<path className={style.pathTheme}
															d="M23,35V34H21V30h2V27h8V26H27V25h6v2h2v8ZM18,20c-3,1-8,2-8,6,0,3,5.07,5,7,5h2v2H17c-2.59,0-9-2-9-7,0-6,7-7,10-8s10-2.09,10-5.25C28,10.08,23.93,9,22,9H17V7h5c2.59,0,8,1.82,8,6C30,18.41,21,19,18,20Zm-5-9H1V3H3V1H9V2H5V3h8V6h2v4H13Z"></path>
													</svg>
												</Link>
											</div>
										</div>
										<div className={`d-flex align-items-center ${style.spaceBetween}`}>
											<div className={`${style.footerGrid}`}>
												<button className="saveButton ico-button light-button" onClick={() => XLSXExport.exportToExcel("", CSVKeyData, `Dashboard Export_${Date.now()}`)}>
													<span><strong>Export</strong></span>
													<svg viewBox="0 0 34 34" className={`ico light-ico ico-arrow downloadArrow`}>
														<path className="cls-1" d="M1,18V16L17,31V1h2V31L35,16v2L20,33H35v2H1V33H16Z"
															transform="translate(-1 -1)">
														</path>
													</svg>
												</button>
											</div>
											<div className={`${style.footerGrid} ${style.desktopView}`}>
												<div id="widget-priority"
													className={`toggle-switch ${toggleSwitchBtn ? 'green-toggle on' : ''}`}
													onClick={() => {
														toggleAction()
													}}>
													<div className="ts-bar">
														<div className="ts-left-edge">&nbsp;</div>
														<div className="ts-right-edge">&nbsp;</div>
														<div className={` ts-switch ${style.tsGreenBG} `}>&nbsp;</div>
													</div>
												</div>
												<p className={`  ts-label ts-label-new ${style.menuLabelText} text-white mb-0`}>DESKTOP
													VIEW</p>
											</div>
											{
												!loader ?
													<div className={`${style.footerGrid}`}>
														<p className={`  ts-label ts-label-new ${style.labelText} text-secondary mb-0`}>Refresh
															in</p>
														<CountDownTimer changeFunction={handleRefresh} seconds={300} />
													</div>
													: null
											}
										</div>
									</div>
									{/* footer end */}
								</div>
							</div>)
						}
					</div>
				</div>
			</div>
		</>
	)
}

const mapDispatchToProps = {
    updateCicuitHealthMpid: setCircuitHealthMpid,
    updateCircuitHealth: setCircuitHealth
}

export default connect(null, mapDispatchToProps)(CircuitHealth);
