const FormattingUtils = (function () {

    const formatCurrency = (number) => {
        const LOCALE = 'en-US';
        const CURRENY_SYMBOL = 'USD';
        const CURRENY_OPTIONS = { style: 'currency', currency: CURRENY_SYMBOL, maximumFractionDigits: 2 };
        let currency = null;
        if (!currency) {
            currency = new Intl.NumberFormat(LOCALE, CURRENY_OPTIONS).format(number);
        }
        return currency
    };

    const truncateWithEllipses = (text, max) => {
        return text.substr(0, max - 1) + (text.length > max ? '...' : '');
    }

    return {
        formatCurrency: formatCurrency,
        truncateWithEllipses: truncateWithEllipses
    }
}());

export default FormattingUtils;