import style from "./TicketCreate.module.css";

const InputComponent = (props) => {
    const label = props.obj.label;
    const mandatory = props.obj.mandatory;
    const placeholder = props?.placeholder;
    const className = props?.cName;
    const type = props.type;
    const value = props.val;
    let nameForClass = (['timeOfBounceHrs', 'timeOfcircuitDownHrs', 'timeOfdurationFromHrs', 'timeOfdurationToHrs', 'timeOfpacketLossHrs', 'intrusiveFromHrs', 'intrusiveToHrs']).includes(props.obj.name)

    const inputChange = (e) => {
        props.inputEmit(e);
    }

    return (
        <>
            <label className={`mb-0 textuppercase ${style.labelText} ${props.obj?.hasError ? (style.errorFldText) : null} ${nameForClass ? 'd-none' : 'd-block '} `}>
                {label} {mandatory ? <span className={style.mandatory}> *</span> : null}
            </label>
            <input type={type} name={label} className={className} placeholder={placeholder} onChange={(e) => { inputChange(e) }} value={value} />
        </>
    );
}

export default InputComponent;