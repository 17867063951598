import style from "./TroubleTickets.module.css";
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import ViewportWarning from "../shared/ViewportWarning";
import XLSXExport from "../../utils/XLSXExport";
import Loader from "../shared/Loader";
import StringUtils from '../../utils/StringUtils';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ReactTooltip from 'react-tooltip';
import { Tooltip } from 'react-tippy';
import _ from 'underscore';
import { DebounceInput } from 'react-debounce-input';
import { useCurrentWidth } from 'react-breakpoints-hook';
import { ConsoleLogger } from "../../logger/ConsoleLogger";
import HTTPErrorHandler from "../errorPages/HTTPErrorHandler";
import getTroubleTicketData from "../../services/TroubleTicketService";
import { connect, useSelector } from 'react-redux';
import CompanyDropdown from "../shared/CompanyDropdown";
import { setTroubleTicketMpid, setTroubleTicketDayFilter,setTroubleTickets,  } from "../../redux/actions/networkIntelligenceActions";

const columnsDesktop = [{
    dataField: 'incidentNumber',
    text: 'Ticket',
    sort: true,
    formatter: (cell, row) => ticketNumberFormatter(cell, row),
    filter: textFilter({
        placeholder: 'Ticket'
    }),
},{
    dataField: 'circuitID',
    text: 'Circuit ID',
    sort: true,
    filter: textFilter({
        placeholder: 'circuit ID'
    })
},{
    dataField: 'gaid',
    text: 'Global Account ID',
    sort: true,
    filter: textFilter({
        placeholder: 'Global Account ID'
    })
},{
    dataField: 'status',
    text: 'Status',
    sort: true,
    filter: textFilter({
        placeholder: 'Status'
    })
},{
    dataField: 'customerTicket',
    text: 'Customer Ticket Number',
    sort: true,
    filter: textFilter({
        placeholder: 'Customer ticket Number'
    })
},{
    dataField: 'openDate',
    text: 'Submitted Date',
    sort: true,
    filter: textFilter({
        placeholder: 'Submitted Date'
    })
},{
    dataField: 'lastMod',
    text: 'Last Modified Date',
    sort: true,
    filter: textFilter({
        placeholder: 'Last Modified Date'
    }),

},{
    dataField: 'proactive',
    text: 'Proactive',
    sort: true,
    filter: textFilter({
        placeholder: 'Proactive'
    }),
    headerAttrs: { id: 'proactive' },
    formatter: (cell, row) => proactiveFormatter(cell, row)
},{
    dataField: 'slName',
    text: 'Customer Name',
    headerAttrs: { id: 'slName' },
    hidden: true
},{
    dataField: 'closeNotes',
    text: 'Resolution Notes',
    headerAttrs: { id: 'closeNotes' },
    hidden: true
}
];

const columnsCompact = [{
    dataField: 'incidentNumber',
    text: 'Ticket',
    sort: true,
    headerAttrs: { id: 'Incident' },
    imgSrc: "ico-spec-white.svg",
    headerFormatter: (cell, row) => headerFormatter(cell, row),
    formatter: (cell, row) => ticketNumberFormatter(cell, row),

},{
    dataField: 'circuitID',
    text: 'circuit ID',
    sort: true,
    headerAttrs: { id: 'circuitID' },
    imgSrc: "ico-transport-white.svg",
    headerFormatter: (cell, row) => headerFormatter(cell, row),
}, {
    dataField: 'gaid',
    text: 'Global Account ID',
    sort: true,
    headerAttrs: { id: 'gaid' },
    imgSrc: "ico-product-white.svg",
    headerFormatter: (cell, row) => headerFormatter(cell, row),
},
{
    dataField: 'status',
    text: 'Status',
    sort: true,
    headerAttrs: { id: 'status' },
    imgSrc: "ico-alert-32-white.svg",
    headerFormatter: (cell, row) => headerFormatter(cell, row),
    formatter: (cell, row) => statusFormatter(cell, row)

},
{
    dataField: 'openDate',
    text: 'Open Date',
    sort: true,
    headerAttrs: { id: 'openDate' },
    imgSrc: "ico-clock-white.svg",
    headerFormatter: (cell, row) => headerFormatter(cell, row),

},
{
    dataField: 'lastMod',
    text: 'Last Modified Date',
    sort: true,
    headerAttrs: { id: 'lastMod' },
    imgSrc: "ico-clock-white.svg",
    headerFormatter: (cell, row) => headerFormatter(cell, row),

},
{
    dataField: 'proactive',
    text: 'Proactive',
    sort: true,
    headerAttrs: { id: 'proactive' },
    imgSrc: "ico-proactive.svg",
    headerFormatter: (cell, row) => headerFormatter(cell, row),
    formatter: (cell, row) => proactiveFormatter(cell, row)
}
];

const headerFormatter = (props, row) => {
    return (
        <>
            <ReactTooltip />
            <span data-tip={props.text}>{props.text}</span>
            <img alt="header-icon" className={style.headerIcon} src={`../images/svg/${props.imgSrc}`} data-tip={props.text}></img>
        </>
    )

}
const ticketNumberFormatter = (props, row) => {
    const URL = `/Network/TicketDetails/${row.incidentNumber}`;
    return (
        <Link to={URL}> {row.incidentNumber}</Link>
    );
}

const statusFormatter = (props, row) => {
    return row.status;
}

const proactiveFormatter = (props, row) => {
    return (
        row.proactive ? (<div className="proactive-check">
            <svg className="ico ico-dashboard" />
        </div>) : ''
    );
}

const TroubleTickets = (props) => {
    const logger = new ConsoleLogger({ level: process.env.REACT_APP_LOGGER_LEVEL, });
    const width = useCurrentWidth();
    const [hasError, setHasError] = useState(null);
    const [troubleTicketsData, setTroubleTicketsData] = useState([]);
    const [loader, setLoader] = useState(false);
    const [copyTroubleTicketsData, setCopyTroubleTicketsData] = useState([]);
    const [enableDesktop, setEnableDesktop] = useState(true);
    const [enableSearch, setEnableSearch] = useState(false);
    const [ticketCount, setTicketCount] = useState(0);
    const [exportTicketData, setExportTicketData] = useState(false);
    const [activeTicketCount, setActiveTicketCount] = useState(0);
    const [closedTicketCount, setClosedTicketCount] = useState(0);
    const [showFooterFilters, setShowFooterFilters] = useState(false);

    const isEmployee = localStorage.getItem("isEmployeeLoggedIn");
    const selectedTroubleTicketData = useSelector(state => state.networkIntelligence.troubleTickets);
    const selectedInternalMpid = useSelector(state => state.internal.company?.value);

    //Setting Date Variables
    const [filterDays, setFilterDays] = useState("30");
    const XLSXHeaders = ["Ticket", "Circuit ID", "Global Account ID", "Status", "Customer Ticket", "Submitted Date", "Last Modified Date", "Proactive", "Customer Name", "Resolution Notes"];

    useEffect(() => {
        if (isEmployee) {
            if (selectedInternalMpid && (selectedInternalMpid !== selectedTroubleTicketData.troubleTicketMpid  || selectedTroubleTicketData.troubleTicketDayFilter !== filterDays)) {
                props.updateTroubleTicketData([]);
                props.updateTroubleTicketMpid(selectedInternalMpid);
                props.updateTroubleTicketDayFilter(filterDays);
                getTroubleTickets(filterDays);
            } else {
                getTicketsFromStore();
            }
        } else {
            if(Object.entries(selectedTroubleTicketData.troubleTicketData)?.length > 0 && selectedTroubleTicketData.troubleTicketDayFilter === filterDays) {
                getTicketsFromStore();
            } else {
               getTroubleTickets(filterDays);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isEmployee, selectedInternalMpid, filterDays]);

    useEffect(() => {
        if (width <= 992) {
            setEnableDesktop(false)
        } else {
            setEnableDesktop(true)
        }
    }, [width]);

    const desktopToggle = () => {
        setEnableDesktop(!enableDesktop);
        setEnableSearch(false);
    }

    const searchToggle = () => {
        setEnableSearch(!enableSearch);
        setEnableDesktop(true);
    }

    const getTicketsFromStore = () => {
        sortTickets(selectedTroubleTicketData.troubleTicketData);
        getTicketCounts(selectedTroubleTicketData.troubleTicketData);
        setShowFooterFilters(true);
    }

	//Function to pull all tickets from salesforce and remedy
	const getTroubleTickets = (startDate) => {
		setLoader(true);
		getTroubleTicketData(startDate).then(response => {
            sortTickets(response.data);
            getTicketCounts(response.data);
			setShowFooterFilters(true);
			setLoader(false);
		}).catch(error => {
            setLoader(false);
			logger.error(`getTroubleTickets ERROR in TroubleTickets Page: ${error}`);
			setHasError(error);
		})
	}

    const sortTickets = (data) => {
        data = data.sort((a, b) => {
            return new Date(a.lastMod) - new Date(b.lastMod)
        })
        const sortedData = data.sort((a, b) => {
            if (a.status === "Closed" && b.status !== "Closed") {
                return 1;
            } else {
                return -1;
            }
        });

        setTroubleTicketsData(sortedData);
        setCopyTroubleTicketsData(sortedData);
        filterExportData(sortedData);

        props.updateTroubleTicketData(sortedData);
    }

    const getTicketCounts = (data) => {
        let activeCounter = 0;
        let closedCounter = 0;
        data.forEach((incident) => {
            if (incident.status !== "Closed") {
                activeCounter++;
            } else {
                closedCounter++;
            }
        })
        setActiveTicketCount(activeCounter);
        setClosedTicketCount(closedCounter);
        setTicketCount(data.length);
    }

    //Function to filter ticket based on incident number search
    const onChangeWindstreamTicket = (event) => {
        let inputValue = event.target.value.trim();
        let ticket;
        if (inputValue) {
            ticket = _.filter(copyTroubleTicketsData, (troubleTicketObj) => {
                return StringUtils.stringIncludes(troubleTicketObj.incidentNumber, inputValue);
            });
            if (ticket && ticket.length > 0) {
                setTroubleTicketsData(ticket)
            } else {
                setTroubleTicketsData([])
            }
        } else { // for empty search box
            setTroubleTicketsData(copyTroubleTicketsData)
        }
    }

    //Function to show only active tickets
    const getActiveTickets = () => {
        let activeTickets = [];
        copyTroubleTicketsData.forEach((ticket) => {
            if (ticket.status !== "Closed") {
                activeTickets.push(ticket);
            }
        })
        setTroubleTicketsData(activeTickets);
    }

    //Function to show all closed tickets
    const getClosedTickets = () => {
        let closedTickets = [];
        copyTroubleTicketsData.forEach((ticket) => {
            if (ticket.status === "Closed") {
                closedTickets.push(ticket);
            }
        })
        setTroubleTicketsData(closedTickets);
    }

    //Function to show all tickets
    const getAllTickets = () => {
        setTroubleTicketsData(copyTroubleTicketsData);
    }

    const filterExportData = (data) => {
        const exportArr = data.map((item) => {
            return {
                'Ticket': item.incidentNumber,
                'Circuit ID': item.circuitID,
                'Global Account ID': item.gaid,
                'Status': item.status,
                'Customer Ticket': item.customerTicket,
                'Submitted Date': item.openDate,
                'Last Modified Date': item.lastMod,
                'Proactive': item.proactive,
                'Customer Name': item.slName,
                'Resolution Notes': item.closeNotes
            };
        });
        setExportTicketData(exportArr);
    };

    return (
        <>
            <ReactTooltip />
            {loader ? <Loader /> : null}
            {hasError && <HTTPErrorHandler error={hasError}></HTTPErrorHandler>}
            <div className="midsection qm-upload abs z1">
                <div className="rel">
                    <ViewportWarning />
                    <div className="wrap">
                        <h1 className="page-title mb-0">{isEmployee ? "Employee Trouble Tickets" : "Trouble Tickets"}</h1>
                        {isEmployee ? <CompanyDropdown /> : null}
                        {!hasError && (
                            <>
                            <div className="section w-100 TroubleTicket-page circuitInventoryPage networkPage">
                                <div className="content bg-transparent px-0">
                                    <div className={` ${style.tableWrap} tableWrap`}>
                                        <div className={`rel table-style-wrap network-table pagination-table page-list my-3 troubleTicketTable ${enableSearch ? 'searchableEnableTable' : null} ${enableDesktop ? null : 'desktopViewTable'} `}>
                                            <BootstrapTable id="troubleTicket"
                                                keyField='id'
                                                data={troubleTicketsData}
                                                columns={enableDesktop ? columnsDesktop : columnsCompact}
                                                hover
                                                condensed
                                                bordered={false}
                                                pagination={paginationFactory()}
                                                filter={filterFactory()}
												defaultSortDirection="desc"
                                                className="mb-0"
                                            />
                                            {troubleTicketsData.length === 0 ?
                                                <div className={`text-center ${style.noDataWrap} `}>
                                                    <h1 className="m-0">No Tickets Found</h1>
                                                </div> : null
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {showFooterFilters ?
                                <>
                                {/* footer */}
                                <div className={`footer-info-panel ${style.fixedFooter} `}>
                                        <div className={`d-flex align-items-center ${style.spaceBetween}`}>
                                            <div className={`${style.footerGrid}  ${style.pointer}`} onClick={() => getAllTickets()}>
                                                <Tooltip html={<span>Filter</span>} size="small">
                                                    <div className="justify-content-center">
                                                        <svg class="ico ico-arrow up size-xs"><path d="M10.5,1h-2l16,17L8.5,35h2l17-17Z"></path></svg>
                                                    </div>

                                                </Tooltip>
                                                <span className={` ${style.labelText} ${style.filterTextLabel}`}>All Tickets</span>
                                                <span className={`rel ${style.circleWrap}`}>
                                                    <span className={` ${style.labelText} ${style.posText} text-white`}>{ticketCount}</span>
                                                </span>
                                            </div>
                                            <div className={`${style.footerGrid} ${style.pointer}`} onClick={() => getActiveTickets()}>
                                                <Tooltip html={<span>Filter</span>} size="small">
                                                <div className="justify-content-center">
                                                        <svg class="ico ico-arrow up size-xs"><path d="M10.5,1h-2l16,17L8.5,35h2l17-17Z"></path></svg>
                                                    </div>
                                                    <span className={` ${style.labelText} ${style.filterTextLabel}`}>Active</span>
                                                </Tooltip>
                                                <span className={`rel ${style.circleWrap}`}>
                                                    <span className={` ${style.labelText} ${style.posText} text-white`}>{activeTicketCount}</span>
                                                </span>
                                            </div>
                                            <div className={`${style.footerGrid} ${style.pointer}`} onClick={() => getClosedTickets()}>
                                                <Tooltip html={<span>Filter</span>} size="small">
                                                <div className="justify-content-center">
                                                        <svg class="ico ico-arrow up size-xs"><path d="M10.5,1h-2l16,17L8.5,35h2l17-17Z"></path></svg>
                                                    </div>
                                                    <span className={` ${style.labelText} ${style.filterTextLabel}`}>Closed</span>
                                                </Tooltip>
                                                <span className={`rel ${style.circleWrap}`}>
                                                    <span className={` ${style.labelText} ${style.posText} text-white`}>{closedTicketCount}</span>
                                                </span>
                                            </div>
                                            <div className={`${style.footerGrid} ${style.searchableParams}`}>
                                                <div id="widget-priority" className={` toggle-switch toggle-switch-sm ${enableSearch ? 'on' : ''}`} onClick={searchToggle}>
                                                    <div className="ts-bar">
                                                        <div className="ts-left-edge">&nbsp;</div>
                                                        <div className="ts-right-edge">&nbsp;</div>
                                                        <div className="ts-switch">&nbsp;</div>
                                                    </div>
                                                </div>
                                                <p className={`  ts-label ts-label-new ${style.menuLabelText} text-white`} >SEARCHABLE PARAMETERS</p>
                                            </div>
                                            <div className={`${style.footerGrid} ${style.desktopView}`}>
                                                <div id="widget-priority" className={` toggle-switch toggle-switch-sm ${style.toggleSwitch} ${enableDesktop ? 'green-toggle on' : ''}`} onClick={desktopToggle}>
                                                    <div className="ts-bar">
                                                        <div className="ts-left-edge">&nbsp;</div>
                                                        <div className="ts-right-edge">&nbsp;</div>
                                                        <div className={` ts-switch ${style.tsGreenBG} `}>&nbsp;</div>
                                                    </div>
                                                </div>
                                                <p className={`  ts-label ts-label-new ${style.menuLabelText} text-white mb-0`} >DESKTOP VIEW</p>
                                            </div>
                                            {!isEmployee ?
                                                <div className={`${style.footerGrid}`}>
                                                    <Link to="/Ticket/Create" className={` ${style.labelText} ${style.textTheme}`}> Create Ticket
                                                        <svg className={` ico ${style.createTicketIco} `}><path className={style.pathTheme} d="M16,8.9h2v10H16Zm2,14H16v-2h2Zm14-1H30l-1-1v-7a12.9,12.9,0,0,0-1.73-6.32A11.55,11.55,0,0,0,23,3.33a11.93,11.93,0,0,0-12,0A11.55,11.55,0,0,0,6.73,7.58,12.9,12.9,0,0,0,5,13.9v8A8.87,8.87,0,0,0,6.73,27,11.14,11.14,0,0,0,11,30.91c1.86,1,3.87,1,6,1h3v-1H16l-1-1,1-1h4c.68,0,.88.35,1.37.85a2.44,2.44,0,0,1,.73,1.77,2.38,2.38,0,0,1-.73,1.75A1.54,1.54,0,0,1,20,33.9H17a13.92,13.92,0,0,1-6.83-1.63,12.74,12.74,0,0,1-4.9-4.56A13.59,13.59,0,0,1,3,20.9v-7A14.63,14.63,0,0,1,5.27,6.77a13.24,13.24,0,0,1,4.9-4.9,13.41,13.41,0,0,1,13.66,0,13.24,13.24,0,0,1,4.9,4.9A14.63,14.63,0,0,1,31,13.9v6h1v-6l1.08-1.08L34,13.9v7Zm-32-2v-6l1-1,1,1v6l-1,1Z"></path></svg>
                                                    </Link>
                                                </div> :  null
                                            }
                                        </div>

                                        <div className={`d-flex align-items-center ${style.spaceBetween}`}>
                                            <div className={`${style.footerGrid} w-50 rel searchWrap d-flex`}>
													<DebounceInput type="search" className={`${style.searchFld} ${style.circuitInventorySearchFld}`} placeholder="Search by Ticket" debounceTimeout={300} onKeyUp={event => onChangeWindstreamTicket(event)} />
                                                <img alt={""} className={` ${style.searchIcoInputField} ${style.searchIcoInputFieldCirInventory}`} src="../../images/svg/ico-search-fill.svg" />
                                                <Tooltip html={'Search on anything, such as a circuit ID, account number, or ticket number to find that information.'} position={'top'}>
                                                    <img data-tip="" className={`tipped cornertip d-flex ${style.posRight} ${style.infoIcoCircuitInventory}`} src="../../images/svg/ico-info-filled-dark-off.svg" width="36" alt="" />
                                                </Tooltip>
                                            </div>
                                            <div className={`${style.footerGrid} ${style.footerdateSearchWrap} rel searchWrap d-flex`}>
                                                <div>
                                                    <span className={` ${style.dateLabel} text-white`}>Display Period</span>
                                                    <select className={`form-control ml-2 ${style.daysDropdown} `} value={filterDays} onChange={(event) => setFilterDays(event.target.value)}>
                                                        <option value="30">30 days</option>
                                                        <option value="60">60 days</option>
                                                        <option value="90">90 days</option>
                                                        <option value="180">180 days</option>
                                                        <option value="365">365 days</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className={`${style.footerGrid}`}>
                                                <button className="ico-button light-button"  onClick={() => XLSXExport.exportToExcel(XLSXHeaders,exportTicketData,`Dashboard Export_${Date.now()}`)}>
                                                    <span><strong>Export</strong></span>
                                                    <svg viewBox="0 0 34 34" className={`ico light-ico ico-arrow downloadArrow`}>
                                                        <path className="cls-1" d="M1,18V16L17,31V1h2V31L35,16v2L20,33H35v2H1V33H16Z"
                                                            transform="translate(-1 -1)">
                                                        </path>
                                                    </svg>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    {/* footer end */} </> : null
                                }</>)
                        }
                    </div>
                </div>
            </div>
        </>
    );
}


const mapDispatchToProps = {
    updateTroubleTicketMpid: setTroubleTicketMpid,
    updateTroubleTicketDayFilter: setTroubleTicketDayFilter,
    updateTroubleTicketData: setTroubleTickets
}

export default connect(null, mapDispatchToProps)(TroubleTickets);
