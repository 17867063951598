import headerImage from './Images/service-quote-header-650x81.jpg';
import renewalHeaderImage from './Images/renewal_quote_header.jpg';
import { Page, Text, View, Document, Image } from '@react-pdf/renderer';
import { styles } from './pdfStyles';

const QuotePDF = (props) => {
	const lineItems = props.quoteData?.pdf?.lineitems || {};
	let lastOption = null;
	var regex = /[0-9]/;

	const formatItemText = (itemText) => {
		return itemText.replace(' - (', '\n');
	};

	const formatTotalsText = (desc, months) => {
		return `${desc} Totals - ${months}`.replace(' - ', '\n');
	};

	const HeaderImage = () => (
		<Image style={styles.imageHeader} src={props.quoteData?.q_cpt_activity === 'R' ? renewalHeaderImage : headerImage} />
	);

	return (
		<Document>
			<Page size="A4" style={styles.page}>
				<HeaderImage />
				<View style={{ height: 10 }}></View>
				<View style={styles.headerBar}>
					<Text style={styles.headerBarText}>Quote</Text>
				</View>

				<View style={styles.table}>
					<View style={styles.tableRow}>
						<Text style={[styles.headerColumn, styles.tableCell, styles.textBold]}>Company Name</Text>
						<Text style={[styles.tableColumn, styles.tableCell, styles.textNormal]}>{props.quoteData?.company}</Text>
						<Text style={[styles.headerColumn, styles.tableCell, styles.textBold]}>Reference ID</Text>
						<Text style={[styles.tableColumn, styles.tableCell, styles.textNormal]}>{props.quoteData?.ref_id}</Text>
					</View>
					<View style={styles.tableRow}>
						<Text style={[styles.headerColumn, styles.tableCell, styles.textBold]}>Quote Name</Text>
						<Text style={[styles.tableColumn, styles.tableCell, styles.textNormal]}>{props.quoteData?.bus_name}</Text>
						<Text style={[styles.headerColumn, styles.tableCell, styles.textBold]}>Customer Quote ID</Text>
						<Text style={[styles.tableColumn, styles.tableCell, styles.textNormal]}>{props.quoteData?.cp_quote_ref_id}</Text>
					</View>
					<View style={styles.tableRow}>
						<Text style={[styles.headerColumn, styles.tableCell, styles.textBold]}>Product</Text>
						<Text style={[styles.tableColumn, styles.tableCell, styles.textNormal]}>{props.quoteData?.product}</Text>
						<Text style={[styles.headerColumn, styles.tableCell, styles.textBold]}>ICB Number</Text>
						<Text style={[styles.tableColumn, styles.tableCell, styles.textNormal]}>{props.quoteData?.icb_number}</Text>
					</View>
					<View style={styles.tableRow}>
						<Text style={[styles.headerColumn, styles.tableCell, styles.textBold]}>Circuit</Text>
						<Text style={[styles.tableColumn, styles.tableCell, styles.textNormal]}>{props.quoteData?.circuit}</Text>
						<Text style={[styles.headerColumn, styles.tableCell, styles.textBold]}>Quote Submitted</Text>
						<Text style={[styles.tableColumn, styles.tableCell, styles.textNormal]}>{props.quoteData?.open_date}</Text>
					</View>
					<View style={styles.tableRow}>
						<Text style={[styles.headerColumn, styles.tableCell, styles.textBold]}>Valid Until</Text>
						<Text style={[styles.tableColumn, styles.tableCell, styles.textNormal]}>{props.quoteData?.valid_until}</Text>
						<Text style={[styles.headerColumn, styles.tableCell, styles.textBold]}></Text>
						<Text style={[styles.tableColumn, styles.tableCell, styles.textNormal]}></Text>
					</View>
				</View>
				<View style={styles.headerBar}>
					<Text style={styles.headerBarText}>Location</Text>
				</View>
				<View style={styles.table}>
					{/* Table row with mixed styles */}
					<View style={styles.tableRow}>
						<Text style={[styles.tableColumn2Width, styles.tableCell, styles.textBold]}>Location A {props.quoteData?.la_loc_type ? ` - ${props.quoteData?.la_loc_type}` : ''}</Text>
						<Text style={[styles.tableColumn2Width, styles.tableCell, styles.textNormal]}></Text>
						<Text style={[styles.tableColumn2Width, styles.tableCell, styles.textBold]}>Location Z {props.quoteData?.lz_loc_type ? ` - ${props.quoteData?.lz_loc_type}` : ''}</Text>
						<Text style={[styles.tableColumn2Width, styles.tableCell, styles.textNormal]}></Text>
					</View>
					<View style={styles.tableRow}>
						<Text style={[styles.tableColumn, styles.tableCell, styles.textNormal]}>{props.quoteData?.la_add}</Text>
						<Text style={[styles.tableColumn, styles.tableCell, styles.textNormal]}>{props.quoteData?.lz_add}</Text>
					</View>
					<View style={styles.tableRow}>
						<Text style={[styles.tableColumn2Width, styles.tableCell, styles.textNormal]}>{props.quoteData?.la_cityStateZip}</Text>
						<Text style={[styles.tableColumn2Width, styles.tableCell, styles.textNormal]}>{props.quoteData?.lz_cityStateZip}</Text>
					</View>
					{props.quoteData?.locA_provider || props.quoteData?.locZ_provider ?
						<View style={styles.tableRow}>
							<Text style={[styles.headerColumn, styles.tableCell, styles.textBold]}>Demarc Provider</Text>
							<Text style={[styles.tableColumn, styles.tableCell, styles.textNormal]}>{props.quoteData?.locA_provider}</Text>
							<Text style={[styles.headerColumn, styles.tableCell, styles.textBold]}>Demarc Provider</Text>
							<Text style={[styles.tableColumn, styles.tableCell, styles.textNormal]}>{props.quoteData?.locZ_provider}</Text>
						</View>
						: null}
				</View>

				<View style={styles.headerBar}>
					<Text style={styles.headerBarText}>Services</Text>
				</View>
				{lineItems && Object.keys(lineItems).length > 0 && Object.keys(lineItems).map((option, index) => {
					return Object.keys(lineItems[option]).map((term, colIndex) => {
						const styleForIteration = index === 0 && colIndex === 0 ? styles.table : styles.tableTop;
						if (term.match(regex)) {
							const isFirstTermOfOption = lastOption !== option;
							if (isFirstTermOfOption) {
								lastOption = option;
							}
							return (
								<>
									<View wrap={false} style={styleForIteration}>
										{isFirstTermOfOption ?
											<>
												<View key={index} style={styles.tableRow}>
													<Text style={[styles.tableColumn2Width, styles.tableCellCenter, styles.textBold]}>
														{props.quoteData?.circuit} {lineItems[option]?.bw} {lineItems[option]?.protocol} Protocol {lineItems[option]?.icb_price_desc ? '- ' + lineItems[option]?.icb_price_desc : ''}
													</Text>
												</View>
												<View style={styles.tableRow}>
													<Text style={[styles.tableColumn2Width, styles.tableCellCenter, styles.textBold]}></Text>
													<Text style={[styles.quantityColumn, styles.tableCellCenter, styles.textNormal]}>Quantity</Text>
													<Text style={[styles.tableColumn, styles.tableCellCenter, styles.textNormal]}>MRC</Text>
													<Text style={[styles.tableColumn, styles.tableCellCenter, styles.textNormal]}>NRC</Text>
												</View>
											</>
											: null}
										<View style={styles.pricingTable}>
											<View style={styles.pricingHeaderRow}>
												<Text style={[styles.tableColumn, styles.tableCell, styles.textBold]}>
													{lineItems[option]?.icb_price_desc ? lineItems[option]?.icb_price_desc + ' - ' : ''} {term} Year Term
												</Text>
											</View>
											{Object.keys(lineItems[option][term]).map((line, index) => {
												if (line.match(regex)) {
													let price_mrc = lineItems[option][term][line].price_mrc;
													let price_nrc = lineItems[option][term][line].price_nrc;
													if ((lineItems[option][term][line]['type'] === '333') || (lineItems[option][term][line]['type'] === '332')) {
														if (price_mrc === 'NO BID') {
															price_mrc = ' ';
														}
														if (price_nrc === 'NO BID') {
															price_nrc = ' ';
														}
													}
													return (
														<View style={styles.tableRow} key={`${line}-${index}`}>
															<Text style={[styles.tableColumn2Width, styles.tableCell, styles.textNormal]}>
																{formatItemText(lineItems[option][term][line].line_desc)}
															</Text>
															<Text style={[styles.quantityColumn, styles.tableCellCenter, styles.textNormal]}>
																{lineItems[option]?.qty}
															</Text>
															<Text style={[styles.tableColumn, styles.tableCellCenter, styles.textNormal]}>
																{price_mrc}
															</Text>
															<Text style={[styles.tableColumn, styles.tableCellCenter, styles.textNormal]}>
																{price_nrc}
															</Text>
														</View>
													);
												}
											})}
											{lineItems[option]?.icb_price_type === '2' || lineItems[option]?.icb_price_type === '4' ?
												<>
													<View style={styles.tableRowBorderTop}>
														<Text style={[styles.tableColumn2Width, styles.tableCell, styles.textBold]}>
															{formatTotalsText(lineItems[option]?.icb_price_desc, lineItems[option]?.varMonths)}
														</Text>
														<Text style={[styles.quantityColumn, styles.tableCellCenter, styles.textNormal]}>
														</Text>
														<Text style={[styles.tableColumn, styles.tableCellCenter, styles.textBold]}>
															{lineItems[option][term]?.total_mrc}
														</Text>
														<Text style={[styles.tableColumn, styles.tableCellCenter, styles.textBold]}>
															{lineItems[option][term]?.total_nrc}
														</Text>
													</View>
													<View style={styles.tableRow}>
														<Text style={[styles.tableColumn2Width, styles.tableCell, styles.textBold]}>
															{formatTotalsText(lineItems[option]?.icb_price_desc, lineItems[option]?.postMonths)}
														</Text>
														<Text style={[styles.quantityColumn, styles.tableCellCenter, styles.textNormal]}>
														</Text>
														<Text style={[styles.tableColumn, styles.tableCellCenter, styles.textBold]}>
															{lineItems[option][term]?.post_mrc}
														</Text>
														<Text style={[styles.tableColumn, styles.tableCellCenter, styles.textBold]}>
															{lineItems[option][term]?.post_nrc}
														</Text>
													</View>
												</>
												:
												<View style={styles.tableRowBorderTop}>
													<Text style={[styles.tableColumn2Width, styles.tableCell, styles.textBold]}>
														{lineItems[option]?.icb_price_desc} Totals
													</Text>
													<Text style={[styles.quantityColumn, styles.tableCellCenter, styles.textBold]}>
														{lineItems[option]?.qty}
													</Text>
													<Text style={[styles.tableColumn, styles.tableCellCenter, styles.textBold]}>
														{lineItems[option][term]?.total_mrc}
													</Text>
													<Text style={[styles.tableColumn, styles.tableCellCenter, styles.textBold]}>
														{lineItems[option][term]?.total_nrc}
													</Text>
												</View>}
											{lineItems[option]?.icb_price_type === '2' || lineItems[option]?.icb_price_type === '3' || lineItems[option]?.icb_price_type === '4' ?
												<View style={styles.tableRow}>
													<Text style={[styles.tableColumn2Width, styles.tableCell, styles.textBold, styles.blueFont]}>
														Average MRC
													</Text>
													<Text style={[styles.quantityColumn, styles.tableCellCenter, styles.textBold]}>
													</Text>
													<Text style={[styles.tableColumn, styles.tableCellCenter, styles.textBold, styles.blueFont]}>
														{lineItems[option][term]?.average_mrc}
													</Text>
													<Text style={[styles.tableColumn, styles.tableCell, styles.textBold, styles.blueFont]}>
														{lineItems[option][term]?.average_nrc}
													</Text>
												</View>
												: null}
										</View>
									</View>
								</>
							)
							lastOption = option;
						} else {
							return null;
						}
					})


				})}


				{props.quoteData?.variablePricing ?
					<View style={styles.table} wrap={false}>
						<View style={styles.tableRow}>
							<Text style={[styles.tableCell, styles.textSmall]}>
								Average MRC is the cumulative MRC over the length of the service contract. Average is calculated using monthly recurring charges and any one time supplemental charges for the length of the term  Upon expiration of the term, the billing will revert to Average Equivalent MRC as defined, unless otherwise noted.
							</Text>
						</View>
					</View>
					: null}
				<View wrap={false}>
					<View style={styles.headerBarTopMargin}>
						<Text style={styles.headerBarText}>Details</Text>
					</View>
					<View style={styles.table}>
						{props.quoteData?.exist_circuit?.length > 0 ?
							<View style={styles.tableRow}>
								<Text style={[styles.tableColumn, styles.tableCell, styles.textBold]}>Circuit ID</Text>
								<Text style={[styles.tableColumn, styles.tableCell, styles.textNormal]}>{props.quoteData?.exist_circuit}</Text>
							</View>
							: null}
						<View style={styles.tableRow}>
							<Text style={[styles.tableColumn, styles.tableCell, styles.textBold]}>Number of Routes</Text>
							<Text style={[styles.tableColumn, styles.tableCell, styles.textNormal]}>{props.quoteData?.num_routes_desc}</Text>
						</View>
						<View style={styles.tableRow}>
							<Text style={[styles.tableColumn, styles.tableCell, styles.textBold]}>Diversity</Text>
							<Text style={[styles.tableColumn, styles.tableCell, styles.textNormal]}>{props.quoteData?.diversity_desc}</Text>
						</View>
						<View style={styles.tableRow}>
							<Text style={[styles.tableColumn, styles.tableCell, styles.textBold]}>KMZ</Text>
							<Text style={[styles.tableColumn, styles.tableCell, styles.textNormal]}>{props.quoteData?.kmz_file}</Text>
						</View>
						<View style={styles.tableRow}>
							<Text style={[styles.tableColumn, styles.tableCell, styles.textBold]}>Latency Requested</Text>
							<Text style={[styles.tableColumn, styles.tableCell, styles.textNormal]}>{props.quoteData?.route_latency}</Text>
						</View>
					</View>
				</View>
				<View wrap={false}>
					<View style={styles.headerBarTopMargin}>
						<Text style={styles.headerBarText}>Customer Contact</Text>
					</View>
					<View style={styles.table}>
						<View style={styles.tableRow}>
							<Text style={[styles.tableColumn, styles.tableCell, styles.textBold]}>Customer Contact</Text>
							<Text style={[styles.tableColumn, styles.tableCell, styles.textNormal]}>{props.quoteData?.cp_contact}</Text>
						</View>
						<View style={styles.tableRow}>
							<Text style={[styles.tableColumn, styles.tableCell, styles.textBold]}>Customer Phone</Text>
							<Text style={[styles.tableColumn, styles.tableCell, styles.textNormal]}>{props.quoteData?.cp_phone}</Text>
						</View>
						<View style={styles.tableRow}>
							<Text style={[styles.tableColumn, styles.tableCell, styles.textBold]}>Customer Email</Text>
							<Text style={[styles.tableColumn, styles.tableCell, styles.textNormal]}>{props.quoteData?.cp_email}</Text>
						</View>
					</View>
				</View>
				<View wrap={false}>
					<View style={styles.headerBarTopMargin}>
						<Text style={styles.headerBarText}>Notices</Text>
					</View>
					<View style={styles.table}>
						<View style={styles.tableRow}>
							<Text style={[styles.tableCell, styles.textNormal]}>
								This Service Quote expires 90 days from the date hereof. This document is for discussion purposes only; it is not a legal offer.
							</Text>
						</View>
						<View style={styles.tableRowBorderBottom}>
							<Text style={[styles.tableCell, styles.textNormal]}>
								Pricing is for BUDGETARY purposes only. Service Inquiry/site survey will be required to provide firm pricing and availability. If firm pricing and availability are required please reply, and request a formal Service Inquiry. Please allow up to 10 business days for Service Inquiries to complete.
							</Text>
						</View>
					</View>
				</View>

			</Page >
		</Document >
	);
}

export default QuotePDF;