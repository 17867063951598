import {useState} from "react";
import {Tooltip} from "react-tippy";
import ViewportWarning from "../shared/ViewportWarning";
import style from "./Charts.module.css";
import ChartUI from "./ChartUI";

const LayerZero = () => {
    const [displayFormat, setDisplayFormat] = useState([{ type: '30', text: 'Display 30 Days', title: 'Past 30 Days' }, { type: '24', text: 'Display 24 HRS', title: 'Past 24 Hrs' }]);

    const c1 = [
        { x: "02-15 13:00:00", y: 22.5 },
        { x: "02-16 13:00:00", y: 21.9 },
        { x: "02-17 13:00:00", y: 21.9 },
        { x: "02-18 13:00:00", y: 22 },
        { x: "02-19 13:00:00", y: 21.7 },
        { x: "02-20 13:00:00", y: 21.9 },
        { x: "02-21 13:00:00", y: 22.2 },
        { x: "02-22 13:00:00", y: 22.5 },
        { x: "02-23 13:00:00", y: 21.9 },
        { x: "02-24 13:00:00", y: 21.7 },
        { x: "02-25 13:00:00", y: 22 },
        { x: "02-26 13:00:00", y: 22.6 },
        { x: "02-27 13:00:00", y: 22.7 },
        { x: "02-28 13:00:00", y: 22.1 },
        { x: "03-01 13:00:00", y: 22 },
        { x: "03-02 13:00:00", y: 22.5 },
        { x: "03-03 13:00:00", y: 21.8 },
        { x: "03-04 13:00:00", y: 22.5 },
        { x: "03-05 13:00:00", y: 22 },
        { x: "03-06 13:00:00", y: 22.4 },
        { x: "03-07 13:00:00", y: 22.2 },
        { x: "03-08 13:00:00", y: 21.8 },
        { x: "03-09 13:00:00", y: 22.7 },
        { x: "03-10 13:00:00", y: 22.2 },
        { x: "03-11 13:00:00", y: 22.7 },
        { x: "03-12 13:00:00", y: 22.5 },
        { x: "03-13 13:00:00", y: 22.1 },
        { x: "03-14 13:00:00", y: 21.9 },
        { x: "03-15 13:00:00", y: 21.9 },
        { x: "03-16 13:00:00", y: 22.4 }
    ];

    const c2 = [
        { x: "02-15 13:00:00", y: 22.6 },
        { x: "02-16 13:00:00", y: 22.4 },
        { x: "02-17 13:00:00", y: 21.7 },
        { x: "02-18 13:00:00", y: 22 },
        { x: "02-19 13:00:00", y: 22.5 },
        { x: "02-20 13:00:00", y: 22.2 },
        { x: "02-21 13:00:00", y: 21.7 },
        { x: "02-22 13:00:00", y: 22.7 },
        { x: "02-23 13:00:00", y: 22.4 },
        { x: "02-24 13:00:00", y: 22.2 },
        { x: "02-25 13:00:00", y: 22.6 },
        { x: "02-26 13:00:00", y: 22.5 },
        { x: "02-27 13:00:00", y: 22.6 },
        { x: "02-28 13:00:00", y: 22 },
        { x: "03-01 13:00:00", y: 21.7 },
        { x: "03-02 13:00:00", y: 22.6 },
        { x: "03-03 13:00:00", y: 22 },
        { x: "03-04 13:00:00", y: 21.8 },
        { x: "03-05 13:00:00", y: 22 },
        { x: "03-06 13:00:00", y: 22.4 },
        { x: "03-07 13:00:00", y: 22.2 },
        { x: "03-08 13:00:00", y: 22.4 },
        { x: "03-09 13:00:00", y: 22 },
        { x: "03-10 13:00:00", y: 22.4 },
        { x: "03-11 13:00:00", y: 22.7 },
        { x: "03-12 13:00:00", y: 22.4 },
        { x: "03-13 13:00:00", y: 22.2 },
        { x: "03-14 13:00:00", y: 21.8 },
        { x: "03-15 13:00:00", y: 22 },
        { x: "03-16 13:00:00", y: 22.3 }
    ];

    const c3 = [
        { x: "02-15 13:00:00", y: 2 },
        { x: "02-16 13:00:00", y: 3 },
        { x: "02-17 13:00:00", y: 2 },
        { x: "02-18 13:00:00", y: 2 },
        { x: "02-19 13:00:00", y: 4 },
        { x: "02-20 13:00:00", y: 3 },
        { x: "02-21 13:00:00", y: 2 },
        { x: "02-22 13:00:00", y: 5 },
        { x: "02-23 13:00:00", y: 5 },
        { x: "02-24 13:00:00", y: 2 },
        { x: "02-25 13:00:00", y: 3 },
        { x: "02-26 13:00:00", y: 4 },
        { x: "02-27 13:00:00", y: 2 },
        { x: "02-28 13:00:00", y: 2 },
        { x: "03-01 13:00:00", y: 1 },
        { x: "03-02 13:00:00", y: 1 },
        { x: "03-03 13:00:00", y: 4 },
        { x: "03-04 13:00:00", y: 1 },
        { x: "03-05 13:00:00", y: 2 },
        { x: "03-06 13:00:00", y: 1 },
        { x: "03-07 13:00:00", y: 2 },
        { x: "03-08 13:00:00", y: 5 },
        { x: "03-09 13:00:00", y: 3 },
        { x: "03-10 13:00:00", y: 1 },
        { x: "03-11 13:00:00", y: 3 },
        { x: "03-12 13:00:00", y: 1 },
        { x: "03-13 13:00:00", y: 3 },
        { x: "03-14 13:00:00", y: 1 },
        { x: "03-15 13:00:00", y: 1 },
        { x: "03-16 13:00:00", y: 1 }
    ];

    const c4 = [
        { x: "02-15 13:00:00", y: 3 },
        { x: "02-16 13:00:00", y: 1 },
        { x: "02-17 13:00:00", y: 4 },
        { x: "02-18 13:00:00", y: 5 },
        { x: "02-19 13:00:00", y: 3 },
        { x: "02-20 13:00:00", y: 1 },
        { x: "02-21 13:00:00", y: 5 },
        { x: "02-22 13:00:00", y: 5 },
        { x: "02-23 13:00:00", y: 1 },
        { x: "02-24 13:00:00", y: 1 },
        { x: "02-25 13:00:00", y: 4 },
        { x: "02-26 13:00:00", y: 1 },
        { x: "02-27 13:00:00", y: 4 },
        { x: "02-28 13:00:00", y: 1 },
        { x: "03-01 13:00:00", y: 2 },
        { x: "03-02 13:00:00", y: 1 },
        { x: "03-03 13:00:00", y: 4 },
        { x: "03-04 13:00:00", y: 5 },
        { x: "03-05 13:00:00", y: 5 },
        { x: "03-06 13:00:00", y: 4 },
        { x: "03-07 13:00:00", y: 3 },
        { x: "03-08 13:00:00", y: 1 },
        { x: "03-09 13:00:00", y: 1 },
        { x: "03-10 13:00:00", y: 3 },
        { x: "03-11 13:00:00", y: 2 },
        { x: "03-12 13:00:00", y: 5 },
        { x: "03-13 13:00:00", y: 4 },
        { x: "03-14 13:00:00", y: 3 },
        { x: "03-15 13:00:00", y: 3 },
        { x: "03-16 13:00:00", y: 3 }
    ]

    const c5 = [
        { x: "02-15 13:00:00", y: 14951 },
        { x: "02-16 13:00:00", y: 15009 },
        { x: "02-17 13:00:00", y: 14927 },
        { x: "02-18 13:00:00", y: 14938 },
        { x: "02-19 13:00:00", y: 14998 },
        { x: "02-20 13:00:00", y: 14947 },
        { x: "02-21 13:00:00", y: 14982 },
        { x: "02-22 13:00:00", y: 14934 },
        { x: "02-23 13:00:00", y: 14985 },
        { x: "02-24 13:00:00", y: 15002 },
        { x: "02-25 13:00:00", y: 14981 },
        { x: "02-26 13:00:00", y: 14925 },
        { x: "02-27 13:00:00", y: 14929 },
        { x: "02-28 13:00:00", y: 14932 },
        { x: "03-01 13:00:00", y: 14987 },
        { x: "03-02 13:00:00", y: 14963 },
        { x: "03-03 13:00:00", y: 14920 },
        { x: "03-04 13:00:00", y: 14945 },
        { x: "03-05 13:00:00", y: 14928 },
        { x: "03-06 13:00:00", y: 14968 },
        { x: "03-07 13:00:00", y: 14999 },
        { x: "03-08 13:00:00", y: 14956 },
        { x: "03-09 13:00:00", y: 14989 },
        { x: "03-10 13:00:00", y: 14978 },
        { x: "03-11 13:00:00", y: 14968 },
        { x: "03-12 13:00:00", y: 14923 },
        { x: "03-13 13:00:00", y: 14954 },
        { x: "03-14 13:00:00", y: 14964 },
        { x: "03-15 13:00:00", y: 14997 },
        { x: "03-16 13:00:00", y: 15002 }
    ]

    const c6 = [
        { x: "02-15 13:00:00", y: 14939 },
        { x: "02-16 13:00:00", y: 14964 },
        { x: "02-17 13:00:00", y: 14942 },
        { x: "02-18 13:00:00", y: 14971 },
        { x: "02-19 13:00:00", y: 14933 },
        { x: "02-20 13:00:00", y: 14926 },
        { x: "02-21 13:00:00", y: 14979 },
        { x: "02-22 13:00:00", y: 14985 },
        { x: "02-23 13:00:00", y: 14987 },
        { x: "02-24 13:00:00", y: 14994 },
        { x: "02-25 13:00:00", y: 14934 },
        { x: "02-26 13:00:00", y: 14995 },
        { x: "02-27 13:00:00", y: 14948 },
        { x: "02-28 13:00:00", y: 14933 },
        { x: "03-01 13:00:00", y: 14919 },
        { x: "03-02 13:00:00", y: 14921 },
        { x: "03-03 13:00:00", y: 14976 },
        { x: "03-04 13:00:00", y: 15013 },
        { x: "03-05 13:00:00", y: 14983 },
        { x: "03-06 13:00:00", y: 14970 },
        { x: "03-07 13:00:00", y: 14974 },
        { x: "03-08 13:00:00", y: 14984 },
        { x: "03-09 13:00:00", y: 14993 },
        { x: "03-10 13:00:00", y: 14935 },
        { x: "03-11 13:00:00", y: 14987 },
        { x: "03-12 13:00:00", y: 14981 },
        { x: "03-13 13:00:00", y: 14945 },
        { x: "03-14 13:00:00", y: 14999 },
        { x: "03-15 13:00:00", y: 14992 },
        { x: "03-16 13:00:00", y: 14989 }
    ]


    const displayAction = () => {
        let changePos = [displayFormat[0], displayFormat[1]] = [displayFormat[1], displayFormat[0]];
        setDisplayFormat(changePos);
    }

    return (
        <div className="midsection qm-upload abs z1">
            <div className="rel">
                <ViewportWarning />
                <div className="wrap">
                    <h1 className="page-title"> Layer Zero</h1>
                    <div className="section">
                        <div className="content">

                            <div className={style.layerWrap}>
                                <h6 className={` m-0 ${style.layerTitle}`}>Windstream Layer 0 Analytics</h6>
                                <div className={style.innerLayer}>
                                    <p>
                                        Metrics displayed are for the Windstream infrastructure provided through the Windstream supplied Coherent Transceiver.
                                        Slight variances may exist from the Customer provided Coherent Transceiver.
                                    </p>

                                    <p className="d-inline-block">Latest</p>
                                    <Tooltip className={style.infoTip} html={'Windstream Layer 0 Analytics captured daily at 13:00 UTC'} position={'left-start'} delay={100} hideDelay={200}>
                                        <span className={`tipped cornertip user-select-pointer`}>?</span>
                                    </Tooltip>

                                    <div className="row">
                                        <div className="col-12 col-md-6">
                                            <label>Location A</label>
                                        </div>
                                        <div className="col-12 col-md-6">
                                            <label>Location Z</label>
                                        </div>
                                    </div>
                                    <hr />

                                    <div className="row">
                                        <div className="col-12 col-md-6">
                                            <table className="">
                                                <tr>
                                                    <td><label>CLLI :</label></td>
                                                    <td>CHCGILDT</td>
                                                </tr>
                                                <tr>
                                                    <td><label>Date Time:	</label></td>
                                                    <td>2023-03-17 13:00:00 UTC</td>
                                                </tr>
                                                <tr>
                                                    <td><label>OSNR (dB):</label></td>
                                                    <td>22.2</td>
                                                </tr>
                                                <tr>
                                                    <td><label>DGD (ps):	</label></td>
                                                    <td>5</td>
                                                </tr>
                                                <tr>
                                                    <td><label>CD (ps/nm):</label></td>
                                                    <td>14994</td>
                                                </tr>
                                            </table>
                                        </div>
                                        <div className="col-12 col-md-6">
                                            <table>
                                                <tr>
                                                    <td><label>CLLI : </label></td>
                                                    <td>ASBNVACY</td>
                                                </tr>
                                                <tr>
                                                    <td><label>Date Time:	</label></td>
                                                    <td>2023-03-17 13:00:00 UTC</td>
                                                </tr>
                                                <tr>
                                                    <td><label>OSNR (dB):</label></td>
                                                    <td>22.2</td>
                                                </tr>
                                                <tr>
                                                    <td><label>DGD (ps):	</label></td>
                                                    <td>2</td>
                                                </tr>
                                                <tr>
                                                    <td><label>CD (ps/nm):</label></td>
                                                    <td>15012</td>
                                                </tr>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="m-5 d-flex justify-content-between">
                                <button className="light-button text-uppercase" onClick={() => { displayAction() }}>{displayFormat[0].text}</button>
                                <button className="light-button text-uppercase">Save Report to File</button>
                            </div>

                            <div className="row">
                                <div className="col-12 col-md-6">
                                    <h6 className="text-dark text-center">CHCGILDT -<span className="text-secondary">{displayFormat[1].title}</span></h6>
                                    <div className="my-5 mx-2">
                                        <ChartUI data={displayFormat[1].type === '24' ? c1.splice(0,1) : c1} title="OSNR (dB)" subTitle="OSNR (dB)" display={displayFormat[1].title} />
                                    </div>

                                    <div className="my-5 mx-2">
                                        <ChartUI data={displayFormat[1].type === '24' ? c3.splice(0,1) : c3} title="DGD (ps)" subTitle="DGD" display={displayFormat[1].title} />
                                    </div>

                                    <div className="my-5 mx-2">
                                        <ChartUI data={displayFormat[1].type === '24' ? c5.splice(0,1) : c5} title="CD (ps/nm)" subTitle="CD" display={displayFormat[1].title} />
                                    </div>

                                </div>

                                <div className="col-12 col-md-6">
                                    <h6 className="text-dark text-center">ASBNVACY -<span className="text-secondary">{displayFormat[1].title}</span></h6>
                                    <div className="my-5 mx-2">
                                        <ChartUI data={displayFormat[1].type === '24' ? c2.splice(0,1) : c2} title="OSNR" subTitle="OSNR" display={displayFormat[1].title} />
                                    </div>

                                    <div className="my-5 mx-2">
                                        <ChartUI data={displayFormat[1].type === '24' ? c4.splice(0,1) : c4} title="DGD (ps)" subTitle="DGD" display={displayFormat[1].title} />

                                    </div>

                                    <div className="my-5 mx-2">
                                        <ChartUI data={displayFormat[1].type === '24' ? c6.splice(0,1) : c6} title="CD (ps/nm)" subTitle="CD" display={displayFormat[1].title} />
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )

}

export default LayerZero;