import style from "./TicketCreate.module.css";

const RadioBtnComponent = (props) => {
    const label = props.obj.label;
    const name = props.obj.name;
    const id = props.obj.id;
    const mandatory = props.obj.mandatory;
    const value = props.val;

    const radioBtnChange = (e) => {
        props.radioBtnEmit(e);
    }

    return (
        <div className="row">
            <label className={`col-sm-12 col-md-6 textuppercase ${style.labelText} ${props.obj?.hasError ? (style.errorFldText) : null} `}>
                {label} {mandatory ? <span className={style.mandatory}> *</span> : null} </label>
            <div className="col-sm-12 col-md-6 text-right">
                <input type="radio" id={id + 'yes'} name={name} onChange={(e) => { radioBtnChange(e) }} value='yes' checked={value === 'yes'}></input>
                <label className={style.lableValueFld} htmlFor={id + 'yes'} >Yes</label>

                <input type="radio" id={id + 'no'} name={name} onChange={(e) => { radioBtnChange(e) }} value='no' checked={value === 'no'}></input>
                <label className={style.lableValueFld} htmlFor={id + 'no'}>No</label>
            </div>
        </div>
    )
}

export default RadioBtnComponent;