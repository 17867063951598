import style from "./QuoteCreate.module.css";
import { useEffect } from "react";
import { connect, useSelector } from "react-redux";
import {
	setQuoteTerm1,
	setQuoteTerm2,
	setQuoteTerm3,
	setQuoteTerm4,
	setQuoteTerm5,
	setQuoteTerm10,
	setQuoteTermMonthToMonth,
	setQuoteDfTerm
} from "../../redux/actions/quoteActions";


const Term = (props) => {
	const selectedTermValues = useSelector(state => state.quote.quoteValues);

	useEffect(() => {
		// Term 3 should be defaulted on for WHSL Broadband Quotes
		if (props.product === "BROADBAND") {
			props.updateQuoteTerm3("on");
		}
	}, [props])

	const termHandler = (event) => {
		if (event.target.type === "checkbox") {
			let checkedBoxesCnt = document.querySelectorAll('input[name=terms]:checked').length;
			let maxAllowed = props.product === "WAVE" ? 5 : 3;
			if (props.product === "OFFICESUITE") {
				maxAllowed = 1;
			}

			if (checkedBoxesCnt >= maxAllowed) {
				if (document.getElementById("term1")) {
					if (document.getElementById("term1").checked === false) {
						document.getElementById("term1").disabled = true;
					}
				}
				if (document.getElementById("term2")) {
					if (document.getElementById("term2").checked === false) {
						document.getElementById("term2").disabled = true;
					}
				}
				if (document.getElementById("term3")) {
					if (document.getElementById("term3").checked === false) {
						document.getElementById("term3").disabled = true;
					}
				}
				if (document.getElementById("term4")) {
					if (document.getElementById("term4").checked === false) {
						document.getElementById("term4").disabled = true;
					}
				}
				if (document.getElementById("term5")) {
					if (document.getElementById("term5").checked === false) {
						document.getElementById("term5").disabled = true;
					}
				}
				if (document.getElementById("term10")) {
					if (document.getElementById("term10").checked === false) {
						document.getElementById("term10").disabled = true;
					}
				}
			} else {
				if (document.getElementById("term1")) {
					document.getElementById("term1").disabled = false;
				}
				if (document.getElementById("term2")) {
					document.getElementById("term2").disabled = false;
				}
				if (document.getElementById("term3")) {
					document.getElementById("term3").disabled = false;
				}
				if (document.getElementById("term4")) {
					document.getElementById("term4").disabled = false;
				}
				if (document.getElementById("term5")) {
					document.getElementById("term5").disabled = false;
				}
				if (document.getElementById("term10")) {
					document.getElementById("term10").disabled = false;
				}
			}

			let checkedValue = "";
			if (event.target.checked) {
				checkedValue = "on";
			} else {
				checkedValue = "off";
			}

			switch (event.target.id) {
				case "term1":
					if (props.renewalFlag) {
						props.renewalTermHandler("term1", checkedValue);
					} else {
						props.updateQuoteTerm1(checkedValue);
					}
					break;
				case "term2":
					if (props.renewalFlag) {
						props.renewalTermHandler("term2", checkedValue);
					} else {
						props.updateQuoteTerm2(checkedValue);
					}
					break;
				case "term3":
					if (props.renewalFlag) {
						props.renewalTermHandler("term3", checkedValue);
					} else {
						props.updateQuoteTerm3(checkedValue);
					}
					break;
				case "term4":
					if (props.renewalFlag) {
						props.renewalTermHandler("term4", checkedValue);
					} else {
						props.updateQuoteTerm4(checkedValue);
					}
					break;
				case "term5":
					if (props.renewalFlag) {
						props.renewalTermHandler("term5", checkedValue);
					} else {
						props.updateQuoteTerm5(checkedValue);
					}
					break;
				case "term10":
					if (props.renewalFlag) {
						props.renewalTermHandler("term10", checkedValue);
					} else {
						props.updateQuoteTerm10(checkedValue);
					}
					break;
				default:
					break;
			}
		} else {
			//Handling Wholesale Broadband term radio buttons
			props.updateQuoteMonthToMonth(event.target.id === "monthTomonth" ? "on" : "off");
			props.updateQuoteTerm1(event.target.id === "term1" ? "on" : "off");
			props.updateQuoteTerm2(event.target.id === "term2" ? "on" : "off");
			props.updateQuoteTerm3(event.target.id === "term3" ? "on" : "off");
		}
	}

	const dfTermHandler = (ev) => {
		switch (ev.target.id) {
			case "df_term":
				props.updateQuoteDfTerm(ev.target.value);
				break;
			default:
				break;
		}
	}

	return (
		<div className={props.renewalFlag ? `row` : `row ${style.rowGrp}`}>
			{props.product === "DARKFIBER" ?
				<>
					<div className="col-12 col-sm-4 col-md-3 mb-5">
						<select className={` form-control  ${style.selectFld} ${style.requiredFld} `} id="df_term" onChange={dfTermHandler} value={selectedTermValues.df_term}>
							<option value=""></option>
							<option value="36">36 Months</option>
							<option value="60">60 Months</option>
							<option value="72">72 Months</option>
							<option value="84">84 Months</option>
							<option value="120">120 Months</option>
							<option value="180">180 Months</option>
							<option value="140">140 Months</option>
						</select>
					</div>
				</>
				:
				<>
					<div className={`${props.renewalFlag} ? col-6 : col-12`}>
						{props.product === "BROADBAND" ?
							<>
								<input type="radio" id="monthTomonth" name="terms" checked={selectedTermValues.termMonthToMonth === 'on' ? "checked" : ""} onChange={termHandler} />
								<label htmlFor="monthTomonth" className={style.checkboxText}>Month to Month</label>
							</> : null
						}
						{props.product === "MPLS_NNI" || props.product === "E_ACCESS_NNI" || props.product === "MANAGED_SPECTRUM" || props.product === "OFFICESUITE" ?
							null :
							<>
								<input type={props.product === "BROADBAND" ? "radio" : "checkbox"} className={props.renewalFlag ? style.renewalCheckboxes : ""} id="term1" name="terms" checked={props.renewalFlag ? props.renewalDetailData.term1 === 'on' : selectedTermValues.term1 === 'on' ? "checked" : ""} onChange={termHandler} />
								<label htmlFor="term1" className={props.renewalFlag ? style.renewalCheckboxesLabel : style.checkboxText}>1 Year</label>
							</>
						}

						{props.product !== "MANAGED_SPECTRUM" ?
							<>
								<input type={props.product === "BROADBAND" ? "radio" : "checkbox"} className={props.renewalFlag ? style.renewalCheckboxes : ""} id="term2" name="terms" checked={props.renewalFlag ? props.renewalDetailData.term2 === 'on' : selectedTermValues.term2 === 'on' ? "checked" : ""} onChange={termHandler} />
								<label htmlFor="term2" className={props.renewalFlag ? style.renewalCheckboxesLabel : style.checkboxText}>2 Year</label>
							</> : null
						}

						<input type={props.product === "BROADBAND" ? "radio" : "checkbox"} className={props.renewalFlag ? style.renewalCheckboxes : ""} id="term3" name="terms" checked={props.renewalFlag ? props.renewalDetailData.term3 === 'on' : selectedTermValues.term3 === 'on' ? "checked" : ""} onChange={termHandler} />
						<label htmlFor="term3" className={props.renewalFlag ? style.renewalCheckboxesLabel : style.checkboxText}>3 Year</label>

						{props.product === "MPLS_NNI" || props.product === "BROADBAND" ?
							null :
							<>
								{props.product === "ELINE" || props.product === "E_ACCESS" || props.product === "E_ACCESS_NNI" || props.product === "OFFICESUITE" ?
									null :
									<>
										<input type="checkbox" className={props.renewalFlag ? style.renewalCheckboxes : ""} id="term4" name="terms" checked={props.renewalFlag ? props.renewalDetailData.term4 === 'on' : selectedTermValues.term4 === 'on' ? "checked" : ""} onChange={termHandler} />
										<label htmlFor="term4" className={props.renewalFlag ? style.renewalCheckboxesLabel : style.checkboxText}>4 Year</label>
									</>
								}
								<input type="checkbox" className={props.renewalFlag ? style.renewalCheckboxes : ""} id="term5" name="terms" checked={props.renewalFlag ? props.renewalDetailData.term5 === 'on' : selectedTermValues.term5 === 'on' ? "checked" : ""} onChange={termHandler} />
								<label htmlFor="term5" className={props.renewalFlag ? style.renewalCheckboxesLabel : style.checkboxText}>5 Year</label>
							</>
						}
						{props.product === "MANAGED_SPECTRUM" ?
							<>
								<input type="checkbox" className={props.renewalFlag ? style.renewalCheckboxes : ""} id="term10" name="terms" checked={props.renewalFlag ? props.renewalDetailData.term10 === 'on' : selectedTermValues.term10 === 'on' ? "checked" : ""} onChange={termHandler} />
								<label htmlFor="term10" className={props.renewalFlag ? style.renewalCheckboxesLabel : style.checkboxText}>10 Year</label>
							</> : null
						}
					</div>
				</>
			}
		</div>
	)

}

const mapDispatchToProps = {
	updateQuoteTerm1: setQuoteTerm1,
	updateQuoteTerm2: setQuoteTerm2,
	updateQuoteTerm3: setQuoteTerm3,
	updateQuoteTerm4: setQuoteTerm4,
	updateQuoteTerm5: setQuoteTerm5,
	updateQuoteTerm10: setQuoteTerm10,
	updateQuoteMonthToMonth: setQuoteTermMonthToMonth,
	updateQuoteDfTerm: setQuoteDfTerm
}

export default connect(null, mapDispatchToProps)(Term);
