import style from "./TicketCreate.module.css";

const CheckboxComponent = (props) => {
    const label = props?.obj?.label;
    const mandatory = props?.obj?.mandatory;
    const value = props?.val;

    const checkboxChange = (e) => {
        props.checkboxEmit(e);
    }

    return (
        <div className="row mt-3">
            <div className="col-12 col-sm-6 col-md-4">
                <label className={` ${style.labelText} textuppercase `}> {label} {mandatory ? <span className={style.mandatory}> *</span> : null} </label>
            </div>
            <div className="col-12 col-sm-6 col-md-6">
                <div className="form-check">
                    <input type="checkbox" className="form-check-input" onChange={(e) => { checkboxChange(e) }} checked={value} />
                </div>
            </div>
        </div>
    );
}

export default CheckboxComponent;