import React from 'react';

const Footer = () => {
    return (
		<div className="ftr">&copy; 2022 Windstream Intellectual Property Services, LLC.  All Rights Reserved.  
            <a rel={"noreferrer"} href="https://www.windstreamenterprise.com/legal/" target="_blank">Terms &amp; Conditions</a>&nbsp;|&nbsp;
            <a rel={"noreferrer"} href="https://www.windstreamenterprise.com/legal/privacy-policy/" target="_blank">Privacy Policy</a>&nbsp;|&nbsp;
            <a rel={"noreferrer"} href="/#" className="clearSession">Clear Session</a>
            <div className="ww-loader">
                <div className="lds-double-ring">
                    <div>&nbsp;</div>
                    <div>&nbsp;</div>
                </div>
            </div>
        </div>
    );
}

export default Footer;