import {
	ACTIVITY_REQUEST,
	ACTIVITY_TYPE,
	MACD_A_ADDRESS,
	MACD_A_ADDRESS2,
	MACD_A_CITY,
	MACD_A_COLO_POP,
	MACD_A_FIBER_CONNECTOR,
	MACD_A_FLOOR,
	MACD_A_FRAMING,
	MACD_A_HANDOFF,
	MACD_A_LATITUDE,
	MACD_A_LINE_CODE,
	MACD_A_LONGITUDE,
	MACD_A_ONSITE_EMAIL,
	MACD_A_ONSITE_NAME,
	MACD_A_ONSITE_PHONE,
	MACD_A_STATE,
	MACD_A_ZIP,
	MACD_ACNA,
	MACD_BW,
	MACD_CABLE_FID,
	MACD_CIRCUIT_LIST_COUNT,
	MACD_CIRCUIT_GAID,
	MACD_CIRCUIT_SEARCH,
	MACD_CIRCUIT_DETAILS,
	MACD_CIRCUIT_TYPE,
	MACD_CUSTOMER_CIRCUIT,
	MACD_CUSTOMER_PON,
	MACD_DATA_TECH_CONTACT,
	MACD_DATA_TECH_EMAIL,
	MACD_DATA_TECH_PHONE,
	MACD_DESIGN_INQUIRY_NUM,
	MACD_DIRECTION,
	MACD_DISCO_NETWORK,
	MACD_DISPATCH_OTSIDE_WIN_BUSINESS_HRS,
	MACD_EDGE_DEVICE,
	MACD_END_BILLING_DATE,
	MACD_EXISTING_CIRCUIT_ID,
	MACD_EXISTING_CIRCUIT_ID1,
	MACD_EXISTING_CIRCUIT_ID2,
	MACD_EXISTING_TN,
	MACD_FIBER_ARRAY,
	MACD_INSERT_NOTES,
	MACD_IPS,
	MACD_ACCOUNT_NUMBER,
	MACD_MANUAL_CIRCUIT_ID,
	MACD_NEW_DATA_TECH_CONTACT,
	MACD_NEW_PRIMARY_CONTACT,
	MACD_NUM_OF_FIBERS,
	MACD_NUM_OF_ROUTES,
	MACD_NUMBER_OF_UPLOADS,
	MACD_ORDER_NOTES,
	MACD_PRIMARY_CONTACT,
	MACD_PRIMARY_EMAIL,
	MACD_PRIMARY_PHONE,
	MACD_PRODUCT,
	MACD_QTY,
	MACD_ESCORT_ACCESS_REQUEST,
	MACD_REQ_SER_DATE,
	MACD_RESET,
	MACD_SPLICE_ARRAY,
	MACD_STEP_CODE,
	MACD_TEST_TYPE,
	MACD_UPLOAD_FILENAMES,
	MACD_UPLOAD_TYPE,
	MACD_Z_ADDRESS,
	MACD_Z_ADDRESS2,
	MACD_Z_CITY,
	MACD_Z_FIBER_CONNECTOR,
	MACD_Z_FLOOR,
	MACD_Z_FRAMING,
	MACD_Z_HANDOFF,
	MACD_Z_LATITUDE,
	MACD_Z_LINE_CODE,
	MACD_Z_LONGITUDE,
	MACD_Z_ONSITE_EMAIL,
	MACD_Z_ONSITE_NAME,
	MACD_Z_ONSITE_PHONE,
	MACD_Z_STATE,
	MACD_Z_ZIP,
	MACD_LEASENET_ID,
	MACD_CIRCUIT_ID,
	MACD_CONNECTOR_TYPE,
	MACD_LIVE_EQUIPMENT,
	MACD_RACK_INFO,
	MACD_PANEL_INFO,
	MACD_PORT_FIBER_ASSIGNMENT,
	MACD_SHIPPING_ADDRESS,
	MACD_SHIPPING_LD1,
	MACD_SHIPPING_LV1,
	MACD_SHIPPING_LV2,
	MACD_SHIPPING_LD3,
	MACD_SHIPPING_LV3,
	MACD_SHIPPING_CITY,
	MACD_SHIPPING_STATE,
	MACD_SHIPPING_ZIP,
	MACD_SHIPPING_CONTACT,
	MACD_SHIPPING_CONTACT_NEW,
	MACD_SHIPPING_PHONE,
	MACD_SHIPPING_EMAIL,
} from "../actions/types/actionTypes"


const initialState = {
	activeStepCode: 1,
	selectedProduct: "",
	selectedActivityRequest: "",
	selectedActivityType: "",
	selectedCircuitDetails: "",
	circuitListCount: "",
	circuitGaid: "",
	circuitSearch: "",
	CONTACTS: {
		PRIMARY_AUTHORIZING_CONTACT: "",
		NEW_PRIMARY_AUTHORIZING_CONTACT: "",
		PRIMARY_AUTHORIZING_PHONE: "",
		PRIMARY_AUTHORIZING_EMAIL: "",
		DATA_TECHNICAL_CONTACT: "",
		NEW_DATA_TECHNICAL_CONTACT: "",
		DATA_TECHNICAL_PHONE: "",
		DATA_TECHNICAL_EMAIL: "",
	},
	GeneralOrderInformation: {
		CUSTOMER_PON: "",
		CUSTOMER_CIRCUIT: "",
		ACNA: "",
		LEASENET_ID: "",
		CIRCUIT_ID: "",
	},
	ServiceInformation: {
		REQUESTED_SERVICE_DATE: "",
		EDGE_DEVICE: "",
		END_BILLING_DATE: "",
		CIRCUIT_TYPE: "",
		BANDWIDTH: "",
		QUANTITY: 1,
		IPS: "",
		ACCOUNT_NUMBER: "",
		MANUAL_CIRCUIT: "",
		DISCO_NETWORK: "",
		EXISTING_CIRCUIT_ID: "",
		EXISTING_CIRCUIT_ID1: "",
		EXISTING_CIRCUIT_ID2: "",
		CABLE_FID: "",
		NUMBER_OF_FIBERS: "",
		NUMBER_OF_ROUTES: "",
		DISPATCH_OUTSIDE_WIN_BUSINESS_HOURS: "NO",
		TEST_TYPE: "",
		DIRECTION: "",
		EXISTING_TN: "",
		DESIGN_INQUIRY_NUM: "",
		FIBER_ARRAY: "",
		SPLICE_ARRAY: ""
	},
	LocationA: {
		A_COLO_POP: "",
		A_LATITUDE: "",
		A_LONGITUDE: "",
		A_ADDRESS: "",
		A_A_ADDRESS2: "",
		A_CITY: "",
		A_STATE: "",
		A_ZIP: "",
		A_FLOOR: "",
		A_ONSITE_NAME_LCON: "",
		A_ONSITE_NUMBER: "",
		A_ONSITE_EMAIL: "",
		A_FRAMING: "",
		A_HANDOFF: "",
		A_LINE_CODE: "",
		A_FIBER_CONNECTOR: "",
		CONNECTOR_TYPE: "",
		LIVE_EQUIPMENT: "",
		RACK_INFO: "",
		PANEL_INFO: "",
		PORT_FIBER_ASSIGNMENT: "",
	},
	LocationZ: {
		Z_LATITUDE: "",
		Z_LONGITUDE: "",
		Z_ADDRESS: "",
		Z_ADDRESS2: "",
		Z_CITY: "",
		Z_STATE: "",
		Z_ZIP: "",
		Z_FLOOR: "",
		Z_ONSITE_NAME_LCON: "",
		Z_ONSITE_NUMBER: "",
		Z_ONSITE_EMAIL: "",
		Z_FRAMING: "",
		Z_HANDOFF: "",
		Z_LINE_CODE: "",
		Z_FIBER_CONNECTOR: "",
	},
	ShippingLocation: {
		SHIPPING_ADDRESS: "",
		SHIPPING_LD1: "",
		SHIPPING_LV1: "",
		SHIPPING_LV2: "",
		SHIPPING_LD3: "",
		SHIPPING_LV3: "",
		SHIPPING_CITY: "",
		SHIPPING_STATE: "",
		SHIPPING_ZIP: "",
		SHIPPING_CONTACT: "",
		SHIPPING_CONTACT_NEW: "",
		SHIPPING_PHONE: "",
		SHIPPING_EMAIL: "",
	},
	File_Attachment: {
		FILE_TYPE: {},
		FILENAME: {},
		uploadCount: 0
	},
	Insert_Notes: {
		INSERT_NOTES: "",
	},
	Order_Notes: {
		ORDER_NOTES: ""
	}
};

const MACDReducer = (state = initialState, action) => {
	switch (action.type) {
		case MACD_STEP_CODE:
			return {
				...state,
				activeStepCode: action.payload
			}

		case MACD_PRODUCT:
			return {
				...state,
				selectedProduct: action.payload
			}
		case ACTIVITY_REQUEST:
			return {
				...state,
				selectedActivityRequest: action.payload
			}
		case ACTIVITY_TYPE:
			return {
				...state,
				selectedActivityType: action.payload
			}
		case MACD_CIRCUIT_DETAILS:
			return {
				...state,
				selectedCircuitDetails: action.payload
			}
		case MACD_CIRCUIT_LIST_COUNT:
			return {
				...state,
				circuitListCount: action.payload
			}
		case MACD_CIRCUIT_GAID:
			return {
				...state,
				circuitGaid: action.payload
			}
		case MACD_CIRCUIT_SEARCH:
			return {
				...state,
				circuitSearch: action.payload
			}
		case MACD_PRIMARY_CONTACT:
			return {
				...state,
				CONTACTS: {
					...state.CONTACTS,
					PRIMARY_AUTHORIZING_CONTACT: action.payload
				}
			}
		case MACD_NEW_PRIMARY_CONTACT:
			return {
				...state,
				CONTACTS: {
					...state.CONTACTS,
					NEW_PRIMARY_AUTHORIZING_CONTACT: action.payload
				}
			}
		case MACD_PRIMARY_PHONE:
			return {
				...state,
				CONTACTS: {
					...state.CONTACTS,
					PRIMARY_AUTHORIZING_PHONE: action.payload
				}
			}
		case MACD_PRIMARY_EMAIL:
			return {
				...state,
				CONTACTS: {
					...state.CONTACTS,
					PRIMARY_AUTHORIZING_EMAIL: action.payload
				}
			}
		case MACD_DATA_TECH_CONTACT:
			return {
				...state,
				CONTACTS: {
					...state.CONTACTS,
					DATA_TECHNICAL_CONTACT: action.payload
				}
			}
		case MACD_NEW_DATA_TECH_CONTACT:
			return {
				...state,
				CONTACTS: {
					...state.CONTACTS,
					NEW_DATA_TECHNICAL_CONTACT: action.payload
				}
			}
		case MACD_DATA_TECH_PHONE:
			return {
				...state,
				CONTACTS: {
					...state.CONTACTS,
					DATA_TECHNICAL_PHONE: action.payload
				}
			}
		case MACD_DATA_TECH_EMAIL:
			return {
				...state,
				CONTACTS: {
					...state.CONTACTS,
					DATA_TECHNICAL_EMAIL: action.payload
				}
			}

		case MACD_CUSTOMER_PON:
			return {
				...state,
				GeneralOrderInformation: {
					...state.GeneralOrderInformation,
					CUSTOMER_PON: action.payload
				}
			}
		case MACD_CUSTOMER_CIRCUIT:
			return {
				...state,
				GeneralOrderInformation: {
					...state.GeneralOrderInformation,
					CUSTOMER_CIRCUIT: action.payload
				}
			}
		case MACD_ACNA:
			return {
				...state,
				GeneralOrderInformation: {
					...state.GeneralOrderInformation,
					ACNA: action.payload
				}
			}
		case MACD_LEASENET_ID:
			return {
				...state,
				GeneralOrderInformation: {
					...state.GeneralOrderInformation,
					LEASENET_ID: action.payload
				}
			}
		case MACD_CIRCUIT_ID:
			return {
				...state,
				GeneralOrderInformation: {
					...state.GeneralOrderInformation,
					CIRCUIT_ID: action.payload
				}
			}
		case MACD_ESCORT_ACCESS_REQUEST:
			return {
				...state,
				ServiceInformation: {
					...state.ServiceInformation,
					ESCORT_ACCESS_REQUEST: action.payload
				}
			}
		case MACD_REQ_SER_DATE:
			return {
				...state,
				ServiceInformation: {
					...state.ServiceInformation,
					REQUESTED_SERVICE_DATE: action.payload
				}
			}
		case MACD_EDGE_DEVICE:
			return {
				...state,
				ServiceInformation: {
					...state.ServiceInformation,
					EDGE_DEVICE: action.payload
				}
			}
		case MACD_END_BILLING_DATE:
			return {
				...state,
				ServiceInformation: {
					...state.ServiceInformation,
					END_BILLING_DATE: action.payload
				}
			}

		case MACD_CIRCUIT_TYPE:
			return {
				...state,
				ServiceInformation: {
					...state.ServiceInformation,
					CIRCUIT_TYPE: action.payload
				}
			}
		case MACD_BW:
			return {
				...state,
				ServiceInformation: {
					...state.ServiceInformation,
					BANDWIDTH: action.payload
				}
			}
		case MACD_QTY:
			return {
				...state,
				ServiceInformation: {
					...state.ServiceInformation,
					QUANTITY: action.payload
				}
			}

		case MACD_IPS:
			return {
				...state,
				ServiceInformation: {
					...state.ServiceInformation,
					IPS: action.payload
				}
			}

		case MACD_CABLE_FID:
			return {
				...state,
				ServiceInformation: {
					...state.ServiceInformation,
					CABLE_FID: action.payload
				}
			}
		case MACD_NUM_OF_FIBERS:
			return {
				...state,
				ServiceInformation: {
					...state.ServiceInformation,
					NUMBER_OF_FIBERS: action.payload
				}
			}
		case MACD_NUM_OF_ROUTES:
			return {
				...state,
				ServiceInformation: {
					...state.ServiceInformation,
					NUMBER_OF_ROUTES: action.payload
				}
			}
		case MACD_DISPATCH_OTSIDE_WIN_BUSINESS_HRS:
			return {
				...state,
				ServiceInformation: {
					...state.ServiceInformation,
					DISPATCH_OUTSIDE_WIN_BUSINESS_HOURS: action.payload
				}
			}
		case MACD_TEST_TYPE:
			return {
				...state,
				ServiceInformation: {
					...state.ServiceInformation,
					TEST_TYPE: action.payload
				}
			}
		case MACD_DIRECTION:
			return {
				...state,
				ServiceInformation: {
					...state.ServiceInformation,
					DIRECTION: action.payload
				}
			}
		case MACD_EXISTING_TN:
			return {
				...state,
				ServiceInformation: {
					...state.ServiceInformation,
					EXISTING_TN: {
						...state.ServiceInformation.EXISTING_TN,
						[action.index]: action.payload
					}
				}
			}
		case MACD_DESIGN_INQUIRY_NUM:
			return {
				...state,
				ServiceInformation: {
					...state.ServiceInformation,
					DESIGN_INQUIRY_NUM: action.payload
				}
			}
		case MACD_FIBER_ARRAY:
			return {
				...state,
				ServiceInformation: {
					...state.ServiceInformation,
					FIBER_ARRAY: {
						...state.ServiceInformation.FIBER_ARRAY,
						[action.index]: action.payload
					}
				}
			}
		case MACD_SPLICE_ARRAY:
			return {
				...state,
				ServiceInformation: {
					...state.ServiceInformation,
					SPLICE_ARRAY: {
						...state.ServiceInformation.SPLICE_ARRAY,
						[action.index]: action.payload
					}
				}
			}
		case MACD_ACCOUNT_NUMBER:
			return {
				...state,
				ServiceInformation: {
					...state.ServiceInformation,
					ACCOUNT_NUMBER: action.payload
				}
			}
		case MACD_MANUAL_CIRCUIT_ID:
			return {
				...state,
				ServiceInformation: {
					...state.ServiceInformation,
					MANUAL_CIRCUIT: {
						...state.ServiceInformation.MANUAL_CIRCUIT,
						[action.index]: action.payload
					}
				}
			}
		case MACD_DISCO_NETWORK:
			return {
				...state,
				ServiceInformation: {
					...state.ServiceInformation,
					DISCO_NETWORK: action.payload
				}
			}
		case MACD_EXISTING_CIRCUIT_ID:
			return {
				...state,
				ServiceInformation: {
					...state.ServiceInformation,
					EXISTING_CIRCUIT_ID: {
						...state.ServiceInformation.EXISTING_CIRCUIT_ID,
						[action.index]: action.payload
					}
				}
			}
		case MACD_EXISTING_CIRCUIT_ID1:
			return {
				...state,
				ServiceInformation: {
					...state.ServiceInformation,
					EXISTING_CIRCUIT_ID1: action.payload
				}
			}
		case MACD_EXISTING_CIRCUIT_ID2:
			return {
				...state,
				ServiceInformation: {
					...state.ServiceInformation,
					EXISTING_CIRCUIT_ID2: action.payload
				}
			}
		case MACD_A_LATITUDE:
			return {
				...state,
				LocationA: {
					...state.LocationA,
					A_LATITUDE: action.payload
				}
			}
		case MACD_A_LONGITUDE:
			return {
				...state,
				LocationA: {
					...state.LocationA,
					A_LONGITUDE: action.payload
				}
			}
		case MACD_A_COLO_POP:
			return {
				...state,
				LocationA: {
					...state.LocationA,
					A_COLO_POP: action.payload
				}
			}
		case MACD_A_ADDRESS:
			return {
				...state,
				LocationA: {
					...state.LocationA,
					A_ADDRESS: action.payload
				}
			}
		case MACD_A_ADDRESS2:
			return {
				...state,
				LocationA: {
					...state.LocationA,
					A_ADDRESS2: action.payload
				}
			}

		case MACD_A_CITY:
			return {
				...state,
				LocationA: {
					...state.LocationA,
					A_CITY: action.payload
				}
			}
		case MACD_A_STATE:
			return {
				...state,
				LocationA: {
					...state.LocationA,
					A_STATE: action.payload
				}
			}
		case MACD_A_ZIP:
			return {
				...state,
				LocationA: {
					...state.LocationA,
					A_ZIP: action.payload
				}
			}
		case MACD_A_FLOOR:
			return {
				...state,
				LocationA: {
					...state.LocationA,
					A_FLOOR: action.payload
				}
			}
		case MACD_A_ONSITE_NAME:
			return {
				...state,
				LocationA: {
					...state.LocationA,
					A_ONSITE_NAME_LCON: action.payload
				}
			}
		case MACD_A_ONSITE_PHONE:
			return {
				...state,
				LocationA: {
					...state.LocationA,
					A_ONSITE_NUMBER: action.payload
				}
			}
		case MACD_A_ONSITE_EMAIL:
			return {
				...state,
				LocationA: {
					...state.LocationA,
					A_ONSITE_EMAIL: action.payload
				}
			}
		case MACD_A_FRAMING:
			return {
				...state,
				LocationA: {
					...state.LocationA,
					A_FRAMING: action.payload

				}
			}
		case MACD_A_LINE_CODE:
			return {
				...state,
				LocationA: {
					...state.LocationA,
					A_LINE_CODE: action.payload

				}
			}
		case MACD_A_HANDOFF:
			return {
				...state,
				LocationA: {
					...state.LocationA,
					A_HANDOFF: action.payload

				}
			}
		case MACD_A_FIBER_CONNECTOR:
			return {
				...state,
				LocationA: {
					...state.LocationA,
					A_FIBER_CONNECTOR: action.payload
				}
			}
		case MACD_CONNECTOR_TYPE:
			return {
				...state,
				LocationA: {
					...state.LocationA,
					CONNECTOR_TYPE: action.payload
				}
			}
		case MACD_LIVE_EQUIPMENT:
			return {
				...state,
				LocationA: {
					...state.LocationA,
					LIVE_EQUIPMENT: action.payload
				}
			}
		case MACD_RACK_INFO:
			return {
				...state,
				LocationA: {
					...state.LocationA,
					RACK_INFO: action.payload
				}
			}
		case MACD_PANEL_INFO:
			return {
				...state,
				LocationA: {
					...state.LocationA,
					PANEL_INFO: action.payload
				}
			}
		case MACD_PORT_FIBER_ASSIGNMENT:
			return {
				...state,
				LocationA: {
					...state.LocationA,
					PORT_FIBER_ASSIGNMENT: action.payload
				}
			}
		case MACD_SHIPPING_ADDRESS:
			return {
				...state,
				ShippingLocation: {
					...state.ShippingLocation,
					SHIPPING_ADDRESS: action.payload
				}
			}
		case MACD_SHIPPING_LV1:
			return {
				...state,
				ShippingLocation: {
					...state.ShippingLocation,
					SHIPPING_LV1: action.payload
				}
			}
		case MACD_SHIPPING_LD1:
			return {
				...state,
				ShippingLocation: {
					...state.ShippingLocation,
					SHIPPING_LD1: action.payload
				}
			}
		case MACD_SHIPPING_LV2:
			return {
				...state,
				ShippingLocation: {
					...state.ShippingLocation,
					SHIPPING_LV2: action.payload
				}
			}
		case MACD_SHIPPING_LV3:
			return {
				...state,
				ShippingLocation: {
					...state.ShippingLocation,
					SHIPPING_LV3: action.payload
				}
			}
		case MACD_SHIPPING_LD3:
			return {
				...state,
				ShippingLocation: {
					...state.ShippingLocation,
					SHIPPING_LD3: action.payload
				}
			}
		case MACD_SHIPPING_CITY:
			return {
				...state,
				ShippingLocation: {
					...state.ShippingLocation,
					SHIPPING_CITY: action.payload
				}
			}
		case MACD_SHIPPING_STATE:
			return {
				...state,
				ShippingLocation: {
					...state.ShippingLocation,
					SHIPPING_STATE: action.payload
				}
			}
		case MACD_SHIPPING_ZIP:
			return {
				...state,
				ShippingLocation: {
					...state.ShippingLocation,
					SHIPPING_ZIP: action.payload
				}
			}
		case MACD_SHIPPING_CONTACT:
			return {
				...state,
				ShippingLocation: {
					...state.ShippingLocation,
					SHIPPING_CONTACT: action.payload
				}
			}
		case MACD_SHIPPING_CONTACT_NEW:
			return {
				...state,
				ShippingLocation: {
					...state.ShippingLocation,
					SHIPPING_CONTACT_NEW: action.payload
				}
			}
		case MACD_SHIPPING_PHONE:
			return {
				...state,
				ShippingLocation: {
					...state.ShippingLocation,
					SHIPPING_PHONE: action.payload
				}
			}
		case MACD_SHIPPING_EMAIL:
			return {
				...state,
				ShippingLocation: {
					...state.ShippingLocation,
					SHIPPING_EMAIL: action.payload
				}
			}
		case MACD_Z_LATITUDE:
			return {
				...state,
				LocationZ: {
					...state.LocationZ,
					Z_LATITUDE: action.payload
				}
			}
		case MACD_Z_LONGITUDE:
			return {
				...state,
				LocationZ: {
					...state.LocationZ,
					Z_LONGITUDE: action.payload
				}
			}
		case MACD_Z_ADDRESS:
			return {
				...state,
				LocationZ: {
					...state.LocationZ,
					Z_ADDRESS: action.payload
				}
			}
		case MACD_Z_ADDRESS2:
			return {
				...state,
				LocationZ: {
					...state.LocationZ,
					Z_ADDRESS2: action.payload
				}
			}

		case MACD_Z_CITY:
			return {
				...state,
				LocationZ: {
					...state.LocationZ,
					Z_CITY: action.payload
				}
			}
		case MACD_Z_STATE:
			return {
				...state,
				LocationZ: {
					...state.LocationZ,
					Z_STATE: action.payload
				}
			}
		case MACD_Z_ZIP:
			return {
				...state,
				LocationZ: {
					...state.LocationZ,
					Z_ZIP: action.payload
				}
			}
		case MACD_Z_FLOOR:
			return {
				...state,
				LocationZ: {
					...state.LocationZ,
					Z_FLOOR: action.payload
				}
			}
		case MACD_Z_ONSITE_NAME:
			return {
				...state,
				LocationZ: {
					...state.LocationZ,
					Z_ONSITE_NAME_LCON: action.payload
				}
			}
		case MACD_Z_ONSITE_PHONE:
			return {
				...state,
				LocationZ: {
					...state.LocationZ,
					Z_ONSITE_NUMBER: action.payload
				}
			}
		case MACD_Z_ONSITE_EMAIL:
			return {
				...state,
				LocationZ: {
					...state.LocationZ,
					Z_ONSITE_EMAIL: action.payload
				}
			}
		case MACD_Z_FRAMING:
			return {
				...state,
				LocationZ: {
					...state.LocationZ,
					Z_FRAMING: action.payload
				}
			}
		case MACD_Z_LINE_CODE:
			return {
				...state,
				LocationZ: {
					...state.LocationZ,
					Z_LINE_CODE: action.payload

				}
			}
		case MACD_Z_HANDOFF:
			return {
				...state,
				LocationZ: {
					...state.LocationZ,
					Z_HANDOFF: action.payload

				}
			}
		case MACD_Z_FIBER_CONNECTOR:
			return {
				...state,
				LocationZ: {
					...state.LocationZ,
					Z_FIBER_CONNECTOR: action.payload
				}
			}

		case MACD_UPLOAD_TYPE:
			return {
				...state,
				File_Attachment: {
					...state.File_Attachment,
					FILE_TYPE: {
						...state.File_Attachment.FILE_TYPE,
						[action.index]: action.payload
					}
				}
			}
		case MACD_UPLOAD_FILENAMES:
			return {
				...state,
				File_Attachment: {
					...state.File_Attachment,
					FILENAME: {
						...state.File_Attachment.FILENAME,
						[action.index]: action.payload
					}
				}
			}
		case MACD_NUMBER_OF_UPLOADS:
			return {
				...state,
				File_Attachment: {
					...state.File_Attachment,
					uploadCount: action.payload
				}
			}
		case MACD_INSERT_NOTES:
			return {
				...state,
				Insert_Notes: {
					...state.Insert_Notes,
					INSERT_NOTES: action.payload
				}
			}
		case MACD_ORDER_NOTES:
			return {
				...state,
				Order_Notes: {
					...state.Order_Notes,
					ORDER_NOTES: action.payload
				}
			}


		case MACD_RESET:
			return initialState;
		default:
			return state
	}
}
export default MACDReducer;
