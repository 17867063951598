import { useEffect, useState, useMemo } from 'react';
import ContactData from '../../services/ContactService';
import { ConsoleLogger } from "../../logger/ConsoleLogger";
import style from "./UtilityContacts.module.css";
import Loader from '../shared/Loader';

const CircuitModal = (props) => {
	const logger = useMemo(() => new ConsoleLogger({ level: process.env.REACT_APP_LOGGER_LEVEL, }),[]);
	const [individualCircuitsSelected, setIndividualCircuitsSelected] = useState(false);
	const [circuitList, setCircuitList] = useState([]);
	const [searchText, setSearchText] = useState('');
	const [circuitListForSearch, setCircuitListForSearch] = useState();
	const [loader, setLoader] = useState(true);

	useEffect(() => {
		if(props.circuitPreference === "INDIVIDUAL"){
			setIndividualCircuitsSelected(true);
		} else{
			setIndividualCircuitsSelected(false);
		}
		// Available Circuits
		ContactData.getEnrollmentIds(props.gaid, props.gapid).then(response => {
			logger.info(`Response from getCircuitIds: ${JSON.stringify(response.data)}`);
			setCircuitList(response.data);
			setCircuitListForSearch(response.data);
			setLoader(false);
		}).catch((error) => {
			logger.error("getContactIds ERROR: " + error);
			setLoader(false);
		});
	}, [logger, props.circuitPreference, props.gaid, props.gapid]);

	const circuitHandle = (event, item) => {
		let isChecked = event.target.checked;
		let updatedCircuitListData = [];
		let updatedupdatedCLDataForSearch = [];
		if (item === 'All') {
			updatedCircuitListData = circuitList.map(cl => cl.id ? { ...cl, checked: isChecked } : cl);
			updatedupdatedCLDataForSearch = circuitListForSearch.map(cl => cl.id ? { ...cl, checked: isChecked } : cl);
		} else {
			updatedCircuitListData = circuitList.map(cl => cl.id === item.id ? { ...cl, checked: isChecked } : cl);
			updatedupdatedCLDataForSearch = circuitListForSearch.map(cl => cl.id === item.id ? { ...cl, checked: isChecked } : cl);
		}

		setCircuitListForSearch(updatedupdatedCLDataForSearch);
		setCircuitList(updatedCircuitListData);
	}

	const searchHandle = (event) => {
		const { value } = event.target;
		setSearchText(value);
		let filterCircuitList = circuitListForSearch.filter(co => {
			return co.value.toLocaleLowerCase().includes(value.toLocaleLowerCase());
		});
		setCircuitList(filterCircuitList);
	}

	const getAllCheckedCircuits = () => {
		let checkedCircuits = circuitList.filter(circuit => {
			return circuit.checked
		})
		props.addSelectedCircuits(checkedCircuits);
	}

	const modalSave = () => {
		props.modalClose();
		getAllCheckedCircuits();

		if(individualCircuitsSelected){
			props.updateCircuitPreference("INDIVIDUAL");
		} else {
			props.updateCircuitPreference("ALL");
		}
	}

	return (
		<>
		<div>
			<div className={`${style.overlay}`}> </div>
			<div className="modal fade show d-block">
				<div className={` modal-dialog modal-dialog-scrollable modal-dialog-centered modal-lg`}>
					<div className="modal-content">
						{loader ? <Loader/> : null}
						<div class="modal-header">
							<h5 className="modal-title">Circuit Selection</h5>
						</div>
						<div className="modal-body">
							<b>Please select from the below list of circuits to receive Maintenance notifications.</b>
							<label className="radio_buttons my-2 text-capitalize">
								<input type="radio" onClick={() => setIndividualCircuitsSelected(false)} value="rcv_maintainences" name="rcv_maintainence" checked={!individualCircuitsSelected ? "checked" : ""} />
								To receive Maintenance notifications
								for all circuits associated with this customer.
								<span className="radio_mark"></span>
							</label>
							<label className="radio_buttons my-2 text-capitalize">
								<input type="radio" checked={individualCircuitsSelected ? "checked" : ""}  onClick={() => setIndividualCircuitsSelected(true)} value="select_circuit" name="rcv_maintainence" />Select individual circuits from the
								list below to receive Maintenance notifications.
								<span className="radio_mark"></span>
							</label>
							<div className="note">
								<p>**Only 500 circuits can be added at a time.
									Choose the option to receive Maintenance
									notifications on all circuits if appropriate.
								</p>
							</div>
							{individualCircuitsSelected ?
								<div className='block'>
									<div className="form-group w-50">
										<input type="text" class="form-control" id="" placeholder="Search" value={searchText} onChange={(event) => { searchHandle(event) }} />
									</div>
									{circuitList?.map((item) => {
										return (
											<div key={item.id} className="form-check ml-6">
												<input type="checkbox" class={`form-check-input ${style.checkbox}`} id={item.id} checked={item.checked} onChange={(event) => { circuitHandle(event, item) }} />
												<label className={`form-check-label ${style.checkboxLabel} ml-2`} for={item.id}>
													{item.value}
												</label>
											</div>
										)
									})}
								</div> : null
							}
						</div>

						<div className="modal-footer d-block text-center">
							<button type="button" className="btn-style btn-cancel" data-dismiss="modal" onClick={() => props.modalClose() }>Cancel</button>
							<button type="button" className="btn-style btn-theme" data-dismiss="modal" onClick={() => modalSave()}>Save</button>
						</div>
					</div>
				</div>
			</div>
		</div>
		</>
	)
}

export default CircuitModal;
