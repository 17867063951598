/**
 * Created by e0173131 on 1/20/2021.
 */
import React, {useState, Fragment} from 'react';

const GoodieColors = () => {
    const [isCollapsed, setIsCollapsed] = useState(true);

    const handleClick = () => {
        setIsCollapsed(!isCollapsed);
    }
    return (
        <Fragment>
            <span className="cf"></span>
            <div className="sub-hdr alt1">
                <div className="expander" collapsed-id="icon-content" onClick={handleClick}>
                    <svg className="ico-link light-ico ico-add">
                        <path
                            d="M24,48A24,24,0,1,1,48,24,24,24,0,0,1,24,48ZM24,1.89A22.1,22.1,0,1,0,46.08,24,22.09,22.09,0,0,0,24,1.89Zm1,32.69H23.05V25h-9.6V23.05h9.6v-9.6H25v9.6h9.61V25H25Z"></path>
                    </svg>
                </div>
                <div className="trim">&nbsp;</div>
                <div className="nib">
                    <div className="the-notch">
                        <div className="notch-fg">
                            <svg>
                                <path
                                    d="M35.81,26.28l-13,10v-5C-10.47,28.68-1,9.16,11.79,0c0,7.28,0,9.13,0,13.28s3.18,7.92,11,9v-5Z"></path>
                            </svg>
                        </div>
                    </div>
                </div>
                <h2>Colors/Palette</h2>
            </div>
            {/*<style>
                .color-box {width: 130px; height: 125px; margin: 24px 24px 0 0; text-align: center; color: #fff; padding: 25px 10px 0 10px;}
            </style>*/}
            {!isCollapsed ?
            <div className="sub-section">
                <h3>Brand</h3>
                <div className="cf">
                    <div className="fl color-box" style={{ backgroundColor: '#B31983' }}>#B31983</div>
                    <div className="fl color-box" style={{ backgroundColor: '#EF9123' }}>#EF9123</div>
                    <div className="fl color-box" style={{ backgroundColor: '#ED2863' }}>#ED2863</div>
                    <div className="fl color-box" style={{ backgroundColor: '#6BBE59' }}>#6BBE59</div>
                    <div className="fl color-box" style={{ backgroundColor: '#316094' }}>#316094</div>
                    <div className="fl color-box" style={{ backgroundColor: '#753BBD' }}>
                        <span style={{ textDecoration: 'line-through' }}>#753BBD</span>
                        <br />
                        Defined but not used
                    </div>
                </div>
                <br />
                <h3>Web</h3>
                <div className="cf">
                    <div className="fl color-box" style={{ backgroundColor: '#4dc7cc' }}>
                        #4DC7CC<br />Links on Dark UI
                    </div>
                    <div className="fl color-box" style={{ backgroundColor: '#753BBD' }}>
                        #753BBD<br />Links on Light UI
                    </div>
                    <div className="fl color-box" style={{ backgroundColor: '#292a2c' }}>
                        #292A2C<br />Background on Dark UI
                    </div>
                </div>
            </div>
            : null}
        </Fragment>
    );
}

export default GoodieColors;
