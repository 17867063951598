const ArrayUtils = (function () {
    
    const sortArrayByDates = (arr) => {
        return arr.slice().sort((a, b) => {
            // Turn your strings into dates, and then subtract them
            // to get a value that is either negative, positive, or zero.
            return new Date(b['ticket'].reportedDate) - new Date(a['ticket'].reportedDate);
        }).reverse();
    };

    const groupByKey = (array, key) => {
        if (array) {
            return array
                .reduce((hash, obj) => {
                    if (obj[key] === undefined) return hash;
                    return Object.assign(hash, { [obj[key]]: (hash[obj[key]] || []).concat(obj) })
                }, {})
        }
    }

    const sum = (array, key) => {
        if (array) {
            return array.reduce((a, b) => a + (b[key] || 0), 0);
        }
    }

    return {
        sortArrayByDates: sortArrayByDates,
        groupByKey: groupByKey,
        sum: sum
    }
}());

export default ArrayUtils;