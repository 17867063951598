import {default as documentList} from "../staticData/documentList.json";

const getSupportDocuments = async () => {
    return await documentList;
}

const DocumentsSupportService = {
    getSupportDocuments
}

export default DocumentsSupportService;