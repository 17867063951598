import { Text, View,  } from '@react-pdf/renderer';
import { styles } from './pdfStyles';

const SignatureSection = () => {
    return (
        <>
        <View wrap={false}>
        <View style={styles.headerBarTopMargin}>
            <Text style={styles.headerBarText}>Signatures</Text>
        </View>
        <View style={styles.dualColumn}>
            <View style={styles.halfWidth}>
                <Text style={styles.textBold}>Windstream</Text>
                <Text style={styles.signatureText}></Text>
                <View style={styles.signatureAboveLine}></View>
                <Text style={styles.signatureBelowText}>By:</Text>
                <View style={styles.signatureAboveLine}></View>
                <Text style={styles.signatureBelowText}>(authorized signature)</Text>
                <Text style={styles.signatureText}>Name(print):</Text>
                <View style={styles.signatureLine}></View>
                <Text style={styles.signatureText}>Title:</Text>
                <View style={styles.signatureLine}></View>
                <Text style={styles.signatureText}>Date:</Text>
                <View style={styles.signatureLine}></View>
            </View>
            <View style={styles.halfWidth}>
                <Text style={styles.textBold}>Customer</Text>
                <Text style={styles.signatureText}></Text>
                <View style={styles.signatureAboveLine}></View>
                <Text style={styles.signatureBelowText}>By:</Text>
                <View style={styles.signatureAboveLine}></View>
                <Text style={styles.signatureBelowText}>(authorized signature)</Text>
                <Text style={styles.signatureText}>Name(print):</Text>
                <View style={styles.signatureLine}></View>
                <Text style={styles.signatureText}>Title:</Text>
                <View style={styles.signatureLine}></View>
                <Text style={styles.signatureText}>Date:</Text>
                <View style={styles.signatureLine}></View>
            </View>
        </View>
    </View>
    </>
    );
};

export default SignatureSection;