import style from "./QuoteCreate.module.css";
import {
	setAgency,
	setAgent,
	setAgentAddress,
	setAgentCity,
	setAgentEmail,
	setAgentFee,
	setAgentName,
	setAgentPhone,
	setAgentState,
	setAgentZip,
	setBillingSystem,
	setDisableCustEmail,
	setEndTermDate,
	setEnterpriseRep,
	setEnterpriseRepObj,
	setExistingQuoteCircuitID,
	setLegacyNetwork,
	setManualQuoteCircuitID,
	setMarkup,
	setNonCommissionable,
	setOffnet,
	setPrevQuoteNum,
	setPricingOverride,
	setRenewalQuote,
	setWholesaleOpportunity
} from "../../redux/actions/internalActions";
import { connect, useSelector } from 'react-redux';
//import QuoteHelper from '../../services/QuoteService';
import { useEffect, useState, useMemo, useCallback } from 'react';
import Loader from '../shared/Loader';
import { ConsoleLogger } from "../../logger/ConsoleLogger";
import HomeData from '../../services/HomeServices';
import Select from 'react-select';
import EmployeeInternalHelper from "../../services/EmployeeInternalService";

const QuoteInternal = (props) => {
	const logger = useMemo(() => new ConsoleLogger({ level: process.env.REACT_APP_LOGGER_LEVEL, }), []);
	const selectedInternalQuote = useSelector(state => state.internal.quote);
	const selectedInternalMpid = useSelector(state => state.internal?.company?.value);
	//const [circuitList, setCircuitList] = useState([]);
	const [stateList, setStateList] = useState([]);
	const [loader, setLoader] = useState(false);
	const [agencyMenu, setAgencyMenu] = useState([]);
	const [agencyFlag, setAgencyFlag] = useState(false);
	const selectedInternalAgency = useSelector(state => state.internal.quote.agency);
	const google = window.google = window.google ? window.google : {};

	const fillInAddress = useCallback((ac) => {
		var place = ac.getPlace();
		props.updateAgentAddress(place.formatted_address);
		for (var x = 0; x < place.address_components.length; x++) {
			switch (place.address_components[x].types[0]) {
				case 'locality':
					props.updateAgentCity(place.address_components[x].short_name);
					break;
				case 'administrative_area_level_1':
					props.updateAgentState(place.address_components[x].short_name);
					break;
				case 'postal_code':
					props.updateAgentZip(place.address_components[x].short_name);
					break;
				default:
					break;
			}
		}
		return place;
	}, [props])

	/*Google Autocomplete for agent address*/
	const initAutocomplete = useCallback(() => {
		const autocompleteAgent = new google.maps.places.Autocomplete((document.getElementById('agent_address')), { types: ['geocode'] });
		google.maps.event.addListener(autocompleteAgent, 'place_changed', function () {
			fillInAddress(autocompleteAgent, "");
		});
	}, [fillInAddress, google.maps.event, google.maps.places.Autocomplete])



	useEffect(() => {
		initAutocomplete();
	}, [initAutocomplete, selectedInternalQuote.agent])


	useEffect(() => {
		//Checks to see if account has the option to select from the agency picklist
		if (props.section === "agency" && selectedInternalMpid) {
			setLoader(true);
			EmployeeInternalHelper.agencyCheck(selectedInternalMpid).then(resp => {
				if (resp.data === 1) {
					setAgencyFlag(true);
					/*Go fetch the agency picklist options*/
					setLoader(true);
					EmployeeInternalHelper.getAgencyOptions().then(resp => {
						setAgencyMenu(resp.data);
						setLoader(false);
					}).catch(error => {
						logger.error("Error getting agency menu options: " + error);
						setLoader(false);
					})
				} else {
					setAgencyFlag(false);
				}
				setLoader(false)
			}).catch(error => {
				logger.error("Error getting agency flag: " + error);
			})
		}
	}, [logger, props.section, selectedInternalMpid])

	useEffect(() => {
		if (selectedInternalMpid === 8948 && props.section === "additional_details") {
			props.updateNonCommissionable(true);
			document.getElementById("noncomm").disabled = true;
		}
	}, [props, selectedInternalMpid])



	/*Controls and saves the agency to the redux internal object*/
	const agencyHandler = (value) => {
		let agencyVal = '';
		if (!value) {
			agencyVal = ''
		} else {
			agencyVal = value;
		}
		//Setting selected agency in store
		props.updateAgency(agencyVal);
	}


	/*As of right now renewals are set to move to a different page so commenting this out
	const getExistingCircuitMenu = (mpid) => {
		let cirList = null;
		let str = '';
		setLoader(true);
		QuoteHelper.getQuoteCircuits(mpid).then(resp => {
			cirList = resp.data
			cirList = cirList.map(item => {
				str = `${item.circuit_id}`;
				return { value: item.circuit_id, label: str }
			});
			setCircuitList([{ value: '', label: 'Select Circuit' }].concat(cirList));
			setCircuitList([{ value: 'manual', label: 'Manual Circuit' }].concat(cirList));
		   setLoader(false);
	   }).catch(error => {
		   logger.error("Error Getting Existing Quote Circuits: " + error)
		   setLoader(false);
	   });
	}

    
	const renewalHandler = (ev) => {
		props.updateRenewalQuote(ev.target.checked);
		if(!ev.target.checked){
			props.updatePrevQuoteNum("");
			props.updateExistingQuoteCircuits("");
			props.updateEndTermDate("");
			props.updateLegacyNetwork("");
			props.updateBillingSystem("");
			props.updateOffnet("");
		}

		//Only load the existing circuit menu if hasn't been loaded and if quote renewal is checked
		if(ev.target.checked && circuitList?.length === 0){
			getExistingCircuitMenu(selectedInternalMpid);
		}
	}
	*/

	const agentHandler = (event) => {
		props.updateAgent(event.target.checked);
		if (!event.target.checked) {
			props.updateAgentName("");
			props.updateAgentPhone("");
			props.updateAgentFee("");
			props.updateAgentEmail("");
			props.updateAgentAddress("");
			props.updateAgentCity("");
			props.updateAgentState("");
			props.updateAgentZip("");
		}
	}

	useEffect(() => {
		if (selectedInternalQuote.agent) {
			// Get the list of all the states for AV form
			HomeData.getStateList().then(resp => {
				setStateList(resp.data);
			}).catch((error) => {
				logger.error("getStates ERROR: " + error)
			})
		}
	}, [logger, selectedInternalQuote.agent])





	/*As of right now renewals are moving to a separate page so commenting this out
	const circuitHandler = (ev) => {
		let circuitVal = '';
		if (!ev) {
			circuitVal = ''
		} else {
			circuitVal = ev;
		}
		//Setting selected circuit id in store
		props.updateExistingQuoteCircuits(circuitVal);
	}
	*/

	const enterpriseRepHandler = (event) => {
		props.updateEnterpriseRep(event.target.checked);
		//Clearing Enterprise Object
		if (!event.target.checked) {
			props.updateEnterpriseRepObj("enterprise_name", "");
			props.updateEnterpriseRepObj("enterprise_email", "");
		}
	}



	return (
		<>
			{loader ? <Loader /> : null}
			{props.section === "agency" && agencyFlag && selectedInternalMpid ?
				<>
					<div className={`sub-hdr alt ${style.subHeader} `}>
						<div className="nib"><div className="the-notch"><div className="notch-fg"><svg><path d="M35.81,26.28l-13,10v-5C-10.47,28.68-1,9.16,11.79,0c0,7.28,0,9.13,0,13.28s3.18,7.92,11,9v-5Z"></path></svg></div></div></div><h2 className={style.sectionTitle}>Agency</h2>
						<p className='text-left mb-0 mt-2'>Please select the Agency:</p>
					</div>
					<div className={`row ${style.rowGrp} justify-content-center`}>
						<div className="col-4">
							<Select
								value={{ value: selectedInternalAgency?.value, label: selectedInternalAgency?.label }}
								onChange={agencyHandler}
								options={agencyMenu.map((item) => {
									return { value: item.id, label: item.name }
								})}
								isSearchable
								isClearable
								noOptionsMessage={() => 'Loading Agency Options'}
							/>
						</div>
					</div>
				</> : null
			}
			{props.section === "additional_details" ?
				<>
					<div className={`row ${style.rowGrp} `}>
						<div className="col-12 col-sm-4 col-md-4">
							<label className={style.lableTextS}>Wholesale Opportunity</label>
							<input type="text" className={style.inputFld} id="wholesale_opp_num" value={selectedInternalQuote.wholesaleOpp} onChange={(event) => props.updateWholesaleOpportunity(event.target.value)}></input>
						</div>
					</div>
					<div className={`row ${style.rowGrp} `}>
						<div className="col-12">
							<input type="checkbox" id="disableEmail" name="disableEmail" checked={selectedInternalQuote.disableCustEmail ? "checked" : ""} onChange={(event) => props.updateDisableCustEmail(event.target.checked)} />
							<label htmlFor="disableEmail" className={` ${style.checkboxText} ${style.textTransformNone} mb-2`}>Disable Email to Customer</label>
						</div>
					</div>
					{/*Renewals might get it's own page 
                <div className={`row ${style.rowGrp} `}>
                    <div className="col-12">
                        <input type="checkbox" id="quoteRenewal" name="quoteRenewal" checked={selectedInternalQuote.renewalQuote ? "checked" : ""} onChange={(ev) => renewalHandler(ev)}/>
                        <label htmlFor="quoteRenewal" className={` ${style.checkboxText} ${style.textTransformNone} mb-2`}>Renewal Quote</label>
                    </div>
                </div>
                {selectedInternalQuote.renewalQuote ? 
                <>
                    <div className={`row ${style.rowGrp} `}>
                        <div className="col-12 col-sm-4 col-md-4">
                            <label className={style.lableTextS}>Previous Quote Number</label>
                            <input type="text" className={style.inputFld} id="prev_id" value={selectedInternalQuote.wholesaleOpp} onChange={(ev) => props.updatePrevQuoteNum(ev.target.value)}></input>
                        </div>
			        </div>
                    <div className={`row ${style.rowGrp} `}>
                        <div className="col-12 col-sm-4 col-md-4">
                            <label className={style.labelSelectRequiredFld}>Existing Circuit ID<span className={style.mandatory}> *</span></label>
                            <Select
                                value={{value: selectedInternalQuote.existingCircuitID?.value , label: selectedInternalQuote.existingCircuitID?.label}}
                                onChange={circuitHandler}
                                options={circuitList.map((item) => {
                                    return { value: item.value, label: item.label }
                                })}
                                isSearchable
                                isClearable
                                noOptionsMessage={() => 'Loading Circuit Options'}
                            />
                        </div>
                        {selectedInternalQuote.existingCircuitID?.value === "manual" ? 
                            <div className="col-12 col-sm-4 col-md-4">
                                <label className={style.labelSelectRequiredFld}>Manual Circuit ID<span className={style.mandatory}>*</span></label>
                                <input type="text" className={style.inputFld} id="manual_circuit" placeholder="Manually Enter Circuit ID" value={selectedInternalQuote.manualCircuitID} onChange={(ev) => props.updateManualQuoteCircuitID(ev.target.value)}></input>
                            </div> : null
                        }
                    </div> 
                    <div className={`row ${style.rowGrp} `}>
                        <div className="col-12 col-sm-4 col-md-4">
                            <label className={style.lableTextS}>End Term Date<span className={style.mandatory}> *</span></label>
                            <div></div>
                            <input type="date" id="endTermDate" className={style.dateFld} value={selectedInternalQuote.endTermDate} onChange={(ev) => props.updateEndTermDate(ev.target.value)}></input>
                        </div>
                        <div className="col-12 col-sm-4 col-md-4">
                            <label className={style.labelSelectRequiredFld}>Legacy Network<span className={style.mandatory}>*</span></label>
                            <select className={` form-control  ${style.selectFld} ${style.requiredFld} `} value={selectedInternalQuote.legacyNetwork} onChange={(ev) => props.updateLegacyNetwork(ev.target.value)}>
                                <option value=""></option>
                                <option value="1">M6</option>
                                <option value="2">NGMSS</option>
                            </select>
                        </div>
			        </div>
                    <div className={`row ${style.rowGrp} `}>
                        <div className="col-12 col-sm-4 col-md-4">
                            <label className={style.labelSelectRequiredFld}>Billing System<span className={style.mandatory}> *</span></label>
                            <select className={` form-control  ${style.selectFld} ${style.requiredFld} `} value={selectedInternalQuote.billingSystem} onChange={(ev) => props.updateBillingSystem(ev.target.value)}>
                                <option value=""></option>
                                <option value="1">Revchain</option>
                                <option value="2">CABS</option>
                            </select>
                        </div>
                        <div className="col-12 col-sm-4 col-md-4">
                            <label className={style.labelSelectRequiredFld}>Offnet<span className={style.mandatory}> *</span></label>
                            <select className={` form-control  ${style.selectFld} ${style.requiredFld} `} value={selectedInternalQuote.offnet} onChange={(ev) => props.updateOffnet(ev.target.value)}>
                                <option value=""></option>
                                <option value="Y">YES</option>
                                <option value="N">NO</option>
                            </select>
                        </div>
			        </div>
                </> : null
                }*/}
					<div className={`row ${style.rowGrp} `}>
						<div className="col-12">
							<input type="checkbox" id="noncomm" name="noncomm" checked={selectedInternalQuote.nonCommissionable ? "checked" : ""} onChange={(event) => props.updateNonCommissionable(event.target.checked)} />
							<label htmlFor="noncomm" className={` ${style.checkboxText} ${style.textTransformNone} mb-2`}>Non-Commissionable</label>
						</div>
					</div>
					<div className={`row ${style.rowGrp} `}>
						<div className="col-12">
							<input type="checkbox" id="markup" name="markup" checked={selectedInternalQuote.markup ? "checked" : ""} onChange={(event) => props.updateMarkup(event.target.checked)} />
							<label htmlFor="markup" className={` ${style.checkboxText} ${style.textTransformNone} mb-2`}>Markup</label>
						</div>
					</div>
					<div className={`row ${style.rowGrp} `}>
						<div className="col-12">
							<input type="checkbox" id="pricingOverride" name="pricingOverride" checked={selectedInternalQuote.pricingOverride ? "checked" : ""} onChange={(event) => props.updatePricingOverride(event.target.value)} />
							<label htmlFor="pricingOverride" className={` ${style.checkboxText} ${style.textTransformNone} mb-2`}>Pricing Override</label>
						</div>
					</div>
					<div className={`row ${style.rowGrp} `}>
						<div className="col-12">
							<input type="checkbox" id="agent" name="agent" checked={selectedInternalQuote.agent ? "checked" : ""} onChange={(event) => agentHandler(event)} />
							<label htmlFor="agent" className={` ${style.checkboxText} ${style.textTransformNone} mb-2`} >Agent Information</label>
						</div>
					</div>
					{selectedInternalQuote.agent ?
						<>
							<div className={`row ${style.rowGrp} `}>
								<div className="col-12 col-sm-4 col-md-4">
									<label className={style.lableTextS}>Agent Name<span className={style.mandatory}> *</span></label>
									<input type="text" className={style.inputFld} id="agent_name" value={selectedInternalQuote.agentName} onChange={(event) => props.updateAgentName(event.target.value)}></input>
								</div>
								<div className="col-12 col-sm-4 col-md-4">
									<label className={style.lableTextS}>Agent Phone</label>
									<input type="text" className={style.inputFld} id="agent_phone" value={selectedInternalQuote.agentPhone} onChange={(event) => props.updateAgentPhone(event.target.value)}></input>
								</div>
								<div className="col-12 col-sm-4 col-md-4">
									<label className={style.lableTextS}>Agent Fee<span className={style.mandatory}> *</span></label>
									<input type="text" className={style.inputFld} id="agent_fee" value={selectedInternalQuote.agentFee} onChange={(event) => props.updateAgentFee(event.target.value)}></input>
								</div>
							</div>
							<div className={`row ${style.rowGrp} `}>
								<div className="col-12 col-sm-4 col-md-4">
									<label className={style.lableTextS}>Agent Email<span className={style.mandatory}> *</span></label>
									<input type="text" className={style.inputFld} id="agent_email" value={selectedInternalQuote.agentEmail} onChange={(event) => props.updateAgentEmail(event.target.value)}></input>
								</div>
							</div>
							<div className={`row ${style.rowGrp} `}>
								<div className="col-12 col-sm-4 col-md-4">
									<label className={style.lableTextS}>Address</label>
									<input type="text" className={style.inputFld} id="agent_address" value={selectedInternalQuote.agentAddress} onChange={(event) => props.updateAgentAddress(event.target.value)}></input>
								</div>
							</div>
							<div className={`row ${style.rowGrp} `}>
								<div className="col-12 col-sm-4 col-md-4">
									<label className={style.lableTextS}>City</label>
									<input type="text" className={style.inputFld} id="agent_city" value={selectedInternalQuote.agentCity} onChange={(event) => props.updateAgentCity(event.target.value)}></input>
								</div>
								<div className="col-12 col-sm-4 col-md-4">
									<label className={style.lableTextS}>State</label>
									<select className={` form-control  ${style.selectFld} ${style.requiredFld} `} value={selectedInternalQuote.agentState} onChange={(event) => props.updateAgentState(event.target.value)}>
										<option value=""></option>
										{stateList.map((obj) => {
											return <option value={obj.id}>{obj.name}</option>
										})}
									</select>
								</div>
								<div className="col-12 col-sm-4 col-md-4">
									<label className={style.lableTextS}>Zip</label>
									<input type="text" className={style.inputFld} id="agent_zip" value={selectedInternalQuote.agentZip} onChange={(event) => props.updateAgentZip(event.target.value)}></input>
								</div>
							</div>
						</> : null
					}
					<div className={`row ${style.rowGrp} `}>
						<div className="col-12">
							<input type="checkbox" id="enterprise_rep" name="enterprise_rep" checked={selectedInternalQuote.enterpriseRep ? "checked" : ""} onChange={(event) => enterpriseRepHandler(event)} />
							<label htmlFor="agent" className={` ${style.checkboxText} ${style.textTransformNone} mb-2`} >Windstream Enterprise Rep</label>
						</div>
					</div>
					{selectedInternalQuote.enterpriseRep ?
						<div className={`row ${style.rowGrp} `}>
							<div className="col-12 col-sm-4 col-md-4">
								<label className={style.lableTextS}>Enterprise Rep Name<span className={style.mandatory}> *</span></label>
								<input type="text" className={style.inputFld} id="enterprise_name" value={selectedInternalQuote.enterpriseRepObj?.enterprise_name} onChange={(event) => props.updateEnterpriseRepObj(event.target.id, event.target.value)}></input>
							</div>
							<div className="col-12 col-sm-4 col-md-4">
								<label className={style.lableTextS}>Enterprise Rep Email<span className={style.mandatory}> *</span></label>
								<input type="text" className={style.inputFld} id="enterprise_email" value={selectedInternalQuote.enterpriseRepObj?.enterprise_email} onChange={(event) => props.updateEnterpriseRepObj(event.target.id, event.target.value)}></input>
							</div>
						</div> : null
					}

				</> : null

			}
		</>
	);
}

const mapDispatchToProps = {
	updateAgency: setAgency,
	updateDisableCustEmail: setDisableCustEmail,
	updateRenewalQuote: setRenewalQuote,
	updateEndTermDate: setEndTermDate,
	updateExistingQuoteCircuits: setExistingQuoteCircuitID,
	updateManualQuoteCircuitID: setManualQuoteCircuitID,
	updateLegacyNetwork: setLegacyNetwork,
	updateBillingSystem: setBillingSystem,
	updatePrevQuoteNum: setPrevQuoteNum,
	updateOffnet: setOffnet,
	updateNonCommissionable: setNonCommissionable,
	updateMarkup: setMarkup,
	updateWholesaleOpportunity: setWholesaleOpportunity,
	updatePricingOverride: setPricingOverride,
	updateAgent: setAgent,
	updateAgentName: setAgentName,
	updateAgentPhone: setAgentPhone,
	updateAgentFee: setAgentFee,
	updateAgentEmail: setAgentEmail,
	updateAgentAddress: setAgentAddress,
	updateAgentCity: setAgentCity,
	updateAgentState: setAgentState,
	updateAgentZip: setAgentZip,
	updateEnterpriseRep: setEnterpriseRep,
	updateEnterpriseRepObj: setEnterpriseRepObj
}

export default connect(null, mapDispatchToProps)(QuoteInternal);
