import axios from "./axios/customAxios";

const getRevchainInvoiceData = async (accountNum) => {
    const invoiceURL = `${process.env.REACT_APP_API_URL}/getInvoices.php?accountNum=${accountNum}`;
    return await axios.get(invoiceURL);
}

const getCabsInvoiceData = async (ban) => {
    const invoiceURL = `${process.env.REACT_APP_API_URL}/getCDGInvoices.php?ban=${ban}&invoiceCompanyId=6111`;
    return await axios.get(invoiceURL);
}

const getCabsInvoicePDF = async (data) => {
    const config = {
        responseType: 'blob',
        headers: {
            'Accept': 'application/pdf',
        },
    };
    const invoiceURL = `${process.env.REACT_APP_API_URL}/getCDGInvoicePDF.php`;
    return await axios.post(invoiceURL, JSON.stringify(data), config);
}


const InvoiceHelper = {
    getRevchainInvoiceData,
    getCabsInvoiceData,
    getCabsInvoicePDF
}

export default InvoiceHelper;
