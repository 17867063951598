import axios from "./axios/customAxios";

const getPopLocations = async (cos) => {
	const popLocationURL = `${process.env.REACT_APP_API_URL}/getAddress.php?type=eline&cos=`+cos;
    return await axios.get(popLocationURL);
}

const getBWOptions = async (circuit) => {
	const elineBWURL = `${process.env.REACT_APP_API_URL}/getBandwidths.php?product=19&menu=bw&circuit=`+circuit;
    return await axios.get(elineBWURL);
}


const ELineHelper = {
    getPopLocations,
    getBWOptions
}

export default ELineHelper;
