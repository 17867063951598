/**
 * Created by Rochaus on 1/20/2021.
 */
import React, { useCallback, useEffect, useState, useMemo } from 'react';
import ViewportWarning from "../shared/ViewportWarning";
import TabsQuote from "./includes/TabsQuote";
import style from "./QuoteSummary.module.css";
import Loader from "../shared/Loader";
import { ConsoleLogger } from "../../logger/ConsoleLogger";
import QuoteHelper from "../../services/QuoteService";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { NavLink } from "react-router-dom";
import { ExportXLSX } from "../../utils/ExportXLSX";
import HTTPErrorHandler from "../errorPages/HTTPErrorHandler";
import CompanyDropdown from '../shared/CompanyDropdown';
import { useSelector } from 'react-redux';

const BulkQuoteSummary = () => {
	const logger = useMemo(() => new ConsoleLogger({ level: process.env.REACT_APP_LOGGER_LEVEL, }), []);
	const [hasError, setHasError] = useState(null);
	const [loader, setLoader] = useState(false);
	const [bulkQuoteSummary, setBulkQuoteSummary] = useState([]);
	const [bulkQuoteDownloadData, setBulkQuoteDownloadData] = useState([]);
	const [reportOptionOpen, setReportOptionOpen] = useState(false);
	const isEmployee = localStorage.getItem('isEmployeeLoggedIn');
	const selectedInternalMpid = useSelector(state => state.internal.company?.value);
	const [errorMSG, setErrorMSG] = useState('');

	const [days, setDays] = useState(15);

	const columns = [{
		dataField: 'Company',
		text: '',
		filter: textFilter({
			placeholder: 'Company',
			className: 'placeHolderInput'
		}),
	},
	{
		dataField: 'BulkId',
		text: '',
		classes: "text-break",
		filter: textFilter({
			placeholder: 'Bulk ID',
			className: 'placeHolderInput'
		}),
		formatter: (cell, row) => quoteFormater(cell, row)
	},
	{
		dataField: 'Project',
		text: '',
		classes: "text-break",
		filter: textFilter({
			placeholder: 'Project',
			className: 'placeHolderInput'
		}),
	},
	{
		dataField: 'Product',
		text: '',
		filter: textFilter({
			placeholder: 'Product',
			className: 'placeHolderInput'
		}),
	},
	{
		dataField: 'Terms',
		text: '',
		filter: textFilter({
			placeholder: 'Terms',
			className: 'placeHolderInput'
		}),
	},
	{
		dataField: 'Status',
		text: '',
		filter: textFilter({
			placeholder: 'Status',
			className: 'placeHolderInput'
		}),
	},
	{
		dataField: 'Submitted',
		text: '',
		filter: textFilter({
			placeholder: 'Submitted',
			className: 'placeHolderInput'
		}),
	},
	{
		dataField: 'Completed',
		text: '',
		filter: textFilter({
			placeholder: 'Completed',
			className: 'placeHolderInput'
		}),
	}

	];

	// This is how thw link is set up.
	const quoteFormater = (props, row) => {
		let URL = `/Quote/Bulk/View/${props}`;
		return (<NavLink className={style.quoteLink} to={URL} activeClassName="active">{row.Project}</NavLink>)
	}

	const fileFormater = (props, row) => {
		return (<NavLink className={style.quoteLink} activeClassName="active">{row.Project}</NavLink>)
	}

	const handleUpdate = async (event) => {
		let num_days = event.target.value;
		setDays(num_days);
	}

	// I do not know the usage of this method or hooks
	const reportOption = () => {
		setReportOptionOpen(!reportOptionOpen);
	}

	const getBulkQuotes = useCallback((days) => {
		setErrorMSG('');
		setLoader(true);
		setBulkQuoteSummary([]);
		setBulkQuoteDownloadData([]);
		QuoteHelper.getBulkQuoteData(days, 'bulk').then((response) => {
			logger.info('getBulkQuoteData response: ' + JSON.stringify(response.data.result));
			if (response.data.result === 'SUCCESS') {
				setBulkQuoteSummary(response.data.bulk);
				setBulkQuoteDownloadData(response.data.bulk);
			} else {
				logger.error('getBulkQuoteData error getting data: ' + JSON.stringify(response.data.error));
				setErrorMSG(response.data.error);
			}
			setLoader(false);
		}).catch(error => {
			setLoader(false);
			logger.error('getBulkQuoteData Error: ' + error);
			setHasError(error);
		});
	}, [logger]);

	useEffect(() => {
		if (isEmployee) {
			//Make sure an internal mpid is selected before calling php
			if (selectedInternalMpid) {
				getBulkQuotes(days);
			}
		} else {
			getBulkQuotes(days);
		}

	}, [days, getBulkQuotes, isEmployee, selectedInternalMpid]);



	return (
		<div>
			{hasError && <HTTPErrorHandler error={hasError}></HTTPErrorHandler>}
			{loader ? <Loader /> : null}
			<div className="midsection qm-summary quote-summary abs z1">
				<div className="rel">
					<ViewportWarning />
					<div className="wrap">
						<h1 className="page-title">{isEmployee ? "Employee Bulk Quote Summary" : "Bulk Quote Summary"}</h1>
						{isEmployee ? <CompanyDropdown area={"Summary"} /> : null}
						<div className="section">
							{/* <TabsQuoteManager/> */}
							<TabsQuote />
							{!hasError && (<div className={`content ${style.contentQueryPage} `}>
								<div className={`sub-hdr alt2 ${style.subHdrAlter}`}>
									<div className="nib">
										<div className="the-notch">
											<div className="notch-fg">
												<svg>
													<path
														d="M35.81,26.28l-13,10v-5C-10.47,28.68-1,9.16,11.79,0c0,7.28,0,9.13,0,13.28s3.18,7.92,11,9v-5Z"></path>
												</svg>
											</div>
										</div>
									</div>
									<h2 className={` ${style.sectionTitle} m-0 `}>Bulk Quotes</h2>
								</div>
								<div className="align-right">
									{/* <GridFilter /> */}
									<div className={style.filterWrap}>
										<div className={style.buttonWrap}>
											<select className={`${style.dateSelect}`} id='days' name="days" value={days}
												onChange={handleUpdate}>
												<option value='15'>Past 15 Days</option>
												<option value='30'>Past 30 Days</option>
												<option value='60'>Past 60 Days</option>
												<option value='90'>Past 90 Days</option>
											</select>
										</div>
										<div>
											<button id={"saveFile"} className={`saveButton ico-button light-button`}>
												<ExportXLSX label="Download Summary"
													csvData={bulkQuoteDownloadData}
													fileName={`Bulk_Quote_Summary_${Date.now()}`} />
											</button>
										</div>
									</div>
								</div>
								<p className={`text-center my-4 text-danger`}>{errorMSG}</p>
								<div className="rel b mt-3"></div>
								<div className={`tableWrap bulkQuoteSummaryTableWrap`}>
									<div className={`rel pagination-table page-list`}>
										<BootstrapTable id="bulkQuoteSummaryTable"
											keyField='BulkId'
											data={bulkQuoteSummary}
											columns={columns}
											striped
											hover
											condensed
											bordered={false}
											pagination={paginationFactory({
												sizePerPage: 5
											})}
											filter={filterFactory()}
											loading={true}
											className="mb-0"
										/>
									</div>
								</div>
							</div>)}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default BulkQuoteSummary;