import { Text, View, } from '@react-pdf/renderer';
import { styles } from './pdfStyles';

const OrderInvoice = ({ invoiceData, term, orderData }) => {
    console.log("invoiceData: " + JSON.stringify(invoiceData));
    if (!invoiceData || invoiceData.length === 0) return;

    const invoiceFees = Object.entries(invoiceData)
        .filter(([key, value]) => !['icbPricingName'].includes(key))
        .map(([key, value]) => value);
    const icbPricingName = invoiceData?.icbPricingName || '';
    const termDisplay = orderData?.o_circuit === '7' ? `${Math.floor(term / 12)} Year Term` : `${term} Month Term`;

    return (
        <View wrap={false}>
            <View style={styles.headerBarTopMargin}>
                <Text style={styles.headerBarText}>Invoice Example</Text>
            </View>
            <View style={styles.tableRowCenter}>
                <Text style={[styles.tableColumn, styles.tableCell, styles.textNormal]}>
                    Example may not reflect exactly the same on invoice.
                </Text>
            </View>
            <View style={styles.pricingTable}>
                <View style={[styles.tableRow, styles.headerRow, styles.tableBorder, styles.pricingHeaderRowBg]}>
                    <Text style={[styles.tableColumn2Width, styles.tableCell, styles.textBold]}>Item(s)</Text>
                    <Text style={[styles.quantityColumn, styles.tableCellCenter, styles.textBold]}>Qty</Text>
                    <Text style={[styles.tableColumn, styles.tableCellCenter, styles.textBold]}>MRC</Text>
                    <Text style={[styles.tableColumn, styles.tableCellCenter, styles.textBold]}>NRC</Text>
                </View>
                {invoiceFees.map((item, idx) => (
                    <View key={`invoiceFee-item-${idx}`} style={[styles.tableRow]}>
                        <Text style={[styles.tableColumn2Width, styles.tableCell, styles.textNormal]}>
                            {item.item}
                        </Text>
                        <Text style={[styles.quantityColumn, styles.tableCellCenter, styles.textNormal]}>
                            {item.qty}
                        </Text>
                        <Text style={[styles.tableColumn, styles.tableCellCenter, styles.textNormal]}>
                            {item.mrc}
                        </Text>
                        <Text style={[styles.tableColumn, styles.tableCellCenter, styles.textNormal]}>
                            {item.nrc}
                        </Text>
                    </View>
                ))}
            </View>
        </View>
    );
};

export default OrderInvoice;