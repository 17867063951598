/**
 * Created by e0173131 on 1/20/2021.
 */
import React, {useState, Fragment} from 'react';

const GoodieSupertip = () => {
    const [isCollapsed, setIsCollapsed] = useState(true);

    const handleClick = () => {
        setIsCollapsed(!isCollapsed);
    }
    return (
        <Fragment>
            <span className="cf"></span>
            <div className="sub-hdr alt3">
                <div className="expander" collapsed-id="supertip-content" onClick={handleClick}>
                    <svg className="ico-link light-ico ico-add">
                        <path
                            d="M24,48A24,24,0,1,1,48,24,24,24,0,0,1,24,48ZM24,1.89A22.1,22.1,0,1,0,46.08,24,22.09,22.09,0,0,0,24,1.89Zm1,32.69H23.05V25h-9.6V23.05h9.6v-9.6H25v9.6h9.61V25H25Z"></path>
                    </svg>
                </div>
                <div className="trim">&nbsp;</div>
                <div className="nib">
                    <div className="the-notch">
                        <div className="notch-fg">
                            <svg>
                                <path
                                    d="M35.81,26.28l-13,10v-5C-10.47,28.68-1,9.16,11.79,0c0,7.28,0,9.13,0,13.28s3.18,7.92,11,9v-5Z"></path>
                            </svg>
                        </div>
                    </div>
                </div>
                <h2>Supertip&trade;!</h2>
            </div>
            {!isCollapsed ?
                <div className="sub-section">
                    <p>This is a custom UI tooltip I wrote; intended to replace HTML alt/title attributes</p>
                    <p>Simple usage<br /><em className="code">className="tipped" data-supertip="I'm Supertip!"</em></p>
                    <p><a href="#" className="tipped" data-supertip="I'm Supertip!">Hover Over Me</a></p>
                    <p>Corner<br /><em className="code">className="tipped cornertip" data-supertip="This is intented to
                        have a brief description but nothing lengthy."</em></p>
                    <p>
                        <a href="#" className="tipped cornertip fr"
                            data-supertip="This is intented to have a brief description but nothing lengthy.">Hover
                            on the Right</a>
                        <a href="#" className="tipped cornertip"
                            data-supertip="This is intented to have a brief description but nothing lengthy.">Hover
                            on the Left</a>
                    </p>
                    <p><em>Note that there are more features baked in to my Supertip if you start looking at the
                        code (panel, sticky panel requiring a Click to close, dynamic) but I left it out of the
                        documentation here since it is not being utilized (yet).</em></p>
                </div>
            : null}
        </Fragment>
    );
}

export default GoodieSupertip;
