/**
 * Created by Boby on 10/1/2021.
 */

/**
 * JS Imports
 * 
 */

// React Core Libraries

// Local JS Files Imports
import ViewportWarning from "../shared/ViewportWarning";
import TabsQuote from "./includes/TabsQuote";
import Loader from "../shared/Loader";
// import useWindowDimensions from '../../utils/customHooks/useWindowDimensions';
// 3rd party npm Libraries
import ReactTooltip from 'react-tooltip';

const LightLevels = () => {
    // const { width } = useWindowDimensions();
    // const [screenWidth, setScreenWidth] = useState(width);
    // const [loader, setLoader] = useState(false);
    const loader = false;

    return (
        <>
            <ReactTooltip />
            {loader ? <Loader /> : null}

            <div id="light-levels-midsection" className="midsection qm-summary abs z1">
                <div className="rel">
                    <ViewportWarning />
                    <div id="light-levels-wrap" className="wrap">
                        <div id="light-levels-header-links" className="header-links">
                            <span>Circuit Health</span>
                        </div>
                        <h1 className="page-title">
                            <span>CA/KJUS/795678/ /WXN /</span>
                        </h1>
                        <div id="light-levels-table-secion" className="section">
                            {/* <TabsQuoteManager/> */}
                            <TabsQuote />
                            <div className="content">
                                <div>
                                    <h1 className="m-0">No Data Available - Light Levels</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default LightLevels;
