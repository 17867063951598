/**
 * Created by e0173131 on 1/20/2021.
 */
import React, {useState, Fragment} from 'react';

const GoodieForm = () => {
    const [isCollapsed, setIsCollapsed] = useState(true);

    const handleClick = () => {
        setIsCollapsed(!isCollapsed);
    }
    return (
        <Fragment>
            <span className="cf"></span>
            <div className="sub-hdr alt1">
                <div className="expander" collapsed-id="form-content" onClick={handleClick}>
                    <svg className="ico-link light-ico ico-add">
                        <path
                            d="M24,48A24,24,0,1,1,48,24,24,24,0,0,1,24,48ZM24,1.89A22.1,22.1,0,1,0,46.08,24,22.09,22.09,0,0,0,24,1.89Zm1,32.69H23.05V25h-9.6V23.05h9.6v-9.6H25v9.6h9.61V25H25Z"></path>
                    </svg>
                </div>
                <div className="trim">&nbsp;</div>
                <div className="nib">
                    <div className="the-notch">
                        <div className="notch-fg">
                            <svg>
                                <path
                                    d="M35.81,26.28l-13,10v-5C-10.47,28.68-1,9.16,11.79,0c0,7.28,0,9.13,0,13.28s3.18,7.92,11,9v-5Z"></path>
                            </svg>
                        </div>
                    </div>
                </div>
                <h2>Form Controls</h2>
            </div>
            {!isCollapsed ?
                <div className="sub-section">View source on these for precise code/usage:<br /><br />
                    <h3>Input Fields</h3>
                    <div className="input-field light w30">
                        <span>Field Label</span>
                        <input type="text" placeholder="I'm an input field" />
                    </div>
                    <p>
                        <br />
                    </p>
                    <div className="input-field light error w40">
                        <span>Field Label + Error</span>
                        <input type="text" value="Oops" placeholder="I'm an input field" />
                    </div>
                    <p>
                        <br />
                    </p>
                    <div className="input-field disabled w50">
                        <span>Field Label + Disabled</span>
                        <input type="text" value="No input for you!" placeholder="I'm an input field" disabled />
                    </div>
                    <p>
                        <br />
                    </p>
                    <h3>Select Drop-Downs</h3>
                    <em className="orange">*Note that standard HTML select will not work in IE11 when used inside
                        the primary navigation. Use must leverage a custom UI select of some sort like jQuery
                        niceSelect (see below)*</em>
                    <p>Dark Select (used on a dark backdrop) + percent width<br /><em className="code">className="dark-select
                        w[xx]"</em><br /></p>
                    <select className="dark-select w20" name="">
                        <option>Download As</option>
                    </select>
                    <p><br /></p>
                    <p>Light Select (used over white/regular content) + percent width<br /><em className="code">className="light-select
                        w[xx]"</em>
                    </p>
                    <select className="light-select w20" name="">
                        <option>Download As</option>
                    </select>
                    <p><br /></p>
                    <h3>jQuery Nice Select</h3>
                    <p>This is a custom UI select drop-down (that will also work inside IE11 hover blocks like the
                        primary menu).<br /><em className="code">className="nice-select [light-select|dark-select]
                            w[xx]"</em></p>
                    <div className="w20">
                        <select className="nice-select light-select wide">
                            <option selected>State</option>
                            <option>NY</option>
                        </select>
                    </div>
                    <p><br /></p>
                    <h3>Textarea</h3>
                    <div className="labeled-textarea">
                        <span>I am a Textarea</span>
                        <textarea></textarea>
                    </div>
                    <p><br /></p>
                    <h3>Radio/Checkbox</h3>Not defined here because it depends on the form framework (<a
                        href="https://www.telerik.com/kendo-ui" target="_blank" rel="noreferrer">Kendo UI</a> for example) but the UI
                    will probably need to resemble WE Connect which would involve additional overhead. For now I am
                    OK with the default browser or would-be framework appearance:
                    <br /><br />
                    <input type="radio" name="radex" />
                    &nbsp;&nbsp;
                    <input type="radio" name="radex" checked />
                    <br /><br />
                    <input type="checkbox" name="chkex" />
                    <p><br /></p>
                    <h3>Date Control</h3>
                    <div className="pagination">
                        <label htmlFor="date-from">From</label>
                        <input type="date" id="date-from" name="date-start" value="2021-07-22" min="2020-01-01"
                            max="2021-12-31" />
                        <label htmlFor="start">To</label>
                        <input type="date" id="date-to" name="date-end" value="2021-07-23" min="2020-01-01"
                            max="2021-12-31" />
                    </div>
                    <br />
                    <p>
                        <em className="red">Note that type="date" is not suppported by IE11.</em> You would need to
                        leverage a cross-browser JS solution such as
                        <a href="https://jqueryui.com/datepicker/" target="_blank" rel="noreferrer">jqueryui.com/datepicker/</a> or
                        <a href="https://www.telerik.com/kendo-angular-ui/components/dateinputs/datepicker/">www.telerik.com/kendo-angular-ui/components/dateinputs/datepicker/</a>.
                        I did not include these libraries for just showing off the datepicker but I did add the
                        calendar icon you would utilize when those datepickers are restyled.</p>
                </div>
                : null}
        </Fragment>
    );
}

export default GoodieForm;
