import axios from "./axios/customAxios";

const getCoherentProbeDemo = async () => {
	return await axios.get(window.location.origin + '/assets/json/coherentProbeDemo.json');
}

const getCoherentProbeData = async (cid) => {
	const coherentProbeURL = `${process.env.REACT_APP_API_URL}/ni/getCoherentProbesInfo.php?cid=${cid}`;
	return await axios.get(coherentProbeURL);
}


const CoherentProbeHelper = {
    getCoherentProbeDemo,
    getCoherentProbeData
}

export default CoherentProbeHelper;
