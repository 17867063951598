import axios from "./axios/customAxios";


const getPOPLocations = async () => {
	const wavePOPLocationURL = `${process.env.REACT_APP_API_URL}/getAddress.php?type=wave`;
    return await axios.get(wavePOPLocationURL);
}

const getWaveAvailBW = async (uid) => {
	const waveBWAvailURL = `${process.env.REACT_APP_API_URL}/getBandwidths.php?menu=waveAvailBW&uid=${uid}`;
	return await axios.get(waveBWAvailURL);
}

const getWaveBW = async () => {
	const waveBWURL = `${process.env.REACT_APP_API_URL}/getBandwidths.php?product=1&menu=bw&circuit=7`;
    return await axios.get(waveBWURL);
}

const getWaveProtocols = async (bandwidth) => {
	const protocalURL = `${process.env.REACT_APP_API_URL}/getBandwidths.php?menu=protocol&band=`+bandwidth;
    return await axios.get(protocalURL);
}

const getLandlordNotices = async (uid) => {
	const landlordURL = `${process.env.REACT_APP_API_URL}/getLandlord.php?uid=${uid}`;
	return await axios.get(landlordURL);
}

const WaveHelper = {
	getPOPLocations,
	getWaveAvailBW,
    getWaveBW,
    getWaveProtocols,
	getLandlordNotices
}

export default WaveHelper
