import { useEffect, useState } from 'react';
import { ConsoleLogger } from "../../logger/ConsoleLogger";
import style from './Summary.module.css';
import OutageOverlayHelper from '../../services/OutageOverlayService';
import { useParams } from 'react-router-dom';
import SummaryData from '../../services/CircuitSummaryService';
import Loader from '../shared/Loader';
import { useSelector } from 'react-redux';

const Fiberpath = (props) => {
	const logger = new ConsoleLogger({ level: process.env.REACT_APP_LOGGER_LEVEL, });
	logger.info(`KMZ TOKEN PASSED FROM SUMMARY PAGE: ${props.kmzToken}`);
	const [kmlModal, setkmlModal] = useState(false);
	const defaultLatlng = { 'lat': 41.85, 'lng': -87.65 };
	const google = window.google = window.google ? window.google : {};
	const localURL = "iconnect-local.windstreamwholesale.com";
	const devURL = "iconnect-dev.windstreamwholesale.com";
	const uatURL = "iconnect-uat.windstreamwholesale.com";
	const prodURL = "iconnect.windstreamwholesale.com";
	const user = localStorage.getItem('user');
	const selectedInternalMpid = useSelector(state => state.internal.company?.value);
	const [outageOverlay, setOutageOverlay] = useState(false);
	const [downloadLink, setDownloadLink] = useState("");
	const [outageDownloadLink, setOutageDownloadLink] = useState("");
	const [outageDays, setOutageDays] = useState("1");
	const [outageKmzLink, setOutageKmzLink] = useState("");
	const [outageCSVAvailable, setOutageCSVAvailable] = useState(true);
	const [outageExcelAvailable, setOutageExcelAvailable] = useState(true);
	const [loader, setLoader] = useState(false);
	const { designID } = useParams();
	logger.info(`Account User: ${user}`);
	var map;

	var styles = [
		{
			"stylers": [
				{ "hue": "#EEEEEE" },
				{ "saturation": -300 },
				{ "lightness": 20 }
			]
		}
	]

	useEffect(() => {
		if (props.kmzToken !== "" && window.location.hostname === prodURL && user !== "Wholesale_Demo" && Number(selectedInternalMpid) !== 8683) {
			kmlInit(`${process.env.REACT_APP_KMZ_URL}/SaveKmzFile.php?token=${props.kmzToken}`);
			setDownloadLink(`${process.env.REACT_APP_KMZ_URL}/SaveKmzFile.php?token=${props.kmzToken}`);
		} else if (window.location.hostname === localURL || window.location.hostname === devURL || window.location.hostname === uatURL || user === "Wholesale_Demo" || Number(selectedInternalMpid) === 8683) {
			kmlInit("https://iconnect.windstreamwholesale.com/kmz/SaveKmzFile.php?token=d3g5w9n3d7l9i9i1");
			setDownloadLink("https://iconnect.windstreamwholesale.com/kmz/SaveKmzFile.php?token=d3g5w9n3d7l9i9i1");
		}
	}, [])

	useEffect(() => {
		if (outageKmzLink) {
			kmlInit(outageKmzLink);
		}
	}, [outageKmzLink])

	const initMap = () => {
		map = new google.maps.Map(document.getElementById('map'), {
			center: defaultLatlng,
			zoom: 6,
			mapTypeId: "terrain"
		});

		map.setOptions({ styles: styles });
	}

	const kmlInit = async (src) => {
		await initMap();
		logger.info(src);
		var kmlLayer = new google.maps.KmlLayer(src, {
			suppressInfoWindows: true,
			preserveViewport: false,
			map: map
		});

		//Set the information for each point on the fiber path
		kmlLayer.addListener('click', function (event) {
			var content = event.featureData.infoWindowHtml;
			var kmlCapture = document.getElementById('capture');
			kmlCapture.innerHTML = content;
			setkmlModal(true);
		});

	}

	const closeKMLModal = () => {
		setkmlModal(false);
	}

	//ADO-683072 KMZ Outage Demo
	const outageHandler = (ev) => {
		setOutageOverlay(ev.target.checked);
		setOutageCSVAvailable(true);
		setOutageExcelAvailable(true);

		if (!ev.target.checked) {
			if (window.location.hostname === localURL || window.location.hostname === devURL || window.location.hostname === uatURL || user === "Wholesale_Demo" || Number(selectedInternalMpid) === 8683) {
				kmlInit("https://iconnect.windstreamwholesale.com/kmz/SaveKmzFile.php?token=d3g5w9n3d7l9i9i1");
				setDownloadLink("https://iconnect.windstreamwholesale.com/kmz/SaveKmzFile.php?token=d3g5w9n3d7l9i9i1");
			} else {
				kmlInit(`${process.env.REACT_APP_KMZ_URL}/SaveKmzFile.php?token=${props.kmzToken}`);
				setDownloadLink(`${process.env.REACT_APP_KMZ_URL}/SaveKmzFile.php?token=${props.kmzToken}`);
			}
		} else {
			//the outage feature will never work in local, dev, or uat so we are hardcoding a kmz to make it work in those environments
			if (window.location.hostname === localURL || window.location.hostname === devURL || window.location.hostname === uatURL || user === "Wholesale_Demo" || Number(selectedInternalMpid) === 8683) {
				//Calling outage demo kmz file --NOTE: I added a time parameter to the url to prevent any caching issues
				kmlInit("https://content.windstreamwholesale.com/download/outageToday.kmz?timeRequested=" + (new Date()).getTime());
				setOutageDownloadLink("https://content.windstreamwholesale.com/download/outageToday.kmz");
			} else {
				fetchKmzOutageLink(designID, outageDays);
			}
		}
	}

	const outageDayHandler = (ev) => {
		setOutageDays(ev.target.value);
		setOutageCSVAvailable(true);
		setOutageExcelAvailable(true);
		//the outage feature will never work in local, dev, or uat so we are hardcoding a kmz to make it work in those environments
		if (window.location.hostname === localURL || window.location.hostname === devURL || window.location.hostname === uatURL || user === "Wholesale_Demo" || Number(selectedInternalMpid) === 8683) {
			switch (ev.target.value) {
				case "1":
					kmlInit("https://content.windstreamwholesale.com/download/outageToday.kmz?timeRequested=" + (new Date()).getTime());
					setOutageDownloadLink("https://content.windstreamwholesale.com/download/outageToday.kmz");
					break;
				case "365":
					kmlInit("https://content.windstreamwholesale.com/download/outageFullYear.kmz?timeRequested=" + (new Date()).getTime());
					setOutageDownloadLink("https://content.windstreamwholesale.com/download/outageFullYear.kmz");
					break;
				default:
					break;
			}
		} else {
			fetchKmzOutageLink(designID, ev.target.value);
		}
	}

	//Function to generate the outage kmz link and then tokenize the file so we can access it from our fileshare
	const fetchKmzOutageLink = (designID, days) => {
		logger.info("Inside of fetchKmzOutageLink arrow function");
		setLoader(true);
		OutageOverlayHelper.getOutageKmzLink(designID, days).then(response => {
			logger.info(`Response from getOutageLink service call: ${JSON.stringify(response.data)}`);
			if (response.data.status === "SUCCESS") {
				/*fetch token for the kmz outage url*/
				SummaryData.getKmzToken(response.data.url, designID).then(response => {
					logger.info(`Response from getKmzToken.php: ${JSON.stringify(response.data)}`);
					if (response.data.result === "SUCCESS") {
						logger.info("Successfully got a token for the outage kmz file");
						setOutageKmzLink(`https://iconnect.windstreamwholesale.com/kmz/SaveKmzFile.php?token=${response.data.token}`);
						setOutageDownloadLink(`https://iconnect.windstreamwholesale.com/kmz/SaveKmzFile.php?token=${response.data.token}`);
						setLoader(false);
					} else {
						logger.info("Error getting token for kmz outage file");
						setLoader(false);
					}
				}).catch(error => {
					logger.info(`getKmzToken ERROR: ${error}`);
					setLoader(false);
				})
			} else {
				logger.info("FAILED TO GET OUTAGE KMZ LINK");
				setLoader(false);
			}
		}).catch(error => {
			logger.error(`getOutageLink ERROR: ${error}`);
			setLoader(false);
		})
	}

	//Function to generate the outage csv link and then tokenize the file so we can access it from our fileshare
	const fetchOutageCsv = (designID, days) => {
		setLoader(true);
		OutageOverlayHelper.getOutageCsvLink(designID, days).then(response => {
			logger.info(`getOutageCsvLink response: ${response.data}`);
			if (response.data.status === "SUCCESS" && response.data.url !== "Not Available") {
				logger.info("Successfully got a csv link");
				//fetch token for the outage csv url
				SummaryData.getKmzToken(response.data.url, designID).then(response => {
					logger.info(`Response from getKmzToken.php: ${JSON.stringify(response.data)}`);
					if (response.data.result === "SUCCESS") {
						logger.info("Successfully got a token for the outage csv file");
						//downloading csv file
						let csvOutagelink = document.createElement('a');
						csvOutagelink.href = `https://iconnect.windstreamwholesale.com/kmz/SaveKmzFile.php?token=${response.data.token}`;
						csvOutagelink.download = response.data.filename;
						csvOutagelink.click();
						setLoader(false);
					} else {
						logger.info("Error getting token for outage csv file");
						setLoader(false);
					}
				}).catch(error => {
					logger.info(`getKmzToken ERROR: ${error}`);
					setLoader(false);
				})
			} else {
				setOutageCSVAvailable(false);
				setLoader(false);
			}
		}).catch(error => {
			logger.error(`fetchOutageCsv ERROR: ${error}`);
			setLoader(false);
		})
	}

	//Function to generate the outage excel link and then tokenize the file so we can access it from our fileshare
	const fetchOutageExcel = (designID, days) => {
		setLoader(true);
		OutageOverlayHelper.getOutageExcelLink(designID, days).then(response => {
			logger.info(`getOutageExcelLink response: ${response.data}`);
			if (response.data.status === "SUCCESS" && response.data.url !== "Not Available") {
				logger.info("Successfully got a excel link");
				//fetch token for the outage excel url
				SummaryData.getKmzToken(response.data.url, designID).then(response => {
					logger.info(`Response from getKmzToken.php: ${JSON.stringify(response.data)}`);
					if (response.data.result === "SUCCESS") {
						logger.info("Successfully got a token for the outage excel file");
						//downloading excel file
						let excelOutagelink = document.createElement('a');
						excelOutagelink.href = `https://iconnect.windstreamwholesale.com/kmz/SaveKmzFile.php?token=${response.data.token}`;
						excelOutagelink.download = response.data.filename;
						excelOutagelink.click();
						setLoader(false);
					} else {
						logger.info("Error getting token for outage excel file");
						setLoader(false);
					}
				}).catch(error => {
					logger.info(`getKmzToken ERROR: ${error}`);
					setLoader(false);
				})
			} else {
				setOutageExcelAvailable(false);
				setLoader(false);
			}
		}).catch(error => {
			logger.error(`fetchOutageExcel ERROR: ${error}`);
			setLoader(false);
		})
	}


	return (
		<>
			{loader ? <Loader /> : null}
			{props.kmzToken?.length > 0 || window.location.hostname === localURL || window.location.hostname === devURL || window.location.hostname === uatURL || user === "Wholesale_Demo" || Number(selectedInternalMpid) === 8683 ?
				<>

					<div className='d-flex mb-3 ml-3'>
						<>
							<div className="p-3">
								<input type="checkbox" value="outBtn" id="outBtn" name="outBtn" checked={outageOverlay ? "checked" : ""} onChange={(ev) => outageHandler(ev)}></input>
								<label htmlFor="outBtn" className={style.outageLabel}>Outage Overlay</label>
							</div>

							{outageOverlay ?
								<>
									<div className="p-2">
										<select className="nice-select dark-select" onChange={(ev) => outageDayHandler(ev)} value={outageDays}>
											<option value="1">Today</option>
											<option value="30">30 days</option>
											<option value="60">60 days</option>
											<option value="180">180 days</option>
											<option value="365">365 days</option>
										</select>
									</div>
									{window.location.hostname === localURL || window.location.hostname === devURL || window.location.hostname === uatURL || user === "Wholesale_Demo" || Number(selectedInternalMpid) === 8683 ?
										<div className="p-2">
											<button className={`ico-button light-button`} type="button">
												<a href={outageDays === "today" ? "../../../assets/files/outages/TodaysOutage.xls" : "../../../assets/files/outages/Outage365.xls"} download type="button">Export outage data Excel</a>
											</button>
										</div> : null
									}
									{window.location.hostname === localURL || window.location.hostname === devURL || window.location.hostname === uatURL || user === "Wholesale_Demo" || Number(selectedInternalMpid) === 8683 ?
										<div className="p-2">
											<button className={`ico-button light-button`} type="button">
												<a href={outageDays === "today" ? "../../../assets/files/outages/TodaysOutage.csv" : "../../../assets/files/outages/Outage365.csv"} download type="button">Export outage data csv</a>
											</button>
										</div> : null
									}
									{window.location.hostname === prodURL && user !== "Wholesale_Demo" && Number(selectedInternalMpid) !== 8683 ?
										<div className="p-2">
											{outageCSVAvailable ?
												<button className={`ico-button light-button`} type="button" onClick={() => fetchOutageCsv(designID, outageDays)}>
													Export outage data csv
												</button> : <p className='text-danger'>No outage data available to download</p>
											}
										</div> : null
									}
									{window.location.hostname === prodURL && user !== "Wholesale_Demo" && Number(selectedInternalMpid) !== 8683 ?
										<div className="p-2">
											{outageExcelAvailable ?
												<button className={`ico-button light-button`} type="button" onClick={() => fetchOutageExcel(designID, outageDays)}>
													Export outage data excel
												</button> : <p className='text-danger'>No outage data available to download</p>
											}
										</div> : null
									}
								</> : null
							}
						</>

						<div className="ml-auto p-2">
							<button className={`ico-button light-button`} type="button">
								<a href={outageOverlay ? outageDownloadLink : downloadLink} download type="button">Download KMZ</a>
							</button>
						</div>
					</div>
					{outageOverlay ? <p>* Please export file to confirm outages not able to be shown on the map</p> : null}
					<div className="map" id="map" style={{ height: '85vh', width: "100%" }} />
					<div className={`modal fade ${kmlModal ? 'show d-block' : ''}`} tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
						<div className={`modal-dialog modal-dialog-centered ${style.modalDialog}`} role="document">
							<div className={`modal-content ${style.modalContent}`}>
								<div className="modal-header">
									<button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => { closeKMLModal() }}>
										<span aria-hidden="true">&times;</span>
									</button>
								</div>
								<div id="capture" className={`modal-body ${style.modalBodyCapture}`} />
							</div>
						</div>
					</div>
					<div class="map">* Route displayed as designed. Map may not reflect redesigned paths or changes made post-order turn up.</div>
				</> : <div><p>Full Fiber Path not Available</p></div>
			}
		</>
	)

}

export default Fiberpath;
