import {ORDER_STEP_CODE, RESET_ACTION} from "../actions/types/actionTypes"

const initialState = {
    orderValues: {
        step: 1
    }
};

const orderReducer = (state = initialState, action) => {
    switch (action.type) {
        
        case ORDER_STEP_CODE:
            return {
                ...state,
                orderValues: {
                    ...state.orderValues,
                    step: action.payload
                }
            }

        case RESET_ACTION:
            return initialState;
        default:
            return state
    }
}

export default orderReducer;
