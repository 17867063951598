const DateUtils = (function () {

    const isDateAGreaterThanDateB = (dateA, dateB) => {
    };

    const sortArrByDateProperty = (arr, dateProperty, order) => {
        // eslint-disable-next-line array-callback-return
        return arr.slice().sort((obj1, obj2) => {
            if (!order || (order && order === 'asc')) {
                return new Date(obj1[dateProperty]) - new Date(obj2[dateProperty])
            } else if (order && order === 'desc') {
                return new Date(obj2[dateProperty]) - new Date(obj1[dateProperty])
            }
        })
    };

    const timeDiffInMinutes = (startTime, endTime) => {
        let diff = 0;
        let minutes;
        const sTime = new Date(startTime);
        const eTime = new Date(endTime);
        if (eTime > sTime) {
            diff = Math.abs(eTime - sTime);
            minutes = Math.floor((diff / 1000) / 60);
        } else {
            return;
        }
        return minutes;
    };

    return {
        isDateAGreaterThanDateB: isDateAGreaterThanDateB,
        timeDiffInMinutes: timeDiffInMinutes,
        sortArrByDateProperty: sortArrByDateProperty
    }
}());

export default DateUtils;