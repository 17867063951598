/**
 * Created by e0173131 on 1/20/2021.
 */
import React, {useState, Fragment} from 'react';

const GoodieWizard = () => {
    const [isCollapsed, setIsCollapsed] = useState(true);

    const handleClick = () => {
        setIsCollapsed(!isCollapsed);
    }
    return (
        <Fragment>
            <span className="cf"></span>
            <div className="sub-hdr alt2">
                <div className="expander" collapsed-id="milestone-content" onClick={handleClick}>
                    <svg className="ico-link light-ico ico-add">
                        <path
                            d="M24,48A24,24,0,1,1,48,24,24,24,0,0,1,24,48ZM24,1.89A22.1,22.1,0,1,0,46.08,24,22.09,22.09,0,0,0,24,1.89Zm1,32.69H23.05V25h-9.6V23.05h9.6v-9.6H25v9.6h9.61V25H25Z"></path>
                    </svg>
                </div>
                <div className="trim">&nbsp;</div>
                <div className="nib">
                    <div className="the-notch">
                        <div className="notch-fg">
                            <svg>
                                <path
                                    d="M35.81,26.28l-13,10v-5C-10.47,28.68-1,9.16,11.79,0c0,7.28,0,9.13,0,13.28s3.18,7.92,11,9v-5Z"></path>
                            </svg>
                        </div>
                    </div>
                </div>
                <h2>Wizard(s)</h2>
            </div>
            {!isCollapsed ?
                <div className="sub-section">
                    Provisioned for a 3 through 5 step wizard.<br /><br />
                <div className="milestones">
                    <div className="step active">
                        <div>1</div>
                        <span>Milestone 1</span>
                    </div>
                    <div className="gap">&nbsp;</div>
                    <div className="step">
                        <div>2</div>
                        <span>Milestone 2</span>
                    </div>
                    <div className="gap">&nbsp;</div>
                    <div className="step">
                        <div>3</div>
                        <span>Milestone 3</span>
                    </div>
                </div>
                <div className="milestones four-steps">
                    <div className="step active">
                        <div>1</div>
                        <span>Milestone 1</span>
                    </div>
                    <div className="gap">&nbsp;</div>
                    <div className="step">
                        <div>2</div>
                        <span>Milestone 2</span>
                    </div>
                    <div className="gap">&nbsp;</div>
                    <div className="step">
                        <div>3</div>
                        <span>Milestone 3</span>
                    </div>
                    <div className="gap">&nbsp;</div>
                    <div className="step">
                        <div>4</div>
                        <span>Milestone 4</span>
                    </div>
                </div>
                <div className="milestones five-steps">
                    <div className="step">
                        <div>1</div>
                        <span>Milestone 1</span>
                    </div>
                    <div className="gap">&nbsp;</div>
                    <div className="step">
                        <div>2</div>
                        <span>Milestone 2</span>
                    </div>
                    <div className="gap">&nbsp;</div>
                    <div className="step">
                        <div>3</div>
                        <span>Milestone 3</span>
                    </div>
                    <div className="gap">&nbsp;</div>
                    <div className="step">
                        <div>4</div>
                        <span>Milestone 4</span>
                    </div>
                    <div className="gap">&nbsp;</div>
                    <div className="step active">
                        <div>5</div>
                        <span>Milestone 5</span>
                    </div>
                </div>
            </div>
        : null}
        </Fragment>
    );
}

export default GoodieWizard;
