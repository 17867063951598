import axios from "./axios/customAxios";

const getPresalesPopList = async () => {
    const preSalesPopListURL = `${process.env.REACT_APP_API_URL}/ni/preSalesPopList.php`;
	return await axios.get(preSalesPopListURL);
}

const getPresalesData = async (locA, locZ, numberOfPaths, sortBy, avoidanceRoutes, inclusionRoutes) => {
    const preSalesDataURL = `${process.env.REACT_APP_API_URL}/ni/getRouteInformation.php?locA=${JSON.stringify(locA)}&locZ=${JSON.stringify(locZ)}&numberOfPaths=${numberOfPaths}&sortBy=${sortBy}&routeAvoidance=${JSON.stringify(avoidanceRoutes)}&routeInclusion=${JSON.stringify(inclusionRoutes)}`;
	return await axios.get(preSalesDataURL);
}

const getRouteCreatorPricingInfo = async (locA, locZ, numberOfPaths, sortBy, avoidanceRoutes, inclusionRoutes) => {
    const routeCreatorPricingURL = `${process.env.REACT_APP_API_URL}/ni/getRouteCreatorPricing.php?locA=${JSON.stringify(locA)}&locZ=${JSON.stringify(locZ)}&numberOfPaths=${numberOfPaths}&sortBy=${sortBy}&routeAvoidance=${JSON.stringify(avoidanceRoutes)}&routeInclusion=${JSON.stringify(inclusionRoutes)}`;
    return await axios.get(routeCreatorPricingURL);
}

const getAccountProfile = async (mpid) => {
    const accountProfileURL = `${process.env.REACT_APP_API_URL}/getAccountProfile.php?mpid=${mpid}`;
    return await axios.get(accountProfileURL);
}

const getAccountExecutiveName = async (eid) => {
    const accountExecutiveNameURL = `${process.env.REACT_APP_API_URL}/getEmployeeInfo.php?eid=${eid}&field=name`;
    return await axios.get(accountExecutiveNameURL);
}

const getAccountExecutiveEmail = async (eid) => {
    const accountExecutiveEmailURL = `${process.env.REACT_APP_API_URL}/getEmployeeInfo.php?eid=${eid}&field=email`;
    return await axios.get(accountExecutiveEmailURL);
}

const postRouteCreatorActivityLog = async (locationA, locationZ, paths, sortBy, diversity, includedRoutes, excludedRoutes, iconOnly, quoteLaunched, emailToSales) => {
    let routerCreatorLoggerURL = `${process.env.REACT_APP_API_URL}/ni/routeCreatorLogger.php`;
    let data = {
        "locationA": locationA,
        "locationZ": locationZ,
        "paths": paths,
        "sortBy": sortBy,
        "diversity": diversity,
        "includedRoutes": includedRoutes,
        "excludedRoutes": excludedRoutes,
        "iconOnly": iconOnly,
        "quoteLaunched": quoteLaunched,
        "emailToSales": emailToSales
    }
	return await axios.post(routerCreatorLoggerURL, JSON.stringify(data));
}

const RouteCreatorHelper = {
    getPresalesPopList,
    getPresalesData,
    getRouteCreatorPricingInfo,
    getAccountProfile,
    getAccountExecutiveName,
    getAccountExecutiveEmail,
    postRouteCreatorActivityLog
}

export default RouteCreatorHelper;
