import {HTTP_404_ERROR, HTTP_500_ERROR, HTTP_OTHER_ERROR, SESSION_ERROR_MESSAGE} from "./types/actionTypes"

export const execute404Handler = (history) => {
    return {
        type: HTTP_404_ERROR,
        history: history
    }
}
export const execute500Handler = (history) => {
    return {
        type: HTTP_500_ERROR,
        history: history
    }
}
export const executeOtherErrorHandler = (error) => {
    return {
        type: HTTP_OTHER_ERROR,
        error: error
    }
}
const HTTPErrorActions = (error, props) => {
    if (error.response.status === 404) {
        return execute404Handler(props);
    }
    else if (error.response.status === 500) {
        return execute500Handler(props);
    }
    else {
        return executeOtherErrorHandler(error);
    }
}

export default HTTPErrorActions;

export const setSessionError = (error) => {
	return {
		type: SESSION_ERROR_MESSAGE,
		payload: error
	}
}