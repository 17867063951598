import style from "./QuoteCreate.module.css";
import { useEffect, useState, useMemo } from "react";
import { connect, useSelector } from "react-redux";
import ColocationHelper from "../../services/ColocationService";
import PremAddress from "./PremAddress";
import {
	setQuoteBreakerAmp,
	setQuoteCrossConnect,
	setQuoteFusePanel,
	setQuotePopLocation,
	setQuotePrimaryPower,
	setQuoteQty,
	setQuoteRackSpace,
	setQuoteSecondaryPower
} from '../../redux/actions/quoteActions';
import Term from "./Term";
import Loader from '../shared/Loader';
import Select from 'react-select';
import { ConsoleLogger } from "../../logger/ConsoleLogger";
import HTTPErrorHandler from "../errorPages/HTTPErrorHandler";
import axios from "axios";

const Colocation = (props) => {
	const logger = useMemo(() => new ConsoleLogger({ level: process.env.REACT_APP_LOGGER_LEVEL, }),[]);
	const [hasError, setHasError] = useState(null);
	const [popLocationOptions, setPopLocationOptions] = useState([]);
	const [UnitiMlaMsg, setUnitiMlaMsg] = useState([]);
	const [rackSpaceOptions, setRackSpaceOptions] = useState([]);
	const [breakerAmpOptions, setBreakerAmpOptions] = useState([]);
	const [primaryPowerOptions, setPrimaryPowerOptions] = useState([]);
	const [loader, setLoader] = useState(false);

	const selectedColocationValues = useSelector(state => state.quote.quoteValues);

	useEffect(() => {
		if (props.stepCode === 2) {
			setLoader(true);
			ColocationHelper.getPopLocations().then(resp => {
				logger.info(resp.data);
				setPopLocationOptions(resp.data);
				setLoader(false);
			}).catch(error => {
				logger.error(`Error Colocation Pop Locations: ${error}`);
				setHasError(error);
				setLoader(false);
			})
		}
	}, [logger, props.stepCode]);

	useEffect(() => {
		if (Number.isInteger(selectedColocationValues.popLoc?.value) && selectedColocationValues.step === 2) {
			if (selectedColocationValues.popLoc.value !== "Other") {
				setLoader(true);
				/*ADO-593576 Function to check the Uniti MLA flag tag for the pop uid selected*/
				ColocationHelper.getUnitiMlaFlag(selectedColocationValues.popLoc.value).then(resp => {
					logger.info(resp.data);
					if (resp.data?.note) {
						setUnitiMlaMsg(resp.data.note);
					}
					setLoader(false);
				}).catch(error => {
					logger.error(`Error checking checkUnitiMlaFlag: ${error}`);
					setLoader(false);
				})
			}
		}
	}, [logger, selectedColocationValues.popLoc, selectedColocationValues.step])

	useEffect(() => {
		if(props.stepCode === 3 && props.section === "productConfig") {
			setLoader(true);
			ColocationHelper.getColoMenus().then(axios.spread(({data: rackSpace}, {data: breakerAmps}, {data: primaryPower}) => {
				setRackSpaceOptions(rackSpace);
				setBreakerAmpOptions(breakerAmps);
				setPrimaryPowerOptions(primaryPower);
				setLoader(false);
			})).catch(error => {
				logger.info(`getColoMenus Error ${error}`);
				setLoader(false);
			})
		}
	},[logger, props.section, props.stepCode])

	const popLocHandler = (event) => {
		setUnitiMlaMsg('');
		let popVal = '';
		if (!event) {
			popVal = ''
		} else {
			popVal = event;
		}
		props.updateColocationLocation(popVal);
	}

	const inputChange = (event) => {
		switch (event.target.id) {
			case "qty":
				props.updateColocationQty(event.target.value);
				break;
			case "fiberXconn":
				props.updateColocationCrossConnect(event.target.value);
				break;
			case "rack_space":
				props.updateColocationRackSpace(event.target.value);
				break;
			case "breaker_amp":
				props.updateColocationBreakerAmp(event.target.value);
				break;
			case "primary_power":
				props.updateColocationPrimaryPower(event.target.value);
				//check secondary power if 48V is selected and disable it
				if (event.target.value === "3") {
					props.updateColocationSecondaryPower(true);
					document.getElementById("secondary_power").disabled = true;
				} else {
					document.getElementById("secondary_power").disabled = false;
				}
				break;
			case "secondary_power":
				props.updateColocationSecondaryPower(event.target.checked);
				break;
			case "fuse_panel":
				props.updateColocationFusePanel(event.target.value);
				break;
			default:
				break;
		}
	}

	const customFilter = (option, searchText) => {
		if (option.value.toString().toLowerCase().includes("other") ||
			option.label.toString().toLowerCase().includes(searchText.toString().toLowerCase()) ||
			option.value.toString().toLowerCase().includes(searchText.toString().toLowerCase())
		) {
			return true;
		} else {
			return false;
		}
	}

	return (
		<>
			{hasError && <HTTPErrorHandler error={hasError}></HTTPErrorHandler>}
			{loader ? <Loader /> : null}
			{!hasError && (<>{props.stepCode === 2 ?
				<>
					<div className={`row ${style.rowGrp}`}>
						<div className="col-6">
							<label className={style.labelSelectRequiredFld}>POP Location<span
								className={style.mandatory}>*</span></label>

							<Select
								value={{ value: selectedColocationValues.popLoc.value, label: selectedColocationValues.popLoc.label }}
								onChange={popLocHandler}
								options={popLocationOptions.map((item) => {
									return { value: item.id, label: item.name }
								})}
								filterOption={customFilter}
								isSearchable
								isClearable
								noOptionsMessage={() => 'Loading Pop Locations'}
							/>
						</div>
						<span className={`text-center my-4 ${style.labelSelectRequiredFld}`}>{UnitiMlaMsg}</span>
					</div>
					{/*if other is selected than take the user input*/}
					{selectedColocationValues.popLoc.value === "Other" ?
						<PremAddress></PremAddress> : null} </>
				: null}
				{(props.stepCode === 3) && (props.section === "term") ?
					<Term />
					: null}
				{(props.stepCode === 3) && (props.section === "productConfig") ?
					<>
						<div className="col-12 col-sm-4 col-md-3 mb-5">
							<label className={style.labelSelectRequiredFld}>Quantity <span className={style.mandatory}>*</span></label>
							<select className={` form-control  ${style.selectFld} ${style.requiredFld} `} id='qty' onChange={inputChange}>
								<option value="1">1</option>
								<option value="2">2</option>
								<option value="3">3</option>
							</select>
						</div>
						<div className="col-12 col-sm-4 col-md-3 mb-5">
							<label className={style.labelSelectRequiredFld}>Fiber Cross Connect<span className={style.mandatory}>*</span></label>
							<select className={` form-control  ${style.selectFld} ${style.requiredFld} `} id="fiberXconn" onChange={inputChange} value={selectedColocationValues.cross_connect}>
								<option value="0">No</option>
								<option value="1">Cross Connect</option>
							</select>
						</div>

						{selectedColocationValues.cross_connect === "0" ?
							<>
								<div className="col-5 col-sm-4 col-md-3 rel mb-5">
									<label className={style.labelSelectRequiredFld}> Rack Space<span className={style.mandatory}>*</span></label>
									<select defaultValue={'-'} className={` form-control  ${style.selectFld} ${style.requiredFld} `} id="rack_space" onChange={inputChange} value={selectedColocationValues.rack_space}>
										{rackSpaceOptions.map((option) => {
											return <option value={option.id}>{option.label}</option>
										})}
									</select>
								</div>
								<div className="col-5 col-sm-4 col-md-3 rel mb-5">
									<label className={style.labelSelectRequiredFld}> Breaker Amperage<span className={style.mandatory}>*</span></label>
									<select className={` form-control  ${style.selectFld} ${style.requiredFld} `} id="breaker_amp" onChange={inputChange} value={selectedColocationValues.breaker_amp} >
										{breakerAmpOptions.map((option) => {
											return <option value={option.id}>{option.label}</option>
										})}
									</select>
								</div>
								<div className="col-5 col-sm-4 col-md-3 rel mb-5">
									<label className={style.labelSelectRequiredFld}> Primary Power</label>
									<select defaultValue={'-'} className={` form-control  ${style.selectFld} ${style.requiredFld} `} id="primary_power" onChange={inputChange} value={selectedColocationValues.primary_power} >
										{primaryPowerOptions.map((option) => {
											return <option value={option.id}>{option.label}</option>
										})}
									</select>
								</div>
								<div className="col-5 col-sm-4 col-md-3 rel mb-5">
									<input type="checkbox" id="secondary_power" name="secondary_power" onChange={inputChange} checked={selectedColocationValues.secondary_power ? "checked" : ""} />
									<label htmlFor="secondary_power" className={` ${style.checkboxText} `}>SECONDARY POWER</label>
								</div>
								{/*Fuse Panel is Required for 48V DC*/}
								{selectedColocationValues.primary_power === "3" ?
									<div className="col-5 col-sm-4 col-md-3 rel mb-5">
										<label className={style.labelSelectRequiredFld}> Fuse Panel</label>
										<select defaultValue={'-'} className={` form-control  ${style.selectFld} ${style.requiredFld} `} id="fuse_panel" onChange={inputChange} value={selectedColocationValues.fuse_panel} >
											<option value="" disabled>-</option>
											<option value="0">NO</option>
											<option value="1">YES</option>
										</select>
									</div> : null
								}
							</> : null}

					</> : null}</>)}
		</>
	)
}

const mapDispatchToProps = {
	updateColocationLocation: setQuotePopLocation,
	updateColocationQty: setQuoteQty,
	updateColocationCrossConnect: setQuoteCrossConnect,
	updateColocationRackSpace: setQuoteRackSpace,
	updateColocationBreakerAmp: setQuoteBreakerAmp,
	updateColocationPrimaryPower: setQuotePrimaryPower,
	updateColocationSecondaryPower: setQuoteSecondaryPower,
	updateColocationFusePanel: setQuoteFusePanel
}

export default connect(null, mapDispatchToProps)(Colocation);
