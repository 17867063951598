import { Text, View, } from '@react-pdf/renderer';
import { styles } from './pdfStyles';

const ColoDetails = (props) => {
    return (
        <>
            <View wrap={false}>
                <View style={styles.headerBarTopMargin}>
                    <Text style={styles.headerBarText}>Colocation Detail</Text>
                </View>
                {props?.rack_space?.length > 0 ?
                    <View style={styles.tableRow}>
                        <Text style={[styles.headerColumn, styles.tableCell2Width, styles.textBold]}>Rack Space</Text>
                        <Text style={[styles.tableColumn, styles.tableCell2Width, styles.textNormal]}>{props?.rack_space}</Text>
                    </View>
                    : null}
                {props?.primary_power?.length > 0 ?
                    <View style={styles.tableRow}>
                        <Text style={[styles.headerColumn, styles.tableCell2Width, styles.textBold]}>Primary Power:</Text>
                        <Text style={[styles.tableColumn, styles.tableCell2Width, styles.textNormal]}>{props?.primary_power}</Text>
                    </View>
                    : null}
                {props?.breaker_amp?.length > 0 ?
                    <View style={styles.tableRow}>
                        <Text style={[styles.headerColumn, styles.tableCell2Width, styles.textBold]}>Breaker Amperage:</Text>
                        <Text style={[styles.tableColumn, styles.tableCell2Width, styles.textNormal]}>{props?.breaker_amp}</Text>
                    </View>
                    : null}
                {props?.second_power?.length > 0 ?
                    <View style={styles.tableRow}>
                        <Text style={[styles.headerColumn, styles.tableCell2Width, styles.textBold]}>Secondary Power:</Text>
                        <Text style={[styles.tableColumn, styles.tableCell2Width, styles.textNormal]}>{props?.second_power}</Text>
                    </View>
                    : null
                }
                {props?.ila?.length > 0 ?
                    <View style={styles.tableRow}>
                        <Text style={[styles.headerColumn, styles.tableCell2Width, styles.textBold]}>ILA:</Text>
                        <Text style={[styles.tableColumn, styles.tableCell2Width, styles.textNormal]}>{props?.ila}</Text>
                    </View>
                    : null}
                {props?.cam_qty?.length > 0 ?
                    <View style={styles.tableRow}>
                        <Text style={[styles.headerColumn, styles.tableCell2Width, styles.textBold]}>Fuse Panel:</Text>
                        <Text style={[styles.tableColumn, styles.tableCell2Width, styles.textNormal]}>{props?.fuse}</Text>
                    </View>
                    : null}
            </View>
        </>
    )
}

export default ColoDetails;