import style from "./TicketDetails.module.css";
import { useEffect, useState, useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import ViewportWarning from "../shared/ViewportWarning";
import Loader from "../shared/Loader";
import TroubleTicketDetails from "../../services/TroubleDetailsService";
import TicketDetailsNotes from "./TicketDetailsNotes";
import { ConsoleLogger } from "../../logger/ConsoleLogger";
import HTTPErrorHandler from "../errorPages/HTTPErrorHandler";
import CompanyDropdown from "../shared/CompanyDropdown";

const escalation_options = [{
	name: 'Select One',
	value: '',
}, {
	name: 'Status (lack of)',
	value: 'Status (lack of)',
}, {
	name: 'Outage - Status (lack of)',
	value: 'Outage - Status (lack of)',
}, {
	name: 'Delayed Resolution / Ticket Duration',
	value: 'Delayed Resolution / Ticket Duration',
}, {
	name: 'Outage - Delayed Resolution',
	value: 'Outage - Delayed Resolution',
}, {
	name: 'Dispatch Delayed / Missed',
	value: 'Dispatch Delayed / Missed',
}];


const TicketDetails = () => {
	const logger = useMemo(() => new ConsoleLogger({ level: process.env.REACT_APP_LOGGER_LEVEL, }), []);
	const [hasError, setHasError] = useState(null);
	const { incidentNumber } = useParams();
	const [activeTab, setActiveTab] = useState("1");
	const [isDisplayAllNotes, setIsDisplayAllNotes] = useState(false);
	const [ticketDetails, setTicketDetails] = useState('');
	const [escalationReason, setEscalationReason] = useState('');
	const [escalationComments, setEscalationComments] = useState('');
	const [escalationFormErrorObj, setEscalationFormErrorObj] = useState({});
	const [escalateLevelTo, setEscalatedLevelTo] = useState('');
	const [escalationLevel, setEscalationLevel] = useState('');
	const [escalationFlag, setEscalationFlag] = useState(false);
	const [escalationMessage, setEscalationMessage] = useState('');
	const [note, setNote] = useState('');
	const [notes, setNotesData] = useState([]);
	const [resolved, setResolved] = useState('');
	const [loader, setLoader] = useState(false);
	const [escalationLoadingFlag, setEscalationLoadingFlag] = useState(false);
	const [escalationSuccessFlag, setEscalationSuccessFlag] = useState(false);
	const [creatingNotesFlag, setCreatingNotesFlag] = useState(false);
	const [notesCreatedFlag, setNotesCreatedFlag] = useState(false);
	const [kineticID, setKineticID] = useState("");
	const isEmployee = localStorage.getItem("isEmployeeLoggedIn");

	var regex = /INC/;

	//Pulls all the information for the Note Section
	const getTicketNotes = useCallback((kineticID = "") => {
		setLoader(true);
		TroubleTicketDetails.getNotes(incidentNumber, kineticID).then(response => {
			logger.info(encodeURIComponent(response.data).replace(/%20/g, " "));
			setNotesData(response.data);
			setLoader(false);
		}).catch(error => {
			setHasError(error);
			logger.error("TroubleTicketDetails.getNotes Error: " + error)
		})
	},[incidentNumber, logger])

	//Pulls all the information for the Support Request
	const getTroubleTicketDetails = useCallback(() => {
		setLoader(true);
		TroubleTicketDetails.getDetailsData(incidentNumber).then(response => {
			logger.info(`Ticket Detail: ${JSON.stringify(response.data)}`);
			setTicketDetails(response.data);
			//We need the kinetic id to escalate, create worklogs or get a status update for KSF TICKETS
			if(response.data?.kineticID){
				logger.info(`KSF ticket with the follow kineticID: ${response.data.kineticID}`);
				setKineticID(response.data.kineticID);
				getTicketNotes(response.data.kineticID);
			} else {
				getTicketNotes();
			}
			if (response.data.escalationLevel === '') {
				setEscalationLevel('0');
				setLoader(false);
			} else {
				setEscalationLevel(response.data.escalationLevel);
				setLoader(false);
			}
		}).catch(error => {
			setHasError(error);
			setLoader(false);
			//logger.error("TroubleTicketDetails.getDetailsData Error: " + error)
		})
	}, [getTicketNotes, incidentNumber, logger])

	//Calls all the functions needed to populate the ticket detail fields
	useEffect(() => {
		getTroubleTicketDetails();
	},  [getTroubleTicketDetails]);

	useEffect(() => {
		//clearing the error message if the escalation reason or escalation comments are changed
		setEscalationFormErrorObj({});
	}, [escalationReason, escalationComments])

	//Determines whether the ticket escalation form is displayed or the error message
	const escalationCheck = () => {
		setLoader(true);
		TroubleTicketDetails.escalationValidation(incidentNumber).then(response => {
			logger.info("============Validing that ticket can be Escalated===============");
			logger.info(encodeURIComponent(response.data.escalationFlag).replace(/%20/g, " "));
			setEscalationFlag(response.data.escalationFlag);
			setEscalationMessage(response.data.displayNotice);
			setEscalatedLevelTo(response.data.escalate_level)
			setActiveTab('4');
			setLoader(false);
		}).catch(error => {
			setHasError(error);
			logger.error("TroubleTicketDetails.escalationValidation Error: " + error)
		})

	}

	//Performs the actually escalation of the ticket
	const escalateTicket = () => {
		//Validation for Escalation Form
		let error = false;
		if (escalationReason === "") { setEscalationFormErrorObj(prevEscalationError => ({ ...prevEscalationError, reason: "Escalation Reason is Required" })); error = true; }
		if (escalationComments === "") { setEscalationFormErrorObj(prevEscalationError => ({ ...prevEscalationError, comments: "Escalation Comments are Required" })); error = true; }
		if (error) return;
		closeModal();
		logger.info("=====Ready to Escalate Ticket=====");
		let escalationData = {
			"incidentId": incidentNumber,
			"employeeId": 'CPTPortal',
			"escalatedTo": 'Level ' + escalateLevelTo,
			"escalationLevel": escalateLevelTo,
			"action": 'ESCALATE',
			"comments": escalationComments,
			"escalationReason": escalationReason,
			"kineticID": kineticID //Adding kinetic id to the payload the php will determine if it should call escalateTicket or the escalateKineticTicket function from the Remedy Class
		}
		logger.info(escalationData);
		setLoader(true)
		setEscalationLoadingFlag(true);
		logger.info("===checking escalation if it is loading or not")
		TroubleTicketDetails.escalateTroubleTicket(escalationData, kineticID).then(response => {
			logger.info(encodeURIComponent(response.data).replace(/%20/g, " "));
			setEscalationLoadingFlag(false);
			setEscalationSuccessFlag(true);
			setLoader(false);
			window.location.reload(false);
		}).catch(error => {
			setHasError(error);
			logger.error("TroubleTicketDetails.escalateTroubleTicket Error: " + error);
		})

	}

	//Adds note to the existing ticket
	const addNewNote = (note, resolved) => {
		closeModal();
		logger.info("======NEW NOTE=====");
		logger.info(encodeURIComponent(note).replace(/%20/g, " "));
		logger.info("======Is Resovled?=========");
		logger.info(resolved);
		let noteData = {
			"incidentId": incidentNumber,
			"resolved": resolved,
			"addNotes": note,
			"kineticID": kineticID
		};
		setLoader(true);
		setCreatingNotesFlag(true);
		TroubleTicketDetails.addNote(noteData, kineticID).then(response => {
			logger.info(encodeURIComponent(response.data).replace(/%20/g, " "));
			setCreatingNotesFlag(false);
			setNotesCreatedFlag(true);
			setLoader(false);
			window.location.reload(false);
		}).catch(error => {
			setHasError(error);
			logger.error("TroubleTicketDetails.addNote Error: " + error)
		})
	}

	//Perform the status update
	const statusUpdate = () => {
		closeModal();
		let statusUpdateData = {
			"incidentId": incidentNumber,
			"worklog_type": "Customer Status Update",
			"addNotes": "Customer has requested status on this trouble ticket",
			"summary": "Status Request",
			"kineticID": kineticID
		}
		TroubleTicketDetails.statusUpdateReq(statusUpdateData, kineticID).then(response => {
			logger.info(encodeURIComponent(response.data).replace(/%20/g, " "));
		}).catch(error => {
			setHasError(error);
			logger.error("TroubleTicketDetails.statusUpdateReq Error: " + error)
		})
	}



	const DisplayEscalationError = () => {
		return (
			<div>
				<div className={`${style.overly}`}></div>
				<div className="modal fade show d-block">
					<div className="modal-dialog modal-dialog-centered">
						<div className="modal-content">
							<div className="modal-body text-center">
								<h3 className="text-center mb-3">Escalate Ticket</h3>
								<p className="m-0 text-center">
									The current Escalation Level is {escalationLevel} out of 6 Escalation Levels
								</p>
								<p className="text-danger text-left">
									{escalationMessage}
								</p>
							</div>
							<div className="modal-footer justify-content-center">
								<button type="button" className="btn-style btn-cancel text-uppercase"
									data-dismiss="modal" onClick={closeModal}>cancel
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		);

	}


	const tabChange = (id) => {
		setActiveTab(id);
		setIsDisplayAllNotes(id === "1" ? !isDisplayAllNotes : false);
	}

	/*
	const addNewContact = () => {
		setIsAddToContactClicked(true);
	}
	*/
	const closeModal = () => {
		setActiveTab('1');
	}

	/*
	const closeModalAddToCaontact = () => {
		setIsAddToContactClicked(false);
	}
	*/
	return (
		<>
			{loader ? <Loader /> : null}
			{hasError && <HTTPErrorHandler error={hasError}></HTTPErrorHandler>}
			<div className="midsection qm-upload abs z1">
				<div className="rel">
					<ViewportWarning />
					<div className="wrap">
						<h1 className="page-title">{isEmployee ? "Employee Ticket Details" : "Ticket Details"}</h1>
						{isEmployee ?
							<CompanyDropdown area={"Ticket Details"} mpid={ticketDetails?.camp?.mpid} company={ticketDetails?.camp?.company} /> : null
						}
						{!hasError && (<div className="section">
							{/* <TabsQuote /> */}
							<div className="content">
								{/*ADO-842853 INC tickets can no longer be updated since Remedy is going away*/}
								{incidentNumber.match(regex) ?
									<div class="alert alert-danger" role="alert">
										We would like to inform you that Trouble Ticket ID: {incidentNumber} has now been archived in our system. This means that the ticket has been successfully closed and can no longer be updated or modified.
									</div> : null
								}
								<div className={`sub-hdr ${style.subHdrAlter} `}>
									<div className="nib">
										<div className="the-notch">
											<div className="notch-fg">
												<svg>
													<path
														d="M35.81,26.28l-13,10v-5C-10.47,28.68-1,9.16,11.79,0c0,7.28,0,9.13,0,13.28s3.18,7.92,11,9v-5Z"></path>
												</svg>
											</div>
										</div>
									</div>
									<h2 className={` ${style.sectionTitle} m-0 `}>Support Request</h2>
								</div>
								<div className="sub-section">
									<div className={`row ${style.rowGrp}`}>
										<div className='col-md-6'>
											<ul className={` ${style.productList}`}>
												<li>Ticket</li>
												<li>{incidentNumber}</li>
											</ul>
										</div>
									</div>
									<div className={`row ${style.rowGrp}`}>
										<div className='col-md-6'>
											<ul className={` ${style.productList}`}>
												<li>Open Date/Time</li>
												<li>{ticketDetails.openDate}</li>
											</ul>
										</div>
										<div className='col-md-6'>
											<ul className={` ${style.productList}`}>
												<li>Close Date Time</li>
												<li>{ticketDetails.closeDate}</li>
											</ul>
										</div>
									</div>
									<div className={`row ${style.rowGrp}`}>
										<div className='col-md-6'>
											<ul className={` ${style.productList}`}>
												<li>Customer Ticket Number</li>
												<li>{ticketDetails.custTicket}</li>
											</ul>
										</div>
										<div className='col-md-6'>
											<ul className={` ${style.productList}`}>
												<li>Ticket Status</li>
												<li>{ticketDetails.status}</li>
											</ul>
										</div>
									</div>
									<div className={`row ${style.rowGrp}`}>
										<div className='col-md-6'>
											<ul className={` ${style.productList}`}>
												<li>Service Item</li>
												<li>{ticketDetails.tier1}</li>
											</ul>
										</div>
										<div className='col-md-6'>
											<ul className={` ${style.productList}`}>
												<li>Ticket Type</li>
												<li>{ticketDetails.tier3}</li>
											</ul>
										</div>
									</div>
									<div className={`row ${style.rowGrp}`}>
										<div className='col-md-12'>
											<ul className={` ${style.summaryList}`}>
												<li>Summary</li>
												<li className={style.breakLine}>{ticketDetails.summary}</li>
											</ul>
										</div>
									</div>
									<div className={`row ${style.rowGrp}`}>
										<div className='col-md-12'>
											<ul className={` ${style.summaryList}`}>
												<li>Description</li>
												<li className={style.breakLine}>{ticketDetails.notes}</li>
											</ul>
										</div>
									</div>
									{escalationLoadingFlag ?
										<div className="my-4 text-center">
											<h1 className="text-success">Escalating Trouble Ticket....</h1>
										</div> : null}
									{escalationSuccessFlag ?
										<div className="my-4 text-center">
											<h1 className="text-success">Trouble Ticket Successfully Escalated</h1>
										</div> : null
									}
									{creatingNotesFlag ?
										<div className="my-4 text-center">
											<h1 className="text-success">Adding New Note...</h1>
										</div> : null}
									{notesCreatedFlag ?
										<div className="my-4 text-center">
											<h1 className="text-success">Note Successfully Added</h1>
										</div> : null
									}
								</div>
								<div className="sub-hdr alt2">
									<div className="nib">
										<div className="the-notch">
											<div className="notch-fg">
												<svg>
													<path
														d="M35.81,26.28l-13,10v-5C-10.47,28.68-1,9.16,11.79,0c0,7.28,0,9.13,0,13.28s3.18,7.92,11,9v-5Z"></path>
												</svg>
											</div>
										</div>
									</div>
									<h2 className={` ${style.sectionTitle} m-0 `}>Notes</h2>
								</div>
								<div className={` ${style.stepThreeHeader} mt-2 mb-2`}>
									<div className="col-3">
										<button className={style.ticketActionBtn} onClick={() => tabChange('1')}>{isDisplayAllNotes ? 'Hide all notes' : 'Display all notes'}</button>
									</div>
									{/*ADO-842853 INC tickets can no longer be updated since Remedy is going away*/}
									{incidentNumber.match(regex) ?
										null :
										<>
											<div className="col-3">
												<button className={style.ticketActionBtn} onClick={() => tabChange('2')}>Add Note</button>
											</div>
											<div className="col-3">
												<button className={style.ticketActionBtn} onClick={() => tabChange('3')}>Status Update</button>
											</div>
											<div className="col-3">
												<button className={style.ticketActionBtn} onClick={escalationCheck}>Escalate</button>
											</div>
										</>
									}
								</div>
								{isDisplayAllNotes ? <>
									<TicketDetailsNotes allNotes={notes} />
								</> : null}
							</div>
						</div>)
						}
					</div>
				</div>
			</div>
			{!hasError && (activeTab === '2' ?
				<div>
					<div className={`${style.overly}`}></div>
					<div className="modal fade show d-block">
						<div className="modal-dialog modal-dialog-centered">
							<div className="modal-content">
								<div className="modal-body">
									<h3 className={` mb-3 text-center ${style.lableText} `}>Additional Notes</h3>
									<p className="m-0">
										Please include comments to the ticket and then click 'Save'.
									</p>
									<p>
										Once saved, the comments will be added to the request activity.
									</p>
									<p className="m-0">
										If you belive your support request is no longer needed simply check.
									</p>
									<p className="m-0">
										'Mark as Resolved', then click save.
									</p>
									<textarea className="w-100 mt-3" onChange={(e) => setNote(e.target.value)}>
									</textarea>
									<div className="form-check">
										<input type="checkbox" className="form-check-input" id="markAsResolved"
											onChange={(e) => setResolved(e.target.checked)} />
										<div className="col-sm">
											<label className="form-check-label" htmlFor="markAsResolved">
												Mark as Resolved
											</label>
										</div>
									</div>
								</div>
								<div className="modal-footer text-right">
									<button type="button" className="btn-style btn-cancel text-uppercase"
										data-dismiss="modal" onClick={closeModal}>cancel
									</button>
									<button type="button" className="btn-style btn-theme text-uppercase"
										onClick={() => addNewNote(note, resolved)}>Save
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
				: null)}

			{!hasError && (activeTab === '3' ?
				<div>
					<div className={`${style.overly}`}></div>
					<div className="modal fade show d-block">
						<div className="modal-dialog modal-dialog-centered">
							<div className="modal-content">
								<div className="modal-body text-center">
									You have requested a status update. Please confirm this is the action you intended
								</div>
								<div className="modal-footer justify-content-center">
									<button type="button" className="btn-style btn-cancel" data-dismiss="modal"
										onClick={closeModal}>Close
									</button>
									<button type="button" className="btn-style btn-theme text-uppercase"
										onClick={statusUpdate}>Ok
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
				: null)}

			{!hasError && (activeTab === '4' ?
				<>
					{escalationFlag ?
						<div>
							<div className={`${style.overly}`}></div>
							<div className="modal fade show d-block">
								<div className="modal-dialog modal-dialog-centered modal-lg">
									<div className="modal-content">
										<div className="modal-body text-center">
											<h3 className="text-center mb-3">Escalate Ticket</h3>
											<h6>The current Escalation Level is {escalationLevel} out of 6 Escalation Levels</h6>
											<div className="row">
												<div className="col-12 col-sm-6 col-md-4">
													<label className="m-0">Escalation Reason*:</label>
												</div>
												<div className="col-12 col-sm-6 col-md-6">
													<select className="nice-select" onChange={(event) => setEscalationReason(event.target.value)} value={escalationReason}>
														{escalation_options.map(item => (
															<option value={item.value}>
																{item.name}
															</option>
														))}
													</select>
													{escalationFormErrorObj?.reason ?
														<div className="invalid-feedback d-block mt-0">
															{escalationFormErrorObj?.reason}
														</div> : null
													}
												</div>
											</div>
											<div className="row">
												<div className="col-12 col-sm-6 col-md-4">
													<label className="m-0">Comments*:</label>
												</div>
												<div className="col-12 col-sm-6 col-md-6">
													<textarea id="comments" style={{ width: "100%", marginTop: "10px" }} onChange={(event) => setEscalationComments(event.target.value)}></textarea>
													{escalationFormErrorObj?.comments ?
														<div className="invalid-feedback d-block mt-0">
															{escalationFormErrorObj?.comments}
														</div> : null
													}
												</div>
											</div>
										</div>
										<div className="modal-footer justify-content-center">
											<button type="button" className="btn-style btn-cancel text-uppercase" data-dismiss="modal" onClick={closeModal}>
												Cancel
											</button>
											<button type="submit" className="btn-style btn-theme text-uppercase" onClick={escalateTicket}>
												Save
											</button>
										</div>

									</div>
								</div>
							</div>
						</div>
						: <DisplayEscalationError />}
				</>
				: null)}

			{!hasError && (escalationLoadingFlag ?

				<div className="my-4 text-center">
					<h1 className="text-success">Escalating Trouble Ticket</h1>
				</div> : null)}


			{!hasError && (escalationSuccessFlag ?
				<div className="my-4 text-center">
					<h1 className="text-success">Trouble Ticket Successfully Escalated</h1>
				</div> : null)
			}
		</>
	);
}

export default TicketDetails;
