import React from 'react'
import {Link} from 'react-router-dom';

const NoInternetError = () => {
    return (
        <div className="error-wrap text-center text-danger my-4">
            <h1>Please check your internet connection and try again!</h1>
            <Link to="/">
                Go Home
            </Link>
        </div>
    )
}

export default NoInternetError
