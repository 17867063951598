import {
    MY_NETWORK_LIST_MPID,
    NETWORK_LIST,
    TROUBLE_TICKET_MPID,
    TROUBLE_TICKET_DAY_FILTER,
    TROUBLE_TICKETS,
    CIRCUIT_HEALTH_MPID,
    CIRCUIT_HEALTH
} from "./types/actionTypes";


export const setMyNetworkListMpid = (mpid) => {
    return {
        type: MY_NETWORK_LIST_MPID,
        payload: mpid
    }
}
export const setMyNetworkList = (data) => {
    return {
        type: NETWORK_LIST,
        payload: data
    }
}

export const setTroubleTicketMpid = (mpid) => {
    return {
        type: TROUBLE_TICKET_MPID,
        payload: mpid
    }
}

export const setTroubleTickets = (tickets) => {
    return {
        type: TROUBLE_TICKETS,
        payload: tickets
    }
}

export const setTroubleTicketDayFilter = (day) => {
    return {
        type: TROUBLE_TICKET_DAY_FILTER,
        payload: day
    }
}

export const setCircuitHealthMpid = (mpid) => {
    return {
        type: CIRCUIT_HEALTH_MPID,
        payload: mpid
    }
}

export const setCircuitHealth = (data) => {
    return {
        type: CIRCUIT_HEALTH,
        payload: data
    }
}
