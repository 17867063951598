import {useEffect, useState} from "react";
import {Redirect, useParams} from "react-router-dom";
import {ConsoleLogger} from "../../logger/ConsoleLogger";
import UserManagementService from "../../services/UserManagementService";
import {setUser, clearReduxStore} from '../../redux/actions/userActions';
import {connect} from "react-redux";
import * as PendoService from "../../services/PendoService";
import {PendoUser} from "../../models/PendoUser";

const LoginResult = (props) => {
	const {authId, userId} = useParams();
	const [dynatraceUser, setDynatraceUser] = useState("");
	const logger = new ConsoleLogger({level: process.env.REACT_APP_LOGGER_LEVEL,});
	const loginAuthenticationURL = `${process.env.REACT_APP_LOGIN_URL}/login.php`;
	const today = new Date();
	const todayFormatted = `${today.getFullYear()}/${today.getMonth() + 1}/${today.getDate()}:${today.getHours()}:${today.getMinutes()}:${today.getSeconds()}`;
	const historyPath = localStorage.getItem('currentPath');
	logger.info(`History Path is set to: ${historyPath}`);

	function updatePendo(resp) {
		const pendoUser = new PendoUser(resp.data);
		PendoService.initializePendo(pendoUser, "Customer");
	}

	useEffect(() => {
		logger.info("useEffect--LoginResult");
		if (authId === "SUCCESS") {
			logger.info("User ID: " + userId);
			logger.info("Customer AUTH SUCCESS!");
			logger.info("AUTHORIZATION: " + authId);
			logger.info("USER_ID: " + userId);
			// Task #736801 -- Remove Employee Data
			localStorage.removeItem("isEmployeeLoggedIn");
			localStorage.removeItem("employeeLoggedInTime");
			localStorage.removeItem("eid");
			localStorage.removeItem("employeeName");
			// Add Customer Data
			localStorage.setItem("isLoggedIn", "true");
			logger.info("Today's date: " + todayFormatted)
			localStorage.setItem("loggedInTime", todayFormatted);
			localStorage.setItem("user", userId);
			localStorage.removeItem("loginFailed");
			localStorage.removeItem("loginAttempt");
			//Clearing Redux Store
			props.clearStore();

			// User Management
			UserManagementService.getUserAccessInfo().then(resp => {
				logger.info("Adding user info to store: " + JSON.stringify(resp.data));
				props.updateUser(resp.data);
				updatePendo(resp);
				setDynatraceUser(resp.data.user);
			}).catch(error => {
				logger.error("Error Adding user to store: " + error);
			});

		} else if (authId === "FAIL" && userId.toString().includes("NO TOKEN FOUND")) {
			logger.info("No Token Found!");
			localStorage.removeItem("isLoggedIn");
			localStorage.removeItem("loggedInTime");
			localStorage.removeItem("user");
			localStorage.setItem("loginFailed",1);
			localStorage.removeItem("loginAttempt");
			//Clearing Redux Store
			props.clearStore();
			//OAuth
			window.location.href = loginAuthenticationURL;
		} else if (authId === "FAIL") {
			logger.info("AUTH FAIL!");
			logger.error(`Error: ${userId}`);
			localStorage.removeItem("isLoggedIn");
			localStorage.removeItem("loggedInTime");
			localStorage.removeItem("user");
			localStorage.setItem("loginFailed",1);
			localStorage.removeItem("loginAttempt");
			//Clearing Redux Store
			props.clearStore();
		} else {
			logger.error("AUTH FAILED!");
			logger.error(`Error code: ${authId}`);
			localStorage.removeItem("isLoggedIn");
			localStorage.removeItem("loggedInTime");
			localStorage.removeItem("user");
			localStorage.setItem("loginFailed",1);
			localStorage.removeItem("loginAttempt");
			//Clearing Redux Store
			props.clearStore();
		}
	});

	return (
		<>
			{historyPath ? <Redirect to={{pathname: historyPath}}/> : <Redirect to={{pathname: "/"}}/>}
		</>
	)

}

const mapDispatchToProps = {
	updateUser: setUser,
	clearStore : clearReduxStore
}

export default connect(null, mapDispatchToProps)(LoginResult);
