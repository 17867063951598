/**************************************************************************
Notes Component
props:  refId and area
Purpose: Inserting or viewing notes on the Quote View or Order View page
**************************************************************************/
import { useState, useEffect, useCallback, useMemo } from "react";
import { ConsoleLogger } from "../../logger/ConsoleLogger";
import NotesHelper from "../../services/NoteService";
import Loader from "./Loader";
import style from "./Notes.module.css";

const Notes = (props) => {
	const logger = useMemo(() => new ConsoleLogger({ level: process.env.REACT_APP_LOGGER_LEVEL, }), []);
	const [loader, setLoader] = useState(false);
	const [notesType, setNotesType] = useState('recent');
	const [notes, setNotes] = useState([]);
	const [addNotes, setAddNotes] = useState('');
	const [recentNote, setRecentNote] = useState([]);
	const isEmployee = localStorage.getItem('isEmployeeLoggedIn');
	const isCustomer = localStorage.getItem('isLoggedIn');
	const [noteInternalFlag, setNoteInternalFlag] = useState('1');

	const getNotes = useCallback((refId) => {
		setLoader(true);
		NotesHelper.getNotes(refId).then(response => {
			setNotes(response.data);
			setRecentNote(response.data[0]);
			setLoader(false);
		}).catch(error => {
			logger.error("getNotes ERROR: " + error)
			setLoader(false);
		})
	}, [logger])

	useEffect(() => {
		if (isCustomer) {
			setNoteInternalFlag("0");
		}
		getNotes(props.refId);
	}, [getNotes, isCustomer, props.refId])

	function submitNotes(event) {
		let page = "";
		switch (props.area) {
			case "Quote View":
				page = "quote";
				break;
			case "Order View":
				page = "order";
				break;
			default:
				break;
		}
		event.preventDefault();
		setLoader(true);
		NotesHelper.insertNotes(props.refId, addNotes, page, noteInternalFlag).then(response => {
			if (response.data.result === "SUCCESS") {
				setAddNotes("");
				getNotes(props.refId);
				logger.info("Notes added successfully");
			} else {
				logger.info("Notes NOT added");
			}
			setLoader(false);
		}).catch(error => {
			logger.error("insertNotes ERROR: " + error)
			setLoader(false);
		})
	}

	const radioBtnChange = (event) => {
		setNoteInternalFlag(event.target.value);
	}

	return (
		<>
			{loader ? <Loader /> : null}
			{isEmployee || (props?.area === "Order View" && props?.selectedOrderAccess > 1 && isCustomer) || (props?.area === "Quote View" && props?.selectedQuoteAccess > 1 && isCustomer) ?
				<>
					<div className={`sub-hdr alt2 ${style.subHdrAlter} `}>
						<div className="nib">
							<div className="the-notch">
								<div className="notch-fg">
									<svg>
										<path
											d="M35.81,26.28l-13,10v-5C-10.47,28.68-1,9.16,11.79,0c0,7.28,0,9.13,0,13.28s3.18,7.92,11,9v-5Z"></path>
									</svg>
								</div>
							</div>
						</div>
						<h2 className={` ${style.sectionTitle} m-0 `}>Insert Notes</h2>
					</div>
					<div className="sub-section">
						<form onSubmit={submitNotes}>
							<label className={style.labelFld}>Notes</label>
							<textarea
								className={style.textArea}
								name="addNotes"
								value={addNotes}
								type="text"
								onChange={e => setAddNotes(e.target.value)}
							>
							</textarea>
							{isEmployee ?
								<div className="row">
									<div className="col-1">
										<input type="radio" value='1' name='internal' onChange={(e) => { radioBtnChange(e) }} checked={noteInternalFlag === '1'} /> Internal
									</div>
									<div className="col-1">
										<input type="radio" value='0' name='internal' onChange={(e) => { radioBtnChange(e) }} checked={noteInternalFlag === '0'} /> External
									</div>
								</div>
								: null}
							<button type="submit" className={style.notesBtn}>Submit Notes</button>
						</form>
					</div>
				</> : null
			}
			<div className={`sub-hdr alt2 ${style.subHdrAlter} `}>
				<div className="nib">
					<div className="the-notch">
						<div className="notch-fg">
							<svg>
								<path
									d="M35.81,26.28l-13,10v-5C-10.47,28.68-1,9.16,11.79,0c0,7.28,0,9.13,0,13.28s3.18,7.92,11,9v-5Z"></path>
							</svg>
						</div>
					</div>
				</div>
				<h2 className={` ${style.sectionTitle} m-0 `}>View Notes</h2>
			</div>
			<div className="sub-section">
				<ul className={style.notesTab}>
					<li className={notesType === 'recent' ? (style.active) : ''}>
						<button className={style.notesBtn2} onClick={() => setNotesType("recent")}>
							Display recent note
						</button>
					</li>
					<li className={notesType === 'all' ? (style.active) : ''}>
						<button className={style.notesBtn2} onClick={() => setNotesType("all")}>
							Display all notes
						</button>
					</li>
				</ul>
				{notesType === 'recent' ? <div className={`row ${style.rowGrp}`}>
					<div className='col-md-4'>
						<ul className={` ${style.productList}`}>
							<li>Date</li>
							<li>{recentNote?.date}</li>
						</ul>
					</div>

					<div className='col-md-4'>
						<ul className={` ${style.productList}`}>
							<li>Entered By</li>
							<li>{recentNote?.user}</li>
						</ul>
					</div>

					<div className='col-md-8'>
						<ul className={style.noteList}>
							<li>{recentNote?.internal === "1" ? "Internal Note" : "External Note"}</li>
							<li className={style.breakLine}>{recentNote?.note}</li>
						</ul>
					</div>
				</div> : <>
					{notes.map((obj) => {
						return (<div className={`row ${style.rowGrp}`}>
							<div className='col-md-4'>
								<ul className={` ${style.productList}`}>
									<li>Date</li>
									<li>{obj.date}</li>
								</ul>
							</div>

							<div className='col-md-4'>
								<ul className={` ${style.productList}`}>
									<li>Entered By</li>
									<li>{obj.user}</li>
								</ul>
							</div>

							<div className='col-md-8'>
								<ul className={style.noteList}>
									<li>{obj.internal === "1" ? "Internal Note" : "External Note"}</li>
									<li className={style.breakLine}>{obj.note}</li>
								</ul>
							</div>
						</div>)
					})}
				</>}
			</div>
		</>
	)

}

export default Notes;
