const JSONUtils = (function () {
    const createObj = (copy) => {
        let obj = {};
        obj['Circuit ID'] = copy.circuitID;
        obj['Account'] = copy.account;
        obj['Product'] = copy.product;
        obj['ALOC'] = copy.aloc;
        obj['ZLOC'] = copy.zloc;
        obj['Health'] = copy.health;
        obj['Customer Ticket #'] = copy.customerTicket;
        if (copy.ticket) {
            obj['Incident'] = copy.ticket['ticketNo'];
            obj['Status'] = copy.ticket['status'];
            obj['Proactive'] = copy.ticket['proactive'] ? 'Yes' : 'No';
            obj['Reported Date'] = copy.ticket['reportedDate'];
        } else {
            obj['Incident'] = '';
            obj['Status'] = '';
            obj['Proactive'] = '';
            obj['Reported Date'] = '';
        }
        return obj;
    }

    const createNetworkSummaryObj = (objectKeyNames, copy) => {
        // const obj = new Object();
        const obj = {};
        obj[objectKeyNames[0]] = copy.circuitId;
        obj[objectKeyNames[1]] = copy.gaid;
        obj[objectKeyNames[2]] = copy.name;
        obj[objectKeyNames[3]] = !!copy.billing_product ? copy.billing_product : copy.product;
        obj[objectKeyNames[4]] = copy.con_rate_code;
        obj[objectKeyNames[5]] = concatCompleteAddress(copy.aloc_street, copy.aloc_city, copy.aloc_state, copy.aloc_zip);
        obj[objectKeyNames[6]] = concatCompleteAddress(copy.zloc_street, copy.zloc_city, copy.zloc_state, copy.zloc_zip);
        return obj;
    }

    const jsonToFlatObject = async (jsonObj) => {
        let flatObj = [];
        let obj;
        jsonObj.forEach(item => {
            if (!item.events || item.events.length === 0) {
                obj = createObj(item);
                obj['Maintenance'] = '';
                obj['Impact Type'] = '';
                obj['Impact Duration'] = '';
                obj['Window Start'] = '';
                obj['Window End'] = '';
                flatObj.push(obj);
            } else if (item.events && item.events.length > 0) {
                item.events.forEach(event => {
                    obj = createObj(item);
                    obj['Maintenance'] = event.eventNumber;
                    obj['Impact Type'] = event.maintenanceType;
                    obj['Impact Duration'] = event.impactDuration;
                    obj['Window Start'] = event.fromTimeFrame;
                    obj['Window End'] = event.toTimeFrame;
                    flatObj.push(obj);
                })
            }
        });
        return await flatObj;
    };

    const concatCompleteAddress = (street, city, state, zip) => {
        let completeAddress = '';
        if (street) {
            completeAddress += street;
        }
        if (city) {
            completeAddress += ', ' + city;
        }
        if (state) {
            completeAddress += ', ' + state;
        }
        if (zip) {
            completeAddress += ', ' + zip;
        }
        return completeAddress;
    }
    
    return {
        createObj: createObj,
        createNetworkSummaryObj: createNetworkSummaryObj,
        jsonToFlatObject: jsonToFlatObject
    }
}());

export default JSONUtils;