import { useEffect, useState, useMemo } from 'react';
import { connect, useSelector } from 'react-redux';
import style from './MACD.module.css';
import MACDHelper from '../../services/MACDService';
import HomeData from '../../services/HomeServices';
import {
    setMACDAAddress,
    setMACDAAddress2,
    setMACDACity,
    setMACDAColoPop,
    setMACDAFiberConnector,
    setMACDAFloor,
    setMACDAFraming,
    setMACDAHandoff,
    setMACDALatitude,
    setMACDALineCode,
    setMACDALongitude,
    setMACDAOnsiteEmail,
    setMACDAOnsiteName,
    setMACDAOnsitePhone,
    setMACDAState,
    setMACDAZip,
    setMACDConnectorType,
    setMACDLiveEquipment,
    setMACDRackInfo,
    setMACDPanelInfo,
    setMACDPortFiberAssignment,
    setMACDZAddress,
    setMACDZAddress2,
    setMACDZCity,
    setMACDZFiberConnector,
    setMACDZFloor,
    setMACDZFraming,
    setMACDZHandoff,
    setMACDZLatitude,
    setMACDZLineCode,
    setMACDZLongitude,
    setMACDZOnsiteEmail,
    setMACDZOnsiteName,
    setMACDZOnsitePhone,
    setMACDZState,
    setMACDZZip,
    setMACDShippingAddress,
    setMACDShippingLV1,
    setMACDShippingLD1,
    setMACDShippingLV2,
    setMACDShippingLV3,
    setMACDShippingLD3,
    setMACDShippingCity,
    setMACDShippingState,
    setMACDShippingZip,
    setMACDShippingContact,
    setMACDShippingContactNew,
    setMACDShippingEmail,
    setMACDShippingPhone,
} from '../../redux';
import Loader from '../shared/Loader';
import { ConsoleLogger } from "../../logger/ConsoleLogger";
import HTTPErrorHandler from "../errorPages/HTTPErrorHandler";
import ColocationHelper from "../../services/ColocationService";
import QuoteHelper from '../../services/QuoteService';

const MACDSiteInfo = (props) => {
    const logger = useMemo(() => new ConsoleLogger({ level: process.env.REACT_APP_LOGGER_LEVEL, }),[]);

    //State Variables
    const [hasError, setHasError] = useState(null);
    const [stateList, setStateList] = useState([]);
    const [handoffOptions, setHandoffOptions] = useState([]);
    const [loader, setLoader] = useState(false);
    const [coloPopLocationOptions, setColoPopLocationOptions] = useState([]);
    const [contactOptions, setContactOptions] = useState([]);

    //Redux Store Variables
    const selectedMACDProduct = useSelector(state => state?.MACDOrder.selectedProduct.productId);
    const selectedMACDCircuit = useSelector(state => state?.MACDOrder.ServiceInformation.CIRCUIT_TYPE);
    const selectedActivityRequest = useSelector(state => state?.MACDOrder.selectedActivityRequest);
    const selectedMACDBandwidth = useSelector(state => state?.MACDOrder.ServiceInformation.BANDWIDTH);
    const selectedMACDNumberOfRoutes = useSelector(state => state?.MACDOrder.ServiceInformation.NUMBER_OF_ROUTES);
    const selectedActivityType = useSelector(state => state?.MACDOrder.selectedActivityType.name);
    const selectedMACDLocationA = useSelector(state => state?.MACDOrder.LocationA);
    const selectedMACDLocationZ = useSelector(state => state?.MACDOrder.LocationZ);
    const selectedMACDShipping = useSelector(state => state?.MACDOrder.ShippingLocation);
	const selectedMACDEscortRequest = useSelector(state => state?.MACDOrder.ServiceInformation.ESCORT_ACCESS_REQUEST);
    const selectedMACDEdgeDevice = useSelector(state => state?.MACDOrder.ServiceInformation.EDGE_DEVICE);

    //Declaring Google
    const google = window.google = window.google ? window.google : {};

    useEffect(() => {
		getStates();
        getOrderHandoffOptions(selectedMACDCircuit, selectedMACDBandwidth, selectedMACDNumberOfRoutes);
		initAutocomplete();
	    // eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

    useEffect(() => {
        if (selectedMACDProduct === 15) {
            setLoader(true);
            ColocationHelper.getPopLocations().then(resp => {
                logger.info(resp.data);
                setColoPopLocationOptions(resp.data);
                setLoader(false);
            }).catch(error => {
                logger.error("Getting Colocation Pop Locations: " + error);
                setHasError(error);
                setLoader(false);
            })
        }
    }, [logger, selectedMACDProduct])


    //Fetching States from the backend
    const getStates = () => {
		HomeData.getStateList().then(resp => {
			setStateList(resp.data);
		}).catch((error) => {
			logger.error("getStates ERROR: " + error)
            setHasError(error);
		})
	}

    useEffect(() => {
        //Only SDWAN with leased option selected for Edge Device
        if(selectedMACDProduct === 24 && selectedMACDEdgeDevice === "2")
        setLoader(true);
        QuoteHelper.getQuoteContact().then(response => {
            logger.info(`Contact Data: ${JSON.stringify(response.data)}`);
            setContactOptions(response.data);
            setLoader(false);
        }).catch(error => {
            logger.error("Error Getting Contacts: " + error);
            setHasError(error);
            setLoader(false)
        })
    },[logger, selectedMACDEdgeDevice, selectedMACDProduct])

    const autoFillContact = (contactValue) => {
        setLoader(true);
        QuoteHelper.getQuoteContactInfo(contactValue).then(response => {
            props.SHIPPING_PHONE(response.data.phone);
            props.SHIPPING_EMAIL(response.data.email);
            setLoader(false);
        }).catch(error => {
            logger.error("Could Not AutoFill Contact: " + error);
            setHasError(error);
            setLoader(false);
        })
    }
    //Fetching Order Handoff Options
    const getOrderHandoffOptions = (circuit, bandwidth, num_routes) => {
        if(selectedMACDProduct !== 30 && selectedMACDProduct !== 24){
            setLoader(true);
            MACDHelper.getMACDHandoffs(circuit, bandwidth,num_routes).then(resp => {
                logger.info(encodeURIComponent(resp.data).replace(/%20/g, " "));
                setHandoffOptions(resp.data);
                //autoselect option if only 1 handoff option
                if(resp.data?.length === 1){
                    props.A_HANDOFF(resp.data[0].id);
                    props.Z_HANDOFF(resp.data[0].id);
                }
                setLoader(false);
            }).catch(error => {
                logger.error("Getting Order Handoff Options: " + error);
                setHasError(error);
                setLoader(false);
            })
        }
	}

    //ADO-850519 Autocompleting colo address fields based on the pop the user selects
    const getColoAddress = (uid) => {
        setLoader(true);
        ColocationHelper.getColoAddressFields(uid).then(response => {
            logger.info(response.data);
            props.A_ADDRESS(response.data.address);
            props.A_ADDRESS2(response.data.address2);
            props.A_CITY(response.data.city);
            props.A_STATE(response.data.state);
            props.A_ZIP(response.data.zip);
            setLoader(false);
        }).catch(error => {
            setLoader(false);
            logger.error(`error gettting colo address: ${error}`);
        })
    }

    //GOOGLE AUTOCOMPLETE
    const initAutocomplete = () => {
		const autocompleteA = new google.maps.places.Autocomplete((document.getElementById('a_address')), { types: ['geocode'] });
		google.maps.event.addListener(autocompleteA, 'place_changed', function () {
			fillInAddress(autocompleteA, "A");
		});
        const autocompleteZ = new google.maps.places.Autocomplete((document.getElementById('z_address')), { types: ['geocode'] });
		google.maps.event.addListener(autocompleteZ, 'place_changed', function () {
			fillInAddress(autocompleteZ, "Z");
		});
        const autocompleteShippingAddress = new google.maps.places.Autocomplete((document.getElementById('shipping_address')), { types: ['geocode'] });
        google.maps.event.addListener(autocompleteShippingAddress, 'place_changed', function () {
            fillInAddress(autocompleteShippingAddress, "S");
        });
	}

    //Google autocomplete based on address selected in UI
    const fillInAddress = (ac,loc) => {
		var place = ac.getPlace();
        logger.info(place);
        //Note for MACD with only use the street name instead of the full formatted address
        if(loc === "A"){
            props.A_ADDRESS(place.name);
        }else if(loc === "Z"){
            props.Z_ADDRESS(place.name);
        } else {
            props.SHIPPING_ADDRESS(place.name);
        }
        for (var x = 0; x < place.address_components.length; x++) {
            switch (place.address_components[x].types[0]) {
                case 'locality':
                    if (loc === "A") {
                        props.A_CITY(place.address_components[x].short_name);
                    } else if (loc === "Z") {
                        props.Z_CITY(place.address_components[x].short_name);
                    } else {
                        props.SHIPPING_CITY(place.address_components[x].short_name);
                    }
                    break;
                case 'administrative_area_level_1':
                    if (loc === "A") {
                        props.A_STATE(place.address_components[x].short_name);
                    } else if (loc === "Z") {
                        props.Z_STATE(place.address_components[x].short_name);
                    } else {
                        props.SHIPPING_STATE(place.address_components[x].short_name);
                    }
                    break;
                case 'postal_code':
                    if (loc === "A") {
                        props.A_ZIP(place.address_components[x].short_name);
                    } else if (loc === "Z") {
                        props.Z_ZIP(place.address_components[x].short_name);
                    } else {
                        props.SHIPPING_ZIP(place.address_components[x].short_name);
                    }
                    break;
                default:
                    break;
            }
        }

		return place;
	}

    //Handler for input changes
    const inputHandler = (ev) => {
        switch (ev.target.id) {
            case "a_lat":
                props.A_LATITUDE(ev.target.value);
                break;
            case "a_long":
                props.A_LONGITUDE(ev.target.value);
                break;
            case "a_colo_pop":
				props.A_ADDRESS("");
				props.A_ADDRESS2("");
				props.A_CITY("");
				props.A_STATE("");
				props.A_ZIP("");
				props.A_COLO_POP(ev.target.value);
				//Disable adddress field if user selects a pop from the dropdown
				if (ev.target.value !== "Other") {
					document.getElementById("a_address").disabled = true;
					document.getElementById("a_address2").disabled = true;
					document.getElementById("a_city").disabled = true;
					document.getElementById("a_state").disabled = true;
					document.getElementById("a_zip").disabled = true;
					getColoAddress(ev.target.value);
				} else {
					document.getElementById("a_address").disabled = false;
					document.getElementById("a_address2").disabled = false;
					document.getElementById("a_city").disabled = false;
					document.getElementById("a_state").disabled = false;
					document.getElementById("a_zip").disabled = false;
				}
                break;
            case "a_address":
                props.A_ADDRESS(ev.target.value);
                break;
            case "a_address2":
                props.A_ADDRESS2(ev.target.value);
                break;
            case "a_city":
                props.A_CITY(ev.target.value);
                break;
            case "a_state":
                props.A_STATE(ev.target.value);
                break;
            case "a_zip":
                props.A_ZIP(ev.target.value);
                break;
            case "a_floor":
                props.A_FLOOR(ev.target.value);
                break;
            case "a_onsite_name":
                props.A_ONSITE_NAME_LCON(ev.target.value);
                break;
            case "a_onsite_email":
                props.A_ONSITE_EMAIL(ev.target.value);
                break;
            case "a_onsite_number":
                props.A_ONSITE_NUMBER(ev.target.value);
                break;
            case "a_handoff":
                props.A_HANDOFF(ev.target.value);
                break;
            case "a_framing":
                props.A_FRAMING(ev.target.value);
                break;
            case "a_line_code":
                props.A_LINE_CODE(ev.target.value);
                break;
            case "a_fiber_conn":
                props.A_FIBER_CONNECTOR(ev.target.value);
                break;
            case "connector_type":
                props.CONNECTOR_TYPE(ev.target.value);
                break;
            case "live_equipment":
                props.LIVE_EQUIPMENT(ev.target.value);
                break;
            case "rack_info":
                props.RACK_INFO(ev.target.value);
                break;
            case "panel_info":
                props.PANEL_INFO(ev.target.value);
                break;
            case "port_fiber_assignment":
                props.PORT_FIBER_ASSIGNMENT(ev.target.value);
                break;
            case "z_lat":
                props.Z_LATITUDE(ev.target.value);
                break;
            case "z_long":
                props.Z_LONGITUDE(ev.target.value);
                break;
            case "z_address":
                props.Z_ADDRESS(ev.target.value);
                break;
            case "z_address2":
                props.Z_ADDRESS2(ev.target.value);
                break;
            case "z_city":
                props.Z_CITY(ev.target.value);
                break;
            case "z_floor":
                props.Z_FLOOR(ev.target.value);
                break;
            case "z_state":
                props.Z_STATE(ev.target.value);
                break;
            case "z_zip":
                props.Z_ZIP(ev.target.value);
                break;
            case "z_onsite_name":
                props.Z_ONSITE_NAME_LCON(ev.target.value);
                break;
            case "z_onsite_email":
                props.Z_ONSITE_EMAIL(ev.target.value);
                break;
            case "z_onsite_number":
                props.Z_ONSITE_NUMBER(ev.target.value);
                break;
            case "z_handoff":
                props.Z_HANDOFF(ev.target.value);
                break;
            case "z_framing":
                props.Z_FRAMING(ev.target.value);
                break;
            case "z_line_code":
                props.Z_LINE_CODE(ev.target.value);
                break;
            case "z_fiber_conn":
                props.Z_FIBER_CONNECTOR(ev.target.value);
				break;
            case "shipping_address":
                props.SHIPPING_ADDRESS(ev.target.value);
                break;
            case "shipping_ld1":
                props.SHIPPING_LD1(ev.target.value);
                break;
            case "shipping_lv1":
                props.SHIPPING_LV1(ev.target.value);
                break;
            case "shipping_lv2":
                props.SHIPPING_LV2(ev.target.value);
                break;
            case "shipping_ld3":
                props.SHIPPING_LD3(ev.target.value);
                break;
            case "shipping_lv3":
                props.SHIPPING_LV3(ev.target.value);
                break;
            case "shipping_city":
                props.SHIPPING_CITY(ev.target.value);
                break;
            case "shipping_state":
                props.SHIPPING_STATE(ev.target.value);
                break;
            case "shipping_zip":
                props.SHIPPING_ZIP(ev.target.value);
                break;
            case "shipping_contact":
                if(ev.target.value !== "New") {
                    autoFillContact(ev.target.value);
                }
                props.SHIPPING_CONTACT(ev.target.value);
                break;
            case "shipping_contact_new":
                props.SHIPPING_CONTACT_NEW(ev.target.value);
                break;
            case "shipping_phone":
                props.SHIPPING_PHONE(ev.target.value);
                break;
            case "shipping_email":
                props.SHIPPING_EMAIL(ev.target.value);
                break;
			default:
				break;
        }
    }

    return (
        <>
        {hasError && <HTTPErrorHandler error={hasError}></HTTPErrorHandler>}
        {loader ? <Loader/> : null}
        {!hasError && (
        <>
            <div className={style.section1}>
                <div className={`sub-hdr alt0 ${style.subHdrAlter} `}>
                    <div className="nib">
                        <div className="the-notch">
                            <div className="notch-fg">
                                <svg>
                                    <path
                                        d="M35.81,26.28l-13,10v-5C-10.47,28.68-1,9.16,11.79,0c0,7.28,0,9.13,0,13.28s3.18,7.92,11,9v-5Z"></path>
                                </svg>
                            </div>
                        </div>
                    </div>
                    <h2 className={` ${style.sectionTitle} m-0 `}>Location A</h2>
                </div>

                <div className="sub-section">
                    <div className={` row ${style.rowGrp}`}>
                        {/*LAT AND LONG ONLY NEEDED FOR DARK FIBER*/}
                        {selectedMACDProduct === 30 ?
                            <>
                                <div className={` col-12 col-sm-6 col-md-4 col-lg-4 ${style.colGrp}`}>
                                    <label className={style.labelText}>Latitude</label>
                                    <input type='text' className={style.inputFld} id="a_lat" name="a_lat" onChange={inputHandler} value={selectedMACDLocationA.A_LATITUDE}></input>
                                </div>
                                <div className={` col-12 col-sm-6 col-md-4 col-lg-4 ${style.colGrp}`}>
                                    <label className={style.labelText}>Longitude</label>
                                    <input type='text' className={style.inputFld} id="a_long" name="a_long" onChange={inputHandler} value={selectedMACDLocationA.A_LONGITUDE}></input>
                                </div>
                                <div className={`w-100 ${style.rowGrp} `}></div>
                            </> : null
                        }
                        {selectedMACDProduct === 15 ?
                            <div className={` col-12 col-sm-6 col-md-4 col-lg-4 ${style.colGrp}`}>
                                <label className={style.labelText}>Pop Location<span className={style.mandatory}>*</span></label>
                                <select className={style.selectFld} id="a_colo_pop" name="a_colo_pop" onChange={inputHandler} value={selectedMACDLocationA.A_COLO_POP} >
                                    <option></option>
                                    {coloPopLocationOptions.map((obj) => {
                                        return <option value={obj.id}>{obj.name}</option>
                                    })}
                                </select>
                            </div> : null
                        }
                        <div className={` col-12 col-sm-6 col-md-4 col-lg-4 ${style.colGrp}`}>
                            <label className={style.labelText}>Address<span className={style.mandatory}>*</span></label>
                            <input type='text' className={style.inputFld} id="a_address" name="a_address" onChange={inputHandler} value={selectedMACDLocationA.A_ADDRESS}></input>
                        </div>
                        <div className={` col-12 col-sm-6 col-md-4 col-lg-4 ${style.colGrp}`}>
                            <label className={style.labelText}>Address 2</label>
                            <input type='text' className={style.inputFld} id="a_address2" name="a_address2" onChange={inputHandler} value={selectedMACDLocationA.A_ADDRESS2}></input>
                        </div>
                        {selectedMACDProduct === 30 ?
                            <>
                                <div className={` col-12 col-sm-6 col-md-4 col-lg-4 ${style.colGrp}`}>
                                    <label className={style.labelText}>Floor<span className={style.mandatory}>*</span></label>
                                    <input type='text' className={style.inputFld} id="afloor" name="afloor" value="FL" disabled></input>
                                </div>
                                <div className={` col-12 col-sm-6 col-md-4 col-lg-4 ${style.colGrp}`}>
                                    <label className={style.labelText}>Floor Value<span className={style.mandatory}>*</span></label>
                                    <input type='text' className={style.inputFld} id="a_floor" name="a_floor" onChange={inputHandler} value={selectedMACDLocationA.A_FLOOR}></input>
                                </div>
                            </> : null
                        }
                        <div className={` col-12 col-sm-6 col-md-4 col-lg-4 ${style.colGrp}`}>
                            <label className={style.labelText}>City<span className={style.mandatory}>*</span></label>
                            <input type='text' className={style.inputFld} id="a_city" name="a_city" onChange={inputHandler} value={selectedMACDLocationA.A_CITY}></input>
                        </div>

                        <div className={` col-12 col-sm-6 col-md-4 col-lg-4 ${style.colGrp}`}>
                            <label className={style.labelText}>State<span className={style.mandatory}>*</span></label>
                            <select className={style.selectFld} id="a_state" name="a_state" onChange={inputHandler} value={selectedMACDLocationA.A_STATE}>
                                {stateList?.map((obj) => {
                                    return <option value={obj.id}>{obj.name}</option>
                                })}
                            </select>
                        </div>
                        <div className={` col-12 col-sm-6 col-md-4 col-lg-4 ${style.colGrp}`}>
                            <label className={style.labelText}>Zip<span className={style.mandatory}>*</span></label>
                            <input type='text' className={style.inputFld} id="a_zip" name="a_zip" onChange={inputHandler} value={selectedMACDLocationA.A_ZIP}></input>
                        </div>
                        <div className={` col-12 col-sm-6 col-md-4 col-lg-4 ${style.colGrp}`}>
                            <label className={style.labelText}>ONSITE NAME (LCON)<span className={style.mandatory}>*</span></label>
                            <input type='text' className={style.inputFld} id="a_onsite_name" name="a_onsite_name" onChange={inputHandler} value={selectedMACDLocationA.A_ONSITE_NAME_LCON}></input>
                        </div>
                        <div className={` col-12 col-sm-6 col-md-4 col-lg-4 ${style.colGrp}`}>
                            <label className={style.labelText}>ONSITE NUMBER<span className={style.mandatory}>*</span></label>
                            <input type='text' className={style.inputFld}  maxLength='10' id="a_onsite_number" name="a_onsite_number" onChange={inputHandler} value={selectedMACDLocationA.A_ONSITE_NUMBER}></input>
                        </div>
                        <div className={` col-12 col-sm-6 col-md-4 col-lg-4 ${style.colGrp}`}>
                            <label className={style.labelText}>ONSITE Email<span className={style.mandatory}>*</span></label>
                            <input type='text' className={style.inputFld} id="a_onsite_email" name="a_onsite_email" onChange={inputHandler} value={selectedMACDLocationA.A_ONSITE_EMAIL}></input>
                        </div>
                        {/*Dark Fiber, Colocation, DIA PREM Broadband Doesn't Need Handoffs*/}
                        {selectedMACDProduct !== 30 && selectedMACDCircuit !== "14" && selectedMACDProduct !== 15 && selectedMACDProduct !== 24 ?
                            <div className={` col-12 col-sm-6 col-md-4 col-lg-4 ${style.colGrp}`}>
                                <label className={style.labelText}>Handoff<span className={style.mandatory}>*</span></label>
                                <select className={style.selectFld} id="a_handoff" name="a_handoff" onChange={inputHandler} value={selectedMACDLocationA.A_HANDOFF}>
                                    <option></option>
                                    {handoffOptions.map((obj) => {
                                        return <option value={obj.id}>{obj.name}</option>
                                    })}
                                </select>
                            </div> : null
                        }

                        {/*Only Display if Circuit Type is DS1 or DS3 which is 1 in the DB*/}
                        {selectedMACDCircuit === "1" || selectedMACDCircuit === "2" ?
                            <>
                                <div className={` col-12 col-sm-6 col-md-4 col-lg-4 ${style.colGrp}`}>
                                    <label className={style.labelText}>Framing<span className={style.mandatory}>*</span></label>
                                    <select className={style.selectFld} id="a_framing" name="a_framing" onChange={inputHandler}  value={selectedMACDLocationA.A_FRAMING}>
                                        <option>-</option>
                                        <option value="1">ESF</option>
                                        <option value="2">SF</option>
                                    </select>
                                </div>
                                <div className={` col-12 col-sm-6 col-md-4 col-lg-4 ${style.colGrp}`}>
                                    <label className={style.labelText}>Line Code<span className={style.mandatory}>*</span></label>
                                    <select className={style.selectFld} id="a_line_code" name="a_line_code" onChange={inputHandler} value={selectedMACDLocationA.A_LINE_CODE}>
                                        <option>-</option>
                                        <option value="AMI/D4">AMI/D4</option>
                                        <option value="B8ZS">B8ZS</option>
                                    </select>
                                </div>
                            </> : null
                        }
                        {/*Fiber Connector Types only for Waves and for certian handoff selections*/}
                        {selectedMACDProduct === 1 || (selectedMACDCircuit === "6" && ["6", "7", "8"].includes(selectedMACDLocationA.A_HANDOFF)) ?
                            <div className={` col-12 col-sm-6 col-md-4 col-lg-4 ${style.colGrp}`}>
                                <label className={style.labelText}>Fiber Connector Types<span className={style.mandatory}>*</span></label>
                                <select className={style.selectFld} id="a_fiber_conn" name="a_fiber_conn" onChange={inputHandler} value={selectedMACDLocationA.A_FIBER_CONNECTOR} >
                                    <option></option>
                                    <option value="FC">FC</option>
                                    <option value="LC">LC</option>
                                    <option value="SC">SC</option>
                                </select>
                            </div> : null
                        }
                        {selectedMACDProduct === 15 ?
                            <>

                                {selectedActivityType === "Cross-Connect" && selectedActivityRequest === "Change" ?
                                    <>
                                        <div className={` col-12 col-sm-6 col-md-4 col-lg-4 ${style.colGrp}`}>
                                            <label className={style.labelText}>Connector Type</label>
                                            <select className={style.selectFld} id="connector_type" name="connector_type" onChange={inputHandler} value={selectedMACDLocationA.CONNECTOR_TYPE}>
                                                <option value=""></option>
                                                <option value="LC/UPC">LC/UPC</option>
                                                <option value="LC/APC">LC/APC</option>
                                                <option value="SC/UPC">SC/UPC</option>
                                                <option value="SC/APC">SC/APC</option>
                                            </select>
                                        </div>
                                        <div className={` col-12 col-sm-6 col-md-4 col-lg-4 ${style.colGrp}`}>
                                            <label className={style.labelText}>Live Equipment</label>
                                            <select className={style.selectFld} id="live_equipment" name="live_equipmen" onChange={inputHandler} value={selectedMACDLocationA.LIVE_EQUIPMENT}>
                                                <option value=""></option>
                                                <option value="Yes">Yes</option>
                                                <option value="No">No</option>
                                            </select>
                                        </div>
                                    </> : null
                                }
                                {selectedActivityType === "Cross-Connect" || selectedActivityRequest === "Disconnect" ?
                                    <>
                                        <div className={` col-12 col-sm-6 col-md-4 col-lg-4 ${style.colGrp}`}>
                                            <label className={style.labelText}>Rack</label>
                                            <input type='text' className={style.inputFld} id="rack_info" name="rack_info" onChange={inputHandler} value={selectedMACDLocationA.RACK_INFO}></input>
                                        </div>
                                        <div className={` col-12 col-sm-6 col-md-4 col-lg-4 ${style.colGrp}`}>
                                            <label className={style.labelText}>Panel</label>
                                            <input type='text' className={style.inputFld} id="panel_info" name="panel_info" onChange={inputHandler} value={selectedMACDLocationA.PANEL_INFO}></input>
                                        </div>
                                    </> : null
                                }
                                {selectedActivityType === "Full Disconnect" || selectedActivityType === "Cross-Connect" ?
                                    <div className={` col-12 col-sm-6 col-md-4 col-lg-4 ${style.colGrp}`}>
                                        <label className={style.labelText}>Port/Fiber Assignment</label>
                                        <input type='text' className={style.inputFld} id="port_fiber_assignment" name="port_fiber_assignment" onChange={inputHandler} value={selectedMACDLocationA.PORT_FIBER_ASSIGNMENT}></input>
                                    </div> : null
                                }
                            </> : null
                        }
                    </div>
                </div></div>
            <div className={style.dividers}></div>

            {/*Only WAVES, EACCESS, AND DARKFIBER WITH CERTIAN ACTIVITY TYPES NEED LOC Z INFO*/}
				{!selectedMACDEscortRequest && (selectedMACDProduct === 1 || selectedMACDProduct === 20 || (selectedMACDProduct === 30 && ["OTDR (Test & Turnup)", "Generic ICB Request", "Submit Project Request"].includes(selectedActivityType))) ?
                    <>
                        <div className={style.section1}>
                            <div className={`sub-hdr alt1 ${style.subHdrAlter} `}>
                                <div className="nib">
                                    <div className="the-notch">
                                        <div className="notch-fg">
                                            <svg>
                                                <path
                                                    d="M35.81,26.28l-13,10v-5C-10.47,28.68-1,9.16,11.79,0c0,7.28,0,9.13,0,13.28s3.18,7.92,11,9v-5Z"></path>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                                <h2 className={` ${style.sectionTitle} m-0 `}>Location Z</h2>
                            </div>

                            <div className="sub-section">
                                <div className={` row ${style.rowGrp}`}>
                                    {/*LAT AND LONG ONLY NEEDED FOR DARK FIBER*/}
                                    {selectedMACDProduct === 30 ?
                                        <>
                                            <div className={` col-12 col-sm-6 col-md-4 col-lg-4 ${style.colGrp}`}>
                                                <label className={style.labelText}>Latitude</label>
                                                <input type='text' className={style.inputFld} id="z_lat" name="z_lat" onChange={inputHandler} value={selectedMACDLocationZ.Z_LATITUDE}></input>
                                            </div>
                                            <div className={` col-12 col-sm-6 col-md-4 col-lg-4 ${style.colGrp}`}>
                                                <label className={style.labelText}>Longitude</label>
                                                <input type='text' className={style.inputFld} id="z_long" name="z_long" onChange={inputHandler} value={selectedMACDLocationZ.Z_LONGITUDE}></input>
                                            </div>
                                            <div className={`w-100 ${style.rowGrp} `}></div>
                                        </> : null
                                    }
                                    <div className={` col-12 col-sm-6 col-md-4 col-lg-4 ${style.colGrp}`}>
                                        <label className={style.labelText}>Address<span className={style.mandatory}>*</span></label>
                                        <input type='text' className={style.inputFld} id="z_address" name="z_address" onChange={inputHandler} value={selectedMACDLocationZ.Z_ADDRESS}></input>
                                    </div>
                                    <div className={` col-12 col-sm-6 col-md-4 col-lg-4 ${style.colGrp}`}>
                                        <label className={style.labelText}>Address 2</label>
                                        <input type='text' className={style.inputFld} id="z_address2" name="z_address2" onChange={inputHandler} value={selectedMACDLocationZ.Z_ADDRESS2}></input>
                                    </div>
                                    {selectedMACDProduct === 30 ?
                                    <>
                                        <div className={` col-12 col-sm-6 col-md-4 col-lg-4 ${style.colGrp}`}>
                                            <label className={style.labelText}>Floor<span className={style.mandatory}>*</span></label>
                                            <input type='text' className={style.inputFld} id="zfloor" name="zfloor" value="FL" disabled></input>
                                        </div>
                                        <div className={` col-12 col-sm-6 col-md-4 col-lg-4 ${style.colGrp}`}>
                                            <label className={style.labelText}>Floor Value<span className={style.mandatory}>*</span></label>
                                            <input type='text' className={style.inputFld} id="z_floor" name="z_floor" onChange={inputHandler} value={selectedMACDLocationA.Z_FLOOR}></input>
                                        </div>
                                    </> : null
                                    }
                                    <div className={` col-12 col-sm-6 col-md-4 col-lg-4 ${style.colGrp}`}>
                                        <label className={style.labelText}>City<span className={style.mandatory}>*</span></label>
                                        <input type='text' className={style.inputFld} id="z_city" name="z_city" onChange={inputHandler} value={selectedMACDLocationZ.Z_CITY}></input>
                                    </div>
                                    <div className={` col-12 col-sm-6 col-md-4 col-lg-4 ${style.colGrp}`}>
                                        <label className={style.labelText}>State<span className={style.mandatory}>*</span></label>
                                        <select className={style.selectFld} id="z_state" name="z_state" onChange={inputHandler} value={selectedMACDLocationZ.Z_STATE}>
                                            {stateList?.map((obj) => {
                                                return <option value={obj.id}>{obj.name}</option>
                                            })}
                                        </select>
                                    </div>
                                    <div className={` col-12 col-sm-6 col-md-4 col-lg-4 ${style.colGrp}`}>
                                        <label className={style.labelText}>Zip<span className={style.mandatory}>*</span></label>
                                        <input type='text' className={style.inputFld} id="z_zip" name="z_zip" onChange={inputHandler} value={selectedMACDLocationZ.Z_ZIP}></input>
                                    </div>
                                    <div className={` col-12 col-sm-6 col-md-4 col-lg-4 ${style.colGrp}`}>
                                        <label className={style.labelText}>ONSITE NAME (LCON)<span className={style.mandatory}>*</span></label>
                                        <input type='text' className={style.inputFld} id="z_onsite_name" name="z_onsite_name" onChange={inputHandler} value={selectedMACDLocationZ.Z_ONSITE_NAME_LCON}></input>
                                    </div>
                                    <div className={` col-12 col-sm-6 col-md-4 col-lg-4 ${style.colGrp}`}>
                                        <label className={style.labelText}>ONSITE NUMBER<span className={style.mandatory}>*</span></label>
                                        <input type='text' className={style.inputFld}  maxLength='10' id="z_onsite_number" name="z_onsite_number" onChange={inputHandler} value={selectedMACDLocationZ.Z_ONSITE_NUMBER}></input>
                                    </div>
                                    <div className={` col-12 col-sm-6 col-md-4 col-lg-4 ${style.colGrp}`}>
                                        <label className={style.labelText}>ONSITE Email<span className={style.mandatory}>*</span></label>
                                        <input type='text' className={style.inputFld} id="z_onsite_email" name="z_onsite_email" onChange={inputHandler} value={selectedMACDLocationZ.Z_ONSITE_EMAIL}></input>
                                    </div>
                                    {/*Dark Fiber Doesn't Need Handoff*/}
                                    {selectedMACDProduct !== 30 ?
                                        <div className={` col-12 col-sm-6 col-md-4 col-lg-4 ${style.colGrp}`}>
                                            <label className={style.labelText}>Handoff<span className={style.mandatory}>*</span></label>
                                            <select className={style.selectFld} id="z_handoff" name="z_handoff" onChange={inputHandler} value={selectedMACDLocationZ.Z_HANDOFF}>
                                                <option></option>
                                                {handoffOptions.map((obj) => {
                                                    return <option value={obj.id}>{obj.name}</option>
                                                })}
                                            </select>
                                        </div> : null
                                    }
                                    {/*Only Display if Circuit Type is DS1 and DS3 which is 1 in the DB*/}
                                    {selectedMACDCircuit === "1" || selectedMACDCircuit === "2"?
                                        <>
                                            <div className={` col-12 col-sm-6 col-md-4 col-lg-4 ${style.colGrp}`}>
                                                <label className={style.labelText}>Framing<span className={style.mandatory}>*</span></label>
                                                <select className={style.selectFld} id="z_framing" name="z_framing" onChange={inputHandler} value={selectedMACDLocationZ.Z_FRAMING}>
                                                    <option>-</option>
                                                    <option value="1">ESF</option>
                                                    <option value="2">SF</option>
                                                </select>
                                            </div>

                                            <div className={` col-12 col-sm-6 col-md-4 col-lg-4 ${style.colGrp}`}>
                                                <label className={style.labelText}>Line Code<span className={style.mandatory}>*</span></label>
                                                <select className={style.selectFld} id="z_line_code" name="z_line_code" onChange={inputHandler} value={selectedMACDLocationZ.Z_LINE_CODE}>
                                                    <option>-</option>
                                                    <option value="AMI/D4">AMI/D4</option>
                                                    <option value="B8ZS">B8ZS</option>
                                                </select>
                                            </div>
                                        </> :  null
                                    }
                                    {/*Fiber Connector Types only for Waves and for certian handoff selections*/}
                                    {selectedMACDProduct === 1 || (selectedMACDCircuit === "6" && ["6", "7", "8"].includes(selectedMACDLocationZ.Z_HANDOFF)) ?
                                        <div className={` col-12 col-sm-6 col-md-4 col-lg-4 ${style.colGrp}`}>
                                            <label className={style.labelText}>Fiber Connector Types<span className={style.mandatory}>*</span></label>
                                            <select className={style.selectFld} id="z_fiber_conn" name="z_fiber_conn" onChange={inputHandler} value={selectedMACDLocationZ.Z_FIBER_CONNECTOR} >
                                                <option></option>
                                                <option value="FC">FC</option>
                                                <option value="LC">LC</option>
                                                <option value="SC">SC</option>
                                            </select>
                                        </div> : null
                                    }
                                </div>
                            </div>
                        </div>
                        <div className={style.dividers}></div>
                    </> : null
                }
                {selectedMACDProduct === 24 && selectedMACDEdgeDevice === "2" ?
                    <>
                        <div className={style.section1}>
                        <div className={`sub-hdr alt1 ${style.subHdrAlter} `}>
                            <div className="nib">
                                <div className="the-notch">
                                    <div className="notch-fg">
                                        <svg>
                                            <path
                                                d="M35.81,26.28l-13,10v-5C-10.47,28.68-1,9.16,11.79,0c0,7.28,0,9.13,0,13.28s3.18,7.92,11,9v-5Z"></path>
                                        </svg>
                                    </div>
                                </div>
                            </div>
                            <h2 className={` ${style.sectionTitle} m-0 `}>SD-WAN Equipment Shipping Address</h2>
                        </div>

                        <div className="sub-section">
                            <div className={` row ${style.rowGrp}`}>
                                <div className={` col-6 ${style.colGrp}`}>
                                    <label className={style.labelText}>Address<span className={style.mandatory}>*</span></label>
                                    <input type='text' className={style.inputFld} id="shipping_address" name="shipping_address" onChange={inputHandler} value={selectedMACDShipping.SHIPPING_ADDRESS}></input>
                                </div>
                            </div>
                            <div className={` row ${style.rowGrp}`}>
                                <div className={`col-2 ${style.colGrp}`}>
                                    <label className={style.labelText}>Structure</label>
                                    <select className={style.selectFld} id="shipping_ld1" name="shipping_ld1" onChange={inputHandler} value={selectedMACDShipping.SHIPPING_LD1}>
                                        <option></option>
                                        <option value="BLDG">BLDG</option>
                                        <option value="WNG">WNG</option>
                                        <option value="PIER">PIER</option>
                                    </select>
                                </div>
                                <div className={`col-2 ${style.colGrp}`}>
                                    <input type='text' className={`mt-4 ${style.inputFld}`} id="shipping_lv1" name="shipping_lv1" onChange={inputHandler} value={selectedMACDShipping.SHIPPING_LV1}></input>
                                </div>
                                <div className={`col-2 ${style.colGrp}`}>
                                    <label className={style.labelText}>Floor</label>
                                    <input type='text' className={style.inputFld} id="shipping_ld2" name="shipping_ld2" value="FL" disabled></input>

                                </div>
                                <div className={`col-2 ${style.colGrp}`}>
                                    <input type='text' className={`mt-4 ${style.inputFld}`} id="shipping_lv2" name="shipping_lv2" onChange={inputHandler} value={selectedMACDShipping.SHIPPING_LV2}></input>
                                </div>
                                <div className={`col-2 ${style.colGrp}`}>
                                    <label className={style.labelText}>Unit</label>
                                    <select className={style.selectFld} id="shipping_ld3" name="shipping_ld3" onChange={inputHandler} value={selectedMACDShipping.SHIPPING_LD3}>
                                        <option value=""></option>
                                        <option value="APT">APT</option>
                                        <option value="RM">RM</option>
                                        <option value="LOT">LOT</option>
                                        <option value="SLIP">SLIP</option>
                                        <option value="UNIT">UNIT</option>
                                        <option value="SUIT">SUIT</option>
                                    </select>
                                </div>
                                <div className={`col-2 ${style.colGrp}`}>
                                    <input type='text' className={`mt-4 ${style.inputFld}`} id="shipping_lv3" name="shipping_lv3" onChange={inputHandler} value={selectedMACDShipping.SHIPPING_LV3}></input>
                                </div>
                            </div>
                            <div className={` row ${style.rowGrp}`}>
                                <div className={` col-12 col-sm-6 col-md-4 col-lg-4 ${style.colGrp}`}>
                                    <label className={style.labelText}>City<span className={style.mandatory}>*</span></label>
                                    <input type='text' className={style.inputFld} id="shipping_city" name="shipping_city" onChange={inputHandler} value={selectedMACDShipping.SHIPPING_CITY}></input>
                                </div>
                                <div className={` col-12 col-sm-6 col-md-4 col-lg-4 ${style.colGrp}`}>
                                    <label className={style.labelText}>State<span className={style.mandatory}>*</span></label>
                                    <select className={style.selectFld} id="shipping_state" name="shipping_state" onChange={inputHandler} value={selectedMACDShipping.SHIPPING_STATE}>
                                        {stateList?.map((obj) => {
                                            return <option value={obj.id}>{obj.name}</option>
                                        })}
                                    </select>
                                </div>
                                <div className={` col-12 col-sm-6 col-md-4 col-lg-4 ${style.colGrp}`}>
                                    <label className={style.labelText}>Zip<span className={style.mandatory}>*</span></label>
                                    <input type='text' className={style.inputFld} id="shipping_zip" name="shipping_zip" onChange={inputHandler} value={selectedMACDShipping.SHIPPING_ZIP}></input>
                                </div>
                            </div>
                            <div className={` row ${style.rowGrp}`}>
                                <div className={` col-12 col-sm-6 col-md-4 col-lg-4 ${style.colGrp}`}>
                                    <label className={style.labelText}>Ship To Contact<span className={style.mandatory}>*</span></label>
                                    {selectedMACDShipping.SHIPPING_CONTACT === "new" ?
                                        <input type='text' className={style.inputFld} id="shipping_contact_new" name="shipping_contact_new" onChange={inputHandler} value={selectedMACDShipping.SHIPPING_CONTACT_NEW}></input> :
                                        <select className={style.selectFld} id="shipping_contact" name="shipping_contact" onChange={inputHandler} value={selectedMACDShipping.SHIPPING_CONTACT}>
                                            <option value=""></option>
                                            {contactOptions?.map((obj) => {
                                                return <option value={obj.id}>{obj.name}</option>
                                            })}
                                        </select>
                                    }
                                </div>
                                <div className={` col-12 col-sm-6 col-md-4 col-lg-4 ${style.colGrp}`}>
                                    <label className={style.labelText}>Ship to Phone<span className={style.mandatory}>*</span></label>
                                    <input type='text' className={style.inputFld}  maxLength='10' id="shipping_phone" name="shipping_phone" onChange={inputHandler} value={selectedMACDShipping.SHIPPING_PHONE}></input>
                                </div>
                                <div className={` col-12 col-sm-6 col-md-4 col-lg-4 ${style.colGrp}`}>
                                    <label className={style.labelText}>Ship to Email<span className={style.mandatory}>*</span></label>
                                    <input type='text' className={style.inputFld} id="shipping_email" name="shipping_email" onChange={inputHandler} value={selectedMACDShipping.SHIPPING_EMAIL}></input>
                                </div>
                            </div>
                        </div>
                    </div>
                    </> : null
                }
            </>)}
        </>
    )
}


const mapDispatchToProps = {
    A_LATITUDE: setMACDALatitude,
    A_LONGITUDE: setMACDALongitude,
    A_COLO_POP: setMACDAColoPop,
    A_ADDRESS: setMACDAAddress,
    A_ADDRESS2: setMACDAAddress2,
    A_CITY: setMACDACity,
    A_STATE: setMACDAState,
    A_ZIP: setMACDAZip,
    A_FLOOR: setMACDAFloor,
    A_ONSITE_NAME_LCON: setMACDAOnsiteName,
    A_ONSITE_NUMBER: setMACDAOnsitePhone,
    A_ONSITE_EMAIL: setMACDAOnsiteEmail,
    A_FRAMING: setMACDAFraming,
    A_HANDOFF: setMACDAHandoff,
    A_LINE_CODE: setMACDALineCode,
    A_FIBER_CONNECTOR: setMACDAFiberConnector,
    CONNECTOR_TYPE: setMACDConnectorType,
    LIVE_EQUIPMENT: setMACDLiveEquipment,
    RACK_INFO: setMACDRackInfo,
    PANEL_INFO: setMACDPanelInfo,
    PORT_FIBER_ASSIGNMENT: setMACDPortFiberAssignment,


    Z_LATITUDE: setMACDZLatitude,
    Z_LONGITUDE: setMACDZLongitude,
    Z_ADDRESS: setMACDZAddress,
    Z_ADDRESS2: setMACDZAddress2,
    Z_CITY: setMACDZCity,
    Z_STATE: setMACDZState,
    Z_ZIP: setMACDZZip,
    Z_FLOOR: setMACDZFloor,
    Z_ONSITE_NAME_LCON: setMACDZOnsiteName,
    Z_ONSITE_NUMBER: setMACDZOnsitePhone,
    Z_ONSITE_EMAIL: setMACDZOnsiteEmail,
    Z_FRAMING: setMACDZFraming,
    Z_HANDOFF: setMACDZHandoff,
    Z_LINE_CODE: setMACDZLineCode,
    Z_FIBER_CONNECTOR: setMACDZFiberConnector,
    SHIPPING_ADDRESS: setMACDShippingAddress,
    SHIPPING_LD1: setMACDShippingLD1,
    SHIPPING_LV1: setMACDShippingLV1,
    SHIPPING_LV2: setMACDShippingLV2,
    SHIPPING_LD3: setMACDShippingLD3,
    SHIPPING_LV3: setMACDShippingLV3,
    SHIPPING_CITY: setMACDShippingCity,
    SHIPPING_STATE: setMACDShippingState,
    SHIPPING_ZIP: setMACDShippingZip,
    SHIPPING_CONTACT: setMACDShippingContact,
    SHIPPING_CONTACT_NEW: setMACDShippingContactNew,
    SHIPPING_PHONE: setMACDShippingPhone,
    SHIPPING_EMAIL: setMACDShippingEmail
}

export default connect(null, mapDispatchToProps)(MACDSiteInfo);
