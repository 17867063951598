import {useDispatch} from 'react-redux';
import {useHistory} from "react-router-dom";
import {execute404Handler, execute500Handler, executeOtherErrorHandler} from '../../redux/actions/errorHandlerActions'

const HTTPErrorHandler = ({ error }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    if (error.response) {
        switch (error.response.status) {
            case 404:
                dispatch(execute404Handler(history));
                break;
            case 500:
                dispatch(execute500Handler(history));
                break;
            default:
                dispatch(executeOtherErrorHandler(error.response.message))
                break;
        }
    }
    return null
}
export default HTTPErrorHandler;
