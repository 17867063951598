import {ConsoleLogger} from "../logger/ConsoleLogger";

const logger = new ConsoleLogger({level: process.env.REACT_APP_LOGGER_LEVEL,});

export class PendoUser {
    constructor(data) {
        this.userLogin = data.pendo.userlogin || "";
        this.userFullName = data.pendo.userfullname || "";
        this.role = {
            web: data.pendo.role.web || "",
            tickets: data.pendo.role.tickets || "",
            quote: data.pendo.role.quote || "",
            order: data.pendo.role.order || "",
            invoice: data.pendo.role.invoice || "",
            profile: data.pendo.role.profile || "",
            bulkav: data.pendo.role.bulkav || ""
        };
        this.email = data.pendo.email || "";
        this.name = data.pendo.name || "";
        this.mpid = data.pendo.mpid || "";
        this.commsGlobalAccountId = data.pendo.commsglobalaccountid || "";
        this.globalAccountId = data.pendo.globalaccountid || "";
        this.partnerType = {
            vertical: "Vertical: " + data.pendo.partnertype.vertical || "",
            ae: "AE: " + data.pendo.partnertype.ae || "",
            cam: "CAM: " + data.pendo.partnertype.cam || "",
            type: "Type: " + data.pendo.partnertype.type || ""
        };
    }

    displayUserInfo() {
        logger.info(`User Login: ${this.userLogin}`);
        logger.info(`User Full Name: ${this.userFullName}`);
        logger.info(`Email: ${this.email}`);
        logger.info(`Role: ${JSON.stringify(this.role, null, 2)}`);
        logger.info(`Name: ${this.name}`);
        logger.info(`MPID: ${this.mpid}`);
        logger.info(`Comms Global Account ID: ${this.commsGlobalAccountId}`);
        logger.info(`Global Account ID: ${this.globalAccountId}`);
        logger.info(`Partner Type: ${JSON.stringify(this.partnerType, null, 2)}`);
    }
}