import { useEffect, useRef, useState } from 'react';
import ViewportWarning from "../shared/ViewportWarning";
import TabsQuote from "./includes/TabsQuote";
import style from "./QuoteCreate.module.css";
import MplsNNI from "./MPLSnni";
import QuoteContacts from "./QuoteContacts";
import QuoteSubmit from './QuoteSubmit';
import { connect, useSelector } from 'react-redux';
import EAccessNNI from './EAccessNNI'
import Internet from './Internet';
import Colocation from './Colocation';
import MPLSAggTail from './MPLSAggTail';
import MPLSInternet from './MPLSInternet';
import DIAPremBroadband from './DIAPremBroadband';
import Wave from './Wave';
import ELINE from './ELINE';
import EAccess from './EAccess';
import DarkFiber from './DarkFiber';

import {
	resetData,
	setQuoteCircuitType,
	setQuoteCOS,
	setQuoteDfOptions,
	setQuoteProduct,
	setQuoteProductCode,
	setQuoteStepCode,
} from '../../redux/actions/quoteActions';

import { resetInternalQuote } from '../../redux/actions/internalActions';
import { setSessionError } from '../../redux/actions/errorHandlerActions';
import { ConsoleLogger } from "../../logger/ConsoleLogger";
import HTTPErrorHandler from "../errorPages/HTTPErrorHandler";
import QuoteInternal from './QuoteInternal';
import CompanyDropdown from '../shared/CompanyDropdown';
import ManagedSpectrum from './ManagedSpectrum';
import Broadband from './Broadband';
import BroadbandHelper from '../../services/BroadbandServices';
import OfficeSuite from './OfficeSuite';

const circuitTypeList = [
	{ id: 1, name: 'Ethernet', cid: 1, code: 'ETHERNET', dbId: 6, selected: false },
	{ id: 2, name: 'Broadband', cid: 2, code: 'BROADBAND', dbId: 14, selected: false },
]

const classOfServiceList = [
	{ id: 1, name: 'YES', code: 'YES', value: 1, selected: false },
	{ id: 2, name: 'NO', code: 'NO', value: 0, selected: false }
]

const productList = [
	{ "productName": "Wave", "code": "WAVE" },
	{ "productName": "Internet", "code": "INTERNET" },
	{ "productName": "MPLS AGG Tail", "code": "MPLS_A_TAIL" },
	{ "productName": "MPLS + Internet", "code": "MPLS_INTERNET" },
	{ "productName": "MPLS NNI", "code": "MPLS_NNI" },
	{ "productName": "E-Line", "code": "E_LINE" },
	{ "productName": "E-Access", "code": "E_ACCESS" },
	{ "productName": "E-Access NNI", "code": "E_ACCESS_NNI" },
	{ "productName": "Colocation", "code": "COLOCATION" }
];

const QuoteCreate = (props) => {
	const logger = new ConsoleLogger({ level: process.env.REACT_APP_LOGGER_LEVEL, });
	const [hasError, setHasError] = useState(null);
	const nextBtnRef = useRef(null);
	const [stepCode, setStepCode] = useState(1);
	const [selectedProducts, setSelectedProducts] = useState(undefined);
	const [selectedIcon, setSelectedIcon] = useState(undefined);
	const nextStepText = ['Product Selection', 'Location Information', 'Product Configuration', 'Review & Create Quote', 'Submit'];
	const [errMsg, setErrMsg] = useState('');
	const [successMsg, setSuccessMsg] = useState('');
	const [popupShow, setPopupShow] = useState(false);
	const [popupMsg, setPopupMsg] = useState('');
	const [popupOkBtn, setPopupOkBtn] = useState(false);
	const [popupCancelBtn, setPopupCancelBtn] = useState(false);
	const [popupAction, setPopupAction] = useState('');
	const [popupSize, setPopupSize] = useState('');
	const [enableNextBtn, setEnableNextBtn] = useState(false);
	const [products, setProducts] = useState(productList);
	const [circuitType, setCircuitType] = useState(circuitTypeList);
	const [classOfServiceType, setClassOfServiceType] = useState(classOfServiceList);
	const [selectedClassOfService, setSelectedClassOfService] = useState('');
	const [selectedCircuitType, setSelectedCircuitType] = useState('');
	const [savedQuotePopup, setSavedQuotePopup] = useState(false);
	const [ilecBroadbandRedirect, setIlecBroadbandRedirect] = useState(false);
	const selectedQuoteValues = useSelector(state => state.quote.quoteValues);
	const selectedLocAValues = useSelector(state => state.quoteLocA.locA);
	const selectedLocZValues = useSelector(state => state.quoteLocZ.locZ);
	const selectedContactDetails = useSelector(state => state.quoteContact.contactValues);
	const selectedSessionError = useSelector(state => state.errorReducer.error.sessionErrorMessage);
	const selectedInternalMpid = useSelector(state => state.internal.company);
	const loggedInMpid = useSelector(state => state.userReducer?.user?.pendo?.mpid);
	const selectedInternalQuoteValues = useSelector(state => state.internal.quote);
	const isEmployee = localStorage.getItem('isEmployeeLoggedIn');
	const isCustomer = localStorage.getItem('isLoggedIn');
	const [showShippingAlert, setShowShippingAlert] = useState(false); // OfficeSuite
	const localURL = "iconnect-local.windstreamwholesale.com";
	const devURL = "iconnect-dev.windstreamwholesale.com";
	const uatURL = "iconnect-uat.windstreamwholesale.com";
	const productsToRemove = ["BROADBAND", "MANAGED_SPECTRUM", "OFFICESUITE", "DARKFIBER"]; // Dynamic Products
	const [dfOptions, setDfOptions] = useState([]);

	useEffect(() => {
		if (showShippingAlert) {
			alert("Shipping and Handling charges will be included in the notes section of the quote.");
		}
	}, [showShippingAlert]);

	useEffect(() => {
		setProducts(currentProducts =>
			currentProducts.filter(product => !productsToRemove.includes(product.code))
		);
		if (isCustomer || (isEmployee && selectedInternalMpid)) {
			BroadbandHelper.broadbandProductCheck().then(response => {
				logger.info(`Broadband Product Check: ${(response.data)}`);
				if (response.data) {
					if (isCustomer) {
						if (loggedInMpid === "9208" || loggedInMpid === "4718") { // 9208 T-Mobile
							setProducts(currentProducts => [
								...currentProducts,
								{ "productName": "Broadband", "code": "BROADBAND" },
								{ "productName": "OfficeSuite White Label", "code": "OFFICESUITE" }
							]);
						} else {
							setProducts([...products, { "productName": "Broadband", "code": "BROADBAND" }]);
						}
					} else if (isEmployee) {
						setProducts(currentProducts => [
							...currentProducts,
							{ "productName": "Broadband", "code": "BROADBAND" },
							{ "productName": "OfficeSuite White Label", "code": "OFFICESUITE" },
							{ "productName": "Managed Spectrum", "code": "MANAGED_SPECTRUM" },
							{ "productName": "Dark Fiber", "code": "DARKFIBER" }
						]);

					}
				} else { // No Broadband
					if (isCustomer) {
						if (loggedInMpid === "7083" || loggedInMpid === "4718") { // 7083 T-Mobile
							setProducts(currentProducts => [
								...currentProducts,
								{ "productName": "OfficeSuite White Label", "code": "OFFICESUITE" },
								{ "productName": "Managed Spectrum", "code": "MANAGED_SPECTRUM" }
							]);
						} else {
							setProducts(currentProducts => [
								...currentProducts,
								{ "productName": "Managed Spectrum", "code": "MANAGED_SPECTRUM" },
							]);
						}
					} else {
						setProducts(currentProducts => [
							...currentProducts,
							{ "productName": "OfficeSuite White Label", "code": "OFFICESUITE" },
							{ "productName": "Managed Spectrum", "code": "MANAGED_SPECTRUM" },
							{ "productName": "Dark Fiber", "code": "DARKFIBER" }
						]);
					}
				}
			}).catch(error => {
				logger.error(`Broadband Product Check error: ${(error)}`);
			})
		}
	}, [selectedInternalMpid])

	useEffect(() => {
		if (selectedQuoteValues.product && selectedQuoteValues.cloned === "" && (!isEmployee && !selectedInternalMpid)) {
			setSavedQuotePopup(true);
		}
		if (selectedQuoteValues.cloned?.length > 0 || selectedQuoteValues.availability) {
			if (selectedQuoteValues.cloned?.length > 0) {
				logger.info("This quote was cloned");
			}
			if (selectedQuoteValues.availability) {
				logger.info("This quote was started from the Wave Availability tool");
			}
			continueQuote();
		}
		if (selectedSessionError) {
			setPopupShow(true);
			setPopupOkBtn(true);
			setPopupMsg(selectedSessionError);
		}
	}, []);

	useEffect(() => {
		if (selectedProducts !== undefined && !selectedQuoteValues?.availability) {
			nextBtnRef.current?.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
		}
	}, [selectedProducts, selectedQuoteValues?.availability]);

	useEffect(() => {
		setErrMsg('');
	}, [selectedQuoteValues, selectedLocAValues, selectedLocZValues, selectedContactDetails, selectedInternalQuoteValues, selectedInternalMpid])

	useEffect(() => {
		//only clear quote data on step 1 if the internal user changes mpid on step 1
		//Adding this condition to prevent issues with Quote Cloning
		if (selectedQuoteValues.step === 1) {
			props.clearQuoteData();
			setSelectedProducts(undefined);
			setSelectedIcon(undefined);
		}
	}, [props, selectedInternalMpid]);

	const shippingHandlingFeature = (item_type) => {
		switch (item_type) {
			case 59: //Purchase of Mitel 6900 DECT Headset
			case 60: //Purchase of Mitel 6900 Bluetooth Handset
			case 61: //Purchase of Mitel M695 PKM (color)
			case 62: //Purchase of Mitel 6900 wall mount bracket
			case 64: //Purchase of Power over Ethernet Adapter
			case 69: //Purchase of VVX OBi Wi-Fi dongle
			case 70: //Purchase of Slim (5304) Phone
			case 74: //Purchased Advanced Cordless handset (BVN 8630)
			case 75: //Purchased Advanced Cordless base (BVN 9430)
			case 76: //Purchase of 18-Key Color (6920) Phone
			case 77: //Purchase of 72-Key Color (6930) Phone
			case 78: //Purchase of 96-Key Color Touch (6940) Phone
			case 86: //Purchase of 2-line LCD (VVX150) phone
			case 87: //Purchase of 4-line Color GigE (VVX250) phone
			case 88: //Purchase of 6-line Color GigE (VVX350) phone
			case 89: //Purchase of 12-line Color GigE (VVX450) phone
			case 90: //Purchase of Conference Set (6000) Power over Ethernet Adapter
			case 91: //Purchase of Conference Set (6000) Ext. Mic
			case 92: //Purchase of Conference Set (6000)
			case 93: //Purchase of 96-Key Color Touchscreen Conference (6970) Phone
			case 94: //VVX EM50 Expansion Module
			case 127: //Purchase of Engenius Durafon PRO Cordless Base Unit
			case 128: //Purchase of Engenius Durafon PRO Cordless Handset
			case 129: //Purchase of Engenius Durafon PRO Cordless Phone System
			case 130: //Purchase of Engenius Durafon SIP Cordless Base Unit
			case 131: //Purchase of Engenius Durafon SIP Cordless Handset
			case 132: //Purchase of Engenius Durafon SIP Cordless Phone System
			case 150: //Rental of 18-Key Color (6920) Phone
			case 151: //Rental of 72-Key Color (6930) Phone
			case 152: //Rental of 96-Key Color Touch (6940) Phone
			case 153: //Rental of Mitel M695 PKM (color)
			case 154: //Rental of Conference Set (6000)
			case 157: //Rental of VVX Color Exp Mod
			case 158: //Rental of 2-line LCD (VVX150) phone
			case 159: //Rental of 4-line Color GigE (VVX250) phone
			case 160: //Rental of 6-line Color GigE (VVX350) phone
			case 161: //Rental of 12-line Color GigE (VVX450) phone
			case 162: //Rental of Adv. Cordless Handset BVN8630
			case 164: //Rental of Adv. Cordless Base BVN9430
			case 165: //Rental of Adv. Cordless Repeater (BVN 4024)
			case 166: //Rental of Engenius Durafon PRO Cordless System
			case 169: //Rental of Engenius Durafon SIP Cordless System (Bundle)
			case 167: //Rental of EnGenius DuraFon PRO Cordless Handset
			case 168: //Rental of EnGenius DuraFon PRO Cordless Base Unit
			case 170: //Rental of Additional DuraFon SIP Cordless Handset
			case 171: //Rental of Additional DuraFon SIP Cordless Base
			case 172: //Rental of Slim (5304) Phone
			case 173: //Rental of 96-Key Color Touchscreen Conference (6970) Phone
			case 179: //Rental of Door Box Controller
			case 180: //Purchase of Door Box Controller
			case 181: //Purchase of Door Box Controller Interface
			case 182: //Rental of Door Box Controller Interface
			case 184: //Rental of Overhead Paging Interface (Algo 8301)
			case 185: //Rental of Overhead Paging Interface (Patton)
			case 186: //Rental of Overhead Paging Interface Amplifier (Bogen TAM-B)
			case 187: //Rental of Overhead Paging Interface 2-port ATA
			case 188: //Purchase of Overhead Paging Interface (Algo 8301)
			case 189: //Purchase of Overhead Paging Interface (Patton)
			case 190: //Purchase of of Overhead Paging Interface Amplifier (Bogen TAM-B)
			case 191: //Purchase of Overhead Paging Interface 2-port ATA
			case 192: //Rental of Door Box - Style 1a - Flush Mount, Black
			case 193: //Rental of Door Box - Style 1b - Flush Mount, Brushed Steel
			case 194: //Rental of Door Box - Style 2a - Surface Mount, White Plastic
			case 195: //Rental of Door Box - Style 2b - Surface Mount, Vandal Resistant
			case 196: //Rental of Door Box - Style 3a - Handset w/ Steel Cable
			case 197: //Rental of Door Box - Style 3b - Handset w/ Steel Cable
			case 198: //Rental of Door Box - Style 4a - Flush Single, Satin Black Style
			case 199: //Rental of Door Box - Style 4b - Flush Single, Oil Rubbed Bronze
			case 200: //Rental of Door Box - Style 4c - Flush Single, Polished Brass
			case 201: //Rental of Door Box - Style 4d - Flush Single, Stainless Steel
			case 202: //Rental of Door Box - Style 4e - Flush Single, Satin White
			case 203: //Rental of Door Box - Style K-1700-3 - Flush Mount with Keypad, Brushed Stainless Steel
			case 204: //Rental of Door Box - Style K-1700-3 bn - Flush Mount with Keypad, Bronze
			case 205: //Purchase of Door Box - Style 1a - Flush Mount, Black
			case 206: //Purchase of Door Box - Style 1b - Flush Mount, Brushed Steel
			case 207: //Purchase of Door Box - Style 2a - Surface Mount, White Plastic
			case 208: //Purchase of Door Box - Style 2b - Surface Mount, Vandal Resistant
			case 209: //Purchase of Door Box - Style 3a - Handset w/ Steel Cable
			case 210: //Purchase of Door Box - Style 3b - Handset w/ Steel Cable
			case 211: //Purchase of Door Box - Style 4a - Flush Single, Satin Black Style
			case 212: //Purchase of Door Box - Style 4b - Flush Single, Oil Rubbed Bronze
			case 213: //Purchase of Door Box - Style 4c - Flush Single, Polished Brass
			case 214: //Purchase of Door Box - Style 4d - Flush Single, Stainless Steel
			case 215: //Purchase of Door Box - Style 4e - Flush Single, Satin White
			case 216: //Purchase of Door Box - Style K-1700-3 - Flush Mount with Keypad, Brushed Stainless Steel
			case 217: //Purchase of Door Box - Style K-1700-3 bn - Flush Mount with Keypad, Bronze
				return 'shipping';
			default:
				break;
		}
		return;
	}

	const productSelect = (id) => {
		setSelectedIcon(id);
		props.updateQuoteProductCode(id);
		setErrMsg('');
		setErrMsg('');
		setSelectedCircuitType('');
		switch (id) {
			case 'MPLS_NNI':
				setSelectedProducts(id);
				props.updateQuoteProduct(7);
				props.updateQuoteCircuitType(6);
				break;
			case 'E_ACCESS_NNI':
				setSelectedProducts(id);
				props.updateQuoteProduct(22);
				props.updateQuoteCircuitType(6);
				break;
			case 'COLOCATION':
				setSelectedProducts(id);
				props.updateQuoteProduct(15);
				props.updateQuoteCircuitType(0);
				break;
			case 'MPLS_A_TAIL':
				setSelectedProducts(id);
				props.updateQuoteProduct(6);
				props.updateQuoteCircuitType(6);
				break;
			case 'MPLS_INTERNET':
				setSelectedProducts(id);
				props.updateQuoteProduct(13);
				props.updateQuoteCircuitType(6);
				break;
			case 'WAVE':
				setSelectedProducts(id);
				props.updateQuoteProduct(1);//Private Line DB ID
				props.updateQuoteCircuitType(7); //WAVE DB ID;
				break;
			case 'E_LINE':
				setSelectedProducts(id);
				props.updateQuoteProduct(19)
				break;
			case 'E_ACCESS':
				setSelectedProducts(id);
				props.updateQuoteProduct(20)
				break;
			case 'MANAGED_SPECTRUM':
				setSelectedProducts(id);
				props.updateQuoteProduct(35);
				props.updateQuoteCircuitType(16);//Fixed Spectrum
				break;
			case 'BROADBAND':
				setSelectedProducts(id);
				props.updateQuoteProduct(23);
				props.updateQuoteCircuitType(13);
				break;
			case 'OFFICESUITE':
				setSelectedProducts(id);
				props.updateQuoteProduct(29);
				break;
			case 'DARKFIBER':
				setSelectedProducts(id);
				props.updateQuoteProduct(32);
				props.updateQuoteCircuitType(0);
				break;
			default:
				break;
		}
	}

	const prevPage = () => {
		if (stepCode > 0) {
			setStepCode(stepCode - 1)
			props.updateQuoteStepCode(stepCode - 1);
		}
	}

	/*Validation function for Internal Quotes*/
	const internalValidation = (step) => {
		switch (step) {
			case 1:
				if (!selectedInternalMpid?.value) {
					setEnableNextBtn(false);
					setErrMsg("Please select a company");
					return;
				} else {
					setEnableNextBtn(true);
					finalValidation();
				}
				break
			case 4:
				//We need to override this check if coming from the route creator
				if ((Number(selectedQuoteValues.bw) >= 600 || Number(selectedQuoteValues.bw1) >= 600 || Number(selectedQuoteValues.bw2) >= 600) && selectedQuoteValues.product_code === "WAVE" && selectedContactDetails.mrcPrice === "" && selectedQuoteValues.iconFlag === "") {
					setEnableNextBtn(false);
					setErrMsg("Please provide a Target MRC Price to expedite strategic ICB pricing process.");
					return;
				} else if (selectedInternalQuoteValues.renewalQuote) {
					if (!selectedInternalQuoteValues.existingCircuitID || !selectedInternalQuoteValues.endTermDate ||
						!selectedInternalQuoteValues.legacyNetwork || !selectedInternalQuoteValues.billingSystem || !selectedInternalQuoteValues.offnet) {
						setEnableNextBtn(false);
						setErrMsg("Please verify all required renewal quote fields are completed.");
						return;
					} else {
						setEnableNextBtn(true);
						setErrMsg("");
						setSuccessMsg("Submitting Internal Quote...");
					}
				} else if (selectedInternalQuoteValues.agent) {
					if (!selectedInternalQuoteValues.agentName || !selectedInternalQuoteValues.agentPhone || !selectedInternalQuoteValues.agentFee || !selectedInternalQuoteValues.agentEmail) {
						setEnableNextBtn(false);
						setErrMsg("Please verify all required agent information fields are completed.");
						return;
					} else {
						setEnableNextBtn(true);
						setErrMsg("");
						setSuccessMsg("Submitting Internal Quote...");
					}
				} else if (selectedInternalQuoteValues.enterpriseRep) {
					if (!selectedInternalQuoteValues.enterpriseRepObj?.enterprise_name || !selectedInternalQuoteValues.enterpriseRepObj?.enterprise_email) {
						setEnableNextBtn(false);
						setErrMsg("Please verify all required Enterprise Rep fields are completed");
					} else {
						setEnableNextBtn(true);
						setErrMsg("");
						setSuccessMsg("Submitting Internal Quote...");
					}
				} else {
					setEnableNextBtn(true);
					setErrMsg("");
					setSuccessMsg("Submitting Internal Quote...");
				}
				break;
			default:
				break;

		}
	}

	const validationStep1 = () => {
		if (selectedProducts === undefined) {
			setEnableNextBtn(false);
			setErrMsg('Please select a product to continue');
			return;
		} else if (selectedProducts === "E_LINE") {
			if (selectedQuoteValues.cosneeded === "") {
				setEnableNextBtn(false);
				setErrMsg('Please select if class of service is needed to continue');
				return;
			} else if (isEmployee) {
				internalValidation(1);
			} else {
				setEnableNextBtn(true);
				finalValidation();
			}
		} else if (selectedProducts === "INTERNET") {
			if (selectedQuoteValues.circuit === "") {
				setEnableNextBtn(false);
				setErrMsg('Please select a circuit type to continue');
				return;
			} else if (isEmployee) {
				internalValidation(1);
			} else {
				setEnableNextBtn(true);
				finalValidation();
			}
		} else if (isEmployee) {
			internalValidation(1);
		} else {
			setEnableNextBtn(true);
			finalValidation();
		}
		logger.info(enableNextBtn);
	}

	const validationStep2 = () => {
		setErrMsg("");
		switch (selectedProducts) {
			case "MPLS_NNI":
			case "E_ACCESS_NNI":
			case "INTERNET":
			case "COLOCATION":
				if (selectedQuoteValues.nniLoc === "" && selectedQuoteValues.popLoc === "") {
					setErrMsg("Location A is required");
					setEnableNextBtn(false);
					return;
				} else if (selectedQuoteValues.nniLoc.value === 99 || selectedQuoteValues.popLoc.value === 99 || selectedQuoteValues.popLoc.value === "Other") {
					if (selectedLocAValues.a_validationFlag === false) {
						setErrMsg("Location A Address Must be Validated");
						setEnableNextBtn(false);
						return;
					}

				} else if (selectedQuoteValues.enniExtension === true) {
					if (selectedLocZValues.z_validationFlag === false) {
						setErrMsg("Location Z Address Must be Validated");
						setEnableNextBtn(false);
						return;
					}
				} else {
					setErrMsg("");
					setEnableNextBtn(true);
					finalValidation();
				}
				break;
			case "MPLS_A_TAIL":
			case "MPLS_INTERNET":
			case "BROADBAND":
			case "OFFICESUITE":
				if (selectedLocAValues.a_validationFlag === false) {
					setErrMsg("Location A Address Must be Validated");
					setEnableNextBtn(false);
					return;
				} else {
					setErrMsg("");
					setEnableNextBtn(true);
					finalValidation();
				}
				break;
			case "WAVE":
			case "E_LINE":
			case "MANAGED_SPECTRUM":
				if (selectedQuoteValues.coAddA === "" || selectedQuoteValues.coAddZ === "") {
					setErrMsg("Location A and Z is required");
					setEnableNextBtn(false);
					return;
				} else if (selectedQuoteValues.coAddA.value === "Other") {
					if (selectedLocAValues.a_validationFlag === false) {
						setErrMsg("Location A and Z Address Must be Validated");
						setEnableNextBtn(false);
						return;
					}
				} else if (selectedQuoteValues.coAddZ.value === "Other") {
					if (selectedLocZValues.z_validationFlag === false) {
						setErrMsg("Location A and Z Address Must be Validated");
						setEnableNextBtn(false);
						return;
					}
				} else {
					setErrMsg("");
					setEnableNextBtn(true);
					finalValidation();
				}
				break;
			case "E_ACCESS":
				if (selectedLocAValues.eaccessValidation.valid === false || Object.keys(selectedLocAValues.eaccessValidation).length === 0) {
					setErrMsg("Location A Must be Validated");
					setEnableNextBtn(false);
					return;
				} else {
					setErrMsg("");
					setEnableNextBtn(true);
					finalValidation();
				}
				break;
			case "DARKFIBER":
				if (selectedLocAValues.a_validationFlag === false) {
					setErrMsg("Location A and Z Address Must be Validated");
					setEnableNextBtn(false);
					return;
				} else if (selectedLocZValues.z_validationFlag === false) {
					setErrMsg("Location A and Z Address Must be Validated");
					setEnableNextBtn(false);
					return;
				} else {
					setErrMsg("");
					setEnableNextBtn(true);
					finalValidation();
				}
				break;
			case "DIA_PREM_BROADBAND":
				//Broadband validation
				if (selectedQuoteValues.circuit === 14) {
					if (!selectedQuoteValues.term1 && !selectedQuoteValues.term2 && !selectedQuoteValues.term3 && !selectedQuoteValues.term4 && !selectedQuoteValues.term5) {
						setErrMsg("Please verify all required fields are completed.");
						setEnableNextBtn(false);
						return;
					} else if (!selectedQuoteValues.ips) {
						setErrMsg("Please verify all required fields are completed.");
						setEnableNextBtn(false);
						return;
					} else if (selectedQuoteValues.adsl === false && selectedQuoteValues.cable === false) {
						setErrMsg("A valid access type is required");
						setEnableNextBtn(false);
						return;
					} else if (selectedLocAValues.a_validationFlag === false) {
						setErrMsg("Location A Address Must be Validated");
						setEnableNextBtn(false);
						return;
					} else {
						setErrMsg("");
						setEnableNextBtn(true);
						finalValidation();
					}
				}
				break;
			default:
				break;

		}
		setErrMsg("");
		setEnableNextBtn(true);
		finalValidation();

	}

	const validationStep3 = () => {
		setErrMsg("");
		switch (selectedProducts) {
			case "MPLS_NNI":
			case "E_ACCESS_NNI":
				if (selectedQuoteValues.bw === "") {
					setErrMsg("Please verify all required fields are completed.");
					setEnableNextBtn(false);
					return;
				} else if (!selectedQuoteValues.term1 && !selectedQuoteValues.term2 && !selectedQuoteValues.term3 && !selectedQuoteValues.term4 && !selectedQuoteValues.term5) {
					setErrMsg("Please verify all required fields are completed.");
					setEnableNextBtn(false);
					return;
				} else {
					setErrMsg("");
					setEnableNextBtn(true);
					finalValidation();
				}
				break;
			case "INTERNET":
				if (selectedQuoteValues.bw === "") {
					setErrMsg("Please verify all required fields are completed.");
					setEnableNextBtn(false);
					return;
				} else if (!selectedQuoteValues.term1 && !selectedQuoteValues.term2 && !selectedQuoteValues.term3 && !selectedQuoteValues.term4 && !selectedQuoteValues.term5) {
					setErrMsg("Please verify all required fields are completed.");
					setEnableNextBtn(false);
					return;
				} else if (!selectedQuoteValues.qty || !selectedQuoteValues.ips) { //ADO-833379 Remove DDOS Mitigation
					setErrMsg("Please verify all required fields are completed.");
					setEnableNextBtn(false);
					return;
				} else if (selectedQuoteValues.cpe_type === "" && selectedQuoteValues.bw <= 505 && selectedLocAValues.a_lec_type !== "ILEC") {
					setErrMsg("Please verify all required fields are completed.");
					setEnableNextBtn(false);
					return;
					//ADO-833379 Remove DDOS Mitigation
					// } else if (selectedQuoteValues.ddos === "1") {
					// 	if (!selectedQuoteValues.ddos_type) {
					// 		setErrMsg("Please verify all required fields are completed.");
					// 		setEnableNextBtn(false);
					// 		return;
					// 	}
				} else {
					setErrMsg("");
					setEnableNextBtn(true);
					finalValidation();
				}
				break;
			case "COLOCATION":
				if (!selectedQuoteValues.term1 && !selectedQuoteValues.term2 && !selectedQuoteValues.term3 && !selectedQuoteValues.term4 && !selectedQuoteValues.term5) {
					setErrMsg("Please verify all required fields are completed.");
					setEnableNextBtn(false);
					return;
				} else if (selectedQuoteValues.cross_connect === "0") {
					if (!selectedQuoteValues.rack_space || !selectedQuoteValues.breaker_amp) {
						setErrMsg("Please verify all required fields are completed.");
						setEnableNextBtn(false);
						return;
					}
				} else {
					setErrMsg("");
					setEnableNextBtn(true);
					finalValidation();
				}
				break;
			case "MPLS_A_TAIL":
			case "MPLS_INTERNET":
				if (selectedQuoteValues.bw === "") {
					setErrMsg("Please verify all required fields are completed.");
					setEnableNextBtn(false);
					return;
				} else if (!selectedQuoteValues.term1 && !selectedQuoteValues.term2 && !selectedQuoteValues.term3 && !selectedQuoteValues.term4 && !selectedQuoteValues.term5) {
					setErrMsg("Please verify all required fields are completed.");
					setEnableNextBtn(false);
					return;
				} else if ((!selectedQuoteValues.cpe_type || !selectedQuoteValues.router_service) && selectedLocAValues.a_lec_type !== "ILEC") {
					setErrMsg("Please verify all required fields are completed.");
					setEnableNextBtn(false);
					return;
				}
				//ADO-833379 Remove DDOS Mitigation
				//else if (selectedProducts === "MPLS_INTERNET" && !selectedQuoteValues.ddos === "") {
				// 	setErrMsg("Please verify all required fields are completed.");
				// 	setEnableNextBtn(false);
				// 	return;
				//
				else {
					setErrMsg("");
					setEnableNextBtn(true);
					finalValidation();
				}
				break;
			case "WAVE":
				if (!selectedQuoteValues.term1 && !selectedQuoteValues.term2 && !selectedQuoteValues.term3 && !selectedQuoteValues.term4 && !selectedQuoteValues.term5) {
					setErrMsg("Please verify all required fields are completed.");
					setEnableNextBtn(false);
					return;
				} else if (selectedQuoteValues.bw === "" || selectedQuoteValues.protocol1 === "") {
					setErrMsg("Please verify all required fields are completed.");
					setEnableNextBtn(false);
					return;
				} else if (selectedQuoteValues.bw1 !== "" && selectedQuoteValues.protocol2 === "") {
					setErrMsg("Please verify all required fields are completed.");
					setEnableNextBtn(false);
					return;
				} else if (selectedQuoteValues.bw2 !== "" && selectedQuoteValues.protocol3 === "") {
					setErrMsg("Please verify all required fields are completed.");
					setEnableNextBtn(false);
					return;
				} else if (selectedQuoteValues.router_type === "" || selectedQuoteValues.diversity === "") {
					setErrMsg("Please verify all required fields are completed.");
					setEnableNextBtn(false);
					return;
				} else {
					setErrMsg("");
					setEnableNextBtn(true);
					finalValidation();
				}
				break;
			case "E_LINE":
				if (!selectedQuoteValues.term1 && !selectedQuoteValues.term2 && !selectedQuoteValues.term3 && !selectedQuoteValues.term4 && !selectedQuoteValues.term5) {
					setErrMsg("Please verify all required fields are completed.");
					setEnableNextBtn(false);
					return;
				} else if ((selectedQuoteValues.la_existing_uni === "no" && selectedQuoteValues.lz_existing_uni === "no" && selectedQuoteValues.cosneeded === 1)) {
					if (selectedQuoteValues.cos1 === "" || selectedQuoteValues.a_vpn_port_1 === "" || selectedQuoteValues.z_vpn_port_1 === "") {
						setErrMsg("Please verify all required fields are completed.");
						setEnableNextBtn(false);
						return;
					} else if (selectedQuoteValues.bw1 !== "" && (selectedQuoteValues.cos2 === "" || selectedQuoteValues.a_vpn_port_2 === "" || selectedQuoteValues.z_vpn_port_2 === "")) {
						setErrMsg("Please verify all required fields are completed.");
						setEnableNextBtn(false);
						return;
					} else if (selectedQuoteValues.bw2 !== "" && (selectedQuoteValues.cos3 === "" || selectedQuoteValues.a_vpn_port_3 === "" || selectedQuoteValues.z_vpn_port_3 === "")) {
						setErrMsg("Please verify all required fields are completed.");
						setEnableNextBtn(false);
						return;
					} else {
						setErrMsg("");
						setEnableNextBtn(true);
						finalValidation();
					}
				} else if (selectedQuoteValues.la_existing_uni === "no" && selectedQuoteValues.lz_existing_uni === "no" && selectedQuoteValues.cosneeded === 0) {
					if (selectedQuoteValues.bw === "" || selectedQuoteValues.a_vpn_port_1 === "" || selectedQuoteValues.z_vpn_port_1 === "") {
						setErrMsg("Please verify all required fields are completed.");
						setEnableNextBtn(false);
						return;
					} else if (selectedQuoteValues.bw1 !== "" && (selectedQuoteValues.a_vpn_port_2 === "" || selectedQuoteValues.z_vpn_port_2 === "")) {
						setErrMsg("Please verify all required fields are completed.");
						setEnableNextBtn(false);
						return;
					} else if (selectedQuoteValues.bw2 !== "" && (selectedQuoteValues.a_vpn_port_3 === "" || selectedQuoteValues.z_vpn_port_3 === "")) {
						setErrMsg("Please verify all required fields are completed.");
						setEnableNextBtn(false);
						return;
					} else {
						setErrMsg("");
						setEnableNextBtn(true);
						finalValidation();
					}
				} else if (selectedQuoteValues.la_existing_uni !== "no" && selectedQuoteValues.lz_existing_uni !== "no" && selectedQuoteValues.cosneeded === 0) {
					if (selectedQuoteValues.bw === "") {
						setErrMsg("Please verify all required fields are completed.");
						setEnableNextBtn(false);
						return;
					} else {
						setErrMsg("");
						setEnableNextBtn(true);
						finalValidation();
					}
				} else if (selectedQuoteValues.la_existing_uni !== "no" && selectedQuoteValues.lz_existing_uni !== "no" && selectedQuoteValues.cosneeded === 1) {
					if (selectedQuoteValues.bw === "" || selectedQuoteValues.cos1 === "") {
						setErrMsg("Please verify all required fields are completed.");
						setEnableNextBtn(false);
						return;
					} else if (selectedQuoteValues.bw1 !== "" && selectedQuoteValues.cos2 === "") {
						setErrMsg("Please verify all required fields are completed.");
						setEnableNextBtn(false);
						return;
					} else if (selectedQuoteValues.bw2 !== "" && selectedQuoteValues.cos3 === "") {
						setErrMsg("Please verify all required fields are completed.");
						setEnableNextBtn(false);
						return;
					} else {
						setErrMsg("");
						setEnableNextBtn(true);
						finalValidation();
					}
				} else {
					setErrMsg("");
					setEnableNextBtn(true);
					finalValidation();
				}
				break;
			case "E_ACCESS":
				if (!selectedQuoteValues.term1 && !selectedQuoteValues.term2 && !selectedQuoteValues.term3 && !selectedQuoteValues.term4 && !selectedQuoteValues.term5) {
					setErrMsg("Please verify all required fields are completed.");
					setEnableNextBtn(false);
					return;
				} else if (selectedQuoteValues.accessType === "" || selectedQuoteValues.circuit === "") {
					setErrMsg("Please verify all required fields are completed.");
					setEnableNextBtn(false);
					return;
				} else if (selectedQuoteValues.la_existing_uni === "no") {
					if (selectedQuoteValues.bw === "" || selectedQuoteValues.a_vpn_port_1 === "") {
						setErrMsg("Please verify all required fields are completed.");
						setEnableNextBtn(false);
						return;
					} else if (selectedQuoteValues.bw1 !== "" && selectedQuoteValues.a_vpn_port_2 === "") {
						setErrMsg("Please verify all required fields are completed.");
						setEnableNextBtn(false);
						return;
					} else if (selectedQuoteValues.bw2 !== "" && selectedQuoteValues.a_vpn_port_3 === "") {
						setErrMsg("Please verify all required fields are completed.");
						setEnableNextBtn(false);
						return;
					} else {
						setErrMsg("");
						setEnableNextBtn(true);
						finalValidation();
					}
				} else if (selectedQuoteValues.bw === "") {
					setErrMsg("Please verify all required fields are completed.");
					setEnableNextBtn(false);
					return;
				} else {
					setErrMsg("");
					setEnableNextBtn(true);
					finalValidation();
				}
				break;
			case "DIA_PREM_BROADBAND":
				//Broadband
				if (selectedQuoteValues.circuit === 14) {
					if (selectedQuoteValues.bw === "") {
						setErrMsg("Please verify all required fields are completed.");
						setEnableNextBtn(false);
						return;
					} else {
						setErrMsg("");
						setEnableNextBtn(true);
						finalValidation();
					}
				}
				break;
			case "MANAGED_SPECTRUM":
				if (selectedQuoteValues.bw === "") {
					setErrMsg("Please select a bandwidth.");
					setEnableNextBtn(false);
					return;
				} else if (!selectedQuoteValues.term3 && !selectedQuoteValues.term4 && !selectedQuoteValues.term5 && !selectedQuoteValues.term10) {
					setErrMsg("Please select a term.");
					setEnableNextBtn(false);
					return;
				} else {
					setErrMsg("");
					setEnableNextBtn(true);
					finalValidation();
				}
				break;
			case "BROADBAND":
				if (selectedQuoteValues.bw === "") {
					setErrMsg("Please verify all required fields are completed.");
					setEnableNextBtn(false);
					return;
				} else if (!selectedQuoteValues.termMonthToMonth && !selectedQuoteValues.term1 && !selectedQuoteValues.term2 && !selectedQuoteValues.term3) {
					setErrMsg("Please verify all required fields are completed.");
					setEnableNextBtn(false);
					return;
				} else if (!selectedQuoteValues.typeOfIps || !selectedQuoteValues.ips || !selectedQuoteValues.modem) {
					setErrMsg("Please verify all required fields are completed.");
					setEnableNextBtn(false);
					return;
				} else {
					setErrMsg("");
					setEnableNextBtn(true);
					finalValidation();
				}
				break;
			case "OFFICESUITE":
				console.log("quantities : " + JSON.stringify(selectedQuoteValues.officeSuiteQuantities));
				console.log("seatQuantities 147: " + selectedQuoteValues.officeSuiteQuantities?.['147'] + " 148: " + selectedQuoteValues.officeSuiteQuantities?.['147'] + " 149: " + selectedQuoteValues.officeSuiteQuantities?.['149']);
				const seatQuantities = [selectedQuoteValues.officeSuiteQuantities?.['147'], selectedQuoteValues.officeSuiteQuantities?.['148'], selectedQuoteValues.officeSuiteQuantities?.['149']];
				const hasQuantityGreaterThanZero = seatQuantities.some(quantity => quantity > 0);
				console.log("hasQuantityGreaterThanZero: " + hasQuantityGreaterThanZero);
				if (!selectedQuoteValues.term2 && !selectedQuoteValues.term3 && !selectedQuoteValues.term5) {
					setErrMsg("A term selection is required.");
					setEnableNextBtn(false);
					return;
				} else if (!selectedQuoteValues.tlcThisLoc) {
					setErrMsg("'Set Tenant Level Configuration at this Location' in Configuration section is required.");
					setEnableNextBtn(false);
					return;
				} else if (!selectedQuoteValues.entGroup) {
					setErrMsg("'Is Tenant part of Enterprise Group' in Configuration section is required.");
					setEnableNextBtn(false);
					return;
				} else if (selectedQuoteValues.entGroup === '1' && !selectedQuoteValues.extToExt) {
					setErrMsg("'Extension-to-Extension Dialing' in Configuration section is required.");
					setEnableNextBtn(false);
					return;
				} else if (!selectedQuoteValues.extLength) {
					setErrMsg("'Extension Length' in Configuration section is required.");
					setEnableNextBtn(false);
					return;
				} else if (!selectedQuoteValues.porting) {
					setErrMsg("'Is the Customer Porting Telephone Numbers' in Configuration section is required.");
					setEnableNextBtn(false);
					return;
				} else if (selectedQuoteValues.porting === '1' && (!selectedQuoteValues.btn || !/^\d{10}$/.test(selectedQuoteValues.btn))) {
					setErrMsg("Valid 'Main Telephone Number (BTN)' in Configuration section is required.");
					setEnableNextBtn(false);
					return;
				} else if (!hasQuantityGreaterThanZero) { // check that one seat has a qty
					setErrMsg("At least one seat under Extensions / Seats section is required.");
					setEnableNextBtn(false);
					return;
				} else {
					var win_install = selectedQuoteValues.officeSuiteQuantities?.['174'] // Equipment Installation by Windstream
					if (win_install !== 1) {
						const officeSuiteQuantities = selectedQuoteValues.officeSuiteQuantities;
						for (const itemType in officeSuiteQuantities) {
							const quantity = officeSuiteQuantities[itemType];
							if (quantity > 0) {
								const shippingStatus = shippingHandlingFeature(parseInt(itemType, 10));
								if (shippingStatus === 'shipping') {
									setShowShippingAlert(true);
									break; // Exit the loop once the condition is met
								}
							}
						}
					}
					setErrMsg("");
					setEnableNextBtn(true);
					finalValidation();
				}
				break;
			case "DARKFIBER":
				if (!selectedQuoteValues.df_term) {
					setErrMsg("Term is required.");
					setEnableNextBtn(false);
				} else if (!selectedQuoteValues.df_num_fibers) {
					setErrMsg("Number of Fiber is required.");
					setEnableNextBtn(false);
				}
				break;
			default:
				break;
		}
		setErrMsg("");
		setEnableNextBtn(true);
		finalValidation();
	}

	const validationStep4 = () => {
		if (selectedContactDetails.name === "" || selectedContactDetails.phone === "" || selectedContactDetails.email === "" || selectedContactDetails.busName === "") {
			setEnableNextBtn(false);
			setErrMsg('Please verify all required fields are completed.');
			return;
		} else if (isEmployee) {
			internalValidation(4);
		} else {
			setEnableNextBtn(true);
			setSuccessMsg('Success');
			setErrMsg('');
		}
	}

	const finalValidation = () => {
		if (stepCode < 4) {
			setStepCode(stepCode + 1);
			props.updateQuoteStepCode(stepCode + 1);
		}
	}

	const nextPage = () => {
		setErrMsg('');
		setErrMsg('');

		// step 1 validation
		if (stepCode === 1) {
			validationStep1();
		}

		//step 2 validation
		if (stepCode === 2) {
			validationStep2();
		}

		//step 3 validation
		if (stepCode === 3) {
			validationStep3();
		}

		//step 4 validation
		if (stepCode === 4) {
			validationStep4();
		}
	}


	const popupOk = () => {
		// footer cancel btn
		if (popupAction === 'cancelBtn') {
			reset();
		}
		props.updateSessionErrorMsg("");
		popupClose();

	}

	const continueQuote = () => {
		setStepCode(selectedQuoteValues.step);
		logger.info(selectedQuoteValues.product_code);
		setSelectedProducts(selectedQuoteValues.product_code);
		popupClose();
	}

	const popupClose = () => {
		setPopupShow(false);
		setPopupMsg('');
		setSavedQuotePopup(false);
	}

	const cancelBtn = () => {
		if (selectedProducts !== undefined || selectedInternalMpid) {
			setPopupShow(true);
			setPopupMsg('Are you sure you want to cancel?');
			setPopupOkBtn(true);
			setPopupCancelBtn(true);
			setPopupAction('cancelBtn');
			setPopupSize('modal-sm');
		}

	}

	const reset = () => {
		setStepCode(1);
		props.clearQuoteData();
		if (isEmployee) {
			props.clearInternalQuoteData();
		}
		props.updateQuoteStepCode(1);
		setSelectedProducts(undefined);
		setSelectedIcon(undefined);
		setErrMsg('');
		setErrMsg('');
		setSuccessMsg('');
	}


	const circuitTypeChange = (ct) => {
		setSelectedCircuitType(ct.code);
		props.updateQuoteCircuitType(ct.dbId);
		if (ct.code === 'ETHERNET') {
			setSelectedProducts("INTERNET");
			props.updateQuoteProductCode("INTERNET");
			props.updateQuoteProduct(10); //setting the product db id to dia pop for the moment if the bandwidth choosen in step is greater < 1 GB change product to dia prem
		} else {
			setSelectedProducts("DIA_PREM_BROADBAND");
			props.updateQuoteProductCode("DIA_PREM_BROADBAND");
			props.updateQuoteProduct(11);
		}

		setCircuitType(circuitType.map(
			cType => (cType.code === ct.code) ? { ...cType, selected: true } : { ...cType, selected: false }
		));
	}

	const classOfServiceChange = (cos) => {
		setSelectedClassOfService(cos.code);
		props.updateQuoteCOS(cos.value);
		logger.info("VALUE OF YES OR NO: " + cos.value);
		setClassOfServiceType(classOfServiceType.map(
			cosType => (cosType.code === cos.code) ? { ...cosType, selected: true } : { ...cosType, selected: false }
		));
	}

	const dfOptionHandler = (event) => {
		const { id, checked, value } = event.target;
		const optionValue = parseInt(value);
		let newOptions = [...dfOptions];

		if (checked) {
			// Handle mutual exclusivity between lease (1) and iru (2)
			if (id === 'lease' || id === 'iru') {
				newOptions = newOptions.filter(opt => opt !== 1 && opt !== 2);
			}
			newOptions.push(optionValue);
		} else {
			newOptions = newOptions.filter(opt => opt !== optionValue);
		}

		setDfOptions(newOptions);
		props.updateQuoteDfOptions(newOptions);
	};


	// Handler function to redirect users who enter an address classified as ILEC
	// in the Internet Broadband (DIA PREM Broadband) section to the Wholesale Broadband Quote Experience.
	// This function sets the state to trigger the redirection and updates the selected product to "BROADBAND".
	const handleIlecBroadbandRedirect = () => {
		setIlecBroadbandRedirect(true);
		setSelectedProducts("BROADBAND");
		setSelectedIcon("BROADBAND");
	}


	return (
		<>
			{hasError && <HTTPErrorHandler error={hasError}></HTTPErrorHandler>}
			<div className="midsection qm-create abs z1">
				<div className="rel">
					<ViewportWarning />
					<div className="wrap">
						<h1 className="page-title">{isEmployee ? "Employee Quote Manager" : "Quote Manager"}</h1>
						{isEmployee ? <CompanyDropdown area={"Quote Create"} stepCode={stepCode} /> : null}
						<div className="section">
							{/* <TabsQuoteManager/> */}
							<TabsQuote />
							{!hasError && (<div className={`content has-actions ${style.contentQueryPage}`}>
								<div className="milestones four-steps">
									<div className={` step ${stepCode === 1 ? 'active' : ''}`}>
										<div>1</div>
										<span>Product Selection</span>
									</div>
									<div className="gap">&nbsp;</div>
									<div className={` step ${stepCode === 2 ? 'active' : ''}`}>
										<div>2</div>
										<span>Location Information</span>
									</div>
									<div className="gap">&nbsp;</div>
									<div className={` step ${stepCode === 3 ? 'active' : ''}`}>
										<div>3</div>
										<span>Product Configuration</span>
									</div>
									<div className="gap">&nbsp;</div>
									<div className={` step ${stepCode === 4 ? 'active' : ''}`}>
										<div>4</div>
										<span>Review &amp; Create Quote</span>
									</div>
								</div>


								<div className={style.stepsOuter}>
									{stepCode === 1 ?
										<>
											<div className="step-content step-1 text-center">
												{products.length > 0 ?
													products.map((pd, i) =>
														<div key={i}
															className={` ${style.productThumb} ${selectedIcon === pd.code ? style.selected : selectedProducts === undefined ? '' : style.notSelected}   `}
															onClick={() => {
																productSelect(pd.code)
															}}>
															<h1 className={` ${style.productLink} `}>
																{pd.productName}
															</h1>
															<div className={` ${style.productType}`}></div>
															<img alt={pd.productName}
																src={`../../images/product_icons/${encodeURIComponent(pd.code)}.svg `}
																className={style.productImgQuotePage} />
															<svg className={`ico ${style.ptCheck}`}>
																<path
																	d="M34.5,7V8l-22,22h-2l-9-9V20l1-1,9,9,22-22Z"></path>
															</svg>
														</div>
													)
													: <h1>Loading</h1>
												}

												{selectedIcon === 'INTERNET' ?
													<div className=''>
														<div className={`sub-hdr text-left alt ${style.subHeader} `}>
															<div className="nib">
																<div className="the-notch">
																	<div className="notch-fg">
																		<svg>
																			<path
																				d="M35.81,26.28l-13,10v-5C-10.47,28.68-1,9.16,11.79,0c0,7.28,0,9.13,0,13.28s3.18,7.92,11,9v-5Z"></path>
																		</svg>
																	</div>
																</div>
															</div>
															<h2 className={style.sectionTitle}>Circuit Type</h2>
														</div>

														{circuitType.map((ct, i) =>
															<div className={`productSubTypeGroup ${style.subGrp} `}
																key={i}>
																<a href
																	className={` d-block ${selectedCircuitType === ct.code ? style.selected : ''}`}
																	onClick={() => {
																		circuitTypeChange(ct)
																	}}>
																	<svg className={`ico ${style.configOptIco} `}>
																		<path
																			d="M17.76,18.84l9.5-9.46c1.36-.34,3.09-.69,3.09-.69L33.1,3.55,32.51,3,27.37,5.71,26.68,8.8l-9.47,9.5c-.75.74-2.37-.38-1.37-1.38L25.1,7.66,25.55,5c7.21-4.49,7-4.65,7.79-3.87,0,0,1.92,1.92,1.71,2.6l-3.22,6.31c-1.67.49-3.3.9-3.3.9l-9.4,9.26C18.54,20.89,16.93,19.67,17.76,18.84ZM2.46,33.49a5.24,5.24,0,0,1-1.18-5.87,4.82,4.82,0,0,1,1.18-1.68L7.36,21C9.8,21,9.47,20,9.53,18.84l2-1.92a1.11,1.11,0,0,1,1.37,0l6.28,6.28a1,1,0,0,1,0,1.37l-1.92,2c-1.8,0-2,.13-2.19,2.16l-4.91,4.8C11.09,32.42,6.83,37.85,2.46,33.49ZM8.39,22.62,3.83,27.21a3.47,3.47,0,0,0,4.91,4.91l4.56-4.56a3.19,3.19,0,0,1,3-3l.93-.92-4.94-4.91-.89.93A3.12,3.12,0,0,1,8.39,22.62ZM18.1,11.44,16.59,9.86C17.84,1,9.81.43,7.81,1.25a1,1,0,0,0-.45,1.61l3,2.95L8,8.11,5.07,5.26c.42.43-1-1.17-1.58.35a6.54,6.54,0,0,0,3,7.88,11.5,11.5,0,0,0,5.54.79l1.48,1.48c2.29.23,1.37-1.37,1.37-1.37L13.44,13c-1.36-1.23-2-.44-3.57-.44C5.85,12.57,5,8,5,8L6.58,9.58c2.27,1.42,5-2.26,5-2.26C13.85,5,10.11,2.86,10.11,2.86c4.62.12,4.7,4.92,4.7,4.91,0,1.37-.79,2.29.45,3.53l1.47,1.51C17.47,13.44,18.81,12.86,18.1,11.44Zm16,15.88-9.7-9.71c-.82-.82-2.48.09-1.38,1.37l9.71,9.71h0c1.12,1.12-.7,2.83-1.82,1.71l-9.74-9.7c-.92-.92-2.4.11-1.37,1.37l9.74,9.7a3.21,3.21,0,0,0,4.65.07A3.08,3.08,0,0,0,34.12,27.32Z"></path>
																	</svg>
																	<svg className={`ico ${style.configOptCheck} `}>
																		<path
																			d="M34.5,7V8l-22,22h-2l-9-9V20l1-1,9,9,22-22Z"></path>
																	</svg>
																	{ct.name}
																</a>

															</div>
														)}


													</div>

													: null}

												{selectedIcon === "E_LINE" ?
													<div className=''>
														<div className={`sub-hdr text-left alt ${style.subHeader} `}>
															<div className="nib">
																<div className="the-notch">
																	<div className="notch-fg">
																		<svg>
																			<path
																				d="M35.81,26.28l-13,10v-5C-10.47,28.68-1,9.16,11.79,0c0,7.28,0,9.13,0,13.28s3.18,7.92,11,9v-5Z"></path>
																		</svg>
																	</div>
																</div>
															</div>
															<h2 className={style.sectionTitle}>Class of Service</h2>
														</div>
														{classOfServiceType.map((cos, i) =>
															<div className={`productSubTypeGroup ${style.subGrp} `}
																key={i}>
																<a href
																	className={` d-block ${selectedClassOfService === cos.code ? style.selected : ''}`}
																	onClick={() => {
																		classOfServiceChange(cos)
																	}}>
																	<svg className={`ico ${style.configOptIco} `}>
																		<path
																			d="M17.76,18.84l9.5-9.46c1.36-.34,3.09-.69,3.09-.69L33.1,3.55,32.51,3,27.37,5.71,26.68,8.8l-9.47,9.5c-.75.74-2.37-.38-1.37-1.38L25.1,7.66,25.55,5c7.21-4.49,7-4.65,7.79-3.87,0,0,1.92,1.92,1.71,2.6l-3.22,6.31c-1.67.49-3.3.9-3.3.9l-9.4,9.26C18.54,20.89,16.93,19.67,17.76,18.84ZM2.46,33.49a5.24,5.24,0,0,1-1.18-5.87,4.82,4.82,0,0,1,1.18-1.68L7.36,21C9.8,21,9.47,20,9.53,18.84l2-1.92a1.11,1.11,0,0,1,1.37,0l6.28,6.28a1,1,0,0,1,0,1.37l-1.92,2c-1.8,0-2,.13-2.19,2.16l-4.91,4.8C11.09,32.42,6.83,37.85,2.46,33.49ZM8.39,22.62,3.83,27.21a3.47,3.47,0,0,0,4.91,4.91l4.56-4.56a3.19,3.19,0,0,1,3-3l.93-.92-4.94-4.91-.89.93A3.12,3.12,0,0,1,8.39,22.62ZM18.1,11.44,16.59,9.86C17.84,1,9.81.43,7.81,1.25a1,1,0,0,0-.45,1.61l3,2.95L8,8.11,5.07,5.26c.42.43-1-1.17-1.58.35a6.54,6.54,0,0,0,3,7.88,11.5,11.5,0,0,0,5.54.79l1.48,1.48c2.29.23,1.37-1.37,1.37-1.37L13.44,13c-1.36-1.23-2-.44-3.57-.44C5.85,12.57,5,8,5,8L6.58,9.58c2.27,1.42,5-2.26,5-2.26C13.85,5,10.11,2.86,10.11,2.86c4.62.12,4.7,4.92,4.7,4.91,0,1.37-.79,2.29.45,3.53l1.47,1.51C17.47,13.44,18.81,12.86,18.1,11.44Zm16,15.88-9.7-9.71c-.82-.82-2.48.09-1.38,1.37l9.71,9.71h0c1.12,1.12-.7,2.83-1.82,1.71l-9.74-9.7c-.92-.92-2.4.11-1.37,1.37l9.74,9.7a3.21,3.21,0,0,0,4.65.07A3.08,3.08,0,0,0,34.12,27.32Z"></path>
																	</svg>
																	<svg className={`ico ${style.configOptCheck} `}>
																		<path
																			d="M34.5,7V8l-22,22h-2l-9-9V20l1-1,9,9,22-22Z"></path>
																	</svg>
																	{cos.name}
																</a>

															</div>
														)}
													</div>
													: null
												}

												{selectedIcon === "DARKFIBER" ?
													<div className=''>
														<div className={`sub-hdr text-left alt ${style.subHeader} `}>
															<div className="nib">
																<div className="the-notch">
																	<div className="notch-fg">
																		<svg>
																			<path
																				d="M35.81,26.28l-13,10v-5C-10.47,28.68-1,9.16,11.79,0c0,7.28,0,9.13,0,13.28s3.18,7.92,11,9v-5Z"></path>
																		</svg>
																	</div>
																</div>
															</div>
															<h2 className={style.sectionTitle}>Product Options</h2>
														</div>
														<div className={`productSubTypeGroup ${style.subGrp} `} >
															<input type="checkbox" className="" id="lease" name="dfOptions" value={1} checked={dfOptions.includes(1)} onChange={dfOptionHandler} />
															<label htmlFor="lease" className={style.checkboxText}>Dark Fiber - Lease</label>
															<input type="checkbox" className="" id="iru" name="dfOptions" value={2} checked={dfOptions.includes(2)} onChange={dfOptionHandler} />
															<label htmlFor="iru" className={style.checkboxText}>Dark Fiber - IRU</label>
															<input type="checkbox" className="" id="entrance_lease" name="dfOptions" value={3} checked={dfOptions.includes(3)} onChange={dfOptionHandler} />
															<label htmlFor="entrance_lease" className={style.checkboxText}>Entrance Dark Fiber - Lease</label>
														</div>
													</div>
													: null
												}
											</div>
											{isEmployee ?
												<QuoteInternal section={"agency"} /> : null
											}
											<div className="mt-5">
												<p className="text-danger text-center">{errMsg}</p>
											</div>
										</>
										: null}

									{stepCode === 2 ?
										<div className="step-content step-2">
											<form>
												<div className={`row `}>
													<div className="col-12">
														<p className={` ${style.lableTextS} ${style.textBlack} `}>
															<span className={style.mandatory}>*</span> Denotes a
															required field</p>
													</div>
												</div>
												{/*Broadband*/}
												{selectedQuoteValues.circuit === 14 ?
													null :
													<div className={`sub-hdr alt ${style.subHeader} `}>
														<div className="nib">
															<div className="the-notch">
																<div className="notch-fg">
																	<svg>
																		<path
																			d="M35.81,26.28l-13,10v-5C-10.47,28.68-1,9.16,11.79,0c0,7.28,0,9.13,0,13.28s3.18,7.92,11,9v-5Z"></path>
																	</svg>
																</div>
															</div>
														</div>
														<h2 className={style.sectionTitle}>Location A</h2>
													</div>}

												{selectedProducts === "MPLS_NNI" ?
													<MplsNNI stepCode={stepCode} /> : null
												}

												{selectedProducts === "E_ACCESS_NNI" ?
													<EAccessNNI stepCode={stepCode} /> : null
												}

												{selectedProducts === "INTERNET" ?
													<Internet stepCode={stepCode} /> : null
												}

												{selectedProducts === "COLOCATION" ?
													<Colocation stepCode={stepCode} /> : null
												}

												{selectedProducts === "MPLS_A_TAIL" ?
													<MPLSAggTail stepCode={stepCode} /> : null
												}

												{selectedProducts === "MPLS_INTERNET" ?
													<MPLSInternet stepCode={stepCode} /> : null
												}

												{selectedProducts === "DIA_PREM_BROADBAND" ?
													<DIAPremBroadband stepCode={stepCode} ilecBroadband={handleIlecBroadbandRedirect} /> : null
												}

												{selectedProducts === "WAVE" ?
													<Wave stepCode={stepCode} /> : null
												}

												{selectedProducts === "MANAGED_SPECTRUM" ?
													<ManagedSpectrum stepCode={stepCode} /> : null
												}

												{selectedProducts === "E_LINE" ?
													<ELINE stepCode={stepCode} /> : null
												}

												{selectedProducts === "E_ACCESS" ?
													<EAccess stepCode={stepCode} /> : null
												}

												{selectedProducts === "BROADBAND" ?
													<Broadband stepCode={stepCode} ilecBroadbandRedirect={ilecBroadbandRedirect} /> : null
												}
												{selectedProducts === "OFFICESUITE" ?
													<OfficeSuite stepCode={stepCode} /> : null
												}
												{selectedProducts === "DARKFIBER" ?
													<DarkFiber stepCode={stepCode} /> : null
												}
												<div className={style.dividers}></div>
											</form>
											<div className="mt-5">
												<p className="text-danger text-center">{errMsg}</p>
											</div>
										</div>
										: null}

									{stepCode === 3 ?
										<div className="step-content step-3">
											<div className={`row `}>
												<div className="col-12">
													<p className={` ${style.lableTextS} ${style.textBlack} `}><span
														className={style.mandatory}>*</span> Denotes a required field
													</p>
												</div>
											</div>
											<div className={style.stepGroup}>
												<div className={` ${style.stepRow} order-1 `}>
													{selectedQuoteValues.circuit !== 14 ?
														<div className={` sub-hdr alt ${style.subHeader} `}>
															<div className="nib">
																<div className="the-notch">
																	<div className="notch-fg">
																		<svg>
																			<path
																				d="M35.81,26.28l-13,10v-5C-10.47,28.68-1,9.16,11.79,0c0,7.28,0,9.13,0,13.28s3.18,7.92,11,9v-5Z"></path>
																		</svg>
																	</div>
																</div>
															</div>
															<h2 className={style.sectionTitle}>Term</h2>
														</div>
														: null
													}


													{selectedProducts === 'MPLS_NNI' ?
														<MplsNNI stepCode={stepCode} section={"term"} /> : null
													}

													{selectedProducts === "E_ACCESS_NNI" ?
														<EAccessNNI stepCode={stepCode} section={"term"} /> : null
													}

													{selectedProducts === "INTERNET" ?
														<Internet stepCode={stepCode} section={"term"} /> : null
													}

													{selectedProducts === "COLOCATION" ?
														<Colocation stepCode={stepCode} section={"term"} /> : null
													}

													{selectedProducts === "MPLS_A_TAIL" ?
														<MPLSAggTail stepCode={stepCode} section={"term"} /> : null
													}

													{selectedProducts === "MPLS_INTERNET" ?
														<MPLSInternet stepCode={stepCode} section={"term"} /> : null
													}

													{selectedProducts === "DIA_PREM_BROADBAND" ?
														<DIAPremBroadband stepCode={stepCode} section={"term"} /> : null
													}

													{selectedProducts === "WAVE" ?
														<Wave stepCode={stepCode} section={"term"} /> : null
													}

													{selectedProducts === "E_LINE" ?
														<ELINE stepCode={stepCode} section={"term"} /> : null
													}

													{selectedProducts === "E_ACCESS" ?
														<EAccess stepCode={stepCode} section={"term"} /> : null
													}

													{selectedProducts === "MANAGED_SPECTRUM" ?
														<ManagedSpectrum stepCode={stepCode} section={"term"} /> : null
													}

													{selectedProducts === "BROADBAND" ?
														<Broadband stepCode={stepCode} section={"term"} /> : null
													}

													{selectedProducts === "OFFICESUITE" ?
														<OfficeSuite stepCode={stepCode} section={"term"} /> : null
													}

													{selectedProducts === "DARKFIBER" ?
														<DarkFiber stepCode={stepCode} section={"term"} /> : null
													}

													{selectedQuoteValues.circuit !== 14 ?
														<div className={style.dividers}></div>
														: null}
												</div>

												<div
													className={` ${style.stepRow} ${['DIA_PREM_BROADBAND'].includes(selectedProducts) && selectedCircuitType === 'BROADBAND' ? 'order-3' : 'order-2'} `}>
													{!['COLOCATION'].includes(selectedProducts) ?
														<>
															<div className={` sub-hdr alt1 ${style.subHeader} `}>
																<div className="nib">
																	<div className="the-notch">
																		<div className="notch-fg">
																			<svg>
																				<path
																					d="M35.81,26.28l-13,10v-5C-10.47,28.68-1,9.16,11.79,0c0,7.28,0,9.13,0,13.28s3.18,7.92,11,9v-5Z"></path>
																			</svg>
																		</div>
																	</div>
																</div>
																<h2 className={style.sectionTitle}>{['E_LINE', 'E_ACCESS', 'OFFICESUITE', 'DARKFIBER'].includes(selectedProducts) ? "Configuration" : "Bandwidth"}</h2>
															</div>
														</>
														: null}

													{selectedProducts === "MPLS_NNI" ?
														<MplsNNI stepCode={stepCode} section={"bandwidth"} /> : null
													}

													{selectedProducts === "E_ACCESS_NNI" ?
														<EAccessNNI stepCode={stepCode} section={"bandwidth"} /> : null
													}

													{selectedProducts === "INTERNET" ?
														<Internet stepCode={stepCode} section={"bandwidth"} /> : null
													}

													{selectedProducts === "MPLS_A_TAIL" ?
														<MPLSAggTail stepCode={stepCode} section={"bandwidth"} /> : null
													}

													{selectedProducts === "MPLS_INTERNET" ?
														<MPLSInternet stepCode={stepCode} section={"bandwidth"} /> : null
													}

													{selectedProducts === "DIA_PREM_BROADBAND" ?
														<DIAPremBroadband stepCode={stepCode} section={"bandwidth"} /> : null
													}

													{selectedProducts === "WAVE" ?
														<Wave stepCode={stepCode} section={"bandwidth"} /> : null
													}

													{selectedProducts === "MANAGED_SPECTRUM" ?
														<ManagedSpectrum stepCode={stepCode} section={"bandwidth"} /> : null
													}

													{selectedProducts === "E_LINE" ?
														<ELINE stepCode={stepCode} section={"productConfig"} /> : null
													}

													{selectedProducts === "E_ACCESS" ?
														<EAccess stepCode={stepCode} section={"productConfig"} /> : null
													}

													{selectedProducts === "BROADBAND" ?
														<Broadband stepCode={stepCode} section={"bandwidth"} /> : null
													}

													{selectedProducts === "OFFICESUITE" ?
														<OfficeSuite stepCode={stepCode} section={"productConfig"} /> : null
													}
													{selectedProducts === "DARKFIBER" ?
														<DarkFiber stepCode={stepCode} section={"productConfig"} /> : null
													}

													{!['COLOCATION', 'OFFICESUITE', 'DARKFIBER'].includes(selectedProducts) ?
														<div className={style.dividers}></div>
														: null}

												</div>

												<div
													className={` ${style.stepRow} ${['DIA_PREM_BROADBAND'].includes(selectedProducts) && selectedCircuitType === 'BROADBAND' ? 'order-2' : 'order-3'} `}>

													{!['MPLS_NNI', 'E_ACCESS_NNI', 'OFFICESUITE', 'DARKFIBER'].includes(selectedProducts) ?
														<>
															<div className={` sub-hdr alt2 ${style.subHeader} `}>
																<div className="nib">
																	<div className="the-notch">
																		<div className="notch-fg">
																			<svg>
																				<path
																					d="M35.81,26.28l-13,10v-5C-10.47,28.68-1,9.16,11.79,0c0,7.28,0,9.13,0,13.28s3.18,7.92,11,9v-5Z"></path>
																			</svg>
																		</div>
																	</div>
																</div>
																<h2 className={style.sectionTitle}>{['E_LINE', 'E_ACCESS'].includes(selectedProducts) ? "Bandwidth" : "Configuration"}</h2>
															</div>


															<div className={`row ${style.rowGrp} `}>

																{selectedProducts === "INTERNET" ?
																	<Internet stepCode={stepCode} section={"productConfig"} /> : null}

																{selectedProducts === "COLOCATION" ?
																	<Colocation stepCode={stepCode} section={"productConfig"} /> : null
																}

																{selectedProducts === "MPLS_A_TAIL" ?
																	<MPLSAggTail stepCode={stepCode} section={"productConfig"} /> : null
																}

																{selectedProducts === "MPLS_INTERNET" ?
																	<MPLSInternet stepCode={stepCode} section={"productConfig"} /> : null

																}

																{selectedProducts === "DIA_PREM_BROADBAND" ?
																	<DIAPremBroadband stepCode={stepCode} section={"productConfig"} /> : null
																}

																{selectedProducts === "WAVE" ?
																	<Wave stepCode={stepCode} section={"productConfig"} /> : null
																}

																{selectedProducts === "MANAGED_SPECTRUM" ?
																	<ManagedSpectrum stepCode={stepCode} section={"productConfig"} /> : null
																}

																{selectedProducts === "E_LINE" ?
																	<ELINE stepCode={stepCode} section={"bandwidth"} /> : null
																}

																{selectedProducts === "E_ACCESS" ?
																	<EAccess stepCode={stepCode} section={"bandwidth"} /> : null
																}

																{selectedProducts === "BROADBAND" ?
																	<Broadband stepCode={stepCode} section={"productConfig"} /> : null
																}
															</div>
															<div className={style.dividers}></div>
														</>
														: null}

												</div>
												<div className={` ${style.stepRow} order-4`}>
													{!['INTERNET', 'MPLS_NNI', 'E_ACCESS_NNI', 'COLOCATION', 'E_LINE', 'OFFICESUITE', 'DARKFIBER'].includes(selectedProducts) ?

														<div className={` sub-hdr alt3 ${style.subHeader} `}>
															<div className="nib">
																<div className="the-notch">
																	<div className="notch-fg">
																		<svg>
																			<path
																				d="M35.81,26.28l-13,10v-5C-10.47,28.68-1,9.16,11.79,0c0,7.28,0,9.13,0,13.28s3.18,7.92,11,9v-5Z"></path>
																		</svg>
																	</div>
																</div>
															</div>
															<h2 className={style.sectionTitle}>Options</h2>
														</div>
														: null}

													{selectedProducts === "MPLS_A_TAIL" ?
														<MPLSAggTail stepCode={stepCode} section={"Options"} /> : null
													}

													{selectedProducts === "MPLS_INTERNET" ?
														<MPLSInternet stepCode={stepCode} section={"Options"} /> : null
													}

													{selectedProducts === "E_ACCESS" ?
														<EAccess stepCode={stepCode} section={"Options"} /> : null
													}

													{selectedProducts === "DIA_PREM_BROADBAND" ?
														<DIAPremBroadband stepCode={stepCode} section={"Options"} /> : null
													}

													{selectedProducts === "INTERNET" ?
														<Internet stepCode={stepCode} section={"Options"} /> : null
													}

													{selectedProducts === "WAVE" ?
														<Wave stepCode={stepCode} section={"Options"} /> : null
													}

													{selectedProducts === "MANAGED_SPECTRUM" ?
														<ManagedSpectrum stepCode={stepCode} section={"Options"} /> : null
													}

													{selectedProducts === "BROADBAND" ?
														<Broadband stepCode={stepCode} section={"Options"} /> : null
													}
												</div>
											</div>

											<div className="mt-5">
												<p className="text-danger text-center">{errMsg}</p>
											</div>
										</div>
										: null}

									{stepCode === 4 ?

										<div className="step-content step-4">
											<QuoteContacts />
											{successMsg ?
												<QuoteSubmit /> : null
											}
											<div className="mt-5">
												<p className="text-danger text-center">{errMsg}</p>
											</div>


										</div>
										: null}

								</div>
								<div ref={nextBtnRef} />
								<div id="quote-create" className={style.pageActions}>
									<div className="text-center">
										<button className={` ico-button light-button `} onClick={cancelBtn}>
											Cancel
											<svg className="ico-x">
												<path
													d="M18,17l-1,1L9,10,1,18,0,17,8,9,0,1,1,0,9,8l8-8,1,1L10,9Z"></path>
											</svg>
										</button>

										{(stepCode > 0 && stepCode !== 1) ?
											<button
												className={` ico-button lhs light-button ${style.nextBtn} ${style.prevBtn} ml-3`}
												onClick={prevPage}>
												<svg className="ico light-ico ico-arrow">
													<path d="M0,17H29L15,31l1,1L32,16,16,0,15,1,29,15H0Z"></path>
												</svg>
												{nextStepText[stepCode - 2]}
											</button>
											: null}

										<button className={` ico-button lhs light-button ${style.nextBtn} ml-3`}
											onClick={nextPage}>
											{nextStepText[stepCode]}
											<svg className="ico light-ico ico-arrow">
												<path d="M0,17H29L15,31l1,1L32,16,16,0,15,1,29,15H0Z"></path>
											</svg>
										</button>

									</div>
								</div>
							</div>)}
						</div>
					</div>
				</div>
				<div ref={nextBtnRef} />
				{
					popupShow ?
						<div>
							<div className={`${style.overly}`}></div>
							<div className="modal fade show d-block">
								<div className={` modal-dialog modal-dialog-centered ${popupSize}`}>
									<div className="modal-content">
										<div className="modal-body text-center">
											{popupMsg}
										</div>

										<div className="modal-footer d-block text-center">
											{popupOkBtn ?
												<button type="button" className="btn-style btn-theme"
													data-dismiss="modal" onClick={popupOk}>Yes</button>
												: null}
											{popupCancelBtn ?
												<button type="button" className="btn-style btn-cancel"
													data-dismiss="modal" onClick={popupClose}>No</button>
												: null}
										</div>

									</div>
								</div>
							</div>
						</div>
						: null
				}
				{savedQuotePopup ?

					<div>
						<div className={`${style.overly}`}></div>
						<div className="modal fade show d-block">
							<div className={` modal-dialog modal-dialog-centered ${popupSize}`}>
								<div className="modal-content">
									<div className="modal-body">
										Would you like to continue working on an existing quote?
									</div>

									<div className="modal-footer d-block text-center">
										<button type="button" className="btn-style btn-theme" data-dismiss="modal"
											onClick={continueQuote}>Yes
										</button>
										<button type="button" className="btn-style btn-cancel" data-dismiss="modal"
											onClick={popupClose}>No
										</button>

									</div>

								</div>
							</div>
						</div>
					</div>
					: null
				}

			</div>
		</>);
}
const mapDispatchToProps = {
	updateQuoteProduct: setQuoteProduct,
	updateQuoteProductCode: setQuoteProductCode,
	updateQuoteCircuitType: setQuoteCircuitType,
	updateQuoteStepCode: setQuoteStepCode,
	updateQuoteCOS: setQuoteCOS,
	updateQuoteDfOptions: setQuoteDfOptions,
	updateSessionErrorMsg: setSessionError,
	clearQuoteData: resetData,
	clearInternalQuoteData: resetInternalQuote
}
export default connect(null, mapDispatchToProps)(QuoteCreate);
