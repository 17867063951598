const CancelModal = ({ cancelEmit }) => {
    const popupOk = () => {
        cancelEmit('Ok');
    }

    const popupCancel = () => {
        cancelEmit('Cancel');
    }

    return (
        <div>
            <div className="overlay show"> </div>
            <div className="modal fade show d-block">
                <div className={` modal-dialog modal-dialog-centered modal-sm`}>
                    <div className="modal-content">
                        <div className="modal-body">
                            Are you sure you want to cancel?
                        </div>

                        <div className="modal-footer d-block text-center">
                            <button type="button" className="btn-style btn-theme" data-dismiss="modal" onClick={() => { popupOk() }}>Ok</button>
                            <button type="button" className="btn-style btn-cancel" data-dismiss="modal" onClick={() => popupCancel()}>Cancel</button>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}
export default CancelModal;