// CSS Imports
import style from "../network/TroubleTickets.module.css";

/**
 * JS Imports
 *
 */
// React Core Libraries
import React, {useEffect, useState} from 'react';


// Local JS Files Imports
import Loader from "../shared/Loader";

// 3rd party npm Libraries
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, {textFilter} from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import XLSXExport from "../../utils/XLSXExport";
import ReactTooltip from 'react-tooltip';
import SummaryData from '../../services/CircuitSummaryService';
import {ConsoleLogger} from "../../logger/ConsoleLogger";
import HTTPErrorHandler from "../errorPages/HTTPErrorHandler";

const maintenance_options = [{
    name: '30 days',
    value: '30',
}, {
    name: '60 days',
    value: '60',
}, {
    name: '90 days',
    value: '90',
}];


const columns = [{
    dataField: 'eventNumber',
    text: 'Event Number',
    sort: true,
    headerAttrs: {id: 'eventNumber'},
    filter: textFilter({
        placeholder: 'Event Number'
    }),
},
    {
        dataField: 'impactType',
        text: 'Impact Type',
        sort: true,
        filter: textFilter({
            placeholder: 'Impact Type'
        }),
        headerAttrs: {id: 'impactType'},
        /*formatter: (cell, row) => troubleTypeFormatter(cell, row)*/
    },
    {
        dataField: 'impactDuration',
        text: 'Impact Duration',
        sort: true,
        filter: textFilter({
            placeholder: 'Impact Duration'
        }),
        headerAttrs: {id: 'impactDuration'},
        /*formatter: (cell, row) => troubleTypeFormatter(cell, row)*/
    },
    {
        dataField: 'windowStart',
        text: 'Window Start',
        sort: true,
        filter: textFilter({
            placeholder: 'Window Start'
        }),
        headerAttrs: {id: 'windowStart'},
        /*formatter: (cell, row) => troubleTypeFormatter(cell, row)*/
    },
    {
        dataField: 'windowEnd',
        text: 'Window End',
        sort: true,
        filter: textFilter({
            placeholder: 'Window End'
        }),
        headerAttrs: {id: 'windowEnd'},
        /*formatter: (cell, row) => troubleTypeFormatter(cell, row)*/
    },
    {
        dataField: 'status',
        text: 'Status',
        sort: true,
        filter: textFilter({
            placeholder: 'Status'
        }),
        headerAttrs: {id: 'status'},
        /*formatter: (cell, row) => statusFormatter(cell, row)*/

    }
];

const columnsDesktop = [{
    dataField: 'eventNumber',
    text: 'Event Number',
    sort: true,
    headerAttrs: {id: 'eventNumber'},
    headerClass: '',
    imgSrc: "ico-spec-white.svg",
    headerFormatter: (cell) => headerFormatter(cell)
},
    {
        dataField: 'impactType',
        text: 'Impact Type',
        sort: true,
        headerAttrs: {id: 'impactType'},
        headerClass: '',
        imgSrc: "ico-alert-32-white.svg",
        headerFormatter: (cell) => headerFormatter(cell),
        /*formatter: (cell, row) => troubleTypeFormatter(cell, row)*/
    },
    {
        dataField: 'impactDuration',
        text: 'Impact Duration',
        sort: true,
        headerAttrs: {id: 'impactDuration'},
        headerClass: '',
        imgSrc: "ico-clock-white.svg",
        headerFormatter: (cell) => headerFormatter(cell),
        /*formatter: (cell, row) => troubleTypeFormatter(cell, row)*/
    },
    {
        dataField: 'windowStart',
        text: 'Window Start',
        sort: true,
        headerAttrs: {id: 'windowStart'},
        headerClass: 'headerIconForTroubleTicketsHistory',
        imgSrc: "ico-events-white.svg",
        headerFormatter: (cell) => headerFormatter(cell),
        /*formatter: (cell, row) => troubleTypeFormatter(cell, row)*/
    },
    {
        dataField: 'windowEnd',
        text: 'Window End',
        sort: true,
        headerAttrs: {id: 'windowEnd'},
        headerClass: 'headerIconForTroubleTicketsHistory',
        imgSrc: "ico-events-white.svg",
        headerFormatter: (cell) => headerFormatter(cell),
        /*formatter: (cell, row) => troubleTypeFormatter(cell, row)*/
    },
    {
        dataField: 'status',
        text: 'Status',
        sort: true,
        headerAttrs: {id: 'status'},
        headerClass: '',
        imgSrc: "ico-alert-32-white.svg",
        headerFormatter: (cell) => headerFormatter(cell),
        /*formatter: (cell, row) => statusFormatter(cell, row)*/

    }
];

const headerFormatter = (props) => {
    return (
        <>
            <ReactTooltip/>
            <img alt="header-icon" className={`${style.headerIcon} ${style.headerIconForTroubleTicketsHistory}`}
                 src={`../images/svg/${props.imgSrc}`} data-tip={props.text}></img>
        </>
    )
}


const SummaryMaintenance = ({circuit_id}) => {
    const logger = new ConsoleLogger({level: process.env.REACT_APP_LOGGER_LEVEL,});
    const [hasError, setHasError] = React.useState(null);
    const [maintenanceHistoryData, setMaintenanceHistoryData] = useState([]);
    const [enableDesktop, setEnableDesktop] = useState(false);
    const [enableSearch, setEnableSearch] = useState(false);
    const [maintenanceDate, setMaintenanceDate] = useState("30");
    const [loader, setLoader] = useState(false);
    const [loadingFlag, setLoadingFlag] = useState(true);
    const XLSXHeaders = ["Event Number", "Impact Type", "Impact Duration", "Start Time", "End Time", "Status"];

    useEffect(() => {
        getCircuitMaintenance(circuit_id, maintenanceDate);
    }, [])

    const getCircuitMaintenance = async (circuit_id, days) => {
        setLoader(true)
        setLoadingFlag(true);
        SummaryData.getMaintenanceHistory(circuit_id, days).then(resp => {
            setMaintenanceHistoryData(resp.data);
            logger.info(encodeURIComponent(resp.data).replace(/%20/g, " "))
            setLoader(false);
            setLoadingFlag(false);
        }).catch((error) => {
            logger.error("getCircuitMaintenance ERROR: " + error)
            setHasError(error);
        });
    }

    const maintenanceHandler = async (ev) => {
        let days = ev.target.value;
        logger.info(encodeURIComponent(days).replace(/%20/g, " "));
        setMaintenanceDate(ev.target.value);
        getCircuitMaintenance(circuit_id, days);
    }


    return (
        <>
            <div className="sub-hdr">
                <div className="nib">
                    <div className="the-notch">
                        <div className="notch-fg">
                            <svg>
                                <path
                                    d="M35.81,26.28l-13,10v-5C-10.47,28.68-1,9.16,11.79,0c0,7.28,0,9.13,0,13.28s3.18,7.92,11,9v-5Z"></path>
                            </svg>
                        </div>
                    </div>
                </div>
                <h2>Maintenance</h2>
            </div>
            {loader ? <Loader/> : null}
            <div className="text-right mb-1">
                <button className="saveButton ico-button light-button" onClick={() => XLSXExport.exportToExcel(XLSXHeaders, maintenanceHistoryData, `Dashboard Export_${Date.now()}`)}>
                    <span><strong>Export</strong></span>
                    <svg viewBox="0 0 34 34" className={`ico light-ico ico-arrow downloadArrow`}>
                        <path className="cls-1" d="M1,18V16L17,31V1h2V31L35,16v2L20,33H35v2H1V33H16Z"
                            transform="translate(-1 -1)">
                        </path>
                    </svg>
                </button>
            </div>
            <select className="nice-select right mb-4" onChange={maintenanceHandler} value={maintenanceDate}>
                {maintenance_options.map(item => (
                    <option key={item.value} value={item.value}>
                        {item.name}
                    </option>
                ))}
            </select>
            {hasError && (
                <HTTPErrorHandler error={hasError}></HTTPErrorHandler>
            )}
            {!hasError && 
            <div id="tickets-history-wrap" className="wrap">
                <div className="section TroubleTicket-page">
                    <div className="content">
                        {loadingFlag ?
                            <p>Loading Data...</p> :
                            <div
                                className={`rel table-style-wrap pagination-table page-list mb-3 troubleTicketTable troubleTicketHistoryTable ${enableSearch ? 'searchableEnableTable' : null} ${enableDesktop ? 'desktopViewTable' : null} `}>
                                {maintenanceHistoryData.length > 0 ?
                                    <BootstrapTable id="troubleTicket"
                                                    keyField='eventNumber'
                                                    data={maintenanceHistoryData}
                                                    columns={enableDesktop ? columnsDesktop : columns}
                                                    hover
                                                    condensed
                                                    bordered={false}
                                                    pagination={paginationFactory()}
                                                    filter={filterFactory()}
                                                    defaultSortDirection="desc"
                                                    className="mb-0"
                                    />
                                    : <p>There is no maintenance data available in the last {maintenanceDate} days</p>
                                }
                            </div>
                        }
                    </div>
                </div>
            </div>
            }
        </>
    )


}

export default SummaryMaintenance;
