import {QUOTE_PROFILE} from "../actions/types/actionTypes";

const initialState = {
    quoteProfile: {}
};


const quoteProfileReducer = (state = initialState, action) => {
    switch (action.type) {
        case QUOTE_PROFILE:
            return {
                ...state,
                quoteProfile: action.payload
            }
        default:
            return state

    }
}
export default quoteProfileReducer;
