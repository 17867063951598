import axios from "./axios/customAxios";

const getEAccessNNILocation = async () => {
	const eaNNILocationURL = `${process.env.REACT_APP_API_URL}/getAddress.php?type=eaccess_nni_list`;
    return await axios.get(eaNNILocationURL);
}

const getEAccessNNIBW = async () =>{
	const eaNNIBWUrl = `${process.env.REACT_APP_API_URL}/getBandwidths.php?product=22&menu=bw`;
    return await axios.get(eaNNIBWUrl);
}


const EAccessNNIHelper = {
    getEAccessNNILocation,
    getEAccessNNIBW
}

export default EAccessNNIHelper;
