import { useEffect, useState, useRef, useCallback, useMemo } from 'react';
import ViewportWarning from "../shared/ViewportWarning";
import TabsOrderManager from "./includes/TabsOrderManager";
import style from './OrderCreate.module.css';
import OrderCustomerInfo from './OrderCustomerInfo';
import OrderServiceInfo from './OrderServiceInfo';
import OrderSiteInfo from './OrderSiteInfo';
import OrderProductDetails from './OrderProductDetails'
import OrderReview from './OrderReview'
import { connect, useSelector } from "react-redux";
import QuoteHelper from '../../services/QuoteService';
import OrderHelper from '../../services/OrderService';
import { resetOrderData, setQuotedData, setOrderServiceForm } from '../../redux/actions/orderCreateActions';
import { setSessionError } from '../../redux/actions/errorHandlerActions';
import Loader from '../shared/Loader';
import { Redirect, useParams, useLocation } from "react-router-dom";
import { ConsoleLogger } from "../../logger/ConsoleLogger";
import HTTPErrorHandler from "../errorPages/HTTPErrorHandler";
import OrderSaved from './OrderSaved';
import CompanyDropdown from '../shared/CompanyDropdown';
import RenewalHelper from '../../services/RenewalService';
import BroadbandHelper from '../../services/BroadbandServices';
import PdfComponent from '../PDF/PDFComponent.js';

const OrderCreate = (props) => {
	const logger = useMemo(() => new ConsoleLogger({ level: process.env.REACT_APP_LOGGER_LEVEL, }), []);
	const [hasError, setHasError] = useState(null);
	const [step, setStep] = useState(1);
	const [errorMSG, setErrorMSG] = useState('');
	const [successMSG, setSuccessMSG] = useState('');
	const [popupShow, setPopupShow] = useState(false);
	const [loader, setLoader] = useState(false);
	const [orderFlag, setOrderFlag] = useState(false);
	const [orderdable, setOrderableFlag] = useState(true);
	const [ordered, setOrderedFlag] = useState(false);
	const [savedFlag, setSavedFlag] = useState(false);
	const [savedOrderFlag, setSavedOrderFlag] = useState(false);
	const [cancelOrderFlag, setCancelOrderFlag] = useState(false);
	const [generateServiceOrderMsg, setGenerateServiceOrderMsg] = useState("");
	const [generatePDF, setGeneratePDF] = useState(false);
	const [orderData, setOrderData] = useState({});
	const { refId } = useParams();
	const sofMsgRef = useRef(null);
	const location = useLocation();
	const quoteViewData = location?.state?.quoteViewData;

	const selectedQuotedData = useSelector(state => state.orderCreate.quotedData);
	const selectedOrderContactInfo = useSelector(state => state.orderCreate.contactInfo);
	const selectedOrderGeneralInfo = useSelector(state => state.orderCreate.generalInfo);
	const selectedOrderServiceInfo = useSelector(state => state.orderCreate.serviceInfo);
	const selectedOrderSiteInfoA = useSelector(state => state.orderCreate.locA);
	const selectedOrderSiteInfoZ = useSelector(state => state.orderCreate.locZ);
	const selectedOrderProductDetails = useSelector(state => state.orderCreate.productDetails);
	const selectedOrderReviewValues = useSelector(state => state.orderCreate.orderReview);
	const selectedOrderVRFDetails = useSelector(state => state.orderCreate.vrfDetails);
	const selectedOrderMplsRouteDetails = useSelector(state => state.orderCreate.mplsRouteDetails);

	const selectedOrderAccess = useSelector(state => state.userReducer.user?.webaccess?.TRANS_ORDER);

	const isEmployee = localStorage.getItem('isEmployeeLoggedIn');
	const isCustomer = localStorage.getItem('isLoggedIn');


	const [stepList, setStepList] = useState([]);

	const customerInfoFields = [
		{ id: 0, labelName: "Primary Contact", code: "primaryName", value: selectedOrderContactInfo.orig_contact, required: true },
		{ id: 1, labelName: "Primary Phone", code: "primaryPhone", value: selectedOrderContactInfo.orig_phone, required: true },
		{ id: 2, labelName: "Primary Email", code: "primaryEmail", value: selectedOrderContactInfo.orig_email, required: true },
		{ id: 3, labelName: "Tech Contact", code: "techName", value: selectedOrderContactInfo.tech_contact, required: true },
		{ id: 4, labelName: "Tech Phone", code: "techPhone", value: selectedOrderContactInfo.tech_phone, required: true },
		{ id: 5, labelName: "Tech Email", code: "techEmail", value: selectedOrderContactInfo.tech_email, required: true },
		{ id: 6, labelName: "Customer Pon", code: "cust_pon", value: selectedOrderGeneralInfo.cust_pon, required: true },
		{ id: 7, labelName: "Customer Circuit", code: "cust_circuit", value: selectedOrderGeneralInfo.cust_circuit, required: false },
		{ id: 8, labelName: "Requested Service Data", code: "serv_date", value: selectedOrderGeneralInfo.serv_date, required: true },
		{ id: 9, labelName: "Initial Term", code: "intial_term", value: selectedOrderGeneralInfo.initial_term, required: true },
		{ id: 10, labelName: "ACNA", code: "acna", value: selectedOrderGeneralInfo.acna, required: true }
	];

	const serviceInfoFields = [
		{ id: 0, labelName: "Activity Request", code: "cpt_activity", value: selectedOrderServiceInfo.cpt_activity, required: true },
		{ id: 1, labelName: "Bandwidth", code: "bandwidth", value: selectedOrderServiceInfo.bandwidth, required: true },
		{ id: 2, labelName: "Existing Circuit Id", code: "ex_circuit", value: selectedOrderServiceInfo.ex_circuit, required: false },
		{ id: 3, labelName: "Manual Circuit", code: "manual_circuit", value: selectedOrderServiceInfo.manual_circuit, required: false },
		{ id: 4, labelName: "GAID", code: "gaid", value: selectedOrderServiceInfo.gaid, required: false }
	];

	const siteInfoFieldsA = [
		{ id: 0, labelName: "Onsite Name A", code: "a_lcon_name", value: selectedOrderSiteInfoA.a_lcon_name, required: true },
		{ id: 1, labelName: "Onsite Number A", code: "a_lcon_phone", value: selectedOrderSiteInfoA.a_lcon_phone, required: true },
		{ id: 2, labelName: "Onsite Email A", code: "a_lcon_email", value: selectedOrderSiteInfoA.a_lcon_email, required: true },
		{ id: 3, labelName: "Handoff A", code: "a_handoff", value: selectedOrderSiteInfoA.a_handoff, required: true },
		{ id: 4, labelName: "Demarcation Point A", code: "a_dem_pt", value: selectedOrderSiteInfoA.a_dem_pt, required: true },
		{ id: 5, labelName: "Fiber Connector A", code: "a_fiber_conn", value: selectedOrderSiteInfoA.a_fiber_conn, required: false },
		{ id: 6, labelName: "Customer A Name", code: "a_cp_name", value: selectedOrderSiteInfoA.a_cp_name, required: true },
		{ id: 7, labelName: "Location A Notes", code: "a_remarks", value: selectedOrderSiteInfoZ.a_remarks, required: false }
	];

	const siteInfoFieldsZ = [
		{ id: 0, labelName: "Onsite Name Z", code: "z_lcon_name", value: selectedOrderSiteInfoZ.z_lcon_name, required: true },
		{ id: 1, labelName: "Onsite Number Z", code: "z_lcon_phone", value: selectedOrderSiteInfoZ.z_lcon_phone, required: true },
		{ id: 2, labelName: "Onsite Email Z", code: "z_lcon_email", value: selectedOrderSiteInfoZ.z_lcon_email, required: true },
		{ id: 3, labelName: "Handoff Z", code: "z_handoff", value: selectedOrderSiteInfoZ.z_handoff, required: true },
		{ id: 4, labelName: "Demarcation Point Z", code: "z_dem_pt", value: selectedOrderSiteInfoZ.z_dem_pt, required: true },
		{ id: 5, labelName: "Fiber Connector Z", code: "z_fiber_conn", value: selectedOrderSiteInfoZ.z_fiber_conn, required: false },
		{ id: 6, labelName: "Customer Z Name", code: "z_cp_name", value: selectedOrderSiteInfoZ.z_cp_name, required: true },
		{ id: 7, labelName: "Location Z Notes", code: "z_remarks", value: selectedOrderSiteInfoZ.z_remarks, required: false }
	];

	//DIA POP & PREM Product Details
	const diaDetails = [
		{ id: 0, labelName: "Wan Requirements", code: "wan_id", value: selectedOrderProductDetails.wan_id, required: true },
		{ id: 1, labelName: "Lan Requirements", code: "lan_id", value: selectedOrderProductDetails.lan_id, required: true },
		{ id: 2, labelName: "Static Ips", code: "ips", value: selectedQuotedData.ip_key, required: true },
	]

	//E-Access Product Details
	const eaccessDetails = [
		{ id: 0, labelName: "NNI Circuit ID", code: "nni_circuit_id", value: selectedOrderProductDetails.nni_circuit_id, required: true },
		{ id: 1, labelName: "MTU Size", code: "ceMtu", value: selectedOrderProductDetails.ceMtu, required: true },
		{ id: 2, labelName: "Other", code: "mtu", value: selectedOrderProductDetails.mtu_other, required: false },
		{ id: 3, labelName: "VLAN", code: "ceVlan", value: selectedOrderProductDetails.ceVlan, required: true },
		{ id: 4, labelName: "Customer Assigned", code: "vlan_customer", value: selectedOrderProductDetails.vlan_customer, required: false },
		{ id: 5, labelName: "Interconnection Point Nni Location", code: "interNNi", value: selectedOrderProductDetails.interNNi, required: true }
	];

	//E-LINE Product Details
	const elineDetails = [
		{ id: 0, labelName: "EVCs", code: "nni_circuit_id", value: selectedOrderProductDetails.eline_evc, required: false },
		{ id: 1, labelName: "MTU Size", code: "ceMtu", value: selectedOrderProductDetails.ceMtu, required: true },
		{ id: 2, labelName: "Layer 2 Control", code: "mtu", value: selectedOrderProductDetails.eline_l2cp, required: true },
		{ id: 3, labelName: "VLAN", code: "ceVlan", value: selectedOrderProductDetails.ceVlan, required: true },
		{ id: 4, labelName: "Customer Assigned", code: "vlan_customer", value: selectedOrderProductDetails.vlan_customer, required: false },
	]

	//MPLS INTERNET AND TAIL Product Details
	const mplsTailInternetDetails = [
		{ id: 0, labelName: "NNI Location", code: "nni_circuit_id", value: selectedOrderVRFDetails.nni, required: true },
		{ id: 1, labelName: "Vlan", code: "ceVlan", value: selectedOrderProductDetails.ceVlan, required: true },
		{ id: 2, labelName: "Circuit or VRF", code: "mpls_circuit", value: selectedOrderVRFDetails.mpls_circuit, required: true },
		{ id: 3, labelName: "Check New VLAN", code: "newVlan", value: selectedOrderProductDetails.newVlan, required: false },
		{ id: 4, labelName: "Remove VRF Override", code: "override", value: selectedOrderVRFDetails.override, required: false },
		{ id: 5, labelName: "BGP VRF ", code: "bgp", value: selectedOrderVRFDetails.bgp, required: false },
		{ id: 6, labelName: "VRF ASN ", code: "asn", value: selectedOrderVRFDetails.asn, required: false },
		{ id: 7, labelName: "VRF Prefix ", code: "prefix", value: selectedOrderVRFDetails.prefix, required: false },
		{ id: 8, labelName: "Network Name ", code: "network_name", value: selectedOrderVRFDetails.network_name, required: false },
		{ id: 9, labelName: "Mpls Route", code: "mpls_route", value: selectedOrderMplsRouteDetails.mpls_route, required: true },
		{ id: 10, labelName: "Mpls Route Remove Override", code: "remove_override", value: selectedOrderMplsRouteDetails.remove_override, required: true },
		{ id: 11, labelName: "BGP Comm", code: "bgp_comm", value: selectedOrderMplsRouteDetails.bgp_comm, required: true },
		{ id: 12, labelName: "Remote ASN", code: "remote_asn", value: selectedOrderMplsRouteDetails.remote_asn, required: true },
		{ id: 13, labelName: "QOS", code: "qos", value: selectedOrderMplsRouteDetails.qos, required: true },
		{ id: 14, labelName: "BGP Prefix", code: "bgp_prefix", value: selectedOrderMplsRouteDetails.bgp_prefix, required: true },
		{ id: 15, labelName: "Customer WAN IP", code: "wan_ip", value: selectedOrderMplsRouteDetails.wan_ip, required: false },
		{ id: 16, labelName: "Static IP Blocks", code: "mpls_static_ip", value: selectedOrderMplsRouteDetails.mpls_static_ip, required: false }
	]

	useEffect(() => {
		sofMsgRef.current?.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
		setGeneratePDF(false);
	}, [selectedOrderReviewValues.generateServiceOrder]);

	const getQuotedData = useCallback((refId) => {
		QuoteHelper.getQuoteView(refId, '', '', 1).then(resp => {
			if (resp.data.error) {
				logger.info("Quote does not exist redirecting user");
				props.updateErrorMsg("Quote Not Found. Please create a new quote.");
				setOrderableFlag(false);
			} else if (!resp.data.orderable) {
				logger.info("Quote is not orderable");
				window.location = `/Quote/View/${refId}`;
			} else if (resp.data.expiredQuote) {
				logger.info("Quote is expired and not orderable");
				window.location = `/Quote/View/${refId}`;
			} else if (Number(resp.data.profile_product) === 0) {
				logger.info("Profile Product not turned on. Redirecting user to Quote View");
				window.location = `/Quote/View/${refId}`;
			} else if (resp.data.order_exist && resp.data.order_exist.toLowerCase() !== "saved") {
				logger.info("Order already exist redirect to OrderView");
				setOrderedFlag(true);
			} else {
				logger.info(encodeURIComponent(resp.data).replace(/%20/g, " "));
				console.log("................Order Create lineItems: " + JSON.stringify(resp.data?.lineitems))
				props.updateQuotedData(resp.data);
			}
		}).catch(error => {
			logger.error("Getting Quoted Data: " + error);
			setHasError(error);
		})
	}, [logger, props])

	/*Calls php script to check if the ref id is linked to a saved order*/
	const savedOrderCheck = useCallback((refId) => {
		setLoader(true);
		OrderHelper.orderStatusCheck(refId).then(resp => {
			logger.info(encodeURIComponent(resp.data).replace(/%20/g, " "));
			if (resp.data === "saved") {
				logger.info("Order was saved in the database");
				setSavedFlag(true);
				setLoader(false);
			} else {
				logger.info("Order was NOT saved in the database");
				setSavedFlag(false);
				setLoader(false);
			}

		}).catch(error => {
			logger.error(`Checking to see if an order was saved ${error}`);
			setLoader(false);
		})
	}, [logger])

	useEffect(() => {
		props.clearCreateOrderObj();
	}, [props])

	useEffect(() => {
		//if user is coming from the quote view page then we already have quote data in props so no need to get it again
		if (!quoteViewData || quoteViewData?.variablePricing) {
			getQuotedData(refId);
		} else {
			props.updateQuotedData(quoteViewData);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [getQuotedData, quoteViewData, refId]);

	useEffect(() => {
		if (selectedQuotedData) {
			if (selectedQuotedData.product_id === "15" || selectedQuotedData.product_id === "23" || (selectedQuotedData.q_cpt_activity === "R" && selectedQuotedData.product_id !== "10" && selectedQuotedData.product_id !== "11")) {
				setStepList(['Customer Information', 'Service Information', 'Site Information', 'Review & Create Order']);
			} else {
				setStepList(['Customer Information', 'Service Information', 'Site Information', 'Product Details', 'Review & Create Order']);
			}
		}
	}, [selectedQuotedData])

	useEffect(() => {
		savedOrderCheck(refId);
	}, [refId, savedOrderCheck])

	const cancelAction = () => {
		setErrorMSG('');
		setSuccessMSG('');
		setPopupShow(true);
	}

	const nextPage = () => {
		validationStep();
	}

	const prevPage = () => {
		if (step > 1) {
			setStep(step - 1);
		}
	}

	const validationStep = () => {
		let flag = 0;
		let findRequired = null;

		if (step === 1) {
			logger.info("Step 1 Validation");

			//Wholesale Broadband doesn't require a service date or an acna
			if (selectedQuotedData.product_id === "23") {
				customerInfoFields[8].required = false;
				customerInfoFields[10].required = false;
			}
			//ACNA is not required for Renewals
			/* ADO #883712
			if (selectedQuotedData.q_cpt_activity === "R") {
				customerInfoFields[10].required = false;
			}
			*/
			findRequired = customerInfoFields.find(UF => (UF.value === '' || UF.value === undefined || UF.value === '0000-00-00') && UF.required);
		}

		if (step === 2) {
			logger.info("Step 2 Validation");
			//Override cpt_activity for renewals since we only care about q_cpt_activity
			if (selectedQuotedData.q_cpt_activity === "R") {
				serviceInfoFields[0].required = false;
			}
			if (selectedQuotedData.product_id === "15") {
				serviceInfoFields[1].required = false;
			}
			if (selectedOrderServiceInfo.cpt_activity === "B") {
				serviceInfoFields[2].required = true;
				if (selectedOrderServiceInfo.ex_circuit[0] === "Manual Circuit") {
					serviceInfoFields[3].required = true;
					serviceInfoFields[4].required = true;
				}
			}
			findRequired = serviceInfoFields.find(UF => (UF.value === '' || UF.value === undefined) && UF.required);

		}

		if (step === 3) {
			logger.info("Step 3 Validation");

			//Colocation doesn't require location a handoff or demarcation point
			if (selectedQuotedData.product_id === "15") {
				siteInfoFieldsA[3].required = false;
				siteInfoFieldsA[4].required = false;
			}
			//EAccess and Wholesale Broadband doesn't require demarcation point A
			if (selectedQuotedData.product_id === "22" || selectedQuotedData.product_id === "23") {
				siteInfoFieldsA[4].required = false;
			}

			//Certain products and handoff selection make fiber connector types required
			if (Number(selectedQuotedData.product_id) < 4 || (selectedQuotedData.circuit_id === "6" && (selectedOrderSiteInfoA.a_handoff === "6" || selectedOrderSiteInfoA.a_handoff === "7" || selectedOrderSiteInfoA.a_handoff === "8"))) {
				siteInfoFieldsA[5].required = true;
			}

			//DIA PREM doesn't require a handoffs
			if (selectedQuotedData.circuit_id === "14") {
				siteInfoFieldsA[3].required = false;
			}

			if (selectedQuotedData.q_cpt_activity === "R") {
				siteInfoFieldsA[4].required = false;
				siteInfoFieldsA[7].required = false;
			}

			let findRequiredA = siteInfoFieldsA.find(UF => (UF.value === '' || UF.value === undefined) && UF.required);

			if (!!findRequiredA) {
				setErrorMSG('Please verify all required Loc A fields are completed.');
				flag = 1;
				return;
			} else {
				setErrorMSG('');
			}

			if (selectedQuotedData.locZ !== "") {
				logger.info("Validating Location Z Info");

				//EAccess doesn't require demarcation point Z
				if (selectedQuotedData.product_id === "22") {
					siteInfoFieldsZ[4].required = false;
				}

				//Certain products and handoff selection make fiber connector types required
				if (Number(selectedQuotedData.product_id) < 4 || (selectedQuotedData.circuit_id === "6" && (selectedOrderSiteInfoA.a_handoff === "6" || selectedOrderSiteInfoA.a_handoff === "7" || selectedOrderSiteInfoA.a_handoff === "8"))) {
					siteInfoFieldsZ[5].required = true;
				}

				if (selectedQuotedData.q_cpt_activity === "R") {
					siteInfoFieldsZ[4].required = false;
					siteInfoFieldsZ[7].required = false;
				}

				let findRequiredZ = siteInfoFieldsZ.find(UF => (UF.value === '' || UF.value === undefined) && UF.required);
				if (!!findRequiredZ) {
					setErrorMSG('Please verify all required fields are completed.');
					flag = 1;
					return;
				} else {
					setErrorMSG('');
				}
			}
		}

		if (step === 4) {
			logger.info("Step 4 Validation");
			switch (selectedQuotedData.product_id) {
				//MPLS TAIL & INTERNET
				case "6":
				case "13":
					if (selectedOrderProductDetails.newVlan === true) {
						mplsTailInternetDetails[5].required = true;
						mplsTailInternetDetails[6].required = true;
						mplsTailInternetDetails[7].required = true;
						mplsTailInternetDetails[8].required = true;
					}
					//Turning off certain required fields for mpls if static is selected
					if (selectedOrderMplsRouteDetails.mpls_route === "Static") {
						mplsTailInternetDetails[10].required = false;
						mplsTailInternetDetails[11].required = false;
						mplsTailInternetDetails[12].required = false;
						mplsTailInternetDetails[14].required = false;
						mplsTailInternetDetails[16].required = true;
					}
					if (selectedOrderMplsRouteDetails.own_ip === "Y") {
						mplsTailInternetDetails[15].required = true;
					}
					findRequired = mplsTailInternetDetails.find(UF => (UF.value === '' || UF.value === undefined) && UF.required);
					break;
				//DIA POP & DIA PREM
				case "10":
				case "11":
					//WAN and LAN not required for DIA PREM BROADBAND or for DIA POP or PREM Renewals
					if (selectedQuotedData.circuit_id === "14" || selectedQuotedData.q_cpt_activity === "R") {
						diaDetails[0].required = false;
						diaDetails[1].required = false;
					}
					findRequired = diaDetails.find(UF => (UF.value === '' || UF.value === undefined) && UF.required);
					break;
				//ELINE
				case "19":
					if (selectedOrderProductDetails.ceVlan === "c") {
						//Customer Assigned field is required
						elineDetails[4].required = true;
					}
					//EVCS are only required for EVPL
					if (selectedQuotedData.circuit_id === "12") {
						elineDetails[0].required = true;
					}
					findRequired = elineDetails.find(UF => (UF.value === '' || UF.value === undefined) && UF.required);
					break;
				//EACCESS
				case "20":
					if (selectedOrderProductDetails.ceMtu === "other") {
						//MTU OTHER FIELD REQUIRED
						eaccessDetails[2].required = true;
					}
					if (selectedOrderProductDetails.ceVlan === "c") {
						//Customer Assigned field is required
						eaccessDetails[4].required = true;
					}
					findRequired = eaccessDetails.find(UF => (UF.value === '' || UF.value === undefined) && UF.required);
					break;
				default:
					break;
			}

			//Checking Jurisdiction Selection
			if (Number(selectedQuotedData.product_id) < 4 || selectedQuotedData.product_id === "19" || selectedQuotedData.product_id === "20" || selectedQuotedData.product_id === "22" || selectedQuotedData.product_id === "7") {
				if (selectedOrderProductDetails.jurisdiction === "") {
					setErrorMSG('Please verify all required fields are completed.');
					flag = 1;
					return;
				} else {
					setErrorMSG('');
				}
			}
		}

		if (!!findRequired) {
			setErrorMSG('Please verify all required fields are completed.');
			flag = 1;
			return;
		} else {
			setErrorMSG('');
		}

		if (flag === 0) {
			finalvalidation();
		}
	}

	const finalvalidation = (props) => {
		if (selectedQuotedData.product_id === "15" || selectedQuotedData.product_id === "23" || (selectedQuotedData.q_cpt_activity === "R" && selectedQuotedData.product_id !== "10" && selectedQuotedData.product_id !== "11")) {
			if (step < 4) {
				setStep(step + 1);
			}
		} else {
			if (step < 5) {
				setStep(step + 1);
			}
		}
	}

	const saveOrder = () => {
		logger.info("Saving Order");
		submitOrder("Save Request");
	}

	const cancelOrder = () => {
		logger.info("Cancel Order");
		if (selectedQuotedData.q_cpt_activity === "R") {
			renewalSubmit("Cancel Order");
		} else {
			submitOrder("Cancel Order");
		}
	}

	const renewalSubmit = (action) => {
		logger.info("Renewal Submit");
		let renewalJSON = {
			"action": action ? action : "",
			"ref_id": refId?.length > 0 ? refId : selectedQuotedData.ref_id,
			"single_term": selectedOrderGeneralInfo.initial_term,
			"acna": selectedOrderGeneralInfo.acna,
			"new_orig_contact": selectedOrderContactInfo.new_orig_contact,
			"orig_contact": selectedOrderContactInfo.orig_contact,
			"orig_phone": selectedOrderContactInfo.orig_phone,
			"orig_email": selectedOrderContactInfo.orig_email,
			"new_tech_contact": selectedOrderContactInfo.new_tech_contact,
			"tech_contact": selectedOrderContactInfo.tech_contact,
			"tech_phone": selectedOrderContactInfo.tech_phone,
			"tech_email": selectedOrderContactInfo.tech_email,
			"cust_circuit": selectedOrderGeneralInfo.cust_circuit,
			"cust_pon": selectedOrderGeneralInfo.cust_pon,
			"serv_date": selectedOrderGeneralInfo.serv_date,
			"exp": selectedOrderGeneralInfo.expedite_req,
			"order_term": selectedOrderGeneralInfo.initial_term,
			"cpt_activity": selectedOrderServiceInfo.cpt_activity,
			"order_qty": selectedOrderServiceInfo.order_qty,
			"bandwidth": selectedOrderServiceInfo.bandwidth,
			"exist_circuit": selectedOrderServiceInfo.ex_circuit,
			"ips": selectedQuotedData.ip_key, //setting the ips to whatever was selected on the renewal quote
			"a_cp_name": selectedOrderSiteInfoA.a_cp_name,
			"a_lcon_name": selectedOrderSiteInfoA.a_lcon_name,
			"a_lcon_phone": selectedOrderSiteInfoA.a_lcon_phone,
			"a_lcon_email": selectedOrderSiteInfoA.a_lcon_email,
			"a_handoff": selectedOrderSiteInfoA.a_handoff,
			"a_fiber_conn": selectedOrderSiteInfoA.a_fiber_conn,
			"z_cp_name": selectedOrderSiteInfoZ.z_cp_name ? selectedOrderSiteInfoZ.z_cp_name : selectedOrderSiteInfoA.a_cp_name,
			"z_lcon_name": selectedOrderSiteInfoZ.z_lcon_name ? selectedOrderSiteInfoZ.z_lcon_name : selectedOrderSiteInfoA.a_lcon_name,
			"z_lcon_phone": selectedOrderSiteInfoZ.z_lcon_phone ? selectedOrderSiteInfoZ.z_lcon_phone : selectedOrderSiteInfoA.a_lcon_phone,
			"z_lcon_email": selectedOrderSiteInfoZ.z_lcon_email ? selectedOrderSiteInfoZ.z_lcon_email : selectedOrderSiteInfoA.a_lcon_email,
			"z_handoff": selectedOrderSiteInfoZ.z_handoff ? selectedOrderSiteInfoZ.z_handoff : selectedOrderSiteInfoA.a_handoff,
			"z_fiber_conn": selectedOrderSiteInfoZ.z_fiber_conn ? selectedOrderSiteInfoZ.z_fiber_conn : selectedOrderSiteInfoA.a_fiber_conn
		}

		setLoader(true);
		RenewalHelper.submitRenewalOrder(renewalJSON).then(response => {
			logger.info(`Renewal Response: ${response.data}`);
			if (response.data.result === "SUCCESS") {
				setLoader(false);
				if (action === "Submit Order") {
					logger.info("Renewal Order has been created");
					if (response.data.generatePDF === 1) { setGeneratePDF(true); }
					setSuccessMSG("RENEWAL ORDER CREATED");
					setOrderFlag(true);//->Time to redirect to order view page
				} else if (action === "Save Request") {
					logger.info("Order has been saved");
					setSuccessMSG("ORDER SAVED");
					setSavedOrderFlag(true);
				} else if (action === "Generate Service Order") {
					setGenerateServiceOrderMsg("");
					props.generateServiceOrderForm(false);
					if (response.data.generatePDF === 1) {
						OrderHelper.getOrderView(refId)
							.then(response => {
								if (!response.data.order_id) {
									console.error("Error fetching order data");
								} else {
									setOrderData(response.data);
									setGeneratePDF(true);
									logger.info("Service order form being generated");
									setGenerateServiceOrderMsg("Service order successfully generated, but NOT submitted to Windstream");
								}
							})
							.catch(error => {
								console.error("Error fetching order data", error);
							});
					}
				} else {
					logger.info("Order has been cancelled");
					setSuccessMSG("ORDER CANCELLED");
					setCancelOrderFlag(true);
				}
			} else {
				setLoader(false);
				setErrorMSG(response.data.error);
			}
		}).catch(error => {
			setLoader(false);
			logger.error(`error creating renewal order: ${error}`);
		})
	}

	const submitBroadbandOrder = () => {
		logger.info("Broadband Submit");
		let broadbandJSON = {
			"ref_id": refId?.length > 0 ? refId : selectedQuotedData.ref_id,
			"single_term": selectedOrderGeneralInfo.initial_term,
			"new_orig_contact": selectedOrderContactInfo.new_orig_contact,
			"orig_contact": selectedOrderContactInfo.orig_contact,
			"orig_phone": selectedOrderContactInfo.orig_phone,
			"orig_email": selectedOrderContactInfo.orig_email,
			"new_tech_contact": selectedOrderContactInfo.new_tech_contact,
			"tech_contact": selectedOrderContactInfo.tech_contact,
			"tech_phone": selectedOrderContactInfo.tech_phone,
			"tech_email": selectedOrderContactInfo.tech_email,
			"cust_pon": selectedOrderGeneralInfo.cust_pon,
			"order_term": selectedOrderGeneralInfo.initial_term,
			"cpt_activity": selectedOrderServiceInfo.cpt_activity,
			"order_qty": selectedOrderServiceInfo.order_qty,
			"bandwidth": selectedOrderServiceInfo.bandwidth,
			"circuit": selectedQuotedData.circuit_id,
			"product": selectedQuotedData.product_id,
			"ips": selectedQuotedData.ip_key,
			"a_handoff": selectedOrderSiteInfoA.a_handoff,
			"a_cp_name": selectedOrderSiteInfoA.a_cp_name,
			"a_lcon_name": selectedOrderSiteInfoA.a_lcon_name,
			"a_lcon_phone": selectedOrderSiteInfoA.a_lcon_phone,
			"a_lcon_email": selectedOrderSiteInfoA.a_lcon_email,
			"notes": selectedOrderReviewValues.notes,
			"notes_internal": selectedOrderReviewValues.notes_internal
		}

		setLoader(true);
		BroadbandHelper.createKineticBroadbandOrder(broadbandJSON).then(response => {
			logger.info(`Broadband Response: ${JSON.stringify(response.data)}`);
			if (response.data?.result === "SUCCESS") {
				if (response.data.generatePDF === 1) {
					setGeneratePDF(true);
				}
				setLoader(false);
				setSuccessMSG("ORDER CREATED");
				setOrderFlag(true);
			} else {
				setLoader(false);
				setErrorMSG(response.data.error);
			}
		}).catch(error => {
			logger.error(`Error creating wholesale broadband order: ${error}`);
		})


	}

	const submitOrder = (action) => {
		if (selectedOrderReviewValues.conditions === false && action !== "Cancel Order" && isCustomer) {
			setErrorMSG('Terms and Conditions must be accepted before submitting order.');
			return;
		} else {
			setErrorMSG('');
		}

		//Final json that will be sent to quoteSubmit.php
		let submitJSON = {
			//hidden values
			"action": action ? action : "",
			"ref_id": refId?.length > 0 ? refId : selectedQuotedData.ref_id,
			"qId": selectedQuotedData.quote_id,
			"num_routes": selectedQuotedData.num_routes,
			"circuit": selectedQuotedData.circuit_id,
			"product": selectedQuotedData.product_id,
			"la_mux": selectedQuotedData.la_mux,
			"la_conx": selectedQuotedData.la_conx,
			"interNNi": selectedOrderProductDetails.interNNi,
			////ELINE OR EACCESS "ce_svc_type"
			"diversity": selectedQuotedData.diversity,
			"icb": selectedQuotedData.icb,
			"manual_flag": selectedQuotedData.manual_flag,
			///User Input Values
			"a_handoff": selectedOrderSiteInfoA.a_handoff,
			"z_handoff": selectedOrderSiteInfoZ.z_handoff ? selectedOrderSiteInfoZ.z_handoff : selectedOrderSiteInfoA.a_handoff,
			"orig_serv_data": "",
			"single_term": selectedOrderGeneralInfo.initial_term,
			"acna": selectedOrderGeneralInfo.acna,
			"new_orig_contact": selectedOrderContactInfo.new_orig_contact,
			"orig_contact": selectedOrderContactInfo.orig_contact,
			"orig_phone": selectedOrderContactInfo.orig_phone,
			"orig_email": selectedOrderContactInfo.orig_email,
			"new_tech_contact": selectedOrderContactInfo.new_tech_contact,
			"tech_contact": selectedOrderContactInfo.tech_contact,
			"tech_phone": selectedOrderContactInfo.tech_phone,
			"tech_email": selectedOrderContactInfo.tech_email,
			"cust_circuit": selectedOrderGeneralInfo.cust_circuit,
			"cust_pon": selectedOrderGeneralInfo.cust_pon,
			"serv_date": selectedOrderGeneralInfo.serv_date,
			"exp": selectedOrderGeneralInfo.expedite_req,
			"order_term": selectedOrderGeneralInfo.initial_term,
			"cpt_activity": selectedOrderServiceInfo.cpt_activity,
			"order_qty": selectedOrderServiceInfo.order_qty,
			"bandwidth": selectedOrderServiceInfo.bandwidth,
			"exist_circuit": selectedOrderServiceInfo?.ex_circuit,
			"manual_circuit": selectedOrderServiceInfo?.manual_circuit,
			"gaid": selectedOrderServiceInfo.gaid,
			"a_cp_name": selectedOrderSiteInfoA.a_cp_name,
			"a_lcon_name": selectedOrderSiteInfoA.a_lcon_name,
			"a_lcon_phone": selectedOrderSiteInfoA.a_lcon_phone,
			"a_lcon_email": selectedOrderSiteInfoA.a_lcon_email,
			"a_dem_pt": selectedOrderSiteInfoA.a_dem_pt,
			"a_remarks": selectedOrderSiteInfoA.a_remarks,
			"a_fiber_conn": selectedOrderSiteInfoA.a_fiber_conn,
			"z_cp_name": selectedOrderSiteInfoZ.z_cp_name ? selectedOrderSiteInfoZ.z_cp_name : selectedOrderSiteInfoA.a_cp_name,
			"z_lcon_name": selectedOrderSiteInfoZ.z_lcon_name ? selectedOrderSiteInfoZ.z_lcon_name : selectedOrderSiteInfoA.a_lcon_name,
			"z_lcon_phone": selectedOrderSiteInfoZ.z_lcon_phone ? selectedOrderSiteInfoZ.z_lcon_phone : selectedOrderSiteInfoA.a_lcon_phone,
			"z_lcon_email": selectedOrderSiteInfoZ.z_lcon_email ? selectedOrderSiteInfoZ.z_lcon_email : selectedOrderSiteInfoA.a_lcon_email,
			"z_dem_pt": selectedOrderSiteInfoZ.z_dem_pt ? selectedOrderSiteInfoZ.z_dem_pt : selectedOrderSiteInfoA.a_dem_pt,
			"z_remarks": selectedOrderSiteInfoZ.z_remarks ? selectedOrderSiteInfoZ.z_remarks : selectedOrderSiteInfoA.a_remarks,
			"z_fiber_conn": selectedOrderSiteInfoZ.z_fiber_conn ? selectedOrderSiteInfoZ.z_fiber_conn : selectedOrderSiteInfoA.a_fiber_conn,
			"wan_id": selectedOrderProductDetails.wan_id,
			"lan_id": selectedOrderProductDetails.lan_id,
			"ips": selectedQuotedData.ip_key,//currently hardset to the ip from quote data
			"notes": selectedOrderReviewValues.notes,
			"notes_internal": selectedOrderReviewValues.notes_internal,
			"general_notes": selectedOrderReviewValues.general_notes,
			"rampup": selectedOrderProductDetails.rampup,
			"jurisdiction": selectedOrderProductDetails.jurisdiction,
			"ceMtu": selectedOrderProductDetails.ceMtu,
			"mtu_other": selectedOrderProductDetails.mtu_other,
			"ceVlan": selectedOrderProductDetails.ceVlan[0],
			"vlan_customer": selectedOrderProductDetails.vlan_customer,
			"nni_circuit_id": selectedOrderProductDetails.nni_circuit_id,
			"eline_l2cp": selectedOrderProductDetails.eline_l2cp,
			"eline_evc": selectedOrderProductDetails.eline_evc,
			"vrf_conn": selectedOrderVRFDetails.vrf_conn,
			"nni": selectedOrderVRFDetails.nni,
			"vlan": selectedOrderProductDetails.ceVlan,
			"newVlan": selectedOrderProductDetails.newVlan,
			"mpls_circuit_id": selectedOrderVRFDetails.mpls_circuit,
			"override": selectedOrderVRFDetails.override,
			"bgp": selectedOrderVRFDetails.bgp,
			"asn": selectedOrderVRFDetails.asn,
			"prefix": selectedOrderVRFDetails.prefix,
			"network_name": selectedOrderVRFDetails.network_name,
			"mpls_route": selectedOrderMplsRouteDetails.mpls_route,
			"own_ip": selectedOrderMplsRouteDetails.own_ip,
			"bgp_prefix": selectedOrderMplsRouteDetails.bgp_prefix,
			"remove_override": selectedOrderMplsRouteDetails.remove_override,
			"bgp_comm": selectedOrderMplsRouteDetails.bgp_comm,
			"remote_asn": selectedOrderMplsRouteDetails.remote_asn,
			"qos": selectedOrderMplsRouteDetails.qos,
			"mounting_equip": selectedOrderServiceInfo.mounting_equip,
			"wan_sfp": selectedOrderServiceInfo.wan_sfp,
			"wan_ip": selectedOrderMplsRouteDetails.wan_ip,
			"static_ips": {
				"0": selectedOrderMplsRouteDetails.mpls_static_ip
			}
		}

		logger.info("Order Data: " + JSON.stringify(submitJSON));
		setLoader(true);
		OrderHelper.createOrder(submitJSON).then(resp => {
			logger.info(encodeURIComponent(resp.data).replace(/%20/g, " "));
			logger.info("create order response: " + JSON.stringify(resp.data));
			if (resp.data.result === "SUCCESS") {
				setLoader(false);
				if (action === "Submit Order") {
					if (resp.data.generatePDF === 1) {
						setGeneratePDF(true);
					}
					logger.info("Order has been created");
					setSuccessMSG("ORDER CREATED");
					setOrderFlag(true);//->Time to redirect to order view page
				} else if (action === "Save Request") {
					logger.info("Order has been saved");
					setSuccessMSG("ORDER SAVED");
					setSavedOrderFlag(true);
				} else if (action === "Generate Service Order") {
					setGenerateServiceOrderMsg("");
					props.generateServiceOrderForm(false);
					if (resp.data.generatePDF === 1) {
						OrderHelper.getOrderView(refId)
							.then(response => {
								if (!response.data.order_id) {
									console.error("Error fetching order data");
								} else {
									setOrderData(response.data);
									setGeneratePDF(true);
									logger.info("Service order form being generated");
									setGenerateServiceOrderMsg("Service order successfully generated, but NOT submitted to Windstream");
								}
							})
							.catch(error => {
								console.error("Error fetching order data", error);
							});

					}
				} else {
					logger.info("Order has been cancelled");
					setSuccessMSG("ORDER CANCELLED");
					setCancelOrderFlag(true);
				}
			} else {
				setLoader(false);
				setErrorMSG(resp.data.error);
			}
		}).catch(error => {
			setLoader(false);
			setErrorMSG("FALIED TO Access orderSubmit.php");
			logger.error("createOrder Error: " + error);
			setHasError(error);
		})
		validationStep();
	}

	//Helper function that routes to the correct order submit
	const submitOrderMapper = (action) => {
		if (selectedQuotedData?.q_cpt_activity === "R") {
			renewalSubmit(action);
		} else if(selectedQuotedData?.product_id === "23") {
			submitBroadbandOrder();
		} else {
			submitOrder(action);
		}
	}

	const formReset = () => {
		props.clearCreateOrderObj();
		getQuotedData();
	}

	const popupOk = () => {
		setPopupShow(false);
		if (savedFlag) {
			cancelOrder();
		} else {
			setStep(1);
			formReset();
		}
	}

	const popupCancel = () => {
		setPopupShow(false);
	}

	return (
		<>
			{savedFlag ? <OrderSaved refId={refId} /> : null}
			{hasError && <HTTPErrorHandler error={hasError}></HTTPErrorHandler>}
			{loader ? <Loader /> : null}
			<div className="midsection om-create abs z1">
				<div className="rel">
					<ViewportWarning />
					<div className="wrap">
						<h1 className="page-title">{isEmployee ? "Employee Order Manager" : "Order Manager"}</h1>
						{isEmployee && selectedQuotedData?.mpid && selectedQuotedData?.company ? <CompanyDropdown area={"Order Create"} mpid={selectedQuotedData.mpid} company={selectedQuotedData.company} /> : null}
						<div className="section">
							<TabsOrderManager />
							{!hasError && (<div className={`content has-actions ${style.orderCreatePage}`}>
								<div className="milestones five-steps">
									{stepList.map((stepName, index) => {
										return (
											<>
												<div className={`step ${step === index + 1 ? 'active' : ''} `}>
													<div>{index + 1}</div>
													<span>{stepName}</span>
												</div>
												{(index + 1 === 4 && selectedQuotedData.q_cpt_activity === "R" && selectedQuotedData.product_id !== "10" && selectedQuotedData.product_id !== "11") || (index + 1 === 5) || (index + 1 === 4 && selectedQuotedData.product_id === "15") || (index + 1 === 4 && selectedQuotedData.product_id === "23") ?
													null : <div className="gap">&nbsp;</div>
												}
											</>
										)
									})}
								</div>

								<div ref={sofMsgRef} />
								<p className={style.denoteText}><span className={style.mandatory}>* </span>Denotes a required field</p>

								{generateServiceOrderMsg ? <span><p className="text-success text-center">{generateServiceOrderMsg}</p></span> : null}
								{generatePDF ?
									<>
										<PdfComponent
											refId={refId}
											quoteData={quoteViewData}
											orderData={orderData}
											action={"upload"}
											type={'GENERATESO'}
											comp={"OrderView"}
										/>
									</>
									: null}

								{step === 1 ?
									<OrderCustomerInfo savedFlag={savedFlag} /> : null
								}
								{step === 2 ?
									<OrderServiceInfo /> : null
								}
								{step === 3 ?
									<OrderSiteInfo /> : null
								}
								{step === 4 ?
									<>
										{selectedQuotedData.product_id === "15" || selectedQuotedData.product_id === "23" || (selectedQuotedData.q_cpt_activity === "R" && selectedQuotedData.product_id !== "10" && selectedQuotedData.product_id !== "11")  ?
											<OrderReview /> : <OrderProductDetails />}
									</>
									: null
								}
								{selectedQuotedData.product_id === "15" || selectedQuotedData.product_id === "23" || (selectedQuotedData.q_cpt_activity === "R" && selectedQuotedData.product_id !== "10" && selectedQuotedData.product_id === "11") ?
									null :
									<>
										{step === 5 ?
											<OrderReview /> : null
										}
									</>
								}
								<div className={style.pageActions}>
									<div className="text-center">
										<p className='text-danger mt-3'>{errorMSG}</p>
										<p className='text-success mt-3'>{successMSG}</p>

										{isEmployee || (isCustomer && selectedOrderAccess > 1) ?
											<button className={` ico-button light-button `} onClick={cancelAction} >
												{savedFlag ? "Cancel Order" : "Clear"}
												<svg className="ico-x">
													<path
														d="M18,17l-1,1L9,10,1,18,0,17,8,9,0,1,1,0,9,8l8-8,1,1L10,9Z"></path>
												</svg>
											</button> : null
										}

										{
											step === 1 ?
												null
												:
												<button className={` ico-button lhs light-button ${style.nextBtn} ${style.prevBtn} ml-3`} onClick={prevPage}>
													<svg className="ico light-ico ico-arrow">
														<path d="M0,17H29L15,31l1,1L32,16,16,0,15,1,29,15H0Z"></path>
													</svg>
													{stepList[step - 2]}
												</button>

										}
										{/*All Order besides Colocation (15) and Wholesale Broadband (23) orders have 5 steps or DIA Pop and Prem Renewals have 5 steps*/}
										{(selectedQuotedData.product_id !== "15" && selectedQuotedData.product_id !== "23" && selectedQuotedData.q_cpt_activity !== "R") || ((selectedQuotedData.product_id === "10" || selectedQuotedData.product_id === "11") && selectedQuotedData.q_cpt_activity === "R") ?
											<>
												{step === 5 ?
													<>
														{isCustomer && selectedOrderAccess > 1 && !selectedQuotedData?.expiredQuote ?
															<button className={` ico-button lhs light-button ${style.nextBtn} ml-3`} onClick={saveOrder} >
																Save Order
																<svg className="ico light-ico ico-arrow">
																	<path d="M0,17H29L15,31l1,1L32,16,16,0,15,1,29,15H0Z"></path>
																</svg>
															</button> : null
														}
														{(isEmployee || (isCustomer && selectedOrderAccess > 1)) && !selectedQuotedData?.expiredQuote ?
															<button className={` ico-button lhs light-button ${style.nextBtn} ml-3`} onClick={() => submitOrderMapper("Submit Order")}>
																Submit Order
																<svg className="ico light-ico ico-arrow">
																	<path d="M0,17H29L15,31l1,1L32,16,16,0,15,1,29,15H0Z"></path>
																</svg>
															</button> : null
														}
														{isEmployee && !selectedQuotedData?.expiredQuote ?
															<button className={` ico-button lhs light-button ${style.nextBtn} ml-3`} onClick={() => submitOrderMapper("Generate Service Order")}>
																Generate Service Order
																<svg className="ico light-ico ico-arrow">
																	<path d="M0,17H29L15,31l1,1L32,16,16,0,15,1,29,15H0Z"></path>
																</svg>
															</button> : null
														}
													</>
													:
													<button className={` ico-button lhs light-button ${style.nextBtn} ml-3`} onClick={nextPage}>
														{stepList[step]}
														<svg className="ico light-ico ico-arrow">
															<path d="M0,17H29L15,31l1,1L32,16,16,0,15,1,29,15H0Z"></path>
														</svg>
													</button>
												}
											</> : null
										}
										{/*All Renewals besides DIA POP and DIA PREM have 4 steps along with normal Colocation (15) and Wholesale Broadband (23) orders*/}
										{(selectedQuotedData.q_cpt_activity === "R" && selectedQuotedData.product_id !== "10" && selectedQuotedData.product_id !== "11")  || selectedQuotedData.product_id === "15" || selectedQuotedData.product_id === "23" ?
											<>
												{
													step === 4 ?
														<>
															{isCustomer && selectedOrderAccess > 1 && !selectedQuotedData?.expiredQuote ?
																<button className={` ico-button lhs light-button ${style.nextBtn} ml-3`} onClick={() => submitOrderMapper("Save Request")} >
																	Save Order
																	<svg className="ico light-ico ico-arrow">
																		<path d="M0,17H29L15,31l1,1L32,16,16,0,15,1,29,15H0Z"></path>
																	</svg>
																</button> : null
															}
															{(isEmployee || (isCustomer && selectedOrderAccess > 1)) && !selectedQuotedData?.expiredQuote ?
																<button className={` ico-button lhs light-button ${style.nextBtn} ml-3`} onClick={() => submitOrderMapper("Submit Order")}>
																	Submit Order
																	<svg className="ico light-ico ico-arrow">
																		<path d="M0,17H29L15,31l1,1L32,16,16,0,15,1,29,15H0Z"></path>
																	</svg>
																</button> : null
															}
															{isEmployee && !selectedQuotedData?.expiredQuote && selectedQuotedData?.product_id !== "23" ?
																<button className={` ico-button lhs light-button ${style.nextBtn} ml-3`} onClick={() => submitOrderMapper("Generate Service Order")}>
																	Generate Service Order
																	<svg className="ico light-ico ico-arrow">
																		<path d="M0,17H29L15,31l1,1L32,16,16,0,15,1,29,15H0Z"></path>
																	</svg>
																</button> : null
															}
														</>
														:
														<button className={` ico-button lhs light-button ${style.nextBtn} ml-3`} onClick={nextPage}>
															{stepList[step]}
															<svg className="ico light-ico ico-arrow">
																<path d="M0,17H29L15,31l1,1L32,16,16,0,15,1,29,15H0Z"></path>
															</svg>
														</button>
												}
											</> : null
										}
									</div>
								</div>
							</div>)}
						</div>
					</div>
				</div>

				{!hasError &&
					(popupShow ?
						<div>
							<div className={`${style.overly}`}> </div>
							<div className="modal fade show d-block">
								<div className={` modal-dialog modal-dialog-centered modal-sm`}>
									<div className="modal-content">
										<div className="modal-body">
											{savedFlag ?
												"Are you sure you want to cancel this order and remove it from the system. It will no longer be able to be ordered." :
												"Are you sure you want to clear the order form?"
											}
										</div>

										<div className="modal-footer d-block text-center">
											<button type="button" className="btn-style btn-theme" data-dismiss="modal" onClick={popupOk}>Ok</button>
											<button type="button" className="btn-style btn-cancel" data-dismiss="modal" onClick={popupCancel}>Cancel</button>
										</div>

									</div>
								</div>
							</div>
						</div>
						: null)
				}
				{!hasError && (orderFlag ?
					<>
						<div className="mt-5">
							<p className="text-success text-center">Order Created</p>
						</div>
						<Redirect to={{ pathname: `/Order/View/${refId}`, state: { generatePDF: generatePDF } }} />
					</> : null)
				}
				{/*Checked to see if quote exist*/}
				{!hasError && (!orderdable ?
					<Redirect to={`/Quote/Create`} />
					: null)
				}
				{/*Check to see if order has already been placed*/}
				{!hasError && (ordered ?
					<Redirect to={`/Order/View/${refId}`} />
					: null)
				}
				{/*Order Saved or Cancelled Redirect to Order Summary*/}
				{!hasError && (savedOrderFlag || cancelOrderFlag ?
					<Redirect to={`/Order/Summary`} /> : null
				)}
			</div></>
	);
}

const mapDispatchToProps = {
	updateQuotedData: setQuotedData,
	clearCreateOrderObj: resetOrderData,
	updateErrorMsg: setSessionError,
	generateServiceOrderForm: setOrderServiceForm
}

export default connect(null, mapDispatchToProps)(OrderCreate);
