/**
 * Created by Rochaus on 1/20/2021.
 */
import React from 'react';
import {NavLink} from 'react-router-dom';

const TabsBilling = () => {
    return (
        <div className="tab-nav cf">
            <NavLink className="tab-opt qm-summary" to="/Billing/Invoices" activeClassName="active">
                <span>Invoices</span>
            </NavLink>
            <NavLink className="tab-opt qm-create" to="/Billing/AccountSummary" activeClassName="active">
                <span>Account Summary</span>
            </NavLink>
            <NavLink className="tab-opt qm-upload" to="/Billing/PaymentHistory" activeClassName="active">
                <span>Payment History</span>
            </NavLink>
            <NavLink className="tab-opt qm-upload" to="/Billing/OneTimePayment" activeClassName="active">
                <span>One Time Payment</span>
            </NavLink>
        </div>
    );
}

export default TabsBilling;
