// CSS Imports
import style from "./TroubleTickets.module.css";

import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ViewportWarning from "../shared/ViewportWarning";
import getNetworkSummaryList from "../../services/NetworkService";
import Loader from "../shared/Loader";
import XLSXExport from "../../utils/XLSXExport";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import ReactTooltip from 'react-tooltip';
import { Tooltip } from 'react-tippy';
import { DebounceInput } from 'react-debounce-input';
import _ from 'underscore';
import { useCurrentWidth } from 'react-breakpoints-hook';
import { ConsoleLogger } from "../../logger/ConsoleLogger";
import { connect, useSelector } from "react-redux";
import CompanyDropdown from '../shared/CompanyDropdown';
import { setMyNetworkListMpid, setMyNetworkList } from "../../redux/actions/networkIntelligenceActions";

const columnsDesktop = [{
	dataField: 'circuitId',
	text: 'Circuit ID',
	classes: "w10",
	filter: textFilter({
		placeholder: 'Circuit ID'
	}),
	headerAttrs: { id: 'circuitID' },
	formatter: (cell, row) => circuidIdFormatter(cell, row)
},
{
	dataField: 'gaid',
	text: 'Account',
	filter: textFilter({
		placeholder: 'Account'
	}),
	headerAttrs: { id: 'account' },
	formatter: (cell, row) => accountCellFormatter(cell, row)
},
{
	dataField: 'name',
	text: 'Customer Name',
	filter: textFilter({
		placeholder: 'Customer Name'
	}),
	headerAttrs: { id: 'account' },
},
{
	dataField: 'product_BW',
	text: 'Product',
	filter: textFilter({
		placeholder: 'Product'
	}),
	headerAttrs: { id: 'product' },
	formatter: (cell, row) => productFormatter(cell, row)
},
{
	dataField: 'locationA',
	text: 'ALoc',
	filter: textFilter({
		placeholder: 'Location A'
	}),
	headerAttrs: { id: 'aloc' },
},
{
	dataField: 'locationZ',
	text: 'ZLoc',
	filter: textFilter({
		placeholder: 'Location Z'
	}),
	headerAttrs: { id: 'zloc' },
}];

const columnsCompact = [{
	dataField: 'circuitId',
	text: 'Circuit ID',
	headerAttrs: { id: 'circuitID' },
	imgSrc: "ico-transport-white.svg",
	headerFormatter: (cell, row) => headerFormatter(cell, row),
	formatter: (cell, row) => circuidIdFormatter(cell, row)
},
{
	dataField: 'gaid',
	text: 'Account',
	headerAttrs: { id: 'account' },
	imgSrc: "ico-account-white.svg",
	headerFormatter: (cell, row) => headerFormatter(cell, row),
	formatter: (cell, row) => accountCellFormatter(cell, row)
},
{
	dataField: 'con_rate_code',
	text: 'Product',
	headerAttrs: { id: 'product' },
	imgSrc: "ico-product-white.svg",
	headerFormatter: (cell, row) => headerFormatter(cell, row),
	formatter: (cell, row) => productFormatter(cell, row)
},
{
	dataField: 'aloc_street',
	text: 'ALoc',
	headerAttrs: { id: 'aloc' },
	imgSrc: "ico-location-white.svg",
	headerFormatter: (cell, row) => headerFormatter(cell, row),
	//formatter: (cell, row) => aLocFormatterCompact(cell, row)
},
{
	dataField: 'zloc_street',
	text: 'ZLoc',
	headerAttrs: { id: 'zloc' },
	imgSrc: "ico-location-white.svg",
	headerFormatter: (cell, row) => headerFormatter(cell, row),
	//formatter: (cell, row) => zLocFormatterCompact(cell, row)
}];

/*Commenting out for now may revist later to add links to create ticket and MAC-D
const DropdownManage = (props, row) => {
	const ticketCreate = {
		pathname: "/Ticket/Create",
		circuitId: row?.circuitId
	};

	const macDCreate = {
		pathname: "/Order/MACD/Create",
		circuitId: row?.circuitId
	};

	return (
		<div className="select-wrap select-wrap-table dropdown-wrap">
			<div className="btn-group">
				<button type="button" className="dropdown-toggle w-100 text-left p-0" data-toggle="dropdown"
					aria-haspopup="true" aria-expanded="false">
					Manage
				</button>
				<div className="dropdown-menu dropdown-menu-right">
					<Link to={ticketCreate} className="dropdown-item">Create Ticket</Link>
					<Link to={macDCreate} className="dropdown-item">Create MAC-D</Link>
				</div>
			</div>
		</div>
	)
}
*/
const headerFormatter = (props) => {
	return (
		<>
			<ReactTooltip />
			<span data-tip={props.text}>{props.text}</span>
			<img alt="header-icon" className={style.headerIcon} src={`../images/svg/${props.imgSrc}`} data-tip={props.text}></img>
		</>
	)
}

const accountCellFormatter = (props, row) => {
	return (
		<>
			<p className="gaid-account m-0">{props}</p>
			<div className="dropdown2 accountDropDown">
				<svg className="ico ico-dashboard ico-svg" />
				<AccountNamePopup accountNumber={row.gaid} accountName={row.name} />
			</div>
		</>
	);
}

const AccountNamePopup = ({ accountNumber, accountName }) => {
	const listItems = (<li key={accountNumber}>
		<Tooltip html={accountName} arrow={true} position={'left'} distance={330}>
			<div><Link>{accountNumber}</Link></div>
		</Tooltip>
	</li>)
	return (
		<>
			<ul>{listItems}</ul>
			{/*<EventInfoRows classType="multi-li" />*/}
		</>
	);
}

const circuidIdFormatter = (props, row) => {
	// const URL = `/History/TroubleTicketsHistory/${row.circuit}`;
	let id = row.circuitId.replace(/\s+/g, '')
	id = id.replace(/\//g, '');
	const URL = `/Circuits/Summary/${id}/${row.designId}`;
	return (
		<Link to={URL}>{row.circuitId}</Link>
	);
}

const productFormatter = (props, row) => {
	return (
		<>
			{!!row.product_BW ?
				<div className="bandwidth-wrap"><ul><li className="py-2 w-75">{row.product_BW}</li></ul></div>
				: null}
		</>
	);
}

/* Commenting these formatters may revist later
const emptyComma = () => {
	return ', '
}


const concatCityAndStateAddress = (city, state) => {
	let cityAndState = '';
	if (city) {
		cityAndState += city;
	}
	if (state) {
		cityAndState += emptyComma() + state;
	}
	return cityAndState;
}

const concatCompleteAddress = (street, city, state, zip) => {
	let completeAddress = '';
	if (street) {
		completeAddress += street;
	}
	if (city) {
		completeAddress += emptyComma() + city;
	}
	if (state) {
		completeAddress += emptyComma() + state;
	}
	if (zip) {
		completeAddress += emptyComma() + zip;
	}
	return completeAddress;
}

/* Commenting these formatters may revist later
const aLocFormatterDesktop = (props, row) => {
	let completeAddress = concatCompleteAddress(row.aloc_street,
		row.aloc_city, row.aloc_state, row.aloc_zip);
	return (
		<span>{completeAddress}</span>
	)
}

const zLocFormatterDesktop = (props, row) => {
	let completeAddress = concatCompleteAddress(row.zloc_street,
		row.zloc_city, row.zloc_state, row.zloc_zip);
	return (
		<span>{completeAddress}</span>
	)
}

const aLocFormatterCompact = (props, row) => {
	let completeAddress = concatCompleteAddress(row.aloc_street,
		row.aloc_city, row.aloc_state, row.aloc_zip);
	let cityAndState = concatCityAndStateAddress(row.aloc_city, row.aloc_state);
	return (
		<>
			<ReactTooltip />
			<span data-tip={completeAddress}>{cityAndState}</span>
		</>
	)
}

const zLocFormatterCompact = (props, row) => {
	let completeAddress = concatCompleteAddress(row.zloc_street,
		row.zloc_city, row.zloc_state, row.zloc_zip);
	let cityAndState = concatCityAndStateAddress(row.zloc_city, row.zloc_state);
	return (
		<>
			<ReactTooltip />
			<span data-tip={completeAddress}>{cityAndState}</span>
		</>
	)
}

*/

const NetworkSummary = (props) => {
	const logger = new ConsoleLogger({ level: process.env.REACT_APP_LOGGER_LEVEL, });
	const [enableDesktop, setEnableDesktop] = useState(true);
	const [enableSearch, setEnableSearch] = useState(false);
	const [csvKeyData, setCSVKeyData] = useState([]);
	const [copyNetworkSummaryData, setCopyNetworkSummaryData] = useState([]);
	const [showDashBoard, setShowDashBoard] = useState(false);
	const [networkSummaryData, setNetworkSummaryData] = useState([]);
	const [loader, setLoader] = useState(false);
	const [displayCircuitFilter, setDisplayCircuitFilter] = useState(false);
	const [circuitFilter, setCircuitFilter] = useState('');
	const [errMsg, setErrMsg] = useState('');
	const [noData, setNoData] = useState('');

	const XLSXHeaders = ["Circuit ID", "Account", "Customer Name", "Product", "BW", "ALOC", "ZLOC"];
	const selectedInternalMpid = useSelector(state => state.internal.company?.value);
	const selectedMyNetworkData = useSelector(state => state.networkIntelligence.myNetwork);
	const width = useCurrentWidth();

	const isEmployee = localStorage.getItem("isEmployeeLoggedIn");
	const user = localStorage.getItem("user");

	useEffect(() => {
		setCircuitFilter('');
		setDisplayCircuitFilter(false);
		if (isEmployee) {
			if (selectedInternalMpid && selectedInternalMpid !== selectedMyNetworkData.networkListMpid) {
				props.updateMyNetworkListMpid(selectedInternalMpid);
				props.updateMyNetworkList([]);
				setNetworkSummaryData([]);
				getMyNetwork();
			} else {
				getMyNetworkFromStore();
			}
		} else {
			if (Object.entries(selectedMyNetworkData.networkListData)?.length > 0) {
				getMyNetworkFromStore();
			} else {
				getMyNetwork();
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isEmployee, selectedInternalMpid]);

	useEffect(() => {
		if (width <= 992) {
			setEnableDesktop(false);
			setEnableSearch(false);
		} else {
			setEnableDesktop(true);
		}
	}, [width]);

	const getMyNetworkFromStore = () => {
		setNetworkSummaryData(selectedMyNetworkData.networkListData);
		setCopyNetworkSummaryData(selectedMyNetworkData.networkListData);
		filterExportData(selectedMyNetworkData.networkListData);
		setShowDashBoard(true);
	}

	const desktopToggle = () => {
		setEnableDesktop(!enableDesktop);
		setEnableSearch(false);
	}

	const searchToggle = () => {
		setEnableSearch(!enableSearch);
		setEnableDesktop(true);
	}

	const getMyNetwork = () => {
		setLoader(true);
		setErrMsg('');
		setNoData('');
		getNetworkSummaryList(circuitFilter).then(resp => {
			if (resp?.data?.result === 'FILTER') {
				setErrMsg(resp?.data?.error);
				setDisplayCircuitFilter(true);
				setLoader(false);
			} else {
				if (resp?.data?.length > 0) {
					setCircuitFilter('');
					resp?.data?.forEach(ns => {
						if (selectedInternalMpid === 8683 || user === "Wholesale_Demo") {
							ns['product_BW'] = ns.product + ' ' + ns.con_rate_code
						} else {
							ns['product_BW'] = ns.billing_product !== '' ? ns.billing_product.split(' - ').slice(0, 1) : (ns.con_rate_code === 'N/A' ? ns.product : ns.product + ' ' + ns.con_rate_code)
						}
						ns['locationA'] = ns?.aloc_street + ' ' + ns?.aloc_city + ' ' + ns?.aloc_state + ' ' + ns?.aloc_zip
						ns['locationZ'] = ns?.zloc_street + ' ' + ns?.zloc_city + ' ' + ns?.zloc_state + ' ' + ns?.zloc_zip
					})
					props.updateMyNetworkList(resp.data);
					setNetworkSummaryData(resp.data);
					setCopyNetworkSummaryData(resp.data);
					setShowDashBoard(true);
					filterExportData(resp.data);
					setLoader(false);
				} else {
					setLoader(false);
					setNoData("No Data Found");
					logger.error("No circuits found");
				}
			}
		}).catch(error => {
			setLoader(false);
			logger.error("getNetworkSummaryList Error: " + error);
			setErrMsg("An error has occured getting data. " + error);
		});
	}

	const filterExportData = (data) => {
		const exportArr = data.map((item) => {
			return {
				'Circuit ID': item.circuitId,
				'Account': item.gaid,
				'Customer Name': item.name,
				'Product': item.product,
				'BW': item.con_rate_code,
				'ALOC': item.aloc_street,
				'ZLOC': item.zloc_street
			};
		});
		setCSVKeyData(exportArr);
	};

	const equalityCheckWithCircuitID = (circuitID, userInput) => {
		const circtID = circuitID.replace(/\s+/g, '')
		const input = userInput.replace(/\s+/g, '')
		return circtID?.toUpperCase().includes(input?.toUpperCase());
	}

	const searchInventory = (ev) => {
		let inputValue = ev.target.value.trim();
		let findCircuidID;
		if (inputValue) {
			findCircuidID = _.filter(copyNetworkSummaryData, (networkObj) => {
				return networkObj.circuit?.toUpperCase().includes(inputValue?.toUpperCase()) ||
					equalityCheckWithCircuitID(networkObj.circuitId, inputValue) ||
					networkObj.gaid?.toUpperCase().includes(inputValue?.toUpperCase()) ||
					networkObj.name?.toUpperCase().includes(inputValue?.toUpperCase()) ||
					networkObj.con_rate_code?.toUpperCase().includes(inputValue?.toUpperCase()) ||
					networkObj.rate_code?.toUpperCase().includes(inputValue?.toUpperCase()) ||
					networkObj.aloc_street?.toUpperCase().includes(inputValue?.toUpperCase()) ||
					networkObj.aloc_city?.toUpperCase().includes(inputValue?.toUpperCase()) ||
					networkObj.aloc_state?.toUpperCase().includes(inputValue?.toUpperCase()) ||
					networkObj.aloc_zip?.toUpperCase().includes(inputValue?.toUpperCase()) ||
					networkObj.zloc_street?.toUpperCase().includes(inputValue?.toUpperCase()) ||
					networkObj.zloc_city?.toUpperCase().includes(inputValue?.toUpperCase()) ||
					networkObj.zloc_state?.toUpperCase().includes(inputValue?.toUpperCase()) ||
					networkObj.zloc_zip?.toUpperCase().includes(inputValue?.toUpperCase())
			});
			if (findCircuidID && findCircuidID.length > 0) {
				setNetworkSummaryData(findCircuidID);
			} else {
				setNetworkSummaryData([]);
			}
		} else { // for empty search box
			setNetworkSummaryData(copyNetworkSummaryData);
		}
	};

	const inputHandler = (ev) => {
		switch (ev.target.id) {
			case "circuitFilter":
				setCircuitFilter(ev.target.value);
				break;
			default:
				break;
		}
	}

	return (
		<>
			{/*<ReactTooltip />*/}
			{loader ? <Loader /> : null}
			<div className="midsection qm-upload abs z1">
				<div className="rel">
					<ViewportWarning />
					<div className="wrap">
						<h1 className="page-title mb-0">{isEmployee ? "Employee My Network" : "My Network"}</h1>
						{isEmployee ? <CompanyDropdown /> : null}
						{displayCircuitFilter && (
							<>
								<div className="mt-5">
									<p className="text-danger text-center">{errMsg}</p>
								</div>
								<div className={`row ${style.centerContent} `}>
									<div className="col-12 col-sm-4 col-md-3">
										<label className={style.positionCenter}>Search Circuit</label>
										<input type="text" className={style.inputFld} id="circuitFilter" onChange={inputHandler} value={circuitFilter}></input>
										<button type="button" className={`btn-style btn-theme ${style.buttonSpacing}`} onClick={getMyNetwork}>Search</button>
									</div>
								</div>
							</>
						)}

						<div className="section w-100 TroubleTicket-page circuitInventoryPage networkPage">
							{/* <TabsQuote /> */}
							<div className="content bg-transparent px-0">
								<div className={` ${style.tableWrap} tableWrap`}>
									<div className={`rel table-style-wrap network-table pagination-table page-list my-3 troubleTicketTable ${enableSearch ? 'searchableEnableTable' : null} ${enableDesktop ? 'compactView' : 'desktopViewTable'} `}>
										<BootstrapTable id="troubleTicket"
											bootstrap4
											keyField='id'
											data={networkSummaryData}
											columns={enableDesktop ? columnsDesktop : columnsCompact}
											striped
											hover
											condensed
											bordered={false}
											pagination={paginationFactory()}
											filter={filterFactory()}
											defaultSortDirection="desc"
											className="mb-0"
										/>
										<div className={`text-center ${style.noDataWrap} `}>
											<h1 className="m-0">{noData}</h1>
										</div>
									</div>
								</div>

								{/* footer */}
								{showDashBoard ?
									<div className={`footer-info-panel ${style.fixedFooter} ${style.fixedFooterForCircuitInventory} `}>
										<div className={`d-flex align-items-center ${style.spaceBetween} ${style.spaceBetweenForCircuitInventory}`}>
											<div className={`${style.footerGrid}`}>
												<span className={` ${style.labelText} ${style.circuitInventoryLabelText} text-white`}>Circuits</span>
												<span className={`rel ${style.circleWrap}`}>
													<span className={` ${style.labelText} ${style.circuitInventoryLabelText} ${style.posText} text-white`}>{networkSummaryData.length}</span>
												</span>
											</div>
											<div className={`${style.footerGrid} ${style.searchableParams}`}>
												<div id="widget-priority" className={` toggle-switch toggle-switch-sm ${enableSearch ? 'green-toggle on' : ''}`} onClick={() => { searchToggle() }}>
													<div className="ts-bar">
														<div className="ts-left-edge">&nbsp;</div>
														<div className="ts-right-edge">&nbsp;</div>
														<div className="ts-switch">&nbsp;</div>
													</div>
												</div>
												<p className={`  ts-label ts-label-new ${style.labelText} ${style.circuitInventoryLabelText} text-white`} >SEARCHABLE PARAMETERS</p>
											</div>
											{!isEmployee ? <div className={`${style.footerGrid}`}>
												<Link to="/Ticket/Create" className={` ${style.labelText} ${style.circuitInventoryLabelText} ${style.textTheme}`}> Create Ticket
													<svg className={` ico ${style.createTicketIco} `}><path className={style.pathTheme} d="M16,8.9h2v10H16Zm2,14H16v-2h2Zm14-1H30l-1-1v-7a12.9,12.9,0,0,0-1.73-6.32A11.55,11.55,0,0,0,23,3.33a11.93,11.93,0,0,0-12,0A11.55,11.55,0,0,0,6.73,7.58,12.9,12.9,0,0,0,5,13.9v8A8.87,8.87,0,0,0,6.73,27,11.14,11.14,0,0,0,11,30.91c1.86,1,3.87,1,6,1h3v-1H16l-1-1,1-1h4c.68,0,.88.35,1.37.85a2.44,2.44,0,0,1,.73,1.77,2.38,2.38,0,0,1-.73,1.75A1.54,1.54,0,0,1,20,33.9H17a13.92,13.92,0,0,1-6.83-1.63,12.74,12.74,0,0,1-4.9-4.56A13.59,13.59,0,0,1,3,20.9v-7A14.63,14.63,0,0,1,5.27,6.77a13.24,13.24,0,0,1,4.9-4.9,13.41,13.41,0,0,1,13.66,0,13.24,13.24,0,0,1,4.9,4.9A14.63,14.63,0,0,1,31,13.9v6h1v-6l1.08-1.08L34,13.9v7Zm-32-2v-6l1-1,1,1v6l-1,1Z"></path></svg>
												</Link>
											</div> : null}
											<div className={`${style.footerGrid}`}>
												<Link to="/Network/CircuitHealth" className={` ${style.labelText} ${style.circuitInventoryLabelText} ${style.textTheme}`}> Circuit Health
													<svg className={` ico ${style.createTicketIco} `}><path className={style.pathTheme} d="M36,2V32l-2,2H2L0,32V2L2,0H34ZM34,32V2H2V16h8l3,4,5-10,3,6h8v2H20l-2-4L13,24,9,18H2V32Z"></path></svg>
												</Link>
											</div>
										</div>

										<div className={`d-flex align-items-center ${style.spaceBetween}`}>
											<div className={`${style.footerGrid} w-50 rel searchWrap d-flex`}>
												<DebounceInput type="search" className={`${style.searchFld} ${style.circuitInventorySearchFld}`}
													placeholder="Search the Inventory" debounceTimeout={300} onChange={event => searchInventory(event)} />
												<img alt={""} className={` ${style.searchIcoInputField} ${style.searchIcoInputFieldCirInventory}`} src="../../images/svg/ico-search-fill.svg" />
												<Tooltip html={'Search on anything, such as a circuit ID, account number, or a location to find that information.'} position={'top'}>
													<img data-tip="" className={`tipped cornertip d-flex ${style.posRight} ${style.infoIcoCircuitInventory}`} src="../../images/svg/ico-info-filled-dark-off.svg" width="36" alt="" />
													{/* <img className={`tipped cornertip d-flex ${style.posRight} ${style.infoIco}`} src="../../images/svg/ico-info-filled-dark-off.svg" width="36" alt="" /> */}
												</Tooltip>
											</div>
											<div className={`${style.footerGrid} ${style.desktopView}`}>
												<div id="widget-priority" className={` toggle-switch toggle-switch-sm ${style.toggleSwitch} ${enableDesktop ? 'green-toggle on' : ''}`} onClick={() => { desktopToggle() }}>
													<div className="ts-bar">
														<div className="ts-left-edge">&nbsp;</div>
														<div className="ts-right-edge">&nbsp;</div>
														<div className={` ts-switch ${style.tsGreenBG} `}>&nbsp;</div>
													</div>
												</div>
												<p className={`  ts-label ts-label-new ${style.labelText} ${style.circuitInventoryLabelText} text-white mb-0`} >DESKTOP VIEW</p>
											</div>

											<div className={`${style.footerGrid}`}>
												<button className="saveButton ico-button light-button" onClick={() => XLSXExport.exportToExcel(XLSXHeaders, csvKeyData, `Circuit Inventory Export_${Date.now()}`)}>
													<span><strong>Export</strong></span>
													<svg viewBox="0 0 34 34" className={`ico light-ico ico-arrow downloadArrow`}>
														<path className="cls-1" d="M1,18V16L17,31V1h2V31L35,16v2L20,33H35v2H1V33H16Z"
															transform="translate(-1 -1)">
														</path>
													</svg>
												</button>

											</div>
										</div>
									</div>
									: null}
								{/* footer end */}
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}


const mapDispatchToProps = {
	updateMyNetworkListMpid: setMyNetworkListMpid,
	updateMyNetworkList: setMyNetworkList
}

export default connect(null, mapDispatchToProps)(NetworkSummary);
