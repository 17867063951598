import { useEffect, useState } from "react";
import style from './ExistingCircuitModal.module.css';

const ExistingCircuitModal = (props) => {
    const [circuitList, setCircuitList] = useState([]);

    useEffect(() => {
        setCircuitList(props?.exist_circuit)
    }, [props?.exist_circuit])

    const popupClose = () => {
        props.updateCircuitListDisplay(false);
    }

    return (
        <>
            <div>
                <div className="overlay show"> </div>
                <div className="modal fade show d-block">
                <div className={` modal-dialog modal-dialog-scrollable modal-dialog-centered modal-md`}>
                        <div className={`modal-content ${style.circuitList}`}>
                            <div class="modal-header">
                                <h5 className="modal-title">Circuit List</h5>
                            </div>
                            <div className="modal-body">
                                {circuitList.map((circuitId, idx) => {
                                    return (
                                        <div>
                                            <ul>
                                                <li>{`${idx + 1}: ` + circuitId}</li>
                                            </ul>
                                        </div>
                                    )
                                })}
                            </div>
                            <div className="modal-footer d-block text-center">
                            <button type="button" className="btn-style btn-theme" data-dismiss="modal" onClick={() => { popupClose() }}>Close</button>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )

}

export default ExistingCircuitModal;