import React, {useEffect, useState} from "react";
import style from "./QuoteCreate.module.css";
import EAccessNNIHelper from "../../services/EAccessNNIService";
import PremAddress from './PremAddress'
import QuoteLocZ from "./QuoteLocZ";
import {connect, useSelector} from "react-redux";
import {setQuoteBW, setQuoteENNIExtension, setQuoteNNILoc} from '../../redux/actions/quoteActions';
import Term from "./Term";
import Select from 'react-select';
import Loader from '../shared/Loader';
import {ConsoleLogger} from "../../logger/ConsoleLogger";
import HTTPErrorHandler from "../errorPages/HTTPErrorHandler";

const EAccessNNI = (props) => {
	const logger = new ConsoleLogger({ level: process.env.REACT_APP_LOGGER_LEVEL, });
	const [hasError, setHasError] = useState(null);
	const [NNILoc, setNNILoc] = useState([]);
	const [NNIBWOptions, setNNIBWOptions] = useState([]);
	const [loader, setLoader] = useState(false);
	const selectedEAccessNniValues = useSelector(state => state.quote.quoteValues);

	useEffect(() => {
		getLocA();
		nniBandwidths();
	}, [])


	//Gets Different E-Access NNI Locations
	const getLocA = () => {
		if (props.stepCode === 2) {
			setLoader(true);
			EAccessNNIHelper.getEAccessNNILocation().then(resp => {
				logger.info(resp.data);
				setNNILoc(resp.data);
				setLoader(false);
			}).catch(error => {
				logger.error('getEAccessNNILocation Error: ' + error);
				setHasError(error);
				setLoader(false);
			})
		}
	}

	//Get Different E-Access NNI Bandwidths
	const nniBandwidths = () => {
		if (props.stepCode === 3) {
			setLoader(true);
			EAccessNNIHelper.getEAccessNNIBW().then(resp => {
				logger.info(resp.data);
				setNNIBWOptions(resp.data);
				setLoader(false);
			}).catch(error => {
				logger.error('getEAccessNNIBW Error: ' + error);
				setHasError(error);
				setLoader(false);
			})
		}

	}

	//Checks to see if an EAccess NNi Extension is required
	const enniExtension = async (ev) => {
		let val = ev.target.checked;
		props.updateEAccessExtension(val);
	}

	//Grabs the user selected NNI Location Value
	const nniLocHandler = (ev) => {
		let nniVal = '';
		if (!ev) {
			nniVal = ''
		} else {
			nniVal = ev;
		}
		props.updateEAccessNNILocation(nniVal);
	}


	const bandwidthHandler = (ev) => {
		props.updateEAccessBw(ev.target.value);
	}

	const customFilter = (option, searchText) => {
		if (option.value.toString().includes("99") ||
			option.label.toString().toLowerCase().includes(searchText.toString().toLowerCase()) ||
			option.value.toString().toLowerCase().includes(searchText.toString().toLowerCase())
		) {
			return true;
		} else {
			return false;
		}
	}

	return (
		<>
			{hasError && <HTTPErrorHandler error={hasError}></HTTPErrorHandler>}
			{loader ? <Loader /> : null}
			{!hasError && (<>{props.stepCode === 2 ?
				<>
					<div className={`row ${style.rowGrp}`}>
						<div className="col-6">
							<label className={style.labelSelectRequiredFld}>NNI Location<span className={style.mandatory}>*</span> </label>
							<Select
								defaultValue={selectedEAccessNniValues.nniLoc}
								onChange={nniLocHandler}
								options={NNILoc.map((item) => {
									return { value: item.id, label: item.name }
								})}
								filterOption={customFilter}
								isSearchable
								isClearable
								noOptionsMessage={() => 'Loading EAccess NNI Locations'}
							/>
						</div>

						<div className="col-12 col-sm-4 col-md-4">
							<input type="checkbox" id="enniExt" name="enniExt" onChange={enniExtension} checked={selectedEAccessNniValues.enniExtension ? "checked" : ""} />
							<label htmlFor="enniExt" className={` ${style.checkboxText} `}>ENNI Extension</label>
						</div>
					</div>
					{/*if other is selected than take the user input*/}
					{selectedEAccessNniValues.nniLoc.value === 99 ?
						<PremAddress /> : null}

					{selectedEAccessNniValues.enniExtension ?
						<QuoteLocZ /> : null
					}
				</> : null}
				{(props.stepCode === 3) && (props.section === "term") ?
					<Term product={"E_ACCESS_NNI"} />
					: null}
				{(props.stepCode === 3) && (props.section === "bandwidth") ?
					<div className={style.bandwidthWrap}>
						<div className={`row ${style.rowGrp} `}>
							<div className="col-6 col-sm-4 col-md-3">
								<label className={style.labelSelectRequiredFld}>Bandwidth <span className={style.mandatory}>*</span></label>
								<select className={` form-control  ${style.selectFld} ${style.requiredFld} `} id="bw" value={selectedEAccessNniValues.bw} onChange={bandwidthHandler}>
									{NNIBWOptions.map((obj) => {
										return <option value={obj.id}>{obj.name}</option>
									})}
								</select>
							</div>
						</div>
					</div> : null}</>)}
		</>
	)
}
const mapDispatchToProps = {
	updateEAccessNNILocation: setQuoteNNILoc,
	updateEAccessBw: setQuoteBW,
	updateEAccessExtension: setQuoteENNIExtension
}

export default connect(null, mapDispatchToProps)(EAccessNNI);
