import React from 'react';
import { Page, Text, View, Document, Image } from '@react-pdf/renderer';
import { styles } from './reportStyles';
import headerImage from '../Images/Windstream_pdf_header_wholesale.jpg';

const HeaderImage = () => (
    <Image style={styles.imageHeader} src={headerImage} />
);

const CustomerServiceRecordPDF = ({ customerInfos, productSummaries, pics, tnSets }) => {
    return (
        <Document>
            <Page size="A4" style={styles.page}>
                <HeaderImage />
                <View style={styles.section}>
                    <Text style={styles.textBold}>Windstream Customer Service Record</Text>
                </View>

                <View style={styles.headerBar}>
                    <Text style={styles.headerBarText}>Account</Text>
                </View>
                {customerInfos?.length > 0 ?
                    <View style={styles.table}>
                        <View style={styles.tableRow}>
                            <Text style={[styles.tableCell, styles.textBold]}>Company Name</Text>
                            <Text style={[styles.tableCell, styles.textNormal]}>{customerInfos[0].companyName}</Text>
                            <Text style={[styles.tableCell, styles.textBold]}>Status</Text>
                            <Text style={[styles.tableCell, styles.textNormal]}>{customerInfos[0].accountStatus}</Text>
                        </View>
                        <View style={styles.tableRow}>
                            <Text style={[styles.tableCell, styles.textBold]}>Address</Text>
                            <Text style={[styles.tableCell, styles.textNormal]}>{`${customerInfos[0].addr1} ${customerInfos[0].addr2}`}</Text>
                            <Text style={[styles.tableCell, styles.textBold]}>Type</Text>
                            <Text style={[styles.tableCell, styles.textNormal]}>{customerInfos[0].custAcctType}</Text>
                        </View>
                    </View> : null
                }

                <View style={{ flexDirection: 'row' }}>
                    {productSummaries?.length > 0 ?
                        <View style={[styles.columnHalf]}>
                            <View style={styles.headerBar}>
                                <Text style={styles.headerBarText}>Product Summary</Text>
                            </View>
                            <View style={styles.table}>
                                <View style={styles.tableRow}>
                                    <Text style={[styles.tableCell, styles.textBold]}>Product</Text>
                                    <Text style={[styles.tableCell, styles.textBold]}>Quantity</Text>
                                </View>
                                {productSummaries.map((product, index) => (
                                    <View key={index} style={styles.tableRow}>
                                        <Text style={[styles.tableCell, styles.textNormal]}>{product.product}</Text>
                                        <Text style={[styles.tableCell, styles.textNormal]}>{product.cnt}</Text>
                                    </View>
                                ))}
                            </View>
                        </View> : null
                    }
                    {pics?.length > 0 ?
                        <View style={[styles.columnHalf]}>
                            <View style={styles.headerBar}>
                                <Text style={styles.headerBarText}>PIC Info</Text>
                            </View>
                            <View style={styles.table}>
                                <View style={styles.tableRow}>
                                    <Text style={[styles.tableCell, styles.textBold]}>PIC</Text>
                                    <Text style={[styles.tableCell, styles.textNormal]}>{pics[0].pic}</Text>
                                </View>
                                <View style={styles.tableRow}>
                                    <Text style={[styles.tableCell, styles.textBold]}>LPIC</Text>
                                    <Text style={[styles.tableCell, styles.textNormal]}>{pics[0].lpic}</Text>
                                </View>
                                <View style={styles.tableRow}>
                                    <Text style={[styles.tableCell, styles.textBold]}>INTNL</Text>
                                    <Text style={[styles.tableCell, styles.textNormal]}>{pics[0].intPic}</Text>
                                </View>
                            </View>
                        </View> : null
                    }
                </View>

                {tnSets?.length > 0 ?
                    <>
                        <View style={styles.headerBar}>
                            <Text style={styles.headerBarText}>Telephone Number Summary</Text>
                        </View>

                        <View style={styles.table}>
                            {tnSets.map((tnSet, index) => (
                                <View key={index} style={styles.tableRow}>
                                    <Text style={[styles.tableCell, styles.textNormal]}>{tnSet.tn1} ({tnSet.type1})</Text>
                                    {tnSet.tn2 && <Text style={[styles.tableCell, styles.textNormal]}>{tnSet.tn2} ({tnSet.type2})</Text>}
                                    {tnSet.tn3 && <Text style={[styles.tableCell, styles.textNormal]}>{tnSet.tn3} ({tnSet.type3})</Text>}
                                    {tnSet.tn4 && <Text style={[styles.tableCell, styles.textNormal]}>{tnSet.tn4} ({tnSet.type4})</Text>}
                                </View>
                            ))}
                        </View>
                    </> : null
                }
            </Page>
        </Document>
    );
};

export default CustomerServiceRecordPDF;
