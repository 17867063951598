import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import './index.css';
import './css/font.css';
import './css/overlayscrollbars.min.css';
import './slick/slick.css';
import './css/normalize.css';
import './css/helpers.css';
import './css/nice-select.css';
import './css/style.css';
import './css/responsive.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {persistor, store} from "./utils/createStore"
import {PersistGate} from "redux-persist/integration/react";

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <App/>
            </PersistGate>
        </Provider>
    </React.StrictMode>,
    document.getElementById('root')
);

reportWebVitals();
