import './server/serverError.css';
import {useEffect, useState, useRef} from 'react';
import {Link, useHistory} from "react-router-dom";
import {ConsoleLogger} from "../../logger/ConsoleLogger";
import RuntimeErrorService from '../../services/RuntimeErrorHandlerService';
import HTTPErrorHandler from "../errorPages/HTTPErrorHandler";
import StackTrace from 'stacktrace-js';
import ErrorStackParser from 'error-stack-parser';
import {useLocation} from "react-router-dom";

const RuntimeErrorHandler = (props) => {
    const history = useHistory();
    const logger = new ConsoleLogger({ level: process.env.REACT_APP_LOGGER_LEVEL, });
    const isEmployee = localStorage.getItem('isEmployeeLoggedIn');
    const [hasError, setHasError] = useState(null);
    const currentDate = new Date();
    const dateObj = currentDate.toLocaleString('en-CA', {hour12: false})
    const now = (currentDate.getTime() / 1000) / 1000;
    const afterDecimal = Number(now.toString().split('.')[1]);
    const errorObject = {
        PageRoute: history.location.pathname,
        errorDescription: props.error.message,
        errorDate: dateObj,
        userID: localStorage.user,
        errorID: afterDecimal
    };
    const location = useLocation();
    const errorLocation = useRef(location.pathname);

    var callback = function (stackframes) {
        var stringifiedStack = stackframes.map(function (sf) {
            return sf.toString();
        }).join('\n');
        var stackFrames = ErrorStackParser.parse({ stack: stringifiedStack })
        errorObject.columnNumber = stackFrames[0].columnNumber;
        errorObject.fileName = stackFrames[0].fileName;
        errorObject.lineNumber = stackFrames[0].lineNumber;
        errorObject.source = stackFrames[0].source;
        return errorObject;
    };
    var errback = function (err) { console.log(err.message); };
    var homeUrl = isEmployee ? "/Employee/Home" : "/";

    //Clearing the error boundary if the user navigates to a different page
    useEffect(() => {
        if (location.pathname !== errorLocation.current) {
            props.resetErrorBoundary();
        }
    },[location.pathname, props])

    useEffect(() => {
        StackTrace.fromError(props.error).then(callback).then(errObj => {
            RuntimeErrorService(errObj).then(resp => {}).catch(error => {
                logger.error("Runtime Service Calling ERROR: " + error)
                setHasError(error);
            })
        }).catch(errback);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            {hasError && <HTTPErrorHandler error={hasError}></HTTPErrorHandler>}
            {!hasError && (<><div className={'errorNumber'}>ErrorID: {errorObject.errorID}</div>
                <div className={'errorText'}>{"Unknown Error Occured. Sorry for the inconvenience!!!"}</div>
                <Link to={homeUrl} className="errLabelText">iConnect Home</Link></>)}
        </>)
}
export default RuntimeErrorHandler;
