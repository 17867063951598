import React from 'react'
import {Link} from 'react-router-dom';

const NoAccessError = () => {
	return (
		<div className="error-wrap text-center text-danger my-2">
			<h3>
				<p>
					Your account does not have access to this page.
				</p>
			</h3>
			<Link to="/">
				Go Home
			</Link>
		</div>
	)
}

export default NoAccessError