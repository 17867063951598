import { Text, View, } from '@react-pdf/renderer';
import { styles } from './pdfStyles';

const DDosDetails = (props) => {
    return (
        <>
            <View wrap={false}>
                <View style={styles.headerBarTopMargin}>
                    <Text style={styles.headerBarText}>DDoS Mitigation Detail</Text>
                </View>
                {props?.ddos_qty?.length > 0 ?
                    <View style={styles.tableRow}>
                        <Text style={[styles.headerColumn, styles.tableCell2Width, styles.textBold]}>DDos Quantity:</Text>
                        <Text style={[styles.tableColumn, styles.tableCell2Width, styles.textNormal]}>{props?.ddos_qty}</Text>
                    </View>
                    : null}
                {props?.cam_qty?.length > 0 ?
                    <View style={styles.tableRow}>
                        <Text style={[styles.headerColumn, styles.tableCell2Width, styles.textBold]}>Critical Asset Monitoring:</Text>
                        <Text style={[styles.tableColumn, styles.tableCell2Width, styles.textNormal]}>{props?.cam_qty}</Text>
                    </View>
                    : null}
                {props?.high_avail?.length > 0 ?
                    <View style={styles.tableRow}>
                        <Text style={[styles.headerColumn, styles.tableCell2Width, styles.textBold]}>High Availability:</Text>
                        <Text style={[styles.tableColumn, styles.tableCell2Width, styles.textNormal]}>{props?.high_avail}</Text>
                    </View>
                    : null}

                {props?.ha_cam_qty?.length > 0 ?
                    <View style={styles.tableRow}>
                        <Text style={[styles.headerColumn, styles.tableCell2Width, styles.textBold]}>High Availability Critical Asset Monitoring:</Text>
                        <Text style={[styles.tableColumn, styles.tableCell2Width, styles.textNormal]}>{props?.ha_cam_qty}</Text>
                    </View>
                    : null
                }

                {props?.ddos_type?.length > 0 ?
                    <View style={styles.tableRow}>
                        <Text style={[styles.headerColumn, styles.tableCell2Width, styles.textBold]}>DDoS Pricing Option:</Text>
                        <Text style={[styles.tableColumn, styles.tableCell2Width, styles.textNormal]}>{props?.ddos_type}</Text>
                    </View>
                    : null}
            </View>
        </>
    )
}

export default DDosDetails;