import style from "./TicketCreate.module.css";

const LabelComponent = (props) => {
    const label = props.obj.label;
    const mandatory = props.obj.mandatory;

    return (
        <label className={`d-block mb-0 textuppercase ${style.labelText} ${props.obj?.hasError ? (style.errorFldText) : null} `}>
            {label} {mandatory ? <span className={style.mandatory}> *</span> : null}
        </label>
    )
}

export default LabelComponent;