import axios from "axios";

const originURL = window.location.origin;
const apiURL = originURL + '/assets/json/'; //api path URL

const fetchQuote = async () => {
	return await axios.get(apiURL + 'quoteSummary.json');
}

const fetchOrdered = async () => {
	return await axios.get(apiURL + 'quoteSummary.json');
}

const fetchOrderCreate = async () => {
	return await axios.get(apiURL + 'orderCreate.json');
}

const fetchQCProducts = async () => {
	return await axios.get(apiURL + 'quoteCreate_Products.json');
}

const fetchQCLocInfo = async () => {
	return await axios.get(apiURL + 'quoteCreate_LOC_INFO.json');
}

const fetchQCProdConfig = async () => {
	return await axios.get(apiURL + 'quoteCreate_Prod_Config.json');
}

const fetchQCReviewQuote = async () => {
	return await axios.get(apiURL + 'quoteCreate_Review_Quote.json');
}

const fetchPlaces = async () => {
	return await axios.get(apiURL + 'places.json');
}

const fetchKML = async () => {
	return await axios.get(apiURL + 'RW_e0151864_2642022134051_04-26-22_184504.kml');
}

const fetchMACDCreate = async () => {
	return await axios.get(apiURL + 'MACD_Create.json');
}

const fetchReports = async () => {
	return await axios.get(apiURL + 'Reports.json');
}

const fetchEmployeeProfile = async () => {
	return await axios.get(apiURL + 'employeeProfile.json');
}

const fetchProducts = async () => {
	return await axios.get(apiURL + 'products.json');
}

const fetchCAMPAccount = async () => {
	return await axios.get(apiURL + 'accountsProfileCAMP.json');
}

const fetchACNA = async () => {
	return await axios.get(apiURL + 'accountProfileACNA.json');
}

const fetchNNI = async () => {
	return await axios.get(apiURL + 'NNI.json');
}

const addOrEditNNIData = async (formData) => {
	const NNIServiceURL = `${process.env.REACT_APP_API_URL}/`;
    return await axios.post(NNIServiceURL, formData);
}

const Api = {
	fetchQuote,
	fetchOrdered,
	fetchOrderCreate,
	fetchQCProducts,
	fetchQCLocInfo,
	fetchQCProdConfig,
	fetchQCReviewQuote,
	fetchPlaces,
	fetchKML,
	fetchMACDCreate,
	fetchReports,
	fetchEmployeeProfile,
	fetchProducts,
	fetchCAMPAccount,
	fetchACNA,
	fetchNNI,
	addOrEditNNIData
}

export default Api;
