import axios from "./axios/customAxios";

const bulkBroadbandAddressQualifier = async (values) => {
    const bulkBroadbandQualifierURL = `${process.env.REACT_APP_API_URL}/broadband/broadbandBulkAddressQualifier.php?data=${encodeURIComponent(JSON.stringify(values))}`;
    return await axios.get(bulkBroadbandQualifierURL);
}

const submitBroadbandBulk = async (data, excelBlob) => {
	return axios({
		method: "post",
		url: `${process.env.REACT_APP_API_URL}/broadband/broadbandBulkSubmit.php?data=${JSON.stringify(data)}`,
		data: excelBlob,
		headers: { "Content-Type": "multipart/form-data" },
	});
}

const BulkHelper = {
	bulkBroadbandAddressQualifier,
	submitBroadbandBulk
}

export default BulkHelper;
