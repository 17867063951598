import {
	AGENCY,
	AGENT,
	AGENT_ADDRESS,
	AGENT_CITY,
	AGENT_EMAIL,
	AGENT_FEE,
	AGENT_NAME,
	AGENT_PHONE,
	AGENT_STATE,
	AGENT_ZIP,
	AUTO_NOBID,
	BILLING_SYSTEM,
	COMPANY_MENU,
	COMPANY_MPID,
	COMPANY_MPID_RESET,
	DISABLE_CUST_EMAIL,
	END_TERM_DATE,
	ENTERPRISE_REP,
	ENTERPRISE_REP_OBJ,
	EXISTING_QUOTE_CIRCUIT_ID,
	LEGACY_NETWORK,
	MANUAL_QUOTE_CIRCUIT_ID,
	MARKUP,
	NON_COMMISSIONABLE,
	OFFNET,
	PREV_QUOTE_NUM,
	PRICING_OVERRIDE,
	RENEWAL_QUOTE,
	RESET_INTERNAL_QUOTE,
	WHOLESALE_OPPORTUNITY
} from "../actions/types/actionTypes";

const initialState = {
    companyMenu: [],
	company: { "value": "", "label": "Please Select Customer" },
    quote: {
        agency: "",
        autoNobid: "",
        disableCustEmail: "",
        renewalQuote: "",
        prevQuoteNum: "",
        existingCircuitID: "",
        manualCircuitID: "",
        endTermDate: "",
        legacyNetwork: "",
        billingSystem: "",
        offnet: "",
        nonCommissionable: "",
        markup: "",
        wholesaleOpp: "",
        pricingOverride: "",
        agent: "",
        agentName: "",
        agentPhone: "",
        agentFee: "",
        agentEmail: "",
        agentAddress: "",
        agentCity: "",
        agentState: "",
		agentZip: "",
		enterpriseRep: "",
		enterpriseRepObj: {}
    }
};


const internalReducer = (state = initialState, action) => {
    switch (action.type) {
        case COMPANY_MENU:
            return {
                ...state,
                companyMenu: action.payload
            }
        case COMPANY_MPID:
            return {
                ...state,
                company: action.payload
            }
        case COMPANY_MPID_RESET:
            return {
                ...state,
                company: ""
            }
        case AGENCY:
            return {
                ...state,
                quote: {
                    ...state.quote,
                    agency: action.payload
                }
            }
        case AUTO_NOBID:
            return {
                ...state,
                quote: {
                    ...state.quote,
                    autoNobid: action.payload
                }
            }
        case DISABLE_CUST_EMAIL:
            return {
                ...state,
                quote: {
                    ...state.quote,
                    disableCustEmail: action.payload
                }
            }
        case RENEWAL_QUOTE:
            return {
                ...state,
                quote: {
                    ...state.quote,
                    renewalQuote: action.payload
                }
            }
        case PREV_QUOTE_NUM:
            return {
                ...state,
                quote: {
                    ...state.quote,
                    prevQuoteNum: action.payload
                }
            }
        case EXISTING_QUOTE_CIRCUIT_ID:
            return {
                ...state,
                quote: {
                    ...state.quote,
                    existingCircuitID: action.payload
                }
            }
        case MANUAL_QUOTE_CIRCUIT_ID:
            return {
                ...state,
                quote: {
                    ...state.quote,
                    manualCircuitID: action.payload
                }
            }
        case END_TERM_DATE:
            return {
                ...state,
                quote: {
                    ...state.quote,
                    endTermDate: action.payload
                }
            }
        case LEGACY_NETWORK:
            return {
                ...state,
                quote: {
                    ...state.quote,
                    legacyNetwork: action.payload
                }
            }
        case BILLING_SYSTEM:
            return {
                ...state,
                quote: {
                    ...state.quote,
                    billingSystem: action.payload
                }
            }
        case OFFNET:
            return {
                ...state,
                quote: {
                    ...state.quote,
                    offnet: action.payload
                }
            }
        case NON_COMMISSIONABLE:
            return {
                ...state,
                quote: {
                    ...state.quote,
                    nonCommissionable: action.payload
                }
            }
        case MARKUP:
            return {
                ...state,
                quote: {
                    ...state.quote,
                    markup: action.payload
                }
            }
        case WHOLESALE_OPPORTUNITY:
            return {
                ...state,
                quote: {
                    ...state.quote,
                    wholesaleOpp: action.payload
                }
            }
        case PRICING_OVERRIDE:
            return {
                ...state,
                quote: {
                    ...state.quote,
                    pricingOverride: action.payload
                }
            }
        case AGENT:
            return {
                ...state,
                quote: {
                    ...state.quote,
                    agent: action.payload
                }
            }
        case AGENT_NAME:
            return {
                ...state,
                quote: {
                    ...state.quote,
                    agentName: action.payload
                }
            }
        case AGENT_PHONE:
            return {
                ...state,
                quote: {
                    ...state.quote,
                    agentPhone: action.payload
                }
            }
        case AGENT_FEE:
            return {
                ...state,
                quote: {
                    ...state.quote,
                    agentFee: action.payload
                }
            }
        case AGENT_EMAIL:
            return {
                ...state,
                quote: {
                    ...state.quote,
                    agentEmail: action.payload
                }
            }
        case AGENT_ADDRESS:
            return {
                ...state,
                quote: {
                    ...state.quote,
                    agentAddress: action.payload
                }
            }
        case AGENT_CITY:
            return {
                ...state,
                quote: {
                    ...state.quote,
                    agentCity: action.payload
                }
            }
        case AGENT_STATE:
            return {
                ...state,
                quote: {
                    ...state.quote,
                    agentState: action.payload
                }
            }
        case AGENT_ZIP:
            return {
                ...state,
                quote: {
                    ...state.quote,
                    agentZip: action.payload
                }
			}
		case ENTERPRISE_REP:
			return {
				...state,
				quote: {
					...state.quote,
					enterpriseRep: action.payload
				}
			}
		case ENTERPRISE_REP_OBJ:
			return {
				...state,
				quote: {
					...state.quote,
					enterpriseRepObj: {
						...state.quote.enterpriseRepObj,
						[action.index]: action.payload
					}
				}
			}
        case RESET_INTERNAL_QUOTE:
            return initialState;

        default:
            return state
    }
}

export default internalReducer;
