import {useEffect, useState} from "react";
import {ConsoleLogger} from '../../logger/ConsoleLogger';
import style from "./Employee.module.css";
import ProfileService from "../../services/ProfileServices";
import Loader from "../shared/Loader";
import {connect, useSelector} from 'react-redux';
import {acnas, camp_accounts, camp_accounts_download} from '../../redux';

const Modal = (props) => {
	console.log("Props: " + JSON.stringify(props));
	const profileFromStore = useSelector(state => state.employeeProfile.profile);
	const logger = new ConsoleLogger({ level: process.env.REACT_APP_LOGGER_LEVEL, });
	const [modalTitle, setModalTitle] = useState(props.title);
	const [modalAction, setModalAction] = useState(props.action);
	const initialValues = { billingAccount: "", globalAccount: "", accountName: "", billable: "" };
	const [formValues, setFormValues] = useState(initialValues);
	const [originalFormValues, setOriginalFormValues] = useState();
	const [formErrors, setFormErrors] = useState({});
	//const [isSubmit, setIsSubmit] = useState(false);
	const [loader, setLoader] = useState(false);
	const [isSuccess, setIsSuccess] = useState(false);
	const [successMsg, setSuccessMsg] = useState("");
	const [buttonVal, setButtonVal] = useState("Save");
	const [acna, setAcna] = useState("");
	const [MSG, setMSG] = useState("");
	const [moveMsg, setMoveMsg] = useState("This account can not be moved.");

	const popupCancel = () => {
		props.emitAction(false);
	}

	const popupOk = () => {
		logger.info("Form Values: " + JSON.stringify(formValues));
		if (modalAction === "ACNA") { //For ACNA
			//props.emitAction(false);
			logger.info("ACNA length: " + encodeURIComponent(acna?.length).replace(/%20/g, " "));
			if (acna?.length === 3) {
				handleSubmitForAcna();
			} else {
				setMSG("Please enter a valid ACNA.");
			}
		}

		if (modalAction === "SEARCHCAMP") {
			setFormErrors(errorCheckSearchForCAMP(formValues));
			logger.info("Camp Account Search form errors: " + JSON.stringify(formErrors));
			if (Object.keys(formErrors).length === 0) {
				handleSearchForCAMP(formValues);
			}
		}
		if (modalAction === "CAMP") { //For CAMP
			setFormErrors(errorCheckSubmitForCAMP(formValues));
			logger.info("Camp Account form errors: " + JSON.stringify(formErrors));
			if (Object.keys(formErrors).length === 0) {
				handleSubmitForCAMP(formValues);
			}
		}
		if (modalAction === "EDITCAMP") { //For CAMP
			setFormErrors(errorCheckSubmitForCAMPUpdate(formValues));
			logger.info("Camp Account form errors: " + JSON.stringify(formErrors));
			if (Object.keys(formErrors).length === 0) {
				handleSubmitForCAMPUpdate(formValues);
			}
		}

		if (modalAction === "DISPLAYCAMP") {
			props.emitAction(false);
		}
	}

	const handleAcnaChange = (e) => {
		setMSG('');
		//const { name, value } = e.target;
		logger.info("Setting ACNA: " + encodeURIComponent(e.target.value).replace(/%20/g, " "));
		setAcna(e.target.value);
		//setIsSubmit(true);
	}

	const handleChange = (e) => {
		setMSG('');
		const { name, value } = e.target;
		setFormValues((prevState) => ({ ...prevState, [name]: value }));
		//setIsSubmit(true);
	}

	const handleSubmitForAcna = () => {
		setLoader(true);
		let submitJSON = {
			"selectedProfile": profileFromStore.selectedProfile.value,
			"action": "new",
			"acna": acna
		}
		//Calling axios service to call profileProductSubmit.php
		logger.info("ACNA Data: " + JSON.stringify(submitJSON));
		ProfileService.submitAcna(submitJSON).then(resp => {
			logger.info('ACNA Submit Response: ' + encodeURIComponent(resp.data?.result).replace(/%20/g, " "));
			if (resp.data?.result === "SUCCESS") {
				ProfileService.getACNAS().then(res => {
					logger.info("ACNAS: " + encodeURIComponent(JSON.stringify(res.data)).replace(/%20/g, " "));
					props.acnas(res.data);
					setSuccessMsg('Added Successfully');
					setIsSuccess(true);
					setLoader(false);
				}).catch(er => {
					setMSG(er);
					logger.error("Error Getting ACNAs: " + er);
					setLoader(false);
				})
			} else {
				logger.error("ACNA Submit Error: " + encodeURIComponent(resp.data?.error).replace(/%20/g, " "));
				setMSG(resp.data?.error);
				setLoader(false);
			}
		})
	}


	const errorCheckSearchForCAMP = (values) => {
		const errors = {};
		return errors;
	}

	const errorCheckSubmitForCAMP = (values) => {
		const errors = {};
		if (!values.billingAccount) {
			errors.billingAccount = "Billing Account is Required";
		}
		if (!values.globalAccount) {
			errors.globalAccount = "Global Account is Required";
		}
		if (!values.accountName) {
			errors.accountName = "Account Name is Required";
		}
		if (!values.billable) {
			errors.billable = "Billable is Required";
		}
		return errors;
	}


	const errorCheckSubmitForCAMPUpdate = (values) => {
		const errors = {};
		if (!values.accountName) {
			errors.accountName = "Account Name is Required";
		}
		if (!values.system) {
			errors.system = "Billing System is Required";
		}
		if (!values.billable) {
			errors.billable = "Billable is Required";
		}
		return errors;
	}

	const moveCamp = (data) => {
		setLoader(true);
		let submitJSON = {
			"selectedProfile": profileFromStore.selectedProfile.value,
			"data": formValues
		}

		logger.info("Move Account Data: " + JSON.stringify(submitJSON));
		ProfileService.moveAccount(submitJSON).then(resp => {
			logger.info('Account Search Response: ' + encodeURIComponent(resp.data?.result).replace(/%20/g, " "));
			if (resp.data?.result === "SUCCESS") {
				setSuccessMsg('Moved Successfully');
				setIsSuccess(true);
				setLoader(false);
				props.onSuccessfulEdit();
			} else {
				logger.error("Account Submit Error: " + encodeURIComponent(resp.data?.error).replace(/%20/g, " "));
				setMSG(resp.data?.error);
				setLoader(false);
			}
		});
	}

	const handleSearchForCAMP = () => {
		setLoader(true);
		let submitJSON = {
			"selectedProfile": profileFromStore.selectedProfile.value,
			"data": formValues
		}
		//Calling axios service to call accountSubmit
		logger.info("Account Data: " + JSON.stringify(submitJSON));
		ProfileService.searchAccount(submitJSON).then(resp => {
			logger.info('Account Search Response: ' + encodeURIComponent(resp.data?.result).replace(/%20/g, " "));
			logger.info('Account Search Response: ' + JSON.stringify(resp.data));
			if (resp.data?.result === "NONE") {
				setModalAction("CAMP");
				setLoader(false);
			} else if (resp.data?.result === "EDIT") {
				const currentValues = { orig_mpid: resp.data?.orig_mpid, globalAccount: resp.data?.gaid, billingAccount: resp.data?.ban, accountName: resp.data?.name, billingSystem: resp.data?.system, billable: resp.data?.billable, moveable: resp.data?.moveable };
				setFormValues(currentValues);
				setModalTitle("Edit CAMP Account");
				setModalAction("EDITCAMP");
				setLoader(false);
			} else if (resp.data?.result === "DISPLAY") {
				const currentValues = { globalAccount: resp.data?.gaid, billingAccount: resp.data?.ban, accountName: resp.data?.name, billingSystem: resp.data?.system, billable: resp.data?.billable, moveable: resp.data?.moveable };
				setFormValues(currentValues);
				setModalTitle("CAMP Account");
				setModalAction("DISPLAYCAMP");
				if (resp?.data?.update) setMoveMsg(resp.data?.update);
				setLoader(false);
			} else {
				logger.error("Account Search Error: " + encodeURIComponent(resp.data?.error).replace(/%20/g, " "));
				setMSG(resp.data?.error);
				setLoader(false);
			}
		})
	}

	const handleSubmitForCAMP = (values) => {
		setLoader(true);
		let submitJSON = {
			"selectedProfile": profileFromStore.selectedProfile.value,
			"data": formValues
		}
		//Calling axios service to call accountSubmit
		logger.info("Account Data: " + JSON.stringify(submitJSON));
		ProfileService.submitAccount(submitJSON).then(resp => {
			logger.info('Account Submit Response: ' + encodeURIComponent(resp.data?.result).replace(/%20/g, " "));
			if (resp.data?.result === "SUCCESS") {
				props.onSuccessfulAdd();
				setIsSuccess(true);
				setLoader(false);
			} else {
				logger.error("Account Submit Error: " + encodeURIComponent(resp.data?.error).replace(/%20/g, " "));
				setMSG(resp.data?.error);
				setLoader(false);
			}
		})
	}

	const handleSubmitForCAMPUpdate = (values) => {
		setLoader(true);
		let submitJSON = {
			"selectedProfile": profileFromStore.selectedProfile.value,
			"data": formValues,
			"origData": originalFormValues
		}
		//Calling axios service to call accountSubmit
		logger.info("Update Account Data: " + JSON.stringify(submitJSON));
		ProfileService.updateCAMPAccount(submitJSON).then(resp => {
			logger.info('Account Update Response: ' + encodeURIComponent(resp.data?.result).replace(/%20/g, " "));
			if (resp.data?.result === "SUCCESS") {
				// Invoke the callback function passed as a prop
				props.onSuccessfulEdit();
				setSuccessMsg('Updated Successfully');
				setIsSuccess(true);
				setLoader(false);
			} else {
				logger.error("Account Update Error: " + encodeURIComponent(resp.data?.error).replace(/%20/g, " "));
				setMSG(resp.data?.error);
				setLoader(false);
			}
		})
	}

	useEffect(() => {
		switch (modalAction) {
			case 'EDITCAMP':
				const currentValues = { globalAccount: props.rowData.gaid, billingAccount: props.rowData.ban, accountName: props.rowData.name, billingSystem: props.rowData.system, billable: props.rowData.billable };
				setFormValues(currentValues);
				setOriginalFormValues(currentValues);
				setButtonVal('Update');
				setSuccessMsg('Updated Successfully');
				break;
			case 'SEARCHCAMP':
				setButtonVal('Search');
				break;
			case 'DISPLAYCAMP':
				setButtonVal('Ok');
				break;
			default:
				setButtonVal('Save');
				break;
		}
	}, [props.rowData, modalAction])

	/*
		useEffect(() => {
			if (Object.keys(formErrors).length === 0 && modalAction === "CAMP" && isSubmit) {
				console.log(formValues);
				setIsSuccess(true)
			}
		}, [formErrors])
	*/
	return (
		<div>
			<div className={`${style.overly}`}> </div>
			{!isSuccess ?
				<div className="modal fade show d-block">
					{loader ? <Loader /> : null}
					<div className={` modal-dialog modal-dialog-centered modal-md`}>
						<div className="modal-content">
							<div class="modal-header">
								<h5 class="modal-title m-auto text-dark">{modalTitle}</h5>
							</div>
							<div className="modal-body">
								{modalAction === "ACNA" ?
									<>
										<p>Please add the three character ACNA for the profile selected below and click Save</p>
										<p>If this request is not needed please click the Cancel button test</p>
										<div className="d-flex align-items-center justify-content-center">
											<label>ACNA</label>
											<input type="text" className={` ${style.inputFldText} w-25 ml-3`} value={formValues.acna} onChange={handleAcnaChange}></input>
										</div>
									</>
									: null
								}

								{modalAction === "SEARCHCAMP" ?
									<>
										<div className='mb-3'>
											<label className='labelText'> Camp Account <span className={` ${style.mandatory} `}>*</span></label>
											<input type="text" name="globalAccount" className={`${style.inputFldText} `} value={formValues.globalAccount} onChange={handleChange} />
											<p className={style.errorMsg}>{formErrors.globalAccount}</p>
										</div>
									</>
									: null}


								{modalAction === "CAMP" ?
									<>
										<div className='mb-3'>
											<label className='labelText'> Billing Account <span className={` ${style.mandatory} `}>*</span></label>
											<input type="text" name="billingAccount" className={`${style.inputFldText} `} value={formValues.billingAccount} onChange={handleChange} />
											<p className={style.errorMsg}>{formErrors.billingAccount}</p>
										</div>
										<div className='mb-3'>
											<label className='labelText'> Global Account <span className={` ${style.mandatory} `}>*</span></label>
											<input type="text" name="globalAccount" className={`${style.inputFldText} `} value={formValues.globalAccount} onChange={handleChange} />
											<p className={style.errorMsg}>{formErrors.globalAccount}</p>
										</div>
										<div className='mb-3'>
											<label className='labelText'> Account Name <span className={` ${style.mandatory} `}>*</span></label>
											<input type="text" name="accountName" className={`${style.inputFldText} `} value={formValues.accountName} onChange={handleChange} />
											<p className={style.errorMsg}>{formErrors.accountName}</p>
										</div>
										<div className='mb-3'>
											<label className='labelText'> Billable <span className={` ${style.mandatory} `}>*</span></label>
											<select className={style.selectFld} id="billable" name="billable" value={formValues.billable} onChange={handleChange} >
												<option></option>
												<option value="1">Yes</option>
												<option value="0">No</option>
											</select>
											<p className={style.errorMsg}>{formErrors.billable}</p>
										</div>
									</>
									: null}



								{modalAction === "EDITCAMP" ?
									<>
										<div className='mb-3'>
											<label className='labelText'> Account Name <span className={` ${style.mandatory} `}>*</span></label>
											<input type="text" name="accountName" className={`${style.inputFldText} `} value={formValues.accountName} onChange={handleChange} />
											<p className={style.errorMsg}>{formErrors.accountName}</p>
										</div>
										<div className='mb-3'>
											<label className='labelText'> Billing System <span className={` ${style.mandatory} `}>*</span></label>
											<select className={style.selectFld} id="billingSystem" name="billingSystem" value={formValues.billingSystem} onChange={handleChange} >
												<option></option>
												<option value="RevChain">RevChain</option>
												<option value="CABS">CABS</option>
											</select>
											<p className={style.errorMsg}>{formErrors.billingSystem}</p>
										</div>
										<div className='mb-3'>
											<label className='labelText'> Billable <span className={` ${style.mandatory} `}>*</span></label>
											{/*<input type="text" name="billingSystem" className={`${style.inputFldText} `} value={formValues.billingSystem} onChange={handleChange} /> }*/}
											<select className={style.selectFld} id="billable" name="billable" value={formValues.billable} onChange={handleChange} >
												<option></option>
												<option value="1">Yes</option>
												<option value="0">No</option>
											</select>
											<p className={style.errorMsg}>{formErrors.billable}</p>
										</div>
									</>
									: null}

								{modalAction === "DISPLAYCAMP" ?
									<>
										<div className='mb-3'>
											<p><label className='labelText'> Billing Account: </label>  {formValues.billingAccount}</p>
										</div>
										<div className='mb-3'>
											<p><label className='labelText'> Global Account:  </label>  {formValues.globalAccount}</p>
										</div>
										<div className='mb-3'>
											<p><label className='labelText'> Account Name:	</label>  {formValues.accountName}</p>
										</div>
										<div className='mb-3'>
											<p><label className='labelText'> Billing System:	</label>  {formValues.billingSystem}</p>
										</div>
										<div className='mb-3'>
											<p><label className='labelText'> Billable:	</label>  {Number(formValues.billable) === 1 ? 'Yes' : 'No'}</p>
										</div>
										<div className='mb-3'>
											{!formValues.moveable ?
												<>
													<p className="text-danger">{moveMsg}</p>
												</>
												:
												<>
													<p> This account is assigned to MPID: {formValues.orig_mpid}.</p>
													<span>
														<p>
															Move account to this MPID?	<button type="button" className="btn-style btn-theme" data-dismiss="modal" onClick={() => moveCamp(formValues)}>Move</button>
														</p>
													</span>
												</>
											}
										</div>
									</>
									: null}

							</div>

							{MSG.length ?
								<div>
									<p className={`text-center my-4 ${MSG === 'Success' ? 'text-success' : 'text-danger'} `}>{MSG}</p>
								</div> : null}

							<div className="modal-footer d-block text-center">
								<button type="button" className="btn-style btn-cancel" data-dismiss="modal" onClick={() => popupCancel()}>Cancel</button>
								<button type="button" className="btn-style btn-theme" data-dismiss="modal" onClick={() => { popupOk() }}>{buttonVal}</button>
							</div>

						</div>
					</div>
				</div>
				: null}

			{isSuccess ?
				<div className="modal fade show d-block">
					<div className={` modal-dialog modal-dialog-centered modal-sm`}>
						<div className="modal-content">
							<div className="modal-body">

								<p className="text-success text-center m-0">{successMsg}</p>
							</div>
							<div className="modal-footer d-block text-center">
								<button type="button" className="btn-style btn-cancel" data-dismiss="modal" onClick={() => popupCancel()}>Close</button>
							</div>
						</div>
					</div>
				</div>
				: null}
		</div>
	)
}

const mapDispatchToProps = {
	camp_accounts: camp_accounts,
	camp_accounts_download: camp_accounts_download,
	acnas: acnas
}

export default connect(null, mapDispatchToProps)(Modal);
