import style from "./Report.module.css";
import XLSXExport from "../../utils/XLSXExport";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import filterFactory from "react-bootstrap-table2-filter";

const ParentChildReport = ({ ParentChildData }) => {
	const columns = [{
		dataField: "usgDttm",
		text: "Invoice Month",
	},
	{
		dataField: "extInvId",
		text: "Invoice ID"
	},
	{
		dataField: "billFrom",
		text: "Bill From",
	},
	{
		dataField: "billTo",
		text: "Bill To"
	},
	{
		dataField: "parentSubId",
		text: "Parent Account Number"
	},
	{
		dataField: "parentSubName",
		text: "Parent Account Name"
	},
	{
		dataField: "subId",
		text: "Child Account Number"
	},
	{
		dataField: "subNm",
		text: "Child Account Name"
	},
	{
		dataField: "ctNum",
		text: "Connection Number"
	},
	{
		dataField: "cmsnTyp",
		text: "Category"
	},
	{
		dataField: "featNm",
		text: "Feature Name"
	},
	{
		dataField: "transNm",
		text: "Transaction Name"
	},
	{
		dataField: "srvcNm",
		text: "Service Name"
	},
	{
		dataField: "rtdAmt",
		text: "Amount"
	}];

	const XLSXHeaders = ["Invoice Month", "Invoice ID", "Bill From", "Bill To", "Parent Account Number", "Parent Account Name", "Child Account Number", 
		"Child Account Name", "Connection Number", "Category", "Feature Name", "Transaction Name", "Service Name", "Amount"];

	return (
		<>
			<div className='d-flex mb-5 ml-3'>
				<div className="w15 mr-5">
					<button className="saveButton ico-button light-button" onClick={() => XLSXExport.exportToExcel(XLSXHeaders, ParentChildData, `Parent_Child_Report${Date.now()}`, 'AA')}><span><strong>Export to Excel</strong></span>
						<svg viewBox="0 0 34 34" className={`ico light-ico ico-arrow downloadArrow`}>
							<path className="cls-1" d="M1,18V16L17,31V1h2V31L35,16v2L20,33H35v2H1V33H16Z"
								transform="translate(-1 -1)">
							</path>
						</svg>
					</button>
				</div>
				<div className="w15 ">
					<button className="saveButton ico-button light-button" onClick={() => XLSXExport.exportToExcel(XLSXHeaders, ParentChildData, `Parent_Child_Report${Date.now()}`, 'AA', '.csv')}><span><strong>Export to CSV</strong></span>
						<svg viewBox="0 0 34 34" className={`ico light-ico ico-arrow downloadArrow`}>
							<path className="cls-1" d="M1,18V16L17,31V1h2V31L35,16v2L20,33H35v2H1V33H16Z"
								transform="translate(-1 -1)">
							</path>
						</svg>
					</button>
				</div>
			</div>

			<BootstrapTable
				id="parentChildTable"
				classes={`${style.parentChildTable} table-fixed table table-responsive `}
				bodyClasses={`${style.parentChildTableRows}`}
				keyField='subId'
				data={ParentChildData}
				columns={columns}
				bootstrap4
				striped
				hover
				bordered={false}
				pagination={paginationFactory({
					paginationSize: 2,
					pageStartIndex: 1,
					sizePerPage: 5
				})}
				filter={filterFactory()}
				loading={true}
			/>
		</>
	)
}

export default ParentChildReport;
