import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';


const columns =  [{
    dataField: 'circuit_id',
    text: 'Circuit Id',
    sort: true,
    style: {
    },
    classes: 'w12'
}, {
    dataField: 'protected',
    text: 'Type',
    classes: 'w12'
}, {
    dataField: 'rate_code',
    text: 'Product'
},
{
    dataField: 'address_a',
    text: 'ALoc'
}, {
    dataField: 'address_z',
    text: 'ZLoc'
}];


const CircuitsAffected = ({circuitsAffected}) => {
    const data = circuitsAffected;
    return (
        <header>
            <h6><b>Circuits Affected</b></h6>
            <div className="table-style-wrap pagination-table border border-5 border-dark">
                { data.length > 0 ?
                    <BootstrapTable 
                        bootstrap4
                        keyField='id'
                        data={data}
                        columns={columns}
                        hover
                        condensed
                        bordered={false}
                    />
                    : null
                }
            </div>
        </header>
    )
}

export default CircuitsAffected;
