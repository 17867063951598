import {ORDER_STEP_CODE, RESET_ACTION} from "./types/actionTypes";

export const setOrderStepCode = (step_code) => {
	return {
		type: ORDER_STEP_CODE,
		payload: step_code
	}
}

export const resetData = (data) => {
	return {
		type: RESET_ACTION,
		payload: data
	}
}