import style from "./QuoteCreate.module.css";
import {useCallback, useEffect, useState, useMemo} from "react";
import {connect, useSelector} from "react-redux";
import PremAddress from "./PremAddress";
import Term from "./Term"
import Loader from '../shared/Loader';
import EAccessHelper from "../../services/EAccessService"
import {
	setQuoteAccessType,
	setQuoteAddBW1,
	setQuoteAddBW2,
	setQuoteBW,
	setQuoteCircuitType,
	setQuoteCOS,
	setQuoteCosType1,
	setQuoteCosType2,
	setQuoteCosType3,
	setQuoteLocAUni,
	setQuoteQty,
	setQuoteVpnAPort1,
	setQuoteVpnAPort2,
	setQuoteVpnAPort3
} from "../../redux/actions/quoteActions";
import {ConsoleLogger} from "../../logger/ConsoleLogger";
import HTTPErrorHandler from "../errorPages/HTTPErrorHandler";
import VendorExclusion from "./VendorExclusion";

const EAccess = (props) => {
	const logger = useMemo(() => new ConsoleLogger({level: process.env.REACT_APP_LOGGER_LEVEL,}),[]);
	const [hasError, setHasError] = useState(null);
	const [bandwidthAdded, setBandwidthAdded] = useState(false);
	const [bandwidthCount, setBandwidthCount] = useState(0);
	const [eAccessBWOptions, setEAccessBWOptions] = useState([]);
	const [loader, setLoader] = useState(false);
	const selectedEAccessValues = useSelector(state => state.quote.quoteValues);
	const selectedEAccessValidationValues = useSelector(state => state.quoteLocA.locA.eaccessValidation)

	useEffect(() => {
		if(props.stepCode === 3){
			if(selectedEAccessValues.bw1 !== ""){
				setBandwidthCount(1);
			}
			if(selectedEAccessValues.bw2 !== ""){
				setBandwidthCount(2);
			}
		}
	},[props.stepCode, selectedEAccessValues.bw1, selectedEAccessValues.bw2])

	const getEAccessBW = useCallback(() => {
		if (props.stepCode === 3 && props.section === "bandwidth" && selectedEAccessValues.circuit && selectedEAccessValues.accessType) {
			setLoader(true);
			EAccessHelper.getBWOptions(selectedEAccessValues.circuit, selectedEAccessValues.accessType).then(resp => {
				logger.info(resp.data);
				setEAccessBWOptions(resp.data);
				setLoader(false);
			}).catch(error => {
				logger.error("Getting E-Access Bandwidths: " + error);
				setHasError(error);
				setLoader(false);
			})
		}
	},[logger, props.section, props.stepCode, selectedEAccessValues.accessType, selectedEAccessValues.circuit])

	useEffect(() => {
		getEAccessBW();
	}, [selectedEAccessValues.circuit, selectedEAccessValues.accessType, getEAccessBW]);

	const addBandwidth = () => {
		setBandwidthAdded(!bandwidthAdded);
		setBandwidthCount(bandwidthCount < 3 ? bandwidthCount + 1 : bandwidthCount);
	}

	const removeBandwidth = () => {
		let counter = bandwidthCount - 1;
		setBandwidthCount(bandwidthCount - 1);
		if (counter === 0) {
			props.updateEAccessAddBw1("");
			props.updateEAccessCosType2("");
			props.updateEAccessAVpnPort2("");
		}
		if (counter === 1) {
			props.updateEAccessAddBw2("");
			props.updateEAccessCosType3("");
			props.updateEAccessAVpnPort3("");

		}

	}

	const bandwidthHandler = (event) => {
		switch (event.target.id) {
			case "bw":
				props.updateEAccessBW(event.target.value);
				break;
			case "bw1":
				props.updateEAccessAddBw1(event.target.value);
				break;
			case "bw2":
				props.updateEAccessAddBw2(event.target.value);
				break;
			default:
				break
		}
	}

	const inputChange = (event) => {
		switch (event.target.id) {
			case "circuit":
				props.updateEAccessCircuitType(event.target.value);
				if (event.target.value === "11") {
					props.updateEAccessLocAUni("no");
				}
				break;
			case "accessType":
				props.updateEAccessType(event.target.value);
				props.updateEAccessCos(selectedEAccessValidationValues.cos);
				break;
			case "la_existing_uni":
				props.updateEAccessLocAUni(event.target.value);
				break;
			case "cos1":
				props.updateEAccessCosType1(event.target.value);
				break;
			case "a_vpn_port_1":
				props.updateEAccessAVpnPort1(event.target.value);
				break;
			case "cos2":
				props.updateEAccessCosType2(event.target.value);
				break;
			case "a_vpn_port_2":
				props.updateEAccessAVpnPort2(event.target.value);
				break;
			case "cos3":
				props.updateEAccessCosType3(event.target.value);
				break;
			case "a_vpn_port_3":
				props.updateEAccessAVpnPort3(event.target.value);
				break;
			default:
				break;
		}
	}


	return (
		<>
		{hasError && <HTTPErrorHandler error={hasError}></HTTPErrorHandler>}
			{loader ? <Loader /> : null}
			{!hasError && (<>{props.stepCode === 2 ?
				<PremAddress></PremAddress> : null
			}
			{(props.stepCode === 3) && (props.section === "term") ?
				<Term product={"E_ACCESS"} /> : null
			}
			{(props.stepCode === 3) && (props.section === "productConfig") ?
				<>
					<div className={`row ${style.rowGrp} `}>
						<div className="col-12 col-sm-4 col-md-3 mb-5">
							<label className={style.labelSelectRequiredFld}>Quantity <span className={style.mandatory}>*</span></label>
							<select className={` form-control  ${style.selectFld} ${style.requiredFld} `} id='qty' onChange={inputChange} value={selectedEAccessValues.qty}>
								<option value="">-</option>
								<option value="1">1</option>
							</select>
						</div>
						<div className="col-12 col-sm-4 col-md-3 mb-5">
							<label className={style.labelSelectRequiredFld}>Access Type <span className={style.mandatory}>*</span></label>
							<select defaultValue={'-'} className={` form-control  ${style.selectFld} ${style.requiredFld} `} id='accessType' onChange={inputChange} value={selectedEAccessValues.accessType}>
								<option value="" disabled>-</option>
								{selectedEAccessValidationValues.fiberOnet === true ?
									<option value="6">Fiber On-Net</option> : null
								}
								{selectedEAccessValidationValues.offnet === true ?
									<option value="7">Ethernet Off-Net</option> : null
								}
							</select>
						</div>
						<div className="col-6 col-sm-4 col-md-3 rel mb-5">
							<div className="d-flex">
								<div className={style.w_90}>
									<label className={style.labelSelectRequiredFld}>Circuit Type <span className={style.mandatory}>*</span></label>
									<select className={` form-control  ${style.selectFld} ${style.requiredFld} `} id="circuit" onChange={inputChange} value={selectedEAccessValues.circuit}>
										<option value="" disabled>-</option>
										<option value="11">EPL</option>
										<option value="12">EVPL</option>
									</select>
								</div>
								<div className={` ${style.w_10} align-self-center`}>
									<div className={style.infoTip}>
										<img src="../../images/svg/ico-info-filled-dark-off.svg" alt="info tip" className={style.infoImg} />
										<p className={style.contentHover}>
											<b>EPL:   </b> Point to Point Ethernet service. Cannot multiplex at UNI port at A or Z end. Very transparent service. No VLAN tag coordination required.
											<br></br>
											<br></br>
											<b>EVPL:   </b> Point to Point Ethernet Service. Can multiplex UNI port at A or Z end. More than one circuit can be provisioned from an existing UNI port. Bandwidth ordered cannot exceed UNI port size. VLAN tag coordination required.
										</p>
									</div>
								</div>
							</div>
						</div>
						{selectedEAccessValues.circuit === "12" || selectedEAccessValues.circuit === 12 ?
							<div className="col-12 col-sm-4 col-md-3 mb-5">
								<label className={style.labelSelectRequiredFld}>Existing UNI Location A<span className={style.mandatory}>*</span></label>
								<select className={` form-control  ${style.selectFld} ${style.requiredFld} `} id='la_existing_uni' onChange={inputChange} value={selectedEAccessValues.la_existing_uni}>
									<option value="" disabled>-</option>
									<option value="no">No - New UNI</option>
									<option value="2">Existing - 10/100 Mbps</option>
									<option value="3">Existing - 1G</option>
									<option value="4">Existing - 10G</option>
								</select>
							</div> : null
						}

					</div>
				</> : null
			}
			{(props.stepCode === 3) && (props.section === "bandwidth") && (selectedEAccessValues.circuit) && (selectedEAccessValues.accessType) ?
				<>
					<div className="col-6 col-sm-4 col-md-3">
						<label className={style.labelSelectRequiredFld}>Bandwidth <span
							className={style.mandatory}>*</span></label>
						<select className={` form-control  ${style.selectFld} ${style.requiredFld} `} id="bw" onChange={bandwidthHandler} value={selectedEAccessValues.bw}>
							{eAccessBWOptions.map((obj) => {
								return <option value={obj.id}>{obj.name}</option>
							})}
						</select>
					</div>
					{selectedEAccessValidationValues.cos === true ?
						<div className="col-2 col-sm-2">
							<div className="d-flex">
								<div className={style.w_90}>
									<label className={style.labelSelectRequiredFld}>Class of Service <span className={style.mandatory}>*</span></label>
									<select defaultValue={'-'} className={` form-control  ${style.selectFld} ${style.requiredFld} `} id="cos1" onChange={inputChange} value={selectedEAccessValues.cos1} >
										<option value="" disabled>-</option>
										<option value="1">Standard Data</option>
										<option value="2">Mission Critical</option>
										<option value="3">Real Time</option>
									</select>
								</div>
								<div className={` ${style.w_10} align-self-center`}>
									<div className={` ${style.infoTip}`}>
										<img src="../../images/svg/ico-info-filled-dark-off.svg" alt="info tip" className={style.infoImg} />
										<p className={style.contentHover}>
											Real-Time - High CoS
											<br></br>
											Mission Critical - Medium CoS
											<br></br>
											Standard - Low CoS
										</p>
									</div>
								</div>
							</div>
						</div>
						: null}
					{selectedEAccessValues.la_existing_uni === "no" ?
						<div className="col-2 col-sm-2">
							<div className="d-flex">
								<div className={style.w_90}>
									<label className={style.labelSelectRequiredFld}>Location A UNI <span className={style.mandatory}>*</span></label>
									<select defaultValue={'-'} className={` form-control  ${style.selectFld} ${style.requiredFld} `} id="a_vpn_port_1" onChange={inputChange} value={selectedEAccessValues.a_vpn_port_1}>
										<option value="" disabled>-</option>
										{Number(selectedEAccessValues.bw) <= 270 ?
											<option value="2">10/100 Mbps</option>
											: null}
										<option value="3">1G</option>
										<option value="4">10G</option>
									</select>
								</div>
							</div>
						</div> : null
					}

					<div className="col-1 col-sm-1 col-md-1 align-self-center clearfix">
						<div className={`add-toggle ${style.actionBtn} ${bandwidthAdded ? '' : ''} `} onClick={addBandwidth}>
							<svg className="ico-plus"><path d="M13.1,24H10.89V13.1H0V10.89H10.89V0H13.1V10.89H24V13.1H13.1Z"></path></svg>
						</div>
					</div>
					{bandwidthCount >= 1 ?
						<>
							<div className={`w-100 ${style.rowGrp} `}></div>
							<div className="col-6 col-sm-4 col-md-3">
								<label className={style.labelSelectRequiredFld}>Additional Bandwidth <span
									className={style.mandatory}>*</span></label>
								<select className={` form-control  ${style.selectFld} ${style.requiredFld} `} id="bw1" onChange={bandwidthHandler} value={selectedEAccessValues.bw1} >
									{eAccessBWOptions.map((obj) => {
										return <option value={obj.id}>{obj.name}</option>
									})}
								</select>
							</div>
							{selectedEAccessValidationValues.cos === true ?
								<div className="col-2 col-sm-2">
									<div className="d-flex">
										<div className={style.w_90}>
											<label className={style.labelSelectRequiredFld}>Class of Service <span className={style.mandatory}>*</span></label>
											<select defaultValue={'-'} className={` form-control  ${style.selectFld} ${style.requiredFld} `} id="cos2" onChange={inputChange} value={selectedEAccessValues.cos2}>
												<option value="" disabled>-</option>
												<option value="1">Standard Data</option>
												<option value="2">Mission Critical</option>
												<option value="3">Real Time</option>
											</select>
										</div>
										<div className={` ${style.w_10} align-self-center`}>
											<div className={` ${style.infoTip}`}>
												<img src="../../images/svg/ico-info-filled-dark-off.svg" alt="info tip" className={style.infoImg} />
												<p className={style.contentHover}>
													Real-Time - High CoS
													<br></br>
													Mission Critical - Medium CoS
													<br></br>
													Standard - Low CoS
												</p>
											</div>
										</div>
									</div>
								</div>
								: null}
							{selectedEAccessValues.la_existing_uni === "no" ?
								<div className="col-2 col-sm-2">
									<div className="d-flex">
										<div className={style.w_90}>
											<label className={style.labelSelectRequiredFld}>Location A UNI <span className={style.mandatory}>*</span></label>
											<select defaultValue={'-'} className={` form-control  ${style.selectFld} ${style.requiredFld} `} id="a_vpn_port_2" onChange={inputChange} value={selectedEAccessValues.a_vpn_port_2}>
												<option value="" disabled>-</option>
												{Number(selectedEAccessValues.bw1) <= 270 ?
													<option value="2">10/100 Mbps</option>
													: null}
												<option value="3">1G</option>
												<option value="4">10G</option>
											</select>
										</div>
									</div>
								</div> : null
							}

							<div className={`col-1 col-sm-1 col-md-1 align-self-center clearfix${style.svgWarp} `}>
								<div className={`add-toggle ${style.actionBtn} ${bandwidthAdded ? '' : ''} `}>
									<svg className="ico-plus" onClick={addBandwidth}><path d="M13.1,24H10.89V13.1H0V10.89H10.89V0H13.1V10.89H24V13.1H13.1Z"></path></svg>
									<svg version="1.1" id="Layer_1" className="ico-plus" viewBox="0 0 511.99 511.99" onClick={removeBandwidth}>
										<path d="M8.27,222.43c165.13,0,330.25,0,495.73,0c0,21.3,0,42.35,0,64.01c-165.25,0-330.49,0-495.73,0
                    C8.27,265.1,8.27,243.76,8.27,222.43z"/>
									</svg>
								</div>
							</div>
						</> : null}
					{bandwidthCount >= 2 ?
						<>
							<div className={`w-100 ${style.rowGrp} `}></div>
							<div className="col-6 col-sm-4 col-md-3">
								<label className={style.labelSelectRequiredFld}>Additional Bandwidth <span
									className={style.mandatory}>*</span></label>
								<select className={` form-control  ${style.selectFld} ${style.requiredFld} `} id="bw2" onChange={bandwidthHandler} value={selectedEAccessValues.bw2}>
									{eAccessBWOptions.map((obj) => {
										return <option value={obj.id}>{obj.name}</option>
									})}
								</select>
							</div>
							{selectedEAccessValidationValues.cos === true ?
								<div className="col-2 col-sm-2">
									<div className="d-flex">
										<div className={style.w_90}>
											<label className={style.labelSelectRequiredFld}>Class of Service <span className={style.mandatory}>*</span></label>
											<select defaultValue={'-'} className={` form-control  ${style.selectFld} ${style.requiredFld} `} id="cos3" onChange={inputChange} value={selectedEAccessValues.cos3}>
												<option value="" disabled>-</option>
												<option value="1">Standard Data</option>
												<option value="2">Mission Critical</option>
												<option value="3">Real Time</option>
											</select>
										</div>
										<div className={` ${style.w_10} align-self-center`}>
											<div className={` ${style.infoTip}`}>
												<img src="../../images/svg/ico-info-filled-dark-off.svg" alt="info tip" className={style.infoImg} />
												<p className={style.contentHover}>
													Real-Time - High CoS
													<br></br>
													Mission Critical - Medium CoS
													<br></br>
													Standard - Low CoS
												</p>
											</div>
										</div>
									</div>
								</div>
								: null}
							{selectedEAccessValues.la_existing_uni === "no" ?
								<div className="col-2 col-sm-2">
									<div className="d-flex">
										<div className={style.w_90}>
											<label className={style.labelSelectRequiredFld}>Location A UNI <span className={style.mandatory}>*</span></label>
											<select defaultValue={'-'} className={` form-control  ${style.selectFld} ${style.requiredFld} `} id="a_vpn_port_3" onChange={inputChange} value={selectedEAccessValues.a_vpn_port_3}>
												<option value="" disabled>-</option>
												{Number(selectedEAccessValues.bw2) <= 270 ?
													<option value="2">10/100 Mbps</option>
													: null}
												<option value="3">1G</option>
												<option value="4">10G</option>
											</select>
										</div>
									</div>
								</div> : null
							}


							<div className={`col-1 col-sm-1 col-md-1 align-self-center clearfix ${style.svgWarp} `}>
								<div className={`add-toggle ${style.actionBtn} ${bandwidthAdded ? '' : ''} `}>

									<svg version="1.1" id="Layer_1" className="ico-plus" viewBox="0 0 511.99 511.99" onClick={removeBandwidth}>
										<path d="M8.27,222.43c165.13,0,330.25,0,495.73,0c0,21.3,0,42.35,0,64.01c-165.25,0-330.49,0-495.73,0
C8.27,265.1,8.27,243.76,8.27,222.43z"/>
									</svg>
								</div>
							</div>
						</> : null}

				</> : null}
			{(props.stepCode === 3) && (props.section === "Options") ?
				<VendorExclusion /> : null
			}
			</>)}

		</>
	)
}

const mapDispatchToProps = {
	updateEAccessBW: setQuoteBW,
	updateEAccessAddBw1: setQuoteAddBW1,
	updateEAccessAddBw2: setQuoteAddBW2,
	updateEAccessType: setQuoteAccessType,
	updateEAccessCircuitType: setQuoteCircuitType,
	updateEAccessQty: setQuoteQty,
	updateEAccessCos: setQuoteCOS,
	updateEAccessLocAUni: setQuoteLocAUni,
	updateEAccessAVpnPort1: setQuoteVpnAPort1,
	updateEAccessAVpnPort2: setQuoteVpnAPort2,
	updateEAccessAVpnPort3: setQuoteVpnAPort3,
	updateEAccessCosType1: setQuoteCosType1,
	updateEAccessCosType2: setQuoteCosType2,
	updateEAccessCosType3: setQuoteCosType3
}

export default connect(null, mapDispatchToProps)(EAccess);
