import React, { useEffect, useState, useMemo } from "react";
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import ViewportWarning from "../shared/ViewportWarning";
import style from "../quote/QuoteCreate.module.css";
import QuoteHelper from "../../services/QuoteService";
import SupportHelper from "../../services/SupportService";
import Loader from "../shared/Loader";
import { ConsoleLogger } from "../../logger/ConsoleLogger";
import HTTPErrorHandler from "../errorPages/HTTPErrorHandler";

const RfoRcaRequestForm = (props) => {
	const customerName = useSelector(state => state.userReducer.user.pendo.name);
	const logger = useMemo(() => new ConsoleLogger({ level: process.env.REACT_APP_LOGGER_LEVEL, }), []);
	const [hasError, setHasError] = useState(null);
	const [loader, setLoader] = useState(false);
	const [contactArray, setContactArray] = useState([]);
	const [emailArray, setEmailArray] = useState([]);
	const [newContactFlag, setNewContactFlag] = useState(false);
	const [contact, setContact] = useState('');
	const [name, setName] = useState('');
	const [phoneNumber, setPhoneNumber] = useState('');
	const [email, setEmail] = useState('');
	const [isSuccess, setIsSuccess] = useState(false);
	const [isError, setIsError] = useState(false);
	const [validationErr, setValidationErr] = useState('');
	const [windstreamCase, setWindstreamCase] = useState('');
	const [customerInternalCase, setCustomerInternalCase] = useState('');
	const [emailToSend, setEmailToSend] = useState('');
	const [requestType, setRequestType] = useState('');
	const [questions, setQuestions] = useState('');
	const [adoNumber, setAdoNumber] = useState('');
	const [successRequestType, setSuccessRequestType] = useState('');
	const [isUnlistedEmail, setIsUnlistedEmail] = useState(false);

	const formFields = [
		{ id: 1, labelName: "Requestor Full Name", code: "contact", value: contact, required: true },
		{ id: 2, labelName: "Requestor Phone #", code: "phoneNumber", value: phoneNumber, required: true },
		{ id: 3, labelName: "Requestor Email", code: "email", value: email, required: true },
		{ id: 4, labelName: "Windstream Case #", code: "windstreamCase", value: windstreamCase, required: true },
		{ id: 5, labelName: "Customer Internal Case # (If Applicable)", code: "customerInternalCase", value: customerInternalCase, required: false },
		{ id: 6, labelName: "Customer/Account Name", code: "customerName", value: customerName, required: true },
		{ id: 7, labelName: "Email Address to send the completed RFO/RCA to when completed", code: "emailToSend", value: emailToSend, required: true },
		{ id: 8, labelName: "RFO / RCA", code: "requestType", value: requestType, required: true },
		{ id: 9, labelName: "Are there any specific questions or concerns for us to address within the RFO/RCA?", code: "questions", value: questions, required: false },
	];

	useEffect(() => {
		setLoader(true);
		QuoteHelper.getQuoteContact().then(resp => {
			setContactArray(resp.data);
			setLoader(false);
		}).catch((error) => {
			logger.error("Get Contact Error: " + error);
			setLoader(false);
			setHasError(error);
		});

		setLoader(true);
		QuoteHelper.getContactEmails().then(resp => {
			setEmailArray(resp.data);
			setLoader(false);
		}).catch((error) => {
			logger.error("Get Contact Emails Error: " + error);
			setLoader(false);
			setHasError(error);
		});
	}, []);

	const inputChange = (ev) => {
		switch (ev.target.id) {
			case "name":
				setName(ev.target.value);
				break;
			case "phoneNumber":
				setPhoneNumber(ev.target.value);
				break;
			case "email":
				setEmail(ev.target.value);
				break;
			case "windstreamCase":
				setWindstreamCase(ev.target.value);
				break;
			case "customerInternalCase":
				setCustomerInternalCase(ev.target.value);
				break;
			case "emailToSend":
				setEmailToSend(ev.target.value);
				break;
			case "questions":
				setQuestions(ev.target.value);
				break;
			default:
				break;
		}
	};

	const contactHandler = (ev) => {
		let cp_id = ev.target.value;
		if (cp_id === "new") {
			setNewContactFlag(true);
		} else {
			autoFillContact(cp_id);
		}
		setContact(cp_id);
	};

	const emailHandler = (ev) => {
		let email_id = ev.target.value;
		if (email_id === "unlisted") {
			setIsUnlistedEmail(true);
			setEmailToSend('');
		} else {
			setIsUnlistedEmail(false);
			setEmailToSend(email_id);
		}
	};

	const autoFillContact = (value) => {
		QuoteHelper.getQuoteContactInfo(value).then(resp => {
			setName(resp.data.name);
			setPhoneNumber(resp.data.phone);
			setEmail(resp.data.email);
		}).catch((error) => {
			logger.error("Get Contact info Error: " + error);
			setHasError(error);
		});
	};

	const validateFields = () => {
		let errMsg = '';
		let validCheck = 'success';

		if (windstreamCase.length !== 8 || isNaN(windstreamCase)) {
			errMsg += "Windstream Case # must be 8 numeric characters. ";
			validCheck = 'error';
		}

		if (customerInternalCase.length > 20 || !/^[a-zA-Z0-9]*$/.test(customerInternalCase)) {
			errMsg += "Customer Internal Case # (If Applicable) must be up to 20 alphanumeric characters. ";
			validCheck = 'error';
		}

		let findRequired = formFields.find(UF => (UF.value === '' || UF.value === undefined) && UF.required);
		if (!!findRequired) {
			errMsg += "Please enter the required fields. ";
			if (!contact) errMsg += "Name is required. ";
			if (contact === "new" && !name) errMsg += "Name is required. ";
			if (!email) errMsg += "Email is required. ";
			if (!phoneNumber) errMsg += "Phone number is required. ";
			validCheck = 'error';
		}

		return { validCheck, errMsg };
	};

	const handleSubmit = (event) => {
		event.preventDefault();
		const { validCheck, errMsg } = validateFields();

		if (validCheck === "error") {
			setValidationErr(errMsg);
			setIsError(true);
		} else {
			setLoader(true);
			let values = {
				"contact": name,
				"new_contact": contact === "new" ? name : null,
				"phone": phoneNumber,
				"email": email,
				"windstreamCase": windstreamCase,
				"customerInternalCase": customerInternalCase,
				"customerName": customerName,
				"emailToSend": emailToSend,
				"requestType": requestType,
				"questions": questions
			};
			logger.info(JSON.stringify(values));
			SupportHelper.RfoRcaRequest(values).then(resp => {
				logger.info(resp.data.result);
				if (resp.data.result === undefined || resp.data.result === "") {
					setValidationErr("The result was empty.");
					setIsError(true);
					setLoader(false);
				}
				if (resp.data.result === "SUCCESS") {
					setAdoNumber(resp.data.adoNumber);
					setSuccessRequestType(resp.data.requestType);
					setIsSuccess(true);
					setLoader(false);
				} else if (resp.data.result === "ERROR") {
					setValidationErr("Error: " + resp.data.error);
					setIsError(true);
					setLoader(false);
				}
			}).catch((error) => {
				setLoader(false);
				logger.error("Upload ERROR: " + error);
				setValidationErr("Error... " + error);
				setIsError(true);
			});
		}
	};

	if (isSuccess) {
		return <Redirect to={{
			pathname: "/Support/RfoRcaRequestSuccess",
			state: { requestType: successRequestType, adoNumber: adoNumber }
		}} />;
	}

	return (
		<div className="midsection qm-upload abs z1">
			{hasError && <HTTPErrorHandler error={hasError}></HTTPErrorHandler>}
			{loader ? <Loader /> : null}
			<div className="rel">
				<ViewportWarning />
				<div className="wrap">
					<h1 className="page-title">{"RFO/RCA Request Form"}</h1>
					<div className="section">
						<div className={`content ${style.contentQueryPage}`}>
							<form>
								<div className={`row `}>
									<div className="col-12">
										<p className={` ${style.lableTextS} ${style.textBlack} `}><span className={style.mandatory}>*</span> Denotes a required field</p>
									</div>
								</div>
								<div className={`sub-hdr alt ${style.subHeader} `}>
									<div className="nib">
										<div className="the-notch">
											<div className="notch-fg">
												<svg>
													<path d="M35.81,26.28l-13,10v-5C-10.47,28.68-1,9.16,11.79,0c0,7.28,0,9.13,0,13.28s3.18,7.92,11,9v-5Z"></path>
												</svg>
											</div>
										</div>
									</div>
									<h2 className={style.sectionTitle}>Project Details</h2>
								</div>
								<div className={`row ${style.rowGrp}`}>
									<div className="col-12 col-sm-4 col-md-3">
										<label className={style.labelSelectRequiredFld}> Requestor Full Name <span className={style.mandatory}>*</span> </label>
										{newContactFlag ?
											<input type="text" id="name" className={style.inputFld} onChange={inputChange}></input>
											:
											<select className={` form-control  ${style.selectFld} ${style.requiredFld} `} id="contact" value={contact} onChange={contactHandler}>
												{contactArray.map((obj) => {
													return <option key={obj.id} value={obj.id}>{obj.name}</option>
												})}
											</select>
										}
									</div>
									<div className="col-12 col-sm-4 col-md-3">
										<label className={style.lableTextS}>Requestor Phone # <span className={style.mandatory}>*</span></label>
										<input type="text" className={style.inputFld} maxLength="10" id="phoneNumber" onChange={inputChange} value={phoneNumber}></input>
									</div>
									<div className="col-12 col-sm-4 col-md-3">
										<label className={style.lableTextS}>Requestor Email <span className={style.mandatory}>*</span></label>
										<input type="text" className={style.inputFld} id="email" onChange={inputChange} value={email}></input>
									</div>
								</div>
								<div className={`row ${style.rowGrp}`}>
									<div className="col-12 col-sm-4 col-md-3">
										<label className={style.lableTextS}>Windstream Case # <span className={style.mandatory}>*</span></label>
										<input type="text" className={style.inputFld} maxLength="8" id="windstreamCase" onChange={inputChange} value={windstreamCase}></input>
									</div>
									<div className="col-12 col-sm-4 col-md-3">
										<label className={style.lableTextS}>Customer Internal Case # (If Applicable)</label>
										<input type="text" className={style.inputFld} maxLength="20" id="customerInternalCase" onChange={inputChange} value={customerInternalCase}></input>
									</div>
									<div className="col-12 col-sm-4 col-md-3">
										<label className={style.lableTextS}>Customer/Account Name <span className={style.mandatory}>*</span></label>
										<input type="text" className={style.inputFld} id="customerName" onChange={inputChange} value={customerName} disabled></input>
									</div>
								</div>
								<div className={`row ${style.rowGrp}`}>
									<div className="col-12 col-sm-4 col-md-3">
										<label className={style.lableTextS}>Email Address to send the completed RFO/RCA to when completed <span className={style.mandatory}>*</span></label>
										{isUnlistedEmail ? (
											<input type="text" className={style.inputFld} id="emailToSend" placeholder="Enter email address" onChange={inputChange} value={emailToSend}></input>
										) : (
											<select className={` form-control  ${style.selectFld} ${style.requiredFld} `} id="emailToSend" value={emailToSend} onChange={emailHandler}>
												{emailArray.map((obj) => {
													return <option key={obj.email} value={obj.email}>{obj.name}</option>
												})}
												<option value="unlisted">Add New Email</option>
											</select>
										)}
									</div>
									<div className="col-12 col-sm-4 col-md-3">
										<label className={style.lableTextS}>RFO / RCA <span className={style.mandatory}>*</span></label>
										<div>
											<label>
												<input type="radio" name="requestType" value="RFO" onChange={(e) => setRequestType(e.target.value)} />
												<span title="Request For Outage (RFO)">RFO</span>
											</label>
											<label style={{ marginLeft: '10px' }}>
												<input type="radio" name="requestType" value="RCA" onChange={(e) => setRequestType(e.target.value)} />
												<span title="Root Cause Analysis (RCA)">RCA</span>
											</label>
										</div>
										<small>Please Note there is a 5 business day turnaround for an RFO and a 10 Business day turnaround for an RCA</small>
									</div>
								</div>
								<div className={`row ${style.rowGrp}`}>
									<div className="col-12 col-sm-4 col-md-3">
										<label className={style.lableTextS}>Are there any specific questions or concerns for us to address within the RFO/RCA?</label>
										<textarea className={style.textareaFld} id="questions" onChange={inputChange} value={questions} maxLength="1000"></textarea>
									</div>
								</div>
								<div className={style.dividers}></div>
								<div className="text-center mt-5">
									<p className={isError ? "text-danger" : "text-success"}>{validationErr}</p>
								</div>
								<div className={"row my-5 rel"}>
									<div className={"col-12 text-center"}>
										<button className={` ico-button lhs light-button ${style.nextBtn} `} type="button" onClick={handleSubmit}>
											Submit
											<svg className="ico light-ico ico-arrow">
												<path d="M0,17H29L15,31l1,1L32,16,16,0,15,1,29,15H0Z"></path>
											</svg>
										</button>
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default RfoRcaRequestForm;