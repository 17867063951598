import {
	ACCOUNT_VERTICAL,
	ACNA,
	ACNAS,
	AE,
	AGENT_FLAG,
	API_ADDRESS_FLAG,
	API_EMAIL,
	API_ENABLED,
	BILLING_PROFILE_GLOBAL_ID,
	CAM,
	CAMP_ACCOUNTS,
	CAMP_ACCOUNTS_DOWNLOAD,
	COLOCATION,
	COLOCATION_DISCOUNT,
	COMMS_ACCOUNT,
	BAN,
	COMPANY,
	L_NAME,
	F_NAME,
	DARK_FIBER,
	DARK_FIBER_DISCOUNT,
	DIA_POP,
	DIA_POP_DISCOUNT,
	DIA_PREM,
	DIA_PREM_DISCOUNT,
	E_ACCESS,
	E_ACCESS_DISCOUNT,
	E_ACCESS_NNI,
	E_ACCESS_NNI_DISCOUNT,
	EIS,
	ELINE,
	ELINE_DISCOUNT,
	EMAIL,
	EXT,
	FIXED_WIRELESS_EACCESS,
	FIXED_WIRELESS_LAYER3,
	JURISDICTION,
	MANAGED_SPECTRUM,
	MANAGED_SPECTRUM_DISCOUNT,
	MPLS_AGG_NNI,
	MPLS_AGG_NNI_DISCOUNT,
	MPLS_AGG_TAIL,
	MPLS_AGG_TAIL_DISCOUNT,
	MPLS_INTERNET,
	MPLS_INTERNET_DISCOUNT,
	BROADBAND,
	BROADBAND_DISCOUNT,
	NCTC,
	NNIS,
	OFFNET_VENDOR_VISIBLE,
	ONNET_ONLY,
	PHONE,
	PRIVATE_LINE,
	PRIVATE_LINE_DISCOUNT,
	PROFILE_CHANGE,
	PROFILE_RESET,
	SE,
	SELECTED_PROFILE,
	UNIQUE_ID,
	NEW_PROFILE,
	PROFILE_USER_TABLE
} from "../actions/types/actionTypes"

const initialState = {
	profile: {
		selectedProfile: { "value": "", "label": "Please Select Customer" },
		company: "",
		fName: "",
		lName: "",
		email: "",
		phone: "",
		ext: "",
		jurisdiction: "",
		account_vertical: "",
		agent_flag: "",
		api_address_flag: 1,
		ae: "",
		cam: "",
		se: "",
		fixedWirelessLayer3: "",
		fixedWirelessEAccess: "",
		onNetOnly: "",
		nctc: "",
		eis: "",
		offnetVendorVisible: "",
		billingProfileGlobalID: "",
		commsAccount: "",
		ban: "",
		acna: "",
		selectedProfileTab: "Profile",
		unique_id: "",
		newProfile: "",
		userTable: []
	},
	products: {
		PrivateLine: "",
		MPLSAggTail: "",
		MPLSInternet: "",
		MPLSAggNNI: "",
		DIAPOP: "",
		DIAPrem: "",
		Colocation: "",
		ELine: "",
		EAccess: "",
		EAccessNNI: "",
		DarkFiber: "",
		ManagedSpectrum: "",
		Broadband: "",
		PrivateLineDiscount: "",
		MPLSAggTailDiscount: "",
		MPLSInternetDiscount: "",
		MPLSAggNNIDiscount: "",
		DIAPOPDiscount: "",
		DIAPremDiscount: "",
		ColocationDiscount: "",
		ELineDiscount: "",
		EAccessDiscount: "",
		EAccessNNIDiscount: "",
		DarkFiberDiscount: "",
		ManagedSpectrumDiscount: "",
		BroadbandDiscount: ""
	},
	accounts: {
		acnas: "",
		camp_accounts: "",
		camp_accounts_download: ""
	},
	nni: {
		nnis: ""
	},
	credentials: {
		api_enabled: "",
		api_email: ""
	}

};

const employeeProfileReducer = (state = initialState, action) => {
	switch (action.type) {
		case SELECTED_PROFILE:
			return {
				...state,
				profile: {
					...state.profile,
					selectedProfile: action.payload
				}
			}
		case COMPANY:
			return {
				...state,
				profile: {
					...state.profile,
					company: action.payload
				}
			}

		case F_NAME:
			return {
				...state,
				profile: {
					...state.profile,
					fName: action.payload
				}
			}
		case L_NAME:
			return {
				...state,
				profile: {
					...state.profile,
					lName: action.payload
				}
			}
		case EMAIL:
			return {
				...state,
				profile: {
					...state.profile,
					email: action.payload
				}
			}
		case PHONE:
			return {
				...state,
				profile: {
					...state.profile,
					phone: action.payload
				}
			}
		case EXT:
			return {
				...state,
				profile: {
					...state.profile,
					exy: action.payload
				}
			}
		case JURISDICTION:
			return {
				...state,
				profile: {
					...state.profile,
					jurisdiction: action.payload
				}
			}
		case ACCOUNT_VERTICAL:
			return {
				...state,
				profile: {
					...state.profile,
					account_vertical: action.payload
				}
			}
		case AGENT_FLAG:
			return {
				...state,
				profile: {
					...state.profile,
					agent_flag: action.payload
				}
			}
		case API_ADDRESS_FLAG:
			return {
				...state,
				profile: {
					...state.profile,
					api_address_flag: action.payload
				}
			}
		case AE:
			return {
				...state,
				profile: {
					...state.profile,
					ae: action.payload
				}
			}
		case CAM:
			return {
				...state,
				profile: {
					...state.profile,
					cam: action.payload
				}
			}
		case SE:
			return {
				...state,
				profile: {
					...state.profile,
					se: action.payload
				}
			}
		case FIXED_WIRELESS_LAYER3:
			return {
				...state,
				profile: {
					...state.profile,
					fixedWirelessLayer3: action.payload
				}
			}
		case FIXED_WIRELESS_EACCESS:
			return {
				...state,
				profile: {
					...state.profile,
					fixedWirelessEAccess: action.payload
				}
			}
		case ONNET_ONLY:
			return {
				...state,
				profile: {
					...state.profile,
					onNetOnly: action.payload
				}
			}
		case NCTC:
			return {
				...state,
				profile: {
					...state.profile,
					nctc: action.payload
				}
			}
		case EIS:
			return {
				...state,
				profile: {
					...state.profile,
					eis: action.payload
				}
			}
		case OFFNET_VENDOR_VISIBLE:
			return {
				...state,
				profile: {
					...state.profile,
					offnetVendorVisible: action.payload
				}
			}
		case BILLING_PROFILE_GLOBAL_ID:
			return {
				...state,
				profile: {
					...state.profile,
					billingProfileGlobalID: action.payload
				}
			}
		case COMMS_ACCOUNT:
			return {
				...state,
				profile: {
					...state.profile,
					commsAccount: action.payload
				}
			}
		case BAN:
			return {
				...state,
				profile: {
					...state.profile,
					ban: action.payload
				}
			}
		case ACNA:
			return {
				...state,
				profile: {
					...state.profile,
					acna: action.payload
				}
			}
		case PROFILE_CHANGE:
			return {
				...state,
				profile: {
					...state.profile,
					selectedProfileTab: action.payload
				}
			}
		case UNIQUE_ID:
			return {
				...state,
				profile: {
					...state.profile,
					unique_id: action.payload
				}
			}
		case NEW_PROFILE:
			return {
				...state,
				profile: {
					...state.profile,
					newProfile: action.payload
				}
			}
		case PROFILE_USER_TABLE:
			return {
				...state,
				profile: {
					...state.profile,
					userTable: action.payload
				}
			}
		case PROFILE_RESET:
			return initialState;

		case PRIVATE_LINE:
			return {
				...state,
				products: {
					...state.products,
					PrivateLine: action.payload
				}
			}

		case ACNAS:
			return {
				...state,
				accounts: {
					...state.accounts,
					acnas: action.payload
				}
			}

		case CAMP_ACCOUNTS:
			return {
				...state,
				accounts: {
					...state.accounts,
					camp_accounts: action.payload
				}
			}

		case CAMP_ACCOUNTS_DOWNLOAD:
			return {
				...state,
				accounts: {
					...state.accounts,
					camp_accounts_download: action.payload
				}
			}

		case MPLS_AGG_TAIL:
			return {
				...state,
				products: {
					...state.products,
					MPLSAggTail: action.payload
				}
			}
		case MPLS_INTERNET:
			return {
				...state,
				products: {
					...state.products,
					MPLSInternet: action.payload
				}
			}
		case MPLS_AGG_NNI:
			return {
				...state,
				products: {
					...state.products,
					MPLSAggNNI: action.payload
				}
			}
		case DIA_POP:
			return {
				...state,
				products: {
					...state.products,
					DIAPOP: action.payload
				}
			}
		case DIA_PREM:
			return {
				...state,
				products: {
					...state.products,
					DIAPrem: action.payload
				}
			}
		case COLOCATION:
			return {
				...state,
				products: {
					...state.products,
					Colocation: action.payload
				}
			}
		case ELINE:
			return {
				...state,
				products: {
					...state.products,
					ELine: action.payload
				}
			}
		case E_ACCESS:
			return {
				...state,
				products: {
					...state.products,
					EAccess: action.payload
				}
			}
		case E_ACCESS_NNI:
			return {
				...state,
				products: {
					...state.products,
					EAccessNNI: action.payload
				}
			}
		case DARK_FIBER:
			return {
				...state,
				products: {
					...state.products,
					DarkFiber: action.payload
				}
			}
		case MANAGED_SPECTRUM:
			return {
				...state,
				products: {
					...state.products,
					ManagedSpectrum: action.payload
				}
			}
		case BROADBAND:
			return {
				...state,
				products: {
					...state.products,
					Broadband: action.payload
				}
			}
		case PRIVATE_LINE_DISCOUNT:
			return {
				...state,
				products: {
					...state.products,
					PrivateLineDiscount: action.payload
				}
			}
		case MPLS_AGG_TAIL_DISCOUNT:
			return {
				...state,
				products: {
					...state.products,
					MPLSAggTailDiscount: action.payload
				}
			}
		case MPLS_INTERNET_DISCOUNT:
			return {
				...state,
				products: {
					...state.products,
					MPLSInternetDiscount: action.payload
				}
			}
		case MPLS_AGG_NNI_DISCOUNT:
			return {
				...state,
				products: {
					...state.products,
					MPLSAggNNIDiscount: action.payload
				}
			}
		case DIA_POP_DISCOUNT:
			return {
				...state,
				products: {
					...state.products,
					DIAPOPDiscount: action.payload
				}
			}
		case DIA_PREM_DISCOUNT:
			return {
				...state,
				products: {
					...state.products,
					DIAPremDiscount: action.payload
				}
			}
		case COLOCATION_DISCOUNT:
			return {
				...state,
				products: {
					...state.products,
					ColocationDiscount: action.payload
				}
			}
		case ELINE_DISCOUNT:
			return {
				...state,
				products: {
					...state.products,
					ELineDiscount: action.payload
				}
			}
		case E_ACCESS_DISCOUNT:
			return {
				...state,
				products: {
					...state.products,
					EAccessDiscount: action.payload
				}
			}
		case E_ACCESS_NNI_DISCOUNT:
			return {
				...state,
				products: {
					...state.products,
					EAccessNNIDiscount: action.payload
				}
			}
		case DARK_FIBER_DISCOUNT:
			return {
				...state,
				products: {
					...state.products,
					DarkFiberDiscount: action.payload
				}
			}
		case MANAGED_SPECTRUM_DISCOUNT:
			return {
				...state,
				products: {
					...state.products,
					ManagedSpectrumDiscount: action.payload
				}
			}
		case BROADBAND_DISCOUNT:
			return {
				...state,
				products: {
					...state.products,
					BroadbandDiscount: action.payload
				}
			}
		case NNIS:
			return {
				...state,
				nni: {
					...state.nni,
					nnis: action.payload
				}
			}
		case API_ENABLED:
			return {
				...state,
				credentials: {
					...state.credentials,
					api_enabled: action.payload
				}
			}
		case API_EMAIL:
			return {
				...state,
				credentials: {
					...state.credentials,
					api_email: action.payload
				}
			}
		default:
			return state

	}
}

export default employeeProfileReducer;
