import React, {useEffect} from 'react'
import {ConsoleLogger} from "../../logger/ConsoleLogger";


const Login = (props) => {
    const logger = new ConsoleLogger({ level: process.env.REACT_APP_LOGGER_LEVEL, });
    const loginAuthenticationURL = `${process.env.REACT_APP_LOGIN_URL}/login.php`;

	var myReferrer = document.referrer;
	logger.info("In Login.js referred from: " + myReferrer);
	var regex = /^https\:\/\/login(-dev|-test){0,1}\.windstream\.com\/$/;

	useEffect(() => {
		/*To check if an employee is logged in and force them to logout before customer login to prevent session issues*/
		if (localStorage.getItem("isEmployeeLoggedIn")) {
			logger.info("In Login.js redirect to /Employee/Home to make employee logout");
			window.location = "/Employee/Home";
			localStorage.setItem("dualLogin", 1);
		} else if (myReferrer.match(regex)) {
			logger.info("Inside else if in Login.js redirecting to /Employee/Home");
			window.location = "/Employee/Home";
		} else {
			localStorage.setItem("loginAttempt", "customer");
			logger.info("We've set loginAttempt to customer");
			/*if we don't get a success from the login.php we want to force an onclick before attempting again*/
			/*we want to avoid a redirect if an employeeLoginAttempt is set in local storage*/
			if (!localStorage.getItem("loginFailed") && !localStorage.getItem("employeeLoginAttempt")) {
				window.location.href = loginAuthenticationURL;
			}
		}

	}, []);

    function handleOnClick() {
        logger.info("handleOnClick");
        window.location.href = loginAuthenticationURL;
    }

    return (
        <>
        {localStorage.getItem("loginFailed") ?
            <div className="page login-page">
                <div className="page-title text-center my-5 rel z9">
                    <img alt="iconnect-logo" src="../../images/iconnect-logo-light-tag.png" />
                </div>
                <div className="login-form rel z9">
                    <form>
                        <div className="my-3 text-center">
                            <button type="button" className="login-btn" onClick={handleOnClick}>
                                <span>Sign In</span>
                            </button>
                        </div>
                    </form>
                </div>
            </div> : null
        }
        </>
    )
}

export default Login;
