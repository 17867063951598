import axios from "./axios/customAxios";

const getCircuitIds = async () => {
	const fiberMappingIdsURL = `${process.env.REACT_APP_API_URL}/getFiberMappingIds.php`;
	return await axios.get(fiberMappingIdsURL);
}

const getSingleCircuitKmz = async (commsId, designId) => {
	const singleKmzURL = `${process.env.REACT_APP_API_URL}/getFiberMappingToken.php?action=singleKmz&commsId=${commsId}&designId=${designId}`;
	return await axios.get(singleKmzURL);
}

const getMultiKmz = async (commsId, designId) => {
	const multiCircuitKmzURL = `${process.env.REACT_APP_API_URL}/getFiberMappingToken.php?action=multiKmz&commsId=${commsId}&designId=${JSON.stringify(designId)}`;
	return await axios.get(multiCircuitKmzURL);
}

const getAllCircuitsKmz = async (commsId) => {
	const allCircuitKmzURL = `${process.env.REACT_APP_API_URL}/getFiberMappingToken.php?action=allKmz&commsId=${commsId}`;
	return await axios.get(allCircuitKmzURL);
}

const FiberMappingHelper = {
	getCircuitIds,
	getSingleCircuitKmz,
	getMultiKmz,
	getAllCircuitsKmz
}

export default FiberMappingHelper;
