import { useState, useEffect } from 'react';
import { connect, useSelector } from "react-redux";
import { pdf } from '@react-pdf/renderer';
import PdfHelper from '../../services/PdfService';
import QuotePDF from './QuotePDF';
import WaveQuotePDF from './WaveQuotePDF';
import OrderPDF from './OrderPDF';
import CustomerServiceRecordPDF from './reports/CustomerServiceRecordPDF';
import { setOrderServiceForm } from '../../redux/actions/orderCreateActions';

const PdfComponent = (props) => {
	const isEmployee = localStorage.getItem('isEmployeeLoggedIn');
	const isCustomer = localStorage.getItem('isLoggedIn');
	const selectedInternalMpid = useSelector(state => state.internal.company?.value);
	const [pdfBlob, setPdfBlob] = useState(null);
	const [pdfUrl, setPdfUrl] = useState(''); // State to store the blob URL

	useEffect(() => {
		const fetchPdfBlob = async () => {
			try {
				const blob = await generatePdfBlob();
				setPdfBlob(blob);
			} catch (error) {
				console.error('Error generating PDF Blob:', error);
			}
		};

		fetchPdfBlob();
	}, []);

	useEffect(() => {
		if (pdfBlob) {
			const url = URL.createObjectURL(pdfBlob);
			setPdfUrl(url); // Store the blob URL in state
			if (props.action === "view") {
				window.open(url, '_blank'); // Have to allow pop-ups for this to work
			} else if (props.action === "download") {
				props.onPdfGenerated(url); // Call the callback with the generated URL
			} else {
				if (pdfBlob && (isCustomer || (isEmployee && (props.quoteData?.mpid === selectedInternalMpid || props.orderData?.mpid === selectedInternalMpid)))) {
					PdfHelper.sendPDFToServer(props.refId, pdfBlob, props.type, props.quoteData?.bus_name, props.quoteData?.product, props.orderData?.cust_pon)
						.then(() => {
							// Handle success (optional)
							console.log("PDF Blob sent to server for RefID: " + props.refId + " Type: " + props.type + " Component: " + props.comp);
							localStorage.setItem('getUploadedFiles', 'true');
							if (props.type === 'GENERATESO') {
								props.generateServiceOrderForm(true);
							}
							setPdfBlob(null); // Clear the blob after sending to server
						})
						.catch(error => {
							console.error("Error sending PDF to server:", error);
						});
				}
			}
		}

		// Cleanup function to revoke the blob URL
		return () => {
			if (pdfUrl) {
				URL.revokeObjectURL(pdfUrl);
				setPdfUrl(''); // Clear the URL state
				console.log("BLOB URL revoked: " + pdfUrl);
			}
		};
	}, [pdfBlob, props.action, props.refId]);

	const generatePdfBlob = async () => {
		switch (props.type) {
			case "WAVE":
				return await pdf(<WaveQuotePDF quoteData={props.quoteData} />).toBlob();
			case "ORDER":
			case "GENERATESO":
				return await pdf(<OrderPDF quoteData={props.quoteData} orderData={props.orderData} />).toBlob();
			case "STANDARD":
				return await pdf(<QuotePDF quoteData={props.quoteData} />).toBlob();
			case "CSR":
				return await pdf(<CustomerServiceRecordPDF {...props.reportData} />).toBlob();
			default:
				break;
		}
	};

	return null;
};

const mapDispatchToProps = {
	generateServiceOrderForm: setOrderServiceForm
};

export default connect(null, mapDispatchToProps)(PdfComponent);