/**
 * Created by e0173131 on 1/20/2021.
 */
import React, {useState, Fragment} from 'react';

const GoodieInfo = () => {
    const [isCollapsed, setIsCollapsed] = useState(true);

    const handleClick = () => {
        setIsCollapsed(!isCollapsed);
    }
    return (
        <Fragment>
            <span className="cf"></span>
            <div className="sub-hdr">
                <div className="expander" collapsed-id="template-info-content" onClick={handleClick}>
                    <svg className="ico-link light-ico ico-add">
                        <path
                            d="M24,48A24,24,0,1,1,48,24,24,24,0,0,1,24,48ZM24,1.89A22.1,22.1,0,1,0,46.08,24,22.09,22.09,0,0,0,24,1.89Zm1,32.69H23.05V25h-9.6V23.05h9.6v-9.6H25v9.6h9.61V25H25Z"></path>
                    </svg>
                </div>
                <div className="trim">&nbsp;</div>
                <div className="nib">
                    <div className="the-notch">
                        <div className="notch-fg">
                            <svg>
                                <path
                                    d="M35.81,26.28l-13,10v-5C-10.47,28.68-1,9.16,11.79,0c0,7.28,0,9.13,0,13.28s3.18,7.92,11,9v-5Z"></path>
                            </svg>
                        </div>
                    </div>
                </div>
                <h2>Template Architecture</h2>
            </div>
            {!isCollapsed ?
                <div className="sub-section">
                    <h3>Dependencies</h3>
                    <p><em>jQuery</em></p>
                    <p>
                        <em>overlayscrollbars.js</em> for custom scrollbars throughout the UI (including
                        Supertip)<br />
                        <span className="orange"><em>has Angular support (untested)</em></span><br />
                        <a className="link" href="https://github.com/KingSora/OverlayScrollbars"
                            target="_blank">github.com/KingSora/OverlayScrollbars</a>
                    </p>
                    <p>
                        <em>jquery.nice-select.min.js</em> for the custom UI select dropdown<br />
                        <a className="link" href="https://jqueryniceselect.hernansartorio.com/"
                            target="_blank">jqueryniceselect.hernansartorio.com</a>
                    </p>
                    <h3>Responsive Design</h3>
                    <p>Adjustments to the UI are made at various browser/viewport dimensions. The values chosen are
                        those that work best with this design.</p>
                    <p><em>Width</em> breakpoints: 1680, 1440, 1280, 1024</p>
                    <p><em>Height</em> breakpoints: 500</p>
                    <h3>Theme Capability</h3>
                    <button className="btn lhs light-button"
                        onClick="$('body').addClass('wild-theme'); return false;">
                        Wild Theme!
                    </button>
                    <button className="btn lhs light-button"
                        onClick="$('body').addClass('wild-theme superdark'); return false;">
                        Wild Theme! + Superdark
                    </button>
                    <br />
                    <p><em>*this page only</em></p>
                </div>
            : null}
        </Fragment>
    );
}

export default GoodieInfo;
