import { useCallback, useEffect, useMemo, useState } from "react";
import ViewportWarning from "../shared/ViewportWarning";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import { Link } from "react-router-dom";
import Loader from "../shared/Loader";
import ContactData from "../../services/ContactService";
import style from "./UtilityContacts.module.css";
import footerStyle from "../network/TroubleTickets.module.css";
import { ConsoleLogger } from "../../logger/ConsoleLogger";
import HTTPErrorHandler from "../errorPages/HTTPErrorHandler";
import { useSelector } from 'react-redux';
import CompanyDropdown from '../shared/CompanyDropdown';
import { Tooltip } from "react-tippy";
import XLSXExport from "../../utils/XLSXExport";
import { DebounceInput } from 'react-debounce-input';
import ReactTooltip from 'react-tooltip';
import StringUtils from '../../utils/StringUtils';
import _ from 'underscore';

const UtilityContacts = () => {
	// Initialize logger
	const logger = useMemo(() => new ConsoleLogger({level: process.env.REACT_APP_LOGGER_LEVEL}), []);
	// Initialize state variables
	const [hasError, setHasError] = useState(null);
	const [loader, setLoader] = useState(false);
	const [commsAccount, setCommsAccount] = useState("");
	const [commsAccountName, setCommsAccountName] = useState(false);
	const [accountDetails, setAccountDetails] = useState([]);
	const [copyContactData, setCopyContactData] = useState([]);
	const [showFooterFilters, setShowFooterFilters] = useState(false);
	const [contactCount, setContactCount] = useState(0);
	const [enableSearch, setEnableSearch] = useState(false);
	const [deleteContactModal, setDeleteContactModal] = useState(false);
	const [deleteContactId, setDeleteContactId] = useState("");
	const [deleteResult, setDeleteResult] = useState({});
	const [exportContactData, setExportContactData] = useState(false);
	const XLSXHeaders = ["Customer Name", "Phone", "Email", "Contact Type"];

	// Check if user is logged in as an employee
	const isEmployee = localStorage.getItem("isEmployeeLoggedIn");

	// Get selected internal MPID from Redux store
	const selectedInternalMpid = useSelector(state => state.internal.company?.value);

	const columns = [{
		dataField: 'name',
		text: 'Customer Name',
		sort: true,
		filter: textFilter({
			placeholder: 'Customer Name'
		})
	}, {
		dataField: 'phone',
		text: 'Phone',
		sort: true,
		filter: textFilter({
			placeholder: 'Phone'
		})
	}, {
		dataField: 'email',
		text: 'Email',
		sort: true,
		filter: textFilter({
			placeholder: 'Email'
		})
	}, {
		dataField: 'contact_Type__c',
		text: 'Contact Type',
		sort: true,
		filter: textFilter({
			placeholder: 'Contact Type'
		}),
		formatter: (cell, row) => contactTypeFormatter(cell, row)
	}, {
		dataField: 'edit',
		text: 'Edit Contact',
		headerAttrs: { id: 'edit' },
		formatter: (cell, row) => fileSymbolFormatterEdit(cell, row),
	}, {
		dataField: 'enroll',
		text: 'Maintenance Enrollment',
		headerAttrs: { id: 'enroll' },
		formatter: (cell, row) => fileSymbolFormatterEnrollMaintenance(cell, row),
	}, {
		dataField: 'ienroll',
		text: 'Invoice Enrollment',
		headerAttrs: { id: 'ienroll' },
		formatter: (cell, row) => fileSymbolFormatterEnrollInvoice(cell, row),
	}, {
		dataField: 'delete',
		text: 'Delete Contact',
		headerAttrs: { id: 'delete' },
		formatter: (cell, row) => deleteContactFormatter(cell, row),
	}];

	/**
	 * Returns a JSX element that displays a link to edit a contact and a bandwidth value.
	 * @param {Object} props - The component props.
	 * @param {Object} row - The row data.
	 * @returns {JSX.Element} - The JSX element.
	 */
	const fileSymbolFormatterEdit = (props, row) => {
		// Construct the URL for the link
		let URL = `/utilities/EditContacts/${commsAccount}/${row.globalAcctPartyID__c}`;

		// Return the JSX element
		return (
			<>
				{/* Link to the edit page */}
				<Link to={{ pathname: URL, state: { contactDetails: row, contactRoles: row?.contact_Type__c }}}>
					<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24">
						<path
							d="M1.438 16.875l5.688 5.689-7.126 1.436 1.438-7.125zm22.562-11.186l-15.46 15.46-5.688-5.689 15.459-15.46 5.689 5.689zm-4.839-2.017l-.849-.849-12.614 12.599.85.849 12.613-12.599z" />
					</svg>
				</Link>

			</>
		);
	}

	/**
	 * Generates a formatted contact type based on the given cell and row.
	 *
	 * @param {any} cell - The cell value.
	 * @param {object} row - The row object.
	 * @return {JSX.Element|string} The formatted contact type as a JSX element or a string.
	 */
	const contactTypeFormatter = (cell, row) => {
		let types = null;
		const contactTypeArray = row.contact_Type__c?.split(";");

		if (contactTypeArray) {
			types = contactTypeArray?.map((val) => <li>{val}</li>);
		} else {
			types = "No contact type set";
		}

		return (
			<div>
				<ul>
					{types}
				</ul>
			</div>
		);

	}

	/**
	 * Formats the file symbol for the maintenance enrollment in the utilities section.
	 * @param {Object} props - The props object.
	 * @param {Object} row - The row object.
	 * @returns {JSX.Element} - The formatted file symbol.
	 */
	const fileSymbolFormatterEnrollMaintenance = (props, row) => {
		// Construct the URL for the maintenance enrollment
		let URL = `/utilities/MaintenanceEnrollment/${commsAccount}/${row.globalAcctPartyID__c}`;

		// Return the formatted file symbol
		return (
			<Tooltip html={"Edit Data Network Maintenance Enrollment"} position="top-end">
				<Link to={{ pathname: URL, state: {contactDetails: row}}} target="_self">
					<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
						<path
							d="M15.137 3.945c-.644-.374-1.042-1.07-1.041-1.82v-.003c.001-1.172-.938-2.122-2.096-2.122s-2.097.95-2.097 2.122v.003c.001.751-.396 1.446-1.041 1.82-4.667 2.712-1.985 11.715-6.862 13.306v1.749h20v-1.749c-4.877-1.591-2.195-10.594-6.863-13.306zm-3.137-2.945c.552 0 1 .449 1 1 0 .552-.448 1-1 1s-1-.448-1-1c0-.551.448-1 1-1zm3 20c0 1.598-1.392 3-2.971 3s-3.029-1.402-3.029-3h6z" />
					</svg>
				</Link>
			</Tooltip>
		);
	}

	/**
	 * Returns a formatted file symbol for editing invoice enrollment.
	 * @param {Object} props - The props object.
	 * @param {Object} row - The row object.
	 * @returns {JSX.Element} - The formatted file symbol.
	 */
	const fileSymbolFormatterEnrollInvoice = (props, row) => {
		// Construct the URL for the invoice enrollment edit page
		let URL = `/utilities/InvoiceEnrollment/${commsAccount}/${row.globalAcctPartyID__c}`;

		// Return the formatted file symbol
		return (
			<Tooltip html={"Edit Invoice Enrollment"} position="top-end">
				<Link to={{ pathname: URL, state: { contactDetails: row}}} target="_self">
					<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
						<path
							d="M15.137 3.945c-.644-.374-1.042-1.07-1.041-1.82v-.003c.001-1.172-.938-2.122-2.096-2.122s-2.097.95-2.097 2.122v.003c.001.751-.396 1.446-1.041 1.82-4.667 2.712-1.985 11.715-6.862 13.306v1.749h20v-1.749c-4.877-1.591-2.195-10.594-6.863-13.306zm-3.137-2.945c.552 0 1 .449 1 1 0 .552-.448 1-1 1s-1-.448-1-1c0-.551.448-1 1-1zm3 20c0 1.598-1.392 3-2.971 3s-3.029-1.402-3.029-3h6z" />
					</svg>
				</Link>
			</Tooltip>
		);
	}

	/**
	 * Returns a formatted file symbol for editing invoice enrollment.
	 * @param {Object} props - The props object.
	 * @param {Object} row - The row object.
	 * @returns {JSX.Element} - The formatted delete symbol.
	 */
	const deleteContactFormatter = (props, row) => {
		return (
			<Tooltip html={"Delete Contact"} position="top-end">
				<button className="action ico-link light-ico tipped" data-tip={"deleteFile"} data-for='deleteFile' onClick={() => showDeleteModal(row.id)}>
					<svg className="ico-x"><path d="M18,17l-1,1L9,10,1,18,0,17,8,9,0,1,1,0,9,8l8-8,1,1L10,9Z"></path></svg>
				</button>
			</Tooltip>
		);
	}


	const searchToggle = () => {
		setEnableSearch(!enableSearch);
	}

	const showDeleteModal = (id) => {
		setDeleteContactModal(true);
		setDeleteContactId(id)
	}
	const deleteContactHandler = () => {
		setDeleteContactModal(false);
		setLoader(true);
		ContactData.deactivateContact(deleteContactId).then(response => {
			console.log(response.data);
			setLoader(false);
			if(response.data?.result === "SUCCESS"){
				setDeleteResult({"result": "SUCCESS", "message": response.data.message});
				setTimeout(function() {
					window.location.reload(false);
				}, 2000);
			} else {
				setDeleteResult({"result": "ERROR", "message": response.data.message});
			}
		})
	}

	/**
	 * Fetches comms account data and sets it to state variables.
	 */
	const getContactList = useCallback(() => {
		setLoader(true);
		// Fetch comms account data
		ContactData.getCommsAccount().then(resp => {
			setCommsAccount(resp.data.accountNo);
			setCommsAccountName(resp.data.customerName);
			logger.info(`Comms Account: ${resp.data.accountNo} ${resp.data.customerName}`);
			// Fetch account details data
			ContactData.getWinforceContacts(resp.data.accountNo).then(resp => {
				//Filter out any contact that have the inactive_Contact__c flag set to true
				let activeContacts = resp.data?.result.filter(contact => {
					return !contact.inactive_Contact__c
				})
				setAccountDetails(activeContacts);
				setCopyContactData(activeContacts);
				logger.info(`Account Details: ${activeContacts}`);
				setShowFooterFilters(true);
				setLoader(false);
				setContactCount(activeContacts.length);
				filterExportData(activeContacts);
			}).catch(error => {
				setHasError(error);
				setLoader(false);
			});
		}).catch(error => {
			setHasError(error);
			setLoader(false);
		});
	}, [logger]);

	const onChangeContact = (event) => {
		let inputValue = event.target.value.trim();
		let contact;
		if (inputValue) {
			contact = _.filter(copyContactData, (contactObj) => {
				return StringUtils.stringIncludes(contactObj.name, inputValue);
			});
			if (contact && contact.length > 0) {
				setAccountDetails(contact)
			} else {
				setAccountDetails([])
			}
		} else { // for empty search box
			setAccountDetails(copyContactData)
		}
	}

	const filterExportData = (data) => {
		const exportArr = data.map((item) => {
			return {
				'Customer Name': item.name,
				'Phone': item.phone,
				'Email': item.email,
				'Contact Type': item.contact_Type__c
			};
		});
		setExportContactData(exportArr);
	};

	const AddContact = () => {
		window.location = `/utilities/AddContacts/${commsAccount}`;
	}

	// Fetch data when component mounts or when selectedInternalMpid changes
	useEffect(() => {
		setCommsAccount("");
		setCommsAccountName(false);
		setAccountDetails([]);
		if (isEmployee) {
			if (selectedInternalMpid) {
				getContactList();
			}
		} else {
			getContactList();
		}
	}, [getContactList, isEmployee, selectedInternalMpid]);

	return (
		<>
			<ReactTooltip />
			{loader ? <Loader /> : null}
			{hasError && <HTTPErrorHandler error={hasError}></HTTPErrorHandler>}
			<div className="midsection qm-upload abs z1">
				<div className="rel">
					<ViewportWarning />
					<div className="wrap">
						<h1 className="page-title">{isEmployee ? "Employee Utility Contacts" : "Utility Contacts"}</h1>
						{isEmployee ? <CompanyDropdown /> : null}
						{deleteResult ? <h5 className={`${deleteResult.result === "SUCCESS" ? "text-success" : "text-danger"} text-center`}>{deleteResult.message}</h5> : null}
						{!hasError && (
							<>
								<div className="section w-100 TroubleTicket-page circuitInventoryPage networkPage">
									<div className="content bg-transparent px-0">
										<div className={` ${style.tableWrap} tableWrap`}>
											<div className={`rel table-style-wrap network-table pagination-table page-list my-3 troubleTicketTable ${style.utilityTable} ${enableSearch ? 'searchableEnableTable' : null} `}>
												<BootstrapTable id="troubleTicket"
													keyField='id'
													data={accountDetails}
													columns={columns}
													hover
													condensed
													bordered={false}
													pagination={paginationFactory()}
													filter={filterFactory()}
													sort={{ dataField: 'name', order: 'asc' }}
													className="mb-0"
												/>
												{accountDetails?.length === 0 && !loader ?
													<div className={`text-center ${style.noDataWrap} `}>
														<h1 className="m-0">No Contacts Found</h1>
													</div> : null
												}
												{accountDetails?.length === 0 && loader ?
													<div className={`text-center ${style.noDataWrap} `}>
														<h1 className="m-0">Loading Contacts</h1>
													</div> : null
												}
											</div>
										</div>
									</div>
								</div>
								{showFooterFilters ?
									<>
										{/* footer */}
										<div className={`footer-info-panel ${footerStyle.fixedFooter} `}>
											<div className={`d-flex align-items-center ${footerStyle.spaceBetween}`}>
												<div className={`${footerStyle.footerGrid}  ${footerStyle.pointer}`}>
													<span className={` ${footerStyle.labelText} ${footerStyle.filterTextLabel}`}>Contact</span>
													<span className={`rel ${footerStyle.circleWrap}`}>
														<span className={` ${footerStyle.labelText} ${footerStyle.posText} text-white`}>{contactCount}</span>
													</span>
												</div>
												<div className={`${footerStyle.footerGrid} ${footerStyle.searchableParams}`}>
													<div id="widget-priority" className={` toggle-switch toggle-switch-sm ${enableSearch ? 'on' : ''}`} onClick={searchToggle}>
														<div className="ts-bar">
															<div className="ts-left-edge">&nbsp;</div>
															<div className="ts-right-edge">&nbsp;</div>
															<div className="ts-switch">&nbsp;</div>
														</div>
													</div>
													<p className={`  ts-label ts-label-new ${footerStyle.menuLabelText} text-white`} >SEARCHABLE PARAMETERS</p>
												</div>
												<div className={`${footerStyle.footerGrid}`}>
													<button className="ico-button light-button" onClick={AddContact}>
														<span><strong>Add Contact</strong></span>
														<svg viewBox="0 0 34 34" className={`ico light-ico ico-arrow downloadArrow`}>
															<path className="cls-1" d="M1,18V16L17,31V1h2V31L35,16v2L20,33H35v2H1V33H16Z"
																transform="translate(-1 -1)">
															</path>
														</svg>
													</button>

												</div>

											</div>
											<div className={`d-flex align-items-center ${footerStyle.spaceBetween}`}>
												<div className={`${footerStyle.footerGrid} w-50 rel searchWrap d-flex`}>
													<DebounceInput type="search" className={`${footerStyle.searchFld} ${footerStyle.circuitInventorySearchFld}`} placeholder="Search Contacts" debounceTimeout={300} onKeyUp={event => onChangeContact(event)} />
													<img alt={""} className={` ${footerStyle.searchIcoInputField} ${footerStyle.searchIcoInputFieldCirInventory}`} src="../../images/svg/ico-search-fill.svg" />
												</div>
												<div className={`${footerStyle.footerGrid}`}>
													<button className="ico-button light-button" onClick={() => XLSXExport.exportToExcel(XLSXHeaders, exportContactData, `Dashboard Export_${Date.now()}`)}>
														<span><strong>Export</strong></span>
														<svg viewBox="0 0 34 34" className={`ico light-ico ico-arrow downloadArrow`}>
															<path className="cls-1" d="M1,18V16L17,31V1h2V31L35,16v2L20,33H35v2H1V33H16Z"
																transform="translate(-1 -1)">
															</path>
														</svg>
													</button>
												</div>
											</div>
										</div>
										{/* footer end */} </> : null
								}
								{deleteContactModal ?
									<div>
										<div className={`${style.overlay}`}></div>
										<div className="modal fade show d-block">
											<div className={` modal-dialog modal-dialog-centered modal-sm`}>
												<div className="modal-content">
													<div className="modal-body text-center">
														Are you sure you want to delete this contact?
													</div>
													<div className="modal-footer d-block text-center">
														<button type="button" className="btn-style btn-theme" data-dismiss="modal" onClick={() => deleteContactHandler()}>Yes</button>
														<button type="button" className="btn-style btn-cancel" data-dismiss="modal" onClick={() => setDeleteContactModal(false)}>No</button>
													</div>
												</div>
											</div>
										</div>
									</div> : null
								}
							</>)
						}
					</div>
				</div>
			</div>
		</>
	);
}

export default UtilityContacts;
