import {FETCH_OS_FAILURE, FETCH_OS_REQUEST, FETCH_OS_SUCCESS} from "../actions/types/orderSummaryType";

const initialState = {
    loading: false,
    orderSummaryData : [],
    error : ""
}

export const orderSummaryReducer = (state = initialState, action) => {
    switch(action.type) {
        case FETCH_OS_REQUEST: return {
            ...state,
            loading: true

        }
        case FETCH_OS_SUCCESS: return {
            loading : false,
            orderSummaryData : action.payload

        }
        case FETCH_OS_FAILURE: return {
            orderSummaryData : [],
            error: action.error
        }
        default: return state;
    }
}