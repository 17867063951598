import {
	ORDER_A_CUSTOMER_NAME,
	ORDER_A_DEMARCATION,
	ORDER_A_LOCATION_NOTES,
	ORDER_A_FIBER_TYPE,
	ORDER_A_HANDOFF,
	ORDER_A_LCON_EMAIL,
	ORDER_A_LCON_NAME,
	ORDER_A_LCON_PHONE,
	ORDER_ACNA,
	ORDER_ACTIVITY_REQUESTED,
	ORDER_ASN,
	ORDER_BGP,
	ORDER_BGP_COMM,
	ORDER_BGP_PREFIX,
	ORDER_BW,
	ORDER_CUSTOMER_ASSIGNED,
	ORDER_CIRCUIT_LIST_COUNT,
	ORDER_CIRCUIT_SEARCH,
	ORDER_CUSTOMER_CIRCUIT,
	ORDER_CUSTOMER_PON,
	ORDER_DATA_TECH_CONTACT,
	ORDER_DATA_TECH_EMAIL,
	ORDER_DATA_TECH_PHONE,
	ORDER_EVCS,
	ORDER_EXISTING_CIRCUIT,
	ORDER_MANUAL_CIRCUIT,
	ORDER_GAID,
	ORDER_EXPEDITE_REQUEST,
	ORDER_INITIAL_TERM,
	ORDER_JURISDICTIONAL_TRAFFIC,
	ORDER_LAN_REQUIREMENTS,
	ORDER_LAYER_2_PROTOCOL,
	ORDER_MOUNTING_EQUIPMENT,
	ORDER_MPLS_CIRCUIT,
	ORDER_MPLS_ROUTE,
	ORDER_MPLS_STATIC_IP,
	ORDER_MTU_SIZE,
	ORDER_NETWORK_NAME,
	ORDER_NEW_DATA_TECH_CONTACT,
	ORDER_NEW_PRIMARY_CONTACT,
	ORDER_NEW_VLAN,
	ORDER_NUMBER_OF_NNI,
	ORDER_NNI,
	ORDER_NNI_CIRCUIT_ID,
	ORDER_NNI_INTERCONNECTION_LOC,
	ORDER_NOTES,
	ORDER_NOTES_INTERNAL,
	ORDER_GENERAL_NOTES,
	ORDER_NUMBER_NNI_LOCATIONS,
	ORDER_OTHER_FIELD,
	ORDER_OWN_IP,
	ORDER_PREFIX,
	ORDER_PRIMARY_CONTACT,
	ORDER_PRIMARY_EMAIL,
	ORDER_PRIMARY_PHONE,
	ORDER_QOS,
	ORDER_QTY,
	ORDER_RAMP_UP,
	ORDER_REMOTE_ASN,
	ORDER_REMOVE_OVERRRIDE,
	ORDER_REMOVE_VRF_OVERRIDE,
	ORDER_REQUESTED_SERVICE,
	ORDER_SERVICE_FORM,
	ORDER_STATIC_IP,
	ORDER_TERMS_CONDITIONS,
	ORDER_UPLOAD_TYPE,
	ORDER_VLAN,
	ORDER_WAN_IP,
	ORDER_WAN_REQUIREMENTS,
	ORDER_WAN_SFP,
	ORDER_Z_CUSTOMER_NAME,
	ORDER_Z_DEMARCATION,
	ORDER_Z_LOCATION_NOTES,
	ORDER_Z_FIBER_TYPE,
	ORDER_Z_HANDOFF,
	ORDER_Z_LCON_EMAIL,
	ORDER_Z_LCON_NAME,
	ORDER_Z_LCON_PHONE,
	QUOTED_DATA,
	RESET_ACTION
} from "./types/actionTypes";

export const setQuotedData = (quotedData) => {
	return {
		type: QUOTED_DATA,
		payload: quotedData
	}
}

export const setOrderPrimaryContact = (contact) => {
	return {
		type: ORDER_PRIMARY_CONTACT,
		payload: contact
	}
}

export const setOrderNewPrimaryContact = (new_contact) => {
	return {
		type: ORDER_NEW_PRIMARY_CONTACT,
		payload: new_contact
	}
}

export const setOrderPrimaryPhone = (phone) => {
	return {
		type: ORDER_PRIMARY_PHONE,
		payload: phone
	}
}

export const setOrderPrimaryEmail = (email) => {
	return {
		type: ORDER_PRIMARY_EMAIL,
		payload: email
	}
}

export const setOrderTechContact = (contact) => {
	return {
		type: ORDER_DATA_TECH_CONTACT,
		payload: contact
	}
}

export const setOrderNewTechContact = (new_tech_contact) => {
	return {
		type: ORDER_NEW_DATA_TECH_CONTACT,
		payload: new_tech_contact
	}
}

export const setOrderTechPhone = (phone) => {
	return {
		type: ORDER_DATA_TECH_PHONE,
		payload: phone
	}
}

export const setOrderTechEmail = (email) => {
	return {
		type: ORDER_DATA_TECH_EMAIL,
		payload: email
	}
}

export const setOrderCustomerPon = (pon) => {
	return {
		type: ORDER_CUSTOMER_PON,
		payload: pon
	}
}

export const setOrderCustomerCircuit = (circuit) => {
	return {
		type: ORDER_CUSTOMER_CIRCUIT,
		payload: circuit
	}
}

export const setOrderRequestedServiceDate = (date) => {
	return {
		type: ORDER_REQUESTED_SERVICE,
		payload: date
	}
}

export const setOrderExpediteRequest = (expedite) => {
	return {
		type: ORDER_EXPEDITE_REQUEST,
		payload: expedite
	}
}

export const setOrderInitialTerm = (term) => {
	return {
		type: ORDER_INITIAL_TERM,
		payload: term
	}
}

export const setOrderACNA = (acna) => {
	return {
		type: ORDER_ACNA,
		payload: acna
	}
}

export const setOrderActivity = (activity) => {
	return {
		type: ORDER_ACTIVITY_REQUESTED,
		payload: activity
	}
}

export const setOrderQty = (qty) => {
	return {
		type: ORDER_QTY,
		payload: qty
	}
}

export const setOrderBW = (bw) => {
	return {
		type: ORDER_BW,
		payload: bw
	}
}

export const setOrderCircuitListCount = (payload) => {
	return {
		type: ORDER_CIRCUIT_LIST_COUNT,
		payload: payload
	}
}

export const setOrderCircuitSearch = (payload) => {
	return {
		type: ORDER_CIRCUIT_SEARCH,
		payload: payload
	}
}

export const setOrderExistingCircuit = (circuitID, index) => {
	return {
		type: ORDER_EXISTING_CIRCUIT,
		payload: circuitID,
		index: index
	}
}

export const setOrderManualCircuit = (manualCircuitID, index) => {
	return {
		type: ORDER_MANUAL_CIRCUIT,
		payload: manualCircuitID,
		index: index
	}
}

export const setOrderGaid = (name) => {
	return {
		type: ORDER_GAID,
		payload: name
	}
}

export const setOrderACustomerName = (name) => {
	return {
		type: ORDER_A_CUSTOMER_NAME,
		payload: name
	}
}
export const setOrderALconName = (a_lcon_name) => {
	return {
		type: ORDER_A_LCON_NAME,
		payload: a_lcon_name
	}
}
export const setOrderALconPhone = (a_lcon_phone) => {
	return {
		type: ORDER_A_LCON_PHONE,
		payload: a_lcon_phone
	}
}
export const setOrderALconEmail = (a_lcon_email) => {
	return {
		type: ORDER_A_LCON_EMAIL,
		payload: a_lcon_email
	}
}

export const setOrderAHandoff = (a_handoff) => {
	return {
		type: ORDER_A_HANDOFF,
		payload: a_handoff
	}
}

export const setOrderADemarcation = (a_dem_pt) => {
	return {
		type: ORDER_A_DEMARCATION,
		payload: a_dem_pt
	}
}

export const setOrderALocationNotes = (a_remarks) => {
	return {
		type: ORDER_A_LOCATION_NOTES,
		payload: a_remarks
	}
}

export const setOrderZCustomerName = (name) => {
	return {
		type: ORDER_Z_CUSTOMER_NAME,
		payload: name
	}
}

export const setOrderZLconName = (z_lcon_name) => {
	return {
		type: ORDER_Z_LCON_NAME,
		payload: z_lcon_name
	}
}
export const setOrderZLconPhone = (z_lcon_phone) => {
	return {
		type: ORDER_Z_LCON_PHONE,
		payload: z_lcon_phone
	}
}
export const setOrderZLconEmail = (z_lcon_email) => {
	return {
		type: ORDER_Z_LCON_EMAIL,
		payload: z_lcon_email
	}
}

export const setOrderZHandoff = (z_handoff) => {
	return {
		type: ORDER_Z_HANDOFF,
		payload: z_handoff
	}
}

export const setOrderZDemarcation = (z_dem_pt) => {
	return {
		type: ORDER_Z_DEMARCATION,
		payload: z_dem_pt
	}
}

export const setOrderZLocationNotes = (z_remarks) => {
	return {
		type: ORDER_Z_LOCATION_NOTES,
		payload: z_remarks
	}
}

export const setOrderWANRequirement = (wan) => {
	return {
		type: ORDER_WAN_REQUIREMENTS,
		payload: wan
	}
}

export const setOrderLANRequirement = (lan) => {
	return {
		type: ORDER_LAN_REQUIREMENTS,
		payload: lan
	}
}

export const setOrderStaticIp = (ip) => {
	return {
		type: ORDER_STATIC_IP,
		payload: ip
	}
}

export const setOrderUploadType = (uploadType) => {
	return {
		type: ORDER_UPLOAD_TYPE,
		payload: uploadType
	}
}

export const setOrderNotes = (notes) => {
	return {
		type: ORDER_NOTES,
		payload: notes
	}
}

export const setOrderNotesInternal = (notes) => {
	return {
		type: ORDER_NOTES_INTERNAL,
		payload: notes
	}
}
export const setGeneralNotes = (notes) => {
	return {
		type: ORDER_GENERAL_NOTES,
		payload: notes
	}
}

export const setTermsAndConditions = (termCondition) => {
	return {
		type: ORDER_TERMS_CONDITIONS,
		payload: termCondition
	}
}

export const setOrderRampUp = (rampup) => {
	return {
		type: ORDER_RAMP_UP,
		payload: rampup
	}
}

export const setOrderJurisdictionalTraffic = (jurisdiction) => {
	return {
		type: ORDER_JURISDICTIONAL_TRAFFIC,
		payload: jurisdiction
	}
}

export const setOrderAFiberType = (fiberAType) => {
	return {
		type: ORDER_A_FIBER_TYPE,
		payload: fiberAType
	}
}

export const setOrderZFiberType = (fiberZType) => {
	return {
		type: ORDER_Z_FIBER_TYPE,
		payload: fiberZType
	}
}

export const setOrderMountingEquipment = (mounting) => {
	return {
		type: ORDER_MOUNTING_EQUIPMENT,
		payload: mounting
	}
}

export const setOrderWanSFP = (wan_sfp) => {
	return {
		type: ORDER_WAN_SFP,
		payload: wan_sfp
	}
}

export const setOrderEVCS = (evcs) => {
	return {
		type: ORDER_EVCS,
		payload: evcs
	}
}

export const setOrderMTUSize = (mtu_size) => {
	return {
		type: ORDER_MTU_SIZE,
		payload: mtu_size
	}
}

export const setOrderVLAN = (vlan, index) => {
	return {
		type: ORDER_VLAN,
		payload: vlan,
		index: index
	}
}

export const setOrderCustomerAssigned = (customer) => {
	return {
		type: ORDER_CUSTOMER_ASSIGNED,
		payload: customer
	}
}

export const setOrderLayer2Protocol = (layer2) => {
	return {
		type: ORDER_LAYER_2_PROTOCOL,
		payload: layer2
	}
}

export const setOrderNNICircuitId = (nni_circuit) => {
	return {
		type: ORDER_NNI_CIRCUIT_ID,
		payload: nni_circuit
	}
}

export const setOrderOtherField = (other) => {
	return {
		type: ORDER_OTHER_FIELD,
		payload: other
	}
}

export const setOrderNNIInterconnection = (interNNi) => {
	return {
		type: ORDER_NNI_INTERCONNECTION_LOC,
		payload: interNNi
	}
}

export const setOrderNumberNNILocations = (nniNumber) => {
	return {
		type: ORDER_NUMBER_NNI_LOCATIONS,
		payload: nniNumber
	}
}

export const setOrderNewVlan = (newVlan, index) => {
	return {
		type: ORDER_NEW_VLAN,
		payload: newVlan,
		index: index
	}
}


export const setOrderNNI = (nni, index) => {
	return {
		type: ORDER_NNI,
		payload: nni,
		index: index
	}
}

export const setOrderMPLSCircuit = (mpls_circuit, index) => {
	return {
		type: ORDER_MPLS_CIRCUIT,
		payload: mpls_circuit,
		index: index
	}
}

export const setOrderRemoveVRFOverrride = (remove, index) => {
	return {
		type: ORDER_REMOVE_VRF_OVERRIDE,
		payload: remove,
		index: index
	}
}

export const setOrderBGP = (bgp, index) => {
	return {
		type: ORDER_BGP,
		payload: bgp,
		index: index
	}
}

export const setOrderASN = (asn, index) => {
	return {
		type: ORDER_ASN,
		payload: asn,
		index: index
	}
}

export const setOrderPrefix = (prefix, index) => {
	return {
		type: ORDER_PREFIX,
		payload: prefix,
		index: index
	}
}

export const setOrderNetworkName = (network, index) => {
	return {
		type: ORDER_NETWORK_NAME,
		payload: network,
		index: index
	}
}

export const setOrderMplsRoute = (mpls_route) => {
	return {
		type: ORDER_MPLS_ROUTE,
		payload: mpls_route
	}
}

export const setOrderOwnIP = (own_ip) => {
	return {
		type: ORDER_OWN_IP,
		payload: own_ip
	}
}

export const setOrderBGPPrefix = (bgp_prefix) => {
	return {
		type: ORDER_BGP_PREFIX,
		payload: bgp_prefix
	}
}

export const setOrderRemoveOverride = (override) => {
	return {
		type: ORDER_REMOVE_OVERRRIDE,
		payload: override
	}
}

export const setOrderBGPComm = (bgp_comm) => {
	return {
		type: ORDER_BGP_COMM,
		payload: bgp_comm
	}
}

export const setOrderRemoteAsn = (remote_asn) => {
	return {
		type: ORDER_REMOTE_ASN,
		payload: remote_asn
	}
}

export const setOrderQOS = (qos) => {
	return {
		type: ORDER_QOS,
		payload: qos
	}
}

export const setOrderMplsStaticIp = (static_ip) => {
	return {
		type: ORDER_MPLS_STATIC_IP,
		payload: static_ip
	}
}

export const setOrderWanIP = (wan_ip) => {
	return {
		type: ORDER_WAN_IP,
		payload: wan_ip
	}
}

export const setOrderServiceForm = (payload) => {
	return {
		type: ORDER_SERVICE_FORM,
		payload: payload
	}
}

export const setOrderNumberOfNNI = (payload) => {
	return {
		type: ORDER_NUMBER_OF_NNI,
		payload: payload
	}
}


export const resetOrderData = (reset) => {
	return {
		type: RESET_ACTION,
		payload: reset
	}
}
