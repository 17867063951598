/***********************************************
Order Saved Component
This component gets called when an order
is saved. It takes in a ref id and
finds the saved order in our database.
It then takes the data related to
the ref id and populates all the fields
in Order Create process so the user can
update the order, cancel, or submit it.
 ***********************************************/
import { useEffect, useState } from 'react';
import { ConsoleLogger } from '../../logger/ConsoleLogger';
import OrderHelper from '../../services/OrderService';
import { connect } from "react-redux";
import {
	setOrderACNA,
	setOrderActivity,
	setOrderACustomerName,
	setOrderADemarcation,
	setOrderALocationNotes,
	setOrderAFiberType,
	setOrderAHandoff,
	setOrderALconEmail,
	setOrderALconName,
	setOrderALconPhone,
	setOrderASN,
	setOrderBGP,
	setOrderBGPComm,
	setOrderBGPPrefix,
	setOrderBW,
	setOrderCustomerAssigned,
	setOrderCustomerCircuit,
	setOrderCustomerPon,
	setOrderEVCS,
	setOrderExistingCircuit,
	setOrderExpediteRequest,
	setOrderInitialTerm,
	setOrderJurisdictionalTraffic,
	setOrderLANRequirement,
	setOrderLayer2Protocol,
	setOrderMountingEquipment,
	setOrderMPLSCircuit,
	setOrderMplsRoute,
	setOrderMplsStaticIp,
	setOrderMTUSize,
	setOrderNetworkName,
	setOrderNewPrimaryContact,
	setOrderNewTechContact,
	setOrderNewVlan,
	setOrderNNI,
	setOrderNNICircuitId,
	setOrderNNIInterconnection,
	setOrderNotes,
	setOrderNotesInternal,
	setGeneralNotes,
	setOrderOtherField,
	setOrderOwnIP,
	setOrderPrefix,
	setOrderPrimaryContact,
	setOrderPrimaryEmail,
	setOrderPrimaryPhone,
	setOrderQOS,
	setOrderQty,
	setOrderRampUp,
	setOrderRemoteAsn,
	setOrderRemoveOverride,
	setOrderRemoveVRFOverrride,
	setOrderRequestedServiceDate,
	setOrderStaticIp,
	setOrderTechContact,
	setOrderTechEmail,
	setOrderTechPhone,
	setOrderVLAN,
	setOrderWanIP,
	setOrderWANRequirement,
	setOrderWanSFP,
	setOrderZCustomerName,
	setOrderZDemarcation,
	setOrderZLocationNotes,
	setOrderZFiberType,
	setOrderZHandoff,
	setOrderZLconEmail,
	setOrderZLconName,
	setOrderZLconPhone
} from '../../redux/actions/orderCreateActions';
import Loader from '../shared/Loader';

const OrderSaved = (props) => {
	const logger = new ConsoleLogger({ level: process.env.REACT_APP_LOGGER_LEVEL, });
	const [loader, setLoader] = useState(false);

	useEffect(() => {
		/*Function to get saved order data from the database using the ref id*/
		const getOrderData = (refId) => {
			setLoader(true);
			OrderHelper.getSavedOrderData(refId).then(resp => {
				logger.info(`-------------SAVED ORDER DATA-----------\n`);
				logger.info(encodeURIComponent(resp.data).replace(/%20/g, " "));
				setStoreOrderData(resp.data);
				setLoader(false);
			}).catch(error => {
				logger.error(`Error Getting Saved Order Data ${error}`);
				setLoader(false);
			})
		}
		getOrderData(props.refId);
	}, [])


	/*Mapping the database values to the redux store*/
	const setStoreOrderData = (orderData) => {
		logger.info("Dispatching all order create actions to fill in the order with the saved database values");
		//Customer Info Page
		props.updateOrderPrimaryContact(orderData.orig_contact_id?.length > 0 ? orderData.orig_contact_id : "");
		props.updateOrderPrimaryPhone(orderData.orig_phone?.length > 0 ? orderData.orig_phone : "");
		props.updateOrderPrimaryEmail(orderData.orig_email?.length > 0 ? orderData.orig_email : "");
		props.updateOrderTechContact(orderData.tech_contact_id?.length > 0 ? orderData.tech_contact_id : "");
		props.updateOrderTechPhone(orderData.tech_phone?.length > 0 ? orderData.tech_phone : "");
		props.updateOrderTechEmail(orderData.tech_email?.length > 0 ? orderData.tech_email : "");
		props.updateOrderCustomerPon(orderData.cust_pon?.length > 0 ? orderData.cust_pon : "");
		props.updateOrderCustomerCircuit(orderData.cust_circuit?.length > 0 ? orderData.cust_circuit : "");
		props.updateOrderACNA(orderData.acna?.length > 0 ? orderData.acna : "");
		props.updateOrderInitialTerm(orderData.term?.length > 0 ? orderData.term : "");
		props.updateOrderRequestService(orderData.serv_date?.length > 0 ? orderData.serv_date : "");
		//Service Info Page
		props.updateOrderActivity(orderData.cpt_activity?.length > 0 ? orderData.cpt_activity : "");
		props.updateOrderQty(orderData.quantity?.length > 0 ? orderData.quantity : "");
		props.updateOrderBW(orderData.bandwidth_id?.length > 0 ? orderData.bandwidth_id : "");
		props.updateOrderMountingEquipment(orderData.cpe_mount?.length > 0 ? orderData.cpe_mount : "");
		props.updateOrderWanSFP(orderData.cpe_wan_sfp?.length > 0 ? orderData.cpe_wan_sfp : "");
		//Site Infomation Page
		props.updateOrderALconName(orderData.a_lcon_name?.length > 0 ? orderData.a_lcon_name : "");
		props.updateOrderALconPhone(orderData.a_lcon_phone?.length > 0 ? orderData.a_lcon_phone : "");
		props.updateOrderALconEmail(orderData.a_lcon_email?.length > 0 ? orderData.a_lcon_email : "");
		props.updateOrderAHandoff(orderData.a_handoff_id?.length > 0 ? orderData.a_handoff_id : "");
		props.updateOrderADemarcation(orderData.a_dem_pt_id?.length > 0 ? orderData.a_dem_pt_id : "");
		props.updateOrderALocationNotes(orderData.a_remarks?.length > 0 ? orderData.a_remarks : "");
		props.updateOrderAFiberType(orderData.a_fiber_conn?.length > 0 ? orderData.a_fiber_conn : "");
		props.updateOrderZLconName(orderData.z_lcon_name?.length > 0 ? orderData.z_lcon_name : "");
		props.updateOrderZLconPhone(orderData.z_lcon_phone?.length > 0 ? orderData.z_lcon_phone : "");
		props.updateOrderZLconEmail(orderData.z_lcon_email?.length > 0 ? orderData.z_lcon_email : "");
		props.updateOrderZHandoff(orderData.z_handoff_id?.length > 0 ? orderData.z_handoff_id : "");
		props.updateOrderZDemarcation(orderData.z_dem_pt_id?.length > 0 ? orderData.z_dem_pt_id : "");
		props.updateOrderZLocationNotes(orderData.z_remarks?.length > 0 ? orderData.z_remarks : "");
		props.updateOrderZFiberType(orderData.z_fiber_conn?.length > 0 ? orderData.z_fiber_conn : "");
		//Product Detail Page
		props.updateOrderWANRequirement(orderData.wan_id?.length > 0 ? orderData.wan_id : "");
		props.updateOrderLANRequirement(orderData.lan_id?.length > 0 ? orderData.lan_id : "");
		props.updateOrderStaticIp(orderData.ip_key?.length > 0 ? orderData.ip_key : "");
		props.updateOrderRampUp(orderData.rampup?.length > 0 ? orderData.rampup : "");
		props.updateOrderJurisdictionalTraffic(orderData.jurisdiction?.length > 0 ? orderData.jurisdiction : "");
		props.updateOrderMTUSize(orderData.ce_mtu?.length > 0 ? orderData.ce_mtu : "");
		props.updateOrderVLAN(orderData.ce_vlan?.length > 0 ? orderData.ce_vlan : "");
		props.updateOrderEVCS(orderData.eline_evc?.length > 0 ? orderData.eline_evc : "");
		props.updateOrderLayer2Protocol(orderData.eline_l2cp?.length > 0 ? orderData.eline_l2cp : "");
		props.updateOrderNewVlan(orderData.vrf_new_vlan?.length > 0 ? orderData.vrf_new_vlan : "");
		props.updateOrderMPLSCircuit(orderData.vrf_circuit?.length > 0 ? orderData.vrf_circuit : "");
		props.updateOrderRemoveVRFOverride(orderData.vrf_override?.length > 0 ? orderData.vrf_override : "");
		props.updateOrderNNI(orderData.vrf_nni?.length > 0 ? orderData.vrf_nni : "");
		props.updateOrderBGP(orderData.vrf_bgp_comm?.length > 0 ? orderData.vrf_bgp_comm : "");
		props.updateOrderASN(orderData.vrf_asn?.length > 0 ? orderData.vrf_asn : "");
		props.updateOrderPrefix(orderData.vrf_prefix?.length > 0 ? orderData.vrf_prefix : "");
		props.updateOrderNetworkName(orderData.vrf_network_name?.length > 0 ? orderData.vrf_network_name : "");
		props.updateOrderMplsRoute(orderData.route?.length > 0 ? orderData.route : "");
		props.updateOrderOwnIP(orderData.own_ip_id?.length > 0 ? orderData.own_ip_id : "");
		props.updateOrderBGPPrefix(orderData.bgp_prefixes?.length > 0 ? orderData.bgp_prefixes : "");
		props.updateOrderBGPComm(orderData.bgp_comm?.length > 0 ? orderData.bgp_comm : "");
		props.updateOrderRemoveOverride(orderData.override?.length > 0 ? orderData.override : "");
		props.updateOrderRemoteAsn(orderData.asn?.length > 0 ? orderData.asn : "");
		props.updateOrderQOS(orderData.qos_id?.length > 0 ? orderData.qos_id : "");
		props.updateOrderMplsStaticIP(orderData.static_ips?.length > 0 ? orderData.static_ips : "");
	}

	return (
		<>
			{loader ? <Loader /> : null}
		</>
	)

}


const mapDispatchToProps = {
	updateOrderPrimaryContact: setOrderPrimaryContact,
	updateOrderNewPrimaryContact: setOrderNewPrimaryContact,
	updateOrderPrimaryPhone: setOrderPrimaryPhone,
	updateOrderPrimaryEmail: setOrderPrimaryEmail,
	updateOrderTechContact: setOrderTechContact,
	updateOrderNewTechContact: setOrderNewTechContact,
	updateOrderTechPhone: setOrderTechPhone,
	updateOrderTechEmail: setOrderTechEmail,
	updateOrderCustomerPon: setOrderCustomerPon,
	updateOrderCustomerCircuit: setOrderCustomerCircuit,
	updateOrderRequestService: setOrderRequestedServiceDate,
	updateOrderExpediteRequest: setOrderExpediteRequest,
	updateOrderInitialTerm: setOrderInitialTerm,
	updateOrderACNA: setOrderACNA,
	updateOrderActivity: setOrderActivity,
	updateOrderQty: setOrderQty,
	updateOrderBW: setOrderBW,
	updateOrderExistingCircuit: setOrderExistingCircuit,
	updateOrderMountingEquipment: setOrderMountingEquipment,
	updateOrderWanSFP: setOrderWanSFP,
	//Site Info Page
	updateOrderACustomerName: setOrderACustomerName,
	updateOrderALconName: setOrderALconName,
	updateOrderALconPhone: setOrderALconPhone,
	updateOrderALconEmail: setOrderALconEmail,
	updateOrderAHandoff: setOrderAHandoff,
	updateOrderADemarcation: setOrderADemarcation,
	updateOrderALocationNotes: setOrderALocationNotes,
	updateOrderAFiberType: setOrderAFiberType,
	updateOrderZCustomerName: setOrderZCustomerName,
	updateOrderZLconName: setOrderZLconName,
	updateOrderZLconPhone: setOrderZLconPhone,
	updateOrderZLconEmail: setOrderZLconEmail,
	updateOrderZHandoff: setOrderZHandoff,
	updateOrderZDemarcation: setOrderZDemarcation,
	updateOrderZLocationNotes: setOrderZLocationNotes,
	updateOrderZFiberType: setOrderZFiberType,
	updateOrderWANRequirement: setOrderWANRequirement,
	updateOrderLANRequirement: setOrderLANRequirement,
	updateOrderStaticIp: setOrderStaticIp,
	updateOrderRampUp: setOrderRampUp,
	updateOrderJurisdictionalTraffic: setOrderJurisdictionalTraffic,
	updateOrderMTUSize: setOrderMTUSize,
	updateOrderMTUOther: setOrderOtherField,
	updateOrderVLAN: setOrderVLAN,
	updateOrderCustomerAssigned: setOrderCustomerAssigned,
	updateOrderNNICircuitId: setOrderNNICircuitId,
	updateOrderEVCS: setOrderEVCS,
	updateOrderLayer2Protocol: setOrderLayer2Protocol,
	updateOrderNNIInterconnection: setOrderNNIInterconnection,
	updateOrderNewVlan: setOrderNewVlan,
	updateOrderNNI: setOrderNNI,
	updateOrderMPLSCircuit: setOrderMPLSCircuit,
	updateOrderRemoveVRFOverride: setOrderRemoveVRFOverrride,
	updateOrderBGP: setOrderBGP,
	updateOrderASN: setOrderASN,
	updateOrderPrefix: setOrderPrefix,
	updateOrderNetworkName: setOrderNetworkName,
	updateOrderMplsRoute: setOrderMplsRoute,
	updateOrderOwnIP: setOrderOwnIP,
	updateOrderBGPPrefix: setOrderBGPPrefix,
	updateOrderBGPComm: setOrderBGPComm,
	updateOrderRemoveOverride: setOrderRemoveOverride,
	updateOrderRemoteAsn: setOrderRemoteAsn,
	updateOrderQOS: setOrderQOS,
	updateOrderMplsStaticIP: setOrderMplsStaticIp,
	updateOrderWanIp: setOrderWanIP,
	updateOrderNotes: setOrderNotes,
	updateOrderNotesInternal: setOrderNotesInternal,
	updateGeneralNotes: setGeneralNotes

}

export default connect(null, mapDispatchToProps)(OrderSaved);
