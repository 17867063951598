import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {tcLocZ} from '../../redux';

let autoComplete;
let myRefs = [];
let currentIndex;

function handleScriptLoad(updateQuery, autoCompleteRef, props) {
    if (myRefs.indexOf(props.id) === -1) {
        myRefs.push(props.id);
    }
    autoComplete = new window.google.maps.places.Autocomplete(
        document.getElementById('locationAAddressZ'),
        {
            componentRestrictions: { country: ["us", "ca"] },
            fields: ["address_components", "geometry"],
            types: ["address"],
        }

    );

    autoComplete.setFields(["address_components", "formatted_address"]);
    autoComplete.addListener("place_changed", () =>
        handlePlaceSelect(updateQuery, props, myRefs[0])
    );
}

async function handlePlaceSelect(updateQuery, props, crRef) {
    const addressObject = autoComplete.getPlace();
    const query = addressObject.formatted_address;
    updateQuery(query);
    props.addressValues(addressObject, crRef);
}

const GoogleAutocompleteLocZ1 = (props, ref) => {
    // export function GoogleAutocomplete forwardRef((props, ref) {
    const [query, setQuery] = useState("");
    const autoCompleteRef = ref;

    useEffect(() => {
        handleScriptLoad(setQuery, myRefs[0], props);
    }, []);


    const addressOnChange = (e) => {
        props.LocZ(e.target.value);
    }

    return (
        <div className="search-location-input">
            <input
                id="locationAAddressZ"
                name={props.name}
                className={props.cName}
                placeholder={props.placeholder}
                ref={autoCompleteRef}
                // onChange={event => setQuery(event.target.value)}
                onChange={(e) => addressOnChange(e)}
                value={props.val}
            />
        </div>


    );
}

const forwardedRef = React.forwardRef(GoogleAutocompleteLocZ1);

const mapDispatchToProps = {
    LocZ: tcLocZ,
}

// export default GoogleAutocomplete;
// export default forwardedRef;
export default connect(null, mapDispatchToProps)(forwardedRef);

