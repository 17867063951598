import { useSelector } from 'react-redux';

const TabsEmployee = (props) => {
	const profileChangeTabFromStore = useSelector(state => state.employeeProfile?.profile?.selectedProfileTab);
	const selectedMpidFromStore = useSelector(state => state.internal.company);
	const agentFlag = useSelector(state => state.employeeProfile?.profile?.agent_flag);


	const navClick = (link) => {
		props.handleNav(link);
	}

	return (
		<div className="tab-nav cf">
			<a href className={`tab-opt ${profileChangeTabFromStore === 'Profile' ? 'active' : ''} `} onClick={() => { navClick('Profile') }}>
				<span>Profile</span>
			</a>
			{selectedMpidFromStore?.value && selectedMpidFromStore?.value !== 'new' ?
				<>
					<a href className={`tab-opt ${profileChangeTabFromStore === 'Accounts' ? 'active' : ''} ${!props.accountsLoaded ? 'disabled' : ''} `} onClick={() => { navClick('Accounts') }}>
						<span>
							Accounts
							{!props.accountsLoaded ? <div className='spinner-border spinner-border-sm ml-2' role='status' /> : null}
						</span>
					</a>
					<a href className={`tab-opt ${profileChangeTabFromStore === 'Products' ? 'active' : ''} ${!props.productsLoaded ? 'disabled' : ''} `} onClick={() => { navClick('Products') }}>
						<span>
							Products
							{!props.productsLoaded ? <div className='spinner-border spinner-border-sm ml-2' role='status' /> : null}
						</span>
					</a>
					<a href className={`tab-opt ${profileChangeTabFromStore === 'NNIs' ? 'active' : ''} ${!props.nnisLoaded ? 'disabled' : ''}`} onClick={() => { navClick('NNIs') }}>
						<span>
							NNIs
							{!props.nnisLoaded ? <div className='spinner-border spinner-border-sm ml-2' role='status' /> : null}
						</span>
					</a>
					<a href className={`tab-opt ${profileChangeTabFromStore === 'API' ? 'active' : ''} ${!props.apiLoaded ? 'disabled' : ''}`} onClick={() => { navClick('API') }}>
						<span>
							API
							{!props.apiLoaded ? <div className='spinner-border spinner-border-sm ml-2' role='status' /> : null}
						</span>
					</a>
					<a href className={`tab-opt ${profileChangeTabFromStore === 'ADD_USER' ? 'active' : ''} `} onClick={() => { navClick('ADD_USER') }}>
						<span>Add User</span>
					</a>
					{/*Only want to display this tab when employe click the user id link on the profile page*/}
					{profileChangeTabFromStore === "EDIT_USER" ?
						<a href className={`tab-opt ${profileChangeTabFromStore === 'EDIT_USER' ? 'active' : ''} `}>
							<span>Edit User</span>
						</a> : null
					}
					{agentFlag === '1' ?
						<a href className={`tab-opt ${profileChangeTabFromStore === 'AGENT' ? 'active' : ''} `}>
							<span>Agent</span>
						</a> : null
					}
					{agentFlag === '2' ?
						<a href className={`tab-opt ${profileChangeTabFromStore === 'AGENT_CUSTOMER' ? 'active' : ''} `}>
							<span>Agent Customer</span>
						</a> : null
					}
				</>
				: null}
		</div>
	);
}

export default TabsEmployee;
