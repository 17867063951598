
/**
 * Created by Boby on 10/1/2021.
 */

// CSS Imports
import style from "../quote/QuoteSummary.module.css";

/**
 * JS Imports
 *
 */
// React Core Libraries
import React from 'react';

// Local JS Files Imports
import ViewportWarning from "../shared/ViewportWarning";
import TabsQuote from "./includes/TabsQuote";
import Loader from "../shared/Loader";

// 3rd party npm Libraries
import ReactTooltip from 'react-tooltip';

const CircuitDetails = () => {
    // const [loader, setLoader] = useState(false);
    const loader = false;
    return (
        <>
            <ReactTooltip />
            {loader ? <Loader /> : null}

            <div id="circuit-details-midsection" className="midsection qm-summary abs z1">
                <div className="rel">
                    <ViewportWarning />
                    <div id="circuit-details-wrap" className="wrap">
                        <div id="circuit-details-header-links" className="header-links">
                            <span>Circuit Health</span>
                        </div>
                        <h1 className="page-title">
                            <span>CA/KJUS/795678/ /WXN /</span>
                        </h1>
                        <div id="circuit-details-table-secion" className="section">
                            {/* <TabsQuoteManager/> */}
                            <TabsQuote />
                            <div className={`content ${style.contentQueryPage}`}>
                                <div className="sub-hdr">
                                    <div className="nib">
                                        <div className="the-notch">
                                            <div className="notch-fg">
                                                <svg>
                                                    <path
                                                        d="M35.81,26.28l-13,10v-5C-10.47,28.68-1,9.16,11.79,0c0,7.28,0,9.13,0,13.28s3.18,7.92,11,9v-5Z"></path>
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                    <h2 className={style.sectionTitle}>Circuit Details</h2>
                                    <div id="circuit-details-row" className="row circuit-details-row">
                                        <div className="col-md-4">
                                            <table id='circuit-details-table' className='table circuit-details-table'>
                                                <tr>
                                                    <td>
                                                        <svg className="ico">
                                                            <path
                                                                d="M23,35V34H21V30h2V27h8V26H27V25h6v2h2v8ZM18,20c-3,1-8,2-8,6,0,3,5.07,5,7,5h2v2H17c-2.59,0-9-2-9-7,0-6,7-7,10-8s10-2.09,10-5.25C28,10.08,23.93,9,22,9H17V7h5c2.59,0,8,1.82,8,6C30,18.41,21,19,18,20Zm-5-9H1V3H3V1H9V2H5V3h8V6h2v4H13Z" />
                                                        </svg>
                                                    </td>
                                                    <td>Circuit ID</td>
                                                    <td>CA/KJUS/795678/ /WXN /</td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <svg className="ico">
                                                            <path
                                                                d="M1.5,36V3h3V0h14V3h3v7h13V36Zm19-32h-3V1H5.5V4h-3V34h7V29h4v5h7Zm13,7h-12V34h12Zm-8,6h-2V15h2Zm0,5h-2V20h2Zm0,5h-2V25h2Zm0,5h-2V30h2Zm3-15h-2V15h2Zm0,5h-2V20h2Zm0,5h-2V25h2Zm0,5h-2V30h2Zm3-15h-2V15h2Zm0,5h-2V20h2Zm0,5h-2V25h2Zm0,5h-2V30h2Zm-18-9h1v3h-1Zm4-11h-2V9h2Zm0,7h-2V16h2Zm0,7h-2V23h2Zm0,6h-2V29h2Zm-4-16h1v3h-1Zm0-7h1v3h-1Zm-5,14h1v3h-1Zm0-7h1v3h-1Zm0-7h1v3h-1Zm-3,20h2v3h-2Zm0-6h2v3h-2Zm0-7h2v3h-2Zm0-7h2v3h-2Z" />
                                                        </svg>
                                                    </td>
                                                    <td>Account Name</td>
                                                    <td>Akamai</td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <svg className="ico">
                                                            <path
                                                                d="M26,20V31l-1,1-1-1V20H2V31L1,32,0,31V20.34L2,18H24ZM7,13V3A7.5,7.5,0,0,1,19,3V13a8.88,8.88,0,0,1-6,2A9,9,0,0,1,7,13Zm2-1a10.28,10.28,0,0,0,4,1,9.9,9.9,0,0,0,4-1V4a4.67,4.67,0,0,0-4-2A4.37,4.37,0,0,0,9,4Z" />
                                                        </svg>
                                                    </td>
                                                    <td>Account Number</td>
                                                    <td>186SPAKASPZZZ</td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <svg className="ico">
                                                            <path
                                                                d="M36,2.5v31H0V2.5Zm-2,2H2v27H34ZM9,30.5H4v-2H9Zm-1-9H4V6.5H32v15H28v4H24v4H12v-4H8Zm19,7h5v2H27Z" />
                                                        </svg>
                                                    </td>
                                                    <td>Bandwidth</td>
                                                    <td>10G</td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <svg className="ico">
                                                            <path
                                                                d="M30.5,24.5v-13h5v13Zm3-11h-1v9h1Zm-9-2h5v13h-5Zm2,11h1v-9h-1Zm-8-11h5v13h-5Zm2,11h1v-9h-1Zm-8-11h5v13h-5Zm2,11h1v-9h-1Zm-8-11h5v13h-5Zm2,11h1v-9h-1Zm-8-11h5v13H.5Zm2,11h1v-9h-1Z" />
                                                        </svg>
                                                    </td>
                                                    <td>PON</td>
                                                    <td>AK-100-LACABSMA</td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <svg className="ico">
                                                            <path
                                                                d="M30.5,24.5v-13h5v13Zm3-11h-1v9h1Zm-9-2h5v13h-5Zm2,11h1v-9h-1Zm-8-11h5v13h-5Zm2,11h1v-9h-1Zm-8-11h5v13h-5Zm2,11h1v-9h-1Zm-8-11h5v13h-5Zm2,11h1v-9h-1Zm-8-11h5v13H.5Zm2,11h1v-9h-1Z" />
                                                        </svg>
                                                    </td>
                                                    <td># REF</td>
                                                    <td>B7U8KN43</td>
                                                </tr>
                                            </table>
                                        </div>
                                        <div className="col-md-4">
                                            <div id="circuit-details-row-location">
                                                <span>Location A</span>
                                                <div>600 West 7th St., Los Angeles, CA 12345</div>
                                            </div>
                                            <table className='table circuit-details-table'>
                                                <tr>
                                                    <td>
                                                        <svg className="ico">
                                                            <path
                                                                d="M30.5,24.5v-13h5v13Zm3-11h-1v9h1Zm-9-2h5v13h-5Zm2,11h1v-9h-1Zm-8-11h5v13h-5Zm2,11h1v-9h-1Zm-8-11h5v13h-5Zm2,11h1v-9h-1Zm-8-11h5v13h-5Zm2,11h1v-9h-1Zm-8-11h5v13H.5Zm2,11h1v-9h-1Z" />
                                                        </svg>
                                                    </td>
                                                    <td>CLLI</td>
                                                    <td>LSANCA02</td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <svg className="ico ico-page">
                                                            <path d="M22,32V12H20V30H2V2H13l6,6H14V5H12v5H22V9L13,0H0V32Z" />
                                                        </svg>
                                                    </td>
                                                    <td>CFA</td>
                                                    <td>RR11.02.03 panel 1</td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <svg className="ico ico-page">
                                                            <path d="M22,32V12H20V30H2V2H13l6,6H14V5H12v5H22V9L13,0H0V32Z" />
                                                        </svg>
                                                    </td>
                                                    <td>CFA</td>
                                                    <td>fibers 39 & 40</td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <svg className="ico">
                                                            <path
                                                                d="M1.5,36V3h3V0h14V3h3v7h13V36Zm19-32h-3V1H5.5V4h-3V34h7V29h4v5h7Zm13,7h-12V34h12Zm-8,6h-2V15h2Zm0,5h-2V20h2Zm0,5h-2V25h2Zm0,5h-2V30h2Zm3-15h-2V15h2Zm0,5h-2V20h2Zm0,5h-2V25h2Zm0,5h-2V30h2Zm3-15h-2V15h2Zm0,5h-2V20h2Zm0,5h-2V25h2Zm0,5h-2V30h2Zm-18-9h1v3h-1Zm4-11h-2V9h2Zm0,7h-2V16h2Zm0,7h-2V23h2Zm0,6h-2V29h2Zm-4-16h1v3h-1Zm0-7h1v3h-1Zm-5,14h1v3h-1Zm0-7h1v3h-1Zm0-7h1v3h-1Zm-3,20h2v3h-2Zm0-6h2v3h-2Zm0-7h2v3h-2Zm0-7h2v3h-2Z" />
                                                        </svg>
                                                    </td>
                                                    <td>Vendor</td>
                                                    <td>Halsey</td>
                                                </tr>
                                            </table>
                                        </div>
                                        <div className="col-md-4">
                                            <div id="circuit-details-row-location">
                                                <span>Location Z</span>
                                                <div>500 East St., Boston, MA 54321</div>
                                            </div>
                                            <table className='table circuit-details-table'>
                                                <tr>
                                                    <td>
                                                        <svg className="ico">
                                                            <path
                                                                d="M30.5,24.5v-13h5v13Zm3-11h-1v9h1Zm-9-2h5v13h-5Zm2,11h1v-9h-1Zm-8-11h5v13h-5Zm2,11h1v-9h-1Zm-8-11h5v13h-5Zm2,11h1v-9h-1Zm-8-11h5v13h-5Zm2,11h1v-9h-1Zm-8-11h5v13H.5Zm2,11h1v-9h-1Z" />
                                                        </svg>
                                                    </td>
                                                    <td>CLLI</td>
                                                    <td>BSTPMALL</td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <svg className="ico ico-page">
                                                            <path d="M22,32V12H20V30H2V2H13l6,6H14V5H12v5H22V9L13,0H0V32Z" />
                                                        </svg>
                                                    </td>
                                                    <td>CFA</td>
                                                    <td>4th floor MMR 04.106.01.502</td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <svg className="ico ico-page">
                                                            <path d="M22,32V12H20V30H2V2H13l6,6H14V5H12v5H22V9L13,0H0V32Z" />
                                                        </svg>
                                                    </td>
                                                    <td>CFA</td>
                                                    <td>fibers 11 & 12</td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <svg className="ico">
                                                            <path
                                                                d="M1.5,36V3h3V0h14V3h3v7h13V36Zm19-32h-3V1H5.5V4h-3V34h7V29h4v5h7Zm13,7h-12V34h12Zm-8,6h-2V15h2Zm0,5h-2V20h2Zm0,5h-2V25h2Zm0,5h-2V30h2Zm3-15h-2V15h2Zm0,5h-2V20h2Zm0,5h-2V25h2Zm0,5h-2V30h2Zm3-15h-2V15h2Zm0,5h-2V20h2Zm0,5h-2V25h2Zm0,5h-2V30h2Zm-18-9h1v3h-1Zm4-11h-2V9h2Zm0,7h-2V16h2Zm0,7h-2V23h2Zm0,6h-2V29h2Zm-4-16h1v3h-1Zm0-7h1v3h-1Zm-5,14h1v3h-1Zm0-7h1v3h-1Zm0-7h1v3h-1Zm-3,20h2v3h-2Zm0-6h2v3h-2Zm0-7h2v3h-2Zm0-7h2v3h-2Z" />
                                                        </svg>
                                                    </td>
                                                    <td>Vendor</td>
                                                    <td>Markley</td>
                                                </tr>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default CircuitDetails;
