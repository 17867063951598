import { useCallback, useEffect, useState, useMemo } from "react";
import { useSelector } from "react-redux";
import ViewportWarning from "../shared/ViewportWarning";
import TabsQuote from "./includes/TabsQuote";
import Loader from "../shared/Loader";
import style from "./BulkQuoteView.module.css";
import { ConsoleLogger } from "../../logger/ConsoleLogger";
import QuoteHelper from "../../services/QuoteService";
import { useParams } from "react-router-dom";
import { ExportXLSX } from "../../utils/ExportXLSX";
import HTTPErrorHandler from "../errorPages/HTTPErrorHandler";
import CountDownTimer from "../components/CountDownTimer";
import CompanyDropdown from "../shared/CompanyDropdown";
import BroadbandBulkView from "./bulk/BroadbandBulkView";
import BulkTableView from "./bulk/BulkTableView";

const BulkQuoteView = () => {
	const logger = useMemo(() => new ConsoleLogger({ level: process.env.REACT_APP_LOGGER_LEVEL, }), []);
	const [hasError, setHasError] = useState(null);
	const [loader, setLoader] = useState(true);
	const [hasQuotes, setHasQuotes] = useState(false);
	const [bulkQuoteDetail, setBulkQuoteDetail] = useState({});
	const [bulkQuoteList, setBulkQuoteList] = useState([]);
	const [checked, setChecked] = useState([]);
	const [orderableError, setOrderableError] = useState("");
	const [bulkDetailTryCount, setBulkQuoteDetailTryCount] = useState(0);
	const isEmployee = localStorage.getItem('isEmployeeLoggedIn');
	const isCustomer = localStorage.getItem("isLoggedIn");
	const selectedAccessValues = useSelector(state => state.userReducer.user.webaccess);
	const { bulkId } = useParams();

	//Fetches bulk quote summary and pricing details
	const fetchBulkQuoteDetail = useCallback((bulkId, section) => {
		logger.info(`fetchBulkQuoteDetail: ${bulkId}, ${section}`);
		setLoader(true);
		QuoteHelper.getBulkQuoteDetail(bulkId, section).then(response => {
			logger.info(JSON.stringify(response.data));
			if(section === "summary") {
				setBulkQuoteDetail(response.data);
			} else {
				setBulkQuoteList(response.data);
				setBulkQuoteDetailTryCount(prevCount => prevCount+1);
				if(response.data.length > 0) {
					setHasQuotes(true);
				}
			}
			setLoader(false);
		}).catch(error => {
			logger.error(`getBulkQuoteDetail Error: ${error}`);
		})
	},[logger])

	//Handles making a quote orderable must select at least one quote
	const handleMakeOrderable = () => {
		logger.info(`handleMakeOrderable: ${checked}`);
		setOrderableError("");
		logger.info(checked);
		if (checked.length === 0) {
			setOrderableError("** You must select at least one quote to make orderable.");
		} else {
			setLoader(true);
			QuoteHelper.bulkMakeOrderable(bulkId, checked).then((response) => {
				if (response.data) {
					logger.info(response.data);
				} else {
					logger.info("ERROR");
				}
				if ((response.data).includes("Maximum execution time of 30 seconds exceeded")) {
					setOrderableError("** Maximum execution time of 30 seconds exceeded.");
				}
				handleRefresh(0);
				setLoader(false);
			}).catch(error => {
				logger.error(`handleMakeOrderable Error: ${error}`);
				setHasError(error);
				setOrderableError(error);
				setLoader(false);
			});
		}
	}

	//Recall quote detail section to see if a quote has finished processing
	const handleRefresh = (countDown) => {
		if (countDown < 2) {
			setChecked([]);
			setBulkQuoteList([]);
			setHasQuotes(false);
			setLoader(true);
			fetchBulkQuoteDetail(bulkId, "detail");
		}
	}

	useEffect(() => {
		fetchBulkQuoteDetail(bulkId, "summary");
		fetchBulkQuoteDetail(bulkId, "detail");
	}, [bulkId, fetchBulkQuoteDetail]);

	return (
		<>
			{hasError && <HTTPErrorHandler error={hasError}></HTTPErrorHandler>}
			{loader ? <Loader /> : ""}
			<div className="midsection bulk-quote-view qm-manage abs z1">
				<div className="rel">
					<ViewportWarning />
					<div className="wrap">
						<h1 className="page-title">{isEmployee ? "Employee Bulk Quote Request Detail" : "Bulk Quote Request Detail"}</h1>\
						{isEmployee ? <CompanyDropdown area={"BulkView"} mpid={bulkQuoteDetail.mpid} company={bulkQuoteDetail.company} /> : null}
						<div className="section">
							<TabsQuote />
							{!hasError && (<div className={style.dFlex}>
								<div className={`content rel ${style.quoteBulkDetailsPage}`}>

									<div className={`sub-hdr ${style.subHdrAlter} `}>
										<div className="nib">
											<div className="the-notch">
												<div className="notch-fg">
													<svg>
														<path
															d="M35.81,26.28l-13,10v-5C-10.47,28.68-1,9.16,11.79,0c0,7.28,0,9.13,0,13.28s3.18,7.92,11,9v-5Z"></path>
													</svg>
												</div>
											</div>
										</div>
										<h2 className={` ${style.sectionTitle} m-0 `}>Project Overview</h2>
										<div className={"float-right"}>
											<button className="ico-button light-button">
												<span><strong>Save Report to File</strong></span>
												<ExportXLSX csvData={bulkQuoteList} fileName={`quoteBulkReport_${Date.now()}`} />
											</button>
										</div>
									</div>
									<div className="sub-section">
										<div className={`row ${style.rowGrp}`}>
											{bulkQuoteDetail?.project?.length > 0 ?
												<div className="col-md-4">
													<ul className={` ${style.productList}`}>
														<li>Project</li>
														<li className="text-break">{bulkQuoteDetail.project}</li>
													</ul>
												</div> : null
											}

											{bulkQuoteDetail?.product?.length > 0 ?
												<div className="col-md-4">
													<ul className={` ${style.productList}`}>
														<li>Product</li>
														<li>{bulkQuoteDetail.product}</li>
													</ul>
												</div> : null
											}
										</div>

										<div className={`row ${style.rowGrp}`}>
											{bulkQuoteDetail?.terms?.length > 0 ?
												<div className="col-md-4">
													<ul className={` ${style.productList}`}>
														<li>Terms</li>
														<li>{bulkQuoteDetail.terms}</li>
													</ul>
												</div> : null
											}

											{bulkQuoteDetail?.contact?.length > 0 ?
												<div className="col-md-4">
													<ul className={` ${style.productList}`}>
														<li>Contact</li>
														<li>{bulkQuoteDetail.contact}</li>
													</ul>
												</div> : null
											}
										</div>

										<div className={`row ${style.rowGrp}`}>
											{bulkQuoteDetail?.status?.length > 0 ?
												<div className="col-md-4">
													<ul className={` ${style.productList}`}>
														<li>Status</li>
														<li>{bulkQuoteDetail.status}</li>
													</ul>
												</div> : null
											}

											{bulkQuoteDetail?.submitted?.length > 0 ?
												<div className="col-md-4">
													<ul className={` ${style.productList}`}>
														<li>Submitted</li>
														<li>{bulkQuoteDetail.submitted}</li>
													</ul>
												</div> : null
											}
										</div>

										<div className={`row ${style.rowGrp}`}>
											{bulkQuoteDetail?.total?.length > 0 ?
												<div className="col-md-4">
													<ul className={` ${style.productList}`}>
														<li>Lines Received</li>
														<li>{bulkQuoteDetail.total}</li>
													</ul>
												</div> : null
											}

											{bulkQuoteDetail?.completed?.length > 0 ?
												<div className="col-md-4">
													<ul className={` ${style.productList}`}>
														<li>Completed</li>
														<li>{bulkQuoteDetail.completed}</li>
													</ul>
												</div> : null
											}
										</div>

										<div className={`row ${style.rowGrp}`}>
											{bulkQuoteDetail?.priced?.length > 0 ?
												<div className="col-md-4">
													<ul className={` ${style.productList}`}>
														<li>Lines Quoted</li>
														<li>{bulkQuoteDetail.priced}</li>
													</ul>
												</div> : null
											}

											{bulkQuoteDetail?.errored?.length > 0 ?
												<div className="col-md-4">
													<ul className={` ${style.productList}`}>
														<li>Lines Not Quoted</li>
														<li>{bulkQuoteDetail.errored}</li>
													</ul>
												</div> : null
											}
										</div>

										<div className={`row ${style.rowGrp}`}>
											{bulkQuoteDetail?.input_file?.length > 0 ?
												<div className="col-md-4">
													<ul className={` ${style.productList}`}>
														<li>Input File</li>
														<li>
															<a className="text-break" href={`${process.env.REACT_APP_API_URL}/quoteBulkSaveFile.php?id=${encodeURIComponent(bulkId)}&tok=${encodeURIComponent(bulkQuoteDetail.input_location)}`}>{bulkQuoteDetail.input_file}</a>
														</li>
													</ul>
												</div> : null
											}

											{bulkQuoteDetail?.output_file?.length > 0 ?
												<div className="col-md-4">
													<ul className={` ${style.productList}`}>
														<li>Output File</li>
														<li>
															<a className="text-break" href={`${process.env.REACT_APP_API_URL}/quoteBulkSaveFile.php?id=${encodeURIComponent(bulkId)}&tok=${encodeURIComponent(bulkQuoteDetail.output_location)}`}>{bulkQuoteDetail.output_file}</a>
														</li>
													</ul>
												</div> : null
											}
										</div>
										{bulkQuoteList.length === 0 && !loader && bulkDetailTryCount > 0 ?
											<div className={"bulkCounter"}>
												<p className={`ts-label ts-label-new text-secondary mb-0`}>Quotes are processing... Refresh in</p>
												<CountDownTimer changeFunction={handleRefresh} page={"bulk_quote_view"} seconds={30} />
											</div> : null
										}
									</div>
									{hasQuotes && bulkQuoteDetail?.product_id ?
										<>
											{Number(bulkQuoteDetail?.product_id) === 23 ? <BroadbandBulkView bulkQuoteList={bulkQuoteList} handleCheckboxes={(checkboxes)=> setChecked(checkboxes)}/> : null}
											{Number(bulkQuoteDetail?.product_id) !== 23 ? <BulkTableView bulkQuoteList={bulkQuoteList} handleCheckboxes={(checkboxes)=> setChecked(checkboxes)}/> : null}
											{isEmployee || (isCustomer && Number(selectedAccessValues?.TRANS_QUOTE) > 1) ?
												<div className={`row ${style.rowGrp}`}>
													<div className={`qm-summary mt-2 ml-4`}>
														<button className="ico-button lhs light-button" onClick={handleMakeOrderable}>
															<svg className="ico light-ico ico-arrow"><path d="M0,17H29L15,31l1,1L32,16,16,0,15,1,29,15H0Z"></path></svg>
															Make Orderable
														</button>
													</div>
												</div> : null
											}
										</>	: null
									}
									<div className={"orderableError"}>
										{orderableError}
									</div>
								</div>
							</div>
							)}
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default BulkQuoteView;
