import {
    MY_NETWORK_LIST_MPID,
    NETWORK_LIST,
    TROUBLE_TICKET_MPID,
    TROUBLE_TICKET_DAY_FILTER,
    TROUBLE_TICKETS,
    CIRCUIT_HEALTH,
    CIRCUIT_HEALTH_MPID
} from "../actions/types/actionTypes";


const initialState = {
    myNetwork : {
        networkListMpid: "",
        networkListData: [],
    },
    troubleTickets: {
        troubleTicketMpid: "",
        troubleTicketDayFilter: "30",
        troubleTicketData: []
    },
    circuitHealth: {
        circuitHealthMpid: "",
        circuitHealthData: []
    }
};



const networkIntelligenceReducer = (state = initialState, action) => {
    switch (action.type) {
        case MY_NETWORK_LIST_MPID:
            return {
                ...state,
                myNetwork: {
                    ...state.myNetwork,
                    networkListMpid: action.payload
                }
            }
        case NETWORK_LIST:
            return {
                ...state,
                myNetwork: {
                    ...state.myNetwork,
                    networkListData: action.payload
                }
            }
        case TROUBLE_TICKET_MPID:
            return {
                ...state,
                troubleTickets: {
                    ...state.troubleTickets,
                    troubleTicketMpid: action.payload
                }
            }
        case TROUBLE_TICKET_DAY_FILTER:
            return {
                ...state,
                troubleTickets: {
                    ...state.troubleTickets,
                    troubleTicketDayFilter: action.payload
                }
            }
        case TROUBLE_TICKETS:
            return {
                ...state,
                troubleTickets: {
                    ...state.troubleTickets,
                    troubleTicketData: action.payload
                }
            }
        case CIRCUIT_HEALTH_MPID:
            return {
                ...state,
                circuitHealth: {
                    ...state.circuitHealth,
                    circuitHealthMpid: action.payload
                }
            }
        case CIRCUIT_HEALTH:
            return {
                ...state,
                circuitHealth: {
                    ...state.circuitHealth,
                    circuitHealthData: action.payload
                }
            }
        default:
            return state
    }
}

export default networkIntelligenceReducer;
