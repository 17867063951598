import axios from "./axios/customAxios";

export const sendPDFToServer = async (ref_id, pdfBlob, type, company, product, pon) => {
	const formData = new FormData();
	const timestamp = Date.now();
	const date = new Date(timestamp);
	const month = (date.getMonth() + 1).toString().padStart(2, '0');
	const day = date.getDate().toString().padStart(2, '0');
	const year = date.getFullYear();
	const formattedDate = `${month}-${day}-${year}`;
	let fileName = '';
	let uploadType = '';
	let sendEmail = '';
	if (type === 'ORDER' || type === 'GENERATESO') {
		fileName = `${pon}.pdf`;
		uploadType = 2; // SO
	} else {
		if (company) {
			fileName = `${company}_${product}_RefId_${ref_id}_${formattedDate}.pdf`;
		} else {
			fileName = `${product}_RefId_${ref_id}_${formattedDate}.pdf`;
		}
		uploadType = 1; // Quote
		sendEmail = 1;
	}
	fileName = fileName.replace(/ /g, '_').replace(/[^a-zA-Z0-9_\-().]/g, '');
	formData.append('userfile', pdfBlob, fileName);

	return axios({
		method: "post",
		url: `${process.env.REACT_APP_API_URL}/uploadFile.php?ref_id=${ref_id}&upload_type=${uploadType}&flag=0&sendEmail=${sendEmail}`,
		data: formData,
		headers: { "Content-Type": "multipart/form-data" },
	});
}

const PdfHelper = {
	sendPDFToServer
}

export default PdfHelper;