import style from "./OrderSearch.module.css";
import { useEffect, useState, useMemo } from 'react';
import { ConsoleLogger } from "../../logger/ConsoleLogger";
import BroadbandHelper from "../../services/BroadbandServices";
import Loader from '../shared/Loader';

const BroadbandTimelineComponent = (props) => {
    const logger = useMemo(() => new ConsoleLogger({ level: process.env.REACT_APP_LOGGER_LEVEL, }), []);
    const [dcrisData, setDcrisData] = useState([]);
    const [dcrisErrorMsg, setDcrisErrorMsg] = useState("");
    const [loader, setLoader] = useState(false);

    useEffect(() => {
		setLoader(true);
		BroadbandHelper.getDcrisOrderStatus(props.refId).then((response) => {
            logger.info(JSON.stringify(response.data));
            if(response.data?.result === "SUCCESS"){
                setDcrisData(response?.data);
            } else {
                setDcrisErrorMsg(response?.data?.error);
            }
            setLoader(false);
        }).catch(error => {
            setLoader(false);
            logger.error(error);
        })
	}, [logger, props.refId]);


    return (
        <>
            {loader ? <Loader /> : null}
            <div className={`sub-hdr alt1 ${style.subHdrAlter} `}>
                <div className="nib">
                    <div className="the-notch">
                        <div className="notch-fg">
                            <svg>
                                <path d="M35.81,26.28l-13,10v-5C-10.47,28.68-1,9.16,11.79,0c0,7.28,0,9.13,0,13.28s3.18,7.92,11,9v-5Z"></path>
                            </svg>
                        </div>
                    </div>
                </div>
                <h2 className={` ${style.sectionTitle} m-0 `}>Order TimeLine</h2>
            </div>
            {Object.entries(dcrisData)?.length > 0 && dcrisData?.result === "SUCCESS" ?
                <div className={"o_milestones three-steps"}>
                    <div className={` step active`}>
                        <div>1</div>
                        <span>Order Date</span>
                    </div>
                    <div className={`gap ${dcrisData?.schedule_install ? 'active' : ''}`}>&nbsp;</div>
                    <div className={` step ${dcrisData?.schedule_install ? 'active' : ''}`}>
                        <div>2</div>
                        <span>Schedule Install Date Recieved</span>
                    </div>

                    <div className={` gap ${dcrisData?.status_id === "B0" ? 'active' : ''}`}>&nbsp;</div>
                    <div className={` step ${dcrisData?.status_id === "B0" ? 'active' : ''}`}>
                        <div>3</div>
                        <span>Order Complete</span>
                    </div>
                </div> : null
            }
            {dcrisData?.result === "ERROR" ? <span className="text-danger">{dcrisErrorMsg}</span> : null}
            <div className={`row ${style.rowGrp}`}>
                <div className={`col-sm-6 col-md-4  ${style.colGrp}`}>
                    <ul className={` ${style.productList}`}>
                        <li>Reference ID</li>
                        <li>{props?.refId}</li>
                    </ul>
                </div>
                <div className={`col-sm-6 col-md-4  ${style.colGrp}`}>
                    <ul className={` ${style.productList}`}>
                        <li>Order Date</li>
                        <li>{dcrisData?.opened_date}</li>
                    </ul>
                </div>
                <div className={`col-sm-6 col-md-4  ${style.colGrp}`}>
                    <ul className={` ${style.productList}`}>
                        <li>DCRIS Order Number</li>
                        <li>{dcrisData?.dcris_order}</li>
                    </ul>
				</div>
				<div className={`col-sm-6 col-md-4  ${style.colGrp}`}>
					<ul className={` ${style.productList}`}>
						<li>Schedule Install Date</li>
						<li>{dcrisData?.schedule_install}</li>
					</ul>
				</div>
                <div className={`col-sm-6 col-md-4  ${style.colGrp}`}>
                    <ul className={` ${style.productList}`}>
                        <li>Order Status</li>
                        <li>{dcrisData?.order_status}</li>
                    </ul>
                </div>
                <div className={`col-sm-6 col-md-4  ${style.colGrp}`}>
                    <ul className={` ${style.productList}`}>
						<li>Service Address TN</li>
                        <li>{dcrisData?.tn}</li>
                    </ul>
                </div>
                <div className={`col-12 ${style.colGrp}`}>
                    <span className="text-danger">{dcrisData?.note}</span>
                </div>
            </div>
        </>
    )


}

 export default BroadbandTimelineComponent;
