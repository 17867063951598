import React from 'react';
import ViewportWarning from "../shared/ViewportWarning";
//  import TabsQuote from "./includes/TabsQuote";
import {Link} from 'react-router-dom';

const StaffWebAccess = () => {
    return (
        <div className="midsection qm-upload abs z1">
            <div className="rel">
                <ViewportWarning />
                <div className="wrap">
                    <h1 className="page-title">Staff web access</h1>
                    <div className="section">
                        {/* <TabsQuote /> */}
                        <div className="content">Add Staff web access contents here
                            <Link to="/utilities/UtilityContacts" className="back_btn_link">
                                <p>Back to Utility Contacts</p>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default StaffWebAccess;
