import { useEffect, useState } from 'react';
import style from './OrderCreate.module.css';
const OrderPricingInfo = (props) => {
	const [selectedTerm, setSelectedTerm] = useState(0);
	const [selectedOption, setSelectedOption] = useState(0);
	const [pricingData, setPricingData] = useState({});
	const [totalMrc, setTotalMrc] = useState(0);
	const [totalNrc, setTotalNrc] = useState(0);
	const [averageMrc, setAverageMrc] = useState(0);
	const [averageNrc, setAverageNrc] = useState(0);
	const [postMrc, setPostMrc] = useState(0);
	const [postNrc, setPostNrc] = useState(0);
	const [varMonths, setVarMonths] = useState('');
	const [postMonths, setPostMonths] = useState('');

	useEffect(() => {
		setTotalMrc(0);
		setTotalNrc(0);
		setAverageMrc(0);
		setAverageNrc(0);
		setSelectedTerm(0);
		setSelectedOption(0);
		setPostMrc(0);
		setPostNrc(0);
		setVarMonths('');
		setPostMonths('');
		let term_months = props.term;
		let term_option = props.term / 12;
		let bw_string = props.bandwidth;
		let band_option = 0;

		//Parsing Bandwidth Option
		if (bw_string.indexOf('-') > 0) {
			band_option = bw_string.substring(0, bw_string.indexOf('-', 0));
			bw_string = bw_string.substring(bw_string.indexOf('-', 0) + 1);
			if (bw_string.indexOf('-', 0) > 0) {
				band_option = bw_string.substring(bw_string.indexOf('-', 0), 0);
			} else {
				band_option = bw_string;
			}
		}
		setSelectedTerm(term_option);
		setSelectedOption(band_option);
		setPricingData(props.lineItems[band_option][term_option]);
		setTotalMrc(Number(((props.lineItems[band_option][term_option].total_mrc).substring(1)).replace(/,/g, '')));
		setTotalNrc(Number(((props.lineItems[band_option][term_option].total_nrc).substring(1)).replace(/,/g, '')));
		if (props.lineItems[band_option]?.icb_price_type === '2' || props.lineItems[band_option]?.icb_price_type === '4') {
			if (props.lineItems[band_option][term_option]?.post_mrc || props.lineItems[band_option][term_option]?.post_nrc) {
				setPostMrc(Number(((props.lineItems[band_option][term_option].post_mrc).substring(1)).replace(/,/g, '')));
				setPostNrc(Number(((props.lineItems[band_option][term_option].post_nrc).substring(1)).replace(/,/g, '')));
				setVarMonths(props.lineItems[band_option]?.varMonths);
				if (props.termCnt > 1 && selectedTerm > 0) {
					let newPostMonths = "(months " + props.lineItems[band_option]?.nextMonth + " - " + term_months + ")";
					setPostMonths(newPostMonths);
				} else {
					setPostMonths(props.lineItems[band_option]?.postMonths);
				}
			}
		}
		if (props.lineItems[band_option][term_option]?.average_mrc || props.lineItems[band_option][term_option]?.average_nrc) {
			setAverageMrc(Number(((props.lineItems[band_option][term_option].average_mrc).substring(1)).replace(/,/g, '')));
			setAverageNrc(Number(((props.lineItems[band_option][term_option].average_nrc).substring(1)).replace(/,/g, '')));
		}
	}, [props.bandwidth, props.lineItems, props.qty, props.term, props.termCnt, selectedOption, selectedTerm])




	return (
		<>
			<div className={style.section1}>
				<div className={`sub-hdr alt2 ${style.subHdrAlter} `}>
					<div className="nib">
						<div className="the-notch">
							<div className="notch-fg">
								<svg>
									<path
										d="M35.81,26.28l-13,10v-5C-10.47,28.68-1,9.16,11.79,0c0,7.28,0,9.13,0,13.28s3.18,7.92,11,9v-5Z"></path>
								</svg>
							</div>
						</div>
					</div>
					<h2 className={` ${style.sectionTitle} m-0 `}>{props.sectionName}</h2>

				</div>

				{selectedOption !== 0 && selectedTerm !== 0 ?
					<div className="sub-section">
						<div className={` row ${style.rowGrp}`}>
							<div className='col-12'>
								<h6>Option {selectedOption} : {props.lineItems[selectedOption].bw} - {props.lineItems[selectedOption].icb_price_desc}</h6>
								<table className={`table table-bordered ${style.leaseTable} text-center`}>
									<thead>
										<tr>
											<th>Item(s)</th>
											<th>Quantity</th>
											<th>MRC</th>
											<th>NRC</th>
											<th>Total MRC <sup>1</sup></th>
											<th>Total NRC <sup>2</sup></th>
										</tr>
									</thead>
									<tbody>

										{Object.keys(pricingData)?.map((line) => {
											let total_mrc = 0;
											let total_nrc = 0;
											if (pricingData[line]?.price_mrc || pricingData[line]?.price_nrc) {

												if (pricingData[line]?.price_mrc === "NO BID") {
													total_mrc = 0;
												} else {
													total_mrc = (((pricingData[line].price_mrc).substring(1))).replace(/,/g, '') * props.qty;
												}

												if (pricingData[line]?.price_nrc === "NO BID") {
													total_nrc = 0;
												} else {
													total_nrc = (((pricingData[line].price_nrc).substring(1))).replace(/,/g, '') * props.qty;
												}

												return (
													<>
														<tr key={line}>
															<td>{pricingData[line]?.line_desc}</td>
															<td>{props.qty}</td>
															<td>{pricingData[line]?.price_mrc === "NO BID" ? "$0.00" : pricingData[line]?.price_mrc}</td>
															<td>{pricingData[line]?.price_nrc === "NO BID" ? "$0.00" : pricingData[line]?.price_nrc}</td>
															<td>{total_mrc?.toLocaleString('en-US', { style: 'currency', currency: 'USD', })}</td>
															<td>{total_nrc?.toLocaleString('en-US', { style: 'currency', currency: 'USD', })}</td>
														</tr>
													</>
												)
											}
										})}

										<tr>
											<td>Overall Totals {varMonths}</td>
											<td></td>
											<td></td>
											<td></td>
											<td>{totalMrc?.toLocaleString('en-US', { style: 'currency', currency: 'USD', })}</td>
											<td>{totalNrc?.toLocaleString('en-US', { style: 'currency', currency: 'USD', })}</td>
										</tr>

										{postMrc || postNrc ?
											<tr>
												<td>Overall Total {postMonths} </td>
												<td></td>
												<td></td>
												<td></td>
												<td>{postMrc?.toLocaleString('en-US', { style: 'currency', currency: 'USD', })}</td>
												<td>{postNrc?.toLocaleString('en-US', { style: 'currency', currency: 'USD', })}</td>
											</tr>
											: null}


										{averageMrc || averageNrc ?
											<tr>
												<td>Average MRC<span style={{ verticalAlign: "super" }}>3</span></td>
												<td></td>
												<td></td>
												<td></td>
												<td>{averageMrc?.toLocaleString('en-US', { style: 'currency', currency: 'USD', })}</td>
												<td>{averageNrc?.toLocaleString('en-US', { style: 'currency', currency: 'USD', })}</td>
											</tr> : null
										}
									</tbody>
								</table>

								<div className='w-100'>
									<p className={style.f16}>
										1) Or any other term used in the Customer's Agreement to convey the monthly fees applicable to Service including, but not limited to,
										the use of "Monthly Lease Fee"
									</p>
									<p className={style.f16}>
										2) Or any other term used in the Customer's Agreement to convey the monthly fees applicable to Service
									</p>
									{props.lineItems[selectedOption]?.icb_price_type === "2" || props.lineItems[selectedOption]?.icb_price_type === "3" || props.lineItems[selectedOption]?.icb_price_type === "4" ?
										<p className={style.f16}>
											3) Average MRC is the cumulative MRC over the length of the service contract. Average is calculated using monthly recurring charges and any one time supplemental charges for the length of the term.  Upon expiration of the term, the billing will revert to Average Equivalent MRC as defined, unless otherwise noted.
										</p> : null
									}
								</div>
							</div>
						</div>
					</div> : null
				}
			</div>
			<div className={style.dividers}></div>
		</>
	)

}

export default OrderPricingInfo
