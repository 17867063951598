import {
	AGENCY,
	AGENT,
	AGENT_ADDRESS,
	AGENT_CITY,
	AGENT_EMAIL,
	AGENT_FEE,
	AGENT_NAME,
	AGENT_PHONE,
	AGENT_STATE,
	AGENT_ZIP,
	AUTO_NOBID,
	BILLING_SYSTEM,
	COMPANY_MENU,
	COMPANY_MPID,
	COMPANY_MPID_RESET,
	DISABLE_CUST_EMAIL,
	END_TERM_DATE,
	ENTERPRISE_REP,
	ENTERPRISE_REP_OBJ,
	EXISTING_QUOTE_CIRCUIT_ID,
	LEGACY_NETWORK,
	MANUAL_QUOTE_CIRCUIT_ID,
	MARKUP,
	NON_COMMISSIONABLE,
	OFFNET,
	PREV_QUOTE_NUM,
	PRICING_OVERRIDE,
	RENEWAL_QUOTE,
	RESET_INTERNAL_QUOTE,
	WHOLESALE_OPPORTUNITY
} from "./types/actionTypes"

export const setCompanyMenu = (payload) => {
    return {
        type: COMPANY_MENU,
        payload: payload
    }
}
export const setCompanyMpid = (mpid) => {
    return {
        type: COMPANY_MPID,
        payload: mpid
    }
}

export const resetCompanyMpid = (payload) => {
    return {
        type:  COMPANY_MPID_RESET,
        payload: payload
    }
}

export const setAgency = (payload) => {
    return {
        type: AGENCY,
        payload: payload
    }
}

export const setAutoNoBid = (payload) => {
    return {
        type: AUTO_NOBID,
        payload: payload
    }
}

export const setDisableCustEmail = (payload) => {
    return {
        type: DISABLE_CUST_EMAIL,
        payload: payload
    }
}

export const setRenewalQuote = (payload) => {
    return {
        type: RENEWAL_QUOTE,
        payload: payload
    }
}

export const setPrevQuoteNum = (payload) => {
    return {
        type: PREV_QUOTE_NUM,
        payload: payload
    }
}

export const setExistingQuoteCircuitID = (payload) => {
    return {
        type: EXISTING_QUOTE_CIRCUIT_ID,
        payload: payload
    }
}

export const setManualQuoteCircuitID = (payload) => {
    return {
        type: MANUAL_QUOTE_CIRCUIT_ID,
        payload: payload
    }
}

export const setEndTermDate = (payload) => {
    return {
        type: END_TERM_DATE,
        payload: payload
    }
}

export const setLegacyNetwork = (payload) => {
    return {
        type: LEGACY_NETWORK,
        payload: payload
    }
}

export const setBillingSystem = (payload) => {
    return {
        type: BILLING_SYSTEM,
        payload: payload
    }
}

export const setOffnet = (payload) => {
    return {
        type: OFFNET,
        payload: payload
    }
}

export const setNonCommissionable = (payload) => {
    return {
        type: NON_COMMISSIONABLE,
        payload: payload
    }
}

export const setMarkup = (payload) => {
    return {
        type: MARKUP,
        payload: payload
    }
}

export const setWholesaleOpportunity = (payload) => {
    return {
        type: WHOLESALE_OPPORTUNITY,
        payload: payload
    }
}

export const setPricingOverride = (payload) => {
    return {
        type: PRICING_OVERRIDE,
        payload: payload
    }
}

export const setAgent = (payload) => {
    return {
        type: AGENT,
        payload: payload
    }
}

export const setAgentName = (payload) => {
    return {
        type: AGENT_NAME,
        payload: payload
    }
}

export const setAgentPhone = (payload) => {
    return {
        type: AGENT_PHONE,
        payload: payload
    }
}

export const setAgentFee = (payload) => {
    return {
        type: AGENT_FEE,
        payload: payload
    }
}

export const setAgentEmail = (payload) => {
    return {
        type: AGENT_EMAIL,
        payload: payload
    }
}

export const setAgentAddress = (payload) => {
    return {
        type: AGENT_ADDRESS,
        payload: payload
    }
}

export const setAgentCity = (payload) => {
    return {
        type: AGENT_CITY,
        payload: payload
    }
}
export const setAgentState = (payload) => {
    return {
        type: AGENT_STATE,
        payload: payload
    }
}
export const setAgentZip = (payload) => {
    return {
        type: AGENT_ZIP,
        payload: payload
    }
}

export const setEnterpriseRep = (payload) => {
	return {
		type: ENTERPRISE_REP,
		payload: payload
	}
}

export const setEnterpriseRepObj = (index, payload) => {
	return {
		type: ENTERPRISE_REP_OBJ,
		index: index,
		payload: payload
	}
}

export const resetInternalQuote = (payload) => {
    return {
        type: RESET_INTERNAL_QUOTE,
        payload: payload
    }
}
