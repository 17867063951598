import axios from "./axios/customAxios";

const getParentMenu = async (value) => {
	const parentMenuURL = `${process.env.REACT_APP_API_URL}/getReports.php?action=parentMenu&billable=1`;
	return await axios.get(parentMenuURL);
}

const getChildMenu = async (accountNum) => {
	const childMenuURL = `${process.env.REACT_APP_API_URL}/getReports.php?action=childMenu&parent=${accountNum}`;
	return await axios.get(childMenuURL);
}

const getCircuitMenu = async (value) => {
	const circuitMenuURL = `${process.env.REACT_APP_API_URL}/getReports.php?action=circuitMenu`;
	return await axios.get(circuitMenuURL);
}

const getInvoiceMenu = async (accountNum) => {
	const invoiceMenuURL = `${process.env.REACT_APP_API_URL}/getReports.php?action=invoiceMenu&parent=${accountNum}`;
	return await axios.get(invoiceMenuURL);
}

const getReport = async (section, report, invoice, parent, child, start_date, end_date) => {
	const invoiceReportURL = `${process.env.REACT_APP_API_URL}/getReports.php?action=report&section=${section}&report=${report}&invoice=${invoice}&parent=${parent}&child=${child}&start_date=${start_date}&end_date=${end_date}`;
	return await axios.get(invoiceReportURL);
}

const ReportHelper = {
	getParentMenu,
	getChildMenu,
	getCircuitMenu,
	getInvoiceMenu,
	getReport
}

export default ReportHelper;
