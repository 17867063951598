import axios from "./axios/customAxios";

const getUserData = async (data) => {
	const manageURL = `${process.env.REACT_APP_LOGIN_URL}/manage.php`;
	return await axios.post(manageURL, JSON.stringify(data));
}

const updateUserStatus = async (data) => {

	const manageURL = `${process.env.REACT_APP_LOGIN_URL}/manage.php`;
	return await axios.post(manageURL, JSON.stringify(data));
}

const updateUserPreferences = async (formData) => {
	const userManageServiceURL = 'https://transport-dpatten5.windstreamwholesale.com:8903/ajax/';
	return await axios.post(userManageServiceURL, formData);
}

const getUserAccessInfo = async () => {
	const accessURL = `${process.env.REACT_APP_LOGIN_URL}/access.php`;
	return await axios.get(accessURL);
}

const UserManagementService = {
	getUserData,
	updateUserStatus,
	updateUserPreferences,
	getUserAccessInfo
}

export default UserManagementService;
