import {Area, AreaChart, CartesianGrid, Label, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";

const CustomizedLabel = (props) => {
    const { x, y, stroke, value } = props;

    return (
        <text x={x} y={y} dy={-4} fill={stroke} fontSize={10} textAnchor="middle">
            {value}
        </text>
    );
};

const CustomizedAxisTick = (props) => {
    const { x, y, payload } = props;

    return (
        <g transform={`translate(${x},${y})`}>
            <text
                x={0}
                y={0}
                dy={16}
                textAnchor="end"
                fill="#666"
                transform="rotate(-35)"
            >
                {payload.value}
            </text>
        </g>
    );
};




const ChartUI = (props) => {
    const data = props.data;
    const chartTitle = props.title;
    const subTitle = props.subTitle;
    const display = props.display;

    const CustomTooltip = ({ active, payload, label }) => {

        if (active && payload && payload.length) {
            return (
                <div className="custom-tooltip">
                    <p className="label">{`${label}`}</p>
                    <p className="label">{`${subTitle} : ${payload[0].value}`}</p>
                </div>
            );
        }

        return null;
    };

    return (

        <>
            <div className="d-flex justify-content-around mx-5">
                <h6 className="text-dark"> {chartTitle}</h6>
                <h6 className="text-dark"> <span>Max: {Math.max(...data.map(d => d.y))}</span> <span className="ml-3">Min: {Math.min(...data.map(d => d.y))}</span></h6>
            </div>

            <ResponsiveContainer width="100%" height={400}>
                <AreaChart
                    data={data}
                    margin={{
                        top: 20,
                        right: 30,
                        left: 20,
                        bottom: 10
                    }}

                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="x" height={60} interval={2} tick={<CustomizedAxisTick />} />
                    <YAxis type="number" allowDecimals={true} axisLine={false} domain={['auto', 'auto']} tickCount={6} >
                        <Label
                            value={display}
                            position="insideLeft"
                            angle={-90}
                            style={{ textAnchor: 'middle' }}
                        />
                    </YAxis>

                    <Tooltip content={<CustomTooltip />} />
                    <Area dataKey="y" stroke="rgb(51, 102, 204)" strokeWidth="2" fillOpacity="0.3"
                        fill="rgb(51, 102, 204)">
                    </Area>
                </AreaChart>
            </ResponsiveContainer>
        </>
    );
}

export default ChartUI;