import style from "./QuoteCreate.module.css";
import { useEffect, useState, useMemo, useContext } from "react";
import { connect, useSelector } from "react-redux";
import Loader from "../shared/Loader";
import OfficeSuiteHelper from "../../services/OfficeSuiteService";
import PremAddress from "./PremAddress";
import Term from "./Term";
import { ConsoleLogger } from "../../logger/ConsoleLogger";
import {
	setQuoteAddressA,
	setTlcThisLoc,
	setEntGroup,
	setExtToExt,
	setExtLength,
	setPorting,
	setBtn,
	setOfficeSuiteQuantities,
	setOfficeSuiteFeatures,
	updateFeaturePricing,
	setFeatureTotalMrc,
	setFeatureTotalNrc,
	setSubTotalMrc,
	setSubTotalNrc,
	setTotalMrc,
	setTotalNrc

} from "../../redux/actions/quoteActions";

const OfficeSuite = (props) => {
	const logger = useMemo(() => new ConsoleLogger({ level: process.env.REACT_APP_LOGGER_LEVEL, }), []);
	const [loader, setLoader] = useState(false);
	const [collapsedSections, setCollapsedSections] = useState({});
	const tlcThisLoc = useSelector(state => state.quote.quoteValues.tlcThisLoc);
	const entGroup = useSelector(state => state.quote.quoteValues.entGroup);
	const extToExt = useSelector(state => state.quote.quoteValues.extToExt);
	const extLength = useSelector(state => state.quote.quoteValues.extLength);
	const porting = useSelector(state => state.quote.quoteValues.porting);
	const btn = useSelector(state => state.quote.quoteValues.btn);
	const selectedOfficeSuiteFeatures = useSelector(state => state.quote.quoteValues.officeSuiteFeatures);
	const selectedQuantities = useSelector(state => state.quote.quoteValues.officeSuiteQuantities);
	const featureTotalMrc = useSelector(state => state.quote.quoteValues.featureTotalMrc);
	const featureTotalNrc = useSelector(state => state.quote.quoteValues.featureTotalNrc);
	const subTotalMrc = useSelector(state => state.quote.quoteValues.subTotalMrc);
	const subTotalNrc = useSelector(state => state.quote.quoteValues.subTotalNrc);
	const totalMrc = useSelector(state => state.quote.quoteValues.totalMrc);
	const totalNrc = useSelector(state => state.quote.quoteValues.totalNrc);
	const [customDialPlanList, setCustomDialPlanList] = useState([]);
	const termSelected = useSelector(state => state.quote.quoteValues);
	const [term, setTerm] = useState();
	const [showVvxPhone, setShowVvxPhone] = useState(false);
	const [disableCustomDials, setDisableCustomDials] = useState({
		"97": false,  // Cat 6, eQueues
		"100": false, // Cat 6, Contact Center Services w/Call Recording
		"124": true   // Cat 11, HD Meeting Room Connector
	});
	const [popupShow, setPopupShow] = useState(false);
	const [popUpMsg, setPopupMsg] = useState("");
	const [lastUpdatedItem, setLastUpdatedItem] = useState({ item_type: null, qty: null });

	var regex = /[0-9999]/;
	const termDependentFeatures = ['147', '148', '149', '150', '151', '152', '153', '154', '155', '156', '157', '158',
		'160', '161', '162', '163', '164', '165', '166', '167', '168', '169', '170', '171',
		'172', '173', '178', '179', '182', '184', '185', '186', '187', '192', '193', '194', '195',
		'196', '197', '198', '199', '200', '201', '202', '203', '204'
	];

	useEffect(() => {
		//if (props.stepCode === 3 && !selectedOfficeSuiteFeatures) {
		if (props.stepCode === 3 && props.section === 'productConfig') {
			logger.info("I AM getting office suite features now............");
			setLoader(true);
			OfficeSuiteHelper.getOfficeSuiteFeatures().then((response) => {
				logger.info('Office Suite data: ' + JSON.stringify(response.data.features));
				if (response.data && Object.entries(response.data.features).length > 0) {
					logger.info('Initializing Office Suite data and quantities');
					//props.setOfficeSuiteQuantities(response.data.quantities);
					props.updateOfficeSuiteFeatures(response.data.features);
					setCustomDialPlanList(response.data.dialPlans);
					logger.info("CustDial: " + JSON.stringify(response.data.dialPlans));

					// Initialize all sections as collapsed
					const initialCollapsedSections = Object.keys(response.data.features).reduce((acc, oid) => {
						// Assuming `key` is the unique identifier for each category
						const categoryId = response.data.features[oid].key;
						acc[categoryId] = true; // Set each category to start as collapsed
						return acc;
					}, {});
					console.log("initialCollapsedSections" + JSON.stringify(initialCollapsedSections));
					setCollapsedSections(initialCollapsedSections);
					setLoader(false);
				}
			}).catch((error) => {
				logger.error(`Error fetching Office Suite data: ${error}`);
			});
		}

	}, []);

	// Toggle function
	const toggleCollapse = (sectionId) => {
		setCollapsedSections(prevState => ({
			...prevState,
			[sectionId]: !prevState[sectionId] // Toggle the collapsed state for the specific section
		}));
	};

	useEffect(() => {
		if (termSelected.term2 === 'on') {
			setTerm(2);
		} else if (termSelected.term3 === 'on') {
			setTerm(3);
		} else if (termSelected.term5 === 'on') {
			setTerm(5);
		}

		logger.info("The term changed: " + JSON.stringify(term) + " Calling zeroOutTermDependentFeatures ");
		zeroOutTermDependentFeatures();
		// This function will be called whenever `term` changes.
		console.log("The term change: " + JSON.stringify(term));
	}, [termSelected.term2, termSelected.term3, termSelected.term5]);

	useEffect(() => {
		if (Object.keys(lastUpdatedItem).length > 0) { // Example conditional check
			logger.info('Calling businessRules with:', lastUpdatedItem);
			businessRules(lastUpdatedItem);
		}
	}, [lastUpdatedItem])

	useEffect(() => {
		calculateSubtotalsAndTotals();
	}, [featureTotalMrc, featureTotalNrc]);

	const calculateSubtotalsAndTotals = () => {
		let newSubTotalMrc = {};
		let newSubTotalNrc = {};
		let newTotalMrc = 0;
		let newTotalNrc = 0;
		logger.info("In calculateSubtotalsAndTotals");
		Object.keys(selectedOfficeSuiteFeatures).forEach(orderedId => {
			const categoryId = selectedOfficeSuiteFeatures[orderedId]?.key;
			const categories = selectedOfficeSuiteFeatures[orderedId].value;
			let catSubTotalMrc = 0;
			let catSubTotalNrc = 0;
			Object.keys(categories).forEach((itemId) => {
				if (itemId.match(regex)) {
					const item = categories[itemId];
					if (selectedQuantities[itemId]) {
						const qty = selectedQuantities[itemId];
						const mrc = item.mrc;
						const nrc = item.nrc;
						catSubTotalMrc += qty * mrc;
						catSubTotalNrc += qty * nrc;
						logger.info("categoryId: " + categoryId + " qty: " + qty + " mrc: " + mrc + " Nrc: " + nrc + " catSubTotalMrc: " + catSubTotalMrc + " catSubTotalNrc: " + catSubTotalNrc);
					}
				}
				newSubTotalMrc[categoryId] = catSubTotalMrc;
				newSubTotalNrc[categoryId] = catSubTotalNrc;
			})
			newTotalMrc += catSubTotalMrc;
			newTotalNrc += catSubTotalNrc;
		});
		logger.info("newSubTotalMrc: " + JSON.stringify(newSubTotalMrc));
		logger.info("newSubTotalNrc: " + JSON.stringify(newSubTotalNrc));
		logger.info("newTotalMrc: " + JSON.stringify(newTotalMrc));
		logger.info("newTotalNrc: " + JSON.stringify(newTotalNrc));

		// Dispatch actions to update Redux store
		props.updateFeatureSubTotalMrc(newSubTotalMrc);
		props.updateFeatureSubTotalNrc(newSubTotalNrc);
		props.updateTotalMrc(newTotalMrc);
		props.updateTotalNrc(newTotalNrc);
	};

	const updatePricingOnDependentFeatured = (catId, item_type, qty, term) => {
		logger.info("In updatePricingOnDependentFeatured");
		OfficeSuiteHelper.getCustomPricing(item_type, term, qty).then((response) => {
			if (!response || !response.data) {
				logger.error("No data in response");
				return;
			}
			console.log("Special Pricing: " + JSON.stringify(response.data));
			props.updateFeaturePricing(catId, item_type, response.data.mrc, response.data.nrc);
			let updateMrcTotal = { ...featureTotalMrc };
			updateMrcTotal[item_type] = response.data.total_mrc;
			props.updateFeatureTotalMrc(updateMrcTotal);


			let updateNrcTotal = { ...featureTotalNrc };
			updateNrcTotal[item_type] = response.data.total_nrc;
			props.updateFeatureTotalNrc(updateNrcTotal);
		}).catch((error) => {
			logger.error(`Error fetching custom pricing: ${error}`);
		});
	};

	const formatCurrency = (amount) => {
		if (!amount || isNaN(amount)) return '';
		return new Intl.NumberFormat('en-US', {
			style: 'currency',
			currency: 'USD',
		}).format(amount);
	};

	const parseCurrency = (currencyStr) => {
		if (typeof currencyStr === 'number') {
			return currencyStr;
		}
		if (typeof currencyStr !== 'string' || !currencyStr) {
			logger.info('parseCurrency expected a string or number, received:', currencyStr);
			return 0;
		}
		const numberStr = currencyStr.replace(/[^0-9.-]+/g, "");
		const number = parseFloat(numberStr);
		return isNaN(number) ? 0 : number;
	};

	const updateItemTypePricing = (item_type, mrc, nrc) => {
		logger.info("++++++ IN updateItemTypePricing++++++++++ Updatedating item_type: " + item_type + " mrc: " + mrc + " nrc: " + nrc);
		let updateMrcTotal = { ...featureTotalMrc, [item_type]: { ...featureTotalMrc[item_type] } };
		updateMrcTotal[item_type] = mrc;
		props.updateFeatureTotalMrc(updateMrcTotal);
		let updateNrcTotal = { ...featureTotalNrc, [item_type]: { ...featureTotalNrc[item_type] } };
		updateNrcTotal[item_type] = nrc;
		props.updateFeatureTotalNrc(updateNrcTotal);
	}

	const handleQtyChange = (catId, item_type, event) => {
		let qty = parseInt(event.target.value, 10);
		if (isNaN(qty)) { qty = ''; }

		logger.info(`handleQtyChange - catID: ${catId} item_type: ${item_type} qty: ${qty}`);

		// Update the state with the new quantity for this item
		let updatedQty = { ...selectedQuantities, [item_type]: { ...selectedQuantities[item_type] } };
		updatedQty[item_type] = qty;
		props.setOfficeSuiteQuantities(updatedQty);

		logger.info(`Updating lastUpdatedItem with item_type: ${item_type}, qty: ${qty}`);
		if (lastUpdatedItem.item_type !== item_type || lastUpdatedItem.qty !== qty) {
			setLastUpdatedItem({ item_type, qty });
		}

		if (termDependentFeatures.includes(item_type)) {
			if (!term) {
				setPopupShow(true);
				setPopupMsg("Term is required to determine pricing. ");
			} else {
				updatePricingOnDependentFeatured(catId, item_type, qty, term);
			}
		} else {
			console.log("In else, not termDependentFeatures");
			const mrcElement = document.getElementById(`mrc_${item_type}`);
			const nrcElement = document.getElementById(`nrc_${item_type}`);
			const mrc = mrcElement ? parseCurrency(mrcElement.value) : 0;
			const nrc = nrcElement ? parseCurrency(nrcElement.value) : 0;
			let totalMrc = mrc * qty;
			let totalNrc = nrc * qty;
			logger.info("CatID: " + catId + " Item ID: " + item_type + " MRC: " + mrc + " NRC: " + nrc + " totalMrc: " + totalMrc + " totalNrc: " + totalNrc);

			updateItemTypePricing(item_type, totalMrc, totalNrc);
		}
	};

	const businessRules = (params) => {
		const { item_type, qty } = params;
		if (item_type == null) { // This checks for both null and undefined
			logger.info('Exiting businessRules due to null item_type');
			return;
		}
		console.log(`Processing businessRules for item_type: ${item_type} and qty: ${qty}`);

		// Check if the item_type is part of the customDialPlanList
		if (customDialPlanList.includes(item_type)) {
			// Logic to disable/enable other dials based on the quantity
			let updatedDisabledDials = { ...disableCustomDials };
			if (qty < 1) {
				// Enable all custom dials
				customDialPlanList.forEach(dial => {
					let updatedQty = { ...selectedQuantities, [dial]: { ...selectedQuantities[dial] } };
					updatedQty[dial] = '';
					props.setOfficeSuiteQuantities(updatedQty);
					updateItemTypePricing(dial, '', '');
					updatedDisabledDials[dial] = false;
				});
			} else {
				// Disable all but the current custom dial
				customDialPlanList.forEach(dial => {
					updatedDisabledDials[dial] = true;
				});
				updatedDisabledDials[item_type] = false; // Enable current one
			}
			setDisableCustomDials(updatedDisabledDials);
		}

		// Special handling for items #97 and #100
		// Cat: 6 - Application  item_type 97: OfficeSuite eQueues 100: Contact Center Services w/Call Recording
		// if one is selected the other is disabled
		if (item_type === "97" || item_type === "100") {
			logger.info("In Special handling for items #97 and #100");
			let updatedDisabledDials = { ...disableCustomDials };
			if (item_type === "97") {
				logger.info("Setting 100 to disabled");
				updatedDisabledDials["100"] = qty > 0;
			} else if (item_type === "100") {
				logger.info("Setting 97 to disabled");
				updatedDisabledDials["97"] = qty > 0;
			}
			setDisableCustomDials(updatedDisabledDials);
		}

		// Special handling for HD Meeting Cat 11
		// 121 - HD Meeting (25)
		// 122 - HD Large Meeting (100)
		// 123 - HD Large Meeting (500)
		// 218 - HD Large Meeting (1000)
		// 124 - HD Meeting Room Connector
		if (["121", "122", "123", "218"].includes(item_type)) {
			logger.info("In Special handling for HD Meeting Cat 11");
			let hd_type = "124";

			if (qty > 0) {
				// If the quantity for the current item_type is more than 0
				let updatedDisabledDials = { ...disableCustomDials };
				updatedDisabledDials[hd_type] = false;
				setDisableCustomDials(updatedDisabledDials);
			} else {
				// Log quantities for all item_types
				["121", "122", "123", "218"].forEach(type => {
					logger.info(`${type}: ${selectedQuantities[type]}`);
				});

				// Check if the other item_types except the current one have a quantity of nothing or undefined
				const otherTypes = ["121", "122", "123", "218"].filter(type => type !== item_type);
				logger.info("...............otherTypes: " + otherTypes);
				const isEmptyOrUndefined = otherTypes.every(type =>
					selectedQuantities[type] === '' || selectedQuantities[type] === undefined || selectedQuantities[type] === null
				);
				logger.info("isEmptyOrUndefined: " + isEmptyOrUndefined);
				if (isEmptyOrUndefined) {
					// If all other item_types have no quantity, clear values and disable
					//logger.info("Setting qty to zero for " + hd_type);
					// Update the state with the new quantity for this item
					let updatedQty = { ...selectedQuantities, [hd_type]: { ...selectedQuantities[hd_type] } };
					updatedQty[hd_type] = qty;
					props.setOfficeSuiteQuantities(updatedQty);
					selectedQuantities[hd_type] = ''; // Update Qty to 0
					logger.info("CALLING CLEARING PRICING");
					updateItemTypePricing(hd_type, '', '');
					let updatedDisabledDials = { ...disableCustomDials };
					updatedDisabledDials[hd_type] = true;
					setDisableCustomDials(updatedDisabledDials);
				}
			}
		}

		// cat 4 'Phones & Extensions'
		// 87 - Purchase of 4-line Color GigE (VVX250) phone
		// 88 - Purchase of 6-line Color GigE (VVX350) phone
		// 89 - Purchase of 12-line Color GigE (VVX450) phone
		if (["87", "88", "89"].includes(item_type)) {
			logger.info("cat 4 'Phones & Extension");
			if (qty > 0) {
				setShowVvxPhone(true);
			} else {
				// Log quantities for all item_types
				["87", "88", "89"].forEach(type => {
					logger.info(`${type}: ${selectedQuantities[type]}`);
				});

				// Check if the other item_types except the current one have a quantity of nothing or undefined
				const otherTypes = ["87", "88", "89"].filter(type => type !== item_type);
				logger.info("...............otherTypes: " + otherTypes);
				const isEmptyOrUndefined = otherTypes.every(type =>
					selectedQuantities[type] === '' || selectedQuantities[type] === undefined || selectedQuantities[type] === null
				);
				logger.info("isEmptyOrUndefined: " + isEmptyOrUndefined);
				if (isEmptyOrUndefined) {
					// If all other item_types have no quantity, clear values and disable
					logger.info("HIDE: ShowVvxPhone ");
					let vxxType = "69"
					let updatedQty = { ...selectedQuantities, [vxxType]: { ...selectedQuantities[vxxType] } };
					updatedQty[vxxType] = '';
					props.setOfficeSuiteQuantities(updatedQty);
					updateItemTypePricing(vxxType, '', '');
					setShowVvxPhone(false);
				}
			}
		}
	}

	const popupOk = () => {
		setPopupShow(false);
	}

	const inputHandler = (ev) => {
		const { name, value } = ev.target;
		logger.info('inputHandler - Name: ' + name + ' Value - ' + value + ' ID: ' + ev.target.id)
		switch (name) {
			case 'tlc_this_loc':
				props.updateTlcThisLoc(value);
				break;
			case 'ent_group':
				props.updateEntGroup(value);
				break;
			case 'ext_to_ext':
				props.updateExtToExt(value);
				break;
			case 'ext_length':
				props.updateExtLength(value);
				break;
			case 'porting':
				props.updatePorting(value);
				break;
			case 'btn':
				props.updateBtn(value);
				break;
			default:
				break;
		}
	};

	// When Terms change, some featured quantities/amounts need to be zeroed out
	const zeroOutTermDependentFeatures = () => {
		console.log("In zeroOutTermDependentFeatures");

		let updatedQuantities = { ...selectedQuantities };
		let updatedMrcTotals = { ...featureTotalMrc };
		let updatedNrcTotals = { ...featureTotalNrc };

		termDependentFeatures.forEach((item_type) => {
			if (selectedQuantities[item_type]) {
				updatedQuantities[item_type] = '';

				// Reset totals if they exist for this item_type
				if (featureTotalMrc.hasOwnProperty(item_type)) {
					updatedMrcTotals[item_type] = '';
				}
				if (featureTotalNrc.hasOwnProperty(item_type)) {
					updatedNrcTotals[item_type] = '';
				}
			}
		});

		// Dispatch actions to update quantities and totals in bulk
		props.setOfficeSuiteQuantities(updatedQuantities);
		props.updateFeatureTotalMrc(updatedMrcTotals);
		props.updateFeatureTotalNrc(updatedNrcTotals);
	};

	const getMaxQtyBtItem = (item_type) => {
		let max = "";
		const itemType = Number(item_type);
		switch (itemType) {
			case 105: // Contact Center Services SFTP
			case 104: // Call Marking
				max = 1;
				break;
			case 127: // Purchase of Engenius Durafon PRO Cordless Base Unit
			case 168: // Rental of EnGenius DuraFon PRO Cordless Base Unit
			case 129: // Purchase of Engenius Durafon PRO Cordless Phone System
			case 166: // Rental of Engenius Durafon PRO Cordless Syste
				max = 8;
				break;
			case 128: // Purchase of Engenius Durafon PRO Cordless Handset
			case 167: // Rental of EnGenius DuraFon PRO Cordless Handset
				max = 90;
				break;
			default:
				max = 999;
				break;
		}
		return max;
	}


	const getRuleByItem = (item_type) => {
		let ruleName = '';
		const itemType = Number(item_type);
		ruleName = shippingHandlingFeature(itemType) || '';
		switch (Number(item_type)) {
			case 77: // Purchase 72-Key Color (6930) Phone
			case 151: // Rent 72-Key Color (6930) Phone
				ruleName += ' mitelHandset';
				break;
			//(Phones & Extensions)
			case 76: // Purchase 18-Key Color (6920) Phone
			case 150: // Rent 18-Key Color (6920) Phone
			case 78: // Purchase 96-Key Color Touch (6940) Phone
			case 152: // Rent 96-Key Color Touch (6940) Phone
				ruleName += ' mitelExModel mitelHeadset mitelWallMount';
				break
			case 70: // Purchase Slim (5304) Phone
			case 172: // Rent Slim (5304) Phone
			case 93: // Purchase 96-Key Color Touchscreen Conference (6970) Phone
			case 176: // Rent 96-Key Color Touchscreen Conference (6970) Phone
				ruleName += ' powerOverEther';
				break;
			case 92: // Purchase of Conference Set (6000)
			case 54: // Rental of Conference Set (6000)
				ruleName += ' conf6000 conf6000mic';
				break;
			case 130: // Purchase of Engenius Durafon SIP Cordless Base Unit
			case 131: // Purchase of Engenius Durafon SIP Cordless Handset
			case 132: // Purchase of Engenius Durafon SIP Cordless Phone System
			case 169: // Purchase of Engenius Durafon SIP Cordless Base System (Bundle)
				ruleName += ' engeniusSIP';
				break;
			case 127: // Purchase of Engenius Durafon PRO Cordless Base Unit
			case 129: // Purchase of Engenius Durafon PRO Cordless Phone System
			case 166: // Rental of Engenius Durafon PRO Cordless System
			case 168: // Rental of EnGenius DuraFon PRO Cordless Base Unit
			/// +++++++++++++++++++++++++++NEED to add this tool tip ++++++++++++++++++++++++++++++++++++
			//$toolTip = '<span class="durafonProTip"><img border="0" align="top" src="https://content'.$this->contentLinkEnv.'.windstreamwholesale.com/images/help.png"></span>';
			case 128: // Purchase of Engenius Durafon PRO Cordless Handset
			case 167: // Rental of EnGenius DuraFon PRO Cordless Handset
				ruleName += ' engeniusPRO';
				break;
			case 186: // Rental of Overhead Paging Interface Amplifier (Bogen TAM-B)
			case 190: // Purchase of of Overhead Paging Interface Amplifier (Bogen TAM-B)
				ruleName += ' pagingInterface';
				break;
			case 184: // Rental of Overhead Paging Interface (Algo 8301)
			case 185: // Rental of Overhead Paging Interface (Patton)
			case 187: // Rental of Overhead Paging Interface 2-port ATA
			case 188: // Purchase of Overhead Paging Interface (Algo 8301)
			case 189: // Purchase of Overhead Paging Interface (Patton)
			case 191: // Purchase of Overhead Paging Interface 2-port ATA
				ruleName += ' overheadPaging';
				break;
			case 124: // OfficeSuite HD Meeting Room Connector
				//$menu_js += " DISABLED"; /// +++++++++++++++++++++++++++NEED to add this tool tip ++++++++++++++++++++++++++++++++++++
				break;
			case 121: // OfficeSuite HD Meeting (25)
			case 122: // OfficeSuite HD Large Meeting (100)
			case 123: // OfficeSuite HD Large Meeting (500)
			case 218: // OfficeSuite HD Meeting (1000)
				ruleName += ' hdMeeting';
				break;
			case 87: // Purchase of 4-line Color GigE (VVX250) phone
			case 88: // Purchase of 6-line Color GigE (VVX350) phone
			case 89: // Purchase of 12-line Color GigE (VVX450) phone
				ruleName += ' vvxPhone';
				break;
			default:
				break;
		}

		return ruleName.trim();
	}

	const shippingHandlingFeature = (item_type) => {
		switch (item_type) {
			case 59: //Purchase of Mitel 6900 DECT Headset
			case 60: //Purchase of Mitel 6900 Bluetooth Handset
			case 61: //Purchase of Mitel M695 PKM (color)
			case 62: //Purchase of Mitel 6900 wall mount bracket
			case 64: //Purchase of Power over Ethernet Adapter
			case 69: //Purchase of VVX OBi Wi-Fi dongle
			case 70: //Purchase of Slim (5304) Phone
			case 74: //Purchased Advanced Cordless handset (BVN 8630)
			case 75: //Purchased Advanced Cordless base (BVN 9430)
			case 76: //Purchase of 18-Key Color (6920) Phone
			case 77: //Purchase of 72-Key Color (6930) Phone
			case 78: //Purchase of 96-Key Color Touch (6940) Phone
			case 86: //Purchase of 2-line LCD (VVX150) phone
			case 87: //Purchase of 4-line Color GigE (VVX250) phone
			case 88: //Purchase of 6-line Color GigE (VVX350) phone
			case 89: //Purchase of 12-line Color GigE (VVX450) phone
			case 90: //Purchase of Conference Set (6000) Power over Ethernet Adapter
			case 91: //Purchase of Conference Set (6000) Ext. Mic
			case 92: //Purchase of Conference Set (6000)
			case 93: //Purchase of 96-Key Color Touchscreen Conference (6970) Phone
			case 94: //VVX EM50 Expansion Module
			case 127: //Purchase of Engenius Durafon PRO Cordless Base Unit
			case 128: //Purchase of Engenius Durafon PRO Cordless Handset
			case 129: //Purchase of Engenius Durafon PRO Cordless Phone System
			case 130: //Purchase of Engenius Durafon SIP Cordless Base Unit
			case 131: //Purchase of Engenius Durafon SIP Cordless Handset
			case 132: //Purchase of Engenius Durafon SIP Cordless Phone System
			case 150: //Rental of 18-Key Color (6920) Phone
			case 151: //Rental of 72-Key Color (6930) Phone
			case 152: //Rental of 96-Key Color Touch (6940) Phone
			case 153: //Rental of Mitel M695 PKM (color)
			case 154: //Rental of Conference Set (6000)
			case 157: //Rental of VVX Color Exp Mod
			case 158: //Rental of 2-line LCD (VVX150) phone
			case 159: //Rental of 4-line Color GigE (VVX250) phone
			case 160: //Rental of 6-line Color GigE (VVX350) phone
			case 161: //Rental of 12-line Color GigE (VVX450) phone
			case 162: //Rental of Adv. Cordless Handset BVN8630
			case 164: //Rental of Adv. Cordless Base BVN9430
			case 165: //Rental of Adv. Cordless Repeater (BVN 4024)
			case 166: //Rental of Engenius Durafon PRO Cordless System
			case 169: //Rental of Engenius Durafon SIP Cordless System (Bundle)
			case 167: //Rental of EnGenius DuraFon PRO Cordless Handset
			case 168: //Rental of EnGenius DuraFon PRO Cordless Base Unit
			case 170: //Rental of Additional DuraFon SIP Cordless Handset
			case 171: //Rental of Additional DuraFon SIP Cordless Base
			case 172: //Rental of Slim (5304) Phone
			case 173: //Rental of 96-Key Color Touchscreen Conference (6970) Phone
			case 179: //Rental of Door Box Controller
			case 180: //Purchase of Door Box Controller
			case 181: //Purchase of Door Box Controller Interface
			case 182: //Rental of Door Box Controller Interface
			case 184: //Rental of Overhead Paging Interface (Algo 8301)
			case 185: //Rental of Overhead Paging Interface (Patton)
			case 186: //Rental of Overhead Paging Interface Amplifier (Bogen TAM-B)
			case 187: //Rental of Overhead Paging Interface 2-port ATA
			case 188: //Purchase of Overhead Paging Interface (Algo 8301)
			case 189: //Purchase of Overhead Paging Interface (Patton)
			case 190: //Purchase of of Overhead Paging Interface Amplifier (Bogen TAM-B)
			case 191: //Purchase of Overhead Paging Interface 2-port ATA
			case 192: //Rental of Door Box - Style 1a - Flush Mount, Black
			case 193: //Rental of Door Box - Style 1b - Flush Mount, Brushed Steel
			case 194: //Rental of Door Box - Style 2a - Surface Mount, White Plastic
			case 195: //Rental of Door Box - Style 2b - Surface Mount, Vandal Resistant
			case 196: //Rental of Door Box - Style 3a - Handset w/ Steel Cable
			case 197: //Rental of Door Box - Style 3b - Handset w/ Steel Cable
			case 198: //Rental of Door Box - Style 4a - Flush Single, Satin Black Style
			case 199: //Rental of Door Box - Style 4b - Flush Single, Oil Rubbed Bronze
			case 200: //Rental of Door Box - Style 4c - Flush Single, Polished Brass
			case 201: //Rental of Door Box - Style 4d - Flush Single, Stainless Steel
			case 202: //Rental of Door Box - Style 4e - Flush Single, Satin White
			case 203: //Rental of Door Box - Style K-1700-3 - Flush Mount with Keypad, Brushed Stainless Steel
			case 204: //Rental of Door Box - Style K-1700-3 bn - Flush Mount with Keypad, Bronze
			case 205: //Purchase of Door Box - Style 1a - Flush Mount, Black
			case 206: //Purchase of Door Box - Style 1b - Flush Mount, Brushed Steel
			case 207: //Purchase of Door Box - Style 2a - Surface Mount, White Plastic
			case 208: //Purchase of Door Box - Style 2b - Surface Mount, Vandal Resistant
			case 209: //Purchase of Door Box - Style 3a - Handset w/ Steel Cable
			case 210: //Purchase of Door Box - Style 3b - Handset w/ Steel Cable
			case 211: //Purchase of Door Box - Style 4a - Flush Single, Satin Black Style
			case 212: //Purchase of Door Box - Style 4b - Flush Single, Oil Rubbed Bronze
			case 213: //Purchase of Door Box - Style 4c - Flush Single, Polished Brass
			case 214: //Purchase of Door Box - Style 4d - Flush Single, Stainless Steel
			case 215: //Purchase of Door Box - Style 4e - Flush Single, Satin White
			case 216: //Purchase of Door Box - Style K-1700-3 - Flush Mount with Keypad, Brushed Stainless Steel
			case 217: //Purchase of Door Box - Style K-1700-3 bn - Flush Mount with Keypad, Bronze
				return 'shipping';
			default:
				break;
		}
		return;
	}

	const getClassName = (item_type) => {
		let className = '';
		switch (Number(item_type)) { // CPT-9171 (comment will have line number on spreadsheet of business rule in JIRA)
			case 61:  // Purchase of Mitel M695 PKM (color) (Phones & Extensions)
			case 153: // Rental of Mitel M695 PKM (color)
				className = ' mitelExFields';
				break;
			case 131: // Purchase of Engenius Durafon SIP Cordless Handset
			case 170: // Rental of Additional DuraFon SIP Cordless Handset
				className = ' sipHandset';
				break;
			case 128: // Purchase of Engenius Durafon PRO Cordless Handset
			case 167: // Purchase of Engenius Durafon PRO Cordless Handset
				className = ' proHandset';
				break;
			case 63: // Purchased Advanced Cordless repeater (BVN 4024)
			case 165: // Rental of Adv. Cordless Repeater (BVN 4024)
				className = ' repeater4024';
				break;
			case 187: // Rental of Overhead Paging Interface 2-port ATA
			case 191:  // Purchase of Overhead Paging Interface 2-port ATA
				className = '$className = overheadInterfacePaging';
				break;
			default:
				break;
		}

		return className.trim();
	}
	const getIdName = (item_type) => {
		let idName = '';
		switch (Number(item_type)) { // CPT-9171 (comment will have line number on spreadsheet of business rule in JIRA)
			case 99: // Chat Add On (applications)
				idName = 'chatAddOn';
				break;
			case 104: // Call Marking (applications)
				idName = 'callMarking';
				break;
			case 64: // Power over Ethernet (Phones & Extensions) //
				idName = 'powOverEther';
				break;
			case 59: // Purchase of Mitel M695 PKM (color) (Phones & Extensions)) (Phones & Extensions)
				idName = 'mitelHeadset';
				break;
			case 62: // Purchase of Mitel M695 PKM (color) (Phones & Extensions) (Phones & Extensions)
				idName = 'mitelWallMount';
				break;
			case 69: // Purchase of VVX OBi Wi-Fi dongle
				idName = 'vvxPhone';
				break;
			case 90: // Purchase of Conference Set (6000) Power over Ethernet Adapter
				idName = 'conf6000';
				break;
			case 91: // Purchase of Conference Set (6000) Ext. Mic
				idName = 'conf6000mic';
				break;
			case 183: // Overhead Paging License
				idName = 'overheadPaging';
				break;
			default:
				break;
		}
		return idName.trim();
	}

	return (
		<>
			{props.stepCode === 2 ?
				<PremAddress></PremAddress> : null
			}

			{(props.stepCode === 3) && (props.section === "term") ?
				<Term product={"OFFICESUITE"} /> : null
			}

			{(props.stepCode === 3) && (props.section === "productConfig") ?
				<>
					{loader ? <Loader /> : null}
					<div className={`row ${style.rowGrp}`}>
						<div className="col-sm-8 d-flex align-items-center">
							<span className={style.osTextWithInfoTip}>
								Set Tenant Level Configuration at this Location?
								<span className={style.mandatory}>*</span>
							</span>
							<div className={` ${style.infoTip}`}>
								<img src="../../images/svg/ico-info-filled-dark-off.svg" alt="info tip" className={style.infoImg} />
								<p className={style.contentHover}>
									All locations following will have same configuration e.g. extension length and dial plan.
								</p>
							</div>
						</div>
						<div className="col-1">
							<input type="radio" value='1' name='tlc_this_loc' checked={tlcThisLoc === '1'} onChange={inputHandler} /> Yes
						</div>
						<div className="col-1">
							<input type="radio" value='0' name='tlc_this_loc' checked={tlcThisLoc === '0'} onChange={inputHandler} /> No
						</div>
						<div className="col-2">
						</div>
					</div>

					<div className={`row ${style.rowGrp}`}>
						<div className="col-sm-8 d-flex align-items-center">
							<span className={style.osTextWithInfoTip}>
								Is Tenant part of Enterprise Group?
								<span className={style.mandatory}>*</span>
							</span>
							<div className={` ${style.infoTip}`}>
								<img src="../../images/svg/ico-info-filled-dark-off.svg" alt="info tip" className={style.infoImg} />
								<p className={style.contentHover}>
									Related tenants will have total seats beyond 5,000.
								</p>
							</div>
						</div>
						<div className="col-1">
							<input type="radio" value='1' name='ent_group' checked={entGroup === '1'} onChange={inputHandler} /> Yes
						</div>
						<div className="col-1">
							<input type="radio" value='0' name='ent_group' checked={entGroup === '0'} onChange={inputHandler} /> No
						</div>
						<div className="col-2">
						</div>
					</div>
					{entGroup === '1' && (
						<div className={`row ${style.rowGrp}`}>
							<div className="col-sm-8 d-flex align-items-center">
								Extention-to-Extention Dialing?
								<span className={style.mandatory}>*</span>
							</div>
							<div className="col-1">
								<input type="radio" value='1' name='ext_to_ext' checked={extToExt === '1'} onChange={inputHandler} /> Yes
							</div>
							<div className="col-1">
								<input type="radio" value='0' name='ext_to_ext' checked={extToExt === '0'} onChange={inputHandler} /> No
							</div>
							<div className="col-2">
							</div>
						</div>
					)}

					<div className={`row ${style.rowGrp}`}>
						<div className="col-sm-8 d-flex align-items-center">
							Extension Length?
							<span className={style.mandatory}>*</span>
						</div>
						<div className="col-2">
							<select className={` form-control  ${style.selectFld} ${style.requiredFld} `} name="ext_length" id="ext_length" value={extLength} onChange={inputHandler} >
								<option value=""></option>
								<option value="1">3 digit (100-599)</option>
								<option value="2">4 digit (1000-5999)</option>
								<option value="3">5 digit (10000-59999)</option>
								<option value="4">6 digit (100000-599999)</option>
								<option value="5">7 digit (1000000-5999999)</option>
							</select>
						</div>
						<div className="col-2">
						</div>
					</div>

					<div className={`row ${style.rowGrp}`}>
						<div className="col-sm-8 d-flex align-items-center">
							Is the Customer Porting Telephone Numbers?
							<span className={style.mandatory}>*</span>
						</div>
						<div className="col-1">
							<input type="radio" value='1' name='porting' checked={porting === '1'} onChange={inputHandler} /> Yes
						</div>
						<div className="col-1">
							<input type="radio" value='0' name='porting' checked={porting === '0'} onChange={inputHandler} /> No
						</div>
						<div className="col-2">
						</div>
					</div>
					{porting === '1' && (
						<div className={`row ${style.rowGrp}`}>
							<div className="col-sm-8 d-flex align-items-center">
								Main Telephone Number (BTN)
								<span className={style.mandatory}>*</span>
							</div>
							<div className="col-4">
								<input type="text" className={style.inputFldShort} name="btn" onChange={inputHandler} value={btn} />
							</div>
						</div>
					)}

					{selectedOfficeSuiteFeatures && Object.keys(selectedOfficeSuiteFeatures).map((orderedId) => {
						//console.log("OrderId: " + orderedId);
						return (
							<>
								{Object.keys(selectedOfficeSuiteFeatures[orderedId]).map((x, y) => {
									//console.log("x: " + x + " y: " + y);

									const categoryId = selectedOfficeSuiteFeatures[orderedId]?.key;
									const category = selectedOfficeSuiteFeatures[orderedId]?.value;
									const catName = selectedOfficeSuiteFeatures[orderedId]?.value?.cat_name;

									const catClassName = `cat_${categoryId}`;
									const catMrcSum = `cat_${categoryId}_mrc_sum`; // You will need to calculate or otherwise determine these
									const catNrcSum = `cat_${categoryId}_nrc_sum`; // values based on your application's logic

									//console.log("categoryId: " + categoryId + " catName: " + catName);
									//console.log("categoryId: ", categoryId);
									//if (!Array.isArray(category) && typeof category === 'object') {
									//console.log("Object.keys(category): " + Object.keys(category));
									//if(typeof category === 'object') {
									if (x === 'value') {
										return (
											<>
												<div key={categoryId} className={`cat_${categoryId}`}> {/* Use a div with a key */}
													<span className="cf"></span>
													<div className="sub-hdr alt1">
														<div className="expander" collapsed-id="icon-content" id={catClassName} onClick={() => toggleCollapse(categoryId)}>
															<svg className="ico-link light-ico ico-add">
																<path
																	d="M24,48A24,24,0,1,1,48,24,24,24,0,0,1,24,48ZM24,1.89A22.1,22.1,0,1,0,46.08,24,22.09,22.09,0,0,0,24,1.89Zm1,32.69H23.05V25h-9.6V23.05h9.6v-9.6H25v9.6h9.61V25H25Z"></path>
															</svg>
														</div>
														<div className="trim">&nbsp;</div>
														<div className="nib">
															<div className="the-notch">
																<div className="notch-fg">
																	<svg>
																		<path
																			d="M35.81,26.28l-13,10v-5C-10.47,28.68-1,9.16,11.79,0c0,7.28,0,9.13,0,13.28s3.18,7.92,11,9v-5Z"></path>
																	</svg>
																</div>
															</div>
														</div>
														<h2>{catName}</h2>
													</div>
												</div>

												{!collapsedSections[categoryId] &&
													<div className={`sub-section ${catClassName}`}>
														<div className="row" >
															<div className="col-sm-8">
															</div>
															<div className="col-1">
																MRC
															</div>
															<div className="col-1">
																NRC
															</div>
															<div className="col-1">
																Total MRC
															</div>
															<div className="col-1">
																Total NRC
															</div>
														</div>
														{Object.keys(category).map((itemId) => {
															//setMaxQtyBtItem(itemId);
															//setRuleByItem(itemId);
															let max = getMaxQtyBtItem(itemId);
															let ruleName = getRuleByItem(itemId);
															let idName = getIdName(itemId);
															let osClass = getClassName(itemId);
															let dialClass = '';
															let dialTotal = '';
															if (customDialPlanList.includes(itemId)) {
																dialClass = 'cust_dial';
																dialTotal = 'cust_total';
															}
															//console.log("ItemId: " + itemId + " max: " + max + " ruleName:" + ruleName + " idName: " + osId + " className: " + osClass);
															const qtyArray = Array.from({ length: max + 1 }, (_, index) => index === 0 ? '' : index);
															const toolTipeItemId = [127, 129, 166, 168, 125].includes(Number(itemId));
															const infoTipMessages = {
																125: "Upgrade from standard Voicemail in greeting, message and storage time.",
															};
															const defaultInfoTipMessage = "Each base unit supports up to four analog lines sold separately.";
															if (itemId.match(regex)) {
																const item = category[itemId];
																let mrc = formatCurrency(item.mrc)
																let nrc = formatCurrency(item.nrc);
																//console.log("itemId", itemId, "feature", item.feature, "MRC", item.mrc, "NRC", item.nrc);
																return (
																	<div className={`row ${osClass} ${catClassName}`} key={itemId} id={idName} style={{ display: Number(itemId) === 69 && !showVvxPhone ? "none" : "" }} >
																		<div className="col-sm-7 d-flex align-items-center">
																			{toolTipeItemId ? (
																				<>
																					<span className={style.osTextWithInfoTip}>
																						{item.feature}
																						<span className={style.mandatory}>*</span>
																					</span>
																					<div className={`${style.infoTip}`}>
																						<img src="../../images/svg/ico-info-filled-dark-off.svg" alt="Info Tip Icon" className={style.infoImg} />
																						<p className={style.contentHover}>
																							{infoTipMessages[Number(itemId)] || defaultInfoTipMessage}
																						</p>
																					</div>
																				</>
																			) : (
																				item.feature
																			)}
																		</div>
																		{Number(itemId) === 174 ?
																			<div className="col-sm-1">
																				<select
																					className={`${style.selectFld} ${ruleName}`}
																					id={`qty_${itemId}`}
																					name={`qty_${itemId}`}
																					value={selectedQuantities[itemId] || '0'} // This line dynamically sets the selected option
																					onChange={(event) => handleQtyChange(categoryId, itemId, event)} >
																					<option value="0">No</option>
																					<option value="1">Yes</option>
																				</select>
																			</div>
																			:
																			<div className="col-sm-1">
																				<select className={`${style.selectFld} ${ruleName} ${dialClass}`}
																					id={`qty_${itemId}`}
																					name={`qty_${itemId}`}
																					value={selectedQuantities?.[itemId]}
																					onChange={(event) => handleQtyChange(categoryId, itemId, event)}
																					disabled={!!disableCustomDials[itemId]} >
																					{qtyArray.map((number) => {
																						return <option value={number}>{number}</option>
																					})}
																				</select>
																			</div>
																		}
																		<div className="col-1">
																			<input type="text" className={`${style.inputFld}`} id={`mrc_${itemId}`} name={`mrc_${itemId}`} value={mrc} readOnly />
																		</div>
																		<div className="col-1">
																			<input type="text" className={`${style.inputFld}`} id={`nrc_${itemId}`} name={`nrc_${itemId}`} value={nrc} readOnly />
																		</div>
																		<div className="col-1">
																			<input type="text" className={`${style.inputFld} ${catMrcSum} ${dialTotal} os_mrc`} id={`total_mrc_${itemId}`} name={`total_mrc_${itemId}`} value={formatCurrency(featureTotalMrc?.[itemId]) || ``} readOnly />
																		</div>
																		<div className="col-1">
																			<input type="text" className={`${style.inputFld} ${catNrcSum} ${dialTotal} os_nrc`} id={`total_nrc_${itemId}`} name={`total_nrc_${itemId}`} value={formatCurrency(featureTotalNrc?.[itemId]) || ``} readOnly />
																		</div>
																	</div>
																);
															}
														})}
														<div className="row" >
															<div className="col-sm-10">
															</div>
															<div className="col-1">
																Sub-Total
															</div>
															<div className="col-1">
																Sub-Total
															</div>
														</div>
														<div className="row" >
															<div className="col-sm-10">
																{Number(categoryId) === 2 ?
																	<>
																		<span className={` ${style.osTextWithInfoTip}`}>
																			Seat Descriptions
																		</span>
																		<div className={`${style.osInfoTip}`}>
																			<img src="../../images/svg/ico-info-filled-dark-off.svg" alt="Info Tip Icon" className={style.infoImg} />
																			<div className={`${style.osContentHover}`}>
																				<img src="https://content.windstreamwholesale.com/images/SeatDescriptions.png" alt="Seat Descriptions" />
																			</div>
																		</div>
																	</>
																	: null}
															</div>
															<div className="col-1">
																<input type="text" className={`${style.inputFld}`} value={formatCurrency(subTotalMrc?.[categoryId]) || ``} readOnly />
															</div>
															<div className="col-1">
																<input type="text" className={`${style.inputFld}`} value={formatCurrency(subTotalNrc?.[categoryId]) || ``} readOnly />
															</div>
														</div>
													</div>
												}

											</>
										)
									}
								})}
							</>
						)
					})}

					<div className="row" >
						<div className="col-sm-10">
						</div>
						<div className="col-1">
							Total MRC
						</div>
						<div className="col-1">
							Total NRC
						</div>
					</div>
					<div className="row" >
						<div className="col-sm-10">
							Overall Total
						</div>
						<div className="col-1">
							<input type="text" className={style.inputFld} id={'os_total_mrc'} name={'os_total_mrc'} value={formatCurrency(totalMrc)} readOnly />
						</div>
						<div className="col-1">
							<input type="text" className={style.inputFld} id={'os_total_nrc'} name={'os_total_nrc'} value={formatCurrency(totalNrc)} readOnly />
						</div>
					</div>

				</> : null
			}
			{(popupShow ?
				<div>
					<div className={`${style.overly}`}> </div>
					<div className="modal fade show d-block">
						<div className={` modal-dialog modal-dialog-centered modal-sm`}>
							<div className="modal-content">
								<div className="modal-body">
									{popUpMsg}
								</div>

								<div className="modal-footer d-block text-center">
									<button type="button" className="btn-style btn-theme" data-dismiss="modal" onClick={() => popupOk()}>Ok</button>
								</div>

							</div>
						</div>
					</div>
				</div>
				: null)
			}
		</>
	)
}
const mapDispatchToProps = {
	updateOfficeSuiteAddress: setQuoteAddressA,
	updateTlcThisLoc: setTlcThisLoc,
	updateEntGroup: setEntGroup,
	updateExtToExt: setExtToExt,
	updateExtLength: setExtLength,
	updatePorting: setPorting,
	updateBtn: setBtn,
	setOfficeSuiteQuantities: setOfficeSuiteQuantities,
	updateOfficeSuiteFeatures: setOfficeSuiteFeatures,
	updateFeaturePricing: updateFeaturePricing,
	updateFeatureTotalMrc: setFeatureTotalMrc,
	updateFeatureTotalNrc: setFeatureTotalNrc,
	updateFeatureSubTotalMrc: setSubTotalMrc,
	updateFeatureSubTotalNrc: setSubTotalNrc,
	updateTotalMrc: setTotalMrc,
	updateTotalNrc: setTotalNrc
}

export default connect(null, mapDispatchToProps)(OfficeSuite);