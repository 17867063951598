/**
 * Created by e0173131 on 1/20/2021.
 */


/**
 * JS Imports
 *
 */

// React Core Libraries
import React from 'react';

// Local JS Files Imports
import ViewportWarning from "../shared/ViewportWarning";
import ContactsSection from './ContactsSection';
import {useParams} from 'react-router-dom';

const AddContacts = () => {
	const { gaid } = useParams();
    return (
        <div className="midsection qm-upload abs z1">
            <div className="rel">
                <ViewportWarning />
                <div className="wrap">
                    <h1 className="page-title">Add Contacts</h1>
					<ContactsSection gaid={gaid} action={"Create"}></ContactsSection>
                </div>
            </div>
        </div>
    );
}

export default AddContacts;
