import { useEffect, useState, useMemo } from "react";
import EmployeeInternalHelper from "../../services/EmployeeInternalService";
import Loader from "./Loader";
import Select from 'react-select';
import style from "../quote/QuoteCreate.module.css";
import { connect, useSelector } from "react-redux";
import { resetCompanyMpid, setCompanyMenu, setCompanyMpid } from "../../redux/actions/internalActions";
import { ConsoleLogger } from "../../logger/ConsoleLogger";
import ProfileService from "../../services/ProfileServices";

const CompanyDropdown = (props) => {
	const logger = useMemo(() => new ConsoleLogger({ level: process.env.REACT_APP_LOGGER_LEVEL, }), []);
	const [companyMenu, setCompanyMenu] = useState([]);
	const [loader, setLoader] = useState(false);
	const [disableCompanyMenu, setDisableCompanyMenu] = useState(false);
	const companyMenuFromStore = useSelector(state => state.internal.companyMenu);
	const selectedCompany = useSelector(state => state.internal.company);

	useEffect(() => {
		//Clearing MPID everytime it gets to this compononet
		//props.clearCompanyMpid();
		//Get Company Menu From store if saved or fetch it from our database
		if (props.area !== "Profile") {
			if ((companyMenuFromStore?.length > 0) && (companyMenuFromStore[1].mpid !== "new") && (props.area !== "Summary") && (companyMenuFromStore[1].name !== "Account Executive")) {
				logger.info("Company list from store: " + JSON.stringify(companyMenuFromStore));
				setCompanyMenu(companyMenuFromStore);
			} else {
				setLoader(true);
				//only clear local storage and the mpid from the redux store if either of the conditions below are met
				if (selectedCompany.value === "new" || selectedCompany.label === "Account Executive" || selectedCompany.label === "All Companies") {
					localStorage.removeItem("mpid");
					props.updateCompanyMpid({ "value": "", "label": "Please Select Customer" });
				}
				let addValue = '';
				let allCompanies = '';
				if ((props.area === "Summary") || (props.area === "BulkView")) {
					addValue = 'AE';
					allCompanies = 'all';
				}
				if (props.area === "Reports") {
					allCompanies = 'all';
				}
				// passing true will get option of all companies
				EmployeeInternalHelper.getCompanyOptions(addValue, allCompanies).then(resp => {
					setCompanyMenu(resp.data);
					props.updateCompanyMenu(resp.data);
					setLoader(false);
				}).catch(error => {
					logger.error("Error getting company list " + error);
					setLoader(false);
				})
			}
		}
	}, [])

	useEffect(() => {
		if (props.area === "Profile") {
			logger.info("Company value: " + selectedCompany.value);
			logger.info("Company label: " + selectedCompany.label);
			/*Profile Menu From store if saved or fetch it from our database*/
			if ((companyMenuFromStore?.length > 0) && (companyMenuFromStore[1].mpid === "new")) {
				setCompanyMenu(companyMenuFromStore);
			} else {
				setLoader(true);
				let profileList = null;
				let str = '';
				ProfileService.getProfiles().then(resp => {
					//logger.info('Get Profiles: ' + encodeURIComponent(JSON.stringify(resp.data)).replace(/%20/g, " "));
					console.log(JSON.stringify(resp.data));
					profileList = resp.data;
					profileList = profileList.map(item => {
						str = `${item.name}`;
						return { mpid: item.id, name: str }
					});
					setCompanyMenu(profileList);
					props.updateCompanyMenu(profileList);
					setLoader(false);
				}).catch(error => {
					logger.error("Getting Profiles: " + error);
					setLoader(false);
				})
			}
		}
	}, [companyMenuFromStore, logger, props, props.area, selectedCompany.label, selectedCompany.value])

	useEffect(() => {
		//Checking what page this components get called on and if the menu needs to be disabled
		if (Number(props.stepCode) > 1 && (props.area === "Quote Create" || props.area === "Ticket Create" || props.area === "MACD")) {
			setDisableCompanyMenu(true);
		} else if (props.area === "Circuit Information") {
			setDisableCompanyMenu(true);
		} else if (props.area === "Profile" && props.tab !== "Profile") {
			setDisableCompanyMenu(true);
		} else if (props.area === "Quote View" || props.area === "Order View" || props.area === "Order Create" || props.area === "Ticket Details" || props.area === "BulkView") {
			setDisableCompanyMenu(true);
			//Only update the company/mpid if the selected mpid is not the same as the one passed into the page using props
			if (props?.mpid !== selectedCompany?.value && props?.company !== selectedCompany?.label) {
				props.updateCompanyMpid({ value: props.mpid, label: props.company });
			}
		} else {
			setDisableCompanyMenu(false);
		}
	}, [props])

	/*Controls and saves the company/mpid to the redux internal object and to local storage*/
	const companyHandler = (ev) => {
		let companyVal = '';
		if (!ev) {
			companyVal = ''
		} else {
			companyVal = ev;
		}
		//Setting selected mpid in store and local storage
		props.updateCompanyMpid(companyVal);
	}


	return (
		<>
			{loader ? <Loader /> : null}
			<div className={`row ${style.rowGrp} justify-content-center`}>
				<div className="col-4">
					<Select
						value={{ value: selectedCompany?.value, label: selectedCompany?.label }}
						onChange={companyHandler}
						options={companyMenu.map((item) => {
							return { value: item.mpid, label: item.name }
						})}
						isSearchable
						isDisabled={disableCompanyMenu ? true : false}
						noOptionsMessage={() => 'Loading Company Options'}
					/>
				</div>
			</div>

		</>
	)
}


const mapDispatchToProps = {
	updateCompanyMenu: setCompanyMenu,
	updateCompanyMpid: setCompanyMpid,
	clearCompanyMpid: resetCompanyMpid
}

export default connect(null, mapDispatchToProps)(CompanyDropdown);
