import {Redirect, Route} from "react-router-dom";
import {ConsoleLogger} from "../src/logger/ConsoleLogger";

const EmployeeRoute = ({component: Component, ...rest}) => {
    const logger = new ConsoleLogger({level: process.env.REACT_APP_LOGGER_LEVEL,});
	logger.info("Inside of Employee Route");
    return (
        <Route
            {...rest}
            render={({props}) => {
                if (localStorage.getItem("isEmployeeLoggedIn")) {
                    return <Component {...props}/>
				} else {
					logger.info("Inside of Employee Route redirecting back to /Employee/Login");
                    return <Redirect to={{pathname: "/Employee/Login"}}/>
                }
            }
            }
        />
    )
}

export default EmployeeRoute;
