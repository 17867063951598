import style from "./QuoteCreate.module.css";
import React, {useEffect, useState} from "react";
import {connect, useSelector} from "react-redux";
import PremAddress from "./PremAddress";
import QuoteLocZ from "./QuoteLocZ";
import Select from 'react-select';
import Loader from "../shared/Loader";
import ELineHelper from "../../services/ELineService";
import Term from './Term'
import {
	setQuoteAddBW1,
	setQuoteAddBW2,
	setQuoteAddressA,
	setQuoteAddressZ,
	setQuoteBW,
	setQuoteCircuitType,
	setQuoteCOS,
	setQuoteCosType1,
	setQuoteCosType2,
	setQuoteCosType3,
	setQuoteLocAUni,
	setQuoteLocZUni,
	setQuoteQty,
	setQuoteVpnAPort1,
	setQuoteVpnAPort2,
	setQuoteVpnAPort3,
	setQuoteVpnZPort1,
	setQuoteVpnZPort2,
	setQuoteVpnZPort3
} from "../../redux/actions/quoteActions";
import {ConsoleLogger} from "../../logger/ConsoleLogger";
import HTTPErrorHandler from "../errorPages/HTTPErrorHandler";

const ELINE = (props) => {
	const logger = new ConsoleLogger({ level: process.env.REACT_APP_LOGGER_LEVEL, });
	const [hasError, setHasError] = useState(null);
	const [popLocOptions, setPopLocOptions] = useState([]);
	const [bandwidthAdded, setBandwidthAdded] = useState(false);
	const [bandwidthCount, setBandwidthCount] = useState(0);
	const [eLineBWOptions, setELineBWOptions] = useState([]);
	const [loader, setLoader] = useState(false);
	const selectedELineValues = useSelector(state => state.quote.quoteValues);

	useEffect(() => {
		getELinePOPLocation();
		getELineBWOptions();
	}, []);

	useEffect(() => {
		if(props.stepCode === 3){
			if(selectedELineValues.bw1 !== ""){
				setBandwidthCount(1);
			}
			if(selectedELineValues.bw2 !== ""){
				setBandwidthCount(2);
			}
		}
	},[])

	const getELinePOPLocation = () => {
		if (props.stepCode === 2) {
			let popList = null;
			let str = '';
			setLoader(true);
			ELineHelper.getPopLocations(selectedELineValues.cosneeded).then(resp => {
				logger.info(resp.data);
				popList = resp.data;
				popList = popList.map(item => {
					str = `${item.name}`;
					return { value: item.id, label: str }
				});
				setPopLocOptions(popList);
				setLoader(false);
			}).catch(error => {
				logger.error("Getting E-Line Pop Locations: " + error);
				setHasError(error);
				setLoader(false);
			})
		}
	}

	const getELineBWOptions = () => {
		if (props.stepCode === 3 && props.section === "bandwidth") {
			setLoader(true);
			ELineHelper.getBWOptions(11).then(resp => {
				logger.info(resp.data);
				setELineBWOptions(resp.data);
				setLoader(false);
			}).catch(error => {
				logger.error("Getting E-Line Bandwidths: " + error);
				setHasError(error);
				setLoader(false);
			})
		}
	}

	const findPopLoc = (e, type) => {
		logger.info(e);
		let popVal = '';
		if (!e) {
			popVal = ''
		} else {
			popVal = e;
		}

		if (type === 'locA') {
			props.updateELinePopAddressA(popVal);
		} else {
			props.updateELinePopAddressZ(popVal);
		}
	}

	const addBandwidth = () => {
		setBandwidthAdded(!bandwidthAdded);
		setBandwidthCount(bandwidthCount < 3 ? bandwidthCount + 1 : bandwidthCount);
	}

	const removeBandwidth = () => {
		let counter = bandwidthCount - 1;
		setBandwidthCount(bandwidthCount - 1);
		if (counter === 0) {
			props.updateELineAddBw1("");
			props.updateELineCosType2("");
			props.updateELineAVpnPort2("");
			props.updateELineZVpnPort2("");
		}
		if (counter === 1) {
			props.updateELineAddBw2("");
			props.updateELineCosType3("");
			props.updateELineAVpnPort3("");
			props.updateELineZVpnPort2("");
		}
	}


	const bandwidthHandler = (ev) => {
		switch (ev.target.id) {
			case "bw":
				props.updateELineBW(ev.target.value);
				break;
			case "bw1":
				props.updateELineAddBw1(ev.target.value);
				break;
			case "bw2":
				props.updateELineAddBw2(ev.target.value);
				break;
			default:
				break;
		}
	}

	const inputChange = (ev) => {
		switch (ev.target.id) {
			case "qty":
				props.updateELineQty(ev.target.value);
				break;
			case "circuit":
				props.updateELineCircuitType(ev.target.value);
				if (ev.target.value === "11") {
					props.updateELineLocAUni("no");
					props.updateELineLocZUni("no");
				}
				break;
			case "cos":
				props.updateELineCos(ev.target.value);
				break;
			case "la_existing_uni":
				props.updateELineLocAUni(ev.target.value);
				break;
			case "lz_existing_uni":
				props.updateELineLocZUni(ev.target.value);
				break;
			case "cos1":
				props.updateELineCosType1(ev.target.value);
				break;
			case "a_vpn_port_1":
				props.updateELineAVpnPort1(ev.target.value);
				break;
			case "z_vpn_port_1":
				props.updateELineZVpnPort1(ev.target.value);
				break;
			case "cos2":
				props.updateELineCosType2(ev.target.value);
				break;
			case "a_vpn_port_2":
				props.updateELineAVpnPort2(ev.target.value);
				break;
			case "z_vpn_port_2":
				props.updateELineZVpnPort2(ev.target.value);
				break;
			case "cos3":
				props.updateELineCosType3(ev.target.value);
				break;
			case "a_vpn_port_3":
				props.updateELineAVpnPort3(ev.target.value);
				break;
			case "z_vpn_port_3":
				props.updateELineZVpnPort3(ev.target.value);
				break;
			default:
				break;

		}
	}

	const customFilter = (option, searchText) => {
		if (option.value.toString().toLowerCase().includes("other") ||
			option.label.toString().toLowerCase().includes(searchText.toString().toLowerCase()) ||
			option.value.toString().toLowerCase().includes(searchText.toString().toLowerCase())
		) {
			return true;
		} else {
			return false;
		}
	}

	return (
		<>
			{hasError && <HTTPErrorHandler error={hasError}></HTTPErrorHandler>}
			{loader ? <Loader /> : null}
			{!hasError && (<>{props.stepCode === 2 ?
				<>
					<div className={`row ${style.rowGrp}`}>
						<div className="col-6">
							<label className={style.labelSelectRequiredFld}>POP Location<span className={style.mandatory}>*</span> </label>
							<Select
								defaultValue={selectedELineValues.coAddA}
								onChange={(e) => { findPopLoc(e, 'locA') }}
								options={popLocOptions.map((item) => {
									return { value: item.value, label: item.label }
								})}
								filterOption={customFilter}
								isSearchable
								isClearable
								noOptionsMessage={() => 'Loading POP Locations'}
							/>
						</div>
					</div>

					{/*if other is selected than take the user input*/}
					{selectedELineValues.coAddA.value === "Other" ?
						<PremAddress />
						: null}


					<div className={style.dividers}></div>

					<div className={` sub-hdr alt1 ${style.subHeader} `}>
						<div className="nib">
							<div className="the-notch">
								<div className="notch-fg"><svg><path d="M35.81,26.28l-13,10v-5C-10.47,28.68-1,9.16,11.79,0c0,7.28,0,9.13,0,13.28s3.18,7.92,11,9v-5Z"></path></svg></div>
							</div>
						</div>
						<h2 className={style.sectionTitle}>Location Z</h2>
					</div>

					<div className={`row ${style.rowGrp}`}>
						<div className="col-6">
							<label className={style.labelSelectRequiredFld}>POP Location<span className={style.mandatory}>*</span> </label>
							<Select
								defaultValue={selectedELineValues.coAddZ}
								onChange={(e) => { findPopLoc(e, 'locZ') }}
								options={popLocOptions.map((item) => {
									return { value: item.value, label: item.label }
								})}
								filterOption={customFilter}
								maxMenuHeight={150}
								isSearchable
								isClearable
								noOptionsMessage={() => 'Loading Pop Locations'}
							/>
						</div>
					</div>
					{/*if other is selected than take the user input*/}
					{selectedELineValues.coAddZ.value === "Other" ?
						<QuoteLocZ />
						: null}

				</>
				: null}
				{(props.stepCode === 3) && (props.section === "term") ?
					<Term product={"ELINE"} />
					: null}


				{(props.stepCode === 3) && (props.section === "productConfig") ?
					<>
						<div className={`row ${style.rowGrp} `}>
							<div className="col-12 col-sm-4 col-md-3 mb-5">
								<label className={style.labelSelectRequiredFld}>Quantity <span className={style.mandatory}>*</span></label>
								<select className={` form-control  ${style.selectFld} ${style.requiredFld} `} id='qty' onChange={inputChange} value={selectedELineValues.qty}>
									<option value="">-</option>
									{[...Array(25)].map((x, i) =>
										<option key={i}>{i + 1}</option>
									)}
								</select>
							</div>
							<div className="col-6 col-sm-4 col-md-3 rel mb-5">
								<div className="d-flex">
									<div className={style.w_90}>
										<label className={style.labelSelectRequiredFld}>Circuit Type <span className={style.mandatory}>*</span></label>
										<select className={` form-control  ${style.selectFld} ${style.requiredFld} `} id="circuit" onChange={inputChange} value={selectedELineValues.circuit}>
											<option value="" disabled>-</option>
											<option value="11">EPL</option>
											<option value="12">EVPL</option>
										</select>
									</div>
									<div className={` ${style.w_10} align-self-center`}>
										<div className={style.infoTip}>
											<img src="../../images/svg/ico-info-filled-dark-off.svg" alt="info tip" className={style.infoImg} />
											<p className={style.contentHover}>
												<b>EPL:   </b> Point to Point Ethernet service. Cannot multiplex at UNI port at A or Z end. Very transparent service. No VLAN tag coordination required.
												<br></br>
												<br></br>
												<b>EVPL:   </b> Point to Point Ethernet Service. Can multiplex UNI port at A or Z end. More than one circuit can be provisioned from an existing UNI port. Bandwidth ordered cannot exceed UNI port size. VLAN tag coordination required.
											</p>
										</div>
									</div>
								</div>
							</div>

							{selectedELineValues.circuit === "12" || selectedELineValues.circuit === 12 ?
								<>
									<div className="col-5 col-sm-4 col-md-3 rel mb-5">
										<label className={style.labelSelectRequiredFld}>EXISTING LOCATION A UNI<span className={style.mandatory}>*</span></label>
										<select className={` form-control  ${style.selectFld} ${style.requiredFld} `} id='la_existing_uni' onChange={inputChange} value={selectedELineValues.la_existing_uni}>
											<option value="">-</option>
											<option value="no">No - New UNI</option>
											<option value="2">Existing - 10/100 Mbps</option>
											<option value="3">Existing - 1G</option>
											<option value="4">Existing - 10G</option>
										</select>
									</div>
									<div className="col-5 col-sm-4 col-md-3 rel mb-5">
										<label className={style.labelSelectRequiredFld}>EXISTING LOCATION Z UNI<span className={style.mandatory}>*</span></label>
										<select className={` form-control  ${style.selectFld} ${style.requiredFld} `} id='lz_existing_uni' onChange={inputChange} value={selectedELineValues.lz_existing_uni}>
											<option value="">-</option>
											<option value="no">No - New UNI</option>
											<option value="2">Existing - 10/100 Mbps</option>
											<option value="3">Existing - 1G</option>
											<option value="4">Existing - 10G</option>
										</select>
									</div>
								</>
								: null}
						</div>
					</>
					: null}
				{(props.stepCode === 3) && (props.section === "bandwidth") ?
					<>
						<div className="col-6 col-sm-4 col-md-3">
							<label className={style.labelSelectRequiredFld}>Bandwidth <span
								className={style.mandatory}>*</span></label>
							<select className={` form-control  ${style.selectFld} ${style.requiredFld} `} id="bw" onChange={bandwidthHandler} value={selectedELineValues.bw}>
								{eLineBWOptions.map((obj) => {
									return <option value={obj.id}>{obj.name}</option>
								})}
							</select>
						</div>
						{selectedELineValues.cosneeded === 1 ?
							<div className="col-2 col-sm-2">
								<div className="d-flex">
									<div className={style.w_90}>
										<label className={style.labelSelectRequiredFld}>COS <span className={style.mandatory}>*</span></label>
										<select defaultValue={'-'} className={` form-control  ${style.selectFld} ${style.requiredFld} `} id="cos1" onChange={inputChange} value={selectedELineValues.cos1} >
											<option value="" disabled>-</option>
											<option value="1">Standard Data</option>
											<option value="2">Mission Critical</option>
											<option value="3">Real Time</option>
										</select>
									</div>
									<div className={` ${style.w_10} align-self-center`}>
										<div className={` ${style.infoTip}`}>
											<img src="../../images/svg/ico-info-filled-dark-off.svg" alt="info tip" className={style.infoImg} />
											<p className={style.contentHover}>
												Real-Time - High CoS
												<br></br>
												Mission Critical - Medium CoS
												<br></br>
												Standard - Low CoS
											</p>
										</div>
									</div>
								</div>
							</div>
							: null}
						{selectedELineValues.la_existing_uni === "no" || selectedELineValues.circuit === "11" ?
							<div className="col-2 col-sm-2">
								<div className="d-flex">
									<div className={style.w_90}>
										<label className={style.labelSelectRequiredFld}>LocA UNI <span className={style.mandatory}>*</span></label>
										<select defaultValue={'-'} className={` form-control  ${style.selectFld} ${style.requiredFld} `} id="a_vpn_port_1" onChange={inputChange} value={selectedELineValues.a_vpn_port_1}>
											<option value="" disabled>-</option>
											{Number(selectedELineValues.bw) <= 270 ?
												<option value="2">10/100 Mbps</option>
												: null}
											<option value="3">1G</option>
											<option value="4">10G</option>
										</select>
									</div>
								</div>
							</div> : null
						}

						{selectedELineValues.lz_existing_uni === "no" || selectedELineValues.circuit === "11" ?
							<div className="col-2 col-sm-2">
								<div className="d-flex">
									<div className={style.w_90}>
										<label className={style.labelSelectRequiredFld}>LocZ UNI <span className={style.mandatory}>*</span></label>
										<select defaultValue={'-'} className={` form-control  ${style.selectFld} ${style.requiredFld} `} id="z_vpn_port_1" onChange={inputChange} value={selectedELineValues.z_vpn_port_1}>
											<option value="" disabled>-</option>
											{Number(selectedELineValues.bw) <= 270 ?
												<option value="2">10/100 Mbps</option>
												: null}
											<option value="3">1G</option>
											<option value="4">10G</option>
										</select>
									</div>
								</div>
							</div> : null
						}


						<div className="col-1 col-sm-1 col-md-1 align-self-center clearfix">
							<div className={`add-toggle ${style.actionBtn} ${bandwidthAdded ? '' : ''} `} onClick={addBandwidth}>
								<svg className="ico-plus"><path d="M13.1,24H10.89V13.1H0V10.89H10.89V0H13.1V10.89H24V13.1H13.1Z"></path></svg>
							</div>
						</div>
						{bandwidthCount >= 1 ?
							<>
								<div className={`w-100 ${style.rowGrp} `}></div>
								<div className="col-6 col-sm-4 col-md-3">
									<label className={style.labelSelectRequiredFld}>Additional Bandwidth <span
										className={style.mandatory}>*</span></label>
									<select className={` form-control  ${style.selectFld} ${style.requiredFld} `} id="bw1" onChange={bandwidthHandler} value={selectedELineValues.bw1}>
										{eLineBWOptions.map((obj) => {
											return <option value={obj.id}>{obj.name}</option>
										})}
									</select>
								</div>
								{selectedELineValues.cosneeded === 1 ?
									<div className="col-2 col-sm-2">
										<div className="d-flex">
											<div className={style.w_90}>
												<label className={style.labelSelectRequiredFld}>COS <span className={style.mandatory}>*</span></label>
												<select defaultValue={'-'} className={` form-control  ${style.selectFld} ${style.requiredFld} `} id="cos2" onChange={inputChange} value={selectedELineValues.cos2} >
													<option value="" disabled>-</option>
													<option value="1">Standard Data</option>
													<option value="2">Mission Critical</option>
													<option value="3">Real Time</option>
												</select>
											</div>
											<div className={` ${style.w_10} align-self-center`}>
												<div className={` ${style.infoTip}`}>
													<img src="../../images/svg/ico-info-filled-dark-off.svg" alt="info tip" className={style.infoImg} />
													<p className={style.contentHover}>
														Real-Time - High CoS
														<br></br>
														Mission Critical - Medium CoS
														<br></br>
														Standard - Low CoS
													</p>
												</div>
											</div>
										</div>
									</div>
									: null}
								{selectedELineValues.la_existing_uni === "no" ?
									<div className="col-2 col-sm-2">
										<div className="d-flex">
											<div className={style.w_90}>
												<label className={style.labelSelectRequiredFld}>LocA UNI <span className={style.mandatory}>*</span></label>
												<select defaultValue={'-'} className={` form-control  ${style.selectFld} ${style.requiredFld} `} id="a_vpn_port_2" onChange={inputChange} value={selectedELineValues.a_vpn_port_2}>
													<option value="" disabled>-</option>
													{Number(selectedELineValues.bw1) <= 270 ?
														<option value="2">10/100 Mbps</option>
														: null}
													<option value="3">1G</option>
													<option value="4">10G</option>
												</select>
											</div>
										</div>
									</div> : null
								}

								{selectedELineValues.lz_existing_uni === "no" ?
									<div className="col-2 col-sm-2">
										<div className="d-flex">
											<div className={style.w_90}>
												<label className={style.labelSelectRequiredFld}>LocZ UNI <span className={style.mandatory}>*</span></label>
												<select defaultValue={'-'} className={` form-control  ${style.selectFld} ${style.requiredFld} `} id="z_vpn_port_2" onChange={inputChange} value={selectedELineValues.z_vpn_port_2}>
													<option value="" disabled>-</option>
													{Number(selectedELineValues.bw1) <= 270 ?
														<option value="2">10/100 Mbps</option>
														: null}
													<option value="3">1G</option>
													<option value="4">10G</option>
												</select>
											</div>
										</div>
									</div> : null
								}
								<div className={`col-1 col-sm-1 col-md-1 align-self-center clearfix${style.svgWarp} `}>
									<div className={`add-toggle ${style.actionBtn} ${bandwidthAdded ? '' : ''} `}>
										<svg className="ico-plus" onClick={addBandwidth}><path d="M13.1,24H10.89V13.1H0V10.89H10.89V0H13.1V10.89H24V13.1H13.1Z"></path></svg>
										<svg version="1.1" id="Layer_1" className="ico-plus" viewBox="0 0 511.99 511.99" onClick={removeBandwidth}>
											<path d="M8.27,222.43c165.13,0,330.25,0,495.73,0c0,21.3,0,42.35,0,64.01c-165.25,0-330.49,0-495.73,0
                    C8.27,265.1,8.27,243.76,8.27,222.43z"/>
										</svg>
									</div>
								</div>
							</> : null}
						{bandwidthCount >= 2 ?
							<>
								<div className={`w-100 ${style.rowGrp} `}></div>
								<div className="col-6 col-sm-4 col-md-3">
									<label className={style.labelSelectRequiredFld}>Additional Bandwidth <span
										className={style.mandatory}>*</span></label>
									<select className={` form-control  ${style.selectFld} ${style.requiredFld} `} id="bw2" onChange={bandwidthHandler} value={selectedELineValues.bw2}>
										{eLineBWOptions.map((obj) => {
											return <option value={obj.id}>{obj.name}</option>
										})}
									</select>
								</div>
								{selectedELineValues.cosneeded === 1 ?
									<div className="col-2 col-sm-2">
										<div className="d-flex">
											<div className={style.w_90}>
												<label className={style.labelSelectRequiredFld}>COS <span className={style.mandatory}>*</span></label>
												<select defaultValue={'-'} className={` form-control  ${style.selectFld} ${style.requiredFld} `} id="cos3" onChange={inputChange} value={selectedELineValues.cos3} >
													<option value="" disabled>-</option>
													<option value="1">Standard Data</option>
													<option value="2">Mission Critical</option>
													<option value="3">Real Time</option>
												</select>
											</div>
											<div className={` ${style.w_10} align-self-center`}>
												<div className={` ${style.infoTip}`}>
													<img src="../../images/svg/ico-info-filled-dark-off.svg" alt="info tip" className={style.infoImg} />
													<p className={style.contentHover}>
														Real-Time - High CoS
														<br></br>
														Mission Critical - Medium CoS
														<br></br>
														Standard - Low CoS
													</p>
												</div>
											</div>
										</div>
									</div>
									: null}
								{selectedELineValues.la_existing_uni === "no" ?
									<div className="col-2 col-sm-2">
										<div className="d-flex">
											<div className={style.w_90}>
												<label className={style.labelSelectRequiredFld}>LocA UNI <span className={style.mandatory}>*</span></label>
												<select defaultValue={'-'} className={` form-control  ${style.selectFld} ${style.requiredFld} `} id="a_vpn_port_3" onChange={inputChange} value={selectedELineValues.a_vpn_port_3}>
													<option value="" disabled>-</option>
													{Number(selectedELineValues.bw2) <= 270 ?
														<option value="2">10/100 Mbps</option>
														: null}
													<option value="3">1G</option>
													<option value="4">10G</option>
												</select>
											</div>
										</div>
									</div> : null
								}

								{selectedELineValues.lz_existing_uni === "no" ?
									<div className="col-2 col-sm-2">
										<div className="d-flex">
											<div className={style.w_90}>
												<label className={style.labelSelectRequiredFld}>LocZ UNI <span className={style.mandatory}>*</span></label>
												<select defaultValue={'-'} className={` form-control  ${style.selectFld} ${style.requiredFld} `} id="z_vpn_port_3" onChange={inputChange} value={selectedELineValues.z_vpn_port_3}>
													<option value="" disabled>-</option>
													{Number(selectedELineValues.bw2) <= 270 ?
														<option value="2">10/100 Mbps</option>
														: null}
													<option value="3">1G</option>
													<option value="4">10G</option>
												</select>
											</div>
										</div>
									</div> : null
								}
								<div className={`col-1 col-sm-1 col-md-1 align-self-center clearfix ${style.svgWarp} `}>
									<div className={`add-toggle ${style.actionBtn} ${bandwidthAdded ? '' : ''} `}>

										<svg version="1.1" id="Layer_1" className="ico-plus" viewBox="0 0 511.99 511.99" onClick={removeBandwidth}>
											<path d="M8.27,222.43c165.13,0,330.25,0,495.73,0c0,21.3,0,42.35,0,64.01c-165.25,0-330.49,0-495.73,0
C8.27,265.1,8.27,243.76,8.27,222.43z"/>
										</svg>
									</div>
								</div>
							</> : null}

					</> : null}</>)}

		</>
	)
}


const mapDispatchToProps = {
	updateELinePopAddressA: setQuoteAddressA,
	updateELinePopAddressZ: setQuoteAddressZ,
	updateELineBW: setQuoteBW,
	updateELineAddBw1: setQuoteAddBW1,
	updateELineAddBw2: setQuoteAddBW2,
	updateELineCos: setQuoteCOS,
	updateELineQty: setQuoteQty,
	updateELineCircuitType: setQuoteCircuitType,
	updateELineLocAUni: setQuoteLocAUni,
	updateELineLocZUni: setQuoteLocZUni,
	updateELineAVpnPort1: setQuoteVpnAPort1,
	updateELineAVpnPort2: setQuoteVpnAPort2,
	updateELineAVpnPort3: setQuoteVpnAPort3,
	updateELineZVpnPort1: setQuoteVpnZPort1,
	updateELineZVpnPort2: setQuoteVpnZPort2,
	updateELineZVpnPort3: setQuoteVpnZPort3,
	updateELineCosType1: setQuoteCosType1,
	updateELineCosType2: setQuoteCosType2,
	updateELineCosType3: setQuoteCosType3
}

export default connect(null, mapDispatchToProps)(ELINE);
