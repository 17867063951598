import React from 'react';
import { useDispatch } from 'react-redux';
import { alertWindowHome } from '../../redux';
import style from "./Alert.module.css";

export const AlertWindow = () => {
	const today = new Date().toLocaleString('en-us', { month: 'long', year: 'numeric' });
	const dispatch = useDispatch();

	const dismissAction = () => {
		dispatch(alertWindowHome(false));
	}

	return (
		<div className='callout'>
			<div className="callout-msg" >
				<span>{today}</span>
				<h2>Welcome to iconnect</h2>
				<p>
					Your new Windstream Wholesale customer portal has arrived!<br /><br />iconnect offers multi-layer network data and self-service tools, providing unprecedented visibility into both wavelength and fiber performance. Reveal more and manage confidently with network intelligence as an extension of your network!
				</p>
			</div >
			<div className="callout-rhs">
				<div className="callout-actions">
					<div className="dismiss" onClick={() => dismissAction()}>
						<svg className="ico-link ico-add ico-close">
							<path
								d="M24,48A24,24,0,1,1,48,24,24,24,0,0,1,24,48ZM24,1.89A22.1,22.1,0,1,0,46.08,24,22.09,22.09,0,0,0,24,1.89Zm1,32.69H23.05V25h-9.6V23.05h9.6v-9.6H25v9.6h9.61V25H25Z" />
						</svg>
					</div>
					<div className="cbg">
						{/* <svg>
                            <path
                                d="M24,25A36,36,0,0,0,85,50.9V83.8l-2,0A82.22,82.22,0,0,0,24.72,224H0V0H34.1A35.9,35.9,0,0,0,24,25Z" />
                        </svg> */}
						<svg viewBox="0 0 127.8 336.48"><path d="M205.88,114A54.18,54.18,0,0,1,153.4,97.78c-17.91-19.68-20-51.47.91-74H103.2V360.24H231V100.38A55.12,55.12,0,0,1,205.88,114Z" transform="translate(-103.2 -23.76)" /></svg>

					</div>
				</div>
			</div>
		</div >
	)
}