import {
	LOC_Z_ADDRESS,
	LOC_Z_CITY,
	LOC_Z_FLOOR_NUM,
	LOC_Z_STATE,
	LOC_Z_STRUCT,
	LOC_Z_STRUCT_NUM,
	LOC_Z_UNIT,
	LOC_Z_UNIT_NUM,
	LOC_Z_VALID_ADDRESS,
	LOC_Z_ZIP,
    LOC_Z_OVERRIDE,
    LOC_Z_LATITUDE,
    LOC_Z_LONGITUDE
} from "./types/actionTypes";


export const setLocZAddress = (z_add) => {
    return {
        type: LOC_Z_ADDRESS,
        payload: z_add,
    }
}

export const setLocZStruct = (struct) => {
    return {
        type: LOC_Z_STRUCT,
        payload: struct
    }
}

export const setLocZStructNum = (struct_num) => {
    return {
        type: LOC_Z_STRUCT_NUM,
        payload: struct_num
    }
}

export const setLocZFloorNum = (floor_num) => {
    return {
        type: LOC_Z_FLOOR_NUM,
        payload: floor_num
    }
}

export const setLocZUnit = (unit) => {
    return {
        type: LOC_Z_UNIT,
        payload: unit
    }
}

export const setLocZUnitNum = (unit_num) => {
    return {
        type: LOC_Z_UNIT_NUM,
        payload: unit_num
    }
}

export const setLocZCity = (city) => {
    return {
        type: LOC_Z_CITY,
        payload: city
    }
}

export const setLocZState = (state) => {
    return {
        type: LOC_Z_STATE,
        payload: state
    }
}

export const setLocZZip = (zip) => {
    return {
        type: LOC_Z_ZIP,
        payload: zip
    }
}

export const setLocZValidationFlag = (validation) => {
    return {
        type: LOC_Z_VALID_ADDRESS,
        payload: validation
    }
}

export const setLocZOverride = (override) => {
    return {
        type: LOC_Z_OVERRIDE,
        payload: override
    }
}

export const setLocZLatitude = (z_lat) => {
    return {
        type: LOC_Z_LATITUDE,
        payload: z_lat
    }
}

export const setLocZLongitude = (z_long) => {
    return {
        type: LOC_Z_LONGITUDE,
        payload: z_long
    }
}
