import axios from "./axios/customAxios";

const getNotes = async (ref_id) => {
	const notesURL = `${process.env.REACT_APP_API_URL}/Notes.php?type=notes&ref_id=${ref_id}`;
	return await axios.get(notesURL);
}

const insertNotes = async (ref_id, note, site, flag) => {
	const notesURL = `${process.env.REACT_APP_API_URL}/Notes.php?type=addNote&site=${site}&ref_id=${ref_id}&internalFlag=${flag}&note=${encodeURIComponent(note)}`;
	return await axios.get(notesURL);
}

const NotesHelper = {
	getNotes,
	insertNotes
}

export default NotesHelper;
