import React from 'react';

const Loader = () => {
    return (
        <div className="goodies-ldr-example">
            <div className="ww-loader d-block">
                <div className="lds-double-ring">
                    <div>&nbsp;</div>
                    <div>&nbsp;</div>
                </div>
            </div>
        </div>
    )
}

export default Loader;