import {
	LOC_A_ADDRESS,
	LOC_A_CITY,
	LOC_A_EACCESS_VALUES,
	LOC_A_FLOOR_NUM,
	LOC_A_STATE,
	LOC_A_STRUCT,
	LOC_A_STRUCT_NUM,
	LOC_A_UNIT,
	LOC_A_UNIT_NUM,
	LOC_A_VALID_ADDRESS,
	LOC_A_ZIP,
    LOC_A_OVERRIDE,
    LOC_A_LATITUDE,
    LOC_A_LONGITUDE,
    LOC_A_LEC_TYPE
} from "./types/actionTypes";


export const setLocAAddress = (a_add) => {
    return {
        type: LOC_A_ADDRESS,
        payload: a_add,
    }
}

export const setLocAStruct = (struct) => {
    return {
        type: LOC_A_STRUCT,
        payload: struct
    }
}

export const setLocAStructNum = (struct_num) => {
    return {
        type: LOC_A_STRUCT_NUM,
        payload: struct_num
    }
}

export const setLocAFloorNum = (floor_num) => {
    return {
        type: LOC_A_FLOOR_NUM,
        payload: floor_num
    }
}

export const setLocAUnit = (unit) => {
    return {
        type: LOC_A_UNIT,
        payload: unit
    }
}

export const setLocAUnitNum = (unit_num) => {
    return {
        type: LOC_A_UNIT_NUM,
        payload: unit_num
    }
}

export const setLocACity = (city) => {
    return {
        type: LOC_A_CITY,
        payload: city
    }
}

export const setLocAState = (state) => {
    return {
        type: LOC_A_STATE,
        payload: state
    }
}

export const setLocAZip = (zip) => {
    return {
        type: LOC_A_ZIP,
        payload: zip
    }
}

export const setLocAValidationFlag = (validation) => {
    return {
        type: LOC_A_VALID_ADDRESS,
        payload: validation
    }
}

export const setLocAEAccessValues = (eaccess_validation) => {
    return {
        type: LOC_A_EACCESS_VALUES,
        payload: eaccess_validation
    }
}

export const setLocAOverride = (override) => {
    return {
        type: LOC_A_OVERRIDE,
        payload: override
    }
}

export const setLocALatitude = (a_lat) => {
    return {
        type: LOC_A_LATITUDE,
        payload: a_lat
    }
}

export const setLocALongitude = (a_long) => {
    return {
        type: LOC_A_LONGITUDE,
        payload: a_long
    }
}

export const setLocALecType = (lec_type) => {
    return {
        type: LOC_A_LEC_TYPE,
        payload: lec_type
    }
}
