import axios from "./axios/customAxios";

const bulkAddressSubmit = async (projectDetails, formData) => {
	return await axios({
		method: "post",
		url: `${process.env.REACT_APP_API_URL}/internal/bulkAddressValidator.php?data=${JSON.stringify(projectDetails)}`,
		data: formData,
		headers: { "Content-Type": "multipart/form-data" },
	});
}

export default bulkAddressSubmit;
