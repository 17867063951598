import {default as TroubleTicketsMockData} from '../staticData/troubleTicketsHistory.json';
import {default as MaintenanceEventHistoryMockData} from '../staticData/maintenanceEventHistory.json';

export const getTroubleTicketsHistory = async (circuitId) => {
    return await Promise.resolve(TroubleTicketsMockData[circuitId]);
}

export const getMaintenanceEventHistory = async (circuitId) => {
    return await Promise.resolve(MaintenanceEventHistoryMockData[circuitId]);
}
