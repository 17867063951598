import {QUICKBASE_TASKS} from "../actions/types/actionTypes";

const initialState = {
    taskData: {}
};


const quickbaseTaskReducer = (state = initialState, action) => {
    switch (action.type) {
        case QUICKBASE_TASKS:
            return {
                ...state,
                taskData: action.payload
            }
        default:
            return state

    }
}
export default quickbaseTaskReducer;
