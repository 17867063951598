import { useEffect, useState, useMemo } from 'react';
import { useSelector } from "react-redux";
import ViewportWarning from "../shared/ViewportWarning";
import TabsQuote from "./includes/TabsQuote";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import filterFactory from "react-bootstrap-table2-filter";
import ReactTooltip from 'react-tooltip';
import ToggleSwitch from '../components/ToggleSwitch';
import { NavLink, Redirect, useParams, useLocation, useHistory } from 'react-router-dom';
import Loader from '../shared/Loader';
import style from './QuoteView.module.css';
import QuoteHelper from '../../services/QuoteService';
import { ConsoleLogger } from "../../logger/ConsoleLogger";
import { setSessionError } from '../../redux/actions/errorHandlerActions';
import { connect } from "react-redux";
import QuoteClone from './QuoteClone';
import CompanyDropdown from '../shared/CompanyDropdown';
import QuotePricing from '../pricing/QuotePricing';
import OfficeSuitePricing from '../pricing/OfficeSuitePricing';
import InternalQuoteHelper from '../../services/InternalQuoteService';
import DisplayCircuitList from "../shared/ExistingCircuitModal";
import PdfComponent from '../PDF/PDFComponent.js';
import OfficeSuiteHelper from "../../services/OfficeSuiteService";
import FileUpload from '../shared/FileUpload';
import Notes from '../shared/Notes';

const QuoteView = (props) => {
	const logger = useMemo(() => new ConsoleLogger({ level: process.env.REACT_APP_LOGGER_LEVEL, }), []);
	const location = useLocation();
	const history = useHistory();
	const [generatePDF, setGeneratePDF] = useState(false);
	const [generateNewPricingPdf, setGenerateNewPricingPDF] = useState(false);
	const [quoteSummaryData, setQuoteSummaryData] = useState([]);
	const [addQuote, setAddQuote] = useState(false);
	const [filterShow, setFilterShow] = useState(false);
	const [selectedQuoteList, setSelectedQuoteList] = useState([]);
	const [dateRequested, setDateRequested] = useState(false);
	const [dateOrdered, setDateOrdered] = useState(false);
	const [requestor, setRequestor] = useState(false);
	const [quoteID, setQuoteID] = useState(false);
	const [isLoader, setIsLoader] = useState(false);
	const [quoteView, setQuoteView] = useState([]);
	const [notQuoted, setNotQuoted] = useState(true);
	const [pricingAvailable, setPricingAvailable] = useState(false);
	const [orderable, setOrderable] = useState(false);
	const [bulkQuote, setBulkQuote] = useState(false);
	const [orderExist, setOrderExist] = useState(false);
	const [archiveFlag, setArchiveFlag] = useState(false);
	const [redirectFlag, setRedirectFlag] = useState(false);
	const [bulkRequestFlag, setBulkRequestFlag] = useState(false);
	const [quoteError, setQuoteError] = useState(false);
	const [quoteErrorMessage, setQuoteErrorMessage] = useState("");
	const [officeSuiteError, setOfficeSuiteError] = useState(false);
	const [officeSuiteErrorMsg, setOfficeSuiteErrorMsg] = useState("");
	const [hideOrderButton, setHideOrderButton] = useState(false);
	const [cloneRedirectFlag, setCloneRedirectFlag] = useState(false);
	const [salesStage, setSalesStage] = useState("");
	const [origSalesStage, setOrigSalesStage] = useState("");
	const [forecastedCloseDate, setForecastedCloseDate] = useState("");
	const [validUntil, setValidUntil] = useState("");
	const [origForecastedCloseDate, setOrigForecastedCloseDate] = useState("");
	const [updatedDetails, setUpdatedDetails] = useState([]);
	const [updateMsg, setUpdateMsg] = useState("");
	const [errorMsg, setErrorMsg] = useState("");
	const [nobidFlag, setNobidFlag] = useState(false);
	const [notProfileProductError, setNotProfileProductError] = useState(false);
	const { refId, makeOrderable } = useParams();
	const [portalOppFlag, setPortalOppFlag] = useState(true);
	const [icbCaseFlag, setIcbCaseFlag] = useState(true);
	const [quoteViewWfFields, setQuoteViewWfFields] = useState([]);
	const [circuitListDisplay, setCircuitListDisplay] = useState(false);
	const isEmployee = localStorage.getItem('isEmployeeLoggedIn');
	const isCustomer = localStorage.getItem("isLoggedIn");
	const selectedAccessValues = useSelector(state => state.userReducer.user.webaccess);
	const [popupShow, setPopupShow] = useState(false);
	const [popUpMsg, setPopupMsg] = useState("");
	const pdfData = location.state?.generatePDF || localStorage.getItem('generateNewPdf') ? 1 : '';
	const [darkFiberOrdered, setDarkFiberOrdered] = useState(false);

	const [uploads, setUploads] = useState([]);
	const handleFileUploadSuccess = (newFile) => {
		setUploads([...uploads, newFile]);
	}

	const columns = [{
		dataField: 'quoteName',
		text: '',
		formatter: (cell, row) => quoteFormater(cell, row)
	},
	{
		dataField: 'isTick',
		text: '',
		formatter: (cell, row) => manageFormater(cell, row)
	}
	]

	const updateCircuitListDisplay = (action) => {
		if (action === "open") {
			setCircuitListDisplay(true);
		} else {
			setCircuitListDisplay(false);
		}
	}

	const quoteFormater = (props, row) => {
		return (
			<>
				<h3 className={style.quoteName}>{row.quoteName}</h3>
				<div className='filterListWrap'>
					<p className='filterList'>
						<svg className="ico size-xs requested">
							<path d="M18,35A17,17,0,1,1,35,18,17,17,0,0,1,18,35ZM18,3a15,15,0,0,0,0,30c10,0,15-7.48,15-15A15.08,15.08,0,0,0,18,3ZM17,20V10h2v9l5,3-1,2Z">
							</path>
						</svg>
						{row.requestedDate}
					</p>
					<p className='filterList'>
						<svg className="ico size-xs ordered"><path d="M34.5,7V8l-22,22h-2l-9-9V20l1-1,9,9,22-22Z"></path></svg>
						{row.orderedDate}
					</p>
					<p className='filterList'>{row.filterList[0]}</p>
					<p className='filterList'>{row.filterList[1]}</p>
				</div>
			</>
		)
	}

	const manageFormater = (props, row) => {
		return (
			<>
				<button className={`add-toggle d-inline-block ${style.addToggleAlt} ${row.isTick ? 'on' : ''}`} id="tog1" onClick={() => { manageAction(row) }}>
					{
						row.isTick ?
							<svg className="ico-check"><path d="M24,1,9,17H7L0,10,2,9l6,6L22,0Z"></path></svg>
							:
							<svg className="ico-plus"><path d="M13.1,24H10.89V13.1H0V10.89H10.89V0H13.1V10.89H24V13.1H13.1Z"></path></svg>
					}
				</button>
			</>
		)
	}

	const manageAction = async (row) => {
		if ((selectedQuoteList.length >= 5) && !row.isTick) {
			return
		}

		quoteSummaryData.find(QMLO => {
			if (QMLO.id === row.id) {
				QMLO.isTick = !QMLO.isTick;
			}
		});
		setQuoteSummaryData(quoteSummaryData);

		let findSelectedQuoteLength = quoteSummaryData.filter(QS => QS.isTick === true);
		setSelectedQuoteList(findSelectedQuoteLength)
	}

	const filterSelectOnChange = () => {
		setIsLoader(true)

		setTimeout(
			() => setIsLoader(false),
			3000
		);
	}

	useEffect(() => {
		const pdfFlag = localStorage.getItem('generateNewPdf');
		if (pdfFlag) {
			setGenerateNewPricingPDF(true);
			localStorage.removeItem('generateNewPdf');
		}
	}, [])

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [quoteError, notQuoted, hideOrderButton]);

	useEffect(() => {
		let missingCheck = true;
		if (isEmployee) {
			const fetchWfData = () => {
				QuoteHelper.getQuoteWfInfo(refId).then((res) => {
					if (res.data?.opportunity_num?.length > 0 && res.data?.win_url?.length > 0 && res.data?.icb_sfid === "" && missingCheck) {
						setPortalOppFlag(true);
						setQuoteViewWfFields(res.data);
						missingCheck = false;
					} else if ((res.data?.opportunity_num?.length > 0 && res.data?.win_url?.length > 0) && (res.data?.icbcase_url?.length > 0 && res.data?.icb_case?.length > 0)) {
						setIcbCaseFlag(true);
						setPortalOppFlag(true);
						setQuoteViewWfFields(res.data);
						clearInterval(interval);
					} else if (!missingCheck) {
						clearInterval(interval);
					}
					else {
						setPortalOppFlag(false);
						setIcbCaseFlag(false);
					}

					if (res.data?.salesStage) {
						setSalesStage(res.data?.salesStage);
						setOrigSalesStage(res.data?.salesStage);
					}

					if (res.data?.forecastedClosedDate) {
						setForecastedCloseDate(res.data?.forecastedClosedDate);
						setOrigForecastedCloseDate(res.data?.forecastedClosedDate);
					}
				})
			};

			fetchWfData();
			const interval = setInterval(fetchWfData, 20000);
			return () => clearInterval(interval);

		}
	}, [])

	useEffect(() => {
		//Fetch Quote Data
		if (refId) {
			setNotQuoted(false);
			setIsLoader(true);
			QuoteHelper.getQuoteView(refId, makeOrderable, pdfData).then((res) => {
				if (res.data.result === "BULK_REQUEST") {
					setBulkRequestFlag(true);
				} else if (res.result === "SUCCESS") {
					setNotQuoted(false);
					setGeneratePDF(location.state?.generatePDF);
					history.replace({ ...location, state: {} });
					logger.info(res.data);
					setQuoteView(res.data);
					setIsLoader(false);
					if (res.data.bulk_id) {
						logger.info("Setting Bulk Quote");
						setBulkQuote(true);
					} else {
						logger.info("Not a Bulk Quote");
						setBulkQuote(false);
					}

					if (!res.data.quote_id) {
						props.updateSessionError("Quote Not Found. Please Create a new quote.");
						setRedirectFlag(true);
					}

					if (res.data.archived === '1') {
						logger.info("Archived Quote");
						setArchiveFlag(true);
					}

					if (res.data.nobid === '1') {
						logger.info("Nobid Quote");
						setNobidFlag(true);
					}

					setValidUntil(res.data.valid_until);
					if (res.data.orderable) {
						logger.info("Orderable");
						setOrderable(true); // determine if button is displayed
					}

					if (Number((res.data.profile_product) === 0)) {
						logger.info("Product Not turned on in Profile, so not Orderable");
						setNotProfileProductError(true);
						setOrderable(false);
					}

					if (res.data.order_exist !== '' && res.data.order_exist !== null && res.data.order_exist !== 0) {
						logger.info("Order does exist");
						setOrderExist(true);
						setOrderable(false);
					}

					if (Number(res.data.nobid) === 0) {
						setPricingAvailable(true);
					}

				} else { // ERROR
					setIsLoader(false);
					setQuoteErrorMessage(res.data.error);
					setQuoteError(true);
					logger.error('fetchQuoteView ERROR:' + res.data.error);
				}
			})
				.catch(err => {
					logger.error('fetchQuoteView ERROR:' + err);
					setNotQuoted(true);
					setIsLoader(false);
				});
		}
	}, [logger, makeOrderable, props, refId])

	const archiveQuote = () => {
		setIsLoader(true);
		logger.info("Clicked Archive quote " + refId);
		QuoteHelper.archiveQuote(refId).then(resp => {
			if (resp.data.result === 'SUCCESS') {
				setArchiveFlag(true);
			}
			setIsLoader(false);
		}).catch((error) => {
			logger.error("archiveQuote ERROR: " + error);
			setIsLoader(false);
		})
	}

	const cloneQuote = () => {
		setCloneRedirectFlag(true);
	}

	const addQuoteAction = () => {
		setAddQuote(!addQuote);
	}

	const onClickFilter = () => {
		setFilterShow(!filterShow)
	}

	const closeQuotePanel = () => {
		setAddQuote(false);
		setFilterShow(false);
	}

	const noBidQuote = () => {
		setIsLoader(true);
		InternalQuoteHelper.noBidQuote(refId).then(resp => {
			logger.info(`Nobid response: ${resp.data}`);
			if (resp.data.result === "SUCCESS") {
				setNobidFlag(true);
			}
			setIsLoader(false);
		}).catch(error => {
			logger.error("Error Nobid quote: " + error);
			setIsLoader(false);
		})
	}

	const updateDetailsHandler = (event) => {
		setUpdateMsg('');
		setErrorMsg('');
		let id = event.target.id;
		let value = event.target.value;
		logger.info("The following details were entered: " + event.target.value);
		logger.info("The Id of the field changed: " + event.target.id);
		setUpdatedDetails({ ...updatedDetails, [id]: value });
	}

	const submitUpdatedDetails = () => {
		setUpdateMsg('');
		setErrorMsg('');
		if (salesStage < origSalesStage) {
			setIsLoader(true);
			InternalQuoteHelper.advanceSalesStage(refId, salesStage, origSalesStage).then(resp => {
				logger.info("Updating Advance Sales Stage");
				if (resp.data.result === "SUCCESS") {
					setUpdateMsg(resp.data.message);
				} else {
					setErrorMsg(resp.data.message);
				}
				setIsLoader(false);
			}).catch(error => {
				logger.error(`Error Updating Advance Sales Stage: ${error}`);
				setIsLoader(false);
			})
		}

		if (forecastedCloseDate !== origForecastedCloseDate) {
			setIsLoader(true);
			InternalQuoteHelper.forecastedCloseDate(refId, forecastedCloseDate, origForecastedCloseDate, validUntil).then(resp => {
				logger.info("Updating Forecasted Close Date");
				if (resp.data.result === "SUCCESS") {
					setUpdateMsg(resp.data.message);
				} else {
					setErrorMsg(resp.data.message);
				}
				setIsLoader(false);
			}).catch(error => {
				logger.error(`Error Updating Forecasted Close Date: ${error}`);
				setIsLoader(false);
			})
		}

		if (Object.keys(updatedDetails).length > 0) {
			setIsLoader(true);
			InternalQuoteHelper.updateDetails(refId, updatedDetails).then(resp => {
				logger.info("Updating Details");
				setIsLoader(false);
				if (resp.data.result === "SUCCESS") {
					setUpdateMsg(resp.data.message);
				} else {
					setErrorMsg(resp.data.message);
				}
			}).catch(error => {
				logger.error(`Error Updating Details: ${error}`);
				setIsLoader(false);
			})
		}
	}

	const confirmOrder = (data) => {
		setPopupShow(true);
		setPopupMsg("You are about to submit your order, please confirm you are ready to submit. ");
	}

	const popupCancel = () => {
		setPopupShow(false);
	}

	const orderDarkFiber = () => {
		setPopupShow(false);
		setIsLoader(true);
		logger.info("Calling Create Dark Fiber Order");
		QuoteHelper.createDarkFiberOrder(refId).then(resp => {
			logger.info("Create Dark Fiber Order result: " + JSON.stringify(resp.data));
			setIsLoader(false);
			if (resp.data.result === "SUCCESS") {
				setUpdateMsg("Dark Fiber Order was successfully created. ");
				setDarkFiberOrdered(true);
			} else {
				setErrorMsg("There was an Error creating order.  " + resp.data.error)
			}
		}).catch((error) => {
			logger.error("Create Dark Fiber Order ERROR: " + error);
			setIsLoader(false);
		})
	}


	const orderOfficeSuite = () => {
		setPopupShow(false);
		setIsLoader(true);
		logger.info("Calling Create Officesuite Order");
		OfficeSuiteHelper.createOrder(refId).then(resp => {
			logger.info("Create Officesuite Order result: " + JSON.stringify(resp.data));
			setIsLoader(false);
			if (resp.data.result === "SUCCESS") {
				setUpdateMsg("Order was successfully created. ");
				setHideOrderButton(true);
			} else {
				setHideOrderButton(false);
				setOfficeSuiteErrorMsg(resp.data.error);
				setOfficeSuiteError(true);
			}
		}).catch((error) => {
			logger.error("Create Officesuite Order ERROR: " + error);
			setIsLoader(false);
		})
	}

	return (
		<>
			{isLoader ? <Loader /> : ''}
			{redirectFlag ? <Redirect to={`/Quote/Create`} /> : null}
			{bulkRequestFlag ? <Redirect to={`/Quote/Bulk/View/${refId}`} /> : null}
			{darkFiberOrdered ? <Redirect to={`/Order/View/${refId}`} /> : null}
			<div className="midsection quote-view qm-manage abs z1">
				<div className="rel">
					<ViewportWarning />
					<div className="wrap">
						<h1 className="page-title">{isEmployee ? "Employee Quote Manager" : "Quote Manager"}</h1>
						{isEmployee ? <CompanyDropdown area={"Quote View"} mpid={quoteView.mpid} company={quoteView.company} /> : null}
						<div className="section">
							{/* <TabsQuoteManager/> */}
							<TabsQuote />

							{archiveFlag ?  // Display note if quote archived
								<div className="mt-5">
									<p className="text-danger text-center">This Quote is Archived.</p>
								</div>
								: null
							}
							{nobidFlag ?  // Diaply note if quote is nobid
								<div className="mt-5">
									<p className="text-danger text-center">This Quote is Nobid.</p>
								</div>
								: null
							}
							{quoteError ?
								<div className="mt-5">
									<p className="text-error text-center">Quote Failed to Create</p>
									<p className="text-error text-center">{quoteErrorMessage}</p>
								</div> : null
							}
							{officeSuiteError ?
								<div className="mt-5">
									<p className="text-error text-center">Order Failed to Create</p>
									<p className="text-error text-center">{officeSuiteErrorMsg}</p>
								</div> : null
							}
							{notProfileProductError ?
								<div className="mt-5 text-danger">
									<p className="text-error text-center">{"Services for the selected product are not available for ordering per the signed MSA. Please consult with your Account Executive or Account Manager for further assistance."}</p>
								</div> : null
							}
							{notQuoted ?
								'No quote found' :
								//quote view
								<div className={style.dFlex}>
									<div className={`content rel ${style.quoteDetailsPage}`}>
										{isEmployee ?
											<>
												<div className={`sub-hdr ${style.subHdrAlter} `}>
													<div className="nib">
														<div className="the-notch">
															<div className="notch-fg">
																<svg>
																	<path
																		d="M35.81,26.28l-13,10v-5C-10.47,28.68-1,9.16,11.79,0c0,7.28,0,9.13,0,13.28s3.18,7.92,11,9v-5Z"></path>
																</svg>
															</div>
														</div>
													</div>
													<h2 className={` ${style.sectionTitle} m-0 `}>Quote Administration</h2>
												</div>

												<div className="sub-section">
													{/* Specific quote info/options.

												<p className="w65"><em className="orange">Note that if you play with the Add a Quote (+)/Manage Quotes feature, MPLS Aggregation Tail <strong>tab on the right</strong> will allways default to 'active' (have a white background) for this UX POC; since you can't actually go to the individual quotes.</em></p> */}
													<div className={`row ${style.rowGrp}`}>
														<div className='col-md-4'>
															<ul className={` ${style.productList}`}>
																<li>Portal Opportunity</li>
																{portalOppFlag ?
																	<li><a href={quoteViewWfFields.win_url}>{quoteViewWfFields.opportunity_num}</a></li>
																	:
																	<li>
																		<div className="d-inline-flex align-items-center">
																			<span>Pending Winforce</span>
																			<div className="spinner-border spinner-border-sm ml-2" role="status" style={{ borderColor: '#753bbd', borderRightColor: 'transparent' }}></div>
																		</div>
																	</li>
																}
															</ul>
														</div>
														{Number(quoteView.icb) !== 0 || Number(quoteView.manual_flag) > 0 || Number(quoteView.isPartial) === 1 ?
															<div className='col-md-4'>
																<ul className={` ${style.productList}`}>
																	<li>ICB Case</li>
																	{icbCaseFlag ?
																		<li><a href={quoteViewWfFields.icbcase_url}>{quoteViewWfFields.icb_case}</a></li>
																		:
																		<li>
																			<div className="d-inline-flex align-items-center">
																				<span>Pending Winforce</span>
																				<div className="spinner-border spinner-border-sm ml-2" role="status" style={{ borderColor: '#753bbd', borderRightColor: 'transparent' }}></div>
																			</div>
																		</li>
																	}
																</ul>
															</div> : null
														}
													</div>
													<div className={`row ${style.rowGrp}`}>
														{Number(quoteView.icb) === 1 || Number(quoteView.manual_flag) > 0 || Number(quoteView.isPartial) === 1 ?
															<>
																{quoteView?.icb_number > 0 ?
																	<div className='col-md-4'>
																		<ul className={` ${style.productList}`}>
																			<li>ICB Number</li>
																			<li>{quoteView.icb_number}</li>
																		</ul>
																	</div>
																	:
																	<div className='col-md-4'>
																		<ul className={` ${style.productList}`}>
																			<li>ICB Number</li>
																			<li><input type="text" id="icb_number" onChange={updateDetailsHandler} /></li>
																		</ul>
																	</div>
																}
																{quoteView?.icb_related > 0 ?
																	<div className='col-md-4'>
																		<ul className={` ${style.productList}`}>
																			<li>Related ICB</li>
																			<li>{quoteView.icb_related}</li>
																		</ul>
																	</div>
																	:
																	<div className='col-md-4'>
																		<ul className={` ${style.productList}`}>
																			<li>Related ICB</li>
																			<li><input type="text" id="icb_related" onChange={updateDetailsHandler} /></li>
																		</ul>
																	</div>
																}
															</> : null
														}
													</div>
													<div className={`row ${style.rowGrp}`}>
														{quoteView?.wholesale_opp_num ?
															<div className='col-md-4'>
																<ul className={` ${style.productList}`}>
																	<li>Wholesale Opportunity</li>
																	<li><a href={quoteView?.win_url}>{quoteView.wholesale_opp_num}</a></li>
																</ul>
															</div>
															:
															<div className='col-md-4'>
																<ul className={` ${style.productList}`}>
																	<li>Wholesale Opportunity</li>
																	<li><input type="text" id="wholesale_opp_num" onChange={updateDetailsHandler} /></li>
																</ul>
															</div>
														}
													</div>
													<div className={`row ${style.rowGrp}`}>
														<div className='col-md-8'>
															<ul className={` ${style.productList}`}>
																<li>Advance Sales Stage</li>
																<li>
																	<select className={`${style.selectFld} w-100`} id="salesStage" value={salesStage} onChange={(event) => setSalesStage(event.target.value)}>
																		<option></option>
																		<option value="prospect">(5%) Prospect</option>
																		<option value="1stmeeting">(10%) Scheduled 1st Meeting</option>
																		<option value="proposal">(25%) Scheduled Proposal Meeting</option>
																		<option value="proposed">(50%) Solution Proposed to Decision Maker</option>
																		<option value="negotiation">(75%) Negotiation</option>
																		<option value="verbal">(90%) Verbal Commitment</option>
																	</select>
																</li>
															</ul>
														</div>
													</div>
													<div className={`row ${style.rowGrp}`}>
														<div className='col-md-8'>
															<ul className={` ${style.productList}`}>
																<li>Forecasted Close Date</li>
																<li><input type="date" id="forecasted" value={forecastedCloseDate} onChange={(event) => setForecastedCloseDate(event.target.value)} /></li>
															</ul>
														</div>
													</div>
													<div className={`row ${style.rowGrp}`}>
														<div className='col-md-8'>
															{!orderExist && (salesStage !== "prospect") && (quoteView.opportunity !== "") ? <p className="text-danger">{"Automation will not mark this Opportunity as Dead/Lost because the Sales Stage has been advanced."}</p> : null}
														</div>
													</div>
												</div>
												<div className={`row ${style.rowGrp}`}>
													<div className='col-md-4'>
														<div className={`qm-summary mt-2 ml-4`}>
															<button className="ico-button lhs light-button" onClick={submitUpdatedDetails}>
																<svg className="ico light-ico ico-arrow"><path d="M0,17H29L15,31l1,1L32,16,16,0,15,1,29,15H0Z"></path></svg>
																Update Details
															</button>
														</div>
													</div>
													<div className='col-md-4'>
														{updateMsg ? <p className="text-success">{updateMsg}</p> : null}
														{errorMsg ? <p className="text-danger">{errorMsg}</p> : null}
													</div>
												</div>
											</> : null

										}

										<div className={`sub-hdr ${style.subHdrAlter} `}>
											<div className="nib">
												<div className="the-notch">
													<div className="notch-fg">
														<svg>
															<path
																d="M35.81,26.28l-13,10v-5C-10.47,28.68-1,9.16,11.79,0c0,7.28,0,9.13,0,13.28s3.18,7.92,11,9v-5Z"></path>
														</svg>
													</div>
												</div>
											</div>
											<h2 className={` ${style.sectionTitle} m-0 `}>Product Overview</h2>
										</div>

										<div className="sub-section">
											{quoteView?.expiredQuote ?
												<div class="alert alert-danger" role="alert">
													<span>Your quote is past 90 days and has expired. Please consider<button style={{ paddingLeft: '5px', textDecoration: 'underline', cursor: 'pointer', color: '#753bbd' }} onClick={cloneQuote}>cloning.</button></span>
												</div> : null
											}
											{/* Specific quote info/options.

                                            <p className="w65"><em className="orange">Note that if you play with the Add a Quote (+)/Manage Quotes feature, MPLS Aggregation Tail <strong>tab on the right</strong> will allways default to 'active' (have a white background) for this UX POC; since you can't actually go to the individual quotes.</em></p> */}
											<div className={`row ${style.rowGrp}`}>
												{quoteView?.product?.length > 0 ?
													<div className='col-md-4'>
														<ul className={` ${style.productList}`}>
															<li>Product</li>
															<li>{quoteView.product}</li>
														</ul>
													</div>
													: null}

												{quoteView?.ref_id?.length > 0 ?
													<div className='col-md-4'>
														<ul className={` ${style.productList}`}>
															<li>Reference ID</li>
															<li>{quoteView.ref_id}</li>
														</ul>
													</div>
													: null}
											</div>
											<div className={`row ${style.rowGrp}`}>
												{quoteView?.circuit?.length > 0 ?
													<div className='col-md-4'>
														<ul className={` ${style.productList}`}>
															<li>Circuit Type</li>
															<li>{quoteView.circuit}</li>
														</ul>
													</div>
													: null}

												{quoteView?.open_date?.length > 0 ?
													<div className='col-md-4'>
														<ul className={` ${style.productList}`}>
															<li>Quote Submitted</li>
															<li>{quoteView.open_date}</li>
														</ul>
													</div>
													: null}
												{quoteView?.valid_until?.length > 0 ?
													<div className='col-md-4'>
														<ul className={` ${style.productList}`}>
															<li>Valid until</li>
															<li>{quoteView.valid_until}</li>
														</ul>
													</div>
													: null}
											</div>
										</div>

										{quoteView.q_cpt_activity === "R" ?
											<>
												<div className={`sub-hdr ${style.subHdrAlter} `}>
													<div className="nib">
														<div className="the-notch">
															<div className="notch-fg">
																<svg>
																	<path
																		d="M35.81,26.28l-13,10v-5C-10.47,28.68-1,9.16,11.79,0c0,7.28,0,9.13,0,13.28s3.18,7.92,11,9v-5Z"></path>
																</svg>
															</div>
														</div>
													</div>
													<h2 className={` ${style.sectionTitle} m-0 `}>Renewal Details</h2>
												</div>
												<div className="sub-section">
													<div className={`row ${style.rowGrp}`}>
														<div className='col-md-4'>
															<ul className={` ${style.productList}`}>
																<li>End Term Date</li>
																<li>{quoteView?.endTerm}</li>
															</ul>
														</div>
														<div className='col-md-4'>
															<ul className={` ${style.productList}`}>
																<li>Legacy Network</li>
																<li>{quoteView?.legacy_network}</li>
															</ul>
														</div>
														<div className='col-md-4'>
															<ul className={` ${style.productList}`}>
																<li>Billing System</li>
																<li>{quoteView?.billing_system}</li>
															</ul>
														</div>
													</div>
													<div className={`row ${style.rowGrp}`}>
														<div className={`col-md-4  ${style.colGrp}`}>
															<ul className={` ${style.productList}`}>
																<li>Offnet</li>
																<li>{quoteView?.offnet}</li>
															</ul>
														</div>
														{Array.isArray(quoteView?.exist_circuit) && (quoteView?.exist_circuit).length > 1 ?
															<>
																{(quoteView?.exist_circuit).map((circuitId, idx) => {
																	if (idx < 8) {
																		return (
																			<div className={`col-md-4  ${style.colGrp}`}>
																				<ul className={` ${style.productList}`}>
																					<li>{`Circuit ID ${idx + 1}`} </li>
																					<li>{circuitId}</li>
																				</ul>
																			</div>
																		)
																	}
																})}
																{(quoteView?.exist_circuit).length > 7 ?
																	<div className={`col-md-4  ${style.colGrp}`}>
																		<button className={style.circuitBtn} onClick={() => updateCircuitListDisplay('open')} >Display All Circuit</button>
																		{circuitListDisplay ?
																			<DisplayCircuitList exist_circuit={quoteView?.exist_circuit} updateCircuitListDisplay={() => updateCircuitListDisplay('close')} />
																			: null}
																	</div>
																	: null}
															</>
															:
															<>
																<div className={`col-md-4  ${style.colGrp}`}>
																	<ul className={` ${style.productList}`}>
																		<li>Circuit ID</li>
																		<li>{quoteView?.exist_circuit}</li>
																	</ul>
																</div>
															</>
														}
													</div>
												</div>
											</>
											: null
										}

										<div className="sub-section">
											<div className={`row ${style.rowGrp}`}>
												{pricingAvailable ?
													<>
														{quoteView?.product_id === '29' ? // OfficeSuite
															<OfficeSuitePricing quoteData={quoteView} refId={refId} icbPricing={quoteView.icb_pricing} orderExist={orderExist} />
															:
															<QuotePricing quoteData={quoteView} refId={refId} icbPricing={quoteView.icb_pricing} orderExist={orderExist} />
														}
														{quoteView?.product_id !== '32' && (generatePDF || generateNewPricingPdf) ?  // Not Dark Fiber
															<>
																{quoteView.circuit_id === '7' ?
																	<>
																		{logger.info('Rendering WAVE PdfComponent')}
																		<PdfComponent
																			refId={refId}
																			quoteData={quoteView}
																			action={"upload"}
																			type={'WAVE'}
																			comp={"QuoteView"}
																		/>
																	</>
																	:
																	<>
																		{logger.info('Rendering STANDARD PdfComponent')}
																		<PdfComponent
																			refId={refId}
																			quoteData={quoteView}
																			action={"upload"}
																			type={'STANDARD'}
																			comp={"QuoteView"}
																		/>
																	</>
																}
															</>
															: null}
													</>
													: null}
											</div>
										</div>

										<div className="sub-hdr alt1">
											<div className="nib">
												<div className="the-notch">
													<div className="notch-fg"><svg><path d="M35.81,26.28l-13,10v-5C-10.47,28.68-1,9.16,11.79,0c0,7.28,0,9.13,0,13.28s3.18,7.92,11,9v-5Z"></path></svg></div>
												</div>
											</div>
											<h2 className={` ${style.sectionTitle} mb-4 `}>File Attachment</h2>
										</div>
										{(isEmployee || (isCustomer && Number(selectedAccessValues?.TRANS_QUOTE) > 1)) && !quoteError ?
											<FileUpload refId={refId} onUploadSuccess={handleFileUploadSuccess} />
											: null
										}
										<div className="sub-hdr alt2">
											<div className="nib">
												<div className="the-notch">
													<div className="notch-fg"><svg><path d="M35.81,26.28l-13,10v-5C-10.47,28.68-1,9.16,11.79,0c0,7.28,0,9.13,0,13.28s3.18,7.92,11,9v-5Z"></path></svg></div>
												</div>
											</div>
											<h2 className={` ${style.sectionTitle} mb-0`}>Quote Request Details</h2>
										</div>

										<div className="sub-section">
											<div className={`row ${style.rowGrp}`}>
												{quoteView?.company?.length > 0 ?
													<div className={`col-12 col-sm-6  col-md-6 col-lg-4  ${style.colGrp}`}>
														<ul className={` ${style.productList}`}>
															<li>Company Name</li>
															<li>{quoteView.company}</li>
														</ul>
													</div>
													: null}

												{quoteView?.bus_name?.length > 0 ?
													<div className={`col-12 col-sm-6  col-md-6 col-lg-4  ${style.colGrp}`}>
														<ul className={` ${style.productList}`}>
															<li>Quote Name</li>
															<li>{quoteView.bus_name}</li>
														</ul>
													</div>
													: null}

												{/* {quoteView['1yr_term'].length > 0 ? */}
												<div className={`col-12 col-sm-6  col-md-6 col-lg-4  ${style.colGrp}`}>
													<ul className={` ${style.productList}`}>
														<li>Term</li>
														<li>{quoteView['term']}</li>
													</ul>
												</div>
												{/* : null} */}

												{/* not OfficeSuite (29) */}
												{quoteView.product_id !== "29" && quoteView?.quantity?.length > 0 ?
													<div className={`col-12 col-sm-6  col-md-6 col-lg-4  ${style.colGrp}`}>
														<ul className={` ${style.productList}`}>
															<li>Quantity</li>
															<li>{quoteView.quantity}</li>
														</ul>
													</div>
													: null}

												{quoteView?.bandwidth?.length > 0 ?
													<div className={`col-12 col-sm-6  col-md-6 col-lg-4  ${style.colGrp}`}>
														<ul className={` ${style.productList}`}>
															<li>Bandwidth</li>
															<li>{quoteView.bandwidth}</li>
														</ul>
													</div>
													: null}

												{quoteView?.ips?.length > 0 ?
													<div className={`col-12 col-sm-6  col-md-6 col-lg-4  ${style.colGrp}`}>
														<ul className={` ${style.productList}`}>
															<li>Ips</li>
															<li>{quoteView.ips}</li>
														</ul>

													</div>
													: null}


												{quoteView?.ddos?.length > 0 ?
													<div className={`col-12 col-sm-6  col-md-6 col-lg-4  ${style.colGrp}`}>
														<ul className={` ${style.productList}`}>
															<li>DDoS Mitigation</li>
															<li>{quoteView.ddos}</li>
														</ul>
													</div>
													: null}

												{quoteView?.ddos_qty?.length > 0 ?
													<div className={`col-12 col-sm-6  col-md-6 col-lg-4  ${style.colGrp}`}>
														<ul className={` ${style.productList}`}>
															<li>DDoS Quantity</li>
															<li>{quoteView?.ddos_qty}</li>
														</ul>
													</div>
													: null}

												{quoteView?.cam_qty?.length > 0 ?
													<div className={`col-12 col-sm-6  col-md-6 col-lg-4  ${style.colGrp}`}>
														<ul className={` ${style.productList}`}>
															<li>Critical Asset Monitoring </li>
															<li>{quoteView?.cam_qty}</li>
														</ul>
													</div>
													: null}

												{quoteView?.ddos_type?.length > 0 ?
													<div className={`col-12 col-sm-6  col-md-6 col-lg-4  ${style.colGrp}`}>
														<ul className={` ${style.productList}`}>
															<li>DDoS Type</li>
															<li>{quoteView?.ddos_type}</li>
														</ul>
													</div>
													: null}

												{quoteView?.vendor_exclusions?.length > 0 ?
													<div className={`col-12 col-sm-6  col-md-6 col-lg-4  ${style.colGrp}`}>
														<ul className={` ${style.productList}`}>
															<li>vendor Exclusions </li>
															<li>{quoteView.vendor_exclusions}</li>
														</ul>
													</div>
													: null}

												{quoteView?.access_type?.length > 0 ?
													<div className={`col-12 col-sm-6  col-md-6 col-lg-4  ${style.colGrp}`}>
														<ul className={` ${style.productList}`}>
															<li>Offnet</li>
															<li>{quoteView?.offnet}</li>
														</ul>
													</div>
													: null}

												{quoteView?.access_type?.length > 0 ?
													<div className={`col-12 col-sm-6  col-md-6 col-lg-4  ${style.colGrp}`}>
														<ul className={` ${style.productList}`}>
															<li>Access Type</li>
															<li>{quoteView?.access_type}</li>
														</ul>
													</div>
													: null}

												{quoteView?.la_existing_uni?.length > 0 ?
													<div className={`col-12 col-sm-6  col-md-6 col-lg-4  ${style.colGrp}`}>
														<ul className={` ${style.productList}`}>
															<li>Loc A Existing UNI</li>
															<li>{quoteView?.la_existing_uni}</li>
														</ul>
													</div>
													: null}
												{quoteView?.lz_existing_uni?.length > 0 ?
													<div className={`col-12 col-sm-6  col-md-6 col-lg-4  ${style.colGrp}`}>
														<ul className={` ${style.productList}`}>
															<li>Loc Z Existing UNI</li>
															<li>{quoteView?.lz_existing_uni}</li>
														</ul>
													</div>
													: null}

												{quoteView?.rack_space?.length > 0 ?
													<div className={`col-12 col-sm-6  col-md-6 col-lg-4  ${style.colGrp}`}>
														<ul className={` ${style.productList}`}>
															<li>Rack Space</li>
															<li>{quoteView?.rack_space}</li>
														</ul>
													</div>
													: null}

												{quoteView?.primary_power?.length > 0 ?
													<div className={`col-12 col-sm-6  col-md-6 col-lg-4  ${style.colGrp}`}>
														<ul className={` ${style.productList}`}>
															<li>Primary Power</li>
															<li>{quoteView?.primary_power}</li>
														</ul>
													</div>
													: null}

												{quoteView?.breaker_amp?.length > 0 ?
													<div className={`col-12 col-sm-6  col-md-6 col-lg-4  ${style.colGrp}`}>
														<ul className={` ${style.productList}`}>
															<li>Break Amperage</li>
															<li>{quoteView?.breaker_amp}</li>
														</ul>
													</div>
													: null}

												{quoteView?.secondary_pwr?.length > 0 ?
													<div className={`col-12 col-sm-6  col-md-6 col-lg-4  ${style.colGrp}`}>
														<ul className={` ${style.productList}`}>
															<li>Secondary Power</li>
															<li>{quoteView?.secondary_pwr}</li>
														</ul>
													</div>
													: null}

												{quoteView?.ila?.length > 0 ?
													<div className={`col-12 col-sm-6  col-md-6 col-lg-4  ${style.colGrp}`}>
														<ul className={` ${style.productList}`}>
															<li>ILA</li>
															<li>{quoteView?.ila}</li>
														</ul>
													</div>
													: null}

												{quoteView?.fuse?.length > 0 ?
													<div className={`col-12 col-sm-6  col-md-6 col-lg-4  ${style.colGrp}`}>
														<ul className={` ${style.productList}`}>
															<li>Fuse Panel</li>
															<li>{quoteView?.fuse}</li>
														</ul>
													</div>
													: null}

												{quoteView?.diversity_desc?.length > 0 ?
													<div className={`col-12 col-sm-6  col-md-6 col-lg-4  ${style.colGrp}`}>
														<ul className={` ${style.productList}`}>
															<li>Diversity</li>
															<li>{quoteView?.diversity_desc}</li>
														</ul>
													</div>
													: null}

												{quoteView?.diversity_note?.length > 0 ?
													<div className={`col-12 col-sm-6  col-md-6 col-lg-4  ${style.colGrp}`}>
														<ul className={` ${style.productList}`}>
															<li>Diversity Note</li>
															<li>{quoteView?.diversity_note}</li>
														</ul>
													</div>
													: null}

												{quoteView?.num_routes?.length > 0 ?
													<div className={`col-12 col-sm-6  col-md-6 col-lg-4  ${style.colGrp}`}>
														<ul className={` ${style.productList}`}>
															<li>Number of Routes</li>
															<li>{quoteView?.num_routes_desc}</li>
														</ul>
													</div>
													: null}

												{quoteView?.route_latency?.length > 0 ?
													<div className={`col-12 col-sm-6  col-md-6 col-lg-4  ${style.colGrp}`}>
														<ul className={` ${style.productList}`}>
															<li>Route Latency</li>
															<li>{quoteView?.route_latency}</li>
														</ul>
													</div>
													: null}

												{quoteView?.kmz_file?.length > 0 ?
													<div className={`col-12 col-sm-6  col-md-6 col-lg-4  ${style.colGrp}`}>
														<ul className={` ${style.productList}`}>
															<li>KMZ Files</li>
															<li>{quoteView?.kmz_file}</li>
														</ul>
													</div>
													: null}

												{quoteView?.display_df_options?.length > 0 ?
													<div className={`col-12 col-sm-6  col-md-6 col-lg-4  ${style.colGrp}`}>
														<ul className={` ${style.productList}`}>
															<li>Dark Fiber Options</li>
															<li>{quoteView?.display_df_options}</li>
														</ul>
													</div>
													: null}

												{quoteView?.df_num_fibers?.length > 0 ?
													<div className={`col-12 col-sm-6  col-md-6 col-lg-4  ${style.colGrp}`}>
														<ul className={` ${style.productList}`}>
															<li>Number of Fibers</li>
															<li>{quoteView?.df_num_fibers}</li>
														</ul>
													</div>
													: null}

												{quoteView?.la_sano?.length > 0 ?
													<div className={`col-12 col-sm-6  col-md-6 col-lg-4  ${style.colGrp}`}>
														<ul className={` ${style.productList}`}>
															<li>Location A</li>
															<li>{quoteView.locA}
																<span class={style.unitiTagText}>{quoteView.coloUniti}</span>
															</li>
														</ul>
													</div>
													: null}

												{quoteView?.locA_provider?.length > 0 ?
													<div className={`col-12 col-sm-6  col-md-6 col-lg-4  ${style.colGrp}`}>
														<ul className={` ${style.productList}`}>
															<li>Location A Provider</li>
															<li>{quoteView.locA_provider}
															</li>
														</ul>
													</div>
													: null}

												{isEmployee && quoteView?.la_ocn_name?.length > 0 ?
													<div className={`col-12 col-sm-6  col-md-6 col-lg-4  ${style.colGrp}`}>
														<ul className={` ${style.productList}`}>
															<li>Location A OCN Name</li>
															<li>{quoteView.la_ocn_name}
															</li>
														</ul>
													</div>
													: null}

												{isEmployee && quoteView?.la_ocn_winIlec?.length > 0 ?
													<div className={`col-12 col-sm-6  col-md-6 col-lg-4  ${style.colGrp}`}>
														<ul className={` ${style.productList}`}>
															<li>WIN ILEC</li>
															<li>{quoteView.la_ocn_winIlec}
															</li>
														</ul>
													</div>
													: null}

												{quoteView?.la_comments?.length > 0 ?
													<div className={`col-12 col-sm-6  col-md-6 col-lg-4  ${style.colGrp}`}>
														<ul className={` ${style.productList}`}>
															<li>Location A Comments</li>
															<li>{quoteView.la_comments}
															</li>
														</ul>
													</div>
													: null}

												{quoteView?.locZ?.length > 0 ?
													<div className={`col-12 col-sm-6  col-md-6 col-lg-4  ${style.colGrp}`}>
														<ul className={` ${style.productList}`}>
															<li>Location Z</li>
															<li>{quoteView.locZ}
															</li>
														</ul>
													</div>
													: null}

												{quoteView?.locZ_provider?.length > 0 ?
													<div className={`col-12 col-sm-6  col-md-6 col-lg-4  ${style.colGrp}`}>
														<ul className={` ${style.productList}`}>
															<li>Location Z Provider</li>
															<li>{quoteView.locZ_provider}
															</li>
														</ul>
													</div>
													: null}

												{isEmployee && quoteView?.lz_ocn_name?.length > 0 ?
													<div className={`col-12 col-sm-6  col-md-6 col-lg-4  ${style.colGrp}`}>
														<ul className={` ${style.productList}`}>
															<li>Location Z OCN Name</li>
															<li>{quoteView.lz_ocn_name}
															</li>
														</ul>
													</div>
													: null}

												{isEmployee && quoteView?.lz_ocn_winIlec?.length > 0 ?
													<div className={`col-12 col-sm-6  col-md-6 col-lg-4  ${style.colGrp}`}>
														<ul className={` ${style.productList}`}>
															<li>WIN ILEC</li>
															<li>{quoteView.lz_ocn_winIlec}
															</li>
														</ul>
													</div>
													: null}
												{quoteView?.lz_comments?.length > 0 ?
													<div className={`col-12 col-sm-6  col-md-6 col-lg-4  ${style.colGrp}`}>
														<ul className={` ${style.productList}`}>
															<li>Location Z Comments</li>
															<li>{quoteView.lz_comments}
															</li>
														</ul>
													</div>
													: null}
											</div>
										</div>

										<div className="sub-hdr alt3">
											<div className="nib">
												<div className="the-notch">
													<div className="notch-fg"><svg><path d="M35.81,26.28l-13,10v-5C-10.47,28.68-1,9.16,11.79,0c0,7.28,0,9.13,0,13.28s3.18,7.92,11,9v-5Z"></path></svg></div>
												</div>
											</div>

											<h2 className={` ${style.sectionTitle} mb-0`}>Customer Contact Information</h2>
										</div>


										<div className="sub-section mb-5">
											<div className={`row ${style.rowGrp}`}>

												{quoteView?.cp_contact_id?.length > 0 ?
													<div className={`col-12 col-sm-6  col-md-6 col-lg-4  ${style.colGrp}`}>
														<ul className={` ${style.productList}`}>
															<li>Customer Contact</li>
															<li>{quoteView.cp_contact}</li>
														</ul>
													</div>
													: null}

												{quoteView?.cp_phone?.length > 0 ?
													<div className={`col-12 col-sm-6  col-md-6 col-lg-4  ${style.colGrp}`}>
														<ul className={` ${style.productList}`}>
															<li>Contact Phone</li>
															<li>{quoteView?.cp_phone}</li>
														</ul>
													</div>
													: null}

												{quoteView?.cp_email?.length > 0 ?
													<div className={`col-12 col-sm-6  col-md-6 col-lg-4  ${style.colGrp}`}>
														<ul className={` ${style.productList}`}>
															<li>Contact Email</li>
															<li>{quoteView?.cp_email}</li>
														</ul>
													</div>
													: null}
												{quoteView?.cp_quote_ref_id?.length > 0 ?
													<div className={`col-12 col-sm-6  col-md-6 col-lg-4  ${style.colGrp}`}>
														<ul className={` ${style.productList}`}>
															<li>Customer Quote Reference ID</li>
															<li>{quoteView.cp_quote_ref_id}</li>
														</ul>
													</div>
													: null}
												{quoteView?.prev_id?.length > 0 ?
													<div className={`col-12 col-sm-6  col-md-6 col-lg-4  ${style.colGrp}`}>
														<ul className={` ${style.productList}`}>
															<li>Prev Quote Num or Existing Circuit ID</li>
															<li>{quoteView.prev_id}</li>
														</ul>
													</div>
													: null}

												{quoteView?.target_mrc_price?.length > 0 ?
													<div className={`col-12 col-sm-6  col-md-6 col-lg-4  ${style.colGrp}`}>
														<ul className={` ${style.productList}`}>
															<li>Target MRC Price</li>
															<li>{quoteView.target_mrc_price}</li>
														</ul>
													</div>
													: null}

												{quoteView?.manual_flag?.length > 0 ?
													<div className={`col-12 col-sm-6  col-md-6 col-lg-4  ${style.colGrp}`}>
														<ul className={` ${style.productList}`}>
															<li>Special Requirements</li>
															<li>{quoteView?.special_req}</li>
														</ul>
													</div>
													: null}


											</div>
											{quoteView?.manual_note?.length > 0 ?
												<div className={`row ${style.rowGrp}`}>
													<div className={`col-12 col-sm-6  col-md-6 col-lg-6  ${style.colGrp}`}>
														<ul className={` ${style.productList}`}>
															<li>Special Comments</li>
															<li className={style.specialComments}>{quoteView?.manual_note}</li>
														</ul>
													</div>
												</div> : null
											}
										</div>

										<Notes refId={refId} area={"Quote View"} selectedQuoteAccess={selectedAccessValues?.TRANS_QUOTE} />

										{refId !== "8DPU44H7" ? <>
											<div className="sub-hdr">
												<div className="nib">
													<div className="the-notch">
														<div className="notch-fg"><svg><path d="M35.81,26.28l-13,10v-5C-10.47,28.68-1,9.16,11.79,0c0,7.28,0,9.13,0,13.28s3.18,7.92,11,9v-5Z"></path></svg></div>
													</div>
												</div>
												<h2 className={` ${style.sectionTitle} mb-0`}>Notices</h2>
											</div>

											<div className={`sub-section ${style.lastSec}`}>
												<div className={style.noticesSec}>
													{/* ADO-859579 3/4/24
													{quoteView?.notice_eofw?.length > 0 ?
														<>
															<p>
																Customer hereby acknowledges that services requested by Customer will be installed and provided by Windstream using a Fixed Wireless access method. Customer also acknowledges and understands the SLAs and requirements for Fixed Wireless access.
															</p>
															<p>
																Customer hereby warrants that Customer and its End User have obtained roof-top and riser rights from building owner to meet Windstream installation requirements for the requested building. It is Customer's responsibility to verify these requirements with its End User. Windstream is not responsible for obtaining and does not negotiate roof-top and riser rights with building owners. Customer must obtain roof-top and riser rights as a condition for Windstream to provide Fixed Wireless services and failure on Customer's part to obtain these rights could prevent Windstream from providing Fixed Wireless services. Customer agrees to defend, indemnify and hold Windstream harmless from all claims, actions, damages, liabilities arising out of or in connection with Customer's failure to obtain roof-top and riser rights form the building owner.
															</p>
														</>
														: null}
													*/}
													{quoteView?.notice_pl_term2n4?.length > 0 ?
														<p>
															Only 1, 3, 5 year standard pricing is available in this market. If a 2 year or 4 year term quote is required, please click "CLONE QUOTE" on CPT and re-submit service quote for 2 and 4 years terms ONLY.
														</p>
														: null}

													{/* ADO 754219 removing 7/14/23 , commenting out until verified completely
												{quoteView.product_id === 22 || quoteView.product_id === '22' ? // E-Access NNI
													<>
														<p>
															Customer, at its own cost, shall provide collocation space and power in the applicable location for the placement of the Windstream NID.
														</p>
														<p>
															Windstream/Customer Ethernet Network to Network Interface (ENNI) monthly charges may be waived if customer selects the ENNI ramp option.  If customer opts for ENNI ramp, there is no ENNI MRC for a twelve (12) month time-frame after Customer turns up an ENNI with WIN (the ramp-up period).  At the conclusion of the ramp-up period, if Customer has met or exceeded minimum monthly Service revenue on the ENNI then the ENNI MRC shall continue to be waived.  If revenue commitment is not met in the first 12 months, Windstream reserves the right to either; apply standard E-NNI rates on a monthly basis, or turn the ENNI down following notice to customer contact on record.  Minimum Monthly service revenue is the total service revenue of tail circuits terminating to the ENNI and is set at $2500 (1G) and $5000 (10G) per ENNI.
														</p>
													</>
													:
													<p>
														Customer, at its own cost, shall provide collocation space and power in applicable location(s) and access to such space, including any necessary easement and building entrance rights to extend our network from the public rights of way into such location(s).
													</p>
												}

												{quoteView?.notice_mpls_agg_100?.length > 0 ?
													<p>
														By choosing the Ramp Period, the customer commits to achieve and maintain $500 MRC of MPLS Agg Tail services (connected to the NNI) within 12 months from the date the NNI is placed into service for it to remain eligible for a $0 MRC.  If the MPLS Agg Tail commitment is not met in the first 12 months and maintained for the remaining term, standard rates for the respective NNI will be applied by Windstream going forward.
													</p>
													: null}

												{quoteView?.notice_mpls_agg_1000?.length > 0 ?
													<p>
														By choosing the Ramp Period, the customer commits to achieve and maintain $2500 MRC of MPLS Agg Tail services (connected to the NNI) within 12 months from the date the NNI is placed into service for it to remain eligible for a $0 MRC.  If the MPLS Agg Tail commitment is not met in the first 12 months and maintained for the remaining term, standard rates for the respective NNI will be applied by Windstream going forward.
													</p>
													: null}

												{quoteView?.notice_building?.length > 0 ?
													<p>
														Customer, at its own cost, shall provide collocation space and power in applicable location(s) and access to such space, including any necessary easement and building entrance rights to extend our network from the public rights of way into such location(s).
													</p>
													: null}
												*/}
													{quoteView?.notice_meeting?.length > 0 ?
														<p>
															Near each city, the parties will determine a specific location to interconnect their fiber networks.  Customer, at its own cost, will provide 2 fiber strands to Windstream and maintain that fiber for Windstream from that mutually agreeable splice point back to the Customer facility within that city.  The services will then terminate on Windstream equipment inside each customer facility.  At each such facility, Customer at its cost, will provide collocation space and power to Windstream.
														</p>
														: null}

													<p>
														This Service Quote expires {quoteView.lifespan} days from the date hereof. This document is for discussion purposes only; it is not a legal offer.
													</p>

													{quoteView?.notice_ilec?.length > 0 ?
														<p>
															Pricing provided is dependent on facilities availability and may be subject to Special Construction charges. If facilities availability confirmation is required please request a formal Service Inquiry and allow up to 10 business days for confirmation to complete.
														</p>
														: null}
													{quoteView?.notice_budget?.length > 0 ?
														<p>
															Pricing is for BUDGETARY purposes only.  Service Inquiry/site survey will be required to provide firm pricing and availability. If firm pricing and availability are required please reply, and request a formal Service Inquiry. Please allow up to 10 business days for Service Inquiries to complete.
														</p>
														: null}
													{/* ADO 754219 removing 7/14/23 , commenting out until verified completely
												<p>
													Rates for services provided in this quote do not include applicable fees, surcharges or taxes. Applicable fees, surcharges and taxes are in addition to the rates provided herein.
												</p>

												<p>
													If any definitions, terms or conditions included in this Service Order differ from those contained in the MSA, the definitions, terms and conditions contained in the MSA shall control.
												</p>

												{quoteView.product === 32 ? // Dark Fiber
													<>
														<p>
															Quote assumes standard cross connects and does not included items such as riser cable or in-building conduit. If required, additional items such as riser cable and in-building conduit will be quoted and charge separately.
														</p>
													</>
													: null}
												*/}
												</div>

											</div></> : null}

										<div className="quote-actions">
											{isEmployee ?
												<>
													<ReactTooltip id='nobid-Quote' aria-haspopup='true' className='tootipCustom'>
														<p className='m-0'>
															Nobid Quote
														</p>
													</ReactTooltip>
													{/*NOTE NEED TO INVESTIGATE WHY THE X ICON DOESN'T WORK*/}
													<button type="button" className="action ico-link light-ico tipped" data-tip data-for='nobid-Quote' onClick={noBidQuote}>
														<svg className="ico-x"><path d="M18,17l-1,1L9,10,1,18,0,17,8,9,0,1,1,0,9,8l8-8,1,1L10,9Z"></path></svg>
													</button>
												</> : null
											}

											{(isEmployee || (isCustomer && Number(selectedAccessValues?.TRANS_QUOTE) > 1)) ?
												<>
													<button type="button" className="action ico-link light-ico tipped" data-tip data-for='archive-Quote' onClick={archiveQuote}>
														<svg className="ico"><path d="M0,32.5V3.5H12l4,4H32l4,3v22Zm34-20-4-3H15l-4-4H2v25H34Z"></path></svg>
													</button>


													<ReactTooltip id='archive-Quote' aria-haspopup='true' className='tootipCustom'>
														<p className='m-0'>
															Archive Quote
														</p>
													</ReactTooltip>
												</>
												: null
											}

											{(quoteView?.q_cpt_activity !== "R" && !quoteView?.isRouteCreatorQuote && (isEmployee || (isCustomer && Number(selectedAccessValues?.TRANS_QUOTE) > 1))) ?
												<>
													<button className="action ico-link light-ico tipped" data-tip data-for='clone-Quote' onClick={cloneQuote}>
														<svg className="ico"><path d="M33,21V35H15V8h9l9,9v2H22V13h2v4h7l-7-7H17V33H31V21ZM10,12V6h2v4h2v2H10Zm2-9H5V26h8v2H3V1h9l6,6H16Z"></path></svg>
													</button>

													<ReactTooltip id='clone-Quote' aria-haspopup='true' className='tootipCustom'>
														<p className='m-0'>
															Clone/Refresh Quote
														</p>
													</ReactTooltip>
												</> : null
											}

											{cloneRedirectFlag ?
												<QuoteClone quoteData={quoteView} /> : null
											}
										</div>


										<div className={`${style.pageActions} ${style.pageAction}`}>
											<div>
												{bulkQuote && (isEmployee || (isCustomer && Number(selectedAccessValues?.TRANS_QUOTE) > 1)) ?
													<NavLink className="tab-opt qm-summary" to={`/Quote/View/${refId}/1`} activeClassName="active">
														<button className="ico-button lhs light-button">
															<svg className="ico light-ico ico-arrow"><path d="M0,17H29L15,31l1,1L32,16,16,0,15,1,29,15H0Z"></path></svg>
															Make Orderable
														</button>
													</NavLink>
													:
													<>
														{orderable && (isEmployee || (Number(selectedAccessValues?.TRANS_ORDER) > 1)) ?
															<>	{/* OfficeSuite */}
																{quoteView.product_id === "29" && !hideOrderButton ?
																	<>
																		<div className='col-md-4'>
																			<div className={`qm-summary mt-2 ml-4`}>
																				<button className="ico-button lhs light-button" onClick={confirmOrder}>
																					<svg className="ico light-ico ico-arrow"><path d="M0,17H29L15,31l1,1L32,16,16,0,15,1,29,15H0Z"></path></svg>
																					Order Service
																				</button>
																			</div>
																		</div>
																	</>
																	: null}
																{quoteView.product_id === "32" ? // Dark Fiber
																	<>
																		<div className='col-md-4'>
																			<div className={`qm-summary mt-2 ml-4`}>
																				<button className="ico-button lhs light-button" onClick={confirmOrder}>
																					<svg className="ico light-ico ico-arrow"><path d="M0,17H29L15,31l1,1L32,16,16,0,15,1,29,15H0Z"></path></svg>
																					Order Service
																				</button>
																			</div>
																		</div>
																	</>
																	: null}
																{quoteView.product_id !== "29" && quoteView.product_id !== "32" ?
																	<>
																		<NavLink className="tab-opt qm-summary" to={{ pathname: `/Order/Create/${refId}`, state: { quoteViewData: quoteView } }} activeClassName="active">
																			<button className="ico-button lhs light-button">
																				<svg className="ico light-ico ico-arrow"><path d="M0,17H29L15,31l1,1L32,16,16,0,15,1,29,15H0Z"></path></svg>
																				Request Services
																			</button>
																		</NavLink>
																	</>
																	: null}
															</>
															: null}
														{quoteView?.orderViewable && quoteView.product_id !== "29" ?
															<NavLink className="tab-opt qm-summary" to={`/Order/View/${refId}`} activeClassName="active">
																<button className="ico-button lhs light-button">
																	<svg className="ico light-ico ico-arrow"><path d="M0,17H29L15,31l1,1L32,16,16,0,15,1,29,15H0Z"></path></svg>
																	View Order
																</button>
															</NavLink>
															: null}
													</>
												}
											</div>
										</div>

									</div>

									{/*
                                    <div className={`quote-tabs ${style.quoteTabs}`}>
                                        <h4 className={style.manageQuoteTitle}>Managed Quotes</h4>
                                        <div className="quote-itm cf active product-poc-tab-mpls-at">
                                            <div className="quote-itm-close pointer"><svg className="ico ico-x"><path d="M18,17l-1,1L9,10,1,18,0,17,8,9,0,1,1,0,9,8l8-8,1,1L10,9Z"></path></svg></div>
                                            <button className="quote-itm-name">MPLS Aggregation Tail</button>
                                        </div>

                                        {
                                            selectedQuoteList.map((e, i) =>
                                                <div key={i} className="quote-itm cf product-poc-tab-crossconnect" >
                                                    <div className="quote-itm-close pointer"><svg className="ico ico-x"><path d="M18,17l-1,1L9,10,1,18,0,17,8,9,0,1,1,0,9,8l8-8,1,1L10,9Z"></path></svg></div>
                                                    <a className="quote-itm-name">{e.quoteName}</a>
                                                </div>
                                            )
                                        }


                                        <div data-tip data-for='add-quote' className="add-opt tipped" onClick={() => { addQuoteAction() }}>
                                            <svg className="ico-link ico-add">
                                                <path d="M24,48A24,24,0,1,1,48,24,24,24,0,0,1,24,48ZM24,1.89A22.1,22.1,0,1,0,46.08,24,22.09,22.09,0,0,0,24,1.89Zm1,32.69H23.05V25h-9.6V23.05h9.6v-9.6H25v9.6h9.61V25H25Z"></path>
                                            </svg>
                                        </div>

                                        <ReactTooltip id='add-quote' aria-haspopup='true' className='tootipCustom' >
                                            <p className='m-0'>
                                                Add a Quote
                                            </p>
                                        </ReactTooltip>

                                    </div>
                                    */}
								</div>
							} {/*end quote View */}
						</div>
					</div>
				</div>
			</div>

			{/* popup */}
			{(popupShow ?
				<div>
					<div className={`${style.overly}`}> </div>
					<div className="modal fade show d-block">
						<div className={` modal-dialog modal-dialog-centered modal-sm`}>
							<div className="modal-content">
								<div className="modal-body">
									{popUpMsg}
								</div>

								<div className="modal-footer d-block text-center">
									<button type="button" className="btn-style btn-theme" data-dismiss="modal" onClick={() => { if (quoteView.product_id === "32") { orderDarkFiber(); } else { orderOfficeSuite(); } }}>Ok</button>
									<button type="button" className="btn-style btn-cancel" data-dismiss="modal" onClick={() => popupCancel()}>Cancel</button>
								</div>

							</div>
						</div>
					</div>
				</div>
				: null)
			}

			<div className={`overlay ${addQuote ? 'show' : ''}`}></div>

			{/* <div className="add-quote-panel" style={{ display: 'block' }}> */}
			<div className={`add-quote-panel ${addQuote ? 'd-block' : ''}`}>

				<div className="panel-content">
					<h2 className={style.manageQuoteTitlePopup}>Manage Quotes</h2>
					<p className={style.manageQuoteDesc}>Add up to 5 quotes to your Quote Manager for rapid access to specific quotes.</p>
					<div className={style.filterWrap}>
						<button onClick={() => { onClickFilter() }}>
							<svg className="ico ico-link light-ico"><path d="M22,27V17L35,5V2s-1-1-1-1H9A1,1,0,0,0,8,2,1,1,0,0,0,9,3H33V5L20,17V27l-5,5V16L2.62,3.36A.84.84,0,0,0,2,3.09a.93.93,0,0,0-.68.26A.87.87,0,0,0,1,4a1.09,1.09,0,0,0,.26.72L13,17V34l2,1Z"></path></svg>
						</button>

						<div className="tally">
							{
								selectedQuoteList.length === 5 ?
									<div className="tally-max">
										MANAGE LIMIT REACHED
									</div>
									:
									<div>ADD < div id='manage-tally'> <span>{5 - selectedQuoteList.length}</span></div>MORE QUOTES</div>
							}
						</div>
					</div>
				</div>

				<div className={style.quotetable}>
					{isLoader ? <Loader /> :
						<div className={` ${style.tableWrap} tableWrap quoteTableWrap m-0`}>
							<div className={`rel pagination-table quoteDetailsTable
                                    ${dateRequested ? 'date-requested-show' : null}
                                    ${dateOrdered ? 'date-ordered-show' : null}
                                    ${quoteID ? 'quote-ID-show' : null}
                                    ${requestor ? 'requestor-show' : null}
                                    `}>
								<BootstrapTable id="quoteDetailsTable"
									keyField='id'
									data={quoteSummaryData}
									columns={columns}
									bordered={false}
									pagination={paginationFactory({
										sizePerPage: 5
									})}
									filter={filterFactory()}
									loading={true}
									className="mb-0"
								/>
							</div>
						</div>
					}
				</div>

				<div className="panel-close quote-panel">
					<div className="dismiss" onClick={() => { closeQuotePanel() }}>
						<div className="ux-ring"></div>
						<svg className="ico-link ico-add light-ico ico-close"><path d="M24,48A24,24,0,1,1,48,24,24,24,0,0,1,24,48ZM24,1.89A22.1,22.1,0,1,0,46.08,24,22.09,22.09,0,0,0,24,1.89Zm1,32.69H23.05V25h-9.6V23.05h9.6v-9.6H25v9.6h9.61V25H25Z"></path></svg>

					</div>
				</div>


				<div className={` grid-filters dark-panel darkPanel ${style.darkPanel} ${filterShow ? 'd-block' : ''}`}>
					<h2>Sort</h2>

					<div className="filters">
						<div className={`dropdown niceSelect`}>
							<button className="btn dropdown-toggle niceSelectBtn" type="button" id="dropdownMenu2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
								Requested Date: Most Recent
							</button>
							<div className="dropdown-menu niceSelectMenu" aria-labelledby="dropdownMenu2">
								<button className={` dropdown-item selected `} type="button">Requested Date: Most Recent</button>
								<button className={` dropdown-item `} type="button">Requested Date: Oldest</button>
								<button className={` dropdown-item `} type="button">Order Date: Most Recent</button>
								<button className={` dropdown-item `} type="button">Order Date: Oldest</button>
							</div>
						</div>
					</div>

					<h2>Filter</h2>
					<div className="filters">

						<select id="panel-filter-time-update" className="time-select w100" name="" onChange={() => { filterSelectOnChange() }}>
							<option>Past 15 Days</option>
							<option>Past 30 Days</option>
							<option>Past 60 Days</option>
							<option>Beginning of Time</option>
						</select>
					</div>

					<h2>View</h2>
					<div className="filters">
						<div className={style.viewListFilter}>
							<ToggleSwitch label='ID' state={quoteID} toggleEmit={() => { setQuoteID(!quoteID) }}></ToggleSwitch>
						</div>
						<div className={style.viewListFilter}>
							<ToggleSwitch label='Requested Date' state={dateRequested} toggleEmit={() => { setDateRequested(!dateRequested) }}></ToggleSwitch>
						</div>
						<div className={style.viewListFilter}>
							<ToggleSwitch label='Ordered Date' state={dateOrdered} toggleEmit={() => { setDateOrdered(!dateOrdered) }}></ToggleSwitch>
						</div>
						<div className={style.viewListFilter}>
							<ToggleSwitch label='Requested by Name' state={requestor} toggleEmit={() => { setRequestor(!requestor) }}></ToggleSwitch>
						</div>
					</div>
				</div>

			</div>

		</>
	);
}

const mapDispatchToProps = {
	updateSessionError: setSessionError
}

export default connect(null, mapDispatchToProps)(QuoteView);
