/**
 * Created by e0173131 on 1/20/2021.
 */
import React, {Fragment} from 'react';

const Supertip = () => {
    return (
        <Fragment>
            <div className="supertip">
                <div className="side-overlay">&nbsp;</div>
                <div className="trim">&nbsp;</div>
                <div className="tip">&nbsp;</div>
                <div className="tip-ico">
                    <img src="../../images/svg/ico-info-filled-orange.svg" width="36" alt="" />
                </div>
                <div className="cor1">&nbsp;</div>
                <div className="cor2">&nbsp;</div>
                <div className="content hide">
                    <div className="injected">&nbsp;</div>
                </div>
                <div className="close">
                    <div className="action-highlight">&nbsp;</div>
                    <svg id="close-supertip" className="ico-link">
                        <path d="M18,17l-1,1L9,10,1,18,0,17,8,9,0,1,1,0,9,8l8-8,1,1L10,9Z" />
                    </svg>
                </div>
                <div className="base">&nbsp;</div>
            </div>
        </Fragment>
    );
}

export default Supertip;
