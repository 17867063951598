import React from "react";

const ToggleSwitch = (props) => {
    var label = props.label;
    var state = props.state === true ? 'on' : '';

    const toggleClick = (e) => {
        props.toggleEmit(e);
    }

    return (
        <div className="toggle-comp" onClick={toggleClick}>
            <div className={`toggle-switch ${state}`}>
                <div className="ts-bar">
                    <div className="ts-left-edge"></div>
                    <div className="ts-right-edge"></div>
                    <div className="ts-switch"></div>
                </div>
            </div>
            <span className="ts-label">{label}</span>
        </div>
    )
}

export default ToggleSwitch;