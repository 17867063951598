import axios from "./axios/customAxios";

const getCompanyOptions = async (addValue, allCompanies) => {
	// add value will add a value to the company list
	const quoteCompanyURL = `${process.env.REACT_APP_API_URL}/QuoteFunctions.php?type=company&value=${addValue}&allCompanies=${allCompanies}`;
	return await axios.get(quoteCompanyURL);
}

const setMpid = async (mpid) => {
	const saveMpidURL = `${process.env.REACT_APP_API_URL}/saveMpid.php?mpid=${mpid}`;
	axios.get(saveMpidURL);
}

const agencyCheck = async (mpid) => {
	const agencyCheckURL = `${process.env.REACT_APP_API_URL}/QuoteFunctions.php?type=eis&mpid=${mpid}`;
	return await axios.get(agencyCheckURL);
}

const getAgencyOptions = async () => {
	const agencyURL = `${process.env.REACT_APP_API_URL}/QuoteFunctions.php?type=agency`;
	return await axios.get(agencyURL)
}

const getEmployeeName = async (eid) => {
	const getEmployeeInfoURL = `${process.env.REACT_APP_API_URL}/getEmployeeInfo.php?eid=${eid}&field=name`;
	return await axios.get(getEmployeeInfoURL);
}

const getMyWorklist = async () => {
	const workListURL = `${process.env.REACT_APP_API_URL}/getMyWorklist.php?queue=my_worklist`;
	return await axios.get(workListURL);
}


const EmployeeInternalHelper = {
	getCompanyOptions,
	setMpid,
	agencyCheck,
	getAgencyOptions,
	getEmployeeName,
	getMyWorklist
}

export default EmployeeInternalHelper
